{
  "version": "2.0",
  "metadata": {
    "apiVersion": "2011-01-01",
    "endpointPrefix": "autoscaling",
    "protocol": "query",
    "serviceFullName": "Auto Scaling",
    "serviceId": "Auto Scaling",
    "signatureVersion": "v4",
    "uid": "autoscaling-2011-01-01",
    "xmlNamespace": "http://autoscaling.amazonaws.com/doc/2011-01-01/"
  },
  "operations": {
    "AttachInstances": {
      "input": {
        "type": "structure",
        "required": [
          "AutoScalingGroupName"
        ],
        "members": {
          "InstanceIds": {
            "shape": "S2"
          },
          "AutoScalingGroupName": {}
        }
      }
    },
    "AttachLoadBalancerTargetGroups": {
      "input": {
        "type": "structure",
        "required": [
          "AutoScalingGroupName",
          "TargetGroupARNs"
        ],
        "members": {
          "AutoScalingGroupName": {},
          "TargetGroupARNs": {
            "shape": "S6"
          }
        }
      },
      "output": {
        "resultWrapper": "AttachLoadBalancerTargetGroupsResult",
        "type": "structure",
        "members": {}
      }
    },
    "AttachLoadBalancers": {
      "input": {
        "type": "structure",
        "required": [
          "AutoScalingGroupName",
          "LoadBalancerNames"
        ],
        "members": {
          "AutoScalingGroupName": {},
          "LoadBalancerNames": {
            "shape": "Sa"
          }
        }
      },
      "output": {
        "resultWrapper": "AttachLoadBalancersResult",
        "type": "structure",
        "members": {}
      }
    },
    "BatchDeleteScheduledAction": {
      "input": {
        "type": "structure",
        "required": [
          "AutoScalingGroupName",
          "ScheduledActionNames"
        ],
        "members": {
          "AutoScalingGroupName": {},
          "ScheduledActionNames": {
            "shape": "Sd"
          }
        }
      },
      "output": {
        "resultWrapper": "BatchDeleteScheduledActionResult",
        "type": "structure",
        "members": {
          "FailedScheduledActions": {
            "shape": "Sf"
          }
        }
      }
    },
    "BatchPutScheduledUpdateGroupAction": {
      "input": {
        "type": "structure",
        "required": [
          "AutoScalingGroupName",
          "ScheduledUpdateGroupActions"
        ],
        "members": {
          "AutoScalingGroupName": {},
          "ScheduledUpdateGroupActions": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "ScheduledActionName"
              ],
              "members": {
                "ScheduledActionName": {},
                "StartTime": {
                  "type": "timestamp"
                },
                "EndTime": {
                  "type": "timestamp"
                },
                "Recurrence": {},
                "MinSize": {
                  "type": "integer"
                },
                "MaxSize": {
                  "type": "integer"
                },
                "DesiredCapacity": {
                  "type": "integer"
                },
                "TimeZone": {}
              }
            }
          }
        }
      },
      "output": {
        "resultWrapper": "BatchPutScheduledUpdateGroupActionResult",
        "type": "structure",
        "members": {
          "FailedScheduledUpdateGroupActions": {
            "shape": "Sf"
          }
        }
      }
    },
    "CancelInstanceRefresh": {
      "input": {
        "type": "structure",
        "required": [
          "AutoScalingGroupName"
        ],
        "members": {
          "AutoScalingGroupName": {}
        }
      },
      "output": {
        "resultWrapper": "CancelInstanceRefreshResult",
        "type": "structure",
        "members": {
          "InstanceRefreshId": {}
        }
      }
    },
    "CompleteLifecycleAction": {
      "input": {
        "type": "structure",
        "required": [
          "LifecycleHookName",
          "AutoScalingGroupName",
          "LifecycleActionResult"
        ],
        "members": {
          "LifecycleHookName": {},
          "AutoScalingGroupName": {},
          "LifecycleActionToken": {},
          "LifecycleActionResult": {},
          "InstanceId": {}
        }
      },
      "output": {
        "resultWrapper": "CompleteLifecycleActionResult",
        "type": "structure",
        "members": {}
      }
    },
    "CreateAutoScalingGroup": {
      "input": {
        "type": "structure",
        "required": [
          "AutoScalingGroupName",
          "MinSize",
          "MaxSize"
        ],
        "members": {
          "AutoScalingGroupName": {},
          "LaunchConfigurationName": {},
          "LaunchTemplate": {
            "shape": "S10"
          },
          "MixedInstancesPolicy": {
            "shape": "S12"
          },
          "InstanceId": {},
          "MinSize": {
            "type": "integer"
          },
          "MaxSize": {
            "type": "integer"
          },
          "DesiredCapacity": {
            "type": "integer"
          },
          "DefaultCooldown": {
            "type": "integer"
          },
          "AvailabilityZones": {
            "shape": "S1d"
          },
          "LoadBalancerNames": {
            "shape": "Sa"
          },
          "TargetGroupARNs": {
            "shape": "S6"
          },
          "HealthCheckType": {},
          "HealthCheckGracePeriod": {
            "type": "integer"
          },
          "PlacementGroup": {},
          "VPCZoneIdentifier": {},
          "TerminationPolicies": {
            "shape": "S1g"
          },
          "NewInstancesProtectedFromScaleIn": {
            "type": "boolean"
          },
          "CapacityRebalance": {
            "type": "boolean"
          },
          "LifecycleHookSpecificationList": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "LifecycleHookName",
                "LifecycleTransition"
              ],
              "members": {
                "LifecycleHookName": {},
                "LifecycleTransition": {},
                "NotificationMetadata": {},
                "HeartbeatTimeout": {
                  "type": "integer"
                },
                "DefaultResult": {},
                "NotificationTargetARN": {},
                "RoleARN": {}
              }
            }
          },
          "Tags": {
            "shape": "S1q"
          },
          "ServiceLinkedRoleARN": {},
          "MaxInstanceLifetime": {
            "type": "integer"
          },
          "Context": {}
        }
      }
    },
    "CreateLaunchConfiguration": {
      "input": {
        "type": "structure",
        "required": [
          "LaunchConfigurationName"
        ],
        "members": {
          "LaunchConfigurationName": {},
          "ImageId": {},
          "KeyName": {},
          "SecurityGroups": {
            "shape": "S1y"
          },
          "ClassicLinkVPCId": {},
          "ClassicLinkVPCSecurityGroups": {
            "shape": "S1z"
          },
          "UserData": {},
          "InstanceId": {},
          "InstanceType": {},
          "KernelId": {},
          "RamdiskId": {},
          "BlockDeviceMappings": {
            "shape": "S21"
          },
          "InstanceMonitoring": {
            "shape": "S2b"
          },
          "SpotPrice": {},
          "IamInstanceProfile": {},
          "EbsOptimized": {
            "type": "boolean"
          },
          "AssociatePublicIpAddress": {
            "type": "boolean"
          },
          "PlacementTenancy": {},
          "MetadataOptions": {
            "shape": "S2g"
          }
        }
      }
    },
    "CreateOrUpdateTags": {
      "input": {
        "type": "structure",
        "required": [
          "Tags"
        ],
        "members": {
          "Tags": {
            "shape": "S1q"
          }
        }
      }
    },
    "DeleteAutoScalingGroup": {
      "input": {
        "type": "structure",
        "required": [
          "AutoScalingGroupName"
        ],
        "members": {
          "AutoScalingGroupName": {},
          "ForceDelete": {
            "type": "boolean"
          }
        }
      }
    },
    "DeleteLaunchConfiguration": {
      "input": {
        "type": "structure",
        "required": [
          "LaunchConfigurationName"
        ],
        "members": {
          "LaunchConfigurationName": {}
        }
      }
    },
    "DeleteLifecycleHook": {
      "input": {
        "type": "structure",
        "required": [
          "LifecycleHookName",
          "AutoScalingGroupName"
        ],
        "members": {
          "LifecycleHookName": {},
          "AutoScalingGroupName": {}
        }
      },
      "output": {
        "resultWrapper": "DeleteLifecycleHookResult",
        "type": "structure",
        "members": {}
      }
    },
    "DeleteNotificationConfiguration": {
      "input": {
        "type": "structure",
        "required": [
          "AutoScalingGroupName",
          "TopicARN"
        ],
        "members": {
          "AutoScalingGroupName": {},
          "TopicARN": {}
        }
      }
    },
    "DeletePolicy": {
      "input": {
        "type": "structure",
        "required": [
          "PolicyName"
        ],
        "members": {
          "AutoScalingGroupName": {},
          "PolicyName": {}
        }
      }
    },
    "DeleteScheduledAction": {
      "input": {
        "type": "structure",
        "required": [
          "AutoScalingGroupName",
          "ScheduledActionName"
        ],
        "members": {
          "AutoScalingGroupName": {},
          "ScheduledActionName": {}
        }
      }
    },
    "DeleteTags": {
      "input": {
        "type": "structure",
        "required": [
          "Tags"
        ],
        "members": {
          "Tags": {
            "shape": "S1q"
          }
        }
      }
    },
    "DeleteWarmPool": {
      "input": {
        "type": "structure",
        "required": [
          "AutoScalingGroupName"
        ],
        "members": {
          "AutoScalingGroupName": {},
          "ForceDelete": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "resultWrapper": "DeleteWarmPoolResult",
        "type": "structure",
        "members": {}
      }
    },
    "DescribeAccountLimits": {
      "output": {
        "resultWrapper": "DescribeAccountLimitsResult",
        "type": "structure",
        "members": {
          "MaxNumberOfAutoScalingGroups": {
            "type": "integer"
          },
          "MaxNumberOfLaunchConfigurations": {
            "type": "integer"
          },
          "NumberOfAutoScalingGroups": {
            "type": "integer"
          },
          "NumberOfLaunchConfigurations": {
            "type": "integer"
          }
        }
      }
    },
    "DescribeAdjustmentTypes": {
      "output": {
        "resultWrapper": "DescribeAdjustmentTypesResult",
        "type": "structure",
        "members": {
          "AdjustmentTypes": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "AdjustmentType": {}
              }
            }
          }
        }
      }
    },
    "DescribeAutoScalingGroups": {
      "input": {
        "type": "structure",
        "members": {
          "AutoScalingGroupNames": {
            "shape": "S35"
          },
          "NextToken": {},
          "MaxRecords": {
            "type": "integer"
          },
          "Filters": {
            "shape": "S37"
          }
        }
      },
      "output": {
        "resultWrapper": "DescribeAutoScalingGroupsResult",
        "type": "structure",
        "required": [
          "AutoScalingGroups"
        ],
        "members": {
          "AutoScalingGroups": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "AutoScalingGroupName",
                "MinSize",
                "MaxSize",
                "DesiredCapacity",
                "DefaultCooldown",
                "AvailabilityZones",
                "HealthCheckType",
                "CreatedTime"
              ],
              "members": {
                "AutoScalingGroupName": {},
                "AutoScalingGroupARN": {},
                "LaunchConfigurationName": {},
                "LaunchTemplate": {
                  "shape": "S10"
                },
                "MixedInstancesPolicy": {
                  "shape": "S12"
                },
                "MinSize": {
                  "type": "integer"
                },
                "MaxSize": {
                  "type": "integer"
                },
                "DesiredCapacity": {
                  "type": "integer"
                },
                "PredictedCapacity": {
                  "type": "integer"
                },
                "DefaultCooldown": {
                  "type": "integer"
                },
                "AvailabilityZones": {
                  "shape": "S1d"
                },
                "LoadBalancerNames": {
                  "shape": "Sa"
                },
                "TargetGroupARNs": {
                  "shape": "S6"
                },
                "HealthCheckType": {},
                "HealthCheckGracePeriod": {
                  "type": "integer"
                },
                "Instances": {
                  "shape": "S3e"
                },
                "CreatedTime": {
                  "type": "timestamp"
                },
                "SuspendedProcesses": {
                  "type": "list",
                  "member": {
                    "type": "structure",
                    "members": {
                      "ProcessName": {},
                      "SuspensionReason": {}
                    }
                  }
                },
                "PlacementGroup": {},
                "VPCZoneIdentifier": {},
                "EnabledMetrics": {
                  "type": "list",
                  "member": {
                    "type": "structure",
                    "members": {
                      "Metric": {},
                      "Granularity": {}
                    }
                  }
                },
                "Status": {},
                "Tags": {
                  "shape": "S3l"
                },
                "TerminationPolicies": {
                  "shape": "S1g"
                },
                "NewInstancesProtectedFromScaleIn": {
                  "type": "boolean"
                },
                "ServiceLinkedRoleARN": {},
                "MaxInstanceLifetime": {
                  "type": "integer"
                },
                "CapacityRebalance": {
                  "type": "boolean"
                },
                "WarmPoolConfiguration": {
                  "shape": "S3n"
                },
                "WarmPoolSize": {
                  "type": "integer"
                },
                "Context": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribeAutoScalingInstances": {
      "input": {
        "type": "structure",
        "members": {
          "InstanceIds": {
            "shape": "S2"
          },
          "MaxRecords": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeAutoScalingInstancesResult",
        "type": "structure",
        "members": {
          "AutoScalingInstances": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "InstanceId",
                "AutoScalingGroupName",
                "AvailabilityZone",
                "LifecycleState",
                "HealthStatus",
                "ProtectedFromScaleIn"
              ],
              "members": {
                "InstanceId": {},
                "InstanceType": {},
                "AutoScalingGroupName": {},
                "AvailabilityZone": {},
                "LifecycleState": {},
                "HealthStatus": {},
                "LaunchConfigurationName": {},
                "LaunchTemplate": {
                  "shape": "S10"
                },
                "ProtectedFromScaleIn": {
                  "type": "boolean"
                },
                "WeightedCapacity": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribeAutoScalingNotificationTypes": {
      "output": {
        "resultWrapper": "DescribeAutoScalingNotificationTypesResult",
        "type": "structure",
        "members": {
          "AutoScalingNotificationTypes": {
            "shape": "S3y"
          }
        }
      }
    },
    "DescribeInstanceRefreshes": {
      "input": {
        "type": "structure",
        "required": [
          "AutoScalingGroupName"
        ],
        "members": {
          "AutoScalingGroupName": {},
          "InstanceRefreshIds": {
            "type": "list",
            "member": {}
          },
          "NextToken": {},
          "MaxRecords": {
            "type": "integer"
          }
        }
      },
      "output": {
        "resultWrapper": "DescribeInstanceRefreshesResult",
        "type": "structure",
        "members": {
          "InstanceRefreshes": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "InstanceRefreshId": {},
                "AutoScalingGroupName": {},
                "Status": {},
                "StatusReason": {},
                "StartTime": {
                  "type": "timestamp"
                },
                "EndTime": {
                  "type": "timestamp"
                },
                "PercentageComplete": {
                  "type": "integer"
                },
                "InstancesToUpdate": {
                  "type": "integer"
                },
                "ProgressDetails": {
                  "type": "structure",
                  "members": {
                    "LivePoolProgress": {
                      "type": "structure",
                      "members": {
                        "PercentageComplete": {
                          "type": "integer"
                        },
                        "InstancesToUpdate": {
                          "type": "integer"
                        }
                      }
                    },
                    "WarmPoolProgress": {
                      "type": "structure",
                      "members": {
                        "PercentageComplete": {
                          "type": "integer"
                        },
                        "InstancesToUpdate": {
                          "type": "integer"
                        }
                      }
                    }
                  }
                },
                "Preferences": {
                  "shape": "S4a"
                },
                "DesiredConfiguration": {
                  "shape": "S4g"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribeLaunchConfigurations": {
      "input": {
        "type": "structure",
        "members": {
          "LaunchConfigurationNames": {
            "type": "list",
            "member": {}
          },
          "NextToken": {},
          "MaxRecords": {
            "type": "integer"
          }
        }
      },
      "output": {
        "resultWrapper": "DescribeLaunchConfigurationsResult",
        "type": "structure",
        "required": [
          "LaunchConfigurations"
        ],
        "members": {
          "LaunchConfigurations": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "LaunchConfigurationName",
                "ImageId",
                "InstanceType",
                "CreatedTime"
              ],
              "members": {
                "LaunchConfigurationName": {},
                "LaunchConfigurationARN": {},
                "ImageId": {},
                "KeyName": {},
                "SecurityGroups": {
                  "shape": "S1y"
                },
                "ClassicLinkVPCId": {},
                "ClassicLinkVPCSecurityGroups": {
                  "shape": "S1z"
                },
                "UserData": {},
                "InstanceType": {},
                "KernelId": {},
                "RamdiskId": {},
                "BlockDeviceMappings": {
                  "shape": "S21"
                },
                "InstanceMonitoring": {
                  "shape": "S2b"
                },
                "SpotPrice": {},
                "IamInstanceProfile": {},
                "CreatedTime": {
                  "type": "timestamp"
                },
                "EbsOptimized": {
                  "type": "boolean"
                },
                "AssociatePublicIpAddress": {
                  "type": "boolean"
                },
                "PlacementTenancy": {},
                "MetadataOptions": {
                  "shape": "S2g"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribeLifecycleHookTypes": {
      "output": {
        "resultWrapper": "DescribeLifecycleHookTypesResult",
        "type": "structure",
        "members": {
          "LifecycleHookTypes": {
            "shape": "S3y"
          }
        }
      }
    },
    "DescribeLifecycleHooks": {
      "input": {
        "type": "structure",
        "required": [
          "AutoScalingGroupName"
        ],
        "members": {
          "AutoScalingGroupName": {},
          "LifecycleHookNames": {
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "resultWrapper": "DescribeLifecycleHooksResult",
        "type": "structure",
        "members": {
          "LifecycleHooks": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "LifecycleHookName": {},
                "AutoScalingGroupName": {},
                "LifecycleTransition": {},
                "NotificationTargetARN": {},
                "RoleARN": {},
                "NotificationMetadata": {},
                "HeartbeatTimeout": {
                  "type": "integer"
                },
                "GlobalTimeout": {
                  "type": "integer"
                },
                "DefaultResult": {}
              }
            }
          }
        }
      }
    },
    "DescribeLoadBalancerTargetGroups": {
      "input": {
        "type": "structure",
        "required": [
          "AutoScalingGroupName"
        ],
        "members": {
          "AutoScalingGroupName": {},
          "NextToken": {},
          "MaxRecords": {
            "type": "integer"
          }
        }
      },
      "output": {
        "resultWrapper": "DescribeLoadBalancerTargetGroupsResult",
        "type": "structure",
        "members": {
          "LoadBalancerTargetGroups": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "LoadBalancerTargetGroupARN": {},
                "State": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribeLoadBalancers": {
      "input": {
        "type": "structure",
        "required": [
          "AutoScalingGroupName"
        ],
        "members": {
          "AutoScalingGroupName": {},
          "NextToken": {},
          "MaxRecords": {
            "type": "integer"
          }
        }
      },
      "output": {
        "resultWrapper": "DescribeLoadBalancersResult",
        "type": "structure",
        "members": {
          "LoadBalancers": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "LoadBalancerName": {},
                "State": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribeMetricCollectionTypes": {
      "output": {
        "resultWrapper": "DescribeMetricCollectionTypesResult",
        "type": "structure",
        "members": {
          "Metrics": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Metric": {}
              }
            }
          },
          "Granularities": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Granularity": {}
              }
            }
          }
        }
      }
    },
    "DescribeNotificationConfigurations": {
      "input": {
        "type": "structure",
        "members": {
          "AutoScalingGroupNames": {
            "shape": "S35"
          },
          "NextToken": {},
          "MaxRecords": {
            "type": "integer"
          }
        }
      },
      "output": {
        "resultWrapper": "DescribeNotificationConfigurationsResult",
        "type": "structure",
        "required": [
          "NotificationConfigurations"
        ],
        "members": {
          "NotificationConfigurations": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "AutoScalingGroupName": {},
                "TopicARN": {},
                "NotificationType": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribePolicies": {
      "input": {
        "type": "structure",
        "members": {
          "AutoScalingGroupName": {},
          "PolicyNames": {
            "type": "list",
            "member": {}
          },
          "PolicyTypes": {
            "type": "list",
            "member": {}
          },
          "NextToken": {},
          "MaxRecords": {
            "type": "integer"
          }
        }
      },
      "output": {
        "resultWrapper": "DescribePoliciesResult",
        "type": "structure",
        "members": {
          "ScalingPolicies": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "AutoScalingGroupName": {},
                "PolicyName": {},
                "PolicyARN": {},
                "PolicyType": {},
                "AdjustmentType": {},
                "MinAdjustmentStep": {
                  "shape": "S5g"
                },
                "MinAdjustmentMagnitude": {
                  "type": "integer"
                },
                "ScalingAdjustment": {
                  "type": "integer"
                },
                "Cooldown": {
                  "type": "integer"
                },
                "StepAdjustments": {
                  "shape": "S5j"
                },
                "MetricAggregationType": {},
                "EstimatedInstanceWarmup": {
                  "type": "integer"
                },
                "Alarms": {
                  "shape": "S5n"
                },
                "TargetTrackingConfiguration": {
                  "shape": "S5p"
                },
                "Enabled": {
                  "type": "boolean"
                },
                "PredictiveScalingConfiguration": {
                  "shape": "S63"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribeScalingActivities": {
      "input": {
        "type": "structure",
        "members": {
          "ActivityIds": {
            "type": "list",
            "member": {}
          },
          "AutoScalingGroupName": {},
          "IncludeDeletedGroups": {
            "type": "boolean"
          },
          "MaxRecords": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeScalingActivitiesResult",
        "type": "structure",
        "required": [
          "Activities"
        ],
        "members": {
          "Activities": {
            "shape": "S6k"
          },
          "NextToken": {}
        }
      }
    },
    "DescribeScalingProcessTypes": {
      "output": {
        "resultWrapper": "DescribeScalingProcessTypesResult",
        "type": "structure",
        "members": {
          "Processes": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "ProcessName"
              ],
              "members": {
                "ProcessName": {}
              }
            }
          }
        }
      }
    },
    "DescribeScheduledActions": {
      "input": {
        "type": "structure",
        "members": {
          "AutoScalingGroupName": {},
          "ScheduledActionNames": {
            "shape": "Sd"
          },
          "StartTime": {
            "type": "timestamp"
          },
          "EndTime": {
            "type": "timestamp"
          },
          "NextToken": {},
          "MaxRecords": {
            "type": "integer"
          }
        }
      },
      "output": {
        "resultWrapper": "DescribeScheduledActionsResult",
        "type": "structure",
        "members": {
          "ScheduledUpdateGroupActions": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "AutoScalingGroupName": {},
                "ScheduledActionName": {},
                "ScheduledActionARN": {},
                "Time": {
                  "type": "timestamp"
                },
                "StartTime": {
                  "type": "timestamp"
                },
                "EndTime": {
                  "type": "timestamp"
                },
                "Recurrence": {},
                "MinSize": {
                  "type": "integer"
                },
                "MaxSize": {
                  "type": "integer"
                },
                "DesiredCapacity": {
                  "type": "integer"
                },
                "TimeZone": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribeTags": {
      "input": {
        "type": "structure",
        "members": {
          "Filters": {
            "shape": "S37"
          },
          "NextToken": {},
          "MaxRecords": {
            "type": "integer"
          }
        }
      },
      "output": {
        "resultWrapper": "DescribeTagsResult",
        "type": "structure",
        "members": {
          "Tags": {
            "shape": "S3l"
          },
          "NextToken": {}
        }
      }
    },
    "DescribeTerminationPolicyTypes": {
      "output": {
        "resultWrapper": "DescribeTerminationPolicyTypesResult",
        "type": "structure",
        "members": {
          "TerminationPolicyTypes": {
            "shape": "S1g"
          }
        }
      }
    },
    "DescribeWarmPool": {
      "input": {
        "type": "structure",
        "required": [
          "AutoScalingGroupName"
        ],
        "members": {
          "AutoScalingGroupName": {},
          "MaxRecords": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeWarmPoolResult",
        "type": "structure",
        "members": {
          "WarmPoolConfiguration": {
            "shape": "S3n"
          },
          "Instances": {
            "shape": "S3e"
          },
          "NextToken": {}
        }
      }
    },
    "DetachInstances": {
      "input": {
        "type": "structure",
        "required": [
          "AutoScalingGroupName",
          "ShouldDecrementDesiredCapacity"
        ],
        "members": {
          "InstanceIds": {
            "shape": "S2"
          },
          "AutoScalingGroupName": {},
          "ShouldDecrementDesiredCapacity": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "resultWrapper": "DetachInstancesResult",
        "type": "structure",
        "members": {
          "Activities": {
            "shape": "S6k"
          }
        }
      }
    },
    "DetachLoadBalancerTargetGroups": {
      "input": {
        "type": "structure",
        "required": [
          "AutoScalingGroupName",
          "TargetGroupARNs"
        ],
        "members": {
          "AutoScalingGroupName": {},
          "TargetGroupARNs": {
            "shape": "S6"
          }
        }
      },
      "output": {
        "resultWrapper": "DetachLoadBalancerTargetGroupsResult",
        "type": "structure",
        "members": {}
      }
    },
    "DetachLoadBalancers": {
      "input": {
        "type": "structure",
        "required": [
          "AutoScalingGroupName",
          "LoadBalancerNames"
        ],
        "members": {
          "AutoScalingGroupName": {},
          "LoadBalancerNames": {
            "shape": "Sa"
          }
        }
      },
      "output": {
        "resultWrapper": "DetachLoadBalancersResult",
        "type": "structure",
        "members": {}
      }
    },
    "DisableMetricsCollection": {
      "input": {
        "type": "structure",
        "required": [
          "AutoScalingGroupName"
        ],
        "members": {
          "AutoScalingGroupName": {},
          "Metrics": {
            "shape": "S79"
          }
        }
      }
    },
    "EnableMetricsCollection": {
      "input": {
        "type": "structure",
        "required": [
          "AutoScalingGroupName",
          "Granularity"
        ],
        "members": {
          "AutoScalingGroupName": {},
          "Metrics": {
            "shape": "S79"
          },
          "Granularity": {}
        }
      }
    },
    "EnterStandby": {
      "input": {
        "type": "structure",
        "required": [
          "AutoScalingGroupName",
          "ShouldDecrementDesiredCapacity"
        ],
        "members": {
          "InstanceIds": {
            "shape": "S2"
          },
          "AutoScalingGroupName": {},
          "ShouldDecrementDesiredCapacity": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "resultWrapper": "EnterStandbyResult",
        "type": "structure",
        "members": {
          "Activities": {
            "shape": "S6k"
          }
        }
      }
    },
    "ExecutePolicy": {
      "input": {
        "type": "structure",
        "required": [
          "PolicyName"
        ],
        "members": {
          "AutoScalingGroupName": {},
          "PolicyName": {},
          "HonorCooldown": {
            "type": "boolean"
          },
          "MetricValue": {
            "type": "double"
          },
          "BreachThreshold": {
            "type": "double"
          }
        }
      }
    },
    "ExitStandby": {
      "input": {
        "type": "structure",
        "required": [
          "AutoScalingGroupName"
        ],
        "members": {
          "InstanceIds": {
            "shape": "S2"
          },
          "AutoScalingGroupName": {}
        }
      },
      "output": {
        "resultWrapper": "ExitStandbyResult",
        "type": "structure",
        "members": {
          "Activities": {
            "shape": "S6k"
          }
        }
      }
    },
    "GetPredictiveScalingForecast": {
      "input": {
        "type": "structure",
        "required": [
          "AutoScalingGroupName",
          "PolicyName",
          "StartTime",
          "EndTime"
        ],
        "members": {
          "AutoScalingGroupName": {},
          "PolicyName": {},
          "StartTime": {
            "type": "timestamp"
          },
          "EndTime": {
            "type": "timestamp"
          }
        }
      },
      "output": {
        "resultWrapper": "GetPredictiveScalingForecastResult",
        "type": "structure",
        "required": [
          "LoadForecast",
          "CapacityForecast",
          "UpdateTime"
        ],
        "members": {
          "LoadForecast": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "Timestamps",
                "Values",
                "MetricSpecification"
              ],
              "members": {
                "Timestamps": {
                  "shape": "S7l"
                },
                "Values": {
                  "shape": "S7m"
                },
                "MetricSpecification": {
                  "shape": "S65"
                }
              }
            }
          },
          "CapacityForecast": {
            "type": "structure",
            "required": [
              "Timestamps",
              "Values"
            ],
            "members": {
              "Timestamps": {
                "shape": "S7l"
              },
              "Values": {
                "shape": "S7m"
              }
            }
          },
          "UpdateTime": {
            "type": "timestamp"
          }
        }
      }
    },
    "PutLifecycleHook": {
      "input": {
        "type": "structure",
        "required": [
          "LifecycleHookName",
          "AutoScalingGroupName"
        ],
        "members": {
          "LifecycleHookName": {},
          "AutoScalingGroupName": {},
          "LifecycleTransition": {},
          "RoleARN": {},
          "NotificationTargetARN": {},
          "NotificationMetadata": {},
          "HeartbeatTimeout": {
            "type": "integer"
          },
          "DefaultResult": {}
        }
      },
      "output": {
        "resultWrapper": "PutLifecycleHookResult",
        "type": "structure",
        "members": {}
      }
    },
    "PutNotificationConfiguration": {
      "input": {
        "type": "structure",
        "required": [
          "AutoScalingGroupName",
          "TopicARN",
          "NotificationTypes"
        ],
        "members": {
          "AutoScalingGroupName": {},
          "TopicARN": {},
          "NotificationTypes": {
            "shape": "S3y"
          }
        }
      }
    },
    "PutScalingPolicy": {
      "input": {
        "type": "structure",
        "required": [
          "AutoScalingGroupName",
          "PolicyName"
        ],
        "members": {
          "AutoScalingGroupName": {},
          "PolicyName": {},
          "PolicyType": {},
          "AdjustmentType": {},
          "MinAdjustmentStep": {
            "shape": "S5g"
          },
          "MinAdjustmentMagnitude": {
            "type": "integer"
          },
          "ScalingAdjustment": {
            "type": "integer"
          },
          "Cooldown": {
            "type": "integer"
          },
          "MetricAggregationType": {},
          "StepAdjustments": {
            "shape": "S5j"
          },
          "EstimatedInstanceWarmup": {
            "type": "integer"
          },
          "TargetTrackingConfiguration": {
            "shape": "S5p"
          },
          "Enabled": {
            "type": "boolean"
          },
          "PredictiveScalingConfiguration": {
            "shape": "S63"
          }
        }
      },
      "output": {
        "resultWrapper": "PutScalingPolicyResult",
        "type": "structure",
        "members": {
          "PolicyARN": {},
          "Alarms": {
            "shape": "S5n"
          }
        }
      }
    },
    "PutScheduledUpdateGroupAction": {
      "input": {
        "type": "structure",
        "required": [
          "AutoScalingGroupName",
          "ScheduledActionName"
        ],
        "members": {
          "AutoScalingGroupName": {},
          "ScheduledActionName": {},
          "Time": {
            "type": "timestamp"
          },
          "StartTime": {
            "type": "timestamp"
          },
          "EndTime": {
            "type": "timestamp"
          },
          "Recurrence": {},
          "MinSize": {
            "type": "integer"
          },
          "MaxSize": {
            "type": "integer"
          },
          "DesiredCapacity": {
            "type": "integer"
          },
          "TimeZone": {}
        }
      }
    },
    "PutWarmPool": {
      "input": {
        "type": "structure",
        "required": [
          "AutoScalingGroupName"
        ],
        "members": {
          "AutoScalingGroupName": {},
          "MaxGroupPreparedCapacity": {
            "type": "integer"
          },
          "MinSize": {
            "type": "integer"
          },
          "PoolState": {}
        }
      },
      "output": {
        "resultWrapper": "PutWarmPoolResult",
        "type": "structure",
        "members": {}
      }
    },
    "RecordLifecycleActionHeartbeat": {
      "input": {
        "type": "structure",
        "required": [
          "LifecycleHookName",
          "AutoScalingGroupName"
        ],
        "members": {
          "LifecycleHookName": {},
          "AutoScalingGroupName": {},
          "LifecycleActionToken": {},
          "InstanceId": {}
        }
      },
      "output": {
        "resultWrapper": "RecordLifecycleActionHeartbeatResult",
        "type": "structure",
        "members": {}
      }
    },
    "ResumeProcesses": {
      "input": {
        "shape": "S7y"
      }
    },
    "SetDesiredCapacity": {
      "input": {
        "type": "structure",
        "required": [
          "AutoScalingGroupName",
          "DesiredCapacity"
        ],
        "members": {
          "AutoScalingGroupName": {},
          "DesiredCapacity": {
            "type": "integer"
          },
          "HonorCooldown": {
            "type": "boolean"
          }
        }
      }
    },
    "SetInstanceHealth": {
      "input": {
        "type": "structure",
        "required": [
          "InstanceId",
          "HealthStatus"
        ],
        "members": {
          "InstanceId": {},
          "HealthStatus": {},
          "ShouldRespectGracePeriod": {
            "type": "boolean"
          }
        }
      }
    },
    "SetInstanceProtection": {
      "input": {
        "type": "structure",
        "required": [
          "InstanceIds",
          "AutoScalingGroupName",
          "ProtectedFromScaleIn"
        ],
        "members": {
          "InstanceIds": {
            "shape": "S2"
          },
          "AutoScalingGroupName": {},
          "ProtectedFromScaleIn": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "resultWrapper": "SetInstanceProtectionResult",
        "type": "structure",
        "members": {}
      }
    },
    "StartInstanceRefresh": {
      "input": {
        "type": "structure",
        "required": [
          "AutoScalingGroupName"
        ],
        "members": {
          "AutoScalingGroupName": {},
          "Strategy": {},
          "DesiredConfiguration": {
            "shape": "S4g"
          },
          "Preferences": {
            "shape": "S4a"
          }
        }
      },
      "output": {
        "resultWrapper": "StartInstanceRefreshResult",
        "type": "structure",
        "members": {
          "InstanceRefreshId": {}
        }
      }
    },
    "SuspendProcesses": {
      "input": {
        "shape": "S7y"
      }
    },
    "TerminateInstanceInAutoScalingGroup": {
      "input": {
        "type": "structure",
        "required": [
          "InstanceId",
          "ShouldDecrementDesiredCapacity"
        ],
        "members": {
          "InstanceId": {},
          "ShouldDecrementDesiredCapacity": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "resultWrapper": "TerminateInstanceInAutoScalingGroupResult",
        "type": "structure",
        "members": {
          "Activity": {
            "shape": "S6l"
          }
        }
      }
    },
    "UpdateAutoScalingGroup": {
      "input": {
        "type": "structure",
        "required": [
          "AutoScalingGroupName"
        ],
        "members": {
          "AutoScalingGroupName": {},
          "LaunchConfigurationName": {},
          "LaunchTemplate": {
            "shape": "S10"
          },
          "MixedInstancesPolicy": {
            "shape": "S12"
          },
          "MinSize": {
            "type": "integer"
          },
          "MaxSize": {
            "type": "integer"
          },
          "DesiredCapacity": {
            "type": "integer"
          },
          "DefaultCooldown": {
            "type": "integer"
          },
          "AvailabilityZones": {
            "shape": "S1d"
          },
          "HealthCheckType": {},
          "HealthCheckGracePeriod": {
            "type": "integer"
          },
          "PlacementGroup": {},
          "VPCZoneIdentifier": {},
          "TerminationPolicies": {
            "shape": "S1g"
          },
          "NewInstancesProtectedFromScaleIn": {
            "type": "boolean"
          },
          "ServiceLinkedRoleARN": {},
          "MaxInstanceLifetime": {
            "type": "integer"
          },
          "CapacityRebalance": {
            "type": "boolean"
          },
          "Context": {}
        }
      }
    }
  },
  "shapes": {
    "S2": {
      "type": "list",
      "member": {}
    },
    "S6": {
      "type": "list",
      "member": {}
    },
    "Sa": {
      "type": "list",
      "member": {}
    },
    "Sd": {
      "type": "list",
      "member": {}
    },
    "Sf": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "ScheduledActionName"
        ],
        "members": {
          "ScheduledActionName": {},
          "ErrorCode": {},
          "ErrorMessage": {}
        }
      }
    },
    "S10": {
      "type": "structure",
      "members": {
        "LaunchTemplateId": {},
        "LaunchTemplateName": {},
        "Version": {}
      }
    },
    "S12": {
      "type": "structure",
      "members": {
        "LaunchTemplate": {
          "type": "structure",
          "members": {
            "LaunchTemplateSpecification": {
              "shape": "S10"
            },
            "Overrides": {
              "type": "list",
              "member": {
                "type": "structure",
                "members": {
                  "InstanceType": {},
                  "WeightedCapacity": {},
                  "LaunchTemplateSpecification": {
                    "shape": "S10"
                  }
                }
              }
            }
          }
        },
        "InstancesDistribution": {
          "type": "structure",
          "members": {
            "OnDemandAllocationStrategy": {},
            "OnDemandBaseCapacity": {
              "type": "integer"
            },
            "OnDemandPercentageAboveBaseCapacity": {
              "type": "integer"
            },
            "SpotAllocationStrategy": {},
            "SpotInstancePools": {
              "type": "integer"
            },
            "SpotMaxPrice": {}
          }
        }
      }
    },
    "S1d": {
      "type": "list",
      "member": {}
    },
    "S1g": {
      "type": "list",
      "member": {}
    },
    "S1q": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "Key"
        ],
        "members": {
          "ResourceId": {},
          "ResourceType": {},
          "Key": {},
          "Value": {},
          "PropagateAtLaunch": {
            "type": "boolean"
          }
        }
      }
    },
    "S1y": {
      "type": "list",
      "member": {}
    },
    "S1z": {
      "type": "list",
      "member": {}
    },
    "S21": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "DeviceName"
        ],
        "members": {
          "VirtualName": {},
          "DeviceName": {},
          "Ebs": {
            "type": "structure",
            "members": {
              "SnapshotId": {},
              "VolumeSize": {
                "type": "integer"
              },
              "VolumeType": {},
              "DeleteOnTermination": {
                "type": "boolean"
              },
              "Iops": {
                "type": "integer"
              },
              "Encrypted": {
                "type": "boolean"
              },
              "Throughput": {
                "type": "integer"
              }
            }
          },
          "NoDevice": {
            "type": "boolean"
          }
        }
      }
    },
    "S2b": {
      "type": "structure",
      "members": {
        "Enabled": {
          "type": "boolean"
        }
      }
    },
    "S2g": {
      "type": "structure",
      "members": {
        "HttpTokens": {},
        "HttpPutResponseHopLimit": {
          "type": "integer"
        },
        "HttpEndpoint": {}
      }
    },
    "S35": {
      "type": "list",
      "member": {}
    },
    "S37": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "Name": {},
          "Values": {
            "type": "list",
            "member": {}
          }
        }
      }
    },
    "S3e": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "InstanceId",
          "AvailabilityZone",
          "LifecycleState",
          "HealthStatus",
          "ProtectedFromScaleIn"
        ],
        "members": {
          "InstanceId": {},
          "InstanceType": {},
          "AvailabilityZone": {},
          "LifecycleState": {},
          "HealthStatus": {},
          "LaunchConfigurationName": {},
          "LaunchTemplate": {
            "shape": "S10"
          },
          "ProtectedFromScaleIn": {
            "type": "boolean"
          },
          "WeightedCapacity": {}
        }
      }
    },
    "S3l": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "ResourceId": {},
          "ResourceType": {},
          "Key": {},
          "Value": {},
          "PropagateAtLaunch": {
            "type": "boolean"
          }
        }
      }
    },
    "S3n": {
      "type": "structure",
      "members": {
        "MaxGroupPreparedCapacity": {
          "type": "integer"
        },
        "MinSize": {
          "type": "integer"
        },
        "PoolState": {},
        "Status": {}
      }
    },
    "S3y": {
      "type": "list",
      "member": {}
    },
    "S4a": {
      "type": "structure",
      "members": {
        "MinHealthyPercentage": {
          "type": "integer"
        },
        "InstanceWarmup": {
          "type": "integer"
        },
        "CheckpointPercentages": {
          "type": "list",
          "member": {
            "type": "integer"
          }
        },
        "CheckpointDelay": {
          "type": "integer"
        },
        "SkipMatching": {
          "type": "boolean"
        }
      }
    },
    "S4g": {
      "type": "structure",
      "members": {
        "LaunchTemplate": {
          "shape": "S10"
        },
        "MixedInstancesPolicy": {
          "shape": "S12"
        }
      }
    },
    "S5g": {
      "type": "integer",
      "deprecated": true
    },
    "S5j": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "ScalingAdjustment"
        ],
        "members": {
          "MetricIntervalLowerBound": {
            "type": "double"
          },
          "MetricIntervalUpperBound": {
            "type": "double"
          },
          "ScalingAdjustment": {
            "type": "integer"
          }
        }
      }
    },
    "S5n": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "AlarmName": {},
          "AlarmARN": {}
        }
      }
    },
    "S5p": {
      "type": "structure",
      "required": [
        "TargetValue"
      ],
      "members": {
        "PredefinedMetricSpecification": {
          "type": "structure",
          "required": [
            "PredefinedMetricType"
          ],
          "members": {
            "PredefinedMetricType": {},
            "ResourceLabel": {}
          }
        },
        "CustomizedMetricSpecification": {
          "type": "structure",
          "required": [
            "MetricName",
            "Namespace",
            "Statistic"
          ],
          "members": {
            "MetricName": {},
            "Namespace": {},
            "Dimensions": {
              "type": "list",
              "member": {
                "type": "structure",
                "required": [
                  "Name",
                  "Value"
                ],
                "members": {
                  "Name": {},
                  "Value": {}
                }
              }
            },
            "Statistic": {},
            "Unit": {}
          }
        },
        "TargetValue": {
          "type": "double"
        },
        "DisableScaleIn": {
          "type": "boolean"
        }
      }
    },
    "S63": {
      "type": "structure",
      "required": [
        "MetricSpecifications"
      ],
      "members": {
        "MetricSpecifications": {
          "type": "list",
          "member": {
            "shape": "S65"
          }
        },
        "Mode": {},
        "SchedulingBufferTime": {
          "type": "integer"
        },
        "MaxCapacityBreachBehavior": {},
        "MaxCapacityBuffer": {
          "type": "integer"
        }
      }
    },
    "S65": {
      "type": "structure",
      "required": [
        "TargetValue"
      ],
      "members": {
        "TargetValue": {
          "type": "double"
        },
        "PredefinedMetricPairSpecification": {
          "type": "structure",
          "required": [
            "PredefinedMetricType"
          ],
          "members": {
            "PredefinedMetricType": {},
            "ResourceLabel": {}
          }
        },
        "PredefinedScalingMetricSpecification": {
          "type": "structure",
          "required": [
            "PredefinedMetricType"
          ],
          "members": {
            "PredefinedMetricType": {},
            "ResourceLabel": {}
          }
        },
        "PredefinedLoadMetricSpecification": {
          "type": "structure",
          "required": [
            "PredefinedMetricType"
          ],
          "members": {
            "PredefinedMetricType": {},
            "ResourceLabel": {}
          }
        }
      }
    },
    "S6k": {
      "type": "list",
      "member": {
        "shape": "S6l"
      }
    },
    "S6l": {
      "type": "structure",
      "required": [
        "ActivityId",
        "AutoScalingGroupName",
        "Cause",
        "StartTime",
        "StatusCode"
      ],
      "members": {
        "ActivityId": {},
        "AutoScalingGroupName": {},
        "Description": {},
        "Cause": {},
        "StartTime": {
          "type": "timestamp"
        },
        "EndTime": {
          "type": "timestamp"
        },
        "StatusCode": {},
        "StatusMessage": {},
        "Progress": {
          "type": "integer"
        },
        "Details": {},
        "AutoScalingGroupState": {},
        "AutoScalingGroupARN": {}
      }
    },
    "S79": {
      "type": "list",
      "member": {}
    },
    "S7l": {
      "type": "list",
      "member": {
        "type": "timestamp"
      }
    },
    "S7m": {
      "type": "list",
      "member": {
        "type": "double"
      }
    },
    "S7y": {
      "type": "structure",
      "required": [
        "AutoScalingGroupName"
      ],
      "members": {
        "AutoScalingGroupName": {},
        "ScalingProcesses": {
          "type": "list",
          "member": {}
        }
      }
    }
  }
}