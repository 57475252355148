{
  "pagination": {
    "SearchDevices": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "devices"
    },
    "SearchQuantumTasks": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "quantumTasks"
    }
  }
}
