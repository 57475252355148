import { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import toaster from '~/src/features/toaster/toaster'
import { Intent } from '@blueprintjs/core'
import LoadingScreen from '~/src/app/components/LoadingScreen'
import { useStore } from '~/src/app/store'

const ProtectedContent = ({ children }) => {
  const [isLoading, setLoading] = useState(true)
  const history = useHistory()
  const store = useStore()
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    const recoverSession = async () => {
      try {
        await store.auth.fetchCurrentAuthenticatedUser()
        setLoading(false)
      } catch (e) {
        if (e === 'The user is not authenticated') {
          toaster.show({
            icon: 'lock',
            intent: Intent.WARNING,
            message: e.message || e,
          })
          store.auth.protectedUrl = history.location.pathname
          history.push('/auth/login')
        } else {
          // setLoading(false)
        }
      }
    }
    recoverSession()
  }, [])

  if (isLoading) {
    return <LoadingScreen forced infinite />
  } else {
    return typeof children === 'function' ? children() : <>{children}</>
  }
}

export default ProtectedContent
