{
  "pagination": {
    "ListAggregatedUtterances": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults"
    },
    "ListBotAliases": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults"
    },
    "ListBotLocales": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults"
    },
    "ListBotVersions": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults"
    },
    "ListBots": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults"
    },
    "ListBuiltInIntents": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults"
    },
    "ListBuiltInSlotTypes": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults"
    },
    "ListExports": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults"
    },
    "ListImports": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults"
    },
    "ListIntents": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults"
    },
    "ListSlotTypes": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults"
    },
    "ListSlots": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults"
    }
  }
}
