{
  "version": "2.0",
  "metadata": {
    "apiVersion": "2020-08-15",
    "endpointPrefix": "profile",
    "jsonVersion": "1.1",
    "protocol": "rest-json",
    "serviceAbbreviation": "Customer Profiles",
    "serviceFullName": "Amazon Connect Customer Profiles",
    "serviceId": "Customer Profiles",
    "signatureVersion": "v4",
    "signingName": "profile",
    "uid": "customer-profiles-2020-08-15"
  },
  "operations": {
    "AddProfileKey": {
      "http": {
        "requestUri": "/domains/{DomainName}/profiles/keys"
      },
      "input": {
        "type": "structure",
        "required": [
          "ProfileId",
          "KeyName",
          "Values",
          "DomainName"
        ],
        "members": {
          "ProfileId": {},
          "KeyName": {},
          "Values": {
            "shape": "S4"
          },
          "DomainName": {
            "location": "uri",
            "locationName": "DomainName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "KeyName": {},
          "Values": {
            "shape": "S4"
          }
        }
      }
    },
    "CreateDomain": {
      "http": {
        "requestUri": "/domains/{DomainName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "DomainName",
          "DefaultExpirationDays"
        ],
        "members": {
          "DomainName": {
            "location": "uri",
            "locationName": "DomainName"
          },
          "DefaultExpirationDays": {
            "type": "integer"
          },
          "DefaultEncryptionKey": {},
          "DeadLetterQueueUrl": {},
          "Matching": {
            "shape": "Sb"
          },
          "Tags": {
            "shape": "Sd"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "DomainName",
          "DefaultExpirationDays",
          "CreatedAt",
          "LastUpdatedAt"
        ],
        "members": {
          "DomainName": {},
          "DefaultExpirationDays": {
            "type": "integer"
          },
          "DefaultEncryptionKey": {},
          "DeadLetterQueueUrl": {},
          "Matching": {
            "shape": "Sh"
          },
          "CreatedAt": {
            "type": "timestamp"
          },
          "LastUpdatedAt": {
            "type": "timestamp"
          },
          "Tags": {
            "shape": "Sd"
          }
        }
      }
    },
    "CreateProfile": {
      "http": {
        "requestUri": "/domains/{DomainName}/profiles"
      },
      "input": {
        "type": "structure",
        "required": [
          "DomainName"
        ],
        "members": {
          "DomainName": {
            "location": "uri",
            "locationName": "DomainName"
          },
          "AccountNumber": {},
          "AdditionalInformation": {},
          "PartyType": {},
          "BusinessName": {},
          "FirstName": {},
          "MiddleName": {},
          "LastName": {},
          "BirthDate": {},
          "Gender": {},
          "PhoneNumber": {},
          "MobilePhoneNumber": {},
          "HomePhoneNumber": {},
          "BusinessPhoneNumber": {},
          "EmailAddress": {},
          "PersonalEmailAddress": {},
          "BusinessEmailAddress": {},
          "Address": {
            "shape": "Sn"
          },
          "ShippingAddress": {
            "shape": "Sn"
          },
          "MailingAddress": {
            "shape": "Sn"
          },
          "BillingAddress": {
            "shape": "Sn"
          },
          "Attributes": {
            "shape": "So"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "ProfileId"
        ],
        "members": {
          "ProfileId": {}
        }
      }
    },
    "DeleteDomain": {
      "http": {
        "method": "DELETE",
        "requestUri": "/domains/{DomainName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "DomainName"
        ],
        "members": {
          "DomainName": {
            "location": "uri",
            "locationName": "DomainName"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "Message"
        ],
        "members": {
          "Message": {}
        }
      }
    },
    "DeleteIntegration": {
      "http": {
        "requestUri": "/domains/{DomainName}/integrations/delete"
      },
      "input": {
        "type": "structure",
        "required": [
          "DomainName",
          "Uri"
        ],
        "members": {
          "DomainName": {
            "location": "uri",
            "locationName": "DomainName"
          },
          "Uri": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "Message"
        ],
        "members": {
          "Message": {}
        }
      }
    },
    "DeleteProfile": {
      "http": {
        "requestUri": "/domains/{DomainName}/profiles/delete"
      },
      "input": {
        "type": "structure",
        "required": [
          "ProfileId",
          "DomainName"
        ],
        "members": {
          "ProfileId": {},
          "DomainName": {
            "location": "uri",
            "locationName": "DomainName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Message": {}
        }
      }
    },
    "DeleteProfileKey": {
      "http": {
        "requestUri": "/domains/{DomainName}/profiles/keys/delete"
      },
      "input": {
        "type": "structure",
        "required": [
          "ProfileId",
          "KeyName",
          "Values",
          "DomainName"
        ],
        "members": {
          "ProfileId": {},
          "KeyName": {},
          "Values": {
            "shape": "S4"
          },
          "DomainName": {
            "location": "uri",
            "locationName": "DomainName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Message": {}
        }
      }
    },
    "DeleteProfileObject": {
      "http": {
        "requestUri": "/domains/{DomainName}/profiles/objects/delete"
      },
      "input": {
        "type": "structure",
        "required": [
          "ProfileId",
          "ProfileObjectUniqueKey",
          "ObjectTypeName",
          "DomainName"
        ],
        "members": {
          "ProfileId": {},
          "ProfileObjectUniqueKey": {},
          "ObjectTypeName": {},
          "DomainName": {
            "location": "uri",
            "locationName": "DomainName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Message": {}
        }
      }
    },
    "DeleteProfileObjectType": {
      "http": {
        "method": "DELETE",
        "requestUri": "/domains/{DomainName}/object-types/{ObjectTypeName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "DomainName",
          "ObjectTypeName"
        ],
        "members": {
          "DomainName": {
            "location": "uri",
            "locationName": "DomainName"
          },
          "ObjectTypeName": {
            "location": "uri",
            "locationName": "ObjectTypeName"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "Message"
        ],
        "members": {
          "Message": {}
        }
      }
    },
    "GetDomain": {
      "http": {
        "method": "GET",
        "requestUri": "/domains/{DomainName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "DomainName"
        ],
        "members": {
          "DomainName": {
            "location": "uri",
            "locationName": "DomainName"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "DomainName",
          "CreatedAt",
          "LastUpdatedAt"
        ],
        "members": {
          "DomainName": {},
          "DefaultExpirationDays": {
            "type": "integer"
          },
          "DefaultEncryptionKey": {},
          "DeadLetterQueueUrl": {},
          "Stats": {
            "type": "structure",
            "members": {
              "ProfileCount": {
                "type": "long"
              },
              "MeteringProfileCount": {
                "type": "long"
              },
              "ObjectCount": {
                "type": "long"
              },
              "TotalSize": {
                "type": "long"
              }
            }
          },
          "Matching": {
            "shape": "Sh"
          },
          "CreatedAt": {
            "type": "timestamp"
          },
          "LastUpdatedAt": {
            "type": "timestamp"
          },
          "Tags": {
            "shape": "Sd"
          }
        }
      }
    },
    "GetIntegration": {
      "http": {
        "requestUri": "/domains/{DomainName}/integrations"
      },
      "input": {
        "type": "structure",
        "required": [
          "DomainName",
          "Uri"
        ],
        "members": {
          "DomainName": {
            "location": "uri",
            "locationName": "DomainName"
          },
          "Uri": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "DomainName",
          "Uri",
          "ObjectTypeName",
          "CreatedAt",
          "LastUpdatedAt"
        ],
        "members": {
          "DomainName": {},
          "Uri": {},
          "ObjectTypeName": {},
          "CreatedAt": {
            "type": "timestamp"
          },
          "LastUpdatedAt": {
            "type": "timestamp"
          },
          "Tags": {
            "shape": "Sd"
          }
        }
      }
    },
    "GetMatches": {
      "http": {
        "method": "GET",
        "requestUri": "/domains/{DomainName}/matches"
      },
      "input": {
        "type": "structure",
        "required": [
          "DomainName"
        ],
        "members": {
          "NextToken": {
            "location": "querystring",
            "locationName": "next-token"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "max-results",
            "type": "integer"
          },
          "DomainName": {
            "location": "uri",
            "locationName": "DomainName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "MatchGenerationDate": {
            "type": "timestamp"
          },
          "PotentialMatches": {
            "type": "integer"
          },
          "Matches": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "MatchId": {},
                "ProfileIds": {
                  "type": "list",
                  "member": {}
                }
              }
            }
          }
        }
      }
    },
    "GetProfileObjectType": {
      "http": {
        "method": "GET",
        "requestUri": "/domains/{DomainName}/object-types/{ObjectTypeName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "DomainName",
          "ObjectTypeName"
        ],
        "members": {
          "DomainName": {
            "location": "uri",
            "locationName": "DomainName"
          },
          "ObjectTypeName": {
            "location": "uri",
            "locationName": "ObjectTypeName"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "ObjectTypeName",
          "Description"
        ],
        "members": {
          "ObjectTypeName": {},
          "Description": {},
          "TemplateId": {},
          "ExpirationDays": {
            "type": "integer"
          },
          "EncryptionKey": {},
          "AllowProfileCreation": {
            "type": "boolean"
          },
          "Fields": {
            "shape": "S1m"
          },
          "Keys": {
            "shape": "S1p"
          },
          "CreatedAt": {
            "type": "timestamp"
          },
          "LastUpdatedAt": {
            "type": "timestamp"
          },
          "Tags": {
            "shape": "Sd"
          }
        }
      }
    },
    "GetProfileObjectTypeTemplate": {
      "http": {
        "method": "GET",
        "requestUri": "/templates/{TemplateId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "TemplateId"
        ],
        "members": {
          "TemplateId": {
            "location": "uri",
            "locationName": "TemplateId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TemplateId": {},
          "SourceName": {},
          "SourceObject": {},
          "AllowProfileCreation": {
            "type": "boolean"
          },
          "Fields": {
            "shape": "S1m"
          },
          "Keys": {
            "shape": "S1p"
          }
        }
      }
    },
    "ListAccountIntegrations": {
      "http": {
        "requestUri": "/integrations"
      },
      "input": {
        "type": "structure",
        "required": [
          "Uri"
        ],
        "members": {
          "Uri": {},
          "NextToken": {
            "location": "querystring",
            "locationName": "next-token"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "max-results",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Items": {
            "shape": "S1z"
          },
          "NextToken": {}
        }
      }
    },
    "ListDomains": {
      "http": {
        "method": "GET",
        "requestUri": "/domains"
      },
      "input": {
        "type": "structure",
        "members": {
          "NextToken": {
            "location": "querystring",
            "locationName": "next-token"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "max-results",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Items": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "DomainName",
                "CreatedAt",
                "LastUpdatedAt"
              ],
              "members": {
                "DomainName": {},
                "CreatedAt": {
                  "type": "timestamp"
                },
                "LastUpdatedAt": {
                  "type": "timestamp"
                },
                "Tags": {
                  "shape": "Sd"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListIntegrations": {
      "http": {
        "method": "GET",
        "requestUri": "/domains/{DomainName}/integrations"
      },
      "input": {
        "type": "structure",
        "required": [
          "DomainName"
        ],
        "members": {
          "DomainName": {
            "location": "uri",
            "locationName": "DomainName"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "next-token"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "max-results",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Items": {
            "shape": "S1z"
          },
          "NextToken": {}
        }
      }
    },
    "ListProfileObjectTypeTemplates": {
      "http": {
        "method": "GET",
        "requestUri": "/templates"
      },
      "input": {
        "type": "structure",
        "members": {
          "NextToken": {
            "location": "querystring",
            "locationName": "next-token"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "max-results",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Items": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "TemplateId": {},
                "SourceName": {},
                "SourceObject": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListProfileObjectTypes": {
      "http": {
        "method": "GET",
        "requestUri": "/domains/{DomainName}/object-types"
      },
      "input": {
        "type": "structure",
        "required": [
          "DomainName"
        ],
        "members": {
          "DomainName": {
            "location": "uri",
            "locationName": "DomainName"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "next-token"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "max-results",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Items": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "ObjectTypeName",
                "Description"
              ],
              "members": {
                "ObjectTypeName": {},
                "Description": {},
                "CreatedAt": {
                  "type": "timestamp"
                },
                "LastUpdatedAt": {
                  "type": "timestamp"
                },
                "Tags": {
                  "shape": "Sd"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListProfileObjects": {
      "http": {
        "requestUri": "/domains/{DomainName}/profiles/objects"
      },
      "input": {
        "type": "structure",
        "required": [
          "DomainName",
          "ObjectTypeName",
          "ProfileId"
        ],
        "members": {
          "NextToken": {
            "location": "querystring",
            "locationName": "next-token"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "max-results",
            "type": "integer"
          },
          "DomainName": {
            "location": "uri",
            "locationName": "DomainName"
          },
          "ObjectTypeName": {},
          "ProfileId": {},
          "ObjectFilter": {
            "type": "structure",
            "required": [
              "KeyName",
              "Values"
            ],
            "members": {
              "KeyName": {},
              "Values": {
                "shape": "S4"
              }
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Items": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "ObjectTypeName": {},
                "ProfileObjectUniqueKey": {},
                "Object": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListTagsForResource": {
      "http": {
        "method": "GET",
        "requestUri": "/tags/{resourceArn}"
      },
      "input": {
        "type": "structure",
        "required": [
          "resourceArn"
        ],
        "members": {
          "resourceArn": {
            "location": "uri",
            "locationName": "resourceArn"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "tags": {
            "shape": "Sd"
          }
        }
      }
    },
    "MergeProfiles": {
      "http": {
        "requestUri": "/domains/{DomainName}/profiles/objects/merge"
      },
      "input": {
        "type": "structure",
        "required": [
          "DomainName",
          "MainProfileId",
          "ProfileIdsToBeMerged"
        ],
        "members": {
          "DomainName": {
            "location": "uri",
            "locationName": "DomainName"
          },
          "MainProfileId": {},
          "ProfileIdsToBeMerged": {
            "type": "list",
            "member": {}
          },
          "FieldSourceProfileIds": {
            "type": "structure",
            "members": {
              "AccountNumber": {},
              "AdditionalInformation": {},
              "PartyType": {},
              "BusinessName": {},
              "FirstName": {},
              "MiddleName": {},
              "LastName": {},
              "BirthDate": {},
              "Gender": {},
              "PhoneNumber": {},
              "MobilePhoneNumber": {},
              "HomePhoneNumber": {},
              "BusinessPhoneNumber": {},
              "EmailAddress": {},
              "PersonalEmailAddress": {},
              "BusinessEmailAddress": {},
              "Address": {},
              "ShippingAddress": {},
              "MailingAddress": {},
              "BillingAddress": {},
              "Attributes": {
                "type": "map",
                "key": {},
                "value": {}
              }
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Message": {}
        }
      }
    },
    "PutIntegration": {
      "http": {
        "method": "PUT",
        "requestUri": "/domains/{DomainName}/integrations"
      },
      "input": {
        "type": "structure",
        "required": [
          "DomainName",
          "ObjectTypeName"
        ],
        "members": {
          "DomainName": {
            "location": "uri",
            "locationName": "DomainName"
          },
          "Uri": {},
          "ObjectTypeName": {},
          "Tags": {
            "shape": "Sd"
          },
          "FlowDefinition": {
            "type": "structure",
            "required": [
              "FlowName",
              "KmsArn",
              "SourceFlowConfig",
              "Tasks",
              "TriggerConfig"
            ],
            "members": {
              "Description": {},
              "FlowName": {},
              "KmsArn": {},
              "SourceFlowConfig": {
                "type": "structure",
                "required": [
                  "ConnectorType",
                  "SourceConnectorProperties"
                ],
                "members": {
                  "ConnectorProfileName": {},
                  "ConnectorType": {},
                  "IncrementalPullConfig": {
                    "type": "structure",
                    "members": {
                      "DatetimeTypeFieldName": {}
                    }
                  },
                  "SourceConnectorProperties": {
                    "type": "structure",
                    "members": {
                      "Marketo": {
                        "type": "structure",
                        "required": [
                          "Object"
                        ],
                        "members": {
                          "Object": {}
                        }
                      },
                      "S3": {
                        "type": "structure",
                        "required": [
                          "BucketName"
                        ],
                        "members": {
                          "BucketName": {},
                          "BucketPrefix": {}
                        }
                      },
                      "Salesforce": {
                        "type": "structure",
                        "required": [
                          "Object"
                        ],
                        "members": {
                          "Object": {},
                          "EnableDynamicFieldUpdate": {
                            "type": "boolean"
                          },
                          "IncludeDeletedRecords": {
                            "type": "boolean"
                          }
                        }
                      },
                      "ServiceNow": {
                        "type": "structure",
                        "required": [
                          "Object"
                        ],
                        "members": {
                          "Object": {}
                        }
                      },
                      "Zendesk": {
                        "type": "structure",
                        "required": [
                          "Object"
                        ],
                        "members": {
                          "Object": {}
                        }
                      }
                    }
                  }
                }
              },
              "Tasks": {
                "type": "list",
                "member": {
                  "type": "structure",
                  "required": [
                    "SourceFields",
                    "TaskType"
                  ],
                  "members": {
                    "ConnectorOperator": {
                      "type": "structure",
                      "members": {
                        "Marketo": {},
                        "S3": {},
                        "Salesforce": {},
                        "ServiceNow": {},
                        "Zendesk": {}
                      }
                    },
                    "DestinationField": {},
                    "SourceFields": {
                      "type": "list",
                      "member": {}
                    },
                    "TaskProperties": {
                      "type": "map",
                      "key": {},
                      "value": {}
                    },
                    "TaskType": {}
                  }
                }
              },
              "TriggerConfig": {
                "type": "structure",
                "required": [
                  "TriggerType"
                ],
                "members": {
                  "TriggerType": {},
                  "TriggerProperties": {
                    "type": "structure",
                    "members": {
                      "Scheduled": {
                        "type": "structure",
                        "required": [
                          "ScheduleExpression"
                        ],
                        "members": {
                          "ScheduleExpression": {},
                          "DataPullMode": {},
                          "ScheduleStartTime": {
                            "type": "timestamp"
                          },
                          "ScheduleEndTime": {
                            "type": "timestamp"
                          },
                          "Timezone": {},
                          "ScheduleOffset": {
                            "type": "long"
                          },
                          "FirstExecutionFrom": {
                            "type": "timestamp"
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "DomainName",
          "Uri",
          "ObjectTypeName",
          "CreatedAt",
          "LastUpdatedAt"
        ],
        "members": {
          "DomainName": {},
          "Uri": {},
          "ObjectTypeName": {},
          "CreatedAt": {
            "type": "timestamp"
          },
          "LastUpdatedAt": {
            "type": "timestamp"
          },
          "Tags": {
            "shape": "Sd"
          }
        }
      }
    },
    "PutProfileObject": {
      "http": {
        "method": "PUT",
        "requestUri": "/domains/{DomainName}/profiles/objects"
      },
      "input": {
        "type": "structure",
        "required": [
          "ObjectTypeName",
          "Object",
          "DomainName"
        ],
        "members": {
          "ObjectTypeName": {},
          "Object": {},
          "DomainName": {
            "location": "uri",
            "locationName": "DomainName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ProfileObjectUniqueKey": {}
        }
      }
    },
    "PutProfileObjectType": {
      "http": {
        "method": "PUT",
        "requestUri": "/domains/{DomainName}/object-types/{ObjectTypeName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "DomainName",
          "ObjectTypeName",
          "Description"
        ],
        "members": {
          "DomainName": {
            "location": "uri",
            "locationName": "DomainName"
          },
          "ObjectTypeName": {
            "location": "uri",
            "locationName": "ObjectTypeName"
          },
          "Description": {},
          "TemplateId": {},
          "ExpirationDays": {
            "type": "integer"
          },
          "EncryptionKey": {},
          "AllowProfileCreation": {
            "type": "boolean"
          },
          "Fields": {
            "shape": "S1m"
          },
          "Keys": {
            "shape": "S1p"
          },
          "Tags": {
            "shape": "Sd"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "ObjectTypeName",
          "Description"
        ],
        "members": {
          "ObjectTypeName": {},
          "Description": {},
          "TemplateId": {},
          "ExpirationDays": {
            "type": "integer"
          },
          "EncryptionKey": {},
          "AllowProfileCreation": {
            "type": "boolean"
          },
          "Fields": {
            "shape": "S1m"
          },
          "Keys": {
            "shape": "S1p"
          },
          "CreatedAt": {
            "type": "timestamp"
          },
          "LastUpdatedAt": {
            "type": "timestamp"
          },
          "Tags": {
            "shape": "Sd"
          }
        }
      }
    },
    "SearchProfiles": {
      "http": {
        "requestUri": "/domains/{DomainName}/profiles/search"
      },
      "input": {
        "type": "structure",
        "required": [
          "DomainName",
          "KeyName",
          "Values"
        ],
        "members": {
          "NextToken": {
            "location": "querystring",
            "locationName": "next-token"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "max-results",
            "type": "integer"
          },
          "DomainName": {
            "location": "uri",
            "locationName": "DomainName"
          },
          "KeyName": {},
          "Values": {
            "shape": "S4"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Items": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "ProfileId": {},
                "AccountNumber": {},
                "AdditionalInformation": {},
                "PartyType": {},
                "BusinessName": {},
                "FirstName": {},
                "MiddleName": {},
                "LastName": {},
                "BirthDate": {},
                "Gender": {},
                "PhoneNumber": {},
                "MobilePhoneNumber": {},
                "HomePhoneNumber": {},
                "BusinessPhoneNumber": {},
                "EmailAddress": {},
                "PersonalEmailAddress": {},
                "BusinessEmailAddress": {},
                "Address": {
                  "shape": "Sn"
                },
                "ShippingAddress": {
                  "shape": "Sn"
                },
                "MailingAddress": {
                  "shape": "Sn"
                },
                "BillingAddress": {
                  "shape": "Sn"
                },
                "Attributes": {
                  "shape": "So"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "TagResource": {
      "http": {
        "requestUri": "/tags/{resourceArn}"
      },
      "input": {
        "type": "structure",
        "required": [
          "resourceArn",
          "tags"
        ],
        "members": {
          "resourceArn": {
            "location": "uri",
            "locationName": "resourceArn"
          },
          "tags": {
            "shape": "Sd"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UntagResource": {
      "http": {
        "method": "DELETE",
        "requestUri": "/tags/{resourceArn}"
      },
      "input": {
        "type": "structure",
        "required": [
          "resourceArn",
          "tagKeys"
        ],
        "members": {
          "resourceArn": {
            "location": "uri",
            "locationName": "resourceArn"
          },
          "tagKeys": {
            "location": "querystring",
            "locationName": "tagKeys",
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UpdateDomain": {
      "http": {
        "method": "PUT",
        "requestUri": "/domains/{DomainName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "DomainName"
        ],
        "members": {
          "DomainName": {
            "location": "uri",
            "locationName": "DomainName"
          },
          "DefaultExpirationDays": {
            "type": "integer"
          },
          "DefaultEncryptionKey": {},
          "DeadLetterQueueUrl": {},
          "Matching": {
            "shape": "Sb"
          },
          "Tags": {
            "shape": "Sd"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "DomainName",
          "CreatedAt",
          "LastUpdatedAt"
        ],
        "members": {
          "DomainName": {},
          "DefaultExpirationDays": {
            "type": "integer"
          },
          "DefaultEncryptionKey": {},
          "DeadLetterQueueUrl": {},
          "Matching": {
            "shape": "Sh"
          },
          "CreatedAt": {
            "type": "timestamp"
          },
          "LastUpdatedAt": {
            "type": "timestamp"
          },
          "Tags": {
            "shape": "Sd"
          }
        }
      }
    },
    "UpdateProfile": {
      "http": {
        "method": "PUT",
        "requestUri": "/domains/{DomainName}/profiles"
      },
      "input": {
        "type": "structure",
        "required": [
          "DomainName",
          "ProfileId"
        ],
        "members": {
          "DomainName": {
            "location": "uri",
            "locationName": "DomainName"
          },
          "ProfileId": {},
          "AdditionalInformation": {},
          "AccountNumber": {},
          "PartyType": {},
          "BusinessName": {},
          "FirstName": {},
          "MiddleName": {},
          "LastName": {},
          "BirthDate": {},
          "Gender": {},
          "PhoneNumber": {},
          "MobilePhoneNumber": {},
          "HomePhoneNumber": {},
          "BusinessPhoneNumber": {},
          "EmailAddress": {},
          "PersonalEmailAddress": {},
          "BusinessEmailAddress": {},
          "Address": {
            "shape": "S4j"
          },
          "ShippingAddress": {
            "shape": "S4j"
          },
          "MailingAddress": {
            "shape": "S4j"
          },
          "BillingAddress": {
            "shape": "S4j"
          },
          "Attributes": {
            "type": "map",
            "key": {},
            "value": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "ProfileId"
        ],
        "members": {
          "ProfileId": {}
        }
      }
    }
  },
  "shapes": {
    "S4": {
      "type": "list",
      "member": {}
    },
    "Sb": {
      "type": "structure",
      "required": [
        "Enabled"
      ],
      "members": {
        "Enabled": {
          "type": "boolean"
        }
      }
    },
    "Sd": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "Sh": {
      "type": "structure",
      "members": {
        "Enabled": {
          "type": "boolean"
        }
      }
    },
    "Sn": {
      "type": "structure",
      "members": {
        "Address1": {},
        "Address2": {},
        "Address3": {},
        "Address4": {},
        "City": {},
        "County": {},
        "State": {},
        "Province": {},
        "Country": {},
        "PostalCode": {}
      }
    },
    "So": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "S1m": {
      "type": "map",
      "key": {},
      "value": {
        "type": "structure",
        "members": {
          "Source": {},
          "Target": {},
          "ContentType": {}
        }
      }
    },
    "S1p": {
      "type": "map",
      "key": {},
      "value": {
        "type": "list",
        "member": {
          "type": "structure",
          "members": {
            "StandardIdentifiers": {
              "type": "list",
              "member": {}
            },
            "FieldNames": {
              "type": "list",
              "member": {}
            }
          }
        }
      }
    },
    "S1z": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "DomainName",
          "Uri",
          "ObjectTypeName",
          "CreatedAt",
          "LastUpdatedAt"
        ],
        "members": {
          "DomainName": {},
          "Uri": {},
          "ObjectTypeName": {},
          "CreatedAt": {
            "type": "timestamp"
          },
          "LastUpdatedAt": {
            "type": "timestamp"
          },
          "Tags": {
            "shape": "Sd"
          }
        }
      }
    },
    "S4j": {
      "type": "structure",
      "members": {
        "Address1": {},
        "Address2": {},
        "Address3": {},
        "Address4": {},
        "City": {},
        "County": {},
        "State": {},
        "Province": {},
        "Country": {},
        "PostalCode": {}
      }
    }
  }
}