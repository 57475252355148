{
  "version": "2.0",
  "metadata": {
    "apiVersion": "2018-09-22",
    "endpointPrefix": "codeartifact",
    "jsonVersion": "1.1",
    "protocol": "rest-json",
    "serviceFullName": "CodeArtifact",
    "serviceId": "codeartifact",
    "signatureVersion": "v4",
    "signingName": "codeartifact",
    "uid": "codeartifact-2018-09-22"
  },
  "operations": {
    "AssociateExternalConnection": {
      "http": {
        "requestUri": "/v1/repository/external-connection"
      },
      "input": {
        "type": "structure",
        "required": [
          "domain",
          "repository",
          "externalConnection"
        ],
        "members": {
          "domain": {
            "location": "querystring",
            "locationName": "domain"
          },
          "domainOwner": {
            "location": "querystring",
            "locationName": "domain-owner"
          },
          "repository": {
            "location": "querystring",
            "locationName": "repository"
          },
          "externalConnection": {
            "location": "querystring",
            "locationName": "external-connection"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "repository": {
            "shape": "S7"
          }
        }
      }
    },
    "CopyPackageVersions": {
      "http": {
        "requestUri": "/v1/package/versions/copy"
      },
      "input": {
        "type": "structure",
        "required": [
          "domain",
          "sourceRepository",
          "destinationRepository",
          "format",
          "package"
        ],
        "members": {
          "domain": {
            "location": "querystring",
            "locationName": "domain"
          },
          "domainOwner": {
            "location": "querystring",
            "locationName": "domain-owner"
          },
          "sourceRepository": {
            "location": "querystring",
            "locationName": "source-repository"
          },
          "destinationRepository": {
            "location": "querystring",
            "locationName": "destination-repository"
          },
          "format": {
            "location": "querystring",
            "locationName": "format"
          },
          "namespace": {
            "location": "querystring",
            "locationName": "namespace"
          },
          "package": {
            "location": "querystring",
            "locationName": "package"
          },
          "versions": {
            "shape": "Sj"
          },
          "versionRevisions": {
            "shape": "Sl"
          },
          "allowOverwrite": {
            "type": "boolean"
          },
          "includeFromUpstream": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "successfulVersions": {
            "shape": "Sp"
          },
          "failedVersions": {
            "shape": "St"
          }
        }
      }
    },
    "CreateDomain": {
      "http": {
        "requestUri": "/v1/domain"
      },
      "input": {
        "type": "structure",
        "required": [
          "domain"
        ],
        "members": {
          "domain": {
            "location": "querystring",
            "locationName": "domain"
          },
          "encryptionKey": {},
          "tags": {
            "shape": "Sy"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "domain": {
            "shape": "S13"
          }
        }
      }
    },
    "CreateRepository": {
      "http": {
        "requestUri": "/v1/repository"
      },
      "input": {
        "type": "structure",
        "required": [
          "domain",
          "repository"
        ],
        "members": {
          "domain": {
            "location": "querystring",
            "locationName": "domain"
          },
          "domainOwner": {
            "location": "querystring",
            "locationName": "domain-owner"
          },
          "repository": {
            "location": "querystring",
            "locationName": "repository"
          },
          "description": {},
          "upstreams": {
            "shape": "S19"
          },
          "tags": {
            "shape": "Sy"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "repository": {
            "shape": "S7"
          }
        }
      }
    },
    "DeleteDomain": {
      "http": {
        "method": "DELETE",
        "requestUri": "/v1/domain"
      },
      "input": {
        "type": "structure",
        "required": [
          "domain"
        ],
        "members": {
          "domain": {
            "location": "querystring",
            "locationName": "domain"
          },
          "domainOwner": {
            "location": "querystring",
            "locationName": "domain-owner"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "domain": {
            "shape": "S13"
          }
        }
      }
    },
    "DeleteDomainPermissionsPolicy": {
      "http": {
        "method": "DELETE",
        "requestUri": "/v1/domain/permissions/policy"
      },
      "input": {
        "type": "structure",
        "required": [
          "domain"
        ],
        "members": {
          "domain": {
            "location": "querystring",
            "locationName": "domain"
          },
          "domainOwner": {
            "location": "querystring",
            "locationName": "domain-owner"
          },
          "policyRevision": {
            "location": "querystring",
            "locationName": "policy-revision"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "policy": {
            "shape": "S1h"
          }
        }
      }
    },
    "DeletePackageVersions": {
      "http": {
        "requestUri": "/v1/package/versions/delete"
      },
      "input": {
        "type": "structure",
        "required": [
          "domain",
          "repository",
          "format",
          "package",
          "versions"
        ],
        "members": {
          "domain": {
            "location": "querystring",
            "locationName": "domain"
          },
          "domainOwner": {
            "location": "querystring",
            "locationName": "domain-owner"
          },
          "repository": {
            "location": "querystring",
            "locationName": "repository"
          },
          "format": {
            "location": "querystring",
            "locationName": "format"
          },
          "namespace": {
            "location": "querystring",
            "locationName": "namespace"
          },
          "package": {
            "location": "querystring",
            "locationName": "package"
          },
          "versions": {
            "shape": "Sj"
          },
          "expectedStatus": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "successfulVersions": {
            "shape": "Sp"
          },
          "failedVersions": {
            "shape": "St"
          }
        }
      }
    },
    "DeleteRepository": {
      "http": {
        "method": "DELETE",
        "requestUri": "/v1/repository"
      },
      "input": {
        "type": "structure",
        "required": [
          "domain",
          "repository"
        ],
        "members": {
          "domain": {
            "location": "querystring",
            "locationName": "domain"
          },
          "domainOwner": {
            "location": "querystring",
            "locationName": "domain-owner"
          },
          "repository": {
            "location": "querystring",
            "locationName": "repository"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "repository": {
            "shape": "S7"
          }
        }
      }
    },
    "DeleteRepositoryPermissionsPolicy": {
      "http": {
        "method": "DELETE",
        "requestUri": "/v1/repository/permissions/policies"
      },
      "input": {
        "type": "structure",
        "required": [
          "domain",
          "repository"
        ],
        "members": {
          "domain": {
            "location": "querystring",
            "locationName": "domain"
          },
          "domainOwner": {
            "location": "querystring",
            "locationName": "domain-owner"
          },
          "repository": {
            "location": "querystring",
            "locationName": "repository"
          },
          "policyRevision": {
            "location": "querystring",
            "locationName": "policy-revision"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "policy": {
            "shape": "S1h"
          }
        }
      }
    },
    "DescribeDomain": {
      "http": {
        "method": "GET",
        "requestUri": "/v1/domain"
      },
      "input": {
        "type": "structure",
        "required": [
          "domain"
        ],
        "members": {
          "domain": {
            "location": "querystring",
            "locationName": "domain"
          },
          "domainOwner": {
            "location": "querystring",
            "locationName": "domain-owner"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "domain": {
            "shape": "S13"
          }
        }
      }
    },
    "DescribePackageVersion": {
      "http": {
        "method": "GET",
        "requestUri": "/v1/package/version"
      },
      "input": {
        "type": "structure",
        "required": [
          "domain",
          "repository",
          "format",
          "package",
          "packageVersion"
        ],
        "members": {
          "domain": {
            "location": "querystring",
            "locationName": "domain"
          },
          "domainOwner": {
            "location": "querystring",
            "locationName": "domain-owner"
          },
          "repository": {
            "location": "querystring",
            "locationName": "repository"
          },
          "format": {
            "location": "querystring",
            "locationName": "format"
          },
          "namespace": {
            "location": "querystring",
            "locationName": "namespace"
          },
          "package": {
            "location": "querystring",
            "locationName": "package"
          },
          "packageVersion": {
            "location": "querystring",
            "locationName": "version"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "packageVersion"
        ],
        "members": {
          "packageVersion": {
            "type": "structure",
            "members": {
              "format": {},
              "namespace": {},
              "packageName": {},
              "displayName": {},
              "version": {},
              "summary": {},
              "homePage": {},
              "sourceCodeRepository": {},
              "publishedTime": {
                "type": "timestamp"
              },
              "licenses": {
                "type": "list",
                "member": {
                  "type": "structure",
                  "members": {
                    "name": {},
                    "url": {}
                  }
                }
              },
              "revision": {},
              "status": {}
            }
          }
        }
      }
    },
    "DescribeRepository": {
      "http": {
        "method": "GET",
        "requestUri": "/v1/repository"
      },
      "input": {
        "type": "structure",
        "required": [
          "domain",
          "repository"
        ],
        "members": {
          "domain": {
            "location": "querystring",
            "locationName": "domain"
          },
          "domainOwner": {
            "location": "querystring",
            "locationName": "domain-owner"
          },
          "repository": {
            "location": "querystring",
            "locationName": "repository"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "repository": {
            "shape": "S7"
          }
        }
      }
    },
    "DisassociateExternalConnection": {
      "http": {
        "method": "DELETE",
        "requestUri": "/v1/repository/external-connection"
      },
      "input": {
        "type": "structure",
        "required": [
          "domain",
          "repository",
          "externalConnection"
        ],
        "members": {
          "domain": {
            "location": "querystring",
            "locationName": "domain"
          },
          "domainOwner": {
            "location": "querystring",
            "locationName": "domain-owner"
          },
          "repository": {
            "location": "querystring",
            "locationName": "repository"
          },
          "externalConnection": {
            "location": "querystring",
            "locationName": "external-connection"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "repository": {
            "shape": "S7"
          }
        }
      }
    },
    "DisposePackageVersions": {
      "http": {
        "requestUri": "/v1/package/versions/dispose"
      },
      "input": {
        "type": "structure",
        "required": [
          "domain",
          "repository",
          "format",
          "package",
          "versions"
        ],
        "members": {
          "domain": {
            "location": "querystring",
            "locationName": "domain"
          },
          "domainOwner": {
            "location": "querystring",
            "locationName": "domain-owner"
          },
          "repository": {
            "location": "querystring",
            "locationName": "repository"
          },
          "format": {
            "location": "querystring",
            "locationName": "format"
          },
          "namespace": {
            "location": "querystring",
            "locationName": "namespace"
          },
          "package": {
            "location": "querystring",
            "locationName": "package"
          },
          "versions": {
            "shape": "Sj"
          },
          "versionRevisions": {
            "shape": "Sl"
          },
          "expectedStatus": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "successfulVersions": {
            "shape": "Sp"
          },
          "failedVersions": {
            "shape": "St"
          }
        }
      }
    },
    "GetAuthorizationToken": {
      "http": {
        "requestUri": "/v1/authorization-token"
      },
      "input": {
        "type": "structure",
        "required": [
          "domain"
        ],
        "members": {
          "domain": {
            "location": "querystring",
            "locationName": "domain"
          },
          "domainOwner": {
            "location": "querystring",
            "locationName": "domain-owner"
          },
          "durationSeconds": {
            "location": "querystring",
            "locationName": "duration",
            "type": "long"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "authorizationToken": {},
          "expiration": {
            "type": "timestamp"
          }
        }
      }
    },
    "GetDomainPermissionsPolicy": {
      "http": {
        "method": "GET",
        "requestUri": "/v1/domain/permissions/policy"
      },
      "input": {
        "type": "structure",
        "required": [
          "domain"
        ],
        "members": {
          "domain": {
            "location": "querystring",
            "locationName": "domain"
          },
          "domainOwner": {
            "location": "querystring",
            "locationName": "domain-owner"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "policy": {
            "shape": "S1h"
          }
        }
      }
    },
    "GetPackageVersionAsset": {
      "http": {
        "method": "GET",
        "requestUri": "/v1/package/version/asset"
      },
      "input": {
        "type": "structure",
        "required": [
          "domain",
          "repository",
          "format",
          "package",
          "packageVersion",
          "asset"
        ],
        "members": {
          "domain": {
            "location": "querystring",
            "locationName": "domain"
          },
          "domainOwner": {
            "location": "querystring",
            "locationName": "domain-owner"
          },
          "repository": {
            "location": "querystring",
            "locationName": "repository"
          },
          "format": {
            "location": "querystring",
            "locationName": "format"
          },
          "namespace": {
            "location": "querystring",
            "locationName": "namespace"
          },
          "package": {
            "location": "querystring",
            "locationName": "package"
          },
          "packageVersion": {
            "location": "querystring",
            "locationName": "version"
          },
          "asset": {
            "location": "querystring",
            "locationName": "asset"
          },
          "packageVersionRevision": {
            "location": "querystring",
            "locationName": "revision"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "asset": {
            "type": "blob",
            "streaming": true
          },
          "assetName": {
            "location": "header",
            "locationName": "X-AssetName"
          },
          "packageVersion": {
            "location": "header",
            "locationName": "X-PackageVersion"
          },
          "packageVersionRevision": {
            "location": "header",
            "locationName": "X-PackageVersionRevision"
          }
        },
        "payload": "asset"
      }
    },
    "GetPackageVersionReadme": {
      "http": {
        "method": "GET",
        "requestUri": "/v1/package/version/readme"
      },
      "input": {
        "type": "structure",
        "required": [
          "domain",
          "repository",
          "format",
          "package",
          "packageVersion"
        ],
        "members": {
          "domain": {
            "location": "querystring",
            "locationName": "domain"
          },
          "domainOwner": {
            "location": "querystring",
            "locationName": "domain-owner"
          },
          "repository": {
            "location": "querystring",
            "locationName": "repository"
          },
          "format": {
            "location": "querystring",
            "locationName": "format"
          },
          "namespace": {
            "location": "querystring",
            "locationName": "namespace"
          },
          "package": {
            "location": "querystring",
            "locationName": "package"
          },
          "packageVersion": {
            "location": "querystring",
            "locationName": "version"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "format": {},
          "namespace": {},
          "package": {},
          "version": {},
          "versionRevision": {},
          "readme": {}
        }
      }
    },
    "GetRepositoryEndpoint": {
      "http": {
        "method": "GET",
        "requestUri": "/v1/repository/endpoint"
      },
      "input": {
        "type": "structure",
        "required": [
          "domain",
          "repository",
          "format"
        ],
        "members": {
          "domain": {
            "location": "querystring",
            "locationName": "domain"
          },
          "domainOwner": {
            "location": "querystring",
            "locationName": "domain-owner"
          },
          "repository": {
            "location": "querystring",
            "locationName": "repository"
          },
          "format": {
            "location": "querystring",
            "locationName": "format"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "repositoryEndpoint": {}
        }
      }
    },
    "GetRepositoryPermissionsPolicy": {
      "http": {
        "method": "GET",
        "requestUri": "/v1/repository/permissions/policy"
      },
      "input": {
        "type": "structure",
        "required": [
          "domain",
          "repository"
        ],
        "members": {
          "domain": {
            "location": "querystring",
            "locationName": "domain"
          },
          "domainOwner": {
            "location": "querystring",
            "locationName": "domain-owner"
          },
          "repository": {
            "location": "querystring",
            "locationName": "repository"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "policy": {
            "shape": "S1h"
          }
        }
      }
    },
    "ListDomains": {
      "http": {
        "requestUri": "/v1/domains"
      },
      "input": {
        "type": "structure",
        "members": {
          "maxResults": {
            "type": "integer"
          },
          "nextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "domains": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "name": {},
                "owner": {},
                "arn": {},
                "status": {},
                "createdTime": {
                  "type": "timestamp"
                },
                "encryptionKey": {}
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListPackageVersionAssets": {
      "http": {
        "requestUri": "/v1/package/version/assets"
      },
      "input": {
        "type": "structure",
        "required": [
          "domain",
          "repository",
          "format",
          "package",
          "packageVersion"
        ],
        "members": {
          "domain": {
            "location": "querystring",
            "locationName": "domain"
          },
          "domainOwner": {
            "location": "querystring",
            "locationName": "domain-owner"
          },
          "repository": {
            "location": "querystring",
            "locationName": "repository"
          },
          "format": {
            "location": "querystring",
            "locationName": "format"
          },
          "namespace": {
            "location": "querystring",
            "locationName": "namespace"
          },
          "package": {
            "location": "querystring",
            "locationName": "package"
          },
          "packageVersion": {
            "location": "querystring",
            "locationName": "version"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "max-results",
            "type": "integer"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "next-token"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "format": {},
          "namespace": {},
          "package": {},
          "version": {},
          "versionRevision": {},
          "nextToken": {},
          "assets": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "name"
              ],
              "members": {
                "name": {},
                "size": {
                  "type": "long"
                },
                "hashes": {
                  "type": "map",
                  "key": {},
                  "value": {}
                }
              }
            }
          }
        }
      }
    },
    "ListPackageVersionDependencies": {
      "http": {
        "requestUri": "/v1/package/version/dependencies"
      },
      "input": {
        "type": "structure",
        "required": [
          "domain",
          "repository",
          "format",
          "package",
          "packageVersion"
        ],
        "members": {
          "domain": {
            "location": "querystring",
            "locationName": "domain"
          },
          "domainOwner": {
            "location": "querystring",
            "locationName": "domain-owner"
          },
          "repository": {
            "location": "querystring",
            "locationName": "repository"
          },
          "format": {
            "location": "querystring",
            "locationName": "format"
          },
          "namespace": {
            "location": "querystring",
            "locationName": "namespace"
          },
          "package": {
            "location": "querystring",
            "locationName": "package"
          },
          "packageVersion": {
            "location": "querystring",
            "locationName": "version"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "next-token"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "format": {},
          "namespace": {},
          "package": {},
          "version": {},
          "versionRevision": {},
          "nextToken": {},
          "dependencies": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "namespace": {},
                "package": {},
                "dependencyType": {},
                "versionRequirement": {}
              }
            }
          }
        }
      }
    },
    "ListPackageVersions": {
      "http": {
        "requestUri": "/v1/package/versions"
      },
      "input": {
        "type": "structure",
        "required": [
          "domain",
          "repository",
          "format",
          "package"
        ],
        "members": {
          "domain": {
            "location": "querystring",
            "locationName": "domain"
          },
          "domainOwner": {
            "location": "querystring",
            "locationName": "domain-owner"
          },
          "repository": {
            "location": "querystring",
            "locationName": "repository"
          },
          "format": {
            "location": "querystring",
            "locationName": "format"
          },
          "namespace": {
            "location": "querystring",
            "locationName": "namespace"
          },
          "package": {
            "location": "querystring",
            "locationName": "package"
          },
          "status": {
            "location": "querystring",
            "locationName": "status"
          },
          "sortBy": {
            "location": "querystring",
            "locationName": "sortBy"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "max-results",
            "type": "integer"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "next-token"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "defaultDisplayVersion": {},
          "format": {},
          "namespace": {},
          "package": {},
          "versions": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "version",
                "status"
              ],
              "members": {
                "version": {},
                "revision": {},
                "status": {}
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListPackages": {
      "http": {
        "requestUri": "/v1/packages"
      },
      "input": {
        "type": "structure",
        "required": [
          "domain",
          "repository"
        ],
        "members": {
          "domain": {
            "location": "querystring",
            "locationName": "domain"
          },
          "domainOwner": {
            "location": "querystring",
            "locationName": "domain-owner"
          },
          "repository": {
            "location": "querystring",
            "locationName": "repository"
          },
          "format": {
            "location": "querystring",
            "locationName": "format"
          },
          "namespace": {
            "location": "querystring",
            "locationName": "namespace"
          },
          "packagePrefix": {
            "location": "querystring",
            "locationName": "package-prefix"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "max-results",
            "type": "integer"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "next-token"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "packages": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "format": {},
                "namespace": {},
                "package": {}
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListRepositories": {
      "http": {
        "requestUri": "/v1/repositories"
      },
      "input": {
        "type": "structure",
        "members": {
          "repositoryPrefix": {
            "location": "querystring",
            "locationName": "repository-prefix"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "max-results",
            "type": "integer"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "next-token"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "repositories": {
            "shape": "S3f"
          },
          "nextToken": {}
        }
      }
    },
    "ListRepositoriesInDomain": {
      "http": {
        "requestUri": "/v1/domain/repositories"
      },
      "input": {
        "type": "structure",
        "required": [
          "domain"
        ],
        "members": {
          "domain": {
            "location": "querystring",
            "locationName": "domain"
          },
          "domainOwner": {
            "location": "querystring",
            "locationName": "domain-owner"
          },
          "administratorAccount": {
            "location": "querystring",
            "locationName": "administrator-account"
          },
          "repositoryPrefix": {
            "location": "querystring",
            "locationName": "repository-prefix"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "max-results",
            "type": "integer"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "next-token"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "repositories": {
            "shape": "S3f"
          },
          "nextToken": {}
        }
      }
    },
    "ListTagsForResource": {
      "http": {
        "requestUri": "/v1/tags"
      },
      "input": {
        "type": "structure",
        "required": [
          "resourceArn"
        ],
        "members": {
          "resourceArn": {
            "location": "querystring",
            "locationName": "resourceArn"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "tags": {
            "shape": "Sy"
          }
        }
      }
    },
    "PutDomainPermissionsPolicy": {
      "http": {
        "method": "PUT",
        "requestUri": "/v1/domain/permissions/policy"
      },
      "input": {
        "type": "structure",
        "required": [
          "domain",
          "policyDocument"
        ],
        "members": {
          "domain": {},
          "domainOwner": {},
          "policyRevision": {},
          "policyDocument": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "policy": {
            "shape": "S1h"
          }
        }
      }
    },
    "PutRepositoryPermissionsPolicy": {
      "http": {
        "method": "PUT",
        "requestUri": "/v1/repository/permissions/policy"
      },
      "input": {
        "type": "structure",
        "required": [
          "domain",
          "repository",
          "policyDocument"
        ],
        "members": {
          "domain": {
            "location": "querystring",
            "locationName": "domain"
          },
          "domainOwner": {
            "location": "querystring",
            "locationName": "domain-owner"
          },
          "repository": {
            "location": "querystring",
            "locationName": "repository"
          },
          "policyRevision": {},
          "policyDocument": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "policy": {
            "shape": "S1h"
          }
        }
      }
    },
    "TagResource": {
      "http": {
        "requestUri": "/v1/tag"
      },
      "input": {
        "type": "structure",
        "required": [
          "resourceArn",
          "tags"
        ],
        "members": {
          "resourceArn": {
            "location": "querystring",
            "locationName": "resourceArn"
          },
          "tags": {
            "shape": "Sy"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UntagResource": {
      "http": {
        "requestUri": "/v1/untag"
      },
      "input": {
        "type": "structure",
        "required": [
          "resourceArn",
          "tagKeys"
        ],
        "members": {
          "resourceArn": {
            "location": "querystring",
            "locationName": "resourceArn"
          },
          "tagKeys": {
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UpdatePackageVersionsStatus": {
      "http": {
        "requestUri": "/v1/package/versions/update_status"
      },
      "input": {
        "type": "structure",
        "required": [
          "domain",
          "repository",
          "format",
          "package",
          "versions",
          "targetStatus"
        ],
        "members": {
          "domain": {
            "location": "querystring",
            "locationName": "domain"
          },
          "domainOwner": {
            "location": "querystring",
            "locationName": "domain-owner"
          },
          "repository": {
            "location": "querystring",
            "locationName": "repository"
          },
          "format": {
            "location": "querystring",
            "locationName": "format"
          },
          "namespace": {
            "location": "querystring",
            "locationName": "namespace"
          },
          "package": {
            "location": "querystring",
            "locationName": "package"
          },
          "versions": {
            "shape": "Sj"
          },
          "versionRevisions": {
            "shape": "Sl"
          },
          "expectedStatus": {},
          "targetStatus": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "successfulVersions": {
            "shape": "Sp"
          },
          "failedVersions": {
            "shape": "St"
          }
        }
      }
    },
    "UpdateRepository": {
      "http": {
        "method": "PUT",
        "requestUri": "/v1/repository"
      },
      "input": {
        "type": "structure",
        "required": [
          "domain",
          "repository"
        ],
        "members": {
          "domain": {
            "location": "querystring",
            "locationName": "domain"
          },
          "domainOwner": {
            "location": "querystring",
            "locationName": "domain-owner"
          },
          "repository": {
            "location": "querystring",
            "locationName": "repository"
          },
          "description": {},
          "upstreams": {
            "shape": "S19"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "repository": {
            "shape": "S7"
          }
        }
      }
    }
  },
  "shapes": {
    "S7": {
      "type": "structure",
      "members": {
        "name": {},
        "administratorAccount": {},
        "domainName": {},
        "domainOwner": {},
        "arn": {},
        "description": {},
        "upstreams": {
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "repositoryName": {}
            }
          }
        },
        "externalConnections": {
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "externalConnectionName": {},
              "packageFormat": {},
              "status": {}
            }
          }
        }
      }
    },
    "Sj": {
      "type": "list",
      "member": {}
    },
    "Sl": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "Sp": {
      "type": "map",
      "key": {},
      "value": {
        "type": "structure",
        "members": {
          "revision": {},
          "status": {}
        }
      }
    },
    "St": {
      "type": "map",
      "key": {},
      "value": {
        "type": "structure",
        "members": {
          "errorCode": {},
          "errorMessage": {}
        }
      }
    },
    "Sy": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "key",
          "value"
        ],
        "members": {
          "key": {},
          "value": {}
        }
      }
    },
    "S13": {
      "type": "structure",
      "members": {
        "name": {},
        "owner": {},
        "arn": {},
        "status": {},
        "createdTime": {
          "type": "timestamp"
        },
        "encryptionKey": {},
        "repositoryCount": {
          "type": "integer"
        },
        "assetSizeBytes": {
          "type": "long"
        },
        "s3BucketArn": {}
      }
    },
    "S19": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "repositoryName"
        ],
        "members": {
          "repositoryName": {}
        }
      }
    },
    "S1h": {
      "type": "structure",
      "members": {
        "resourceArn": {},
        "revision": {},
        "document": {}
      }
    },
    "S3f": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "name": {},
          "administratorAccount": {},
          "domainName": {},
          "domainOwner": {},
          "arn": {},
          "description": {}
        }
      }
    }
  }
}