{
  "pagination": {
    "ListAliases": {
      "input_token": "Marker",
      "limit_key": "MaxItems",
      "output_token": "NextMarker",
      "result_key": "Aliases"
    },
    "ListCodeSigningConfigs": {
      "input_token": "Marker",
      "limit_key": "MaxItems",
      "output_token": "NextMarker",
      "result_key": "CodeSigningConfigs"
    },
    "ListEventSourceMappings": {
      "input_token": "Marker",
      "limit_key": "MaxItems",
      "output_token": "NextMarker",
      "result_key": "EventSourceMappings"
    },
    "ListFunctionEventInvokeConfigs": {
      "input_token": "Marker",
      "limit_key": "MaxItems",
      "output_token": "NextMarker",
      "result_key": "FunctionEventInvokeConfigs"
    },
    "ListFunctions": {
      "input_token": "Marker",
      "limit_key": "MaxItems",
      "output_token": "NextMarker",
      "result_key": "Functions"
    },
    "ListFunctionsByCodeSigningConfig": {
      "input_token": "Marker",
      "limit_key": "MaxItems",
      "output_token": "NextMarker",
      "result_key": "FunctionArns"
    },
    "ListLayerVersions": {
      "input_token": "Marker",
      "limit_key": "MaxItems",
      "output_token": "NextMarker",
      "result_key": "LayerVersions"
    },
    "ListLayers": {
      "input_token": "Marker",
      "limit_key": "MaxItems",
      "output_token": "NextMarker",
      "result_key": "Layers"
    },
    "ListProvisionedConcurrencyConfigs": {
      "input_token": "Marker",
      "limit_key": "MaxItems",
      "output_token": "NextMarker",
      "result_key": "ProvisionedConcurrencyConfigs"
    },
    "ListVersionsByFunction": {
      "input_token": "Marker",
      "limit_key": "MaxItems",
      "output_token": "NextMarker",
      "result_key": "Versions"
    }
  }
}