{
  "version": "2.0",
  "metadata": {
    "apiVersion": "2019-02-03",
    "endpointPrefix": "kendra",
    "jsonVersion": "1.1",
    "protocol": "json",
    "serviceAbbreviation": "kendra",
    "serviceFullName": "AWSKendraFrontendService",
    "serviceId": "kendra",
    "signatureVersion": "v4",
    "signingName": "kendra",
    "targetPrefix": "AWSKendraFrontendService",
    "uid": "kendra-2019-02-03"
  },
  "operations": {
    "BatchDeleteDocument": {
      "input": {
        "type": "structure",
        "required": [
          "IndexId",
          "DocumentIdList"
        ],
        "members": {
          "IndexId": {},
          "DocumentIdList": {
            "type": "list",
            "member": {}
          },
          "DataSourceSyncJobMetricTarget": {
            "type": "structure",
            "required": [
              "DataSourceId"
            ],
            "members": {
              "DataSourceId": {},
              "DataSourceSyncJobId": {}
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "FailedDocuments": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Id": {},
                "ErrorCode": {},
                "ErrorMessage": {}
              }
            }
          }
        }
      }
    },
    "BatchGetDocumentStatus": {
      "input": {
        "type": "structure",
        "required": [
          "IndexId",
          "DocumentInfoList"
        ],
        "members": {
          "IndexId": {},
          "DocumentInfoList": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "DocumentId"
              ],
              "members": {
                "DocumentId": {},
                "Attributes": {
                  "shape": "Sg"
                }
              }
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Errors": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "DocumentId": {},
                "ErrorCode": {},
                "ErrorMessage": {}
              }
            }
          },
          "DocumentStatusList": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "DocumentId": {},
                "DocumentStatus": {},
                "FailureCode": {},
                "FailureReason": {}
              }
            }
          }
        }
      }
    },
    "BatchPutDocument": {
      "input": {
        "type": "structure",
        "required": [
          "IndexId",
          "Documents"
        ],
        "members": {
          "IndexId": {},
          "RoleArn": {},
          "Documents": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "Id"
              ],
              "members": {
                "Id": {},
                "Title": {},
                "Blob": {
                  "type": "blob"
                },
                "S3Path": {
                  "shape": "S11"
                },
                "Attributes": {
                  "shape": "Sg"
                },
                "AccessControlList": {
                  "shape": "S14"
                },
                "HierarchicalAccessControlList": {
                  "type": "list",
                  "member": {
                    "type": "structure",
                    "required": [
                      "PrincipalList"
                    ],
                    "members": {
                      "PrincipalList": {
                        "shape": "S14"
                      }
                    }
                  }
                },
                "ContentType": {}
              }
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "FailedDocuments": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Id": {},
                "ErrorCode": {},
                "ErrorMessage": {}
              }
            }
          }
        }
      }
    },
    "ClearQuerySuggestions": {
      "input": {
        "type": "structure",
        "required": [
          "IndexId"
        ],
        "members": {
          "IndexId": {}
        }
      }
    },
    "CreateDataSource": {
      "input": {
        "type": "structure",
        "required": [
          "Name",
          "IndexId",
          "Type"
        ],
        "members": {
          "Name": {},
          "IndexId": {},
          "Type": {},
          "Configuration": {
            "shape": "S1j"
          },
          "Description": {},
          "Schedule": {},
          "RoleArn": {},
          "Tags": {
            "shape": "S4n"
          },
          "ClientToken": {
            "idempotencyToken": true
          },
          "LanguageCode": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "Id"
        ],
        "members": {
          "Id": {}
        }
      }
    },
    "CreateFaq": {
      "input": {
        "type": "structure",
        "required": [
          "IndexId",
          "Name",
          "S3Path",
          "RoleArn"
        ],
        "members": {
          "IndexId": {},
          "Name": {},
          "Description": {},
          "S3Path": {
            "shape": "S11"
          },
          "RoleArn": {},
          "Tags": {
            "shape": "S4n"
          },
          "FileFormat": {},
          "ClientToken": {
            "idempotencyToken": true
          },
          "LanguageCode": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Id": {}
        }
      }
    },
    "CreateIndex": {
      "input": {
        "type": "structure",
        "required": [
          "Name",
          "RoleArn"
        ],
        "members": {
          "Name": {},
          "Edition": {},
          "RoleArn": {},
          "ServerSideEncryptionConfiguration": {
            "shape": "S52"
          },
          "Description": {},
          "ClientToken": {
            "idempotencyToken": true
          },
          "Tags": {
            "shape": "S4n"
          },
          "UserTokenConfigurations": {
            "shape": "S54"
          },
          "UserContextPolicy": {},
          "UserGroupResolutionConfiguration": {
            "shape": "S5e"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Id": {}
        }
      }
    },
    "CreateQuerySuggestionsBlockList": {
      "input": {
        "type": "structure",
        "required": [
          "IndexId",
          "Name",
          "SourceS3Path",
          "RoleArn"
        ],
        "members": {
          "IndexId": {},
          "Name": {},
          "Description": {},
          "SourceS3Path": {
            "shape": "S11"
          },
          "ClientToken": {
            "idempotencyToken": true
          },
          "RoleArn": {},
          "Tags": {
            "shape": "S4n"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Id": {}
        }
      }
    },
    "CreateThesaurus": {
      "input": {
        "type": "structure",
        "required": [
          "IndexId",
          "Name",
          "RoleArn",
          "SourceS3Path"
        ],
        "members": {
          "IndexId": {},
          "Name": {},
          "Description": {},
          "RoleArn": {},
          "Tags": {
            "shape": "S4n"
          },
          "SourceS3Path": {
            "shape": "S11"
          },
          "ClientToken": {
            "idempotencyToken": true
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Id": {}
        }
      }
    },
    "DeleteDataSource": {
      "input": {
        "type": "structure",
        "required": [
          "Id",
          "IndexId"
        ],
        "members": {
          "Id": {},
          "IndexId": {}
        }
      }
    },
    "DeleteFaq": {
      "input": {
        "type": "structure",
        "required": [
          "Id",
          "IndexId"
        ],
        "members": {
          "Id": {},
          "IndexId": {}
        }
      }
    },
    "DeleteIndex": {
      "input": {
        "type": "structure",
        "required": [
          "Id"
        ],
        "members": {
          "Id": {}
        }
      }
    },
    "DeletePrincipalMapping": {
      "input": {
        "type": "structure",
        "required": [
          "IndexId",
          "GroupId"
        ],
        "members": {
          "IndexId": {},
          "DataSourceId": {},
          "GroupId": {},
          "OrderingId": {
            "type": "long"
          }
        }
      }
    },
    "DeleteQuerySuggestionsBlockList": {
      "input": {
        "type": "structure",
        "required": [
          "IndexId",
          "Id"
        ],
        "members": {
          "IndexId": {},
          "Id": {}
        }
      }
    },
    "DeleteThesaurus": {
      "input": {
        "type": "structure",
        "required": [
          "Id",
          "IndexId"
        ],
        "members": {
          "Id": {},
          "IndexId": {}
        }
      }
    },
    "DescribeDataSource": {
      "input": {
        "type": "structure",
        "required": [
          "Id",
          "IndexId"
        ],
        "members": {
          "Id": {},
          "IndexId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Id": {},
          "IndexId": {},
          "Name": {},
          "Type": {},
          "Configuration": {
            "shape": "S1j"
          },
          "CreatedAt": {
            "type": "timestamp"
          },
          "UpdatedAt": {
            "type": "timestamp"
          },
          "Description": {},
          "Status": {},
          "Schedule": {},
          "RoleArn": {},
          "ErrorMessage": {},
          "LanguageCode": {}
        }
      }
    },
    "DescribeFaq": {
      "input": {
        "type": "structure",
        "required": [
          "Id",
          "IndexId"
        ],
        "members": {
          "Id": {},
          "IndexId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Id": {},
          "IndexId": {},
          "Name": {},
          "Description": {},
          "CreatedAt": {
            "type": "timestamp"
          },
          "UpdatedAt": {
            "type": "timestamp"
          },
          "S3Path": {
            "shape": "S11"
          },
          "Status": {},
          "RoleArn": {},
          "ErrorMessage": {},
          "FileFormat": {},
          "LanguageCode": {}
        }
      }
    },
    "DescribeIndex": {
      "input": {
        "type": "structure",
        "required": [
          "Id"
        ],
        "members": {
          "Id": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Name": {},
          "Id": {},
          "Edition": {},
          "RoleArn": {},
          "ServerSideEncryptionConfiguration": {
            "shape": "S52"
          },
          "Status": {},
          "Description": {},
          "CreatedAt": {
            "type": "timestamp"
          },
          "UpdatedAt": {
            "type": "timestamp"
          },
          "DocumentMetadataConfigurations": {
            "shape": "S66"
          },
          "IndexStatistics": {
            "type": "structure",
            "required": [
              "FaqStatistics",
              "TextDocumentStatistics"
            ],
            "members": {
              "FaqStatistics": {
                "type": "structure",
                "required": [
                  "IndexedQuestionAnswersCount"
                ],
                "members": {
                  "IndexedQuestionAnswersCount": {
                    "type": "integer"
                  }
                }
              },
              "TextDocumentStatistics": {
                "type": "structure",
                "required": [
                  "IndexedTextDocumentsCount",
                  "IndexedTextBytes"
                ],
                "members": {
                  "IndexedTextDocumentsCount": {
                    "type": "integer"
                  },
                  "IndexedTextBytes": {
                    "type": "long"
                  }
                }
              }
            }
          },
          "ErrorMessage": {},
          "CapacityUnits": {
            "shape": "S6o"
          },
          "UserTokenConfigurations": {
            "shape": "S54"
          },
          "UserContextPolicy": {},
          "UserGroupResolutionConfiguration": {
            "shape": "S5e"
          }
        }
      }
    },
    "DescribePrincipalMapping": {
      "input": {
        "type": "structure",
        "required": [
          "IndexId",
          "GroupId"
        ],
        "members": {
          "IndexId": {},
          "DataSourceId": {},
          "GroupId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "IndexId": {},
          "DataSourceId": {},
          "GroupId": {},
          "GroupOrderingIdSummaries": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Status": {},
                "LastUpdatedAt": {
                  "type": "timestamp"
                },
                "ReceivedAt": {
                  "type": "timestamp"
                },
                "OrderingId": {
                  "type": "long"
                },
                "FailureReason": {}
              }
            }
          }
        }
      }
    },
    "DescribeQuerySuggestionsBlockList": {
      "input": {
        "type": "structure",
        "required": [
          "IndexId",
          "Id"
        ],
        "members": {
          "IndexId": {},
          "Id": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "IndexId": {},
          "Id": {},
          "Name": {},
          "Description": {},
          "Status": {},
          "ErrorMessage": {},
          "CreatedAt": {
            "type": "timestamp"
          },
          "UpdatedAt": {
            "type": "timestamp"
          },
          "SourceS3Path": {
            "shape": "S11"
          },
          "ItemCount": {
            "type": "integer"
          },
          "FileSizeBytes": {
            "type": "long"
          },
          "RoleArn": {}
        }
      }
    },
    "DescribeQuerySuggestionsConfig": {
      "input": {
        "type": "structure",
        "required": [
          "IndexId"
        ],
        "members": {
          "IndexId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Mode": {},
          "Status": {},
          "QueryLogLookBackWindowInDays": {
            "type": "integer"
          },
          "IncludeQueriesWithoutUserInformation": {
            "type": "boolean"
          },
          "MinimumNumberOfQueryingUsers": {
            "type": "integer"
          },
          "MinimumQueryCount": {
            "type": "integer"
          },
          "LastSuggestionsBuildTime": {
            "type": "timestamp"
          },
          "LastClearTime": {
            "type": "timestamp"
          },
          "TotalSuggestionsCount": {
            "type": "integer"
          }
        }
      }
    },
    "DescribeThesaurus": {
      "input": {
        "type": "structure",
        "required": [
          "Id",
          "IndexId"
        ],
        "members": {
          "Id": {},
          "IndexId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Id": {},
          "IndexId": {},
          "Name": {},
          "Description": {},
          "Status": {},
          "ErrorMessage": {},
          "CreatedAt": {
            "type": "timestamp"
          },
          "UpdatedAt": {
            "type": "timestamp"
          },
          "RoleArn": {},
          "SourceS3Path": {
            "shape": "S11"
          },
          "FileSizeBytes": {
            "type": "long"
          },
          "TermCount": {
            "type": "long"
          },
          "SynonymRuleCount": {
            "type": "long"
          }
        }
      }
    },
    "GetQuerySuggestions": {
      "input": {
        "type": "structure",
        "required": [
          "IndexId",
          "QueryText"
        ],
        "members": {
          "IndexId": {},
          "QueryText": {},
          "MaxSuggestionsCount": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "QuerySuggestionsId": {},
          "Suggestions": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Id": {},
                "Value": {
                  "type": "structure",
                  "members": {
                    "Text": {
                      "type": "structure",
                      "members": {
                        "Text": {},
                        "Highlights": {
                          "type": "list",
                          "member": {
                            "type": "structure",
                            "members": {
                              "BeginOffset": {
                                "type": "integer"
                              },
                              "EndOffset": {
                                "type": "integer"
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    },
    "ListDataSourceSyncJobs": {
      "input": {
        "type": "structure",
        "required": [
          "Id",
          "IndexId"
        ],
        "members": {
          "Id": {},
          "IndexId": {},
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          },
          "StartTimeFilter": {
            "type": "structure",
            "members": {
              "StartTime": {
                "type": "timestamp"
              },
              "EndTime": {
                "type": "timestamp"
              }
            }
          },
          "StatusFilter": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "History": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "ExecutionId": {},
                "StartTime": {
                  "type": "timestamp"
                },
                "EndTime": {
                  "type": "timestamp"
                },
                "Status": {},
                "ErrorMessage": {},
                "ErrorCode": {},
                "DataSourceErrorCode": {},
                "Metrics": {
                  "type": "structure",
                  "members": {
                    "DocumentsAdded": {},
                    "DocumentsModified": {},
                    "DocumentsDeleted": {},
                    "DocumentsFailed": {},
                    "DocumentsScanned": {}
                  }
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListDataSources": {
      "input": {
        "type": "structure",
        "required": [
          "IndexId"
        ],
        "members": {
          "IndexId": {},
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "SummaryItems": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Name": {},
                "Id": {},
                "Type": {},
                "CreatedAt": {
                  "type": "timestamp"
                },
                "UpdatedAt": {
                  "type": "timestamp"
                },
                "Status": {},
                "LanguageCode": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListFaqs": {
      "input": {
        "type": "structure",
        "required": [
          "IndexId"
        ],
        "members": {
          "IndexId": {},
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "FaqSummaryItems": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Id": {},
                "Name": {},
                "Status": {},
                "CreatedAt": {
                  "type": "timestamp"
                },
                "UpdatedAt": {
                  "type": "timestamp"
                },
                "FileFormat": {},
                "LanguageCode": {}
              }
            }
          }
        }
      }
    },
    "ListGroupsOlderThanOrderingId": {
      "input": {
        "type": "structure",
        "required": [
          "IndexId",
          "OrderingId"
        ],
        "members": {
          "IndexId": {},
          "DataSourceId": {},
          "OrderingId": {
            "type": "long"
          },
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "GroupsSummaries": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "GroupId": {},
                "OrderingId": {
                  "type": "long"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListIndices": {
      "input": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "IndexConfigurationSummaryItems": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "CreatedAt",
                "UpdatedAt",
                "Status"
              ],
              "members": {
                "Name": {},
                "Id": {},
                "Edition": {},
                "CreatedAt": {
                  "type": "timestamp"
                },
                "UpdatedAt": {
                  "type": "timestamp"
                },
                "Status": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListQuerySuggestionsBlockLists": {
      "input": {
        "type": "structure",
        "required": [
          "IndexId"
        ],
        "members": {
          "IndexId": {},
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "BlockListSummaryItems": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Id": {},
                "Name": {},
                "Status": {},
                "CreatedAt": {
                  "type": "timestamp"
                },
                "UpdatedAt": {
                  "type": "timestamp"
                },
                "ItemCount": {
                  "type": "integer"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListTagsForResource": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceARN"
        ],
        "members": {
          "ResourceARN": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Tags": {
            "shape": "S4n"
          }
        }
      }
    },
    "ListThesauri": {
      "input": {
        "type": "structure",
        "required": [
          "IndexId"
        ],
        "members": {
          "IndexId": {},
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "ThesaurusSummaryItems": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Id": {},
                "Name": {},
                "Status": {},
                "CreatedAt": {
                  "type": "timestamp"
                },
                "UpdatedAt": {
                  "type": "timestamp"
                }
              }
            }
          }
        }
      }
    },
    "PutPrincipalMapping": {
      "input": {
        "type": "structure",
        "required": [
          "IndexId",
          "GroupId",
          "GroupMembers"
        ],
        "members": {
          "IndexId": {},
          "DataSourceId": {},
          "GroupId": {},
          "GroupMembers": {
            "type": "structure",
            "members": {
              "MemberGroups": {
                "type": "list",
                "member": {
                  "type": "structure",
                  "required": [
                    "GroupId"
                  ],
                  "members": {
                    "GroupId": {},
                    "DataSourceId": {}
                  }
                }
              },
              "MemberUsers": {
                "type": "list",
                "member": {
                  "type": "structure",
                  "required": [
                    "UserId"
                  ],
                  "members": {
                    "UserId": {}
                  }
                }
              },
              "S3PathforGroupMembers": {
                "shape": "S11"
              }
            }
          },
          "OrderingId": {
            "type": "long"
          },
          "RoleArn": {}
        }
      }
    },
    "Query": {
      "input": {
        "type": "structure",
        "required": [
          "IndexId",
          "QueryText"
        ],
        "members": {
          "IndexId": {},
          "QueryText": {},
          "AttributeFilter": {
            "shape": "S92"
          },
          "Facets": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "DocumentAttributeKey": {}
              }
            }
          },
          "RequestedDocumentAttributes": {
            "type": "list",
            "member": {}
          },
          "QueryResultTypeFilter": {},
          "DocumentRelevanceOverrideConfigurations": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "Name",
                "Relevance"
              ],
              "members": {
                "Name": {},
                "Relevance": {
                  "shape": "S6a"
                }
              }
            }
          },
          "PageNumber": {
            "type": "integer"
          },
          "PageSize": {
            "type": "integer"
          },
          "SortingConfiguration": {
            "type": "structure",
            "required": [
              "DocumentAttributeKey",
              "SortOrder"
            ],
            "members": {
              "DocumentAttributeKey": {},
              "SortOrder": {}
            }
          },
          "UserContext": {
            "type": "structure",
            "members": {
              "Token": {},
              "UserId": {},
              "Groups": {
                "type": "list",
                "member": {}
              },
              "DataSourceGroups": {
                "type": "list",
                "member": {
                  "type": "structure",
                  "required": [
                    "GroupId",
                    "DataSourceId"
                  ],
                  "members": {
                    "GroupId": {},
                    "DataSourceId": {}
                  }
                }
              }
            }
          },
          "VisitorId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "QueryId": {},
          "ResultItems": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Id": {},
                "Type": {},
                "AdditionalAttributes": {
                  "type": "list",
                  "member": {
                    "type": "structure",
                    "required": [
                      "Key",
                      "ValueType",
                      "Value"
                    ],
                    "members": {
                      "Key": {},
                      "ValueType": {},
                      "Value": {
                        "type": "structure",
                        "members": {
                          "TextWithHighlightsValue": {
                            "shape": "S9q"
                          }
                        }
                      }
                    }
                  }
                },
                "DocumentId": {},
                "DocumentTitle": {
                  "shape": "S9q"
                },
                "DocumentExcerpt": {
                  "shape": "S9q"
                },
                "DocumentURI": {},
                "DocumentAttributes": {
                  "shape": "Sg"
                },
                "ScoreAttributes": {
                  "type": "structure",
                  "members": {
                    "ScoreConfidence": {}
                  }
                },
                "FeedbackToken": {}
              }
            }
          },
          "FacetResults": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "DocumentAttributeKey": {},
                "DocumentAttributeValueType": {},
                "DocumentAttributeValueCountPairs": {
                  "type": "list",
                  "member": {
                    "type": "structure",
                    "members": {
                      "DocumentAttributeValue": {
                        "shape": "Sj"
                      },
                      "Count": {
                        "type": "integer"
                      }
                    }
                  }
                }
              }
            }
          },
          "TotalNumberOfResults": {
            "type": "integer"
          }
        }
      }
    },
    "StartDataSourceSyncJob": {
      "input": {
        "type": "structure",
        "required": [
          "Id",
          "IndexId"
        ],
        "members": {
          "Id": {},
          "IndexId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ExecutionId": {}
        }
      }
    },
    "StopDataSourceSyncJob": {
      "input": {
        "type": "structure",
        "required": [
          "Id",
          "IndexId"
        ],
        "members": {
          "Id": {},
          "IndexId": {}
        }
      }
    },
    "SubmitFeedback": {
      "input": {
        "type": "structure",
        "required": [
          "IndexId",
          "QueryId"
        ],
        "members": {
          "IndexId": {},
          "QueryId": {},
          "ClickFeedbackItems": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "ResultId",
                "ClickTime"
              ],
              "members": {
                "ResultId": {},
                "ClickTime": {
                  "type": "timestamp"
                }
              }
            }
          },
          "RelevanceFeedbackItems": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "ResultId",
                "RelevanceValue"
              ],
              "members": {
                "ResultId": {},
                "RelevanceValue": {}
              }
            }
          }
        }
      }
    },
    "TagResource": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceARN",
          "Tags"
        ],
        "members": {
          "ResourceARN": {},
          "Tags": {
            "shape": "S4n"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UntagResource": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceARN",
          "TagKeys"
        ],
        "members": {
          "ResourceARN": {},
          "TagKeys": {
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UpdateDataSource": {
      "input": {
        "type": "structure",
        "required": [
          "Id",
          "IndexId"
        ],
        "members": {
          "Id": {},
          "Name": {},
          "IndexId": {},
          "Configuration": {
            "shape": "S1j"
          },
          "Description": {},
          "Schedule": {},
          "RoleArn": {},
          "LanguageCode": {}
        }
      }
    },
    "UpdateIndex": {
      "input": {
        "type": "structure",
        "required": [
          "Id"
        ],
        "members": {
          "Id": {},
          "Name": {},
          "RoleArn": {},
          "Description": {},
          "DocumentMetadataConfigurationUpdates": {
            "shape": "S66"
          },
          "CapacityUnits": {
            "shape": "S6o"
          },
          "UserTokenConfigurations": {
            "shape": "S54"
          },
          "UserContextPolicy": {},
          "UserGroupResolutionConfiguration": {
            "shape": "S5e"
          }
        }
      }
    },
    "UpdateQuerySuggestionsBlockList": {
      "input": {
        "type": "structure",
        "required": [
          "IndexId",
          "Id"
        ],
        "members": {
          "IndexId": {},
          "Id": {},
          "Name": {},
          "Description": {},
          "SourceS3Path": {
            "shape": "S11"
          },
          "RoleArn": {}
        }
      }
    },
    "UpdateQuerySuggestionsConfig": {
      "input": {
        "type": "structure",
        "required": [
          "IndexId"
        ],
        "members": {
          "IndexId": {},
          "Mode": {},
          "QueryLogLookBackWindowInDays": {
            "type": "integer"
          },
          "IncludeQueriesWithoutUserInformation": {
            "type": "boolean"
          },
          "MinimumNumberOfQueryingUsers": {
            "type": "integer"
          },
          "MinimumQueryCount": {
            "type": "integer"
          }
        }
      }
    },
    "UpdateThesaurus": {
      "input": {
        "type": "structure",
        "required": [
          "Id",
          "IndexId"
        ],
        "members": {
          "Id": {},
          "Name": {},
          "IndexId": {},
          "Description": {},
          "RoleArn": {},
          "SourceS3Path": {
            "shape": "S11"
          }
        }
      }
    }
  },
  "shapes": {
    "Sg": {
      "type": "list",
      "member": {
        "shape": "Sh"
      }
    },
    "Sh": {
      "type": "structure",
      "required": [
        "Key",
        "Value"
      ],
      "members": {
        "Key": {},
        "Value": {
          "shape": "Sj"
        }
      }
    },
    "Sj": {
      "type": "structure",
      "members": {
        "StringValue": {},
        "StringListValue": {
          "type": "list",
          "member": {}
        },
        "LongValue": {
          "type": "long"
        },
        "DateValue": {
          "type": "timestamp"
        }
      }
    },
    "S11": {
      "type": "structure",
      "required": [
        "Bucket",
        "Key"
      ],
      "members": {
        "Bucket": {},
        "Key": {}
      }
    },
    "S14": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "Name",
          "Type",
          "Access"
        ],
        "members": {
          "Name": {},
          "Type": {},
          "Access": {},
          "DataSourceId": {}
        }
      }
    },
    "S1j": {
      "type": "structure",
      "members": {
        "S3Configuration": {
          "type": "structure",
          "required": [
            "BucketName"
          ],
          "members": {
            "BucketName": {},
            "InclusionPrefixes": {
              "shape": "S1l"
            },
            "InclusionPatterns": {
              "shape": "S1l"
            },
            "ExclusionPatterns": {
              "shape": "S1l"
            },
            "DocumentsMetadataConfiguration": {
              "type": "structure",
              "members": {
                "S3Prefix": {}
              }
            },
            "AccessControlListConfiguration": {
              "type": "structure",
              "members": {
                "KeyPath": {}
              }
            }
          }
        },
        "SharePointConfiguration": {
          "type": "structure",
          "required": [
            "SharePointVersion",
            "Urls",
            "SecretArn"
          ],
          "members": {
            "SharePointVersion": {},
            "Urls": {
              "type": "list",
              "member": {}
            },
            "SecretArn": {},
            "CrawlAttachments": {
              "type": "boolean"
            },
            "UseChangeLog": {
              "type": "boolean"
            },
            "InclusionPatterns": {
              "shape": "S1l"
            },
            "ExclusionPatterns": {
              "shape": "S1l"
            },
            "VpcConfiguration": {
              "shape": "S1v"
            },
            "FieldMappings": {
              "shape": "S20"
            },
            "DocumentTitleFieldName": {},
            "DisableLocalGroups": {
              "type": "boolean"
            },
            "SslCertificateS3Path": {
              "shape": "S11"
            }
          }
        },
        "DatabaseConfiguration": {
          "type": "structure",
          "required": [
            "DatabaseEngineType",
            "ConnectionConfiguration",
            "ColumnConfiguration"
          ],
          "members": {
            "DatabaseEngineType": {},
            "ConnectionConfiguration": {
              "type": "structure",
              "required": [
                "DatabaseHost",
                "DatabasePort",
                "DatabaseName",
                "TableName",
                "SecretArn"
              ],
              "members": {
                "DatabaseHost": {},
                "DatabasePort": {
                  "type": "integer"
                },
                "DatabaseName": {},
                "TableName": {},
                "SecretArn": {}
              }
            },
            "VpcConfiguration": {
              "shape": "S1v"
            },
            "ColumnConfiguration": {
              "type": "structure",
              "required": [
                "DocumentIdColumnName",
                "DocumentDataColumnName",
                "ChangeDetectingColumns"
              ],
              "members": {
                "DocumentIdColumnName": {},
                "DocumentDataColumnName": {},
                "DocumentTitleColumnName": {},
                "FieldMappings": {
                  "shape": "S20"
                },
                "ChangeDetectingColumns": {
                  "type": "list",
                  "member": {}
                }
              }
            },
            "AclConfiguration": {
              "type": "structure",
              "required": [
                "AllowedGroupsColumnName"
              ],
              "members": {
                "AllowedGroupsColumnName": {}
              }
            },
            "SqlConfiguration": {
              "type": "structure",
              "members": {
                "QueryIdentifiersEnclosingOption": {}
              }
            }
          }
        },
        "SalesforceConfiguration": {
          "type": "structure",
          "required": [
            "ServerUrl",
            "SecretArn"
          ],
          "members": {
            "ServerUrl": {},
            "SecretArn": {},
            "StandardObjectConfigurations": {
              "type": "list",
              "member": {
                "type": "structure",
                "required": [
                  "Name",
                  "DocumentDataFieldName"
                ],
                "members": {
                  "Name": {},
                  "DocumentDataFieldName": {},
                  "DocumentTitleFieldName": {},
                  "FieldMappings": {
                    "shape": "S20"
                  }
                }
              }
            },
            "KnowledgeArticleConfiguration": {
              "type": "structure",
              "required": [
                "IncludedStates"
              ],
              "members": {
                "IncludedStates": {
                  "type": "list",
                  "member": {}
                },
                "StandardKnowledgeArticleTypeConfiguration": {
                  "type": "structure",
                  "required": [
                    "DocumentDataFieldName"
                  ],
                  "members": {
                    "DocumentDataFieldName": {},
                    "DocumentTitleFieldName": {},
                    "FieldMappings": {
                      "shape": "S20"
                    }
                  }
                },
                "CustomKnowledgeArticleTypeConfigurations": {
                  "type": "list",
                  "member": {
                    "type": "structure",
                    "required": [
                      "Name",
                      "DocumentDataFieldName"
                    ],
                    "members": {
                      "Name": {},
                      "DocumentDataFieldName": {},
                      "DocumentTitleFieldName": {},
                      "FieldMappings": {
                        "shape": "S20"
                      }
                    }
                  }
                }
              }
            },
            "ChatterFeedConfiguration": {
              "type": "structure",
              "required": [
                "DocumentDataFieldName"
              ],
              "members": {
                "DocumentDataFieldName": {},
                "DocumentTitleFieldName": {},
                "FieldMappings": {
                  "shape": "S20"
                },
                "IncludeFilterTypes": {
                  "type": "list",
                  "member": {}
                }
              }
            },
            "CrawlAttachments": {
              "type": "boolean"
            },
            "StandardObjectAttachmentConfiguration": {
              "type": "structure",
              "members": {
                "DocumentTitleFieldName": {},
                "FieldMappings": {
                  "shape": "S20"
                }
              }
            },
            "IncludeAttachmentFilePatterns": {
              "shape": "S1l"
            },
            "ExcludeAttachmentFilePatterns": {
              "shape": "S1l"
            }
          }
        },
        "OneDriveConfiguration": {
          "type": "structure",
          "required": [
            "TenantDomain",
            "SecretArn",
            "OneDriveUsers"
          ],
          "members": {
            "TenantDomain": {},
            "SecretArn": {},
            "OneDriveUsers": {
              "type": "structure",
              "members": {
                "OneDriveUserList": {
                  "type": "list",
                  "member": {}
                },
                "OneDriveUserS3Path": {
                  "shape": "S11"
                }
              }
            },
            "InclusionPatterns": {
              "shape": "S1l"
            },
            "ExclusionPatterns": {
              "shape": "S1l"
            },
            "FieldMappings": {
              "shape": "S20"
            },
            "DisableLocalGroups": {
              "type": "boolean"
            }
          }
        },
        "ServiceNowConfiguration": {
          "type": "structure",
          "required": [
            "HostUrl",
            "SecretArn",
            "ServiceNowBuildVersion"
          ],
          "members": {
            "HostUrl": {},
            "SecretArn": {},
            "ServiceNowBuildVersion": {},
            "KnowledgeArticleConfiguration": {
              "type": "structure",
              "required": [
                "DocumentDataFieldName"
              ],
              "members": {
                "CrawlAttachments": {
                  "type": "boolean"
                },
                "IncludeAttachmentFilePatterns": {
                  "shape": "S1l"
                },
                "ExcludeAttachmentFilePatterns": {
                  "shape": "S1l"
                },
                "DocumentDataFieldName": {},
                "DocumentTitleFieldName": {},
                "FieldMappings": {
                  "shape": "S20"
                },
                "FilterQuery": {}
              }
            },
            "ServiceCatalogConfiguration": {
              "type": "structure",
              "required": [
                "DocumentDataFieldName"
              ],
              "members": {
                "CrawlAttachments": {
                  "type": "boolean"
                },
                "IncludeAttachmentFilePatterns": {
                  "shape": "S1l"
                },
                "ExcludeAttachmentFilePatterns": {
                  "shape": "S1l"
                },
                "DocumentDataFieldName": {},
                "DocumentTitleFieldName": {},
                "FieldMappings": {
                  "shape": "S20"
                }
              }
            },
            "AuthenticationType": {}
          }
        },
        "ConfluenceConfiguration": {
          "type": "structure",
          "required": [
            "ServerUrl",
            "SecretArn",
            "Version"
          ],
          "members": {
            "ServerUrl": {},
            "SecretArn": {},
            "Version": {},
            "SpaceConfiguration": {
              "type": "structure",
              "members": {
                "CrawlPersonalSpaces": {
                  "type": "boolean"
                },
                "CrawlArchivedSpaces": {
                  "type": "boolean"
                },
                "IncludeSpaces": {
                  "shape": "S3c"
                },
                "ExcludeSpaces": {
                  "shape": "S3c"
                },
                "SpaceFieldMappings": {
                  "type": "list",
                  "member": {
                    "type": "structure",
                    "members": {
                      "DataSourceFieldName": {},
                      "DateFieldFormat": {},
                      "IndexFieldName": {}
                    }
                  }
                }
              }
            },
            "PageConfiguration": {
              "type": "structure",
              "members": {
                "PageFieldMappings": {
                  "type": "list",
                  "member": {
                    "type": "structure",
                    "members": {
                      "DataSourceFieldName": {},
                      "DateFieldFormat": {},
                      "IndexFieldName": {}
                    }
                  }
                }
              }
            },
            "BlogConfiguration": {
              "type": "structure",
              "members": {
                "BlogFieldMappings": {
                  "type": "list",
                  "member": {
                    "type": "structure",
                    "members": {
                      "DataSourceFieldName": {},
                      "DateFieldFormat": {},
                      "IndexFieldName": {}
                    }
                  }
                }
              }
            },
            "AttachmentConfiguration": {
              "type": "structure",
              "members": {
                "CrawlAttachments": {
                  "type": "boolean"
                },
                "AttachmentFieldMappings": {
                  "type": "list",
                  "member": {
                    "type": "structure",
                    "members": {
                      "DataSourceFieldName": {},
                      "DateFieldFormat": {},
                      "IndexFieldName": {}
                    }
                  }
                }
              }
            },
            "VpcConfiguration": {
              "shape": "S1v"
            },
            "InclusionPatterns": {
              "shape": "S1l"
            },
            "ExclusionPatterns": {
              "shape": "S1l"
            }
          }
        },
        "GoogleDriveConfiguration": {
          "type": "structure",
          "required": [
            "SecretArn"
          ],
          "members": {
            "SecretArn": {},
            "InclusionPatterns": {
              "shape": "S1l"
            },
            "ExclusionPatterns": {
              "shape": "S1l"
            },
            "FieldMappings": {
              "shape": "S20"
            },
            "ExcludeMimeTypes": {
              "type": "list",
              "member": {}
            },
            "ExcludeUserAccounts": {
              "type": "list",
              "member": {}
            },
            "ExcludeSharedDrives": {
              "type": "list",
              "member": {}
            }
          }
        },
        "WebCrawlerConfiguration": {
          "type": "structure",
          "required": [
            "Urls"
          ],
          "members": {
            "Urls": {
              "type": "structure",
              "members": {
                "SeedUrlConfiguration": {
                  "type": "structure",
                  "required": [
                    "SeedUrls"
                  ],
                  "members": {
                    "SeedUrls": {
                      "type": "list",
                      "member": {}
                    },
                    "WebCrawlerMode": {}
                  }
                },
                "SiteMapsConfiguration": {
                  "type": "structure",
                  "required": [
                    "SiteMaps"
                  ],
                  "members": {
                    "SiteMaps": {
                      "type": "list",
                      "member": {}
                    }
                  }
                }
              }
            },
            "CrawlDepth": {
              "type": "integer"
            },
            "MaxLinksPerPage": {
              "type": "integer"
            },
            "MaxContentSizePerPageInMegaBytes": {
              "type": "float"
            },
            "MaxUrlsPerMinuteCrawlRate": {
              "type": "integer"
            },
            "UrlInclusionPatterns": {
              "shape": "S1l"
            },
            "UrlExclusionPatterns": {
              "shape": "S1l"
            },
            "ProxyConfiguration": {
              "type": "structure",
              "required": [
                "Host",
                "Port"
              ],
              "members": {
                "Host": {},
                "Port": {
                  "type": "integer"
                },
                "Credentials": {}
              }
            },
            "AuthenticationConfiguration": {
              "type": "structure",
              "members": {
                "BasicAuthentication": {
                  "type": "list",
                  "member": {
                    "type": "structure",
                    "required": [
                      "Host",
                      "Port",
                      "Credentials"
                    ],
                    "members": {
                      "Host": {},
                      "Port": {
                        "type": "integer"
                      },
                      "Credentials": {}
                    }
                  }
                }
              }
            }
          }
        },
        "WorkDocsConfiguration": {
          "type": "structure",
          "required": [
            "OrganizationId"
          ],
          "members": {
            "OrganizationId": {},
            "CrawlComments": {
              "type": "boolean"
            },
            "UseChangeLog": {
              "type": "boolean"
            },
            "InclusionPatterns": {
              "shape": "S1l"
            },
            "ExclusionPatterns": {
              "shape": "S1l"
            },
            "FieldMappings": {
              "shape": "S20"
            }
          }
        }
      }
    },
    "S1l": {
      "type": "list",
      "member": {}
    },
    "S1v": {
      "type": "structure",
      "required": [
        "SubnetIds",
        "SecurityGroupIds"
      ],
      "members": {
        "SubnetIds": {
          "type": "list",
          "member": {}
        },
        "SecurityGroupIds": {
          "type": "list",
          "member": {}
        }
      }
    },
    "S20": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "DataSourceFieldName",
          "IndexFieldName"
        ],
        "members": {
          "DataSourceFieldName": {},
          "DateFieldFormat": {},
          "IndexFieldName": {}
        }
      }
    },
    "S3c": {
      "type": "list",
      "member": {}
    },
    "S4n": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "Key",
          "Value"
        ],
        "members": {
          "Key": {},
          "Value": {}
        }
      }
    },
    "S52": {
      "type": "structure",
      "members": {
        "KmsKeyId": {
          "type": "string",
          "sensitive": true
        }
      }
    },
    "S54": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "JwtTokenTypeConfiguration": {
            "type": "structure",
            "required": [
              "KeyLocation"
            ],
            "members": {
              "KeyLocation": {},
              "URL": {},
              "SecretManagerArn": {},
              "UserNameAttributeField": {},
              "GroupAttributeField": {},
              "Issuer": {},
              "ClaimRegex": {}
            }
          },
          "JsonTokenTypeConfiguration": {
            "type": "structure",
            "required": [
              "UserNameAttributeField",
              "GroupAttributeField"
            ],
            "members": {
              "UserNameAttributeField": {},
              "GroupAttributeField": {}
            }
          }
        }
      }
    },
    "S5e": {
      "type": "structure",
      "required": [
        "UserGroupResolutionMode"
      ],
      "members": {
        "UserGroupResolutionMode": {}
      }
    },
    "S66": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "Name",
          "Type"
        ],
        "members": {
          "Name": {},
          "Type": {},
          "Relevance": {
            "shape": "S6a"
          },
          "Search": {
            "type": "structure",
            "members": {
              "Facetable": {
                "type": "boolean"
              },
              "Searchable": {
                "type": "boolean"
              },
              "Displayable": {
                "type": "boolean"
              },
              "Sortable": {
                "type": "boolean"
              }
            }
          }
        }
      }
    },
    "S6a": {
      "type": "structure",
      "members": {
        "Freshness": {
          "type": "boolean"
        },
        "Importance": {
          "type": "integer"
        },
        "Duration": {},
        "RankOrder": {},
        "ValueImportanceMap": {
          "type": "map",
          "key": {},
          "value": {
            "type": "integer"
          }
        }
      }
    },
    "S6o": {
      "type": "structure",
      "required": [
        "StorageCapacityUnits",
        "QueryCapacityUnits"
      ],
      "members": {
        "StorageCapacityUnits": {
          "type": "integer"
        },
        "QueryCapacityUnits": {
          "type": "integer"
        }
      }
    },
    "S92": {
      "type": "structure",
      "members": {
        "AndAllFilters": {
          "shape": "S93"
        },
        "OrAllFilters": {
          "shape": "S93"
        },
        "NotFilter": {
          "shape": "S92"
        },
        "EqualsTo": {
          "shape": "Sh"
        },
        "ContainsAll": {
          "shape": "Sh"
        },
        "ContainsAny": {
          "shape": "Sh"
        },
        "GreaterThan": {
          "shape": "Sh"
        },
        "GreaterThanOrEquals": {
          "shape": "Sh"
        },
        "LessThan": {
          "shape": "Sh"
        },
        "LessThanOrEquals": {
          "shape": "Sh"
        }
      }
    },
    "S93": {
      "type": "list",
      "member": {
        "shape": "S92"
      }
    },
    "S9q": {
      "type": "structure",
      "members": {
        "Text": {},
        "Highlights": {
          "type": "list",
          "member": {
            "type": "structure",
            "required": [
              "BeginOffset",
              "EndOffset"
            ],
            "members": {
              "BeginOffset": {
                "type": "integer"
              },
              "EndOffset": {
                "type": "integer"
              },
              "TopAnswer": {
                "type": "boolean"
              },
              "Type": {}
            }
          }
        }
      }
    }
  }
}