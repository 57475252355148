{
  "version": "2.0",
  "metadata": {
    "apiVersion": "2020-05-15",
    "endpointPrefix": "apprunner",
    "jsonVersion": "1.0",
    "protocol": "json",
    "serviceFullName": "AWS App Runner",
    "serviceId": "AppRunner",
    "signatureVersion": "v4",
    "signingName": "apprunner",
    "targetPrefix": "AppRunner",
    "uid": "apprunner-2020-05-15"
  },
  "operations": {
    "AssociateCustomDomain": {
      "input": {
        "type": "structure",
        "required": [
          "ServiceArn",
          "DomainName"
        ],
        "members": {
          "ServiceArn": {},
          "DomainName": {},
          "EnableWWWSubdomain": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "DNSTarget",
          "ServiceArn",
          "CustomDomain"
        ],
        "members": {
          "DNSTarget": {},
          "ServiceArn": {},
          "CustomDomain": {
            "shape": "S7"
          }
        }
      }
    },
    "CreateAutoScalingConfiguration": {
      "input": {
        "type": "structure",
        "required": [
          "AutoScalingConfigurationName"
        ],
        "members": {
          "AutoScalingConfigurationName": {},
          "MaxConcurrency": {
            "type": "integer"
          },
          "MinSize": {
            "type": "integer"
          },
          "MaxSize": {
            "type": "integer"
          },
          "Tags": {
            "shape": "Sh"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "AutoScalingConfiguration"
        ],
        "members": {
          "AutoScalingConfiguration": {
            "shape": "Sm"
          }
        }
      }
    },
    "CreateConnection": {
      "input": {
        "type": "structure",
        "required": [
          "ConnectionName",
          "ProviderType"
        ],
        "members": {
          "ConnectionName": {},
          "ProviderType": {},
          "Tags": {
            "shape": "Sh"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "Connection"
        ],
        "members": {
          "Connection": {
            "shape": "Sv"
          }
        }
      }
    },
    "CreateService": {
      "input": {
        "type": "structure",
        "required": [
          "ServiceName",
          "SourceConfiguration"
        ],
        "members": {
          "ServiceName": {},
          "SourceConfiguration": {
            "shape": "Sz"
          },
          "InstanceConfiguration": {
            "shape": "S1i"
          },
          "Tags": {
            "shape": "Sh"
          },
          "EncryptionConfiguration": {
            "shape": "S1l"
          },
          "HealthCheckConfiguration": {
            "shape": "S1n"
          },
          "AutoScalingConfigurationArn": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "Service",
          "OperationId"
        ],
        "members": {
          "Service": {
            "shape": "S1v"
          },
          "OperationId": {}
        }
      }
    },
    "DeleteAutoScalingConfiguration": {
      "input": {
        "type": "structure",
        "required": [
          "AutoScalingConfigurationArn"
        ],
        "members": {
          "AutoScalingConfigurationArn": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "AutoScalingConfiguration"
        ],
        "members": {
          "AutoScalingConfiguration": {
            "shape": "Sm"
          }
        }
      }
    },
    "DeleteConnection": {
      "input": {
        "type": "structure",
        "required": [
          "ConnectionArn"
        ],
        "members": {
          "ConnectionArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Connection": {
            "shape": "Sv"
          }
        }
      }
    },
    "DeleteService": {
      "input": {
        "type": "structure",
        "required": [
          "ServiceArn"
        ],
        "members": {
          "ServiceArn": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "Service",
          "OperationId"
        ],
        "members": {
          "Service": {
            "shape": "S1v"
          },
          "OperationId": {}
        }
      }
    },
    "DescribeAutoScalingConfiguration": {
      "input": {
        "type": "structure",
        "required": [
          "AutoScalingConfigurationArn"
        ],
        "members": {
          "AutoScalingConfigurationArn": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "AutoScalingConfiguration"
        ],
        "members": {
          "AutoScalingConfiguration": {
            "shape": "Sm"
          }
        }
      }
    },
    "DescribeCustomDomains": {
      "input": {
        "type": "structure",
        "required": [
          "ServiceArn"
        ],
        "members": {
          "ServiceArn": {},
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "DNSTarget",
          "ServiceArn",
          "CustomDomains"
        ],
        "members": {
          "DNSTarget": {},
          "ServiceArn": {},
          "CustomDomains": {
            "type": "list",
            "member": {
              "shape": "S7"
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribeService": {
      "input": {
        "type": "structure",
        "required": [
          "ServiceArn"
        ],
        "members": {
          "ServiceArn": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "Service"
        ],
        "members": {
          "Service": {
            "shape": "S1v"
          }
        }
      }
    },
    "DisassociateCustomDomain": {
      "input": {
        "type": "structure",
        "required": [
          "ServiceArn",
          "DomainName"
        ],
        "members": {
          "ServiceArn": {},
          "DomainName": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "DNSTarget",
          "ServiceArn",
          "CustomDomain"
        ],
        "members": {
          "DNSTarget": {},
          "ServiceArn": {},
          "CustomDomain": {
            "shape": "S7"
          }
        }
      }
    },
    "ListAutoScalingConfigurations": {
      "input": {
        "type": "structure",
        "members": {
          "AutoScalingConfigurationName": {},
          "LatestOnly": {
            "type": "boolean"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "AutoScalingConfigurationSummaryList"
        ],
        "members": {
          "AutoScalingConfigurationSummaryList": {
            "type": "list",
            "member": {
              "shape": "S1y"
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListConnections": {
      "input": {
        "type": "structure",
        "members": {
          "ConnectionName": {},
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "ConnectionSummaryList"
        ],
        "members": {
          "ConnectionSummaryList": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "ConnectionName": {},
                "ConnectionArn": {},
                "ProviderType": {},
                "Status": {},
                "CreatedAt": {
                  "type": "timestamp"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListOperations": {
      "input": {
        "type": "structure",
        "required": [
          "ServiceArn"
        ],
        "members": {
          "ServiceArn": {},
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "OperationSummaryList": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Id": {},
                "Type": {},
                "Status": {},
                "TargetArn": {},
                "StartedAt": {
                  "type": "timestamp"
                },
                "EndedAt": {
                  "type": "timestamp"
                },
                "UpdatedAt": {
                  "type": "timestamp"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListServices": {
      "input": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "ServiceSummaryList"
        ],
        "members": {
          "ServiceSummaryList": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "ServiceName": {},
                "ServiceId": {},
                "ServiceArn": {},
                "ServiceUrl": {},
                "CreatedAt": {
                  "type": "timestamp"
                },
                "UpdatedAt": {
                  "type": "timestamp"
                },
                "Status": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListTagsForResource": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceArn"
        ],
        "members": {
          "ResourceArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Tags": {
            "shape": "Sh"
          }
        }
      }
    },
    "PauseService": {
      "input": {
        "type": "structure",
        "required": [
          "ServiceArn"
        ],
        "members": {
          "ServiceArn": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "Service"
        ],
        "members": {
          "Service": {
            "shape": "S1v"
          },
          "OperationId": {}
        }
      }
    },
    "ResumeService": {
      "input": {
        "type": "structure",
        "required": [
          "ServiceArn"
        ],
        "members": {
          "ServiceArn": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "Service"
        ],
        "members": {
          "Service": {
            "shape": "S1v"
          },
          "OperationId": {}
        }
      }
    },
    "StartDeployment": {
      "input": {
        "type": "structure",
        "required": [
          "ServiceArn"
        ],
        "members": {
          "ServiceArn": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "OperationId"
        ],
        "members": {
          "OperationId": {}
        }
      }
    },
    "TagResource": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceArn",
          "Tags"
        ],
        "members": {
          "ResourceArn": {},
          "Tags": {
            "shape": "Sh"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UntagResource": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceArn",
          "TagKeys"
        ],
        "members": {
          "ResourceArn": {},
          "TagKeys": {
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UpdateService": {
      "input": {
        "type": "structure",
        "required": [
          "ServiceArn"
        ],
        "members": {
          "ServiceArn": {},
          "SourceConfiguration": {
            "shape": "Sz"
          },
          "InstanceConfiguration": {
            "shape": "S1i"
          },
          "AutoScalingConfigurationArn": {},
          "HealthCheckConfiguration": {
            "shape": "S1n"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "Service",
          "OperationId"
        ],
        "members": {
          "Service": {
            "shape": "S1v"
          },
          "OperationId": {}
        }
      }
    }
  },
  "shapes": {
    "S7": {
      "type": "structure",
      "required": [
        "DomainName",
        "EnableWWWSubdomain",
        "Status"
      ],
      "members": {
        "DomainName": {},
        "EnableWWWSubdomain": {
          "type": "boolean"
        },
        "CertificateValidationRecords": {
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "Name": {},
              "Type": {},
              "Value": {},
              "Status": {}
            }
          }
        },
        "Status": {}
      }
    },
    "Sh": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "Key": {},
          "Value": {}
        }
      }
    },
    "Sm": {
      "type": "structure",
      "members": {
        "AutoScalingConfigurationArn": {},
        "AutoScalingConfigurationName": {},
        "AutoScalingConfigurationRevision": {
          "type": "integer"
        },
        "Latest": {
          "type": "boolean"
        },
        "Status": {},
        "MaxConcurrency": {
          "type": "integer"
        },
        "MinSize": {
          "type": "integer"
        },
        "MaxSize": {
          "type": "integer"
        },
        "CreatedAt": {
          "type": "timestamp"
        },
        "DeletedAt": {
          "type": "timestamp"
        }
      }
    },
    "Sv": {
      "type": "structure",
      "members": {
        "ConnectionName": {},
        "ConnectionArn": {},
        "ProviderType": {},
        "Status": {},
        "CreatedAt": {
          "type": "timestamp"
        }
      }
    },
    "Sz": {
      "type": "structure",
      "members": {
        "CodeRepository": {
          "type": "structure",
          "required": [
            "RepositoryUrl",
            "SourceCodeVersion"
          ],
          "members": {
            "RepositoryUrl": {},
            "SourceCodeVersion": {
              "type": "structure",
              "required": [
                "Type",
                "Value"
              ],
              "members": {
                "Type": {},
                "Value": {}
              }
            },
            "CodeConfiguration": {
              "type": "structure",
              "required": [
                "ConfigurationSource"
              ],
              "members": {
                "ConfigurationSource": {},
                "CodeConfigurationValues": {
                  "type": "structure",
                  "required": [
                    "Runtime"
                  ],
                  "members": {
                    "Runtime": {},
                    "BuildCommand": {
                      "type": "string",
                      "sensitive": true
                    },
                    "StartCommand": {
                      "type": "string",
                      "sensitive": true
                    },
                    "Port": {},
                    "RuntimeEnvironmentVariables": {
                      "shape": "S19"
                    }
                  }
                }
              }
            }
          }
        },
        "ImageRepository": {
          "type": "structure",
          "required": [
            "ImageIdentifier",
            "ImageRepositoryType"
          ],
          "members": {
            "ImageIdentifier": {},
            "ImageConfiguration": {
              "type": "structure",
              "members": {
                "RuntimeEnvironmentVariables": {
                  "shape": "S19"
                },
                "StartCommand": {},
                "Port": {}
              }
            },
            "ImageRepositoryType": {}
          }
        },
        "AutoDeploymentsEnabled": {
          "type": "boolean"
        },
        "AuthenticationConfiguration": {
          "type": "structure",
          "members": {
            "ConnectionArn": {},
            "AccessRoleArn": {}
          }
        }
      }
    },
    "S19": {
      "type": "map",
      "key": {
        "type": "string",
        "sensitive": true
      },
      "value": {
        "type": "string",
        "sensitive": true
      }
    },
    "S1i": {
      "type": "structure",
      "members": {
        "Cpu": {},
        "Memory": {},
        "InstanceRoleArn": {}
      }
    },
    "S1l": {
      "type": "structure",
      "required": [
        "KmsKey"
      ],
      "members": {
        "KmsKey": {}
      }
    },
    "S1n": {
      "type": "structure",
      "members": {
        "Protocol": {},
        "Path": {},
        "Interval": {
          "type": "integer"
        },
        "Timeout": {
          "type": "integer"
        },
        "HealthyThreshold": {
          "type": "integer"
        },
        "UnhealthyThreshold": {
          "type": "integer"
        }
      }
    },
    "S1v": {
      "type": "structure",
      "required": [
        "ServiceName",
        "ServiceId",
        "ServiceArn",
        "ServiceUrl",
        "CreatedAt",
        "UpdatedAt",
        "Status",
        "SourceConfiguration",
        "InstanceConfiguration",
        "AutoScalingConfigurationSummary"
      ],
      "members": {
        "ServiceName": {},
        "ServiceId": {},
        "ServiceArn": {},
        "ServiceUrl": {},
        "CreatedAt": {
          "type": "timestamp"
        },
        "UpdatedAt": {
          "type": "timestamp"
        },
        "DeletedAt": {
          "type": "timestamp"
        },
        "Status": {},
        "SourceConfiguration": {
          "shape": "Sz"
        },
        "InstanceConfiguration": {
          "shape": "S1i"
        },
        "EncryptionConfiguration": {
          "shape": "S1l"
        },
        "HealthCheckConfiguration": {
          "shape": "S1n"
        },
        "AutoScalingConfigurationSummary": {
          "shape": "S1y"
        }
      }
    },
    "S1y": {
      "type": "structure",
      "members": {
        "AutoScalingConfigurationArn": {},
        "AutoScalingConfigurationName": {},
        "AutoScalingConfigurationRevision": {
          "type": "integer"
        }
      }
    }
  }
}