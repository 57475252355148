import { observer } from 'mobx-react-lite'
import { Menu } from '@blueprintjs/core'
import HighlightColorPicker from './HighlightColorPicker'
import AnnotationLabelSubmenu from './AnnotationLabelSubmenu'
import AttributePropagationMenu from './AttributePropagationMenu'
import MenuButton from './MenuButton'
import {
  useToolbarIsHidden,
  useToolbarFloatingPosition,
} from '~/src/hooks/toolbar'
import cx from 'classnames'
// tools
import PositionTool from '~/src/features/tools/position/positionTool'
import RotationTool from '~/src/features/tools/rotation/rotationTool'
import ScaleTool from '~/src/features/tools/scale/scaleTool'
import HighlightTool from '~/src/features/tools/highlight/highlightTool'
import LabelTool from '~/src/features/tools/label/labelTool'
import RemoveTool from '~/src/features/tools/remove/removeTool'
import CustomIcon from '~/src/app/components/Icon'
import Tooltip from '~/src/utils/components/Tooltip'

const ToolbarUI = ({ controller }) => {
  const hidden = useToolbarIsHidden()
  const { x, y } = useToolbarFloatingPosition()

  return (
    <div
      className={cx('toolbar-container', { hidden })}
      style={{ transform: `translate(${x}px, ${y}px)` }}
    >
      <AttributePropagationMenu controller={controller} />
      <Menu large className="toolbar-box">
        <MenuButton
          controller={controller}
          onClick={controller.onAttributePropagationButtonClick}
          icon={
            <Tooltip content="Attributes">
              <CustomIcon icon="doubleCaret" className="xs-icon" />
            </Tooltip>
          }
          id="attribute-propagation"
        />
      </Menu>
      <Menu large className="toolbar-box">
        <MenuButton
          controller={controller}
          icon={
            <Tooltip content="Translate (W)">
              <CustomIcon icon="move" className="sm-icon" />
            </Tooltip>
          }
          tool={PositionTool}
        />

        <MenuButton
          controller={controller}
          icon={
            <Tooltip content="Rotate (R)">
              <CustomIcon icon="refresh" className="sm-icon" />
            </Tooltip>
          }
          tool={RotationTool}
        />

        <Tooltip content="Scale (Ctrl + S)">
          <MenuButton
            controller={controller}
            icon={
              <Tooltip content="Rotate (R)">
                <CustomIcon icon="max" className="sm-icon" />
              </Tooltip>
            }
            tool={ScaleTool}
          />
        </Tooltip>
        <MenuButton
          controller={controller}
          icon={
            <Tooltip content="Highlight (Ctrl + H)">
              <CustomIcon icon="sun" />
            </Tooltip>
          }
          tool={HighlightTool}
          popoverProps={{
            isOpen: controller.isToolMenuOpen(HighlightTool),
          }}
        >
          <HighlightColorPicker
            onClick={params => {
              controller.activateTool(HighlightTool, params)
            }}
          />
        </MenuButton>

        <MenuButton
          controller={controller}
          icon={
            <Tooltip content="Add label (A)">
              <CustomIcon icon="tag" className="sm-icon" />
            </Tooltip>
          }
          tool={LabelTool}
          popoverProps={{
            isOpen: controller.isToolMenuOpen(LabelTool),
          }}
        >
          <AnnotationLabelSubmenu />
        </MenuButton>

        <MenuButton
          controller={controller}
          icon={
            <Tooltip content="Delete/hide (Ctrl + X)">
              <CustomIcon icon="trash" />
            </Tooltip>
          }
          tool={RemoveTool}
        />
      </Menu>
    </div>
  )
}

export default observer(ToolbarUI)
