{
  "pagination": {
    "GetAssetPropertyAggregates": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "aggregatedValues"
    },
    "GetAssetPropertyValueHistory": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "assetPropertyValueHistory"
    },
    "GetInterpolatedAssetPropertyValues": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "interpolatedAssetPropertyValues"
    },
    "ListAccessPolicies": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "accessPolicySummaries"
    },
    "ListAssetModels": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "assetModelSummaries"
    },
    "ListAssetRelationships": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "assetRelationshipSummaries"
    },
    "ListAssets": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "assetSummaries"
    },
    "ListAssociatedAssets": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "assetSummaries"
    },
    "ListDashboards": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "dashboardSummaries"
    },
    "ListGateways": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "gatewaySummaries"
    },
    "ListPortals": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "portalSummaries"
    },
    "ListProjectAssets": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "assetIds"
    },
    "ListProjects": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "projectSummaries"
    }
  }
}
