{
  "version": "2.0",
  "metadata": {
    "apiVersion": "2019-12-03",
    "endpointPrefix": "outposts",
    "jsonVersion": "1.1",
    "protocol": "rest-json",
    "serviceAbbreviation": "Outposts",
    "serviceFullName": "AWS Outposts",
    "serviceId": "Outposts",
    "signatureVersion": "v4",
    "signingName": "outposts",
    "uid": "outposts-2019-12-03"
  },
  "operations": {
    "CreateOrder": {
      "http": {
        "requestUri": "/orders"
      },
      "input": {
        "type": "structure",
        "required": [
          "OutpostIdentifier",
          "LineItems",
          "PaymentOption"
        ],
        "members": {
          "OutpostIdentifier": {},
          "LineItems": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "CatalogItemId": {},
                "Quantity": {
                  "type": "integer"
                }
              }
            }
          },
          "PaymentOption": {},
          "PaymentTerm": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Order": {
            "type": "structure",
            "members": {
              "OutpostId": {},
              "OrderId": {},
              "Status": {},
              "LineItems": {
                "type": "list",
                "member": {
                  "type": "structure",
                  "members": {
                    "CatalogItemId": {},
                    "LineItemId": {},
                    "Quantity": {
                      "type": "integer"
                    },
                    "Status": {}
                  }
                }
              },
              "PaymentOption": {},
              "OrderSubmissionDate": {
                "type": "timestamp"
              },
              "OrderFulfilledDate": {
                "type": "timestamp"
              }
            }
          }
        }
      }
    },
    "CreateOutpost": {
      "http": {
        "requestUri": "/outposts"
      },
      "input": {
        "type": "structure",
        "required": [
          "Name",
          "SiteId"
        ],
        "members": {
          "Name": {},
          "Description": {},
          "SiteId": {},
          "AvailabilityZone": {},
          "AvailabilityZoneId": {},
          "Tags": {
            "shape": "Sp"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Outpost": {
            "shape": "St"
          }
        }
      }
    },
    "DeleteOutpost": {
      "http": {
        "method": "DELETE",
        "requestUri": "/outposts/{OutpostId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "OutpostId"
        ],
        "members": {
          "OutpostId": {
            "location": "uri",
            "locationName": "OutpostId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteSite": {
      "http": {
        "method": "DELETE",
        "requestUri": "/sites/{SiteId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "SiteId"
        ],
        "members": {
          "SiteId": {
            "location": "uri",
            "locationName": "SiteId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "GetOutpost": {
      "http": {
        "method": "GET",
        "requestUri": "/outposts/{OutpostId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "OutpostId"
        ],
        "members": {
          "OutpostId": {
            "location": "uri",
            "locationName": "OutpostId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Outpost": {
            "shape": "St"
          }
        }
      }
    },
    "GetOutpostInstanceTypes": {
      "http": {
        "method": "GET",
        "requestUri": "/outposts/{OutpostId}/instanceTypes"
      },
      "input": {
        "type": "structure",
        "required": [
          "OutpostId"
        ],
        "members": {
          "OutpostId": {
            "location": "uri",
            "locationName": "OutpostId"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "NextToken"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "MaxResults",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "InstanceTypes": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "InstanceType": {}
              }
            }
          },
          "NextToken": {},
          "OutpostId": {},
          "OutpostArn": {}
        }
      }
    },
    "ListOutposts": {
      "http": {
        "method": "GET",
        "requestUri": "/outposts"
      },
      "input": {
        "type": "structure",
        "members": {
          "NextToken": {
            "location": "querystring",
            "locationName": "NextToken"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "MaxResults",
            "type": "integer"
          },
          "LifeCycleStatusFilter": {
            "location": "querystring",
            "locationName": "LifeCycleStatusFilter",
            "type": "list",
            "member": {}
          },
          "AvailabilityZoneFilter": {
            "location": "querystring",
            "locationName": "AvailabilityZoneFilter",
            "type": "list",
            "member": {}
          },
          "AvailabilityZoneIdFilter": {
            "location": "querystring",
            "locationName": "AvailabilityZoneIdFilter",
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Outposts": {
            "type": "list",
            "member": {
              "shape": "St"
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListSites": {
      "http": {
        "method": "GET",
        "requestUri": "/sites"
      },
      "input": {
        "type": "structure",
        "members": {
          "NextToken": {
            "location": "querystring",
            "locationName": "NextToken"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "MaxResults",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Sites": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "SiteId": {},
                "AccountId": {},
                "Name": {},
                "Description": {},
                "Tags": {
                  "shape": "Sp"
                },
                "SiteArn": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListTagsForResource": {
      "http": {
        "method": "GET",
        "requestUri": "/tags/{ResourceArn}"
      },
      "input": {
        "type": "structure",
        "required": [
          "ResourceArn"
        ],
        "members": {
          "ResourceArn": {
            "location": "uri",
            "locationName": "ResourceArn"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Tags": {
            "shape": "Sp"
          }
        }
      }
    },
    "TagResource": {
      "http": {
        "requestUri": "/tags/{ResourceArn}"
      },
      "input": {
        "type": "structure",
        "required": [
          "ResourceArn",
          "Tags"
        ],
        "members": {
          "ResourceArn": {
            "location": "uri",
            "locationName": "ResourceArn"
          },
          "Tags": {
            "shape": "Sp"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UntagResource": {
      "http": {
        "method": "DELETE",
        "requestUri": "/tags/{ResourceArn}"
      },
      "input": {
        "type": "structure",
        "required": [
          "ResourceArn",
          "TagKeys"
        ],
        "members": {
          "ResourceArn": {
            "location": "uri",
            "locationName": "ResourceArn"
          },
          "TagKeys": {
            "location": "querystring",
            "locationName": "tagKeys",
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    }
  },
  "shapes": {
    "Sp": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "St": {
      "type": "structure",
      "members": {
        "OutpostId": {},
        "OwnerId": {},
        "OutpostArn": {},
        "SiteId": {},
        "Name": {},
        "Description": {},
        "LifeCycleStatus": {},
        "AvailabilityZone": {},
        "AvailabilityZoneId": {},
        "Tags": {
          "shape": "Sp"
        },
        "SiteArn": {}
      }
    }
  }
}