{
  "pagination": {
    "DescribeResourceCollectionHealth": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "result_key": [
        "CloudFormation",
        "Service"
      ]
    },
    "GetCostEstimation": {
      "input_token": "NextToken",
      "non_aggregate_keys": [
        "Status",
        "TotalCost",
        "TimeRange",
        "ResourceCollection"
      ],
      "output_token": "NextToken",
      "result_key": [
        "Costs"
      ]
    },
    "GetResourceCollection": {
      "input_token": "NextToken",
      "non_aggregate_keys": [
        "ResourceCollection"
      ],
      "output_token": "NextToken",
      "result_key": [
        "ResourceCollection.CloudFormation.StackNames"
      ]
    },
    "ListAnomaliesForInsight": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": [
        "ReactiveAnomalies",
        "ProactiveAnomalies"
      ]
    },
    "ListEvents": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "Events"
    },
    "ListInsights": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": [
        "ProactiveInsights",
        "ReactiveInsights"
      ]
    },
    "ListNotificationChannels": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "result_key": "Channels"
    },
    "ListRecommendations": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "result_key": "Recommendations"
    },
    "SearchInsights": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": [
        "ProactiveInsights",
        "ReactiveInsights"
      ]
    }
  }
}