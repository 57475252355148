import { RenderTargetTexture, Vector3, Vector2 } from '@babylonjs/core'
import createSelectedCloneShader from '~/src/utils/shaders/selectedCloneMaterial'
import createPostProcess from '~/src/utils/shaders/outlinePostProcess'

class OutlineManager {
  outlinedMeshes = []
  scene = null
  outlineLayer = null
  renderTargetTexture = null
  selectedMeshesRender = null
  camera = null
  postProcess = null
  selectedNodeCenter = null
  selectedCircleRadius = null

  constructor(sceneManager) {
    this.sceneManager = sceneManager
    this.scene = sceneManager.scene
    this.selectedMeshesRender = new RenderTargetTexture(
      'selectedMeshesRender',
      {
        width: scene.getEngine().getRenderWidth(),
        height: scene.getEngine().getRenderHeight(),
      },
      scene,
      false,
      true,
      undefined,
      false,
      undefined,
      false,
      false,
      false,
      undefined,
      undefined,
      undefined,
    )
    this.selectedMeshesRender.samples = 4
    this.camera = sceneManager.camera
    this.initPostProcess()
  }

  initPostProcess() {
    this.scene.customRenderTargets.push(this.selectedMeshesRender)
    this.postProcess = createPostProcess(this.camera)
    this.postProcess.onApply = effect => this.updatePostProcess(effect)
  }

  updatePostProcess(effect) {
    const boundingData = this.getBoundingSphereUVData()
    const center = boundingData?.center || null
    const radius = boundingData?.radius || null
    const outlineThickness = 3 / window.innerWidth // thickness in pixels / width in pixels
    this.selectedMeshesRender.renderList = this.outlinedMeshes
    const ratio = this.scene.getEngine().getScreenAspectRatio()
    effect.setTexture('selectedMeshesRender', this.selectedMeshesRender)
    effect.setFloat('thickness', outlineThickness)
    effect.setFloat('ratio', ratio)
    if (center && radius) {
      effect.setVector2('boundingCenter', center)
      effect.setFloat('boundingRadius', radius)
      // console.log('!1. radius: ', radius)
      // console.log('!1. center: ', center)
    }
  }

  applyOutline(mesh) {
    if (this.outlinedMeshes.find(m => m.name.match(mesh.uniqueId))) return
    const meshClone = mesh.clone(`${mesh.uniqueId}-aucta-outline`, mesh)
    meshClone.resetLocalMatrix()
    meshClone.material = createSelectedCloneShader(this.scene)
    meshClone.layerMask = 2
    meshClone.isPickable = false
    meshClone.material.backFaceCulling = false
    meshClone.renderOverlay = false
    this.outlinedMeshes.push(meshClone)
  }

  removeOutline(mesh) {
    const meshClone = this.outlinedMeshes.find(m => m.name.match(mesh.uniqueId))
    if (!meshClone) return
    meshClone.dispose()
    this.outlinedMeshes = this.outlinedMeshes.filter(
      m => !m.name.match(mesh.uniqueId),
    )
  }

  removeAllOutlines() {
    this.outlinedMeshes.forEach(m => m.dispose())
    this.outlinedMeshes = []
  }

  getBoundingSphereUVData() {
    if (this.outlinedMeshes.length < 1) return null
    const mesh = this.outlinedMeshes[0]
    const meshCenter = mesh.getBoundingInfo().boundingSphere.centerWorld
    const boundingSphereRadius =
      mesh.getBoundingInfo().boundingSphere.radiusWorld
    const centerUV = this.sceneManager.getPointScreenUV(meshCenter)
    // This is not the vector we should project the radius on.
    // Doing it with this vector causes the object to get out of the circle
    // when it is off center of the screen due to the FOV of the camera.
    const camLeftVector = this.camera.getDirection(
      new Vector3(-1, 0, 0).scale(boundingSphereRadius),
    )
    const radiusLeft = meshCenter.add(camLeftVector)
    const radiusLeftUV = this.sceneManager.getPointScreenUV(radiusLeft)

    const camRightVector = this.camera.getDirection(
      new Vector3(1, 0, 0).scale(boundingSphereRadius),
    )
    const radiusRight = meshCenter.add(camRightVector)
    const radiusRightUV = this.sceneManager.getPointScreenUV(radiusRight)
    const radiusLengthUV = Math.max(
      centerUV.x - radiusLeftUV.x,
      radiusRightUV.x - centerUV.x,
    )

    return { center: centerUV, radius: radiusLengthUV }
  }
}

export default OutlineManager
