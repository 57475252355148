{
  "pagination": {
    "ListActions": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults",
      "result_key": "ActionSummaries"
    },
    "ListAlgorithms": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults",
      "result_key": "AlgorithmSummaryList"
    },
    "ListAppImageConfigs": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults",
      "result_key": "AppImageConfigs"
    },
    "ListApps": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults",
      "result_key": "Apps"
    },
    "ListArtifacts": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults",
      "result_key": "ArtifactSummaries"
    },
    "ListAssociations": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults",
      "result_key": "AssociationSummaries"
    },
    "ListAutoMLJobs": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults",
      "result_key": "AutoMLJobSummaries"
    },
    "ListCandidatesForAutoMLJob": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults",
      "result_key": "Candidates"
    },
    "ListCodeRepositories": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults",
      "result_key": "CodeRepositorySummaryList"
    },
    "ListCompilationJobs": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults",
      "result_key": "CompilationJobSummaries"
    },
    "ListContexts": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults",
      "result_key": "ContextSummaries"
    },
    "ListDataQualityJobDefinitions": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults",
      "result_key": "JobDefinitionSummaries"
    },
    "ListDeviceFleets": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults",
      "result_key": "DeviceFleetSummaries"
    },
    "ListDevices": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults",
      "result_key": "DeviceSummaries"
    },
    "ListDomains": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults",
      "result_key": "Domains"
    },
    "ListEdgePackagingJobs": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults",
      "result_key": "EdgePackagingJobSummaries"
    },
    "ListEndpointConfigs": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults",
      "result_key": "EndpointConfigs"
    },
    "ListEndpoints": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults",
      "result_key": "Endpoints"
    },
    "ListExperiments": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults",
      "result_key": "ExperimentSummaries"
    },
    "ListFeatureGroups": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults",
      "result_key": "FeatureGroupSummaries"
    },
    "ListFlowDefinitions": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults",
      "result_key": "FlowDefinitionSummaries"
    },
    "ListHumanTaskUis": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults",
      "result_key": "HumanTaskUiSummaries"
    },
    "ListHyperParameterTuningJobs": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults",
      "result_key": "HyperParameterTuningJobSummaries"
    },
    "ListImageVersions": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults",
      "result_key": "ImageVersions"
    },
    "ListImages": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults",
      "result_key": "Images"
    },
    "ListLabelingJobs": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults",
      "result_key": "LabelingJobSummaryList"
    },
    "ListLabelingJobsForWorkteam": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults",
      "result_key": "LabelingJobSummaryList"
    },
    "ListModelBiasJobDefinitions": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults",
      "result_key": "JobDefinitionSummaries"
    },
    "ListModelExplainabilityJobDefinitions": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults",
      "result_key": "JobDefinitionSummaries"
    },
    "ListModelPackageGroups": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults",
      "result_key": "ModelPackageGroupSummaryList"
    },
    "ListModelPackages": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults",
      "result_key": "ModelPackageSummaryList"
    },
    "ListModelQualityJobDefinitions": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults",
      "result_key": "JobDefinitionSummaries"
    },
    "ListModels": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults",
      "result_key": "Models"
    },
    "ListMonitoringExecutions": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults",
      "result_key": "MonitoringExecutionSummaries"
    },
    "ListMonitoringSchedules": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults",
      "result_key": "MonitoringScheduleSummaries"
    },
    "ListNotebookInstanceLifecycleConfigs": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults",
      "result_key": "NotebookInstanceLifecycleConfigs"
    },
    "ListNotebookInstances": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults",
      "result_key": "NotebookInstances"
    },
    "ListPipelineExecutionSteps": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults",
      "result_key": "PipelineExecutionSteps"
    },
    "ListPipelineExecutions": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults",
      "result_key": "PipelineExecutionSummaries"
    },
    "ListPipelineParametersForExecution": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults",
      "result_key": "PipelineParameters"
    },
    "ListPipelines": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults",
      "result_key": "PipelineSummaries"
    },
    "ListProcessingJobs": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults",
      "result_key": "ProcessingJobSummaries"
    },
    "ListProjects": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults"
    },
    "ListStudioLifecycleConfigs": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults",
      "result_key": "StudioLifecycleConfigs"
    },
    "ListSubscribedWorkteams": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults",
      "result_key": "SubscribedWorkteams"
    },
    "ListTags": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults",
      "result_key": "Tags"
    },
    "ListTrainingJobs": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults",
      "result_key": "TrainingJobSummaries"
    },
    "ListTrainingJobsForHyperParameterTuningJob": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults",
      "result_key": "TrainingJobSummaries"
    },
    "ListTransformJobs": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults",
      "result_key": "TransformJobSummaries"
    },
    "ListTrialComponents": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults",
      "result_key": "TrialComponentSummaries"
    },
    "ListTrials": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults",
      "result_key": "TrialSummaries"
    },
    "ListUserProfiles": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults",
      "result_key": "UserProfiles"
    },
    "ListWorkforces": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults",
      "result_key": "Workforces"
    },
    "ListWorkteams": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults",
      "result_key": "Workteams"
    },
    "Search": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults",
      "result_key": "Results"
    }
  }
}
