{
  "pagination": {
    "DescribeApplicableIndividualAssessments": {
      "input_token": "Marker",
      "output_token": "Marker",
      "limit_key": "MaxRecords"
    },
    "DescribeCertificates": {
      "input_token": "Marker",
      "output_token": "Marker",
      "limit_key": "MaxRecords"
    },
    "DescribeConnections": {
      "input_token": "Marker",
      "output_token": "Marker",
      "limit_key": "MaxRecords"
    },
    "DescribeEndpointSettings": {
      "input_token": "Marker",
      "output_token": "Marker",
      "limit_key": "MaxRecords"
    },
    "DescribeEndpointTypes": {
      "input_token": "Marker",
      "output_token": "Marker",
      "limit_key": "MaxRecords"
    },
    "DescribeEndpoints": {
      "input_token": "Marker",
      "output_token": "Marker",
      "limit_key": "MaxRecords"
    },
    "DescribeEventSubscriptions": {
      "input_token": "Marker",
      "output_token": "Marker",
      "limit_key": "MaxRecords"
    },
    "DescribeEvents": {
      "input_token": "Marker",
      "output_token": "Marker",
      "limit_key": "MaxRecords"
    },
    "DescribeOrderableReplicationInstances": {
      "input_token": "Marker",
      "output_token": "Marker",
      "limit_key": "MaxRecords"
    },
    "DescribePendingMaintenanceActions": {
      "input_token": "Marker",
      "output_token": "Marker",
      "limit_key": "MaxRecords"
    },
    "DescribeReplicationInstanceTaskLogs": {
      "input_token": "Marker",
      "output_token": "Marker",
      "limit_key": "MaxRecords"
    },
    "DescribeReplicationInstances": {
      "input_token": "Marker",
      "output_token": "Marker",
      "limit_key": "MaxRecords"
    },
    "DescribeReplicationSubnetGroups": {
      "input_token": "Marker",
      "output_token": "Marker",
      "limit_key": "MaxRecords"
    },
    "DescribeReplicationTaskAssessmentResults": {
      "input_token": "Marker",
      "output_token": "Marker",
      "limit_key": "MaxRecords"
    },
    "DescribeReplicationTaskAssessmentRuns": {
      "input_token": "Marker",
      "output_token": "Marker",
      "limit_key": "MaxRecords"
    },
    "DescribeReplicationTaskIndividualAssessments": {
      "input_token": "Marker",
      "output_token": "Marker",
      "limit_key": "MaxRecords"
    },
    "DescribeReplicationTasks": {
      "input_token": "Marker",
      "output_token": "Marker",
      "limit_key": "MaxRecords"
    },
    "DescribeSchemas": {
      "input_token": "Marker",
      "output_token": "Marker",
      "limit_key": "MaxRecords"
    },
    "DescribeTableStatistics": {
      "input_token": "Marker",
      "output_token": "Marker",
      "limit_key": "MaxRecords"
    }
  }
}
