import { makeAutoObservable } from 'mobx'
import { SceneLoader } from '@babylonjs/core/Loading/sceneLoader'
// import { replaceInstancesWithClones } from '~/src/utils/nodes'
import { autoScaleModel } from '~/src/utils/nodes'
import { logCritical } from '~/src/features/backend/log'
import toaster from '~/src/features/toaster/toaster'
import {
  forEach,
  assign,
  keys,
  map,
  values,
  flatMap,
  uniq,
  uniqBy,
  includes,
} from 'lodash'
import rootStore from '~/src/app/store'

class ModelRepository {
  models = {}

  constructor({ training, persistence, appState, sceneManager }) {
    makeAutoObservable(this, {})
    assign(this, { training, persistence, appState, sceneManager })
  }

  async initializeFromTraining() {
    const steps = this.training.getAllSteps()
    const allObjects = uniqBy(
      flatMap(steps, step => step.getAllObjects()),
      'id',
    )
    await Promise.all(
      allObjects.map(object => this.loadModel(object.id, object.model)),
    )
    // NOTE: damn bayblon doesn't know how to work with instanced meshes
    // and loading a model with instances leaves shit in the scene
    forEach(this.models, model => this.removeModelFromScene(model.id))
  }

  async loadModel(id, modelData) {
    const { appState } = this
    console.log('>> loadModel:', id)
    if (this.models[id]) return this.models[id]
    const { id: modelId, url: modelUrl, name } = modelData
    const url = this.persistence.getContentUrl(modelUrl)
    try {
      const container = await SceneLoader.LoadAssetContainerAsync(
        url,
        undefined,
        undefined,
        ({ lengthComputable, loaded, total }) => {
          if (lengthComputable) appState.setLoading(id, loaded / total)
          else appState.setLoading(id, 0.5)
        },
      )
      appState.clearLoading(id)
      if (!container) {
        console.error('* No container loaded for:', modelData)
      }
      container.createRootMesh()
      const scale = autoScaleModel(container.meshes[0])
      // save a copy of the rootNode descendants to use as ref and avoid problems
      // if a tool modifies the model tree at runtime
      const rootNode = this.getRootNode(container)
      rootNode._descendants = [...rootNode.getDescendants()]
      // NOTE: instance meshes are problematic. Let's not deal with then (yet).
      // replaceInstancesWithClones(container, this.getRootNode(container))
      this.models[id] = { id, modelId, url, scale, name, container }
      return container
    } catch (e) {
      console.error(e)
      logCritical(
        `Error loading model ${name} | ${modelId} | ${modelUrl}: ${e}`,
        'editorV3',
        'modelRepository/loadModel',
        rootStore.toJSON(),
      )
      appState.clearLoading(id)
      toaster.show({
        icon: 'warning-sign',
        intent: 'warning',
        message:
          'It was not possible to load the model. Make sure it is a correct GLTF or GLB file.',
        timeout: 5000,
      })
      return null
    }
  }

  getModelScale(id) {
    if (!this.models[id]) throw new Error(`Unloaded model: ${id}`)
    return this.models[id].scale
  }

  getScaleForNode(node) {
    const id = this.findIdForNode(node)
    return this.getModelScale(id)
  }

  addModelToScene(id) {
    // it is an Error to try to add something that doesn't exist
    if (!this.models[id]) throw new Error(`Unloaded model: ${id}`)
    try {
      this.models[id].container.addAllToScene()
      this.sceneManager.projectShadows(this.findNodeForId(id))
    } catch (e) {
      toaster.show({
        icon: 'warning-sign',
        intent: 'warning',
        message: 'It was not possible to load the model',
        timeout: 1500,
      })
      console.log('* Error loading model:', e)
    }
  }

  removeModelFromScene(id) {
    // is *not* a problem to try to remove something that doesn't exist
    if (!this.models[id]) return
    this.sceneManager.disposeShadows(this.findNodeForId(id))
    this.models[id].container.removeAllFromScene()
  }

  findIdForNode(node) {
    for (const model of values(this.models)) {
      const rootNode = this.getRootNode(model.container)
      if (rootNode === node || rootNode.getDescendants().includes(node))
        return model.id
    }
    return null
  }

  findNodeForId(id) {
    return this.getRootNode(this.models[id].container)
  }

  getRootNode(container) {
    // - a rootMesh is generated on loading and unshifted to the array
    // - I'm assuming the next node is the glb root
    return container.meshes[1]
  }

  disposeModel(id) {
    const model = this.models[id]
    if (!model) return
    model.container.dispose()
    delete this.models[id]
  }

  disposeAllModels() {
    for (const id of keys(this.models)) this.disposeModel(id)
  }

  disposeOrphans() {
    const steps = this.training.getAllSteps()
    const allIds = map(
      flatMap(steps, step => step.getAllObjects()),
      'id',
    )
    const uniqIds = uniq(allIds)
    for (const id of keys(this.models))
      if (!includes(uniqIds, id)) this.disposeModel(id)
  }
}

export default ModelRepository
