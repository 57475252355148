{
  "version": "2.0",
  "metadata": {
    "apiVersion": "2020-11-19",
    "endpointPrefix": "geo",
    "jsonVersion": "1.1",
    "protocol": "rest-json",
    "serviceFullName": "Amazon Location Service",
    "serviceId": "Location",
    "signatureVersion": "v4",
    "signingName": "geo",
    "uid": "location-2020-11-19"
  },
  "operations": {
    "AssociateTrackerConsumer": {
      "http": {
        "requestUri": "/tracking/v0/trackers/{TrackerName}/consumers",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "ConsumerArn",
          "TrackerName"
        ],
        "members": {
          "ConsumerArn": {},
          "TrackerName": {
            "location": "uri",
            "locationName": "TrackerName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "endpoint": {
        "hostPrefix": "tracking."
      }
    },
    "BatchDeleteDevicePositionHistory": {
      "http": {
        "requestUri": "/tracking/v0/trackers/{TrackerName}/delete-positions",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "DeviceIds",
          "TrackerName"
        ],
        "members": {
          "DeviceIds": {
            "type": "list",
            "member": {}
          },
          "TrackerName": {
            "location": "uri",
            "locationName": "TrackerName"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "Errors"
        ],
        "members": {
          "Errors": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "DeviceId",
                "Error"
              ],
              "members": {
                "DeviceId": {},
                "Error": {
                  "shape": "Sb"
                }
              }
            }
          }
        }
      },
      "endpoint": {
        "hostPrefix": "tracking."
      }
    },
    "BatchDeleteGeofence": {
      "http": {
        "requestUri": "/geofencing/v0/collections/{CollectionName}/delete-geofences",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "CollectionName",
          "GeofenceIds"
        ],
        "members": {
          "CollectionName": {
            "location": "uri",
            "locationName": "CollectionName"
          },
          "GeofenceIds": {
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "Errors"
        ],
        "members": {
          "Errors": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "Error",
                "GeofenceId"
              ],
              "members": {
                "Error": {
                  "shape": "Sb"
                },
                "GeofenceId": {}
              }
            }
          }
        }
      },
      "endpoint": {
        "hostPrefix": "geofencing."
      }
    },
    "BatchEvaluateGeofences": {
      "http": {
        "requestUri": "/geofencing/v0/collections/{CollectionName}/positions",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "CollectionName",
          "DevicePositionUpdates"
        ],
        "members": {
          "CollectionName": {
            "location": "uri",
            "locationName": "CollectionName"
          },
          "DevicePositionUpdates": {
            "type": "list",
            "member": {
              "shape": "Sl"
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "Errors"
        ],
        "members": {
          "Errors": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "DeviceId",
                "Error",
                "SampleTime"
              ],
              "members": {
                "DeviceId": {},
                "Error": {
                  "shape": "Sb"
                },
                "SampleTime": {
                  "shape": "So"
                }
              }
            }
          }
        }
      },
      "endpoint": {
        "hostPrefix": "geofencing."
      }
    },
    "BatchGetDevicePosition": {
      "http": {
        "requestUri": "/tracking/v0/trackers/{TrackerName}/get-positions",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "DeviceIds",
          "TrackerName"
        ],
        "members": {
          "DeviceIds": {
            "type": "list",
            "member": {}
          },
          "TrackerName": {
            "location": "uri",
            "locationName": "TrackerName"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "DevicePositions",
          "Errors"
        ],
        "members": {
          "DevicePositions": {
            "shape": "Sw"
          },
          "Errors": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "DeviceId",
                "Error"
              ],
              "members": {
                "DeviceId": {},
                "Error": {
                  "shape": "Sb"
                }
              }
            }
          }
        }
      },
      "endpoint": {
        "hostPrefix": "tracking."
      }
    },
    "BatchPutGeofence": {
      "http": {
        "requestUri": "/geofencing/v0/collections/{CollectionName}/put-geofences",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "CollectionName",
          "Entries"
        ],
        "members": {
          "CollectionName": {
            "location": "uri",
            "locationName": "CollectionName"
          },
          "Entries": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "GeofenceId",
                "Geometry"
              ],
              "members": {
                "GeofenceId": {},
                "Geometry": {
                  "shape": "S13"
                }
              }
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "Errors",
          "Successes"
        ],
        "members": {
          "Errors": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "Error",
                "GeofenceId"
              ],
              "members": {
                "Error": {
                  "shape": "Sb"
                },
                "GeofenceId": {}
              }
            }
          },
          "Successes": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "CreateTime",
                "GeofenceId",
                "UpdateTime"
              ],
              "members": {
                "CreateTime": {
                  "shape": "So"
                },
                "GeofenceId": {},
                "UpdateTime": {
                  "shape": "So"
                }
              }
            }
          }
        }
      },
      "endpoint": {
        "hostPrefix": "geofencing."
      }
    },
    "BatchUpdateDevicePosition": {
      "http": {
        "requestUri": "/tracking/v0/trackers/{TrackerName}/positions",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "TrackerName",
          "Updates"
        ],
        "members": {
          "TrackerName": {
            "location": "uri",
            "locationName": "TrackerName"
          },
          "Updates": {
            "type": "list",
            "member": {
              "shape": "Sl"
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "Errors"
        ],
        "members": {
          "Errors": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "DeviceId",
                "Error",
                "SampleTime"
              ],
              "members": {
                "DeviceId": {},
                "Error": {
                  "shape": "Sb"
                },
                "SampleTime": {
                  "shape": "So"
                }
              }
            }
          }
        }
      },
      "endpoint": {
        "hostPrefix": "tracking."
      }
    },
    "CalculateRoute": {
      "http": {
        "requestUri": "/routes/v0/calculators/{CalculatorName}/calculate/route",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "CalculatorName",
          "DeparturePosition",
          "DestinationPosition"
        ],
        "members": {
          "CalculatorName": {
            "location": "uri",
            "locationName": "CalculatorName"
          },
          "CarModeOptions": {
            "type": "structure",
            "members": {
              "AvoidFerries": {
                "type": "boolean"
              },
              "AvoidTolls": {
                "type": "boolean"
              }
            }
          },
          "DepartNow": {
            "type": "boolean"
          },
          "DeparturePosition": {
            "shape": "Sm"
          },
          "DepartureTime": {
            "shape": "So"
          },
          "DestinationPosition": {
            "shape": "Sm"
          },
          "DistanceUnit": {},
          "IncludeLegGeometry": {
            "type": "boolean"
          },
          "TravelMode": {},
          "TruckModeOptions": {
            "type": "structure",
            "members": {
              "AvoidFerries": {
                "type": "boolean"
              },
              "AvoidTolls": {
                "type": "boolean"
              },
              "Dimensions": {
                "type": "structure",
                "members": {
                  "Height": {
                    "type": "double"
                  },
                  "Length": {
                    "type": "double"
                  },
                  "Unit": {},
                  "Width": {
                    "type": "double"
                  }
                }
              },
              "Weight": {
                "type": "structure",
                "members": {
                  "Total": {
                    "type": "double"
                  },
                  "Unit": {}
                }
              }
            }
          },
          "WaypointPositions": {
            "type": "list",
            "member": {
              "shape": "Sm"
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "Legs",
          "Summary"
        ],
        "members": {
          "Legs": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "Distance",
                "DurationSeconds",
                "EndPosition",
                "StartPosition",
                "Steps"
              ],
              "members": {
                "Distance": {
                  "type": "double"
                },
                "DurationSeconds": {
                  "type": "double"
                },
                "EndPosition": {
                  "shape": "Sm"
                },
                "Geometry": {
                  "type": "structure",
                  "members": {
                    "LineString": {
                      "type": "list",
                      "member": {
                        "shape": "Sm"
                      }
                    }
                  }
                },
                "StartPosition": {
                  "shape": "Sm"
                },
                "Steps": {
                  "type": "list",
                  "member": {
                    "type": "structure",
                    "required": [
                      "Distance",
                      "DurationSeconds",
                      "EndPosition",
                      "StartPosition"
                    ],
                    "members": {
                      "Distance": {
                        "type": "double"
                      },
                      "DurationSeconds": {
                        "type": "double"
                      },
                      "EndPosition": {
                        "shape": "Sm"
                      },
                      "GeometryOffset": {
                        "type": "integer"
                      },
                      "StartPosition": {
                        "shape": "Sm"
                      }
                    }
                  }
                }
              }
            }
          },
          "Summary": {
            "type": "structure",
            "required": [
              "DataSource",
              "Distance",
              "DistanceUnit",
              "DurationSeconds",
              "RouteBBox"
            ],
            "members": {
              "DataSource": {},
              "Distance": {
                "type": "double"
              },
              "DistanceUnit": {},
              "DurationSeconds": {
                "type": "double"
              },
              "RouteBBox": {
                "shape": "S2a"
              }
            }
          }
        }
      },
      "endpoint": {
        "hostPrefix": "routes."
      }
    },
    "CreateGeofenceCollection": {
      "http": {
        "requestUri": "/geofencing/v0/collections",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "CollectionName",
          "PricingPlan"
        ],
        "members": {
          "CollectionName": {},
          "Description": {},
          "KmsKeyId": {},
          "PricingPlan": {},
          "PricingPlanDataSource": {},
          "Tags": {
            "shape": "S2f"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "CollectionArn",
          "CollectionName",
          "CreateTime"
        ],
        "members": {
          "CollectionArn": {},
          "CollectionName": {},
          "CreateTime": {
            "shape": "So"
          }
        }
      },
      "endpoint": {
        "hostPrefix": "geofencing."
      },
      "idempotent": true
    },
    "CreateMap": {
      "http": {
        "requestUri": "/maps/v0/maps",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "Configuration",
          "MapName",
          "PricingPlan"
        ],
        "members": {
          "Configuration": {
            "shape": "S2k"
          },
          "Description": {},
          "MapName": {},
          "PricingPlan": {},
          "Tags": {
            "shape": "S2f"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "CreateTime",
          "MapArn",
          "MapName"
        ],
        "members": {
          "CreateTime": {
            "shape": "So"
          },
          "MapArn": {},
          "MapName": {}
        }
      },
      "endpoint": {
        "hostPrefix": "maps."
      },
      "idempotent": true
    },
    "CreatePlaceIndex": {
      "http": {
        "requestUri": "/places/v0/indexes",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "DataSource",
          "IndexName",
          "PricingPlan"
        ],
        "members": {
          "DataSource": {},
          "DataSourceConfiguration": {
            "shape": "S2o"
          },
          "Description": {},
          "IndexName": {},
          "PricingPlan": {},
          "Tags": {
            "shape": "S2f"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "CreateTime",
          "IndexArn",
          "IndexName"
        ],
        "members": {
          "CreateTime": {
            "shape": "So"
          },
          "IndexArn": {},
          "IndexName": {}
        }
      },
      "endpoint": {
        "hostPrefix": "places."
      },
      "idempotent": true
    },
    "CreateRouteCalculator": {
      "http": {
        "requestUri": "/routes/v0/calculators",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "CalculatorName",
          "DataSource",
          "PricingPlan"
        ],
        "members": {
          "CalculatorName": {},
          "DataSource": {},
          "Description": {},
          "PricingPlan": {},
          "Tags": {
            "shape": "S2f"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "CalculatorArn",
          "CalculatorName",
          "CreateTime"
        ],
        "members": {
          "CalculatorArn": {},
          "CalculatorName": {},
          "CreateTime": {
            "shape": "So"
          }
        }
      },
      "endpoint": {
        "hostPrefix": "routes."
      },
      "idempotent": true
    },
    "CreateTracker": {
      "http": {
        "requestUri": "/tracking/v0/trackers",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "PricingPlan",
          "TrackerName"
        ],
        "members": {
          "Description": {},
          "KmsKeyId": {},
          "PositionFiltering": {},
          "PricingPlan": {},
          "PricingPlanDataSource": {},
          "Tags": {
            "shape": "S2f"
          },
          "TrackerName": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "CreateTime",
          "TrackerArn",
          "TrackerName"
        ],
        "members": {
          "CreateTime": {
            "shape": "So"
          },
          "TrackerArn": {},
          "TrackerName": {}
        }
      },
      "endpoint": {
        "hostPrefix": "tracking."
      },
      "idempotent": true
    },
    "DeleteGeofenceCollection": {
      "http": {
        "method": "DELETE",
        "requestUri": "/geofencing/v0/collections/{CollectionName}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "CollectionName"
        ],
        "members": {
          "CollectionName": {
            "location": "uri",
            "locationName": "CollectionName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "endpoint": {
        "hostPrefix": "geofencing."
      },
      "idempotent": true
    },
    "DeleteMap": {
      "http": {
        "method": "DELETE",
        "requestUri": "/maps/v0/maps/{MapName}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "MapName"
        ],
        "members": {
          "MapName": {
            "location": "uri",
            "locationName": "MapName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "endpoint": {
        "hostPrefix": "maps."
      },
      "idempotent": true
    },
    "DeletePlaceIndex": {
      "http": {
        "method": "DELETE",
        "requestUri": "/places/v0/indexes/{IndexName}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "IndexName"
        ],
        "members": {
          "IndexName": {
            "location": "uri",
            "locationName": "IndexName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "endpoint": {
        "hostPrefix": "places."
      },
      "idempotent": true
    },
    "DeleteRouteCalculator": {
      "http": {
        "method": "DELETE",
        "requestUri": "/routes/v0/calculators/{CalculatorName}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "CalculatorName"
        ],
        "members": {
          "CalculatorName": {
            "location": "uri",
            "locationName": "CalculatorName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "endpoint": {
        "hostPrefix": "routes."
      },
      "idempotent": true
    },
    "DeleteTracker": {
      "http": {
        "method": "DELETE",
        "requestUri": "/tracking/v0/trackers/{TrackerName}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "TrackerName"
        ],
        "members": {
          "TrackerName": {
            "location": "uri",
            "locationName": "TrackerName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "endpoint": {
        "hostPrefix": "tracking."
      },
      "idempotent": true
    },
    "DescribeGeofenceCollection": {
      "http": {
        "method": "GET",
        "requestUri": "/geofencing/v0/collections/{CollectionName}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "CollectionName"
        ],
        "members": {
          "CollectionName": {
            "location": "uri",
            "locationName": "CollectionName"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "CollectionArn",
          "CollectionName",
          "CreateTime",
          "Description",
          "PricingPlan",
          "UpdateTime"
        ],
        "members": {
          "CollectionArn": {},
          "CollectionName": {},
          "CreateTime": {
            "shape": "So"
          },
          "Description": {},
          "KmsKeyId": {},
          "PricingPlan": {},
          "PricingPlanDataSource": {},
          "Tags": {
            "shape": "S2f"
          },
          "UpdateTime": {
            "shape": "So"
          }
        }
      },
      "endpoint": {
        "hostPrefix": "geofencing."
      }
    },
    "DescribeMap": {
      "http": {
        "method": "GET",
        "requestUri": "/maps/v0/maps/{MapName}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "MapName"
        ],
        "members": {
          "MapName": {
            "location": "uri",
            "locationName": "MapName"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "Configuration",
          "CreateTime",
          "DataSource",
          "Description",
          "MapArn",
          "MapName",
          "PricingPlan",
          "UpdateTime"
        ],
        "members": {
          "Configuration": {
            "shape": "S2k"
          },
          "CreateTime": {
            "shape": "So"
          },
          "DataSource": {},
          "Description": {},
          "MapArn": {},
          "MapName": {},
          "PricingPlan": {},
          "Tags": {
            "shape": "S2f"
          },
          "UpdateTime": {
            "shape": "So"
          }
        }
      },
      "endpoint": {
        "hostPrefix": "maps."
      }
    },
    "DescribePlaceIndex": {
      "http": {
        "method": "GET",
        "requestUri": "/places/v0/indexes/{IndexName}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "IndexName"
        ],
        "members": {
          "IndexName": {
            "location": "uri",
            "locationName": "IndexName"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "CreateTime",
          "DataSource",
          "DataSourceConfiguration",
          "Description",
          "IndexArn",
          "IndexName",
          "PricingPlan",
          "UpdateTime"
        ],
        "members": {
          "CreateTime": {
            "shape": "So"
          },
          "DataSource": {},
          "DataSourceConfiguration": {
            "shape": "S2o"
          },
          "Description": {},
          "IndexArn": {},
          "IndexName": {},
          "PricingPlan": {},
          "Tags": {
            "shape": "S2f"
          },
          "UpdateTime": {
            "shape": "So"
          }
        }
      },
      "endpoint": {
        "hostPrefix": "places."
      }
    },
    "DescribeRouteCalculator": {
      "http": {
        "method": "GET",
        "requestUri": "/routes/v0/calculators/{CalculatorName}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "CalculatorName"
        ],
        "members": {
          "CalculatorName": {
            "location": "uri",
            "locationName": "CalculatorName"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "CalculatorArn",
          "CalculatorName",
          "CreateTime",
          "DataSource",
          "Description",
          "PricingPlan",
          "UpdateTime"
        ],
        "members": {
          "CalculatorArn": {},
          "CalculatorName": {},
          "CreateTime": {
            "shape": "So"
          },
          "DataSource": {},
          "Description": {},
          "PricingPlan": {},
          "Tags": {
            "shape": "S2f"
          },
          "UpdateTime": {
            "shape": "So"
          }
        }
      },
      "endpoint": {
        "hostPrefix": "routes."
      }
    },
    "DescribeTracker": {
      "http": {
        "method": "GET",
        "requestUri": "/tracking/v0/trackers/{TrackerName}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "TrackerName"
        ],
        "members": {
          "TrackerName": {
            "location": "uri",
            "locationName": "TrackerName"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "CreateTime",
          "Description",
          "PricingPlan",
          "TrackerArn",
          "TrackerName",
          "UpdateTime"
        ],
        "members": {
          "CreateTime": {
            "shape": "So"
          },
          "Description": {},
          "KmsKeyId": {},
          "PositionFiltering": {},
          "PricingPlan": {},
          "PricingPlanDataSource": {},
          "Tags": {
            "shape": "S2f"
          },
          "TrackerArn": {},
          "TrackerName": {},
          "UpdateTime": {
            "shape": "So"
          }
        }
      },
      "endpoint": {
        "hostPrefix": "tracking."
      }
    },
    "DisassociateTrackerConsumer": {
      "http": {
        "method": "DELETE",
        "requestUri": "/tracking/v0/trackers/{TrackerName}/consumers/{ConsumerArn}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "ConsumerArn",
          "TrackerName"
        ],
        "members": {
          "ConsumerArn": {
            "location": "uri",
            "locationName": "ConsumerArn"
          },
          "TrackerName": {
            "location": "uri",
            "locationName": "TrackerName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "endpoint": {
        "hostPrefix": "tracking."
      }
    },
    "GetDevicePosition": {
      "http": {
        "method": "GET",
        "requestUri": "/tracking/v0/trackers/{TrackerName}/devices/{DeviceId}/positions/latest",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "DeviceId",
          "TrackerName"
        ],
        "members": {
          "DeviceId": {
            "location": "uri",
            "locationName": "DeviceId"
          },
          "TrackerName": {
            "location": "uri",
            "locationName": "TrackerName"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "Position",
          "ReceivedTime",
          "SampleTime"
        ],
        "members": {
          "DeviceId": {},
          "Position": {
            "shape": "Sm"
          },
          "ReceivedTime": {
            "shape": "So"
          },
          "SampleTime": {
            "shape": "So"
          }
        }
      },
      "endpoint": {
        "hostPrefix": "tracking."
      }
    },
    "GetDevicePositionHistory": {
      "http": {
        "requestUri": "/tracking/v0/trackers/{TrackerName}/devices/{DeviceId}/list-positions",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "DeviceId",
          "TrackerName"
        ],
        "members": {
          "DeviceId": {
            "location": "uri",
            "locationName": "DeviceId"
          },
          "EndTimeExclusive": {
            "shape": "So"
          },
          "NextToken": {},
          "StartTimeInclusive": {
            "shape": "So"
          },
          "TrackerName": {
            "location": "uri",
            "locationName": "TrackerName"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "DevicePositions"
        ],
        "members": {
          "DevicePositions": {
            "shape": "Sw"
          },
          "NextToken": {}
        }
      },
      "endpoint": {
        "hostPrefix": "tracking."
      }
    },
    "GetGeofence": {
      "http": {
        "method": "GET",
        "requestUri": "/geofencing/v0/collections/{CollectionName}/geofences/{GeofenceId}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "CollectionName",
          "GeofenceId"
        ],
        "members": {
          "CollectionName": {
            "location": "uri",
            "locationName": "CollectionName"
          },
          "GeofenceId": {
            "location": "uri",
            "locationName": "GeofenceId"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "CreateTime",
          "GeofenceId",
          "Geometry",
          "Status",
          "UpdateTime"
        ],
        "members": {
          "CreateTime": {
            "shape": "So"
          },
          "GeofenceId": {},
          "Geometry": {
            "shape": "S13"
          },
          "Status": {},
          "UpdateTime": {
            "shape": "So"
          }
        }
      },
      "endpoint": {
        "hostPrefix": "geofencing."
      }
    },
    "GetMapGlyphs": {
      "http": {
        "method": "GET",
        "requestUri": "/maps/v0/maps/{MapName}/glyphs/{FontStack}/{FontUnicodeRange}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "FontStack",
          "FontUnicodeRange",
          "MapName"
        ],
        "members": {
          "FontStack": {
            "location": "uri",
            "locationName": "FontStack"
          },
          "FontUnicodeRange": {
            "location": "uri",
            "locationName": "FontUnicodeRange"
          },
          "MapName": {
            "location": "uri",
            "locationName": "MapName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Blob": {
            "type": "blob"
          },
          "ContentType": {
            "location": "header",
            "locationName": "Content-Type"
          }
        },
        "payload": "Blob"
      },
      "endpoint": {
        "hostPrefix": "maps."
      }
    },
    "GetMapSprites": {
      "http": {
        "method": "GET",
        "requestUri": "/maps/v0/maps/{MapName}/sprites/{FileName}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "FileName",
          "MapName"
        ],
        "members": {
          "FileName": {
            "location": "uri",
            "locationName": "FileName"
          },
          "MapName": {
            "location": "uri",
            "locationName": "MapName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Blob": {
            "type": "blob"
          },
          "ContentType": {
            "location": "header",
            "locationName": "Content-Type"
          }
        },
        "payload": "Blob"
      },
      "endpoint": {
        "hostPrefix": "maps."
      }
    },
    "GetMapStyleDescriptor": {
      "http": {
        "method": "GET",
        "requestUri": "/maps/v0/maps/{MapName}/style-descriptor",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "MapName"
        ],
        "members": {
          "MapName": {
            "location": "uri",
            "locationName": "MapName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Blob": {
            "type": "blob"
          },
          "ContentType": {
            "location": "header",
            "locationName": "Content-Type"
          }
        },
        "payload": "Blob"
      },
      "endpoint": {
        "hostPrefix": "maps."
      }
    },
    "GetMapTile": {
      "http": {
        "method": "GET",
        "requestUri": "/maps/v0/maps/{MapName}/tiles/{Z}/{X}/{Y}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "MapName",
          "X",
          "Y",
          "Z"
        ],
        "members": {
          "MapName": {
            "location": "uri",
            "locationName": "MapName"
          },
          "X": {
            "location": "uri",
            "locationName": "X"
          },
          "Y": {
            "location": "uri",
            "locationName": "Y"
          },
          "Z": {
            "location": "uri",
            "locationName": "Z"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Blob": {
            "type": "blob"
          },
          "ContentType": {
            "location": "header",
            "locationName": "Content-Type"
          }
        },
        "payload": "Blob"
      },
      "endpoint": {
        "hostPrefix": "maps."
      }
    },
    "ListDevicePositions": {
      "http": {
        "requestUri": "/tracking/v0/trackers/{TrackerName}/list-positions",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "TrackerName"
        ],
        "members": {
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {},
          "TrackerName": {
            "location": "uri",
            "locationName": "TrackerName"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "Entries"
        ],
        "members": {
          "Entries": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "DeviceId",
                "Position",
                "SampleTime"
              ],
              "members": {
                "DeviceId": {},
                "Position": {
                  "shape": "Sm"
                },
                "SampleTime": {
                  "shape": "So"
                }
              }
            }
          },
          "NextToken": {}
        }
      },
      "endpoint": {
        "hostPrefix": "tracking."
      }
    },
    "ListGeofenceCollections": {
      "http": {
        "requestUri": "/geofencing/v0/list-collections",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "Entries"
        ],
        "members": {
          "Entries": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "CollectionName",
                "CreateTime",
                "Description",
                "PricingPlan",
                "UpdateTime"
              ],
              "members": {
                "CollectionName": {},
                "CreateTime": {
                  "shape": "So"
                },
                "Description": {},
                "PricingPlan": {},
                "PricingPlanDataSource": {},
                "UpdateTime": {
                  "shape": "So"
                }
              }
            }
          },
          "NextToken": {}
        }
      },
      "endpoint": {
        "hostPrefix": "geofencing."
      }
    },
    "ListGeofences": {
      "http": {
        "requestUri": "/geofencing/v0/collections/{CollectionName}/list-geofences",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "CollectionName"
        ],
        "members": {
          "CollectionName": {
            "location": "uri",
            "locationName": "CollectionName"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "Entries"
        ],
        "members": {
          "Entries": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "CreateTime",
                "GeofenceId",
                "Geometry",
                "Status",
                "UpdateTime"
              ],
              "members": {
                "CreateTime": {
                  "shape": "So"
                },
                "GeofenceId": {},
                "Geometry": {
                  "shape": "S13"
                },
                "Status": {},
                "UpdateTime": {
                  "shape": "So"
                }
              }
            }
          },
          "NextToken": {}
        }
      },
      "endpoint": {
        "hostPrefix": "geofencing."
      }
    },
    "ListMaps": {
      "http": {
        "requestUri": "/maps/v0/list-maps",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "Entries"
        ],
        "members": {
          "Entries": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "CreateTime",
                "DataSource",
                "Description",
                "MapName",
                "PricingPlan",
                "UpdateTime"
              ],
              "members": {
                "CreateTime": {
                  "shape": "So"
                },
                "DataSource": {},
                "Description": {},
                "MapName": {},
                "PricingPlan": {},
                "UpdateTime": {
                  "shape": "So"
                }
              }
            }
          },
          "NextToken": {}
        }
      },
      "endpoint": {
        "hostPrefix": "maps."
      }
    },
    "ListPlaceIndexes": {
      "http": {
        "requestUri": "/places/v0/list-indexes",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "Entries"
        ],
        "members": {
          "Entries": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "CreateTime",
                "DataSource",
                "Description",
                "IndexName",
                "PricingPlan",
                "UpdateTime"
              ],
              "members": {
                "CreateTime": {
                  "shape": "So"
                },
                "DataSource": {},
                "Description": {},
                "IndexName": {},
                "PricingPlan": {},
                "UpdateTime": {
                  "shape": "So"
                }
              }
            }
          },
          "NextToken": {}
        }
      },
      "endpoint": {
        "hostPrefix": "places."
      }
    },
    "ListRouteCalculators": {
      "http": {
        "requestUri": "/routes/v0/list-calculators",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "Entries"
        ],
        "members": {
          "Entries": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "CalculatorName",
                "CreateTime",
                "DataSource",
                "Description",
                "PricingPlan",
                "UpdateTime"
              ],
              "members": {
                "CalculatorName": {},
                "CreateTime": {
                  "shape": "So"
                },
                "DataSource": {},
                "Description": {},
                "PricingPlan": {},
                "UpdateTime": {
                  "shape": "So"
                }
              }
            }
          },
          "NextToken": {}
        }
      },
      "endpoint": {
        "hostPrefix": "routes."
      }
    },
    "ListTagsForResource": {
      "http": {
        "method": "GET",
        "requestUri": "/tags/{ResourceArn}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "ResourceArn"
        ],
        "members": {
          "ResourceArn": {
            "location": "uri",
            "locationName": "ResourceArn"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Tags": {
            "shape": "S2f"
          }
        }
      },
      "endpoint": {
        "hostPrefix": "metadata."
      }
    },
    "ListTrackerConsumers": {
      "http": {
        "requestUri": "/tracking/v0/trackers/{TrackerName}/list-consumers",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "TrackerName"
        ],
        "members": {
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {},
          "TrackerName": {
            "location": "uri",
            "locationName": "TrackerName"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "ConsumerArns"
        ],
        "members": {
          "ConsumerArns": {
            "type": "list",
            "member": {}
          },
          "NextToken": {}
        }
      },
      "endpoint": {
        "hostPrefix": "tracking."
      }
    },
    "ListTrackers": {
      "http": {
        "requestUri": "/tracking/v0/list-trackers",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "Entries"
        ],
        "members": {
          "Entries": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "CreateTime",
                "Description",
                "PricingPlan",
                "TrackerName",
                "UpdateTime"
              ],
              "members": {
                "CreateTime": {
                  "shape": "So"
                },
                "Description": {},
                "PricingPlan": {},
                "PricingPlanDataSource": {},
                "TrackerName": {},
                "UpdateTime": {
                  "shape": "So"
                }
              }
            }
          },
          "NextToken": {}
        }
      },
      "endpoint": {
        "hostPrefix": "tracking."
      }
    },
    "PutGeofence": {
      "http": {
        "method": "PUT",
        "requestUri": "/geofencing/v0/collections/{CollectionName}/geofences/{GeofenceId}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "CollectionName",
          "GeofenceId",
          "Geometry"
        ],
        "members": {
          "CollectionName": {
            "location": "uri",
            "locationName": "CollectionName"
          },
          "GeofenceId": {
            "location": "uri",
            "locationName": "GeofenceId"
          },
          "Geometry": {
            "shape": "S13"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "CreateTime",
          "GeofenceId",
          "UpdateTime"
        ],
        "members": {
          "CreateTime": {
            "shape": "So"
          },
          "GeofenceId": {},
          "UpdateTime": {
            "shape": "So"
          }
        }
      },
      "endpoint": {
        "hostPrefix": "geofencing."
      }
    },
    "SearchPlaceIndexForPosition": {
      "http": {
        "requestUri": "/places/v0/indexes/{IndexName}/search/position",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "IndexName",
          "Position"
        ],
        "members": {
          "IndexName": {
            "location": "uri",
            "locationName": "IndexName"
          },
          "MaxResults": {
            "type": "integer"
          },
          "Position": {
            "shape": "Sm"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "Results",
          "Summary"
        ],
        "members": {
          "Results": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "Place"
              ],
              "members": {
                "Place": {
                  "shape": "S5e"
                }
              }
            }
          },
          "Summary": {
            "type": "structure",
            "required": [
              "DataSource",
              "Position"
            ],
            "members": {
              "DataSource": {},
              "MaxResults": {
                "type": "integer"
              },
              "Position": {
                "shape": "Sm"
              }
            }
          }
        }
      },
      "endpoint": {
        "hostPrefix": "places."
      }
    },
    "SearchPlaceIndexForText": {
      "http": {
        "requestUri": "/places/v0/indexes/{IndexName}/search/text",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "IndexName",
          "Text"
        ],
        "members": {
          "BiasPosition": {
            "shape": "Sm"
          },
          "FilterBBox": {
            "shape": "S2a"
          },
          "FilterCountries": {
            "shape": "S5i"
          },
          "IndexName": {
            "location": "uri",
            "locationName": "IndexName"
          },
          "MaxResults": {
            "type": "integer"
          },
          "Text": {
            "type": "string",
            "sensitive": true
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "Results",
          "Summary"
        ],
        "members": {
          "Results": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "Place"
              ],
              "members": {
                "Place": {
                  "shape": "S5e"
                }
              }
            }
          },
          "Summary": {
            "type": "structure",
            "required": [
              "DataSource",
              "Text"
            ],
            "members": {
              "BiasPosition": {
                "shape": "Sm"
              },
              "DataSource": {},
              "FilterBBox": {
                "shape": "S2a"
              },
              "FilterCountries": {
                "shape": "S5i"
              },
              "MaxResults": {
                "type": "integer"
              },
              "ResultBBox": {
                "shape": "S2a"
              },
              "Text": {
                "type": "string",
                "sensitive": true
              }
            }
          }
        }
      },
      "endpoint": {
        "hostPrefix": "places."
      }
    },
    "TagResource": {
      "http": {
        "requestUri": "/tags/{ResourceArn}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "ResourceArn",
          "Tags"
        ],
        "members": {
          "ResourceArn": {
            "location": "uri",
            "locationName": "ResourceArn"
          },
          "Tags": {
            "shape": "S2f"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "endpoint": {
        "hostPrefix": "metadata."
      }
    },
    "UntagResource": {
      "http": {
        "method": "DELETE",
        "requestUri": "/tags/{ResourceArn}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "ResourceArn",
          "TagKeys"
        ],
        "members": {
          "ResourceArn": {
            "location": "uri",
            "locationName": "ResourceArn"
          },
          "TagKeys": {
            "location": "querystring",
            "locationName": "tagKeys",
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "endpoint": {
        "hostPrefix": "metadata."
      },
      "idempotent": true
    },
    "UpdateGeofenceCollection": {
      "http": {
        "method": "PATCH",
        "requestUri": "/geofencing/v0/collections/{CollectionName}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "CollectionName"
        ],
        "members": {
          "CollectionName": {
            "location": "uri",
            "locationName": "CollectionName"
          },
          "Description": {},
          "PricingPlan": {},
          "PricingPlanDataSource": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "CollectionArn",
          "CollectionName",
          "UpdateTime"
        ],
        "members": {
          "CollectionArn": {},
          "CollectionName": {},
          "UpdateTime": {
            "shape": "So"
          }
        }
      },
      "endpoint": {
        "hostPrefix": "geofencing."
      },
      "idempotent": true
    },
    "UpdateMap": {
      "http": {
        "method": "PATCH",
        "requestUri": "/maps/v0/maps/{MapName}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "MapName"
        ],
        "members": {
          "Description": {},
          "MapName": {
            "location": "uri",
            "locationName": "MapName"
          },
          "PricingPlan": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "MapArn",
          "MapName",
          "UpdateTime"
        ],
        "members": {
          "MapArn": {},
          "MapName": {},
          "UpdateTime": {
            "shape": "So"
          }
        }
      },
      "endpoint": {
        "hostPrefix": "maps."
      },
      "idempotent": true
    },
    "UpdatePlaceIndex": {
      "http": {
        "method": "PATCH",
        "requestUri": "/places/v0/indexes/{IndexName}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "IndexName"
        ],
        "members": {
          "DataSourceConfiguration": {
            "shape": "S2o"
          },
          "Description": {},
          "IndexName": {
            "location": "uri",
            "locationName": "IndexName"
          },
          "PricingPlan": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "IndexArn",
          "IndexName",
          "UpdateTime"
        ],
        "members": {
          "IndexArn": {},
          "IndexName": {},
          "UpdateTime": {
            "shape": "So"
          }
        }
      },
      "endpoint": {
        "hostPrefix": "places."
      },
      "idempotent": true
    },
    "UpdateRouteCalculator": {
      "http": {
        "method": "PATCH",
        "requestUri": "/routes/v0/calculators/{CalculatorName}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "CalculatorName"
        ],
        "members": {
          "CalculatorName": {
            "location": "uri",
            "locationName": "CalculatorName"
          },
          "Description": {},
          "PricingPlan": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "CalculatorArn",
          "CalculatorName",
          "UpdateTime"
        ],
        "members": {
          "CalculatorArn": {},
          "CalculatorName": {},
          "UpdateTime": {
            "shape": "So"
          }
        }
      },
      "endpoint": {
        "hostPrefix": "routes."
      },
      "idempotent": true
    },
    "UpdateTracker": {
      "http": {
        "method": "PATCH",
        "requestUri": "/tracking/v0/trackers/{TrackerName}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "TrackerName"
        ],
        "members": {
          "Description": {},
          "PositionFiltering": {},
          "PricingPlan": {},
          "PricingPlanDataSource": {},
          "TrackerName": {
            "location": "uri",
            "locationName": "TrackerName"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "TrackerArn",
          "TrackerName",
          "UpdateTime"
        ],
        "members": {
          "TrackerArn": {},
          "TrackerName": {},
          "UpdateTime": {
            "shape": "So"
          }
        }
      },
      "endpoint": {
        "hostPrefix": "tracking."
      },
      "idempotent": true
    }
  },
  "shapes": {
    "Sb": {
      "type": "structure",
      "members": {
        "Code": {},
        "Message": {}
      }
    },
    "Sl": {
      "type": "structure",
      "required": [
        "DeviceId",
        "Position",
        "SampleTime"
      ],
      "members": {
        "DeviceId": {},
        "Position": {
          "shape": "Sm"
        },
        "SampleTime": {
          "shape": "So"
        }
      }
    },
    "Sm": {
      "type": "list",
      "member": {
        "type": "double"
      },
      "sensitive": true
    },
    "So": {
      "type": "timestamp",
      "timestampFormat": "iso8601"
    },
    "Sw": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "Position",
          "ReceivedTime",
          "SampleTime"
        ],
        "members": {
          "DeviceId": {},
          "Position": {
            "shape": "Sm"
          },
          "ReceivedTime": {
            "shape": "So"
          },
          "SampleTime": {
            "shape": "So"
          }
        }
      }
    },
    "S13": {
      "type": "structure",
      "members": {
        "Polygon": {
          "type": "list",
          "member": {
            "type": "list",
            "member": {
              "shape": "Sm"
            }
          }
        }
      }
    },
    "S2a": {
      "type": "list",
      "member": {
        "type": "double"
      },
      "sensitive": true
    },
    "S2f": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "S2k": {
      "type": "structure",
      "required": [
        "Style"
      ],
      "members": {
        "Style": {}
      }
    },
    "S2o": {
      "type": "structure",
      "members": {
        "IntendedUse": {}
      }
    },
    "S5e": {
      "type": "structure",
      "required": [
        "Geometry"
      ],
      "members": {
        "AddressNumber": {},
        "Country": {},
        "Geometry": {
          "type": "structure",
          "members": {
            "Point": {
              "shape": "Sm"
            }
          }
        },
        "Label": {},
        "Municipality": {},
        "Neighborhood": {},
        "PostalCode": {},
        "Region": {},
        "Street": {},
        "SubRegion": {}
      }
    },
    "S5i": {
      "type": "list",
      "member": {}
    }
  }
}