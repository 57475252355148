import { config, S3 } from 'aws-sdk'
import { getCredentials } from '../auth/identity'
import { region, userContentBucket } from '../config/aws-exports'
import { tempLinkDuration } from '../config/file-system'
import { globalOptions } from '../options'
import { fileSystemOptions as options } from './options'

// @TODO remove this from here, belongs on user-content.js
const defaultBucket = userContentBucket
let s3 = undefined
let initialized = false

export async function updateOptions(opts) {
  Object.assign(options, globalOptions, options, opts)
}

export async function initializeFileSystem(forceRefresh = false, opts = {}) {
  if (initialized && !forceRefresh) return
  initialized = true
  updateOptions(opts)
  await initializeS3(forceRefresh)
}

export async function initializeS3(forceRefresh = false) {
  if (s3 === undefined || forceRefresh) {
    config.update({
      credentials: await getCredentials(options),
      correctClockSkew: true,
      region: region,
    })

    s3 = new S3({
      apiVersion: '2006-03-01',
      params: { Bucket: defaultBucket },
    })
  }
}

export async function retrieveRawFile(filePath, bucket) {
  await initializeS3()
  const res = await s3
    .getObject({
      Bucket: bucket || defaultBucket,
      Key: filePath,
    })
    .promise()
  return res.Body
}

export async function retrieveFile(filePath, bucket) {
  try {
    const body = await retrieveRawFile(filePath, bucket)
    return body ? body.toString() : ''
  } catch (e) {
    console.error(e)
    return ''
  }
}

export async function retrieveJson(filePath, bucket) {
  try {
    const contents = await retrieveFile(filePath, bucket)
    return contents !== '' ? JSON.parse(contents) : {}
  } catch (e) {
    return {}
  }
}

export async function persistFile(filePath, body, bucket) {
  await initializeS3()
  try {
    await s3
      .putObject({
        Key: filePath,
        Body: body,
        Bucket: bucket || defaultBucket,
      })
      .promise()
  } catch (e) {
    console.error(e)
  }
}

export async function persistJson(filePath, data, bucket) {
  return persistFile(filePath, JSON.stringify(data), bucket)
}

export async function updateJson(filePath, handler, bucket) {
  const content = await retrieveJson(filePath)
  const updated = handler(content)
  await persistJson(filePath, updated, bucket)
}

export async function removeFile(filePath, bucket) {
  await initializeS3()
  try {
    await s3
      .deleteObject({ Key: filePath, Bucket: bucket || defaultBucket })
      .promise()
  } catch (e) {
    console.log(e)
  }
}

export async function uploadFile(file, filePath, progressHandler, bucket) {
  const managedUpload = new S3.ManagedUpload({
    params: {
      Bucket: bucket || defaultBucket,
      Key: filePath,
      Body: file,
    },
  })
  if (progressHandler) {
    managedUpload.on('httpUploadProgress', ({ total, loaded }) => {
      progressHandler(loaded / total)
    })
  }
  const result = await managedUpload.promise()
  return result
}

export async function getTempLink(filePath, expiration, bucket) {
  const params = {
    Bucket: bucket || defaultBucket,
    Key: filePath,
    Expires: expiration || tempLinkDuration,
  }
  const url = await s3.getSignedUrlPromise('getObject', params)
  return url
}
