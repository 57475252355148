import React, { useState, useRef } from 'react'
import { action } from 'mobx'
import { observer } from 'mobx-react-lite'
import { Card, Alert, Button, Spinner } from '@blueprintjs/core'
import {
  useStep,
  useStepCount,
  useSelectedStepId,
  useRemoveStep,
  useMoveStep,
  useSaveCamera,
} from '~/src/hooks/training'
import { useStore } from '~/src/app/store'
import cx from 'classnames'
import CustomIcon from '~/src/app/components/Icon'

const StepThumbnail = ({ stepId }) => {
  const { appState } = useStore()
  const [stepToDelete, setStepToDelete] = useState(false)
  const [selectedStepId, setSelectedStepId] = useSelectedStepId()
  const canRemoveStep = useStepCount() > 1
  const canSaveCamera = selectedStepId === stepId
  const removeStep = useRemoveStep()
  const step = useStep(stepId)
  const confirmDeleteStep = action(() => {
    removeStep(stepToDelete)
    setStepToDelete(false)
  })

  // drag & drop
  const [isDragging, setDragging] = useState(false)
  const [isDropping, setDropping] = useState(false)
  const [isDropHigh, setDropHigh] = useState(false)
  const moveStep = useMoveStep()
  const cardRef = useRef()
  const handleDragStart = e => {
    const img = new Image()
    e.dataTransfer.setDragImage(img, 10, 10)
    e.dataTransfer.effectAllowed = 'move'
    setDragging(true)
    appState.draggingStepId = step.id
  }

  const handleDragEnd = () => {
    setDragging(false)
    appState.draggingStepId = null
  }

  const handleDragEnter = () => {
    setDropping(true)
    console.log('> drag enter!')
  }

  const handleDragOver = e => {
    e.preventDefault()
    const { pageY } = e
    const { y, height } = cardRef.current.getBoundingClientRect()
    const isHigh = pageY <= y + height / 2
    setDropHigh(isHigh)
  }

  const handleDragLeave = () => {
    setDropping(false)
  }

  const handleDrop = e => {
    moveStep(appState.draggingStepId, stepId, isDropHigh)
    setDropping(false)
    e.preventDefault()
  }

  const saveCamera = useSaveCamera(step)

  return (
    <>
      <Alert
        canOutsideClickCancel
        intent="danger"
        cancelButtonText="Cancel"
        confirmButtonText="Delete"
        icon={<CustomIcon icon="trash" />}
        isOpen={stepToDelete}
        onCancel={e => setStepToDelete(false)}
        onConfirm={confirmDeleteStep}
      >
        <h3>Delete Step</h3>
        <p>Are you sure you want to delete this step?</p>
      </Alert>
      <div
        className="drag-drop-box"
        draggable
        ref={cardRef}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
      >
        <Card
          className={cx('step-thumbnail', {
            selected: selectedStepId === stepId,
            dragging: isDragging,
          })}
          onClick={() => setSelectedStepId(stepId)}
        >
          {step.thumbnail && (
            <Spinner
              className="background-spinner"
              intent="primary"
              size={36}
            />
          )}
          {step.thumbnail && (
            // thumbnails change, but URL stays the same. This overrides the cache
            <img src={step.thumbnail + `#${new Date().getTime()}`} />
          )}
          <div
            className={cx('drop-overlay', {
              dragging: isDragging,
              dropping: isDropping,
              'drop-high': isDropHigh,
              'drop-low': !isDropHigh,
            })}
            onDragEnter={handleDragEnter}
            onDragLeave={handleDragLeave}
            onDragOver={handleDragOver}
            onDrop={handleDrop}
          />
          <div className="thumbnail-actions">
            {canSaveCamera && (
              <Button
                className={cx('camera-button', { 'has-camera': step.camera })}
                minimal
                icon={<CustomIcon icon="camera" />}
                onClick={saveCamera}
              />
            )}
            {canRemoveStep && (
              <Button
                className="delete-button"
                minimal
                icon={<CustomIcon icon="trash" />}
                onClick={e => {
                  e.stopPropagation()
                  setStepToDelete(stepId)
                }}
              />
            )}
          </div>
        </Card>
      </div>
    </>
  )
}

export default observer(StepThumbnail)
