{
  "version": "2.0",
  "metadata": {
    "apiVersion": "2020-11-12",
    "endpointPrefix": "network-firewall",
    "jsonVersion": "1.0",
    "protocol": "json",
    "serviceAbbreviation": "Network Firewall",
    "serviceFullName": "AWS Network Firewall",
    "serviceId": "Network Firewall",
    "signatureVersion": "v4",
    "signingName": "network-firewall",
    "targetPrefix": "NetworkFirewall_20201112",
    "uid": "network-firewall-2020-11-12"
  },
  "operations": {
    "AssociateFirewallPolicy": {
      "input": {
        "type": "structure",
        "required": [
          "FirewallPolicyArn"
        ],
        "members": {
          "UpdateToken": {},
          "FirewallArn": {},
          "FirewallName": {},
          "FirewallPolicyArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "FirewallArn": {},
          "FirewallName": {},
          "FirewallPolicyArn": {},
          "UpdateToken": {}
        }
      }
    },
    "AssociateSubnets": {
      "input": {
        "type": "structure",
        "required": [
          "SubnetMappings"
        ],
        "members": {
          "UpdateToken": {},
          "FirewallArn": {},
          "FirewallName": {},
          "SubnetMappings": {
            "shape": "S7"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "FirewallArn": {},
          "FirewallName": {},
          "SubnetMappings": {
            "shape": "S7"
          },
          "UpdateToken": {}
        }
      }
    },
    "CreateFirewall": {
      "input": {
        "type": "structure",
        "required": [
          "FirewallName",
          "FirewallPolicyArn",
          "VpcId",
          "SubnetMappings"
        ],
        "members": {
          "FirewallName": {},
          "FirewallPolicyArn": {},
          "VpcId": {},
          "SubnetMappings": {
            "shape": "S7"
          },
          "DeleteProtection": {
            "type": "boolean"
          },
          "SubnetChangeProtection": {
            "type": "boolean"
          },
          "FirewallPolicyChangeProtection": {
            "type": "boolean"
          },
          "Description": {},
          "Tags": {
            "shape": "Sf"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Firewall": {
            "shape": "Sk"
          },
          "FirewallStatus": {
            "shape": "Sm"
          }
        }
      }
    },
    "CreateFirewallPolicy": {
      "input": {
        "type": "structure",
        "required": [
          "FirewallPolicyName",
          "FirewallPolicy"
        ],
        "members": {
          "FirewallPolicyName": {},
          "FirewallPolicy": {
            "shape": "S10"
          },
          "Description": {},
          "Tags": {
            "shape": "Sf"
          },
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "UpdateToken",
          "FirewallPolicyResponse"
        ],
        "members": {
          "UpdateToken": {},
          "FirewallPolicyResponse": {
            "shape": "S1j"
          }
        }
      }
    },
    "CreateRuleGroup": {
      "input": {
        "type": "structure",
        "required": [
          "RuleGroupName",
          "Type",
          "Capacity"
        ],
        "members": {
          "RuleGroupName": {},
          "RuleGroup": {
            "shape": "S1o"
          },
          "Rules": {},
          "Type": {},
          "Description": {},
          "Capacity": {
            "type": "integer"
          },
          "Tags": {
            "shape": "Sf"
          },
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "UpdateToken",
          "RuleGroupResponse"
        ],
        "members": {
          "UpdateToken": {},
          "RuleGroupResponse": {
            "shape": "S32"
          }
        }
      }
    },
    "DeleteFirewall": {
      "input": {
        "type": "structure",
        "members": {
          "FirewallName": {},
          "FirewallArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Firewall": {
            "shape": "Sk"
          },
          "FirewallStatus": {
            "shape": "Sm"
          }
        }
      }
    },
    "DeleteFirewallPolicy": {
      "input": {
        "type": "structure",
        "members": {
          "FirewallPolicyName": {},
          "FirewallPolicyArn": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "FirewallPolicyResponse"
        ],
        "members": {
          "FirewallPolicyResponse": {
            "shape": "S1j"
          }
        }
      }
    },
    "DeleteResourcePolicy": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceArn"
        ],
        "members": {
          "ResourceArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteRuleGroup": {
      "input": {
        "type": "structure",
        "members": {
          "RuleGroupName": {},
          "RuleGroupArn": {},
          "Type": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "RuleGroupResponse"
        ],
        "members": {
          "RuleGroupResponse": {
            "shape": "S32"
          }
        }
      }
    },
    "DescribeFirewall": {
      "input": {
        "type": "structure",
        "members": {
          "FirewallName": {},
          "FirewallArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "UpdateToken": {},
          "Firewall": {
            "shape": "Sk"
          },
          "FirewallStatus": {
            "shape": "Sm"
          }
        }
      }
    },
    "DescribeFirewallPolicy": {
      "input": {
        "type": "structure",
        "members": {
          "FirewallPolicyName": {},
          "FirewallPolicyArn": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "UpdateToken",
          "FirewallPolicyResponse"
        ],
        "members": {
          "UpdateToken": {},
          "FirewallPolicyResponse": {
            "shape": "S1j"
          },
          "FirewallPolicy": {
            "shape": "S10"
          }
        }
      }
    },
    "DescribeLoggingConfiguration": {
      "input": {
        "type": "structure",
        "members": {
          "FirewallArn": {},
          "FirewallName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "FirewallArn": {},
          "LoggingConfiguration": {
            "shape": "S3h"
          }
        }
      }
    },
    "DescribeResourcePolicy": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceArn"
        ],
        "members": {
          "ResourceArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Policy": {}
        }
      }
    },
    "DescribeRuleGroup": {
      "input": {
        "type": "structure",
        "members": {
          "RuleGroupName": {},
          "RuleGroupArn": {},
          "Type": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "UpdateToken",
          "RuleGroupResponse"
        ],
        "members": {
          "UpdateToken": {},
          "RuleGroup": {
            "shape": "S1o"
          },
          "RuleGroupResponse": {
            "shape": "S32"
          }
        }
      }
    },
    "DisassociateSubnets": {
      "input": {
        "type": "structure",
        "required": [
          "SubnetIds"
        ],
        "members": {
          "UpdateToken": {},
          "FirewallArn": {},
          "FirewallName": {},
          "SubnetIds": {
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "FirewallArn": {},
          "FirewallName": {},
          "SubnetMappings": {
            "shape": "S7"
          },
          "UpdateToken": {}
        }
      }
    },
    "ListFirewallPolicies": {
      "input": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "FirewallPolicies": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Name": {},
                "Arn": {}
              }
            }
          }
        }
      }
    },
    "ListFirewalls": {
      "input": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "VpcIds": {
            "type": "list",
            "member": {}
          },
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "Firewalls": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "FirewallName": {},
                "FirewallArn": {}
              }
            }
          }
        }
      }
    },
    "ListRuleGroups": {
      "input": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "RuleGroups": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Name": {},
                "Arn": {}
              }
            }
          }
        }
      }
    },
    "ListTagsForResource": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceArn"
        ],
        "members": {
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          },
          "ResourceArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "Tags": {
            "shape": "Sf"
          }
        }
      }
    },
    "PutResourcePolicy": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceArn",
          "Policy"
        ],
        "members": {
          "ResourceArn": {},
          "Policy": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "TagResource": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceArn",
          "Tags"
        ],
        "members": {
          "ResourceArn": {},
          "Tags": {
            "shape": "Sf"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UntagResource": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceArn",
          "TagKeys"
        ],
        "members": {
          "ResourceArn": {},
          "TagKeys": {
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UpdateFirewallDeleteProtection": {
      "input": {
        "type": "structure",
        "required": [
          "DeleteProtection"
        ],
        "members": {
          "UpdateToken": {},
          "FirewallArn": {},
          "FirewallName": {},
          "DeleteProtection": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "FirewallArn": {},
          "FirewallName": {},
          "DeleteProtection": {
            "type": "boolean"
          },
          "UpdateToken": {}
        }
      }
    },
    "UpdateFirewallDescription": {
      "input": {
        "type": "structure",
        "members": {
          "UpdateToken": {},
          "FirewallArn": {},
          "FirewallName": {},
          "Description": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "FirewallArn": {},
          "FirewallName": {},
          "Description": {},
          "UpdateToken": {}
        }
      }
    },
    "UpdateFirewallPolicy": {
      "input": {
        "type": "structure",
        "required": [
          "UpdateToken",
          "FirewallPolicy"
        ],
        "members": {
          "UpdateToken": {},
          "FirewallPolicyArn": {},
          "FirewallPolicyName": {},
          "FirewallPolicy": {
            "shape": "S10"
          },
          "Description": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "UpdateToken",
          "FirewallPolicyResponse"
        ],
        "members": {
          "UpdateToken": {},
          "FirewallPolicyResponse": {
            "shape": "S1j"
          }
        }
      }
    },
    "UpdateFirewallPolicyChangeProtection": {
      "input": {
        "type": "structure",
        "required": [
          "FirewallPolicyChangeProtection"
        ],
        "members": {
          "UpdateToken": {},
          "FirewallArn": {},
          "FirewallName": {},
          "FirewallPolicyChangeProtection": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "UpdateToken": {},
          "FirewallArn": {},
          "FirewallName": {},
          "FirewallPolicyChangeProtection": {
            "type": "boolean"
          }
        }
      }
    },
    "UpdateLoggingConfiguration": {
      "input": {
        "type": "structure",
        "members": {
          "FirewallArn": {},
          "FirewallName": {},
          "LoggingConfiguration": {
            "shape": "S3h"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "FirewallArn": {},
          "FirewallName": {},
          "LoggingConfiguration": {
            "shape": "S3h"
          }
        }
      }
    },
    "UpdateRuleGroup": {
      "input": {
        "type": "structure",
        "required": [
          "UpdateToken"
        ],
        "members": {
          "UpdateToken": {},
          "RuleGroupArn": {},
          "RuleGroupName": {},
          "RuleGroup": {
            "shape": "S1o"
          },
          "Rules": {},
          "Type": {},
          "Description": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "UpdateToken",
          "RuleGroupResponse"
        ],
        "members": {
          "UpdateToken": {},
          "RuleGroupResponse": {
            "shape": "S32"
          }
        }
      }
    },
    "UpdateSubnetChangeProtection": {
      "input": {
        "type": "structure",
        "required": [
          "SubnetChangeProtection"
        ],
        "members": {
          "UpdateToken": {},
          "FirewallArn": {},
          "FirewallName": {},
          "SubnetChangeProtection": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "UpdateToken": {},
          "FirewallArn": {},
          "FirewallName": {},
          "SubnetChangeProtection": {
            "type": "boolean"
          }
        }
      }
    }
  },
  "shapes": {
    "S7": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "SubnetId"
        ],
        "members": {
          "SubnetId": {}
        }
      }
    },
    "Sf": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "Key",
          "Value"
        ],
        "members": {
          "Key": {},
          "Value": {}
        }
      }
    },
    "Sk": {
      "type": "structure",
      "required": [
        "FirewallPolicyArn",
        "VpcId",
        "SubnetMappings",
        "FirewallId"
      ],
      "members": {
        "FirewallName": {},
        "FirewallArn": {},
        "FirewallPolicyArn": {},
        "VpcId": {},
        "SubnetMappings": {
          "shape": "S7"
        },
        "DeleteProtection": {
          "type": "boolean"
        },
        "SubnetChangeProtection": {
          "type": "boolean"
        },
        "FirewallPolicyChangeProtection": {
          "type": "boolean"
        },
        "Description": {},
        "FirewallId": {},
        "Tags": {
          "shape": "Sf"
        }
      }
    },
    "Sm": {
      "type": "structure",
      "required": [
        "Status",
        "ConfigurationSyncStateSummary"
      ],
      "members": {
        "Status": {},
        "ConfigurationSyncStateSummary": {},
        "SyncStates": {
          "type": "map",
          "key": {},
          "value": {
            "type": "structure",
            "members": {
              "Attachment": {
                "type": "structure",
                "members": {
                  "SubnetId": {},
                  "EndpointId": {},
                  "Status": {}
                }
              },
              "Config": {
                "type": "map",
                "key": {},
                "value": {
                  "type": "structure",
                  "members": {
                    "SyncStatus": {},
                    "UpdateToken": {}
                  }
                }
              }
            }
          }
        }
      }
    },
    "S10": {
      "type": "structure",
      "required": [
        "StatelessDefaultActions",
        "StatelessFragmentDefaultActions"
      ],
      "members": {
        "StatelessRuleGroupReferences": {
          "type": "list",
          "member": {
            "type": "structure",
            "required": [
              "ResourceArn",
              "Priority"
            ],
            "members": {
              "ResourceArn": {},
              "Priority": {
                "type": "integer"
              }
            }
          }
        },
        "StatelessDefaultActions": {
          "shape": "S14"
        },
        "StatelessFragmentDefaultActions": {
          "shape": "S14"
        },
        "StatelessCustomActions": {
          "shape": "S15"
        },
        "StatefulRuleGroupReferences": {
          "type": "list",
          "member": {
            "type": "structure",
            "required": [
              "ResourceArn"
            ],
            "members": {
              "ResourceArn": {},
              "Priority": {
                "type": "integer"
              }
            }
          }
        },
        "StatefulDefaultActions": {
          "type": "list",
          "member": {}
        },
        "StatefulEngineOptions": {
          "type": "structure",
          "members": {
            "RuleOrder": {}
          }
        }
      }
    },
    "S14": {
      "type": "list",
      "member": {}
    },
    "S15": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "ActionName",
          "ActionDefinition"
        ],
        "members": {
          "ActionName": {},
          "ActionDefinition": {
            "type": "structure",
            "members": {
              "PublishMetricAction": {
                "type": "structure",
                "required": [
                  "Dimensions"
                ],
                "members": {
                  "Dimensions": {
                    "type": "list",
                    "member": {
                      "type": "structure",
                      "required": [
                        "Value"
                      ],
                      "members": {
                        "Value": {}
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    },
    "S1j": {
      "type": "structure",
      "required": [
        "FirewallPolicyName",
        "FirewallPolicyArn",
        "FirewallPolicyId"
      ],
      "members": {
        "FirewallPolicyName": {},
        "FirewallPolicyArn": {},
        "FirewallPolicyId": {},
        "Description": {},
        "FirewallPolicyStatus": {},
        "Tags": {
          "shape": "Sf"
        },
        "ConsumedStatelessRuleCapacity": {
          "type": "integer"
        },
        "ConsumedStatefulRuleCapacity": {
          "type": "integer"
        },
        "NumberOfAssociations": {
          "type": "integer"
        }
      }
    },
    "S1o": {
      "type": "structure",
      "required": [
        "RulesSource"
      ],
      "members": {
        "RuleVariables": {
          "type": "structure",
          "members": {
            "IPSets": {
              "type": "map",
              "key": {},
              "value": {
                "type": "structure",
                "required": [
                  "Definition"
                ],
                "members": {
                  "Definition": {
                    "shape": "S1t"
                  }
                }
              }
            },
            "PortSets": {
              "type": "map",
              "key": {},
              "value": {
                "type": "structure",
                "members": {
                  "Definition": {
                    "shape": "S1t"
                  }
                }
              }
            }
          }
        },
        "RulesSource": {
          "type": "structure",
          "members": {
            "RulesString": {},
            "RulesSourceList": {
              "type": "structure",
              "required": [
                "Targets",
                "TargetTypes",
                "GeneratedRulesType"
              ],
              "members": {
                "Targets": {
                  "type": "list",
                  "member": {}
                },
                "TargetTypes": {
                  "type": "list",
                  "member": {}
                },
                "GeneratedRulesType": {}
              }
            },
            "StatefulRules": {
              "type": "list",
              "member": {
                "type": "structure",
                "required": [
                  "Action",
                  "Header",
                  "RuleOptions"
                ],
                "members": {
                  "Action": {},
                  "Header": {
                    "type": "structure",
                    "required": [
                      "Protocol",
                      "Source",
                      "SourcePort",
                      "Direction",
                      "Destination",
                      "DestinationPort"
                    ],
                    "members": {
                      "Protocol": {},
                      "Source": {},
                      "SourcePort": {},
                      "Direction": {},
                      "Destination": {},
                      "DestinationPort": {}
                    }
                  },
                  "RuleOptions": {
                    "type": "list",
                    "member": {
                      "type": "structure",
                      "required": [
                        "Keyword"
                      ],
                      "members": {
                        "Keyword": {},
                        "Settings": {
                          "type": "list",
                          "member": {}
                        }
                      }
                    }
                  }
                }
              }
            },
            "StatelessRulesAndCustomActions": {
              "type": "structure",
              "required": [
                "StatelessRules"
              ],
              "members": {
                "StatelessRules": {
                  "type": "list",
                  "member": {
                    "type": "structure",
                    "required": [
                      "RuleDefinition",
                      "Priority"
                    ],
                    "members": {
                      "RuleDefinition": {
                        "type": "structure",
                        "required": [
                          "MatchAttributes",
                          "Actions"
                        ],
                        "members": {
                          "MatchAttributes": {
                            "type": "structure",
                            "members": {
                              "Sources": {
                                "shape": "S2n"
                              },
                              "Destinations": {
                                "shape": "S2n"
                              },
                              "SourcePorts": {
                                "shape": "S2q"
                              },
                              "DestinationPorts": {
                                "shape": "S2q"
                              },
                              "Protocols": {
                                "type": "list",
                                "member": {
                                  "type": "integer"
                                }
                              },
                              "TCPFlags": {
                                "type": "list",
                                "member": {
                                  "type": "structure",
                                  "required": [
                                    "Flags"
                                  ],
                                  "members": {
                                    "Flags": {
                                      "shape": "S2x"
                                    },
                                    "Masks": {
                                      "shape": "S2x"
                                    }
                                  }
                                }
                              }
                            }
                          },
                          "Actions": {
                            "shape": "S14"
                          }
                        }
                      },
                      "Priority": {
                        "type": "integer"
                      }
                    }
                  }
                },
                "CustomActions": {
                  "shape": "S15"
                }
              }
            }
          }
        },
        "StatefulRuleOptions": {
          "type": "structure",
          "members": {
            "RuleOrder": {}
          }
        }
      }
    },
    "S1t": {
      "type": "list",
      "member": {}
    },
    "S2n": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "AddressDefinition"
        ],
        "members": {
          "AddressDefinition": {}
        }
      }
    },
    "S2q": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "FromPort",
          "ToPort"
        ],
        "members": {
          "FromPort": {
            "type": "integer"
          },
          "ToPort": {
            "type": "integer"
          }
        }
      }
    },
    "S2x": {
      "type": "list",
      "member": {}
    },
    "S32": {
      "type": "structure",
      "required": [
        "RuleGroupArn",
        "RuleGroupName",
        "RuleGroupId"
      ],
      "members": {
        "RuleGroupArn": {},
        "RuleGroupName": {},
        "RuleGroupId": {},
        "Description": {},
        "Type": {},
        "Capacity": {
          "type": "integer"
        },
        "RuleGroupStatus": {},
        "Tags": {
          "shape": "Sf"
        },
        "ConsumedCapacity": {
          "type": "integer"
        },
        "NumberOfAssociations": {
          "type": "integer"
        }
      }
    },
    "S3h": {
      "type": "structure",
      "required": [
        "LogDestinationConfigs"
      ],
      "members": {
        "LogDestinationConfigs": {
          "type": "list",
          "member": {
            "type": "structure",
            "required": [
              "LogType",
              "LogDestinationType",
              "LogDestination"
            ],
            "members": {
              "LogType": {},
              "LogDestinationType": {},
              "LogDestination": {
                "type": "map",
                "key": {},
                "value": {}
              }
            }
          }
        }
      }
    }
  }
}