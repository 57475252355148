{
  "version": "2.0",
  "metadata": {
    "apiVersion": "2020-08-23",
    "endpointPrefix": "appflow",
    "jsonVersion": "1.1",
    "protocol": "rest-json",
    "serviceFullName": "Amazon Appflow",
    "serviceId": "Appflow",
    "signatureVersion": "v4",
    "signingName": "appflow",
    "uid": "appflow-2020-08-23"
  },
  "operations": {
    "CreateConnectorProfile": {
      "http": {
        "requestUri": "/create-connector-profile"
      },
      "input": {
        "type": "structure",
        "required": [
          "connectorProfileName",
          "connectorType",
          "connectionMode",
          "connectorProfileConfig"
        ],
        "members": {
          "connectorProfileName": {},
          "kmsArn": {},
          "connectorType": {},
          "connectionMode": {},
          "connectorProfileConfig": {
            "shape": "S6"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "connectorProfileArn": {}
        }
      }
    },
    "CreateFlow": {
      "http": {
        "requestUri": "/create-flow"
      },
      "input": {
        "type": "structure",
        "required": [
          "flowName",
          "triggerConfig",
          "sourceFlowConfig",
          "destinationFlowConfigList",
          "tasks"
        ],
        "members": {
          "flowName": {},
          "description": {},
          "kmsArn": {},
          "triggerConfig": {
            "shape": "S2g"
          },
          "sourceFlowConfig": {
            "shape": "S2p"
          },
          "destinationFlowConfigList": {
            "shape": "S3c"
          },
          "tasks": {
            "shape": "S44"
          },
          "tags": {
            "shape": "S4t"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "flowArn": {},
          "flowStatus": {}
        }
      }
    },
    "DeleteConnectorProfile": {
      "http": {
        "requestUri": "/delete-connector-profile"
      },
      "input": {
        "type": "structure",
        "required": [
          "connectorProfileName"
        ],
        "members": {
          "connectorProfileName": {},
          "forceDelete": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteFlow": {
      "http": {
        "requestUri": "/delete-flow"
      },
      "input": {
        "type": "structure",
        "required": [
          "flowName"
        ],
        "members": {
          "flowName": {},
          "forceDelete": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DescribeConnectorEntity": {
      "http": {
        "requestUri": "/describe-connector-entity"
      },
      "input": {
        "type": "structure",
        "required": [
          "connectorEntityName"
        ],
        "members": {
          "connectorEntityName": {},
          "connectorType": {},
          "connectorProfileName": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "connectorEntityFields"
        ],
        "members": {
          "connectorEntityFields": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "identifier"
              ],
              "members": {
                "identifier": {},
                "label": {},
                "supportedFieldTypeDetails": {
                  "type": "structure",
                  "required": [
                    "v1"
                  ],
                  "members": {
                    "v1": {
                      "type": "structure",
                      "required": [
                        "fieldType",
                        "filterOperators"
                      ],
                      "members": {
                        "fieldType": {},
                        "filterOperators": {
                          "type": "list",
                          "member": {}
                        },
                        "supportedValues": {
                          "type": "list",
                          "member": {}
                        }
                      }
                    }
                  }
                },
                "description": {},
                "sourceProperties": {
                  "type": "structure",
                  "members": {
                    "isRetrievable": {
                      "type": "boolean"
                    },
                    "isQueryable": {
                      "type": "boolean"
                    }
                  }
                },
                "destinationProperties": {
                  "type": "structure",
                  "members": {
                    "isCreatable": {
                      "type": "boolean"
                    },
                    "isNullable": {
                      "type": "boolean"
                    },
                    "isUpsertable": {
                      "type": "boolean"
                    },
                    "isUpdatable": {
                      "type": "boolean"
                    },
                    "supportedWriteOperations": {
                      "type": "list",
                      "member": {}
                    }
                  }
                }
              }
            }
          }
        }
      }
    },
    "DescribeConnectorProfiles": {
      "http": {
        "requestUri": "/describe-connector-profiles"
      },
      "input": {
        "type": "structure",
        "members": {
          "connectorProfileNames": {
            "type": "list",
            "member": {}
          },
          "connectorType": {},
          "maxResults": {
            "type": "integer"
          },
          "nextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "connectorProfileDetails": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "connectorProfileArn": {},
                "connectorProfileName": {},
                "connectorType": {},
                "connectionMode": {},
                "credentialsArn": {},
                "connectorProfileProperties": {
                  "shape": "S7"
                },
                "createdAt": {
                  "type": "timestamp"
                },
                "lastUpdatedAt": {
                  "type": "timestamp"
                },
                "privateConnectionProvisioningState": {
                  "type": "structure",
                  "members": {
                    "status": {},
                    "failureMessage": {},
                    "failureCause": {}
                  }
                }
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "DescribeConnectors": {
      "http": {
        "requestUri": "/describe-connectors"
      },
      "input": {
        "type": "structure",
        "members": {
          "connectorTypes": {
            "shape": "S5x"
          },
          "nextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "connectorConfigurations": {
            "type": "map",
            "key": {},
            "value": {
              "type": "structure",
              "members": {
                "canUseAsSource": {
                  "type": "boolean"
                },
                "canUseAsDestination": {
                  "type": "boolean"
                },
                "supportedDestinationConnectors": {
                  "shape": "S5x"
                },
                "supportedSchedulingFrequencies": {
                  "type": "list",
                  "member": {}
                },
                "isPrivateLinkEnabled": {
                  "type": "boolean"
                },
                "isPrivateLinkEndpointUrlRequired": {
                  "type": "boolean"
                },
                "supportedTriggerTypes": {
                  "type": "list",
                  "member": {}
                },
                "connectorMetadata": {
                  "type": "structure",
                  "members": {
                    "Amplitude": {
                      "type": "structure",
                      "members": {}
                    },
                    "Datadog": {
                      "type": "structure",
                      "members": {}
                    },
                    "Dynatrace": {
                      "type": "structure",
                      "members": {}
                    },
                    "GoogleAnalytics": {
                      "type": "structure",
                      "members": {
                        "oAuthScopes": {
                          "shape": "S18"
                        }
                      }
                    },
                    "InforNexus": {
                      "type": "structure",
                      "members": {}
                    },
                    "Marketo": {
                      "type": "structure",
                      "members": {}
                    },
                    "Redshift": {
                      "type": "structure",
                      "members": {}
                    },
                    "S3": {
                      "type": "structure",
                      "members": {}
                    },
                    "Salesforce": {
                      "type": "structure",
                      "members": {
                        "oAuthScopes": {
                          "shape": "S18"
                        }
                      }
                    },
                    "ServiceNow": {
                      "type": "structure",
                      "members": {}
                    },
                    "Singular": {
                      "type": "structure",
                      "members": {}
                    },
                    "Slack": {
                      "type": "structure",
                      "members": {
                        "oAuthScopes": {
                          "shape": "S18"
                        }
                      }
                    },
                    "Snowflake": {
                      "type": "structure",
                      "members": {
                        "supportedRegions": {
                          "type": "list",
                          "member": {}
                        }
                      }
                    },
                    "Trendmicro": {
                      "type": "structure",
                      "members": {}
                    },
                    "Veeva": {
                      "type": "structure",
                      "members": {}
                    },
                    "Zendesk": {
                      "type": "structure",
                      "members": {
                        "oAuthScopes": {
                          "shape": "S18"
                        }
                      }
                    },
                    "EventBridge": {
                      "type": "structure",
                      "members": {}
                    },
                    "Upsolver": {
                      "type": "structure",
                      "members": {}
                    },
                    "CustomerProfiles": {
                      "type": "structure",
                      "members": {}
                    },
                    "Honeycode": {
                      "type": "structure",
                      "members": {
                        "oAuthScopes": {
                          "shape": "S18"
                        }
                      }
                    },
                    "SAPOData": {
                      "type": "structure",
                      "members": {}
                    }
                  }
                }
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "DescribeFlow": {
      "http": {
        "requestUri": "/describe-flow"
      },
      "input": {
        "type": "structure",
        "required": [
          "flowName"
        ],
        "members": {
          "flowName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "flowArn": {},
          "description": {},
          "flowName": {},
          "kmsArn": {},
          "flowStatus": {},
          "flowStatusMessage": {},
          "sourceFlowConfig": {
            "shape": "S2p"
          },
          "destinationFlowConfigList": {
            "shape": "S3c"
          },
          "lastRunExecutionDetails": {
            "shape": "S6u"
          },
          "triggerConfig": {
            "shape": "S2g"
          },
          "tasks": {
            "shape": "S44"
          },
          "createdAt": {
            "type": "timestamp"
          },
          "lastUpdatedAt": {
            "type": "timestamp"
          },
          "createdBy": {},
          "lastUpdatedBy": {},
          "tags": {
            "shape": "S4t"
          }
        }
      }
    },
    "DescribeFlowExecutionRecords": {
      "http": {
        "requestUri": "/describe-flow-execution-records"
      },
      "input": {
        "type": "structure",
        "required": [
          "flowName"
        ],
        "members": {
          "flowName": {},
          "maxResults": {
            "type": "integer"
          },
          "nextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "flowExecutions": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "executionId": {},
                "executionStatus": {},
                "executionResult": {
                  "type": "structure",
                  "members": {
                    "errorInfo": {
                      "type": "structure",
                      "members": {
                        "putFailuresCount": {
                          "type": "long"
                        },
                        "executionMessage": {}
                      }
                    },
                    "bytesProcessed": {
                      "type": "long"
                    },
                    "bytesWritten": {
                      "type": "long"
                    },
                    "recordsProcessed": {
                      "type": "long"
                    }
                  }
                },
                "startedAt": {
                  "type": "timestamp"
                },
                "lastUpdatedAt": {
                  "type": "timestamp"
                },
                "dataPullStartTime": {
                  "type": "timestamp"
                },
                "dataPullEndTime": {
                  "type": "timestamp"
                }
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListConnectorEntities": {
      "http": {
        "requestUri": "/list-connector-entities"
      },
      "input": {
        "type": "structure",
        "members": {
          "connectorProfileName": {},
          "connectorType": {},
          "entitiesPath": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "connectorEntityMap"
        ],
        "members": {
          "connectorEntityMap": {
            "type": "map",
            "key": {},
            "value": {
              "type": "list",
              "member": {
                "type": "structure",
                "required": [
                  "name"
                ],
                "members": {
                  "name": {},
                  "label": {},
                  "hasNestedEntities": {
                    "type": "boolean"
                  }
                }
              }
            }
          }
        }
      }
    },
    "ListFlows": {
      "http": {
        "requestUri": "/list-flows"
      },
      "input": {
        "type": "structure",
        "members": {
          "maxResults": {
            "type": "integer"
          },
          "nextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "flows": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "flowArn": {},
                "description": {},
                "flowName": {},
                "flowStatus": {},
                "sourceConnectorType": {},
                "destinationConnectorType": {},
                "triggerType": {},
                "createdAt": {
                  "type": "timestamp"
                },
                "lastUpdatedAt": {
                  "type": "timestamp"
                },
                "createdBy": {},
                "lastUpdatedBy": {},
                "tags": {
                  "shape": "S4t"
                },
                "lastRunExecutionDetails": {
                  "shape": "S6u"
                }
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListTagsForResource": {
      "http": {
        "method": "GET",
        "requestUri": "/tags/{resourceArn}"
      },
      "input": {
        "type": "structure",
        "required": [
          "resourceArn"
        ],
        "members": {
          "resourceArn": {
            "location": "uri",
            "locationName": "resourceArn"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "tags": {
            "shape": "S4t"
          }
        }
      }
    },
    "StartFlow": {
      "http": {
        "requestUri": "/start-flow"
      },
      "input": {
        "type": "structure",
        "required": [
          "flowName"
        ],
        "members": {
          "flowName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "flowArn": {},
          "flowStatus": {},
          "executionId": {}
        }
      }
    },
    "StopFlow": {
      "http": {
        "requestUri": "/stop-flow"
      },
      "input": {
        "type": "structure",
        "required": [
          "flowName"
        ],
        "members": {
          "flowName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "flowArn": {},
          "flowStatus": {}
        }
      }
    },
    "TagResource": {
      "http": {
        "requestUri": "/tags/{resourceArn}"
      },
      "input": {
        "type": "structure",
        "required": [
          "resourceArn",
          "tags"
        ],
        "members": {
          "resourceArn": {
            "location": "uri",
            "locationName": "resourceArn"
          },
          "tags": {
            "shape": "S4t"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UntagResource": {
      "http": {
        "method": "DELETE",
        "requestUri": "/tags/{resourceArn}"
      },
      "input": {
        "type": "structure",
        "required": [
          "resourceArn",
          "tagKeys"
        ],
        "members": {
          "resourceArn": {
            "location": "uri",
            "locationName": "resourceArn"
          },
          "tagKeys": {
            "location": "querystring",
            "locationName": "tagKeys",
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UpdateConnectorProfile": {
      "http": {
        "requestUri": "/update-connector-profile"
      },
      "input": {
        "type": "structure",
        "required": [
          "connectorProfileName",
          "connectionMode",
          "connectorProfileConfig"
        ],
        "members": {
          "connectorProfileName": {},
          "connectionMode": {},
          "connectorProfileConfig": {
            "shape": "S6"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "connectorProfileArn": {}
        }
      }
    },
    "UpdateFlow": {
      "http": {
        "requestUri": "/update-flow"
      },
      "input": {
        "type": "structure",
        "required": [
          "flowName",
          "triggerConfig",
          "sourceFlowConfig",
          "destinationFlowConfigList",
          "tasks"
        ],
        "members": {
          "flowName": {},
          "description": {},
          "triggerConfig": {
            "shape": "S2g"
          },
          "sourceFlowConfig": {
            "shape": "S2p"
          },
          "destinationFlowConfigList": {
            "shape": "S3c"
          },
          "tasks": {
            "shape": "S44"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "flowStatus": {}
        }
      }
    }
  },
  "shapes": {
    "S6": {
      "type": "structure",
      "required": [
        "connectorProfileProperties",
        "connectorProfileCredentials"
      ],
      "members": {
        "connectorProfileProperties": {
          "shape": "S7"
        },
        "connectorProfileCredentials": {
          "type": "structure",
          "members": {
            "Amplitude": {
              "type": "structure",
              "required": [
                "apiKey",
                "secretKey"
              ],
              "members": {
                "apiKey": {},
                "secretKey": {
                  "type": "string",
                  "sensitive": true
                }
              }
            },
            "Datadog": {
              "type": "structure",
              "required": [
                "apiKey",
                "applicationKey"
              ],
              "members": {
                "apiKey": {},
                "applicationKey": {}
              }
            },
            "Dynatrace": {
              "type": "structure",
              "required": [
                "apiToken"
              ],
              "members": {
                "apiToken": {}
              }
            },
            "GoogleAnalytics": {
              "type": "structure",
              "required": [
                "clientId",
                "clientSecret"
              ],
              "members": {
                "clientId": {},
                "clientSecret": {
                  "shape": "S1k"
                },
                "accessToken": {
                  "shape": "S1l"
                },
                "refreshToken": {},
                "oAuthRequest": {
                  "shape": "S1n"
                }
              }
            },
            "Honeycode": {
              "type": "structure",
              "members": {
                "accessToken": {
                  "shape": "S1l"
                },
                "refreshToken": {},
                "oAuthRequest": {
                  "shape": "S1n"
                }
              }
            },
            "InforNexus": {
              "type": "structure",
              "required": [
                "accessKeyId",
                "userId",
                "secretAccessKey",
                "datakey"
              ],
              "members": {
                "accessKeyId": {
                  "type": "string",
                  "sensitive": true
                },
                "userId": {},
                "secretAccessKey": {},
                "datakey": {}
              }
            },
            "Marketo": {
              "type": "structure",
              "required": [
                "clientId",
                "clientSecret"
              ],
              "members": {
                "clientId": {},
                "clientSecret": {
                  "shape": "S1k"
                },
                "accessToken": {
                  "shape": "S1l"
                },
                "oAuthRequest": {
                  "shape": "S1n"
                }
              }
            },
            "Redshift": {
              "type": "structure",
              "required": [
                "username",
                "password"
              ],
              "members": {
                "username": {},
                "password": {
                  "shape": "S1x"
                }
              }
            },
            "Salesforce": {
              "type": "structure",
              "members": {
                "accessToken": {
                  "shape": "S1l"
                },
                "refreshToken": {},
                "oAuthRequest": {
                  "shape": "S1n"
                },
                "clientCredentialsArn": {
                  "type": "string",
                  "sensitive": true
                }
              }
            },
            "ServiceNow": {
              "type": "structure",
              "required": [
                "username",
                "password"
              ],
              "members": {
                "username": {},
                "password": {
                  "shape": "S1x"
                }
              }
            },
            "Singular": {
              "type": "structure",
              "required": [
                "apiKey"
              ],
              "members": {
                "apiKey": {}
              }
            },
            "Slack": {
              "type": "structure",
              "required": [
                "clientId",
                "clientSecret"
              ],
              "members": {
                "clientId": {},
                "clientSecret": {
                  "shape": "S1k"
                },
                "accessToken": {
                  "shape": "S1l"
                },
                "oAuthRequest": {
                  "shape": "S1n"
                }
              }
            },
            "Snowflake": {
              "type": "structure",
              "required": [
                "username",
                "password"
              ],
              "members": {
                "username": {},
                "password": {
                  "shape": "S1x"
                }
              }
            },
            "Trendmicro": {
              "type": "structure",
              "required": [
                "apiSecretKey"
              ],
              "members": {
                "apiSecretKey": {
                  "type": "string",
                  "sensitive": true
                }
              }
            },
            "Veeva": {
              "type": "structure",
              "required": [
                "username",
                "password"
              ],
              "members": {
                "username": {},
                "password": {
                  "shape": "S1x"
                }
              }
            },
            "Zendesk": {
              "type": "structure",
              "required": [
                "clientId",
                "clientSecret"
              ],
              "members": {
                "clientId": {},
                "clientSecret": {
                  "shape": "S1k"
                },
                "accessToken": {
                  "shape": "S1l"
                },
                "oAuthRequest": {
                  "shape": "S1n"
                }
              }
            },
            "SAPOData": {
              "type": "structure",
              "members": {
                "basicAuthCredentials": {
                  "type": "structure",
                  "required": [
                    "username",
                    "password"
                  ],
                  "members": {
                    "username": {},
                    "password": {
                      "shape": "S1x"
                    }
                  }
                },
                "oAuthCredentials": {
                  "type": "structure",
                  "required": [
                    "clientId",
                    "clientSecret"
                  ],
                  "members": {
                    "clientId": {},
                    "clientSecret": {
                      "shape": "S1k"
                    },
                    "accessToken": {
                      "shape": "S1l"
                    },
                    "refreshToken": {},
                    "oAuthRequest": {
                      "shape": "S1n"
                    }
                  }
                }
              }
            }
          }
        }
      }
    },
    "S7": {
      "type": "structure",
      "members": {
        "Amplitude": {
          "type": "structure",
          "members": {}
        },
        "Datadog": {
          "type": "structure",
          "required": [
            "instanceUrl"
          ],
          "members": {
            "instanceUrl": {}
          }
        },
        "Dynatrace": {
          "type": "structure",
          "required": [
            "instanceUrl"
          ],
          "members": {
            "instanceUrl": {}
          }
        },
        "GoogleAnalytics": {
          "type": "structure",
          "members": {}
        },
        "Honeycode": {
          "type": "structure",
          "members": {}
        },
        "InforNexus": {
          "type": "structure",
          "required": [
            "instanceUrl"
          ],
          "members": {
            "instanceUrl": {}
          }
        },
        "Marketo": {
          "type": "structure",
          "required": [
            "instanceUrl"
          ],
          "members": {
            "instanceUrl": {}
          }
        },
        "Redshift": {
          "type": "structure",
          "required": [
            "databaseUrl",
            "bucketName",
            "roleArn"
          ],
          "members": {
            "databaseUrl": {},
            "bucketName": {},
            "bucketPrefix": {},
            "roleArn": {}
          }
        },
        "Salesforce": {
          "type": "structure",
          "members": {
            "instanceUrl": {},
            "isSandboxEnvironment": {
              "type": "boolean"
            }
          }
        },
        "ServiceNow": {
          "type": "structure",
          "required": [
            "instanceUrl"
          ],
          "members": {
            "instanceUrl": {}
          }
        },
        "Singular": {
          "type": "structure",
          "members": {}
        },
        "Slack": {
          "type": "structure",
          "required": [
            "instanceUrl"
          ],
          "members": {
            "instanceUrl": {}
          }
        },
        "Snowflake": {
          "type": "structure",
          "required": [
            "warehouse",
            "stage",
            "bucketName"
          ],
          "members": {
            "warehouse": {},
            "stage": {},
            "bucketName": {},
            "bucketPrefix": {},
            "privateLinkServiceName": {},
            "accountName": {},
            "region": {}
          }
        },
        "Trendmicro": {
          "type": "structure",
          "members": {}
        },
        "Veeva": {
          "type": "structure",
          "required": [
            "instanceUrl"
          ],
          "members": {
            "instanceUrl": {}
          }
        },
        "Zendesk": {
          "type": "structure",
          "required": [
            "instanceUrl"
          ],
          "members": {
            "instanceUrl": {}
          }
        },
        "SAPOData": {
          "type": "structure",
          "required": [
            "applicationHostUrl",
            "applicationServicePath",
            "portNumber",
            "clientNumber"
          ],
          "members": {
            "applicationHostUrl": {},
            "applicationServicePath": {},
            "portNumber": {
              "type": "integer"
            },
            "clientNumber": {},
            "logonLanguage": {},
            "privateLinkServiceName": {},
            "oAuthProperties": {
              "type": "structure",
              "required": [
                "tokenUrl",
                "authCodeUrl",
                "oAuthScopes"
              ],
              "members": {
                "tokenUrl": {},
                "authCodeUrl": {},
                "oAuthScopes": {
                  "shape": "S18"
                }
              }
            }
          }
        }
      }
    },
    "S18": {
      "type": "list",
      "member": {}
    },
    "S1k": {
      "type": "string",
      "sensitive": true
    },
    "S1l": {
      "type": "string",
      "sensitive": true
    },
    "S1n": {
      "type": "structure",
      "members": {
        "authCode": {},
        "redirectUri": {}
      }
    },
    "S1x": {
      "type": "string",
      "sensitive": true
    },
    "S2g": {
      "type": "structure",
      "required": [
        "triggerType"
      ],
      "members": {
        "triggerType": {},
        "triggerProperties": {
          "type": "structure",
          "members": {
            "Scheduled": {
              "type": "structure",
              "required": [
                "scheduleExpression"
              ],
              "members": {
                "scheduleExpression": {},
                "dataPullMode": {},
                "scheduleStartTime": {
                  "type": "timestamp"
                },
                "scheduleEndTime": {
                  "type": "timestamp"
                },
                "timezone": {},
                "scheduleOffset": {
                  "type": "long"
                },
                "firstExecutionFrom": {
                  "type": "timestamp"
                }
              }
            }
          }
        }
      }
    },
    "S2p": {
      "type": "structure",
      "required": [
        "connectorType",
        "sourceConnectorProperties"
      ],
      "members": {
        "connectorType": {},
        "connectorProfileName": {},
        "sourceConnectorProperties": {
          "type": "structure",
          "members": {
            "Amplitude": {
              "type": "structure",
              "required": [
                "object"
              ],
              "members": {
                "object": {}
              }
            },
            "Datadog": {
              "type": "structure",
              "required": [
                "object"
              ],
              "members": {
                "object": {}
              }
            },
            "Dynatrace": {
              "type": "structure",
              "required": [
                "object"
              ],
              "members": {
                "object": {}
              }
            },
            "GoogleAnalytics": {
              "type": "structure",
              "required": [
                "object"
              ],
              "members": {
                "object": {}
              }
            },
            "InforNexus": {
              "type": "structure",
              "required": [
                "object"
              ],
              "members": {
                "object": {}
              }
            },
            "Marketo": {
              "type": "structure",
              "required": [
                "object"
              ],
              "members": {
                "object": {}
              }
            },
            "S3": {
              "type": "structure",
              "required": [
                "bucketName"
              ],
              "members": {
                "bucketName": {},
                "bucketPrefix": {},
                "s3InputFormatConfig": {
                  "type": "structure",
                  "members": {
                    "s3InputFileType": {}
                  }
                }
              }
            },
            "Salesforce": {
              "type": "structure",
              "required": [
                "object"
              ],
              "members": {
                "object": {},
                "enableDynamicFieldUpdate": {
                  "type": "boolean"
                },
                "includeDeletedRecords": {
                  "type": "boolean"
                }
              }
            },
            "ServiceNow": {
              "type": "structure",
              "required": [
                "object"
              ],
              "members": {
                "object": {}
              }
            },
            "Singular": {
              "type": "structure",
              "required": [
                "object"
              ],
              "members": {
                "object": {}
              }
            },
            "Slack": {
              "type": "structure",
              "required": [
                "object"
              ],
              "members": {
                "object": {}
              }
            },
            "Trendmicro": {
              "type": "structure",
              "required": [
                "object"
              ],
              "members": {
                "object": {}
              }
            },
            "Veeva": {
              "type": "structure",
              "required": [
                "object"
              ],
              "members": {
                "object": {},
                "documentType": {},
                "includeSourceFiles": {
                  "type": "boolean"
                },
                "includeRenditions": {
                  "type": "boolean"
                },
                "includeAllVersions": {
                  "type": "boolean"
                }
              }
            },
            "Zendesk": {
              "type": "structure",
              "required": [
                "object"
              ],
              "members": {
                "object": {}
              }
            },
            "SAPOData": {
              "type": "structure",
              "members": {
                "objectPath": {}
              }
            }
          }
        },
        "incrementalPullConfig": {
          "type": "structure",
          "members": {
            "datetimeTypeFieldName": {}
          }
        }
      }
    },
    "S3c": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "connectorType",
          "destinationConnectorProperties"
        ],
        "members": {
          "connectorType": {},
          "connectorProfileName": {},
          "destinationConnectorProperties": {
            "type": "structure",
            "members": {
              "Redshift": {
                "type": "structure",
                "required": [
                  "object",
                  "intermediateBucketName"
                ],
                "members": {
                  "object": {},
                  "intermediateBucketName": {},
                  "bucketPrefix": {},
                  "errorHandlingConfig": {
                    "shape": "S3g"
                  }
                }
              },
              "S3": {
                "type": "structure",
                "required": [
                  "bucketName"
                ],
                "members": {
                  "bucketName": {},
                  "bucketPrefix": {},
                  "s3OutputFormatConfig": {
                    "type": "structure",
                    "members": {
                      "fileType": {},
                      "prefixConfig": {
                        "shape": "S3k"
                      },
                      "aggregationConfig": {
                        "shape": "S3n"
                      }
                    }
                  }
                }
              },
              "Salesforce": {
                "type": "structure",
                "required": [
                  "object"
                ],
                "members": {
                  "object": {},
                  "idFieldNames": {
                    "shape": "S3q"
                  },
                  "errorHandlingConfig": {
                    "shape": "S3g"
                  },
                  "writeOperationType": {}
                }
              },
              "Snowflake": {
                "type": "structure",
                "required": [
                  "object",
                  "intermediateBucketName"
                ],
                "members": {
                  "object": {},
                  "intermediateBucketName": {},
                  "bucketPrefix": {},
                  "errorHandlingConfig": {
                    "shape": "S3g"
                  }
                }
              },
              "EventBridge": {
                "type": "structure",
                "required": [
                  "object"
                ],
                "members": {
                  "object": {},
                  "errorHandlingConfig": {
                    "shape": "S3g"
                  }
                }
              },
              "LookoutMetrics": {
                "type": "structure",
                "members": {}
              },
              "Upsolver": {
                "type": "structure",
                "required": [
                  "bucketName",
                  "s3OutputFormatConfig"
                ],
                "members": {
                  "bucketName": {},
                  "bucketPrefix": {},
                  "s3OutputFormatConfig": {
                    "type": "structure",
                    "required": [
                      "prefixConfig"
                    ],
                    "members": {
                      "fileType": {},
                      "prefixConfig": {
                        "shape": "S3k"
                      },
                      "aggregationConfig": {
                        "shape": "S3n"
                      }
                    }
                  }
                }
              },
              "Honeycode": {
                "type": "structure",
                "required": [
                  "object"
                ],
                "members": {
                  "object": {},
                  "errorHandlingConfig": {
                    "shape": "S3g"
                  }
                }
              },
              "CustomerProfiles": {
                "type": "structure",
                "required": [
                  "domainName"
                ],
                "members": {
                  "domainName": {},
                  "objectTypeName": {}
                }
              },
              "Zendesk": {
                "type": "structure",
                "required": [
                  "object"
                ],
                "members": {
                  "object": {},
                  "idFieldNames": {
                    "shape": "S3q"
                  },
                  "errorHandlingConfig": {
                    "shape": "S3g"
                  },
                  "writeOperationType": {}
                }
              }
            }
          }
        }
      }
    },
    "S3g": {
      "type": "structure",
      "members": {
        "failOnFirstDestinationError": {
          "type": "boolean"
        },
        "bucketPrefix": {},
        "bucketName": {}
      }
    },
    "S3k": {
      "type": "structure",
      "members": {
        "prefixType": {},
        "prefixFormat": {}
      }
    },
    "S3n": {
      "type": "structure",
      "members": {
        "aggregationType": {}
      }
    },
    "S3q": {
      "type": "list",
      "member": {}
    },
    "S44": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "sourceFields",
          "taskType"
        ],
        "members": {
          "sourceFields": {
            "type": "list",
            "member": {}
          },
          "connectorOperator": {
            "type": "structure",
            "members": {
              "Amplitude": {},
              "Datadog": {},
              "Dynatrace": {},
              "GoogleAnalytics": {},
              "InforNexus": {},
              "Marketo": {},
              "S3": {},
              "Salesforce": {},
              "ServiceNow": {},
              "Singular": {},
              "Slack": {},
              "Trendmicro": {},
              "Veeva": {},
              "Zendesk": {},
              "SAPOData": {}
            }
          },
          "destinationField": {},
          "taskType": {},
          "taskProperties": {
            "type": "map",
            "key": {},
            "value": {}
          }
        }
      }
    },
    "S4t": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "S5x": {
      "type": "list",
      "member": {}
    },
    "S6u": {
      "type": "structure",
      "members": {
        "mostRecentExecutionMessage": {},
        "mostRecentExecutionTime": {
          "type": "timestamp"
        },
        "mostRecentExecutionStatus": {}
      }
    }
  }
}