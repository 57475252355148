{
  "version": "2.0",
  "metadata": {
    "apiVersion": "2021-03-12",
    "endpointPrefix": "finspace",
    "jsonVersion": "1.1",
    "protocol": "rest-json",
    "serviceAbbreviation": "finspace",
    "serviceFullName": "FinSpace User Environment Management service",
    "serviceId": "finspace",
    "signatureVersion": "v4",
    "signingName": "finspace",
    "uid": "finspace-2021-03-12"
  },
  "operations": {
    "CreateEnvironment": {
      "http": {
        "requestUri": "/environment"
      },
      "input": {
        "type": "structure",
        "required": [
          "name"
        ],
        "members": {
          "name": {},
          "description": {},
          "kmsKeyId": {},
          "tags": {
            "shape": "S5"
          },
          "federationMode": {},
          "federationParameters": {
            "shape": "S9"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "environmentId": {},
          "environmentArn": {},
          "environmentUrl": {}
        }
      }
    },
    "DeleteEnvironment": {
      "http": {
        "method": "DELETE",
        "requestUri": "/environment/{environmentId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "environmentId"
        ],
        "members": {
          "environmentId": {
            "location": "uri",
            "locationName": "environmentId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "GetEnvironment": {
      "http": {
        "method": "GET",
        "requestUri": "/environment/{environmentId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "environmentId"
        ],
        "members": {
          "environmentId": {
            "location": "uri",
            "locationName": "environmentId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "environment": {
            "shape": "Sn"
          }
        }
      }
    },
    "ListEnvironments": {
      "http": {
        "method": "GET",
        "requestUri": "/environment"
      },
      "input": {
        "type": "structure",
        "members": {
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "environments": {
            "type": "list",
            "member": {
              "shape": "Sn"
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListTagsForResource": {
      "http": {
        "method": "GET",
        "requestUri": "/tags/{resourceArn}"
      },
      "input": {
        "type": "structure",
        "required": [
          "resourceArn"
        ],
        "members": {
          "resourceArn": {
            "location": "uri",
            "locationName": "resourceArn"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "tags": {
            "shape": "S5"
          }
        }
      }
    },
    "TagResource": {
      "http": {
        "requestUri": "/tags/{resourceArn}"
      },
      "input": {
        "type": "structure",
        "required": [
          "resourceArn",
          "tags"
        ],
        "members": {
          "resourceArn": {
            "location": "uri",
            "locationName": "resourceArn"
          },
          "tags": {
            "shape": "S5"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UntagResource": {
      "http": {
        "method": "DELETE",
        "requestUri": "/tags/{resourceArn}"
      },
      "input": {
        "type": "structure",
        "required": [
          "resourceArn",
          "tagKeys"
        ],
        "members": {
          "resourceArn": {
            "location": "uri",
            "locationName": "resourceArn"
          },
          "tagKeys": {
            "location": "querystring",
            "locationName": "tagKeys",
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UpdateEnvironment": {
      "http": {
        "method": "PUT",
        "requestUri": "/environment/{environmentId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "environmentId"
        ],
        "members": {
          "environmentId": {
            "location": "uri",
            "locationName": "environmentId"
          },
          "name": {},
          "description": {},
          "federationMode": {},
          "federationParameters": {
            "shape": "S9"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "environment": {
            "shape": "Sn"
          }
        }
      }
    }
  },
  "shapes": {
    "S5": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "S9": {
      "type": "structure",
      "members": {
        "samlMetadataDocument": {},
        "samlMetadataURL": {},
        "applicationCallBackURL": {},
        "federationURN": {},
        "federationProviderName": {},
        "attributeMap": {
          "type": "map",
          "key": {},
          "value": {}
        }
      }
    },
    "Sn": {
      "type": "structure",
      "members": {
        "name": {},
        "environmentId": {},
        "awsAccountId": {},
        "status": {},
        "environmentUrl": {},
        "description": {},
        "environmentArn": {},
        "sageMakerStudioDomainUrl": {},
        "kmsKeyId": {},
        "dedicatedServiceAccountId": {},
        "federationMode": {},
        "federationParameters": {
          "shape": "S9"
        }
      }
    }
  }
}