{
  "version": "2.0",
  "metadata": {
    "apiVersion": "2020-07-14",
    "endpointPrefix": "ivs",
    "jsonVersion": "1.1",
    "protocol": "rest-json",
    "serviceAbbreviation": "Amazon IVS",
    "serviceFullName": "Amazon Interactive Video Service",
    "serviceId": "ivs",
    "signatureVersion": "v4",
    "signingName": "ivs",
    "uid": "ivs-2020-07-14"
  },
  "operations": {
    "BatchGetChannel": {
      "http": {
        "requestUri": "/BatchGetChannel",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "arns"
        ],
        "members": {
          "arns": {
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "channels": {
            "type": "list",
            "member": {
              "shape": "S6"
            }
          },
          "errors": {
            "shape": "Sh"
          }
        }
      }
    },
    "BatchGetStreamKey": {
      "http": {
        "requestUri": "/BatchGetStreamKey",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "arns"
        ],
        "members": {
          "arns": {
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "errors": {
            "shape": "Sh"
          },
          "streamKeys": {
            "type": "list",
            "member": {
              "shape": "Sr"
            }
          }
        }
      }
    },
    "CreateChannel": {
      "http": {
        "requestUri": "/CreateChannel",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "authorized": {
            "type": "boolean"
          },
          "latencyMode": {},
          "name": {},
          "recordingConfigurationArn": {},
          "tags": {
            "shape": "Sd"
          },
          "type": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "channel": {
            "shape": "S6"
          },
          "streamKey": {
            "shape": "Sr"
          }
        }
      }
    },
    "CreateRecordingConfiguration": {
      "http": {
        "requestUri": "/CreateRecordingConfiguration",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "destinationConfiguration"
        ],
        "members": {
          "destinationConfiguration": {
            "shape": "Sx"
          },
          "name": {},
          "tags": {
            "shape": "Sd"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "recordingConfiguration": {
            "shape": "S12"
          }
        }
      }
    },
    "CreateStreamKey": {
      "http": {
        "requestUri": "/CreateStreamKey",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "channelArn"
        ],
        "members": {
          "channelArn": {},
          "tags": {
            "shape": "Sd"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "streamKey": {
            "shape": "Sr"
          }
        }
      }
    },
    "DeleteChannel": {
      "http": {
        "requestUri": "/DeleteChannel",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "arn"
        ],
        "members": {
          "arn": {}
        }
      }
    },
    "DeletePlaybackKeyPair": {
      "http": {
        "requestUri": "/DeletePlaybackKeyPair",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "arn"
        ],
        "members": {
          "arn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteRecordingConfiguration": {
      "http": {
        "requestUri": "/DeleteRecordingConfiguration",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "arn"
        ],
        "members": {
          "arn": {}
        }
      }
    },
    "DeleteStreamKey": {
      "http": {
        "requestUri": "/DeleteStreamKey",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "arn"
        ],
        "members": {
          "arn": {}
        }
      }
    },
    "GetChannel": {
      "http": {
        "requestUri": "/GetChannel",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "arn"
        ],
        "members": {
          "arn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "channel": {
            "shape": "S6"
          }
        }
      }
    },
    "GetPlaybackKeyPair": {
      "http": {
        "requestUri": "/GetPlaybackKeyPair",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "arn"
        ],
        "members": {
          "arn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "keyPair": {
            "shape": "S1h"
          }
        }
      }
    },
    "GetRecordingConfiguration": {
      "http": {
        "requestUri": "/GetRecordingConfiguration",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "arn"
        ],
        "members": {
          "arn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "recordingConfiguration": {
            "shape": "S12"
          }
        }
      }
    },
    "GetStream": {
      "http": {
        "requestUri": "/GetStream",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "channelArn"
        ],
        "members": {
          "channelArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "stream": {
            "type": "structure",
            "members": {
              "channelArn": {},
              "health": {},
              "playbackUrl": {},
              "startTime": {
                "shape": "S1q"
              },
              "state": {},
              "viewerCount": {
                "type": "long"
              }
            }
          }
        }
      }
    },
    "GetStreamKey": {
      "http": {
        "requestUri": "/GetStreamKey",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "arn"
        ],
        "members": {
          "arn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "streamKey": {
            "shape": "Sr"
          }
        }
      }
    },
    "ImportPlaybackKeyPair": {
      "http": {
        "requestUri": "/ImportPlaybackKeyPair",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "publicKeyMaterial"
        ],
        "members": {
          "name": {},
          "publicKeyMaterial": {},
          "tags": {
            "shape": "Sd"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "keyPair": {
            "shape": "S1h"
          }
        }
      }
    },
    "ListChannels": {
      "http": {
        "requestUri": "/ListChannels",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "filterByName": {},
          "filterByRecordingConfigurationArn": {},
          "maxResults": {
            "type": "integer"
          },
          "nextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "channels"
        ],
        "members": {
          "channels": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "arn": {},
                "authorized": {
                  "type": "boolean"
                },
                "latencyMode": {},
                "name": {},
                "recordingConfigurationArn": {},
                "tags": {
                  "shape": "Sd"
                }
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListPlaybackKeyPairs": {
      "http": {
        "requestUri": "/ListPlaybackKeyPairs",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "maxResults": {
            "type": "integer"
          },
          "nextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "keyPairs"
        ],
        "members": {
          "keyPairs": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "arn": {},
                "name": {},
                "tags": {
                  "shape": "Sd"
                }
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListRecordingConfigurations": {
      "http": {
        "requestUri": "/ListRecordingConfigurations",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "maxResults": {
            "type": "integer"
          },
          "nextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "recordingConfigurations"
        ],
        "members": {
          "nextToken": {},
          "recordingConfigurations": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "arn",
                "destinationConfiguration",
                "state"
              ],
              "members": {
                "arn": {},
                "destinationConfiguration": {
                  "shape": "Sx"
                },
                "name": {},
                "state": {},
                "tags": {
                  "shape": "Sd"
                }
              }
            }
          }
        }
      }
    },
    "ListStreamKeys": {
      "http": {
        "requestUri": "/ListStreamKeys",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "channelArn"
        ],
        "members": {
          "channelArn": {},
          "maxResults": {
            "type": "integer"
          },
          "nextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "streamKeys"
        ],
        "members": {
          "nextToken": {},
          "streamKeys": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "arn": {},
                "channelArn": {},
                "tags": {
                  "shape": "Sd"
                }
              }
            }
          }
        }
      }
    },
    "ListStreams": {
      "http": {
        "requestUri": "/ListStreams",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "maxResults": {
            "type": "integer"
          },
          "nextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "streams"
        ],
        "members": {
          "nextToken": {},
          "streams": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "channelArn": {},
                "health": {},
                "startTime": {
                  "shape": "S1q"
                },
                "state": {},
                "viewerCount": {
                  "type": "long"
                }
              }
            }
          }
        }
      }
    },
    "ListTagsForResource": {
      "http": {
        "method": "GET",
        "requestUri": "/tags/{resourceArn}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "resourceArn"
        ],
        "members": {
          "resourceArn": {
            "location": "uri",
            "locationName": "resourceArn"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "tags"
        ],
        "members": {
          "tags": {
            "shape": "Sd"
          }
        }
      }
    },
    "PutMetadata": {
      "http": {
        "requestUri": "/PutMetadata",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "channelArn",
          "metadata"
        ],
        "members": {
          "channelArn": {},
          "metadata": {
            "type": "string",
            "sensitive": true
          }
        }
      }
    },
    "StopStream": {
      "http": {
        "requestUri": "/StopStream",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "channelArn"
        ],
        "members": {
          "channelArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "TagResource": {
      "http": {
        "requestUri": "/tags/{resourceArn}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "resourceArn",
          "tags"
        ],
        "members": {
          "resourceArn": {
            "location": "uri",
            "locationName": "resourceArn"
          },
          "tags": {
            "shape": "Sd"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UntagResource": {
      "http": {
        "method": "DELETE",
        "requestUri": "/tags/{resourceArn}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "resourceArn",
          "tagKeys"
        ],
        "members": {
          "resourceArn": {
            "location": "uri",
            "locationName": "resourceArn"
          },
          "tagKeys": {
            "location": "querystring",
            "locationName": "tagKeys",
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    },
    "UpdateChannel": {
      "http": {
        "requestUri": "/UpdateChannel",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "arn"
        ],
        "members": {
          "arn": {},
          "authorized": {
            "type": "boolean"
          },
          "latencyMode": {},
          "name": {},
          "recordingConfigurationArn": {},
          "type": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "channel": {
            "shape": "S6"
          }
        }
      }
    }
  },
  "shapes": {
    "S6": {
      "type": "structure",
      "members": {
        "arn": {},
        "authorized": {
          "type": "boolean"
        },
        "ingestEndpoint": {},
        "latencyMode": {},
        "name": {},
        "playbackUrl": {},
        "recordingConfigurationArn": {},
        "tags": {
          "shape": "Sd"
        },
        "type": {}
      }
    },
    "Sd": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "Sh": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "arn": {},
          "code": {},
          "message": {}
        }
      }
    },
    "Sr": {
      "type": "structure",
      "members": {
        "arn": {},
        "channelArn": {},
        "tags": {
          "shape": "Sd"
        },
        "value": {
          "type": "string",
          "sensitive": true
        }
      }
    },
    "Sx": {
      "type": "structure",
      "members": {
        "s3": {
          "type": "structure",
          "required": [
            "bucketName"
          ],
          "members": {
            "bucketName": {}
          }
        }
      }
    },
    "S12": {
      "type": "structure",
      "required": [
        "arn",
        "destinationConfiguration",
        "state"
      ],
      "members": {
        "arn": {},
        "destinationConfiguration": {
          "shape": "Sx"
        },
        "name": {},
        "state": {},
        "tags": {
          "shape": "Sd"
        }
      }
    },
    "S1h": {
      "type": "structure",
      "members": {
        "arn": {},
        "fingerprint": {},
        "name": {},
        "tags": {
          "shape": "Sd"
        }
      }
    },
    "S1q": {
      "type": "timestamp",
      "timestampFormat": "iso8601"
    }
  }
}