{
  "version": "2.0",
  "metadata": {
    "apiVersion": "2016-01-01",
    "endpointPrefix": "dms",
    "jsonVersion": "1.1",
    "protocol": "json",
    "serviceFullName": "AWS Database Migration Service",
    "serviceId": "Database Migration Service",
    "signatureVersion": "v4",
    "targetPrefix": "AmazonDMSv20160101",
    "uid": "dms-2016-01-01"
  },
  "operations": {
    "AddTagsToResource": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceArn",
          "Tags"
        ],
        "members": {
          "ResourceArn": {},
          "Tags": {
            "shape": "S3"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "ApplyPendingMaintenanceAction": {
      "input": {
        "type": "structure",
        "required": [
          "ReplicationInstanceArn",
          "ApplyAction",
          "OptInType"
        ],
        "members": {
          "ReplicationInstanceArn": {},
          "ApplyAction": {},
          "OptInType": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ResourcePendingMaintenanceActions": {
            "shape": "S8"
          }
        }
      }
    },
    "CancelReplicationTaskAssessmentRun": {
      "input": {
        "type": "structure",
        "required": [
          "ReplicationTaskAssessmentRunArn"
        ],
        "members": {
          "ReplicationTaskAssessmentRunArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ReplicationTaskAssessmentRun": {
            "shape": "Se"
          }
        }
      }
    },
    "CreateEndpoint": {
      "input": {
        "type": "structure",
        "required": [
          "EndpointIdentifier",
          "EndpointType",
          "EngineName"
        ],
        "members": {
          "EndpointIdentifier": {},
          "EndpointType": {},
          "EngineName": {},
          "Username": {},
          "Password": {
            "shape": "Sj"
          },
          "ServerName": {},
          "Port": {
            "type": "integer"
          },
          "DatabaseName": {},
          "ExtraConnectionAttributes": {},
          "KmsKeyId": {},
          "Tags": {
            "shape": "S3"
          },
          "CertificateArn": {},
          "SslMode": {},
          "ServiceAccessRoleArn": {},
          "ExternalTableDefinition": {},
          "DynamoDbSettings": {
            "shape": "Sm"
          },
          "S3Settings": {
            "shape": "Sn"
          },
          "DmsTransferSettings": {
            "shape": "Sx"
          },
          "MongoDbSettings": {
            "shape": "Sy"
          },
          "KinesisSettings": {
            "shape": "S12"
          },
          "KafkaSettings": {
            "shape": "S14"
          },
          "ElasticsearchSettings": {
            "shape": "S16"
          },
          "NeptuneSettings": {
            "shape": "S17"
          },
          "RedshiftSettings": {
            "shape": "S18"
          },
          "PostgreSQLSettings": {
            "shape": "S19"
          },
          "MySQLSettings": {
            "shape": "S1b"
          },
          "OracleSettings": {
            "shape": "S1d"
          },
          "SybaseSettings": {
            "shape": "S1g"
          },
          "MicrosoftSQLServerSettings": {
            "shape": "S1h"
          },
          "IBMDb2Settings": {
            "shape": "S1j"
          },
          "ResourceIdentifier": {},
          "DocDbSettings": {
            "shape": "S1k"
          },
          "RedisSettings": {
            "shape": "S1l"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Endpoint": {
            "shape": "S1p"
          }
        }
      }
    },
    "CreateEventSubscription": {
      "input": {
        "type": "structure",
        "required": [
          "SubscriptionName",
          "SnsTopicArn"
        ],
        "members": {
          "SubscriptionName": {},
          "SnsTopicArn": {},
          "SourceType": {},
          "EventCategories": {
            "shape": "S1r"
          },
          "SourceIds": {
            "shape": "S1s"
          },
          "Enabled": {
            "type": "boolean"
          },
          "Tags": {
            "shape": "S3"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "EventSubscription": {
            "shape": "S1u"
          }
        }
      }
    },
    "CreateReplicationInstance": {
      "input": {
        "type": "structure",
        "required": [
          "ReplicationInstanceIdentifier",
          "ReplicationInstanceClass"
        ],
        "members": {
          "ReplicationInstanceIdentifier": {},
          "AllocatedStorage": {
            "type": "integer"
          },
          "ReplicationInstanceClass": {},
          "VpcSecurityGroupIds": {
            "shape": "S1x"
          },
          "AvailabilityZone": {},
          "ReplicationSubnetGroupIdentifier": {},
          "PreferredMaintenanceWindow": {},
          "MultiAZ": {
            "type": "boolean"
          },
          "EngineVersion": {},
          "AutoMinorVersionUpgrade": {
            "type": "boolean"
          },
          "Tags": {
            "shape": "S3"
          },
          "KmsKeyId": {},
          "PubliclyAccessible": {
            "type": "boolean"
          },
          "DnsNameServers": {},
          "ResourceIdentifier": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ReplicationInstance": {
            "shape": "S1z"
          }
        }
      }
    },
    "CreateReplicationSubnetGroup": {
      "input": {
        "type": "structure",
        "required": [
          "ReplicationSubnetGroupIdentifier",
          "ReplicationSubnetGroupDescription",
          "SubnetIds"
        ],
        "members": {
          "ReplicationSubnetGroupIdentifier": {},
          "ReplicationSubnetGroupDescription": {},
          "SubnetIds": {
            "shape": "S2a"
          },
          "Tags": {
            "shape": "S3"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ReplicationSubnetGroup": {
            "shape": "S22"
          }
        }
      }
    },
    "CreateReplicationTask": {
      "input": {
        "type": "structure",
        "required": [
          "ReplicationTaskIdentifier",
          "SourceEndpointArn",
          "TargetEndpointArn",
          "ReplicationInstanceArn",
          "MigrationType",
          "TableMappings"
        ],
        "members": {
          "ReplicationTaskIdentifier": {},
          "SourceEndpointArn": {},
          "TargetEndpointArn": {},
          "ReplicationInstanceArn": {},
          "MigrationType": {},
          "TableMappings": {},
          "ReplicationTaskSettings": {},
          "CdcStartTime": {
            "type": "timestamp"
          },
          "CdcStartPosition": {},
          "CdcStopPosition": {},
          "Tags": {
            "shape": "S3"
          },
          "TaskData": {},
          "ResourceIdentifier": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ReplicationTask": {
            "shape": "S2f"
          }
        }
      }
    },
    "DeleteCertificate": {
      "input": {
        "type": "structure",
        "required": [
          "CertificateArn"
        ],
        "members": {
          "CertificateArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Certificate": {
            "shape": "S2k"
          }
        }
      }
    },
    "DeleteConnection": {
      "input": {
        "type": "structure",
        "required": [
          "EndpointArn",
          "ReplicationInstanceArn"
        ],
        "members": {
          "EndpointArn": {},
          "ReplicationInstanceArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Connection": {
            "shape": "S2o"
          }
        }
      }
    },
    "DeleteEndpoint": {
      "input": {
        "type": "structure",
        "required": [
          "EndpointArn"
        ],
        "members": {
          "EndpointArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Endpoint": {
            "shape": "S1p"
          }
        }
      }
    },
    "DeleteEventSubscription": {
      "input": {
        "type": "structure",
        "required": [
          "SubscriptionName"
        ],
        "members": {
          "SubscriptionName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "EventSubscription": {
            "shape": "S1u"
          }
        }
      }
    },
    "DeleteReplicationInstance": {
      "input": {
        "type": "structure",
        "required": [
          "ReplicationInstanceArn"
        ],
        "members": {
          "ReplicationInstanceArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ReplicationInstance": {
            "shape": "S1z"
          }
        }
      }
    },
    "DeleteReplicationSubnetGroup": {
      "input": {
        "type": "structure",
        "required": [
          "ReplicationSubnetGroupIdentifier"
        ],
        "members": {
          "ReplicationSubnetGroupIdentifier": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteReplicationTask": {
      "input": {
        "type": "structure",
        "required": [
          "ReplicationTaskArn"
        ],
        "members": {
          "ReplicationTaskArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ReplicationTask": {
            "shape": "S2f"
          }
        }
      }
    },
    "DeleteReplicationTaskAssessmentRun": {
      "input": {
        "type": "structure",
        "required": [
          "ReplicationTaskAssessmentRunArn"
        ],
        "members": {
          "ReplicationTaskAssessmentRunArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ReplicationTaskAssessmentRun": {
            "shape": "Se"
          }
        }
      }
    },
    "DescribeAccountAttributes": {
      "input": {
        "type": "structure",
        "members": {}
      },
      "output": {
        "type": "structure",
        "members": {
          "AccountQuotas": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "AccountQuotaName": {},
                "Used": {
                  "type": "long"
                },
                "Max": {
                  "type": "long"
                }
              }
            }
          },
          "UniqueAccountIdentifier": {}
        }
      }
    },
    "DescribeApplicableIndividualAssessments": {
      "input": {
        "type": "structure",
        "members": {
          "ReplicationTaskArn": {},
          "ReplicationInstanceArn": {},
          "SourceEngineName": {},
          "TargetEngineName": {},
          "MigrationType": {},
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "IndividualAssessmentNames": {
            "type": "list",
            "member": {}
          },
          "Marker": {}
        }
      }
    },
    "DescribeCertificates": {
      "input": {
        "type": "structure",
        "members": {
          "Filters": {
            "shape": "S39"
          },
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Marker": {},
          "Certificates": {
            "type": "list",
            "member": {
              "shape": "S2k"
            }
          }
        }
      }
    },
    "DescribeConnections": {
      "input": {
        "type": "structure",
        "members": {
          "Filters": {
            "shape": "S39"
          },
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Marker": {},
          "Connections": {
            "type": "list",
            "member": {
              "shape": "S2o"
            }
          }
        }
      }
    },
    "DescribeEndpointSettings": {
      "input": {
        "type": "structure",
        "required": [
          "EngineName"
        ],
        "members": {
          "EngineName": {},
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Marker": {},
          "EndpointSettings": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Name": {},
                "Type": {},
                "EnumValues": {
                  "type": "list",
                  "member": {}
                },
                "Sensitive": {
                  "type": "boolean"
                },
                "Units": {},
                "Applicability": {},
                "IntValueMin": {
                  "type": "integer"
                },
                "IntValueMax": {
                  "type": "integer"
                },
                "DefaultValue": {}
              }
            }
          }
        }
      }
    },
    "DescribeEndpointTypes": {
      "input": {
        "type": "structure",
        "members": {
          "Filters": {
            "shape": "S39"
          },
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Marker": {},
          "SupportedEndpointTypes": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "EngineName": {},
                "SupportsCDC": {
                  "type": "boolean"
                },
                "EndpointType": {},
                "ReplicationInstanceEngineMinimumVersion": {},
                "EngineDisplayName": {}
              }
            }
          }
        }
      }
    },
    "DescribeEndpoints": {
      "input": {
        "type": "structure",
        "members": {
          "Filters": {
            "shape": "S39"
          },
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Marker": {},
          "Endpoints": {
            "type": "list",
            "member": {
              "shape": "S1p"
            }
          }
        }
      }
    },
    "DescribeEventCategories": {
      "input": {
        "type": "structure",
        "members": {
          "SourceType": {},
          "Filters": {
            "shape": "S39"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "EventCategoryGroupList": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "SourceType": {},
                "EventCategories": {
                  "shape": "S1r"
                }
              }
            }
          }
        }
      }
    },
    "DescribeEventSubscriptions": {
      "input": {
        "type": "structure",
        "members": {
          "SubscriptionName": {},
          "Filters": {
            "shape": "S39"
          },
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Marker": {},
          "EventSubscriptionsList": {
            "type": "list",
            "member": {
              "shape": "S1u"
            }
          }
        }
      }
    },
    "DescribeEvents": {
      "input": {
        "type": "structure",
        "members": {
          "SourceIdentifier": {},
          "SourceType": {},
          "StartTime": {
            "type": "timestamp"
          },
          "EndTime": {
            "type": "timestamp"
          },
          "Duration": {
            "type": "integer"
          },
          "EventCategories": {
            "shape": "S1r"
          },
          "Filters": {
            "shape": "S39"
          },
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Marker": {},
          "Events": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "SourceIdentifier": {},
                "SourceType": {},
                "Message": {},
                "EventCategories": {
                  "shape": "S1r"
                },
                "Date": {
                  "type": "timestamp"
                }
              }
            }
          }
        }
      }
    },
    "DescribeOrderableReplicationInstances": {
      "input": {
        "type": "structure",
        "members": {
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "OrderableReplicationInstances": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "EngineVersion": {},
                "ReplicationInstanceClass": {},
                "StorageType": {},
                "MinAllocatedStorage": {
                  "type": "integer"
                },
                "MaxAllocatedStorage": {
                  "type": "integer"
                },
                "DefaultAllocatedStorage": {
                  "type": "integer"
                },
                "IncludedAllocatedStorage": {
                  "type": "integer"
                },
                "AvailabilityZones": {
                  "type": "list",
                  "member": {}
                },
                "ReleaseStatus": {}
              }
            }
          },
          "Marker": {}
        }
      }
    },
    "DescribePendingMaintenanceActions": {
      "input": {
        "type": "structure",
        "members": {
          "ReplicationInstanceArn": {},
          "Filters": {
            "shape": "S39"
          },
          "Marker": {},
          "MaxRecords": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "PendingMaintenanceActions": {
            "type": "list",
            "member": {
              "shape": "S8"
            }
          },
          "Marker": {}
        }
      }
    },
    "DescribeRefreshSchemasStatus": {
      "input": {
        "type": "structure",
        "required": [
          "EndpointArn"
        ],
        "members": {
          "EndpointArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "RefreshSchemasStatus": {
            "shape": "S4h"
          }
        }
      }
    },
    "DescribeReplicationInstanceTaskLogs": {
      "input": {
        "type": "structure",
        "required": [
          "ReplicationInstanceArn"
        ],
        "members": {
          "ReplicationInstanceArn": {},
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ReplicationInstanceArn": {},
          "ReplicationInstanceTaskLogs": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "ReplicationTaskName": {},
                "ReplicationTaskArn": {},
                "ReplicationInstanceTaskLogSize": {
                  "type": "long"
                }
              }
            }
          },
          "Marker": {}
        }
      }
    },
    "DescribeReplicationInstances": {
      "input": {
        "type": "structure",
        "members": {
          "Filters": {
            "shape": "S39"
          },
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Marker": {},
          "ReplicationInstances": {
            "type": "list",
            "member": {
              "shape": "S1z"
            }
          }
        }
      }
    },
    "DescribeReplicationSubnetGroups": {
      "input": {
        "type": "structure",
        "members": {
          "Filters": {
            "shape": "S39"
          },
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Marker": {},
          "ReplicationSubnetGroups": {
            "type": "list",
            "member": {
              "shape": "S22"
            }
          }
        }
      }
    },
    "DescribeReplicationTaskAssessmentResults": {
      "input": {
        "type": "structure",
        "members": {
          "ReplicationTaskArn": {},
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Marker": {},
          "BucketName": {},
          "ReplicationTaskAssessmentResults": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "ReplicationTaskIdentifier": {},
                "ReplicationTaskArn": {},
                "ReplicationTaskLastAssessmentDate": {
                  "type": "timestamp"
                },
                "AssessmentStatus": {},
                "AssessmentResultsFile": {},
                "AssessmentResults": {},
                "S3ObjectUrl": {}
              }
            }
          }
        }
      }
    },
    "DescribeReplicationTaskAssessmentRuns": {
      "input": {
        "type": "structure",
        "members": {
          "Filters": {
            "shape": "S39"
          },
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Marker": {},
          "ReplicationTaskAssessmentRuns": {
            "type": "list",
            "member": {
              "shape": "Se"
            }
          }
        }
      }
    },
    "DescribeReplicationTaskIndividualAssessments": {
      "input": {
        "type": "structure",
        "members": {
          "Filters": {
            "shape": "S39"
          },
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Marker": {},
          "ReplicationTaskIndividualAssessments": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "ReplicationTaskIndividualAssessmentArn": {},
                "ReplicationTaskAssessmentRunArn": {},
                "IndividualAssessmentName": {},
                "Status": {},
                "ReplicationTaskIndividualAssessmentStartDate": {
                  "type": "timestamp"
                }
              }
            }
          }
        }
      }
    },
    "DescribeReplicationTasks": {
      "input": {
        "type": "structure",
        "members": {
          "Filters": {
            "shape": "S39"
          },
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {},
          "WithoutSettings": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Marker": {},
          "ReplicationTasks": {
            "type": "list",
            "member": {
              "shape": "S2f"
            }
          }
        }
      }
    },
    "DescribeSchemas": {
      "input": {
        "type": "structure",
        "required": [
          "EndpointArn"
        ],
        "members": {
          "EndpointArn": {},
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Marker": {},
          "Schemas": {
            "type": "list",
            "member": {}
          }
        }
      }
    },
    "DescribeTableStatistics": {
      "input": {
        "type": "structure",
        "required": [
          "ReplicationTaskArn"
        ],
        "members": {
          "ReplicationTaskArn": {},
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {},
          "Filters": {
            "shape": "S39"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ReplicationTaskArn": {},
          "TableStatistics": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "SchemaName": {},
                "TableName": {},
                "Inserts": {
                  "type": "long"
                },
                "Deletes": {
                  "type": "long"
                },
                "Updates": {
                  "type": "long"
                },
                "Ddls": {
                  "type": "long"
                },
                "FullLoadRows": {
                  "type": "long"
                },
                "FullLoadCondtnlChkFailedRows": {
                  "type": "long"
                },
                "FullLoadErrorRows": {
                  "type": "long"
                },
                "FullLoadStartTime": {
                  "type": "timestamp"
                },
                "FullLoadEndTime": {
                  "type": "timestamp"
                },
                "FullLoadReloaded": {
                  "type": "boolean"
                },
                "LastUpdateTime": {
                  "type": "timestamp"
                },
                "TableState": {},
                "ValidationPendingRecords": {
                  "type": "long"
                },
                "ValidationFailedRecords": {
                  "type": "long"
                },
                "ValidationSuspendedRecords": {
                  "type": "long"
                },
                "ValidationState": {},
                "ValidationStateDetails": {}
              }
            }
          },
          "Marker": {}
        }
      }
    },
    "ImportCertificate": {
      "input": {
        "type": "structure",
        "required": [
          "CertificateIdentifier"
        ],
        "members": {
          "CertificateIdentifier": {},
          "CertificatePem": {
            "shape": "Sj"
          },
          "CertificateWallet": {
            "type": "blob"
          },
          "Tags": {
            "shape": "S3"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Certificate": {
            "shape": "S2k"
          }
        }
      }
    },
    "ListTagsForResource": {
      "input": {
        "type": "structure",
        "members": {
          "ResourceArn": {},
          "ResourceArnList": {
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TagList": {
            "shape": "S3"
          }
        }
      }
    },
    "ModifyEndpoint": {
      "input": {
        "type": "structure",
        "required": [
          "EndpointArn"
        ],
        "members": {
          "EndpointArn": {},
          "EndpointIdentifier": {},
          "EndpointType": {},
          "EngineName": {},
          "Username": {},
          "Password": {
            "shape": "Sj"
          },
          "ServerName": {},
          "Port": {
            "type": "integer"
          },
          "DatabaseName": {},
          "ExtraConnectionAttributes": {},
          "CertificateArn": {},
          "SslMode": {},
          "ServiceAccessRoleArn": {},
          "ExternalTableDefinition": {},
          "DynamoDbSettings": {
            "shape": "Sm"
          },
          "S3Settings": {
            "shape": "Sn"
          },
          "DmsTransferSettings": {
            "shape": "Sx"
          },
          "MongoDbSettings": {
            "shape": "Sy"
          },
          "KinesisSettings": {
            "shape": "S12"
          },
          "KafkaSettings": {
            "shape": "S14"
          },
          "ElasticsearchSettings": {
            "shape": "S16"
          },
          "NeptuneSettings": {
            "shape": "S17"
          },
          "RedshiftSettings": {
            "shape": "S18"
          },
          "PostgreSQLSettings": {
            "shape": "S19"
          },
          "MySQLSettings": {
            "shape": "S1b"
          },
          "OracleSettings": {
            "shape": "S1d"
          },
          "SybaseSettings": {
            "shape": "S1g"
          },
          "MicrosoftSQLServerSettings": {
            "shape": "S1h"
          },
          "IBMDb2Settings": {
            "shape": "S1j"
          },
          "DocDbSettings": {
            "shape": "S1k"
          },
          "RedisSettings": {
            "shape": "S1l"
          },
          "ExactSettings": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Endpoint": {
            "shape": "S1p"
          }
        }
      }
    },
    "ModifyEventSubscription": {
      "input": {
        "type": "structure",
        "required": [
          "SubscriptionName"
        ],
        "members": {
          "SubscriptionName": {},
          "SnsTopicArn": {},
          "SourceType": {},
          "EventCategories": {
            "shape": "S1r"
          },
          "Enabled": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "EventSubscription": {
            "shape": "S1u"
          }
        }
      }
    },
    "ModifyReplicationInstance": {
      "input": {
        "type": "structure",
        "required": [
          "ReplicationInstanceArn"
        ],
        "members": {
          "ReplicationInstanceArn": {},
          "AllocatedStorage": {
            "type": "integer"
          },
          "ApplyImmediately": {
            "type": "boolean"
          },
          "ReplicationInstanceClass": {},
          "VpcSecurityGroupIds": {
            "shape": "S1x"
          },
          "PreferredMaintenanceWindow": {},
          "MultiAZ": {
            "type": "boolean"
          },
          "EngineVersion": {},
          "AllowMajorVersionUpgrade": {
            "type": "boolean"
          },
          "AutoMinorVersionUpgrade": {
            "type": "boolean"
          },
          "ReplicationInstanceIdentifier": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ReplicationInstance": {
            "shape": "S1z"
          }
        }
      }
    },
    "ModifyReplicationSubnetGroup": {
      "input": {
        "type": "structure",
        "required": [
          "ReplicationSubnetGroupIdentifier",
          "SubnetIds"
        ],
        "members": {
          "ReplicationSubnetGroupIdentifier": {},
          "ReplicationSubnetGroupDescription": {},
          "SubnetIds": {
            "shape": "S2a"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ReplicationSubnetGroup": {
            "shape": "S22"
          }
        }
      }
    },
    "ModifyReplicationTask": {
      "input": {
        "type": "structure",
        "required": [
          "ReplicationTaskArn"
        ],
        "members": {
          "ReplicationTaskArn": {},
          "ReplicationTaskIdentifier": {},
          "MigrationType": {},
          "TableMappings": {},
          "ReplicationTaskSettings": {},
          "CdcStartTime": {
            "type": "timestamp"
          },
          "CdcStartPosition": {},
          "CdcStopPosition": {},
          "TaskData": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ReplicationTask": {
            "shape": "S2f"
          }
        }
      }
    },
    "MoveReplicationTask": {
      "input": {
        "type": "structure",
        "required": [
          "ReplicationTaskArn",
          "TargetReplicationInstanceArn"
        ],
        "members": {
          "ReplicationTaskArn": {},
          "TargetReplicationInstanceArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ReplicationTask": {
            "shape": "S2f"
          }
        }
      }
    },
    "RebootReplicationInstance": {
      "input": {
        "type": "structure",
        "required": [
          "ReplicationInstanceArn"
        ],
        "members": {
          "ReplicationInstanceArn": {},
          "ForceFailover": {
            "type": "boolean"
          },
          "ForcePlannedFailover": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ReplicationInstance": {
            "shape": "S1z"
          }
        }
      }
    },
    "RefreshSchemas": {
      "input": {
        "type": "structure",
        "required": [
          "EndpointArn",
          "ReplicationInstanceArn"
        ],
        "members": {
          "EndpointArn": {},
          "ReplicationInstanceArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "RefreshSchemasStatus": {
            "shape": "S4h"
          }
        }
      }
    },
    "ReloadTables": {
      "input": {
        "type": "structure",
        "required": [
          "ReplicationTaskArn",
          "TablesToReload"
        ],
        "members": {
          "ReplicationTaskArn": {},
          "TablesToReload": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "SchemaName",
                "TableName"
              ],
              "members": {
                "SchemaName": {},
                "TableName": {}
              }
            }
          },
          "ReloadOption": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ReplicationTaskArn": {}
        }
      }
    },
    "RemoveTagsFromResource": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceArn",
          "TagKeys"
        ],
        "members": {
          "ResourceArn": {},
          "TagKeys": {
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "StartReplicationTask": {
      "input": {
        "type": "structure",
        "required": [
          "ReplicationTaskArn",
          "StartReplicationTaskType"
        ],
        "members": {
          "ReplicationTaskArn": {},
          "StartReplicationTaskType": {},
          "CdcStartTime": {
            "type": "timestamp"
          },
          "CdcStartPosition": {},
          "CdcStopPosition": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ReplicationTask": {
            "shape": "S2f"
          }
        }
      }
    },
    "StartReplicationTaskAssessment": {
      "input": {
        "type": "structure",
        "required": [
          "ReplicationTaskArn"
        ],
        "members": {
          "ReplicationTaskArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ReplicationTask": {
            "shape": "S2f"
          }
        }
      }
    },
    "StartReplicationTaskAssessmentRun": {
      "input": {
        "type": "structure",
        "required": [
          "ReplicationTaskArn",
          "ServiceAccessRoleArn",
          "ResultLocationBucket",
          "AssessmentRunName"
        ],
        "members": {
          "ReplicationTaskArn": {},
          "ServiceAccessRoleArn": {},
          "ResultLocationBucket": {},
          "ResultLocationFolder": {},
          "ResultEncryptionMode": {},
          "ResultKmsKeyArn": {},
          "AssessmentRunName": {},
          "IncludeOnly": {
            "type": "list",
            "member": {}
          },
          "Exclude": {
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ReplicationTaskAssessmentRun": {
            "shape": "Se"
          }
        }
      }
    },
    "StopReplicationTask": {
      "input": {
        "type": "structure",
        "required": [
          "ReplicationTaskArn"
        ],
        "members": {
          "ReplicationTaskArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ReplicationTask": {
            "shape": "S2f"
          }
        }
      }
    },
    "TestConnection": {
      "input": {
        "type": "structure",
        "required": [
          "ReplicationInstanceArn",
          "EndpointArn"
        ],
        "members": {
          "ReplicationInstanceArn": {},
          "EndpointArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Connection": {
            "shape": "S2o"
          }
        }
      }
    }
  },
  "shapes": {
    "S3": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "Key": {},
          "Value": {},
          "ResourceArn": {}
        }
      }
    },
    "S8": {
      "type": "structure",
      "members": {
        "ResourceIdentifier": {},
        "PendingMaintenanceActionDetails": {
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "Action": {},
              "AutoAppliedAfterDate": {
                "type": "timestamp"
              },
              "ForcedApplyDate": {
                "type": "timestamp"
              },
              "OptInStatus": {},
              "CurrentApplyDate": {
                "type": "timestamp"
              },
              "Description": {}
            }
          }
        }
      }
    },
    "Se": {
      "type": "structure",
      "members": {
        "ReplicationTaskAssessmentRunArn": {},
        "ReplicationTaskArn": {},
        "Status": {},
        "ReplicationTaskAssessmentRunCreationDate": {
          "type": "timestamp"
        },
        "AssessmentProgress": {
          "type": "structure",
          "members": {
            "IndividualAssessmentCount": {
              "type": "integer"
            },
            "IndividualAssessmentCompletedCount": {
              "type": "integer"
            }
          }
        },
        "LastFailureMessage": {},
        "ServiceAccessRoleArn": {},
        "ResultLocationBucket": {},
        "ResultLocationFolder": {},
        "ResultEncryptionMode": {},
        "ResultKmsKeyArn": {},
        "AssessmentRunName": {}
      }
    },
    "Sj": {
      "type": "string",
      "sensitive": true
    },
    "Sm": {
      "type": "structure",
      "required": [
        "ServiceAccessRoleArn"
      ],
      "members": {
        "ServiceAccessRoleArn": {}
      }
    },
    "Sn": {
      "type": "structure",
      "members": {
        "ServiceAccessRoleArn": {},
        "ExternalTableDefinition": {},
        "CsvRowDelimiter": {},
        "CsvDelimiter": {},
        "BucketFolder": {},
        "BucketName": {},
        "CompressionType": {},
        "EncryptionMode": {},
        "ServerSideEncryptionKmsKeyId": {},
        "DataFormat": {},
        "EncodingType": {},
        "DictPageSizeLimit": {
          "type": "integer"
        },
        "RowGroupLength": {
          "type": "integer"
        },
        "DataPageSize": {
          "type": "integer"
        },
        "ParquetVersion": {},
        "EnableStatistics": {
          "type": "boolean"
        },
        "IncludeOpForFullLoad": {
          "type": "boolean"
        },
        "CdcInsertsOnly": {
          "type": "boolean"
        },
        "TimestampColumnName": {},
        "ParquetTimestampInMillisecond": {
          "type": "boolean"
        },
        "CdcInsertsAndUpdates": {
          "type": "boolean"
        },
        "DatePartitionEnabled": {
          "type": "boolean"
        },
        "DatePartitionSequence": {},
        "DatePartitionDelimiter": {},
        "UseCsvNoSupValue": {
          "type": "boolean"
        },
        "CsvNoSupValue": {},
        "PreserveTransactions": {
          "type": "boolean"
        },
        "CdcPath": {},
        "CannedAclForObjects": {},
        "AddColumnName": {
          "type": "boolean"
        },
        "CdcMaxBatchInterval": {
          "type": "integer"
        },
        "CdcMinFileSize": {
          "type": "integer"
        },
        "CsvNullValue": {},
        "IgnoreHeaderRows": {
          "type": "integer"
        },
        "MaxFileSize": {
          "type": "integer"
        },
        "Rfc4180": {
          "type": "boolean"
        }
      }
    },
    "Sx": {
      "type": "structure",
      "members": {
        "ServiceAccessRoleArn": {},
        "BucketName": {}
      }
    },
    "Sy": {
      "type": "structure",
      "members": {
        "Username": {},
        "Password": {
          "shape": "Sj"
        },
        "ServerName": {},
        "Port": {
          "type": "integer"
        },
        "DatabaseName": {},
        "AuthType": {},
        "AuthMechanism": {},
        "NestingLevel": {},
        "ExtractDocId": {},
        "DocsToInvestigate": {},
        "AuthSource": {},
        "KmsKeyId": {},
        "SecretsManagerAccessRoleArn": {},
        "SecretsManagerSecretId": {}
      }
    },
    "S12": {
      "type": "structure",
      "members": {
        "StreamArn": {},
        "MessageFormat": {},
        "ServiceAccessRoleArn": {},
        "IncludeTransactionDetails": {
          "type": "boolean"
        },
        "IncludePartitionValue": {
          "type": "boolean"
        },
        "PartitionIncludeSchemaTable": {
          "type": "boolean"
        },
        "IncludeTableAlterOperations": {
          "type": "boolean"
        },
        "IncludeControlDetails": {
          "type": "boolean"
        },
        "IncludeNullAndEmpty": {
          "type": "boolean"
        },
        "NoHexPrefix": {
          "type": "boolean"
        }
      }
    },
    "S14": {
      "type": "structure",
      "members": {
        "Broker": {},
        "Topic": {},
        "MessageFormat": {},
        "IncludeTransactionDetails": {
          "type": "boolean"
        },
        "IncludePartitionValue": {
          "type": "boolean"
        },
        "PartitionIncludeSchemaTable": {
          "type": "boolean"
        },
        "IncludeTableAlterOperations": {
          "type": "boolean"
        },
        "IncludeControlDetails": {
          "type": "boolean"
        },
        "MessageMaxBytes": {
          "type": "integer"
        },
        "IncludeNullAndEmpty": {
          "type": "boolean"
        },
        "SecurityProtocol": {},
        "SslClientCertificateArn": {},
        "SslClientKeyArn": {},
        "SslClientKeyPassword": {
          "shape": "Sj"
        },
        "SslCaCertificateArn": {},
        "SaslUsername": {},
        "SaslPassword": {
          "shape": "Sj"
        },
        "NoHexPrefix": {
          "type": "boolean"
        }
      }
    },
    "S16": {
      "type": "structure",
      "required": [
        "ServiceAccessRoleArn",
        "EndpointUri"
      ],
      "members": {
        "ServiceAccessRoleArn": {},
        "EndpointUri": {},
        "FullLoadErrorPercentage": {
          "type": "integer"
        },
        "ErrorRetryDuration": {
          "type": "integer"
        }
      }
    },
    "S17": {
      "type": "structure",
      "required": [
        "S3BucketName",
        "S3BucketFolder"
      ],
      "members": {
        "ServiceAccessRoleArn": {},
        "S3BucketName": {},
        "S3BucketFolder": {},
        "ErrorRetryDuration": {
          "type": "integer"
        },
        "MaxFileSize": {
          "type": "integer"
        },
        "MaxRetryCount": {
          "type": "integer"
        },
        "IamAuthEnabled": {
          "type": "boolean"
        }
      }
    },
    "S18": {
      "type": "structure",
      "members": {
        "AcceptAnyDate": {
          "type": "boolean"
        },
        "AfterConnectScript": {},
        "BucketFolder": {},
        "BucketName": {},
        "CaseSensitiveNames": {
          "type": "boolean"
        },
        "CompUpdate": {
          "type": "boolean"
        },
        "ConnectionTimeout": {
          "type": "integer"
        },
        "DatabaseName": {},
        "DateFormat": {},
        "EmptyAsNull": {
          "type": "boolean"
        },
        "EncryptionMode": {},
        "ExplicitIds": {
          "type": "boolean"
        },
        "FileTransferUploadStreams": {
          "type": "integer"
        },
        "LoadTimeout": {
          "type": "integer"
        },
        "MaxFileSize": {
          "type": "integer"
        },
        "Password": {
          "shape": "Sj"
        },
        "Port": {
          "type": "integer"
        },
        "RemoveQuotes": {
          "type": "boolean"
        },
        "ReplaceInvalidChars": {},
        "ReplaceChars": {},
        "ServerName": {},
        "ServiceAccessRoleArn": {},
        "ServerSideEncryptionKmsKeyId": {},
        "TimeFormat": {},
        "TrimBlanks": {
          "type": "boolean"
        },
        "TruncateColumns": {
          "type": "boolean"
        },
        "Username": {},
        "WriteBufferSize": {
          "type": "integer"
        },
        "SecretsManagerAccessRoleArn": {},
        "SecretsManagerSecretId": {}
      }
    },
    "S19": {
      "type": "structure",
      "members": {
        "AfterConnectScript": {},
        "CaptureDdls": {
          "type": "boolean"
        },
        "MaxFileSize": {
          "type": "integer"
        },
        "DatabaseName": {},
        "DdlArtifactsSchema": {},
        "ExecuteTimeout": {
          "type": "integer"
        },
        "FailTasksOnLobTruncation": {
          "type": "boolean"
        },
        "HeartbeatEnable": {
          "type": "boolean"
        },
        "HeartbeatSchema": {},
        "HeartbeatFrequency": {
          "type": "integer"
        },
        "Password": {
          "shape": "Sj"
        },
        "Port": {
          "type": "integer"
        },
        "ServerName": {},
        "Username": {},
        "SlotName": {},
        "PluginName": {},
        "SecretsManagerAccessRoleArn": {},
        "SecretsManagerSecretId": {}
      }
    },
    "S1b": {
      "type": "structure",
      "members": {
        "AfterConnectScript": {},
        "CleanSourceMetadataOnMismatch": {
          "type": "boolean"
        },
        "DatabaseName": {},
        "EventsPollInterval": {
          "type": "integer"
        },
        "TargetDbType": {},
        "MaxFileSize": {
          "type": "integer"
        },
        "ParallelLoadThreads": {
          "type": "integer"
        },
        "Password": {
          "shape": "Sj"
        },
        "Port": {
          "type": "integer"
        },
        "ServerName": {},
        "ServerTimezone": {},
        "Username": {},
        "SecretsManagerAccessRoleArn": {},
        "SecretsManagerSecretId": {}
      }
    },
    "S1d": {
      "type": "structure",
      "members": {
        "AddSupplementalLogging": {
          "type": "boolean"
        },
        "ArchivedLogDestId": {
          "type": "integer"
        },
        "AdditionalArchivedLogDestId": {
          "type": "integer"
        },
        "ExtraArchivedLogDestIds": {
          "type": "list",
          "member": {
            "type": "integer"
          }
        },
        "AllowSelectNestedTables": {
          "type": "boolean"
        },
        "ParallelAsmReadThreads": {
          "type": "integer"
        },
        "ReadAheadBlocks": {
          "type": "integer"
        },
        "AccessAlternateDirectly": {
          "type": "boolean"
        },
        "UseAlternateFolderForOnline": {
          "type": "boolean"
        },
        "OraclePathPrefix": {},
        "UsePathPrefix": {},
        "ReplacePathPrefix": {
          "type": "boolean"
        },
        "EnableHomogenousTablespace": {
          "type": "boolean"
        },
        "DirectPathNoLog": {
          "type": "boolean"
        },
        "ArchivedLogsOnly": {
          "type": "boolean"
        },
        "AsmPassword": {
          "shape": "Sj"
        },
        "AsmServer": {},
        "AsmUser": {},
        "CharLengthSemantics": {},
        "DatabaseName": {},
        "DirectPathParallelLoad": {
          "type": "boolean"
        },
        "FailTasksOnLobTruncation": {
          "type": "boolean"
        },
        "NumberDatatypeScale": {
          "type": "integer"
        },
        "Password": {
          "shape": "Sj"
        },
        "Port": {
          "type": "integer"
        },
        "ReadTableSpaceName": {
          "type": "boolean"
        },
        "RetryInterval": {
          "type": "integer"
        },
        "SecurityDbEncryption": {
          "shape": "Sj"
        },
        "SecurityDbEncryptionName": {},
        "ServerName": {},
        "SpatialDataOptionToGeoJsonFunctionName": {},
        "StandbyDelayTime": {
          "type": "integer"
        },
        "Username": {},
        "UseBFile": {
          "type": "boolean"
        },
        "UseDirectPathFullLoad": {
          "type": "boolean"
        },
        "UseLogminerReader": {
          "type": "boolean"
        },
        "SecretsManagerAccessRoleArn": {},
        "SecretsManagerSecretId": {},
        "SecretsManagerOracleAsmAccessRoleArn": {},
        "SecretsManagerOracleAsmSecretId": {}
      }
    },
    "S1g": {
      "type": "structure",
      "members": {
        "DatabaseName": {},
        "Password": {
          "shape": "Sj"
        },
        "Port": {
          "type": "integer"
        },
        "ServerName": {},
        "Username": {},
        "SecretsManagerAccessRoleArn": {},
        "SecretsManagerSecretId": {}
      }
    },
    "S1h": {
      "type": "structure",
      "members": {
        "Port": {
          "type": "integer"
        },
        "BcpPacketSize": {
          "type": "integer"
        },
        "DatabaseName": {},
        "ControlTablesFileGroup": {},
        "Password": {
          "shape": "Sj"
        },
        "QuerySingleAlwaysOnNode": {
          "type": "boolean"
        },
        "ReadBackupOnly": {
          "type": "boolean"
        },
        "SafeguardPolicy": {},
        "ServerName": {},
        "Username": {},
        "UseBcpFullLoad": {
          "type": "boolean"
        },
        "UseThirdPartyBackupDevice": {
          "type": "boolean"
        },
        "SecretsManagerAccessRoleArn": {},
        "SecretsManagerSecretId": {}
      }
    },
    "S1j": {
      "type": "structure",
      "members": {
        "DatabaseName": {},
        "Password": {
          "shape": "Sj"
        },
        "Port": {
          "type": "integer"
        },
        "ServerName": {},
        "SetDataCaptureChanges": {
          "type": "boolean"
        },
        "CurrentLsn": {},
        "MaxKBytesPerRead": {
          "type": "integer"
        },
        "Username": {},
        "SecretsManagerAccessRoleArn": {},
        "SecretsManagerSecretId": {}
      }
    },
    "S1k": {
      "type": "structure",
      "members": {
        "Username": {},
        "Password": {
          "shape": "Sj"
        },
        "ServerName": {},
        "Port": {
          "type": "integer"
        },
        "DatabaseName": {},
        "NestingLevel": {},
        "ExtractDocId": {
          "type": "boolean"
        },
        "DocsToInvestigate": {
          "type": "integer"
        },
        "KmsKeyId": {},
        "SecretsManagerAccessRoleArn": {},
        "SecretsManagerSecretId": {}
      }
    },
    "S1l": {
      "type": "structure",
      "required": [
        "ServerName",
        "Port"
      ],
      "members": {
        "ServerName": {},
        "Port": {
          "type": "integer"
        },
        "SslSecurityProtocol": {},
        "AuthType": {},
        "AuthUserName": {},
        "AuthPassword": {
          "shape": "Sj"
        },
        "SslCaCertificateArn": {}
      }
    },
    "S1p": {
      "type": "structure",
      "members": {
        "EndpointIdentifier": {},
        "EndpointType": {},
        "EngineName": {},
        "EngineDisplayName": {},
        "Username": {},
        "ServerName": {},
        "Port": {
          "type": "integer"
        },
        "DatabaseName": {},
        "ExtraConnectionAttributes": {},
        "Status": {},
        "KmsKeyId": {},
        "EndpointArn": {},
        "CertificateArn": {},
        "SslMode": {},
        "ServiceAccessRoleArn": {},
        "ExternalTableDefinition": {},
        "ExternalId": {},
        "DynamoDbSettings": {
          "shape": "Sm"
        },
        "S3Settings": {
          "shape": "Sn"
        },
        "DmsTransferSettings": {
          "shape": "Sx"
        },
        "MongoDbSettings": {
          "shape": "Sy"
        },
        "KinesisSettings": {
          "shape": "S12"
        },
        "KafkaSettings": {
          "shape": "S14"
        },
        "ElasticsearchSettings": {
          "shape": "S16"
        },
        "NeptuneSettings": {
          "shape": "S17"
        },
        "RedshiftSettings": {
          "shape": "S18"
        },
        "PostgreSQLSettings": {
          "shape": "S19"
        },
        "MySQLSettings": {
          "shape": "S1b"
        },
        "OracleSettings": {
          "shape": "S1d"
        },
        "SybaseSettings": {
          "shape": "S1g"
        },
        "MicrosoftSQLServerSettings": {
          "shape": "S1h"
        },
        "IBMDb2Settings": {
          "shape": "S1j"
        },
        "DocDbSettings": {
          "shape": "S1k"
        },
        "RedisSettings": {
          "shape": "S1l"
        }
      }
    },
    "S1r": {
      "type": "list",
      "member": {}
    },
    "S1s": {
      "type": "list",
      "member": {}
    },
    "S1u": {
      "type": "structure",
      "members": {
        "CustomerAwsId": {},
        "CustSubscriptionId": {},
        "SnsTopicArn": {},
        "Status": {},
        "SubscriptionCreationTime": {},
        "SourceType": {},
        "SourceIdsList": {
          "shape": "S1s"
        },
        "EventCategoriesList": {
          "shape": "S1r"
        },
        "Enabled": {
          "type": "boolean"
        }
      }
    },
    "S1x": {
      "type": "list",
      "member": {}
    },
    "S1z": {
      "type": "structure",
      "members": {
        "ReplicationInstanceIdentifier": {},
        "ReplicationInstanceClass": {},
        "ReplicationInstanceStatus": {},
        "AllocatedStorage": {
          "type": "integer"
        },
        "InstanceCreateTime": {
          "type": "timestamp"
        },
        "VpcSecurityGroups": {
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "VpcSecurityGroupId": {},
              "Status": {}
            }
          }
        },
        "AvailabilityZone": {},
        "ReplicationSubnetGroup": {
          "shape": "S22"
        },
        "PreferredMaintenanceWindow": {},
        "PendingModifiedValues": {
          "type": "structure",
          "members": {
            "ReplicationInstanceClass": {},
            "AllocatedStorage": {
              "type": "integer"
            },
            "MultiAZ": {
              "type": "boolean"
            },
            "EngineVersion": {}
          }
        },
        "MultiAZ": {
          "type": "boolean"
        },
        "EngineVersion": {},
        "AutoMinorVersionUpgrade": {
          "type": "boolean"
        },
        "KmsKeyId": {},
        "ReplicationInstanceArn": {},
        "ReplicationInstancePublicIpAddress": {
          "deprecated": true
        },
        "ReplicationInstancePrivateIpAddress": {
          "deprecated": true
        },
        "ReplicationInstancePublicIpAddresses": {
          "type": "list",
          "member": {}
        },
        "ReplicationInstancePrivateIpAddresses": {
          "type": "list",
          "member": {}
        },
        "PubliclyAccessible": {
          "type": "boolean"
        },
        "SecondaryAvailabilityZone": {},
        "FreeUntil": {
          "type": "timestamp"
        },
        "DnsNameServers": {}
      }
    },
    "S22": {
      "type": "structure",
      "members": {
        "ReplicationSubnetGroupIdentifier": {},
        "ReplicationSubnetGroupDescription": {},
        "VpcId": {},
        "SubnetGroupStatus": {},
        "Subnets": {
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "SubnetIdentifier": {},
              "SubnetAvailabilityZone": {
                "type": "structure",
                "members": {
                  "Name": {}
                }
              },
              "SubnetStatus": {}
            }
          }
        }
      }
    },
    "S2a": {
      "type": "list",
      "member": {}
    },
    "S2f": {
      "type": "structure",
      "members": {
        "ReplicationTaskIdentifier": {},
        "SourceEndpointArn": {},
        "TargetEndpointArn": {},
        "ReplicationInstanceArn": {},
        "MigrationType": {},
        "TableMappings": {},
        "ReplicationTaskSettings": {},
        "Status": {},
        "LastFailureMessage": {},
        "StopReason": {},
        "ReplicationTaskCreationDate": {
          "type": "timestamp"
        },
        "ReplicationTaskStartDate": {
          "type": "timestamp"
        },
        "CdcStartPosition": {},
        "CdcStopPosition": {},
        "RecoveryCheckpoint": {},
        "ReplicationTaskArn": {},
        "ReplicationTaskStats": {
          "type": "structure",
          "members": {
            "FullLoadProgressPercent": {
              "type": "integer"
            },
            "ElapsedTimeMillis": {
              "type": "long"
            },
            "TablesLoaded": {
              "type": "integer"
            },
            "TablesLoading": {
              "type": "integer"
            },
            "TablesQueued": {
              "type": "integer"
            },
            "TablesErrored": {
              "type": "integer"
            },
            "FreshStartDate": {
              "type": "timestamp"
            },
            "StartDate": {
              "type": "timestamp"
            },
            "StopDate": {
              "type": "timestamp"
            },
            "FullLoadStartDate": {
              "type": "timestamp"
            },
            "FullLoadFinishDate": {
              "type": "timestamp"
            }
          }
        },
        "TaskData": {},
        "TargetReplicationInstanceArn": {}
      }
    },
    "S2k": {
      "type": "structure",
      "members": {
        "CertificateIdentifier": {},
        "CertificateCreationDate": {
          "type": "timestamp"
        },
        "CertificatePem": {},
        "CertificateWallet": {
          "type": "blob"
        },
        "CertificateArn": {},
        "CertificateOwner": {},
        "ValidFromDate": {
          "type": "timestamp"
        },
        "ValidToDate": {
          "type": "timestamp"
        },
        "SigningAlgorithm": {},
        "KeyLength": {
          "type": "integer"
        }
      }
    },
    "S2o": {
      "type": "structure",
      "members": {
        "ReplicationInstanceArn": {},
        "EndpointArn": {},
        "Status": {},
        "LastFailureMessage": {},
        "EndpointIdentifier": {},
        "ReplicationInstanceIdentifier": {}
      }
    },
    "S39": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "Name",
          "Values"
        ],
        "members": {
          "Name": {},
          "Values": {
            "type": "list",
            "member": {}
          }
        }
      }
    },
    "S4h": {
      "type": "structure",
      "members": {
        "EndpointArn": {},
        "ReplicationInstanceArn": {},
        "Status": {},
        "LastRefreshDate": {
          "type": "timestamp"
        },
        "LastFailureMessage": {}
      }
    }
  }
}