import { useState } from 'react'
import { observer } from 'mobx-react-lite'
import { FormGroup, Button, Spinner } from '@blueprintjs/core'
import {
  useTrainingThumbnail,
  useUpdateTrainingThumbnail,
} from '~/src/hooks/training'
import { refreshTrainingIndex } from '~/src/features/backend/manager'
import rootStore from '~/src/app/store'
import { flow } from 'mobx'
import CustomIcon from '~/src/app/components/Icon'

const Thumbnail = () => {
  const [isLoading, setLoading] = useState(false)
  const thumbnail = useTrainingThumbnail()
  const updateTrainingThumbnail = useUpdateTrainingThumbnail()
  return (
    <div className="thumbnail-preview">
      {thumbnail ? (
        <div className="thumbnail">
          {/* thumbnails change, but URL stays the same. This overrides the cache */}
          <img src={thumbnail + `#${new Date().getTime()}`} alt="thumbnail" />
          {isLoading && <Spinner intent="primary" size={40} />}
        </div>
      ) : null}
      <FormGroup>
        <Button
          fill
          outlined
          icon={<CustomIcon icon="camera" />}
          disabled={isLoading}
          onClick={flow(function* () {
            setLoading(true)
            yield updateTrainingThumbnail()
            yield rootStore.persistence.saveTraining()
            yield refreshTrainingIndex()
            setLoading(false)
          })}
        >
          {isLoading ? 'Capturing...' : 'New Thumbnail'}
        </Button>
      </FormGroup>
    </div>
  )
}

export default observer(Thumbnail)
