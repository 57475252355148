import { assign } from 'lodash'

class ScreenshotStore {
  constructor({ sceneManager, persistence, ui, training }) {
    assign(this, { sceneManager, persistence, ui, training })
    window.shots = this
  }

  async generateThumbnail(filename = 'thumbnail.png', unique = false) {
    const thumbSize = { width: 280, height: 210 }
    const dataUrl = await this.sceneManager.takeScreenshot(thumbSize)
    const buffer = new Buffer.from(
      dataUrl.replace(/^data:image\/\w+;base64,/, ''),
      'base64',
    )
    const url = await this.persistence.saveBlob(buffer, filename, unique)
    // we want to mantain the same url, but notify observers of update
    return url + `#${new Date().getTime()}`
  }
}

export default ScreenshotStore
