import { Vector3, Color3 } from '@babylonjs/core/Maths/math'
import rootStore from '~/src/app/store'
import { indexOf, reduce, forEach, filter, remove, pull } from 'lodash'

export const RED = new Color3(225 / 255, 36 / 255, 36 / 255)
export const GREEN = new Color3(31 / 255, 229 / 255, 35 / 255)
export const BLUE = new Color3(0, 95 / 255, 255 / 255)

export function isRootNode(node) {
  return node.id === '__root__' || !node.parent
}

export function getRootAncestor(node) {
  if (isRootNode(node)) return node
  else return getRootAncestor(node.parent)
}

export function getNodeAncestors(node) {
  const ancestors = []
  let ancestor = node
  while (ancestor !== null) {
    ancestors.push(ancestor)
    ancestor = ancestor.parent
  }
  // the first ancestor is the AssetContainer root mesh
  ancestors.pop()
  return ancestors
}

export function getObjectIdFromNode(node) {
  const { modelRepository } = rootStore
  const rootNode = getRootAncestor(node)
  return modelRepository.findIdForNode(rootNode)
}

export function getNodeIdx(node) {
  // NOTE: I'm *ASSUMING* that the order of getDescendants() is *CONSISTENT*
  const rootNode = getRootAncestor(node)
  const descendants = rootNode._descendants || rootNode.getDescendants()
  return indexOf(descendants, node)
}

export function findNode(rootNode, idx) {
  const descendants = rootNode.getDescendants()
  return descendants[idx]
}

export function isInstancedMesh(node) {
  return node.getClassName() === 'InstancedMesh'
}

export function getHighestInstancedAncestor(node) {
  return reduce(
    getNodeAncestors(node),
    (acc, ancestor) => {
      return isInstancedMesh(ancestor) ? ancestor : acc
    },
    node,
  )
}

export function getNodePath(node) {
  return isRootNode(node) ? [] : ['nodes', getNodeIdx(node)]
}

export function replaceInstancesWithClones(container, rootNode) {
  // TODO: this doesn't work, but I don't know why
  // const topLevelInstances = filter(
  //   instancedMeshes,
  //   m => (getHighestInstancedAncestor(m) === m)
  // )
  // console.log(instancedMeshes, topLevelInstances)
  // forEach(topLevelInstances, childMesh => {
  //   if (isInstancedMesh(childMesh)) {
  //     const id = childMesh.id
  //     const clone = childMesh.sourceMesh.clone(
  //       childMesh.name,
  //       childMesh.parent
  //     )
  //     clone.setParent(childMesh.parent)
  //     clone.position.copyFrom(childMesh.position)
  //     clone.rotation.copyFrom(childMesh.rotation)
  //     clone.scaling.copyFrom(childMesh.scaling)
  //     clone.name = childMesh.name
  //     clone.setEnabled(false)
  //     childMesh.setParent(null)
  //     childMesh.name = "<deleted>"
  //     console.log(container.meshes.length)
  //     pull(container.meshes, childMesh)
  //     console.log(container.meshes.length)
  //     // clone.id = id
  //   }
  // })
  // remove(rootNode.meshes, isInstancedMesh)
}

const TARGET_CUBE_SIZE = 2

export function autoScaleModel(model) {
  let { min, max } = model.getHierarchyBoundingVectors()
  const diagonal = Vector3.Distance(min, max)
  const scale = (TARGET_CUBE_SIZE * 2) / diagonal
  console.log('auto-scale>', scale)
  model.scaling = new Vector3(scale, scale, scale)
  return scale
}

export function nuke(node) {
  if (!node) return
  node.setEnabled(false)
  node.parent = null
  node.getScene().removeMesh(node)
  node.dispose()
}
