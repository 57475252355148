{
  "version": "2.0",
  "metadata": {
    "apiVersion": "2019-05-23",
    "endpointPrefix": "groundstation",
    "jsonVersion": "1.1",
    "protocol": "rest-json",
    "serviceFullName": "AWS Ground Station",
    "serviceId": "GroundStation",
    "signatureVersion": "v4",
    "signingName": "groundstation",
    "uid": "groundstation-2019-05-23"
  },
  "operations": {
    "CancelContact": {
      "http": {
        "method": "DELETE",
        "requestUri": "/contact/{contactId}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "contactId"
        ],
        "members": {
          "contactId": {
            "location": "uri",
            "locationName": "contactId"
          }
        }
      },
      "output": {
        "shape": "S3"
      },
      "idempotent": true
    },
    "CreateConfig": {
      "http": {
        "requestUri": "/config",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "configData",
          "name"
        ],
        "members": {
          "configData": {
            "shape": "S5"
          },
          "name": {},
          "tags": {
            "shape": "Sx"
          }
        }
      },
      "output": {
        "shape": "Sy"
      }
    },
    "CreateDataflowEndpointGroup": {
      "http": {
        "requestUri": "/dataflowEndpointGroup",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "endpointDetails"
        ],
        "members": {
          "endpointDetails": {
            "shape": "S11"
          },
          "tags": {
            "shape": "Sx"
          }
        }
      },
      "output": {
        "shape": "S1b"
      }
    },
    "CreateMissionProfile": {
      "http": {
        "requestUri": "/missionprofile",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "dataflowEdges",
          "minimumViableContactDurationSeconds",
          "name",
          "trackingConfigArn"
        ],
        "members": {
          "contactPostPassDurationSeconds": {
            "type": "integer"
          },
          "contactPrePassDurationSeconds": {
            "type": "integer"
          },
          "dataflowEdges": {
            "shape": "S1e"
          },
          "minimumViableContactDurationSeconds": {
            "type": "integer"
          },
          "name": {},
          "tags": {
            "shape": "Sx"
          },
          "trackingConfigArn": {}
        }
      },
      "output": {
        "shape": "S1g"
      }
    },
    "DeleteConfig": {
      "http": {
        "method": "DELETE",
        "requestUri": "/config/{configType}/{configId}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "configId",
          "configType"
        ],
        "members": {
          "configId": {
            "location": "uri",
            "locationName": "configId"
          },
          "configType": {
            "location": "uri",
            "locationName": "configType"
          }
        }
      },
      "output": {
        "shape": "Sy"
      },
      "idempotent": true
    },
    "DeleteDataflowEndpointGroup": {
      "http": {
        "method": "DELETE",
        "requestUri": "/dataflowEndpointGroup/{dataflowEndpointGroupId}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "dataflowEndpointGroupId"
        ],
        "members": {
          "dataflowEndpointGroupId": {
            "location": "uri",
            "locationName": "dataflowEndpointGroupId"
          }
        }
      },
      "output": {
        "shape": "S1b"
      },
      "idempotent": true
    },
    "DeleteMissionProfile": {
      "http": {
        "method": "DELETE",
        "requestUri": "/missionprofile/{missionProfileId}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "missionProfileId"
        ],
        "members": {
          "missionProfileId": {
            "location": "uri",
            "locationName": "missionProfileId"
          }
        }
      },
      "output": {
        "shape": "S1g"
      },
      "idempotent": true
    },
    "DescribeContact": {
      "http": {
        "method": "GET",
        "requestUri": "/contact/{contactId}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "contactId"
        ],
        "members": {
          "contactId": {
            "location": "uri",
            "locationName": "contactId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "contactId": {},
          "contactStatus": {},
          "dataflowList": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "destination": {
                  "type": "structure",
                  "members": {
                    "configDetails": {
                      "shape": "S1q"
                    },
                    "configId": {},
                    "configType": {},
                    "dataflowDestinationRegion": {}
                  }
                },
                "errorMessage": {},
                "source": {
                  "type": "structure",
                  "members": {
                    "configDetails": {
                      "shape": "S1q"
                    },
                    "configId": {},
                    "configType": {},
                    "dataflowSourceRegion": {}
                  }
                }
              }
            }
          },
          "endTime": {
            "type": "timestamp"
          },
          "errorMessage": {},
          "groundStation": {},
          "maximumElevation": {
            "shape": "S1v"
          },
          "missionProfileArn": {},
          "postPassEndTime": {
            "type": "timestamp"
          },
          "prePassStartTime": {
            "type": "timestamp"
          },
          "region": {},
          "satelliteArn": {},
          "startTime": {
            "type": "timestamp"
          },
          "tags": {
            "shape": "Sx"
          }
        }
      }
    },
    "GetConfig": {
      "http": {
        "method": "GET",
        "requestUri": "/config/{configType}/{configId}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "configId",
          "configType"
        ],
        "members": {
          "configId": {
            "location": "uri",
            "locationName": "configId"
          },
          "configType": {
            "location": "uri",
            "locationName": "configType"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "configArn",
          "configData",
          "configId",
          "name"
        ],
        "members": {
          "configArn": {},
          "configData": {
            "shape": "S5"
          },
          "configId": {},
          "configType": {},
          "name": {},
          "tags": {
            "shape": "Sx"
          }
        }
      }
    },
    "GetDataflowEndpointGroup": {
      "http": {
        "method": "GET",
        "requestUri": "/dataflowEndpointGroup/{dataflowEndpointGroupId}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "dataflowEndpointGroupId"
        ],
        "members": {
          "dataflowEndpointGroupId": {
            "location": "uri",
            "locationName": "dataflowEndpointGroupId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "dataflowEndpointGroupArn": {},
          "dataflowEndpointGroupId": {},
          "endpointsDetails": {
            "shape": "S11"
          },
          "tags": {
            "shape": "Sx"
          }
        }
      }
    },
    "GetMinuteUsage": {
      "http": {
        "requestUri": "/minute-usage",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "month",
          "year"
        ],
        "members": {
          "month": {
            "type": "integer"
          },
          "year": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "estimatedMinutesRemaining": {
            "type": "integer"
          },
          "isReservedMinutesCustomer": {
            "type": "boolean"
          },
          "totalReservedMinuteAllocation": {
            "type": "integer"
          },
          "totalScheduledMinutes": {
            "type": "integer"
          },
          "upcomingMinutesScheduled": {
            "type": "integer"
          }
        }
      }
    },
    "GetMissionProfile": {
      "http": {
        "method": "GET",
        "requestUri": "/missionprofile/{missionProfileId}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "missionProfileId"
        ],
        "members": {
          "missionProfileId": {
            "location": "uri",
            "locationName": "missionProfileId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "contactPostPassDurationSeconds": {
            "type": "integer"
          },
          "contactPrePassDurationSeconds": {
            "type": "integer"
          },
          "dataflowEdges": {
            "shape": "S1e"
          },
          "minimumViableContactDurationSeconds": {
            "type": "integer"
          },
          "missionProfileArn": {},
          "missionProfileId": {},
          "name": {},
          "region": {},
          "tags": {
            "shape": "Sx"
          },
          "trackingConfigArn": {}
        }
      }
    },
    "GetSatellite": {
      "http": {
        "method": "GET",
        "requestUri": "/satellite/{satelliteId}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "satelliteId"
        ],
        "members": {
          "satelliteId": {
            "location": "uri",
            "locationName": "satelliteId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "groundStations": {
            "shape": "S2a"
          },
          "noradSatelliteID": {
            "type": "integer"
          },
          "satelliteArn": {},
          "satelliteId": {}
        }
      }
    },
    "ListConfigs": {
      "http": {
        "method": "GET",
        "requestUri": "/config",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "configList": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "configArn": {},
                "configId": {},
                "configType": {},
                "name": {}
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListContacts": {
      "http": {
        "requestUri": "/contacts",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "endTime",
          "startTime",
          "statusList"
        ],
        "members": {
          "endTime": {
            "type": "timestamp"
          },
          "groundStation": {},
          "maxResults": {
            "type": "integer"
          },
          "missionProfileArn": {},
          "nextToken": {},
          "satelliteArn": {},
          "startTime": {
            "type": "timestamp"
          },
          "statusList": {
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "contactList": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "contactId": {},
                "contactStatus": {},
                "endTime": {
                  "type": "timestamp"
                },
                "errorMessage": {},
                "groundStation": {},
                "maximumElevation": {
                  "shape": "S1v"
                },
                "missionProfileArn": {},
                "postPassEndTime": {
                  "type": "timestamp"
                },
                "prePassStartTime": {
                  "type": "timestamp"
                },
                "region": {},
                "satelliteArn": {},
                "startTime": {
                  "type": "timestamp"
                },
                "tags": {
                  "shape": "Sx"
                }
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListDataflowEndpointGroups": {
      "http": {
        "method": "GET",
        "requestUri": "/dataflowEndpointGroup",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "dataflowEndpointGroupList": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "dataflowEndpointGroupArn": {},
                "dataflowEndpointGroupId": {}
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListGroundStations": {
      "http": {
        "method": "GET",
        "requestUri": "/groundstation",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "satelliteId": {
            "location": "querystring",
            "locationName": "satelliteId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "groundStationList": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "groundStationId": {},
                "groundStationName": {},
                "region": {}
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListMissionProfiles": {
      "http": {
        "method": "GET",
        "requestUri": "/missionprofile",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "missionProfileList": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "missionProfileArn": {},
                "missionProfileId": {},
                "name": {},
                "region": {}
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListSatellites": {
      "http": {
        "method": "GET",
        "requestUri": "/satellite",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "nextToken": {},
          "satellites": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "groundStations": {
                  "shape": "S2a"
                },
                "noradSatelliteID": {
                  "type": "integer"
                },
                "satelliteArn": {},
                "satelliteId": {}
              }
            }
          }
        }
      }
    },
    "ListTagsForResource": {
      "http": {
        "method": "GET",
        "requestUri": "/tags/{resourceArn}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "resourceArn"
        ],
        "members": {
          "resourceArn": {
            "location": "uri",
            "locationName": "resourceArn"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "tags": {
            "shape": "Sx"
          }
        }
      }
    },
    "ReserveContact": {
      "http": {
        "requestUri": "/contact",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "endTime",
          "groundStation",
          "missionProfileArn",
          "satelliteArn",
          "startTime"
        ],
        "members": {
          "endTime": {
            "type": "timestamp"
          },
          "groundStation": {},
          "missionProfileArn": {},
          "satelliteArn": {},
          "startTime": {
            "type": "timestamp"
          },
          "tags": {
            "shape": "Sx"
          }
        }
      },
      "output": {
        "shape": "S3"
      }
    },
    "TagResource": {
      "http": {
        "requestUri": "/tags/{resourceArn}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "resourceArn",
          "tags"
        ],
        "members": {
          "resourceArn": {
            "location": "uri",
            "locationName": "resourceArn"
          },
          "tags": {
            "shape": "Sx"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UntagResource": {
      "http": {
        "method": "DELETE",
        "requestUri": "/tags/{resourceArn}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "resourceArn",
          "tagKeys"
        ],
        "members": {
          "resourceArn": {
            "location": "uri",
            "locationName": "resourceArn"
          },
          "tagKeys": {
            "location": "querystring",
            "locationName": "tagKeys",
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    },
    "UpdateConfig": {
      "http": {
        "method": "PUT",
        "requestUri": "/config/{configType}/{configId}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "configData",
          "configId",
          "configType",
          "name"
        ],
        "members": {
          "configData": {
            "shape": "S5"
          },
          "configId": {
            "location": "uri",
            "locationName": "configId"
          },
          "configType": {
            "location": "uri",
            "locationName": "configType"
          },
          "name": {}
        }
      },
      "output": {
        "shape": "Sy"
      },
      "idempotent": true
    },
    "UpdateMissionProfile": {
      "http": {
        "method": "PUT",
        "requestUri": "/missionprofile/{missionProfileId}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "missionProfileId"
        ],
        "members": {
          "contactPostPassDurationSeconds": {
            "type": "integer"
          },
          "contactPrePassDurationSeconds": {
            "type": "integer"
          },
          "dataflowEdges": {
            "shape": "S1e"
          },
          "minimumViableContactDurationSeconds": {
            "type": "integer"
          },
          "missionProfileId": {
            "location": "uri",
            "locationName": "missionProfileId"
          },
          "name": {},
          "trackingConfigArn": {}
        }
      },
      "output": {
        "shape": "S1g"
      },
      "idempotent": true
    }
  },
  "shapes": {
    "S3": {
      "type": "structure",
      "members": {
        "contactId": {}
      }
    },
    "S5": {
      "type": "structure",
      "members": {
        "antennaDownlinkConfig": {
          "type": "structure",
          "required": [
            "spectrumConfig"
          ],
          "members": {
            "spectrumConfig": {
              "shape": "S7"
            }
          }
        },
        "antennaDownlinkDemodDecodeConfig": {
          "type": "structure",
          "required": [
            "decodeConfig",
            "demodulationConfig",
            "spectrumConfig"
          ],
          "members": {
            "decodeConfig": {
              "type": "structure",
              "required": [
                "unvalidatedJSON"
              ],
              "members": {
                "unvalidatedJSON": {}
              }
            },
            "demodulationConfig": {
              "type": "structure",
              "required": [
                "unvalidatedJSON"
              ],
              "members": {
                "unvalidatedJSON": {}
              }
            },
            "spectrumConfig": {
              "shape": "S7"
            }
          }
        },
        "antennaUplinkConfig": {
          "type": "structure",
          "required": [
            "spectrumConfig",
            "targetEirp"
          ],
          "members": {
            "spectrumConfig": {
              "type": "structure",
              "required": [
                "centerFrequency"
              ],
              "members": {
                "centerFrequency": {
                  "shape": "Sb"
                },
                "polarization": {}
              }
            },
            "targetEirp": {
              "type": "structure",
              "required": [
                "units",
                "value"
              ],
              "members": {
                "units": {},
                "value": {
                  "type": "double"
                }
              }
            },
            "transmitDisabled": {
              "type": "boolean"
            }
          }
        },
        "dataflowEndpointConfig": {
          "type": "structure",
          "required": [
            "dataflowEndpointName"
          ],
          "members": {
            "dataflowEndpointName": {},
            "dataflowEndpointRegion": {}
          }
        },
        "s3RecordingConfig": {
          "type": "structure",
          "required": [
            "bucketArn",
            "roleArn"
          ],
          "members": {
            "bucketArn": {},
            "prefix": {},
            "roleArn": {}
          }
        },
        "trackingConfig": {
          "type": "structure",
          "required": [
            "autotrack"
          ],
          "members": {
            "autotrack": {}
          }
        },
        "uplinkEchoConfig": {
          "type": "structure",
          "required": [
            "antennaUplinkConfigArn",
            "enabled"
          ],
          "members": {
            "antennaUplinkConfigArn": {},
            "enabled": {
              "type": "boolean"
            }
          }
        }
      },
      "union": true
    },
    "S7": {
      "type": "structure",
      "required": [
        "bandwidth",
        "centerFrequency"
      ],
      "members": {
        "bandwidth": {
          "type": "structure",
          "required": [
            "units",
            "value"
          ],
          "members": {
            "units": {},
            "value": {
              "type": "double"
            }
          }
        },
        "centerFrequency": {
          "shape": "Sb"
        },
        "polarization": {}
      }
    },
    "Sb": {
      "type": "structure",
      "required": [
        "units",
        "value"
      ],
      "members": {
        "units": {},
        "value": {
          "type": "double"
        }
      }
    },
    "Sx": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "Sy": {
      "type": "structure",
      "members": {
        "configArn": {},
        "configId": {},
        "configType": {}
      }
    },
    "S11": {
      "type": "list",
      "member": {
        "shape": "S12"
      }
    },
    "S12": {
      "type": "structure",
      "members": {
        "endpoint": {
          "type": "structure",
          "members": {
            "address": {
              "type": "structure",
              "required": [
                "name",
                "port"
              ],
              "members": {
                "name": {},
                "port": {
                  "type": "integer"
                }
              }
            },
            "mtu": {
              "type": "integer"
            },
            "name": {},
            "status": {}
          }
        },
        "securityDetails": {
          "type": "structure",
          "required": [
            "roleArn",
            "securityGroupIds",
            "subnetIds"
          ],
          "members": {
            "roleArn": {},
            "securityGroupIds": {
              "type": "list",
              "member": {}
            },
            "subnetIds": {
              "type": "list",
              "member": {}
            }
          }
        }
      }
    },
    "S1b": {
      "type": "structure",
      "members": {
        "dataflowEndpointGroupId": {}
      }
    },
    "S1e": {
      "type": "list",
      "member": {
        "type": "list",
        "member": {}
      }
    },
    "S1g": {
      "type": "structure",
      "members": {
        "missionProfileId": {}
      }
    },
    "S1q": {
      "type": "structure",
      "members": {
        "antennaDemodDecodeDetails": {
          "type": "structure",
          "members": {
            "outputNode": {}
          }
        },
        "endpointDetails": {
          "shape": "S12"
        },
        "s3RecordingDetails": {
          "type": "structure",
          "members": {
            "bucketArn": {},
            "keyTemplate": {}
          }
        }
      },
      "union": true
    },
    "S1v": {
      "type": "structure",
      "required": [
        "unit",
        "value"
      ],
      "members": {
        "unit": {},
        "value": {
          "type": "double"
        }
      }
    },
    "S2a": {
      "type": "list",
      "member": {}
    }
  }
}