{
  "version": "2.0",
  "metadata": {
    "apiVersion": "2020-06-15",
    "endpointPrefix": "identitystore",
    "jsonVersion": "1.1",
    "protocol": "json",
    "serviceAbbreviation": "IdentityStore",
    "serviceFullName": "AWS SSO Identity Store",
    "serviceId": "identitystore",
    "signatureVersion": "v4",
    "signingName": "identitystore",
    "targetPrefix": "AWSIdentityStore",
    "uid": "identitystore-2020-06-15"
  },
  "operations": {
    "DescribeGroup": {
      "input": {
        "type": "structure",
        "required": [
          "IdentityStoreId",
          "GroupId"
        ],
        "members": {
          "IdentityStoreId": {},
          "GroupId": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "GroupId",
          "DisplayName"
        ],
        "members": {
          "GroupId": {},
          "DisplayName": {}
        }
      }
    },
    "DescribeUser": {
      "input": {
        "type": "structure",
        "required": [
          "IdentityStoreId",
          "UserId"
        ],
        "members": {
          "IdentityStoreId": {},
          "UserId": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "UserName",
          "UserId"
        ],
        "members": {
          "UserName": {
            "shape": "S8"
          },
          "UserId": {}
        }
      }
    },
    "ListGroups": {
      "input": {
        "type": "structure",
        "required": [
          "IdentityStoreId"
        ],
        "members": {
          "IdentityStoreId": {},
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {},
          "Filters": {
            "shape": "Sc"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "Groups"
        ],
        "members": {
          "Groups": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "GroupId",
                "DisplayName"
              ],
              "members": {
                "GroupId": {},
                "DisplayName": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListUsers": {
      "input": {
        "type": "structure",
        "required": [
          "IdentityStoreId"
        ],
        "members": {
          "IdentityStoreId": {},
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {},
          "Filters": {
            "shape": "Sc"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "Users"
        ],
        "members": {
          "Users": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "UserName",
                "UserId"
              ],
              "members": {
                "UserName": {
                  "shape": "S8"
                },
                "UserId": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    }
  },
  "shapes": {
    "S8": {
      "type": "string",
      "sensitive": true
    },
    "Sc": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "AttributePath",
          "AttributeValue"
        ],
        "members": {
          "AttributePath": {},
          "AttributeValue": {
            "type": "string",
            "sensitive": true
          }
        }
      }
    }
  }
}