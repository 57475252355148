import { Button, InputGroup, Menu, MenuItem } from '@blueprintjs/core'
import { map } from 'lodash'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import CustomIcon from '~/src/app/components/Icon'
import { useStore } from '~/src/app/store'
import StockModelGalleryItems from './StockModelGalleryItems'
import UserModelGalleryItems from './UserModelGalleryItems'

const ModelCategoryItems = observer(({ menuItemProps, categories }) => (
  // item list as separate component to follow mobx perf. advice
  <>
    {categories.map(({ label, key }) => (
      <MenuItem key={key} {...menuItemProps(key)} text={label} />
    ))}
  </>
))

const ModelGalleryPanel = () => {
  const { ui, modelGallery } = useStore()
  const [searchQuery, setSearchQuery] = useState('')
  const categories = map(modelGallery.commonModels, (v, key) => ({
    key,
    label: v.name,
  }))
  const category = ui.get('model-gallery.category')
  const menuItemProps = cat => ({
    active: category === cat,
    onClick: () => ui.set('model-gallery.category', cat),
  })
  // select the 'own-models' category by default
  useEffect(() => {
    if (!category) ui.set('model-gallery.category', 'own-models')
  }, [])
  return (
    <div className="model-gallery-panel">
      <div className="model-gallery-left-sidebar">
        <Menu>
          <div className="search-box">
            <InputGroup
              outlined
              type="search"
              fill
              leftIcon={<CustomIcon icon="search" />}
              placeholder="Search objects"
              onChange={ev => setSearchQuery(ev.target.value)}
            />
          </div>
          <Button
            {...menuItemProps('own-models')}
            large
            fill
            minimal
            intent="primary"
            rightIcon={<CustomIcon icon="plus" />}
          >
            My 3D objects
          </Button>
          <MenuItem {...menuItemProps('all')} text="All objects" />
          <ModelCategoryItems
            categories={categories}
            menuItemProps={menuItemProps}
          />
        </Menu>
      </div>
      <div className="model-gallery-content">
        {category === 'own-models' ? (
          <UserModelGalleryItems nameFilter={searchQuery} />
        ) : (
          <StockModelGalleryItems
            category={category}
            nameFilter={searchQuery}
          />
        )}
      </div>
    </div>
  )
}

export default observer(ModelGalleryPanel)
