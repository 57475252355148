import { env } from '../env'
import { globalOptions } from '../options'

export async function getInfo() {
  let user = undefined
  if (globalOptions.credentialsProvider) {
    const auth = await import('../auth')
    user = await auth.currentAuthenticatedUserId()
  }
  const isBrowser = typeof window !== 'undefined'
  const url = isBrowser && window ? window.location.href : null
  const agent = isBrowser && navigator ? navigator.userAgent : null
  const environment = env ? env.environment : null
  const info = {
    isBrowser,
    user: user,
    url: url,
    agent: agent,
    env: environment,
  }
  return info
}
