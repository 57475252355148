{
  "version": "2.0",
  "metadata": {
    "apiVersion": "2017-11-01",
    "endpointPrefix": "eks",
    "jsonVersion": "1.1",
    "protocol": "rest-json",
    "serviceAbbreviation": "Amazon EKS",
    "serviceFullName": "Amazon Elastic Kubernetes Service",
    "serviceId": "EKS",
    "signatureVersion": "v4",
    "signingName": "eks",
    "uid": "eks-2017-11-01"
  },
  "operations": {
    "AssociateEncryptionConfig": {
      "http": {
        "requestUri": "/clusters/{name}/encryption-config/associate"
      },
      "input": {
        "type": "structure",
        "required": [
          "clusterName",
          "encryptionConfig"
        ],
        "members": {
          "clusterName": {
            "location": "uri",
            "locationName": "name"
          },
          "encryptionConfig": {
            "shape": "S3"
          },
          "clientRequestToken": {
            "idempotencyToken": true
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "update": {
            "shape": "S8"
          }
        }
      }
    },
    "AssociateIdentityProviderConfig": {
      "http": {
        "requestUri": "/clusters/{name}/identity-provider-configs/associate"
      },
      "input": {
        "type": "structure",
        "required": [
          "clusterName",
          "oidc"
        ],
        "members": {
          "clusterName": {
            "location": "uri",
            "locationName": "name"
          },
          "oidc": {
            "type": "structure",
            "required": [
              "identityProviderConfigName",
              "issuerUrl",
              "clientId"
            ],
            "members": {
              "identityProviderConfigName": {},
              "issuerUrl": {},
              "clientId": {},
              "usernameClaim": {},
              "usernamePrefix": {},
              "groupsClaim": {},
              "groupsPrefix": {},
              "requiredClaims": {
                "shape": "Sk"
              }
            }
          },
          "tags": {
            "shape": "Sn"
          },
          "clientRequestToken": {
            "idempotencyToken": true
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "update": {
            "shape": "S8"
          },
          "tags": {
            "shape": "Sn"
          }
        }
      }
    },
    "CreateAddon": {
      "http": {
        "requestUri": "/clusters/{name}/addons"
      },
      "input": {
        "type": "structure",
        "required": [
          "clusterName",
          "addonName"
        ],
        "members": {
          "clusterName": {
            "location": "uri",
            "locationName": "name"
          },
          "addonName": {},
          "addonVersion": {},
          "serviceAccountRoleArn": {},
          "resolveConflicts": {},
          "clientRequestToken": {
            "idempotencyToken": true
          },
          "tags": {
            "shape": "Sn"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "addon": {
            "shape": "Sw"
          }
        }
      }
    },
    "CreateCluster": {
      "http": {
        "requestUri": "/clusters"
      },
      "input": {
        "type": "structure",
        "required": [
          "name",
          "roleArn",
          "resourcesVpcConfig"
        ],
        "members": {
          "name": {},
          "version": {},
          "roleArn": {},
          "resourcesVpcConfig": {
            "shape": "S13"
          },
          "kubernetesNetworkConfig": {
            "type": "structure",
            "members": {
              "serviceIpv4Cidr": {}
            }
          },
          "logging": {
            "shape": "S16"
          },
          "clientRequestToken": {
            "idempotencyToken": true
          },
          "tags": {
            "shape": "Sn"
          },
          "encryptionConfig": {
            "shape": "S3"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "cluster": {
            "shape": "S1c"
          }
        }
      }
    },
    "CreateFargateProfile": {
      "http": {
        "requestUri": "/clusters/{name}/fargate-profiles"
      },
      "input": {
        "type": "structure",
        "required": [
          "fargateProfileName",
          "clusterName",
          "podExecutionRoleArn"
        ],
        "members": {
          "fargateProfileName": {},
          "clusterName": {
            "location": "uri",
            "locationName": "name"
          },
          "podExecutionRoleArn": {},
          "subnets": {
            "shape": "S5"
          },
          "selectors": {
            "shape": "S1m"
          },
          "clientRequestToken": {
            "idempotencyToken": true
          },
          "tags": {
            "shape": "Sn"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "fargateProfile": {
            "shape": "S1q"
          }
        }
      }
    },
    "CreateNodegroup": {
      "http": {
        "requestUri": "/clusters/{name}/node-groups"
      },
      "input": {
        "type": "structure",
        "required": [
          "clusterName",
          "nodegroupName",
          "subnets",
          "nodeRole"
        ],
        "members": {
          "clusterName": {
            "location": "uri",
            "locationName": "name"
          },
          "nodegroupName": {},
          "scalingConfig": {
            "shape": "S1t"
          },
          "diskSize": {
            "type": "integer"
          },
          "subnets": {
            "shape": "S5"
          },
          "instanceTypes": {
            "shape": "S5"
          },
          "amiType": {},
          "remoteAccess": {
            "shape": "S1y"
          },
          "nodeRole": {},
          "labels": {
            "shape": "S1z"
          },
          "taints": {
            "shape": "S22"
          },
          "tags": {
            "shape": "Sn"
          },
          "clientRequestToken": {
            "idempotencyToken": true
          },
          "launchTemplate": {
            "shape": "S27"
          },
          "updateConfig": {
            "shape": "S28"
          },
          "capacityType": {},
          "version": {},
          "releaseVersion": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "nodegroup": {
            "shape": "S2d"
          }
        }
      }
    },
    "DeleteAddon": {
      "http": {
        "method": "DELETE",
        "requestUri": "/clusters/{name}/addons/{addonName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "clusterName",
          "addonName"
        ],
        "members": {
          "clusterName": {
            "location": "uri",
            "locationName": "name"
          },
          "addonName": {
            "location": "uri",
            "locationName": "addonName"
          },
          "preserve": {
            "location": "querystring",
            "locationName": "preserve",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "addon": {
            "shape": "Sw"
          }
        }
      }
    },
    "DeleteCluster": {
      "http": {
        "method": "DELETE",
        "requestUri": "/clusters/{name}"
      },
      "input": {
        "type": "structure",
        "required": [
          "name"
        ],
        "members": {
          "name": {
            "location": "uri",
            "locationName": "name"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "cluster": {
            "shape": "S1c"
          }
        }
      }
    },
    "DeleteFargateProfile": {
      "http": {
        "method": "DELETE",
        "requestUri": "/clusters/{name}/fargate-profiles/{fargateProfileName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "clusterName",
          "fargateProfileName"
        ],
        "members": {
          "clusterName": {
            "location": "uri",
            "locationName": "name"
          },
          "fargateProfileName": {
            "location": "uri",
            "locationName": "fargateProfileName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "fargateProfile": {
            "shape": "S1q"
          }
        }
      }
    },
    "DeleteNodegroup": {
      "http": {
        "method": "DELETE",
        "requestUri": "/clusters/{name}/node-groups/{nodegroupName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "clusterName",
          "nodegroupName"
        ],
        "members": {
          "clusterName": {
            "location": "uri",
            "locationName": "name"
          },
          "nodegroupName": {
            "location": "uri",
            "locationName": "nodegroupName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "nodegroup": {
            "shape": "S2d"
          }
        }
      }
    },
    "DeregisterCluster": {
      "http": {
        "method": "DELETE",
        "requestUri": "/cluster-registrations/{name}"
      },
      "input": {
        "type": "structure",
        "required": [
          "name"
        ],
        "members": {
          "name": {
            "location": "uri",
            "locationName": "name"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "cluster": {
            "shape": "S1c"
          }
        }
      }
    },
    "DescribeAddon": {
      "http": {
        "method": "GET",
        "requestUri": "/clusters/{name}/addons/{addonName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "clusterName",
          "addonName"
        ],
        "members": {
          "clusterName": {
            "location": "uri",
            "locationName": "name"
          },
          "addonName": {
            "location": "uri",
            "locationName": "addonName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "addon": {
            "shape": "Sw"
          }
        }
      }
    },
    "DescribeAddonVersions": {
      "http": {
        "method": "GET",
        "requestUri": "/addons/supported-versions"
      },
      "input": {
        "type": "structure",
        "members": {
          "kubernetesVersion": {
            "location": "querystring",
            "locationName": "kubernetesVersion"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "addonName": {
            "location": "querystring",
            "locationName": "addonName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "addons": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "addonName": {},
                "type": {},
                "addonVersions": {
                  "type": "list",
                  "member": {
                    "type": "structure",
                    "members": {
                      "addonVersion": {},
                      "architecture": {
                        "shape": "S5"
                      },
                      "compatibilities": {
                        "type": "list",
                        "member": {
                          "type": "structure",
                          "members": {
                            "clusterVersion": {},
                            "platformVersions": {
                              "shape": "S5"
                            },
                            "defaultVersion": {
                              "type": "boolean"
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "DescribeCluster": {
      "http": {
        "method": "GET",
        "requestUri": "/clusters/{name}"
      },
      "input": {
        "type": "structure",
        "required": [
          "name"
        ],
        "members": {
          "name": {
            "location": "uri",
            "locationName": "name"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "cluster": {
            "shape": "S1c"
          }
        }
      }
    },
    "DescribeFargateProfile": {
      "http": {
        "method": "GET",
        "requestUri": "/clusters/{name}/fargate-profiles/{fargateProfileName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "clusterName",
          "fargateProfileName"
        ],
        "members": {
          "clusterName": {
            "location": "uri",
            "locationName": "name"
          },
          "fargateProfileName": {
            "location": "uri",
            "locationName": "fargateProfileName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "fargateProfile": {
            "shape": "S1q"
          }
        }
      }
    },
    "DescribeIdentityProviderConfig": {
      "http": {
        "requestUri": "/clusters/{name}/identity-provider-configs/describe"
      },
      "input": {
        "type": "structure",
        "required": [
          "clusterName",
          "identityProviderConfig"
        ],
        "members": {
          "clusterName": {
            "location": "uri",
            "locationName": "name"
          },
          "identityProviderConfig": {
            "shape": "S3c"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "identityProviderConfig": {
            "type": "structure",
            "members": {
              "oidc": {
                "type": "structure",
                "members": {
                  "identityProviderConfigName": {},
                  "identityProviderConfigArn": {},
                  "clusterName": {},
                  "issuerUrl": {},
                  "clientId": {},
                  "usernameClaim": {},
                  "usernamePrefix": {},
                  "groupsClaim": {},
                  "groupsPrefix": {},
                  "requiredClaims": {
                    "shape": "Sk"
                  },
                  "tags": {
                    "shape": "Sn"
                  },
                  "status": {}
                }
              }
            }
          }
        }
      }
    },
    "DescribeNodegroup": {
      "http": {
        "method": "GET",
        "requestUri": "/clusters/{name}/node-groups/{nodegroupName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "clusterName",
          "nodegroupName"
        ],
        "members": {
          "clusterName": {
            "location": "uri",
            "locationName": "name"
          },
          "nodegroupName": {
            "location": "uri",
            "locationName": "nodegroupName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "nodegroup": {
            "shape": "S2d"
          }
        }
      }
    },
    "DescribeUpdate": {
      "http": {
        "method": "GET",
        "requestUri": "/clusters/{name}/updates/{updateId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "name",
          "updateId"
        ],
        "members": {
          "name": {
            "location": "uri",
            "locationName": "name"
          },
          "updateId": {
            "location": "uri",
            "locationName": "updateId"
          },
          "nodegroupName": {
            "location": "querystring",
            "locationName": "nodegroupName"
          },
          "addonName": {
            "location": "querystring",
            "locationName": "addonName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "update": {
            "shape": "S8"
          }
        }
      }
    },
    "DisassociateIdentityProviderConfig": {
      "http": {
        "requestUri": "/clusters/{name}/identity-provider-configs/disassociate"
      },
      "input": {
        "type": "structure",
        "required": [
          "clusterName",
          "identityProviderConfig"
        ],
        "members": {
          "clusterName": {
            "location": "uri",
            "locationName": "name"
          },
          "identityProviderConfig": {
            "shape": "S3c"
          },
          "clientRequestToken": {
            "idempotencyToken": true
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "update": {
            "shape": "S8"
          }
        }
      }
    },
    "ListAddons": {
      "http": {
        "method": "GET",
        "requestUri": "/clusters/{name}/addons"
      },
      "input": {
        "type": "structure",
        "required": [
          "clusterName"
        ],
        "members": {
          "clusterName": {
            "location": "uri",
            "locationName": "name"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "addons": {
            "shape": "S5"
          },
          "nextToken": {}
        }
      }
    },
    "ListClusters": {
      "http": {
        "method": "GET",
        "requestUri": "/clusters"
      },
      "input": {
        "type": "structure",
        "members": {
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "include": {
            "location": "querystring",
            "locationName": "include",
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "clusters": {
            "shape": "S5"
          },
          "nextToken": {}
        }
      }
    },
    "ListFargateProfiles": {
      "http": {
        "method": "GET",
        "requestUri": "/clusters/{name}/fargate-profiles"
      },
      "input": {
        "type": "structure",
        "required": [
          "clusterName"
        ],
        "members": {
          "clusterName": {
            "location": "uri",
            "locationName": "name"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "fargateProfileNames": {
            "shape": "S5"
          },
          "nextToken": {}
        }
      }
    },
    "ListIdentityProviderConfigs": {
      "http": {
        "method": "GET",
        "requestUri": "/clusters/{name}/identity-provider-configs"
      },
      "input": {
        "type": "structure",
        "required": [
          "clusterName"
        ],
        "members": {
          "clusterName": {
            "location": "uri",
            "locationName": "name"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "identityProviderConfigs": {
            "type": "list",
            "member": {
              "shape": "S3c"
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListNodegroups": {
      "http": {
        "method": "GET",
        "requestUri": "/clusters/{name}/node-groups"
      },
      "input": {
        "type": "structure",
        "required": [
          "clusterName"
        ],
        "members": {
          "clusterName": {
            "location": "uri",
            "locationName": "name"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "nodegroups": {
            "shape": "S5"
          },
          "nextToken": {}
        }
      }
    },
    "ListTagsForResource": {
      "http": {
        "method": "GET",
        "requestUri": "/tags/{resourceArn}"
      },
      "input": {
        "type": "structure",
        "required": [
          "resourceArn"
        ],
        "members": {
          "resourceArn": {
            "location": "uri",
            "locationName": "resourceArn"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "tags": {
            "shape": "Sn"
          }
        }
      }
    },
    "ListUpdates": {
      "http": {
        "method": "GET",
        "requestUri": "/clusters/{name}/updates"
      },
      "input": {
        "type": "structure",
        "required": [
          "name"
        ],
        "members": {
          "name": {
            "location": "uri",
            "locationName": "name"
          },
          "nodegroupName": {
            "location": "querystring",
            "locationName": "nodegroupName"
          },
          "addonName": {
            "location": "querystring",
            "locationName": "addonName"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "updateIds": {
            "shape": "S5"
          },
          "nextToken": {}
        }
      }
    },
    "RegisterCluster": {
      "http": {
        "requestUri": "/cluster-registrations"
      },
      "input": {
        "type": "structure",
        "required": [
          "name",
          "connectorConfig"
        ],
        "members": {
          "name": {},
          "connectorConfig": {
            "type": "structure",
            "required": [
              "roleArn",
              "provider"
            ],
            "members": {
              "roleArn": {},
              "provider": {}
            }
          },
          "clientRequestToken": {
            "idempotencyToken": true
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "cluster": {
            "shape": "S1c"
          }
        }
      }
    },
    "TagResource": {
      "http": {
        "requestUri": "/tags/{resourceArn}"
      },
      "input": {
        "type": "structure",
        "required": [
          "resourceArn",
          "tags"
        ],
        "members": {
          "resourceArn": {
            "location": "uri",
            "locationName": "resourceArn"
          },
          "tags": {
            "shape": "Sn"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UntagResource": {
      "http": {
        "method": "DELETE",
        "requestUri": "/tags/{resourceArn}"
      },
      "input": {
        "type": "structure",
        "required": [
          "resourceArn",
          "tagKeys"
        ],
        "members": {
          "resourceArn": {
            "location": "uri",
            "locationName": "resourceArn"
          },
          "tagKeys": {
            "location": "querystring",
            "locationName": "tagKeys",
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UpdateAddon": {
      "http": {
        "requestUri": "/clusters/{name}/addons/{addonName}/update"
      },
      "input": {
        "type": "structure",
        "required": [
          "clusterName",
          "addonName"
        ],
        "members": {
          "clusterName": {
            "location": "uri",
            "locationName": "name"
          },
          "addonName": {
            "location": "uri",
            "locationName": "addonName"
          },
          "addonVersion": {},
          "serviceAccountRoleArn": {},
          "resolveConflicts": {},
          "clientRequestToken": {
            "idempotencyToken": true
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "update": {
            "shape": "S8"
          }
        }
      }
    },
    "UpdateClusterConfig": {
      "http": {
        "requestUri": "/clusters/{name}/update-config"
      },
      "input": {
        "type": "structure",
        "required": [
          "name"
        ],
        "members": {
          "name": {
            "location": "uri",
            "locationName": "name"
          },
          "resourcesVpcConfig": {
            "shape": "S13"
          },
          "logging": {
            "shape": "S16"
          },
          "clientRequestToken": {
            "idempotencyToken": true
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "update": {
            "shape": "S8"
          }
        }
      }
    },
    "UpdateClusterVersion": {
      "http": {
        "requestUri": "/clusters/{name}/updates"
      },
      "input": {
        "type": "structure",
        "required": [
          "name",
          "version"
        ],
        "members": {
          "name": {
            "location": "uri",
            "locationName": "name"
          },
          "version": {},
          "clientRequestToken": {
            "idempotencyToken": true
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "update": {
            "shape": "S8"
          }
        }
      }
    },
    "UpdateNodegroupConfig": {
      "http": {
        "requestUri": "/clusters/{name}/node-groups/{nodegroupName}/update-config"
      },
      "input": {
        "type": "structure",
        "required": [
          "clusterName",
          "nodegroupName"
        ],
        "members": {
          "clusterName": {
            "location": "uri",
            "locationName": "name"
          },
          "nodegroupName": {
            "location": "uri",
            "locationName": "nodegroupName"
          },
          "labels": {
            "type": "structure",
            "members": {
              "addOrUpdateLabels": {
                "shape": "S1z"
              },
              "removeLabels": {
                "type": "list",
                "member": {}
              }
            }
          },
          "taints": {
            "type": "structure",
            "members": {
              "addOrUpdateTaints": {
                "shape": "S22"
              },
              "removeTaints": {
                "shape": "S22"
              }
            }
          },
          "scalingConfig": {
            "shape": "S1t"
          },
          "updateConfig": {
            "shape": "S28"
          },
          "clientRequestToken": {
            "idempotencyToken": true
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "update": {
            "shape": "S8"
          }
        }
      }
    },
    "UpdateNodegroupVersion": {
      "http": {
        "requestUri": "/clusters/{name}/node-groups/{nodegroupName}/update-version"
      },
      "input": {
        "type": "structure",
        "required": [
          "clusterName",
          "nodegroupName"
        ],
        "members": {
          "clusterName": {
            "location": "uri",
            "locationName": "name"
          },
          "nodegroupName": {
            "location": "uri",
            "locationName": "nodegroupName"
          },
          "version": {},
          "releaseVersion": {},
          "launchTemplate": {
            "shape": "S27"
          },
          "force": {
            "type": "boolean"
          },
          "clientRequestToken": {
            "idempotencyToken": true
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "update": {
            "shape": "S8"
          }
        }
      }
    }
  },
  "shapes": {
    "S3": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "resources": {
            "shape": "S5"
          },
          "provider": {
            "type": "structure",
            "members": {
              "keyArn": {}
            }
          }
        }
      }
    },
    "S5": {
      "type": "list",
      "member": {}
    },
    "S8": {
      "type": "structure",
      "members": {
        "id": {},
        "status": {},
        "type": {},
        "params": {
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "type": {},
              "value": {}
            }
          }
        },
        "createdAt": {
          "type": "timestamp"
        },
        "errors": {
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "errorCode": {},
              "errorMessage": {},
              "resourceIds": {
                "shape": "S5"
              }
            }
          }
        }
      }
    },
    "Sk": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "Sn": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "Sw": {
      "type": "structure",
      "members": {
        "addonName": {},
        "clusterName": {},
        "status": {},
        "addonVersion": {},
        "health": {
          "type": "structure",
          "members": {
            "issues": {
              "type": "list",
              "member": {
                "type": "structure",
                "members": {
                  "code": {},
                  "message": {},
                  "resourceIds": {
                    "shape": "S5"
                  }
                }
              }
            }
          }
        },
        "addonArn": {},
        "createdAt": {
          "type": "timestamp"
        },
        "modifiedAt": {
          "type": "timestamp"
        },
        "serviceAccountRoleArn": {},
        "tags": {
          "shape": "Sn"
        }
      }
    },
    "S13": {
      "type": "structure",
      "members": {
        "subnetIds": {
          "shape": "S5"
        },
        "securityGroupIds": {
          "shape": "S5"
        },
        "endpointPublicAccess": {
          "type": "boolean"
        },
        "endpointPrivateAccess": {
          "type": "boolean"
        },
        "publicAccessCidrs": {
          "shape": "S5"
        }
      }
    },
    "S16": {
      "type": "structure",
      "members": {
        "clusterLogging": {
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "types": {
                "type": "list",
                "member": {}
              },
              "enabled": {
                "type": "boolean"
              }
            }
          }
        }
      }
    },
    "S1c": {
      "type": "structure",
      "members": {
        "name": {},
        "arn": {},
        "createdAt": {
          "type": "timestamp"
        },
        "version": {},
        "endpoint": {},
        "roleArn": {},
        "resourcesVpcConfig": {
          "type": "structure",
          "members": {
            "subnetIds": {
              "shape": "S5"
            },
            "securityGroupIds": {
              "shape": "S5"
            },
            "clusterSecurityGroupId": {},
            "vpcId": {},
            "endpointPublicAccess": {
              "type": "boolean"
            },
            "endpointPrivateAccess": {
              "type": "boolean"
            },
            "publicAccessCidrs": {
              "shape": "S5"
            }
          }
        },
        "kubernetesNetworkConfig": {
          "type": "structure",
          "members": {
            "serviceIpv4Cidr": {}
          }
        },
        "logging": {
          "shape": "S16"
        },
        "identity": {
          "type": "structure",
          "members": {
            "oidc": {
              "type": "structure",
              "members": {
                "issuer": {}
              }
            }
          }
        },
        "status": {},
        "certificateAuthority": {
          "type": "structure",
          "members": {
            "data": {}
          }
        },
        "clientRequestToken": {},
        "platformVersion": {},
        "tags": {
          "shape": "Sn"
        },
        "encryptionConfig": {
          "shape": "S3"
        },
        "connectorConfig": {
          "type": "structure",
          "members": {
            "activationId": {},
            "activationCode": {},
            "activationExpiry": {
              "type": "timestamp"
            },
            "provider": {},
            "roleArn": {}
          }
        }
      }
    },
    "S1m": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "namespace": {},
          "labels": {
            "type": "map",
            "key": {},
            "value": {}
          }
        }
      }
    },
    "S1q": {
      "type": "structure",
      "members": {
        "fargateProfileName": {},
        "fargateProfileArn": {},
        "clusterName": {},
        "createdAt": {
          "type": "timestamp"
        },
        "podExecutionRoleArn": {},
        "subnets": {
          "shape": "S5"
        },
        "selectors": {
          "shape": "S1m"
        },
        "status": {},
        "tags": {
          "shape": "Sn"
        }
      }
    },
    "S1t": {
      "type": "structure",
      "members": {
        "minSize": {
          "type": "integer"
        },
        "maxSize": {
          "type": "integer"
        },
        "desiredSize": {
          "type": "integer"
        }
      }
    },
    "S1y": {
      "type": "structure",
      "members": {
        "ec2SshKey": {},
        "sourceSecurityGroups": {
          "shape": "S5"
        }
      }
    },
    "S1z": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "S22": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "key": {},
          "value": {},
          "effect": {}
        }
      }
    },
    "S27": {
      "type": "structure",
      "members": {
        "name": {},
        "version": {},
        "id": {}
      }
    },
    "S28": {
      "type": "structure",
      "members": {
        "maxUnavailable": {
          "type": "integer"
        },
        "maxUnavailablePercentage": {
          "type": "integer"
        }
      }
    },
    "S2d": {
      "type": "structure",
      "members": {
        "nodegroupName": {},
        "nodegroupArn": {},
        "clusterName": {},
        "version": {},
        "releaseVersion": {},
        "createdAt": {
          "type": "timestamp"
        },
        "modifiedAt": {
          "type": "timestamp"
        },
        "status": {},
        "capacityType": {},
        "scalingConfig": {
          "shape": "S1t"
        },
        "instanceTypes": {
          "shape": "S5"
        },
        "subnets": {
          "shape": "S5"
        },
        "remoteAccess": {
          "shape": "S1y"
        },
        "amiType": {},
        "nodeRole": {},
        "labels": {
          "shape": "S1z"
        },
        "taints": {
          "shape": "S22"
        },
        "resources": {
          "type": "structure",
          "members": {
            "autoScalingGroups": {
              "type": "list",
              "member": {
                "type": "structure",
                "members": {
                  "name": {}
                }
              }
            },
            "remoteAccessSecurityGroup": {}
          }
        },
        "diskSize": {
          "type": "integer"
        },
        "health": {
          "type": "structure",
          "members": {
            "issues": {
              "type": "list",
              "member": {
                "type": "structure",
                "members": {
                  "code": {},
                  "message": {},
                  "resourceIds": {
                    "shape": "S5"
                  }
                }
              }
            }
          }
        },
        "updateConfig": {
          "shape": "S28"
        },
        "launchTemplate": {
          "shape": "S27"
        },
        "tags": {
          "shape": "Sn"
        }
      }
    },
    "S3c": {
      "type": "structure",
      "required": [
        "type",
        "name"
      ],
      "members": {
        "type": {},
        "name": {}
      }
    }
  }
}