{
  "version": "2.0",
  "metadata": {
    "apiVersion": "2018-04-01",
    "endpointPrefix": "quicksight",
    "jsonVersion": "1.0",
    "protocol": "rest-json",
    "serviceFullName": "Amazon QuickSight",
    "serviceId": "QuickSight",
    "signatureVersion": "v4",
    "uid": "quicksight-2018-04-01"
  },
  "operations": {
    "CancelIngestion": {
      "http": {
        "method": "DELETE",
        "requestUri": "/accounts/{AwsAccountId}/data-sets/{DataSetId}/ingestions/{IngestionId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId",
          "DataSetId",
          "IngestionId"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "DataSetId": {
            "location": "uri",
            "locationName": "DataSetId"
          },
          "IngestionId": {
            "location": "uri",
            "locationName": "IngestionId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Arn": {},
          "IngestionId": {},
          "RequestId": {},
          "Status": {
            "location": "statusCode",
            "type": "integer"
          }
        }
      }
    },
    "CreateAccountCustomization": {
      "http": {
        "requestUri": "/accounts/{AwsAccountId}/customizations"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId",
          "AccountCustomization"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "Namespace": {
            "location": "querystring",
            "locationName": "namespace"
          },
          "AccountCustomization": {
            "shape": "Sa"
          },
          "Tags": {
            "shape": "Sb"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Arn": {},
          "AwsAccountId": {},
          "Namespace": {},
          "AccountCustomization": {
            "shape": "Sa"
          },
          "RequestId": {},
          "Status": {
            "location": "statusCode",
            "type": "integer"
          }
        }
      }
    },
    "CreateAnalysis": {
      "http": {
        "requestUri": "/accounts/{AwsAccountId}/analyses/{AnalysisId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId",
          "AnalysisId",
          "Name",
          "SourceEntity"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "AnalysisId": {
            "location": "uri",
            "locationName": "AnalysisId"
          },
          "Name": {},
          "Parameters": {
            "shape": "Sj"
          },
          "Permissions": {
            "shape": "S10"
          },
          "SourceEntity": {
            "shape": "S14"
          },
          "ThemeArn": {},
          "Tags": {
            "shape": "Sb"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Arn": {},
          "AnalysisId": {},
          "CreationStatus": {},
          "Status": {
            "location": "statusCode",
            "type": "integer"
          },
          "RequestId": {}
        }
      }
    },
    "CreateDashboard": {
      "http": {
        "requestUri": "/accounts/{AwsAccountId}/dashboards/{DashboardId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId",
          "DashboardId",
          "Name",
          "SourceEntity"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "DashboardId": {
            "location": "uri",
            "locationName": "DashboardId"
          },
          "Name": {},
          "Parameters": {
            "shape": "Sj"
          },
          "Permissions": {
            "shape": "S10"
          },
          "SourceEntity": {
            "shape": "S1c"
          },
          "Tags": {
            "shape": "Sb"
          },
          "VersionDescription": {},
          "DashboardPublishOptions": {
            "shape": "S1f"
          },
          "ThemeArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Arn": {},
          "VersionArn": {},
          "DashboardId": {},
          "CreationStatus": {},
          "Status": {
            "location": "statusCode",
            "type": "integer"
          },
          "RequestId": {}
        }
      }
    },
    "CreateDataSet": {
      "http": {
        "requestUri": "/accounts/{AwsAccountId}/data-sets"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId",
          "DataSetId",
          "Name",
          "PhysicalTableMap",
          "ImportMode"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "DataSetId": {},
          "Name": {},
          "PhysicalTableMap": {
            "shape": "S1p"
          },
          "LogicalTableMap": {
            "shape": "S2a"
          },
          "ImportMode": {},
          "ColumnGroups": {
            "shape": "S37"
          },
          "FieldFolders": {
            "shape": "S3d"
          },
          "Permissions": {
            "shape": "S10"
          },
          "RowLevelPermissionDataSet": {
            "shape": "S3i"
          },
          "RowLevelPermissionTagConfiguration": {
            "shape": "S3m"
          },
          "ColumnLevelPermissionRules": {
            "shape": "S3s"
          },
          "Tags": {
            "shape": "Sb"
          },
          "DataSetUsageConfiguration": {
            "shape": "S3w"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Arn": {},
          "DataSetId": {},
          "IngestionArn": {},
          "IngestionId": {},
          "RequestId": {},
          "Status": {
            "location": "statusCode",
            "type": "integer"
          }
        }
      }
    },
    "CreateDataSource": {
      "http": {
        "requestUri": "/accounts/{AwsAccountId}/data-sources"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId",
          "DataSourceId",
          "Name",
          "Type"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "DataSourceId": {},
          "Name": {},
          "Type": {},
          "DataSourceParameters": {
            "shape": "S40"
          },
          "Credentials": {
            "shape": "S52"
          },
          "Permissions": {
            "shape": "S10"
          },
          "VpcConnectionProperties": {
            "shape": "S58"
          },
          "SslProperties": {
            "shape": "S59"
          },
          "Tags": {
            "shape": "Sb"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Arn": {},
          "DataSourceId": {},
          "CreationStatus": {},
          "RequestId": {},
          "Status": {
            "location": "statusCode",
            "type": "integer"
          }
        }
      }
    },
    "CreateFolder": {
      "http": {
        "requestUri": "/accounts/{AwsAccountId}/folders/{FolderId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId",
          "FolderId"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "FolderId": {
            "location": "uri",
            "locationName": "FolderId"
          },
          "Name": {},
          "FolderType": {},
          "ParentFolderArn": {},
          "Permissions": {
            "shape": "S10"
          },
          "Tags": {
            "shape": "Sb"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Status": {
            "location": "statusCode",
            "type": "integer"
          },
          "Arn": {},
          "FolderId": {},
          "RequestId": {}
        }
      }
    },
    "CreateFolderMembership": {
      "http": {
        "method": "PUT",
        "requestUri": "/accounts/{AwsAccountId}/folders/{FolderId}/members/{MemberType}/{MemberId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId",
          "FolderId",
          "MemberId",
          "MemberType"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "FolderId": {
            "location": "uri",
            "locationName": "FolderId"
          },
          "MemberId": {
            "location": "uri",
            "locationName": "MemberId"
          },
          "MemberType": {
            "location": "uri",
            "locationName": "MemberType"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Status": {
            "type": "integer"
          },
          "FolderMember": {
            "type": "structure",
            "members": {
              "MemberId": {},
              "MemberType": {}
            }
          },
          "RequestId": {}
        }
      }
    },
    "CreateGroup": {
      "http": {
        "requestUri": "/accounts/{AwsAccountId}/namespaces/{Namespace}/groups"
      },
      "input": {
        "type": "structure",
        "required": [
          "GroupName",
          "AwsAccountId",
          "Namespace"
        ],
        "members": {
          "GroupName": {},
          "Description": {},
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "Namespace": {
            "location": "uri",
            "locationName": "Namespace"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Group": {
            "shape": "S5n"
          },
          "RequestId": {},
          "Status": {
            "location": "statusCode",
            "type": "integer"
          }
        }
      }
    },
    "CreateGroupMembership": {
      "http": {
        "method": "PUT",
        "requestUri": "/accounts/{AwsAccountId}/namespaces/{Namespace}/groups/{GroupName}/members/{MemberName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "MemberName",
          "GroupName",
          "AwsAccountId",
          "Namespace"
        ],
        "members": {
          "MemberName": {
            "location": "uri",
            "locationName": "MemberName"
          },
          "GroupName": {
            "location": "uri",
            "locationName": "GroupName"
          },
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "Namespace": {
            "location": "uri",
            "locationName": "Namespace"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "GroupMember": {
            "shape": "S5r"
          },
          "RequestId": {},
          "Status": {
            "location": "statusCode",
            "type": "integer"
          }
        }
      }
    },
    "CreateIAMPolicyAssignment": {
      "http": {
        "requestUri": "/accounts/{AwsAccountId}/namespaces/{Namespace}/iam-policy-assignments/"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId",
          "AssignmentName",
          "AssignmentStatus",
          "Namespace"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "AssignmentName": {},
          "AssignmentStatus": {},
          "PolicyArn": {},
          "Identities": {
            "shape": "S5v"
          },
          "Namespace": {
            "location": "uri",
            "locationName": "Namespace"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AssignmentName": {},
          "AssignmentId": {},
          "AssignmentStatus": {},
          "PolicyArn": {},
          "Identities": {
            "shape": "S5v"
          },
          "RequestId": {},
          "Status": {
            "location": "statusCode",
            "type": "integer"
          }
        }
      }
    },
    "CreateIngestion": {
      "http": {
        "method": "PUT",
        "requestUri": "/accounts/{AwsAccountId}/data-sets/{DataSetId}/ingestions/{IngestionId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "DataSetId",
          "IngestionId",
          "AwsAccountId"
        ],
        "members": {
          "DataSetId": {
            "location": "uri",
            "locationName": "DataSetId"
          },
          "IngestionId": {
            "location": "uri",
            "locationName": "IngestionId"
          },
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "IngestionType": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Arn": {},
          "IngestionId": {},
          "IngestionStatus": {},
          "RequestId": {},
          "Status": {
            "location": "statusCode",
            "type": "integer"
          }
        }
      }
    },
    "CreateNamespace": {
      "http": {
        "requestUri": "/accounts/{AwsAccountId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId",
          "Namespace",
          "IdentityStore"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "Namespace": {},
          "IdentityStore": {},
          "Tags": {
            "shape": "Sb"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Arn": {},
          "Name": {},
          "CapacityRegion": {},
          "CreationStatus": {},
          "IdentityStore": {},
          "RequestId": {},
          "Status": {
            "location": "statusCode",
            "type": "integer"
          }
        }
      }
    },
    "CreateTemplate": {
      "http": {
        "requestUri": "/accounts/{AwsAccountId}/templates/{TemplateId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId",
          "TemplateId",
          "SourceEntity"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "TemplateId": {
            "location": "uri",
            "locationName": "TemplateId"
          },
          "Name": {},
          "Permissions": {
            "shape": "S10"
          },
          "SourceEntity": {
            "shape": "S69"
          },
          "Tags": {
            "shape": "Sb"
          },
          "VersionDescription": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Arn": {},
          "VersionArn": {},
          "TemplateId": {},
          "CreationStatus": {},
          "Status": {
            "location": "statusCode",
            "type": "integer"
          },
          "RequestId": {}
        }
      }
    },
    "CreateTemplateAlias": {
      "http": {
        "requestUri": "/accounts/{AwsAccountId}/templates/{TemplateId}/aliases/{AliasName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId",
          "TemplateId",
          "AliasName",
          "TemplateVersionNumber"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "TemplateId": {
            "location": "uri",
            "locationName": "TemplateId"
          },
          "AliasName": {
            "location": "uri",
            "locationName": "AliasName"
          },
          "TemplateVersionNumber": {
            "type": "long"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TemplateAlias": {
            "shape": "S6h"
          },
          "Status": {
            "location": "statusCode",
            "type": "integer"
          },
          "RequestId": {}
        }
      }
    },
    "CreateTheme": {
      "http": {
        "requestUri": "/accounts/{AwsAccountId}/themes/{ThemeId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId",
          "ThemeId",
          "Name",
          "BaseThemeId",
          "Configuration"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "ThemeId": {
            "location": "uri",
            "locationName": "ThemeId"
          },
          "Name": {},
          "BaseThemeId": {},
          "VersionDescription": {},
          "Configuration": {
            "shape": "S6k"
          },
          "Permissions": {
            "shape": "S10"
          },
          "Tags": {
            "shape": "Sb"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Arn": {},
          "VersionArn": {},
          "ThemeId": {},
          "CreationStatus": {},
          "Status": {
            "location": "statusCode",
            "type": "integer"
          },
          "RequestId": {}
        }
      }
    },
    "CreateThemeAlias": {
      "http": {
        "requestUri": "/accounts/{AwsAccountId}/themes/{ThemeId}/aliases/{AliasName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId",
          "ThemeId",
          "AliasName",
          "ThemeVersionNumber"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "ThemeId": {
            "location": "uri",
            "locationName": "ThemeId"
          },
          "AliasName": {
            "location": "uri",
            "locationName": "AliasName"
          },
          "ThemeVersionNumber": {
            "type": "long"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ThemeAlias": {
            "shape": "S6z"
          },
          "Status": {
            "location": "statusCode",
            "type": "integer"
          },
          "RequestId": {}
        }
      }
    },
    "DeleteAccountCustomization": {
      "http": {
        "method": "DELETE",
        "requestUri": "/accounts/{AwsAccountId}/customizations"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "Namespace": {
            "location": "querystring",
            "locationName": "namespace"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "RequestId": {},
          "Status": {
            "location": "statusCode",
            "type": "integer"
          }
        }
      }
    },
    "DeleteAnalysis": {
      "http": {
        "method": "DELETE",
        "requestUri": "/accounts/{AwsAccountId}/analyses/{AnalysisId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId",
          "AnalysisId"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "AnalysisId": {
            "location": "uri",
            "locationName": "AnalysisId"
          },
          "RecoveryWindowInDays": {
            "location": "querystring",
            "locationName": "recovery-window-in-days",
            "type": "long"
          },
          "ForceDeleteWithoutRecovery": {
            "location": "querystring",
            "locationName": "force-delete-without-recovery",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Status": {
            "location": "statusCode",
            "type": "integer"
          },
          "Arn": {},
          "AnalysisId": {},
          "DeletionTime": {
            "type": "timestamp"
          },
          "RequestId": {}
        }
      }
    },
    "DeleteDashboard": {
      "http": {
        "method": "DELETE",
        "requestUri": "/accounts/{AwsAccountId}/dashboards/{DashboardId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId",
          "DashboardId"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "DashboardId": {
            "location": "uri",
            "locationName": "DashboardId"
          },
          "VersionNumber": {
            "location": "querystring",
            "locationName": "version-number",
            "type": "long"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Status": {
            "location": "statusCode",
            "type": "integer"
          },
          "Arn": {},
          "DashboardId": {},
          "RequestId": {}
        }
      }
    },
    "DeleteDataSet": {
      "http": {
        "method": "DELETE",
        "requestUri": "/accounts/{AwsAccountId}/data-sets/{DataSetId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId",
          "DataSetId"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "DataSetId": {
            "location": "uri",
            "locationName": "DataSetId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Arn": {},
          "DataSetId": {},
          "RequestId": {},
          "Status": {
            "location": "statusCode",
            "type": "integer"
          }
        }
      }
    },
    "DeleteDataSource": {
      "http": {
        "method": "DELETE",
        "requestUri": "/accounts/{AwsAccountId}/data-sources/{DataSourceId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId",
          "DataSourceId"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "DataSourceId": {
            "location": "uri",
            "locationName": "DataSourceId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Arn": {},
          "DataSourceId": {},
          "RequestId": {},
          "Status": {
            "location": "statusCode",
            "type": "integer"
          }
        }
      }
    },
    "DeleteFolder": {
      "http": {
        "method": "DELETE",
        "requestUri": "/accounts/{AwsAccountId}/folders/{FolderId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId",
          "FolderId"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "FolderId": {
            "location": "uri",
            "locationName": "FolderId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Status": {
            "location": "statusCode",
            "type": "integer"
          },
          "Arn": {},
          "FolderId": {},
          "RequestId": {}
        }
      }
    },
    "DeleteFolderMembership": {
      "http": {
        "method": "DELETE",
        "requestUri": "/accounts/{AwsAccountId}/folders/{FolderId}/members/{MemberType}/{MemberId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId",
          "FolderId",
          "MemberId",
          "MemberType"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "FolderId": {
            "location": "uri",
            "locationName": "FolderId"
          },
          "MemberId": {
            "location": "uri",
            "locationName": "MemberId"
          },
          "MemberType": {
            "location": "uri",
            "locationName": "MemberType"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Status": {
            "type": "integer"
          },
          "RequestId": {}
        }
      }
    },
    "DeleteGroup": {
      "http": {
        "method": "DELETE",
        "requestUri": "/accounts/{AwsAccountId}/namespaces/{Namespace}/groups/{GroupName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "GroupName",
          "AwsAccountId",
          "Namespace"
        ],
        "members": {
          "GroupName": {
            "location": "uri",
            "locationName": "GroupName"
          },
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "Namespace": {
            "location": "uri",
            "locationName": "Namespace"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "RequestId": {},
          "Status": {
            "location": "statusCode",
            "type": "integer"
          }
        }
      }
    },
    "DeleteGroupMembership": {
      "http": {
        "method": "DELETE",
        "requestUri": "/accounts/{AwsAccountId}/namespaces/{Namespace}/groups/{GroupName}/members/{MemberName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "MemberName",
          "GroupName",
          "AwsAccountId",
          "Namespace"
        ],
        "members": {
          "MemberName": {
            "location": "uri",
            "locationName": "MemberName"
          },
          "GroupName": {
            "location": "uri",
            "locationName": "GroupName"
          },
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "Namespace": {
            "location": "uri",
            "locationName": "Namespace"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "RequestId": {},
          "Status": {
            "location": "statusCode",
            "type": "integer"
          }
        }
      }
    },
    "DeleteIAMPolicyAssignment": {
      "http": {
        "method": "DELETE",
        "requestUri": "/accounts/{AwsAccountId}/namespace/{Namespace}/iam-policy-assignments/{AssignmentName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId",
          "AssignmentName",
          "Namespace"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "AssignmentName": {
            "location": "uri",
            "locationName": "AssignmentName"
          },
          "Namespace": {
            "location": "uri",
            "locationName": "Namespace"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AssignmentName": {},
          "RequestId": {},
          "Status": {
            "location": "statusCode",
            "type": "integer"
          }
        }
      }
    },
    "DeleteNamespace": {
      "http": {
        "method": "DELETE",
        "requestUri": "/accounts/{AwsAccountId}/namespaces/{Namespace}"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId",
          "Namespace"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "Namespace": {
            "location": "uri",
            "locationName": "Namespace"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "RequestId": {},
          "Status": {
            "location": "statusCode",
            "type": "integer"
          }
        }
      }
    },
    "DeleteTemplate": {
      "http": {
        "method": "DELETE",
        "requestUri": "/accounts/{AwsAccountId}/templates/{TemplateId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId",
          "TemplateId"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "TemplateId": {
            "location": "uri",
            "locationName": "TemplateId"
          },
          "VersionNumber": {
            "location": "querystring",
            "locationName": "version-number",
            "type": "long"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "RequestId": {},
          "Arn": {},
          "TemplateId": {},
          "Status": {
            "location": "statusCode",
            "type": "integer"
          }
        }
      }
    },
    "DeleteTemplateAlias": {
      "http": {
        "method": "DELETE",
        "requestUri": "/accounts/{AwsAccountId}/templates/{TemplateId}/aliases/{AliasName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId",
          "TemplateId",
          "AliasName"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "TemplateId": {
            "location": "uri",
            "locationName": "TemplateId"
          },
          "AliasName": {
            "location": "uri",
            "locationName": "AliasName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Status": {
            "location": "statusCode",
            "type": "integer"
          },
          "TemplateId": {},
          "AliasName": {},
          "Arn": {},
          "RequestId": {}
        }
      }
    },
    "DeleteTheme": {
      "http": {
        "method": "DELETE",
        "requestUri": "/accounts/{AwsAccountId}/themes/{ThemeId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId",
          "ThemeId"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "ThemeId": {
            "location": "uri",
            "locationName": "ThemeId"
          },
          "VersionNumber": {
            "location": "querystring",
            "locationName": "version-number",
            "type": "long"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Arn": {},
          "RequestId": {},
          "Status": {
            "location": "statusCode",
            "type": "integer"
          },
          "ThemeId": {}
        }
      }
    },
    "DeleteThemeAlias": {
      "http": {
        "method": "DELETE",
        "requestUri": "/accounts/{AwsAccountId}/themes/{ThemeId}/aliases/{AliasName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId",
          "ThemeId",
          "AliasName"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "ThemeId": {
            "location": "uri",
            "locationName": "ThemeId"
          },
          "AliasName": {
            "location": "uri",
            "locationName": "AliasName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AliasName": {},
          "Arn": {},
          "RequestId": {},
          "Status": {
            "location": "statusCode",
            "type": "integer"
          },
          "ThemeId": {}
        }
      }
    },
    "DeleteUser": {
      "http": {
        "method": "DELETE",
        "requestUri": "/accounts/{AwsAccountId}/namespaces/{Namespace}/users/{UserName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "UserName",
          "AwsAccountId",
          "Namespace"
        ],
        "members": {
          "UserName": {
            "location": "uri",
            "locationName": "UserName"
          },
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "Namespace": {
            "location": "uri",
            "locationName": "Namespace"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "RequestId": {},
          "Status": {
            "location": "statusCode",
            "type": "integer"
          }
        }
      }
    },
    "DeleteUserByPrincipalId": {
      "http": {
        "method": "DELETE",
        "requestUri": "/accounts/{AwsAccountId}/namespaces/{Namespace}/user-principals/{PrincipalId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "PrincipalId",
          "AwsAccountId",
          "Namespace"
        ],
        "members": {
          "PrincipalId": {
            "location": "uri",
            "locationName": "PrincipalId"
          },
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "Namespace": {
            "location": "uri",
            "locationName": "Namespace"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "RequestId": {},
          "Status": {
            "location": "statusCode",
            "type": "integer"
          }
        }
      }
    },
    "DescribeAccountCustomization": {
      "http": {
        "method": "GET",
        "requestUri": "/accounts/{AwsAccountId}/customizations"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "Namespace": {
            "location": "querystring",
            "locationName": "namespace"
          },
          "Resolved": {
            "location": "querystring",
            "locationName": "resolved",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Arn": {},
          "AwsAccountId": {},
          "Namespace": {},
          "AccountCustomization": {
            "shape": "Sa"
          },
          "RequestId": {},
          "Status": {
            "location": "statusCode",
            "type": "integer"
          }
        }
      }
    },
    "DescribeAccountSettings": {
      "http": {
        "method": "GET",
        "requestUri": "/accounts/{AwsAccountId}/settings"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AccountSettings": {
            "type": "structure",
            "members": {
              "AccountName": {},
              "Edition": {},
              "DefaultNamespace": {},
              "NotificationEmail": {}
            }
          },
          "RequestId": {},
          "Status": {
            "location": "statusCode",
            "type": "integer"
          }
        }
      }
    },
    "DescribeAnalysis": {
      "http": {
        "method": "GET",
        "requestUri": "/accounts/{AwsAccountId}/analyses/{AnalysisId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId",
          "AnalysisId"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "AnalysisId": {
            "location": "uri",
            "locationName": "AnalysisId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Analysis": {
            "type": "structure",
            "members": {
              "AnalysisId": {},
              "Arn": {},
              "Name": {},
              "Status": {},
              "Errors": {
                "type": "list",
                "member": {
                  "type": "structure",
                  "members": {
                    "Type": {},
                    "Message": {}
                  }
                }
              },
              "DataSetArns": {
                "shape": "S8c"
              },
              "ThemeArn": {},
              "CreatedTime": {
                "type": "timestamp"
              },
              "LastUpdatedTime": {
                "type": "timestamp"
              },
              "Sheets": {
                "shape": "S8d"
              }
            }
          },
          "Status": {
            "location": "statusCode",
            "type": "integer"
          },
          "RequestId": {}
        }
      }
    },
    "DescribeAnalysisPermissions": {
      "http": {
        "method": "GET",
        "requestUri": "/accounts/{AwsAccountId}/analyses/{AnalysisId}/permissions"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId",
          "AnalysisId"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "AnalysisId": {
            "location": "uri",
            "locationName": "AnalysisId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AnalysisId": {},
          "AnalysisArn": {},
          "Permissions": {
            "shape": "S10"
          },
          "Status": {
            "location": "statusCode",
            "type": "integer"
          },
          "RequestId": {}
        }
      }
    },
    "DescribeDashboard": {
      "http": {
        "method": "GET",
        "requestUri": "/accounts/{AwsAccountId}/dashboards/{DashboardId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId",
          "DashboardId"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "DashboardId": {
            "location": "uri",
            "locationName": "DashboardId"
          },
          "VersionNumber": {
            "location": "querystring",
            "locationName": "version-number",
            "type": "long"
          },
          "AliasName": {
            "location": "querystring",
            "locationName": "alias-name"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Dashboard": {
            "type": "structure",
            "members": {
              "DashboardId": {},
              "Arn": {},
              "Name": {},
              "Version": {
                "type": "structure",
                "members": {
                  "CreatedTime": {
                    "type": "timestamp"
                  },
                  "Errors": {
                    "type": "list",
                    "member": {
                      "type": "structure",
                      "members": {
                        "Type": {},
                        "Message": {}
                      }
                    }
                  },
                  "VersionNumber": {
                    "type": "long"
                  },
                  "Status": {},
                  "Arn": {},
                  "SourceEntityArn": {},
                  "DataSetArns": {
                    "shape": "S8c"
                  },
                  "Description": {},
                  "ThemeArn": {},
                  "Sheets": {
                    "shape": "S8d"
                  }
                }
              },
              "CreatedTime": {
                "type": "timestamp"
              },
              "LastPublishedTime": {
                "type": "timestamp"
              },
              "LastUpdatedTime": {
                "type": "timestamp"
              }
            }
          },
          "Status": {
            "location": "statusCode",
            "type": "integer"
          },
          "RequestId": {}
        }
      }
    },
    "DescribeDashboardPermissions": {
      "http": {
        "method": "GET",
        "requestUri": "/accounts/{AwsAccountId}/dashboards/{DashboardId}/permissions"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId",
          "DashboardId"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "DashboardId": {
            "location": "uri",
            "locationName": "DashboardId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DashboardId": {},
          "DashboardArn": {},
          "Permissions": {
            "shape": "S10"
          },
          "Status": {
            "location": "statusCode",
            "type": "integer"
          },
          "RequestId": {}
        }
      }
    },
    "DescribeDataSet": {
      "http": {
        "method": "GET",
        "requestUri": "/accounts/{AwsAccountId}/data-sets/{DataSetId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId",
          "DataSetId"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "DataSetId": {
            "location": "uri",
            "locationName": "DataSetId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DataSet": {
            "type": "structure",
            "members": {
              "Arn": {},
              "DataSetId": {},
              "Name": {},
              "CreatedTime": {
                "type": "timestamp"
              },
              "LastUpdatedTime": {
                "type": "timestamp"
              },
              "PhysicalTableMap": {
                "shape": "S1p"
              },
              "LogicalTableMap": {
                "shape": "S2a"
              },
              "OutputColumns": {
                "type": "list",
                "member": {
                  "type": "structure",
                  "members": {
                    "Name": {},
                    "Description": {},
                    "Type": {}
                  }
                }
              },
              "ImportMode": {},
              "ConsumedSpiceCapacityInBytes": {
                "type": "long"
              },
              "ColumnGroups": {
                "shape": "S37"
              },
              "FieldFolders": {
                "shape": "S3d"
              },
              "RowLevelPermissionDataSet": {
                "shape": "S3i"
              },
              "RowLevelPermissionTagConfiguration": {
                "shape": "S3m"
              },
              "ColumnLevelPermissionRules": {
                "shape": "S3s"
              },
              "DataSetUsageConfiguration": {
                "shape": "S3w"
              }
            }
          },
          "RequestId": {},
          "Status": {
            "location": "statusCode",
            "type": "integer"
          }
        }
      }
    },
    "DescribeDataSetPermissions": {
      "http": {
        "method": "GET",
        "requestUri": "/accounts/{AwsAccountId}/data-sets/{DataSetId}/permissions"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId",
          "DataSetId"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "DataSetId": {
            "location": "uri",
            "locationName": "DataSetId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DataSetArn": {},
          "DataSetId": {},
          "Permissions": {
            "shape": "S10"
          },
          "RequestId": {},
          "Status": {
            "location": "statusCode",
            "type": "integer"
          }
        }
      }
    },
    "DescribeDataSource": {
      "http": {
        "method": "GET",
        "requestUri": "/accounts/{AwsAccountId}/data-sources/{DataSourceId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId",
          "DataSourceId"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "DataSourceId": {
            "location": "uri",
            "locationName": "DataSourceId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DataSource": {
            "shape": "S8z"
          },
          "RequestId": {},
          "Status": {
            "location": "statusCode",
            "type": "integer"
          }
        }
      }
    },
    "DescribeDataSourcePermissions": {
      "http": {
        "method": "GET",
        "requestUri": "/accounts/{AwsAccountId}/data-sources/{DataSourceId}/permissions"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId",
          "DataSourceId"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "DataSourceId": {
            "location": "uri",
            "locationName": "DataSourceId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DataSourceArn": {},
          "DataSourceId": {},
          "Permissions": {
            "shape": "S10"
          },
          "RequestId": {},
          "Status": {
            "location": "statusCode",
            "type": "integer"
          }
        }
      }
    },
    "DescribeFolder": {
      "http": {
        "method": "GET",
        "requestUri": "/accounts/{AwsAccountId}/folders/{FolderId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId",
          "FolderId"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "FolderId": {
            "location": "uri",
            "locationName": "FolderId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Status": {
            "location": "statusCode",
            "type": "integer"
          },
          "Folder": {
            "type": "structure",
            "members": {
              "FolderId": {},
              "Arn": {},
              "Name": {},
              "FolderType": {},
              "FolderPath": {
                "type": "list",
                "member": {}
              },
              "CreatedTime": {
                "type": "timestamp"
              },
              "LastUpdatedTime": {
                "type": "timestamp"
              }
            }
          },
          "RequestId": {}
        }
      }
    },
    "DescribeFolderPermissions": {
      "http": {
        "method": "GET",
        "requestUri": "/accounts/{AwsAccountId}/folders/{FolderId}/permissions"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId",
          "FolderId"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "FolderId": {
            "location": "uri",
            "locationName": "FolderId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Status": {
            "location": "statusCode",
            "type": "integer"
          },
          "FolderId": {},
          "Arn": {},
          "Permissions": {
            "shape": "S10"
          },
          "RequestId": {}
        }
      }
    },
    "DescribeFolderResolvedPermissions": {
      "http": {
        "method": "GET",
        "requestUri": "/accounts/{AwsAccountId}/folders/{FolderId}/resolved-permissions"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId",
          "FolderId"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "FolderId": {
            "location": "uri",
            "locationName": "FolderId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Status": {
            "location": "statusCode",
            "type": "integer"
          },
          "FolderId": {},
          "Arn": {},
          "Permissions": {
            "shape": "S10"
          },
          "RequestId": {}
        }
      }
    },
    "DescribeGroup": {
      "http": {
        "method": "GET",
        "requestUri": "/accounts/{AwsAccountId}/namespaces/{Namespace}/groups/{GroupName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "GroupName",
          "AwsAccountId",
          "Namespace"
        ],
        "members": {
          "GroupName": {
            "location": "uri",
            "locationName": "GroupName"
          },
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "Namespace": {
            "location": "uri",
            "locationName": "Namespace"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Group": {
            "shape": "S5n"
          },
          "RequestId": {},
          "Status": {
            "location": "statusCode",
            "type": "integer"
          }
        }
      }
    },
    "DescribeIAMPolicyAssignment": {
      "http": {
        "method": "GET",
        "requestUri": "/accounts/{AwsAccountId}/namespaces/{Namespace}/iam-policy-assignments/{AssignmentName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId",
          "AssignmentName",
          "Namespace"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "AssignmentName": {
            "location": "uri",
            "locationName": "AssignmentName"
          },
          "Namespace": {
            "location": "uri",
            "locationName": "Namespace"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "IAMPolicyAssignment": {
            "type": "structure",
            "members": {
              "AwsAccountId": {},
              "AssignmentId": {},
              "AssignmentName": {},
              "PolicyArn": {},
              "Identities": {
                "shape": "S5v"
              },
              "AssignmentStatus": {}
            }
          },
          "RequestId": {},
          "Status": {
            "location": "statusCode",
            "type": "integer"
          }
        }
      }
    },
    "DescribeIngestion": {
      "http": {
        "method": "GET",
        "requestUri": "/accounts/{AwsAccountId}/data-sets/{DataSetId}/ingestions/{IngestionId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId",
          "DataSetId",
          "IngestionId"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "DataSetId": {
            "location": "uri",
            "locationName": "DataSetId"
          },
          "IngestionId": {
            "location": "uri",
            "locationName": "IngestionId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Ingestion": {
            "shape": "S9j"
          },
          "RequestId": {},
          "Status": {
            "location": "statusCode",
            "type": "integer"
          }
        }
      }
    },
    "DescribeIpRestriction": {
      "http": {
        "method": "GET",
        "requestUri": "/accounts/{AwsAccountId}/ip-restriction"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AwsAccountId": {},
          "IpRestrictionRuleMap": {
            "shape": "S9s"
          },
          "Enabled": {
            "type": "boolean"
          },
          "RequestId": {},
          "Status": {
            "location": "statusCode",
            "type": "integer"
          }
        }
      }
    },
    "DescribeNamespace": {
      "http": {
        "method": "GET",
        "requestUri": "/accounts/{AwsAccountId}/namespaces/{Namespace}"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId",
          "Namespace"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "Namespace": {
            "location": "uri",
            "locationName": "Namespace"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Namespace": {
            "shape": "S9y"
          },
          "RequestId": {},
          "Status": {
            "location": "statusCode",
            "type": "integer"
          }
        }
      }
    },
    "DescribeTemplate": {
      "http": {
        "method": "GET",
        "requestUri": "/accounts/{AwsAccountId}/templates/{TemplateId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId",
          "TemplateId"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "TemplateId": {
            "location": "uri",
            "locationName": "TemplateId"
          },
          "VersionNumber": {
            "location": "querystring",
            "locationName": "version-number",
            "type": "long"
          },
          "AliasName": {
            "location": "querystring",
            "locationName": "alias-name"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Template": {
            "type": "structure",
            "members": {
              "Arn": {},
              "Name": {},
              "Version": {
                "type": "structure",
                "members": {
                  "CreatedTime": {
                    "type": "timestamp"
                  },
                  "Errors": {
                    "type": "list",
                    "member": {
                      "type": "structure",
                      "members": {
                        "Type": {},
                        "Message": {}
                      }
                    }
                  },
                  "VersionNumber": {
                    "type": "long"
                  },
                  "Status": {},
                  "DataSetConfigurations": {
                    "type": "list",
                    "member": {
                      "type": "structure",
                      "members": {
                        "Placeholder": {},
                        "DataSetSchema": {
                          "type": "structure",
                          "members": {
                            "ColumnSchemaList": {
                              "type": "list",
                              "member": {
                                "type": "structure",
                                "members": {
                                  "Name": {},
                                  "DataType": {},
                                  "GeographicRole": {}
                                }
                              }
                            }
                          }
                        },
                        "ColumnGroupSchemaList": {
                          "type": "list",
                          "member": {
                            "type": "structure",
                            "members": {
                              "Name": {},
                              "ColumnGroupColumnSchemaList": {
                                "type": "list",
                                "member": {
                                  "type": "structure",
                                  "members": {
                                    "Name": {}
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  },
                  "Description": {},
                  "SourceEntityArn": {},
                  "ThemeArn": {},
                  "Sheets": {
                    "shape": "S8d"
                  }
                }
              },
              "TemplateId": {},
              "LastUpdatedTime": {
                "type": "timestamp"
              },
              "CreatedTime": {
                "type": "timestamp"
              }
            }
          },
          "Status": {
            "location": "statusCode",
            "type": "integer"
          },
          "RequestId": {}
        }
      }
    },
    "DescribeTemplateAlias": {
      "http": {
        "method": "GET",
        "requestUri": "/accounts/{AwsAccountId}/templates/{TemplateId}/aliases/{AliasName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId",
          "TemplateId",
          "AliasName"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "TemplateId": {
            "location": "uri",
            "locationName": "TemplateId"
          },
          "AliasName": {
            "location": "uri",
            "locationName": "AliasName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TemplateAlias": {
            "shape": "S6h"
          },
          "Status": {
            "location": "statusCode",
            "type": "integer"
          },
          "RequestId": {}
        }
      }
    },
    "DescribeTemplatePermissions": {
      "http": {
        "method": "GET",
        "requestUri": "/accounts/{AwsAccountId}/templates/{TemplateId}/permissions"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId",
          "TemplateId"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "TemplateId": {
            "location": "uri",
            "locationName": "TemplateId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TemplateId": {},
          "TemplateArn": {},
          "Permissions": {
            "shape": "S10"
          },
          "RequestId": {},
          "Status": {
            "location": "statusCode",
            "type": "integer"
          }
        }
      }
    },
    "DescribeTheme": {
      "http": {
        "method": "GET",
        "requestUri": "/accounts/{AwsAccountId}/themes/{ThemeId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId",
          "ThemeId"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "ThemeId": {
            "location": "uri",
            "locationName": "ThemeId"
          },
          "VersionNumber": {
            "location": "querystring",
            "locationName": "version-number",
            "type": "long"
          },
          "AliasName": {
            "location": "querystring",
            "locationName": "alias-name"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Theme": {
            "type": "structure",
            "members": {
              "Arn": {},
              "Name": {},
              "ThemeId": {},
              "Version": {
                "type": "structure",
                "members": {
                  "VersionNumber": {
                    "type": "long"
                  },
                  "Arn": {},
                  "Description": {},
                  "BaseThemeId": {},
                  "CreatedTime": {
                    "type": "timestamp"
                  },
                  "Configuration": {
                    "shape": "S6k"
                  },
                  "Errors": {
                    "type": "list",
                    "member": {
                      "type": "structure",
                      "members": {
                        "Type": {},
                        "Message": {}
                      }
                    }
                  },
                  "Status": {}
                }
              },
              "CreatedTime": {
                "type": "timestamp"
              },
              "LastUpdatedTime": {
                "type": "timestamp"
              },
              "Type": {}
            }
          },
          "Status": {
            "location": "statusCode",
            "type": "integer"
          },
          "RequestId": {}
        }
      }
    },
    "DescribeThemeAlias": {
      "http": {
        "method": "GET",
        "requestUri": "/accounts/{AwsAccountId}/themes/{ThemeId}/aliases/{AliasName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId",
          "ThemeId",
          "AliasName"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "ThemeId": {
            "location": "uri",
            "locationName": "ThemeId"
          },
          "AliasName": {
            "location": "uri",
            "locationName": "AliasName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ThemeAlias": {
            "shape": "S6z"
          },
          "Status": {
            "location": "statusCode",
            "type": "integer"
          },
          "RequestId": {}
        }
      }
    },
    "DescribeThemePermissions": {
      "http": {
        "method": "GET",
        "requestUri": "/accounts/{AwsAccountId}/themes/{ThemeId}/permissions"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId",
          "ThemeId"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "ThemeId": {
            "location": "uri",
            "locationName": "ThemeId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ThemeId": {},
          "ThemeArn": {},
          "Permissions": {
            "shape": "S10"
          },
          "RequestId": {},
          "Status": {
            "location": "statusCode",
            "type": "integer"
          }
        }
      }
    },
    "DescribeUser": {
      "http": {
        "method": "GET",
        "requestUri": "/accounts/{AwsAccountId}/namespaces/{Namespace}/users/{UserName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "UserName",
          "AwsAccountId",
          "Namespace"
        ],
        "members": {
          "UserName": {
            "location": "uri",
            "locationName": "UserName"
          },
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "Namespace": {
            "location": "uri",
            "locationName": "Namespace"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "User": {
            "shape": "Sb0"
          },
          "RequestId": {},
          "Status": {
            "location": "statusCode",
            "type": "integer"
          }
        }
      }
    },
    "GenerateEmbedUrlForAnonymousUser": {
      "http": {
        "requestUri": "/accounts/{AwsAccountId}/embed-url/anonymous-user"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId",
          "Namespace",
          "AuthorizedResourceArns",
          "ExperienceConfiguration"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "SessionLifetimeInMinutes": {
            "type": "long"
          },
          "Namespace": {},
          "SessionTags": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "Key",
                "Value"
              ],
              "members": {
                "Key": {},
                "Value": {
                  "shape": "S3r"
                }
              }
            }
          },
          "AuthorizedResourceArns": {
            "type": "list",
            "member": {}
          },
          "ExperienceConfiguration": {
            "type": "structure",
            "members": {
              "Dashboard": {
                "type": "structure",
                "required": [
                  "InitialDashboardId"
                ],
                "members": {
                  "InitialDashboardId": {}
                }
              }
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "EmbedUrl",
          "Status",
          "RequestId"
        ],
        "members": {
          "EmbedUrl": {
            "shape": "Sbc"
          },
          "Status": {
            "location": "statusCode",
            "type": "integer"
          },
          "RequestId": {}
        }
      }
    },
    "GenerateEmbedUrlForRegisteredUser": {
      "http": {
        "requestUri": "/accounts/{AwsAccountId}/embed-url/registered-user"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId",
          "UserArn",
          "ExperienceConfiguration"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "SessionLifetimeInMinutes": {
            "type": "long"
          },
          "UserArn": {},
          "ExperienceConfiguration": {
            "type": "structure",
            "members": {
              "Dashboard": {
                "type": "structure",
                "required": [
                  "InitialDashboardId"
                ],
                "members": {
                  "InitialDashboardId": {}
                }
              },
              "QuickSightConsole": {
                "type": "structure",
                "members": {
                  "InitialPath": {}
                }
              },
              "QSearchBar": {
                "type": "structure",
                "members": {
                  "InitialTopicId": {}
                }
              }
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "EmbedUrl",
          "Status",
          "RequestId"
        ],
        "members": {
          "EmbedUrl": {
            "shape": "Sbc"
          },
          "Status": {
            "location": "statusCode",
            "type": "integer"
          },
          "RequestId": {}
        }
      }
    },
    "GetDashboardEmbedUrl": {
      "http": {
        "method": "GET",
        "requestUri": "/accounts/{AwsAccountId}/dashboards/{DashboardId}/embed-url"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId",
          "DashboardId",
          "IdentityType"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "DashboardId": {
            "location": "uri",
            "locationName": "DashboardId"
          },
          "IdentityType": {
            "location": "querystring",
            "locationName": "creds-type"
          },
          "SessionLifetimeInMinutes": {
            "location": "querystring",
            "locationName": "session-lifetime",
            "type": "long"
          },
          "UndoRedoDisabled": {
            "location": "querystring",
            "locationName": "undo-redo-disabled",
            "type": "boolean"
          },
          "ResetDisabled": {
            "location": "querystring",
            "locationName": "reset-disabled",
            "type": "boolean"
          },
          "StatePersistenceEnabled": {
            "location": "querystring",
            "locationName": "state-persistence-enabled",
            "type": "boolean"
          },
          "UserArn": {
            "location": "querystring",
            "locationName": "user-arn"
          },
          "Namespace": {
            "location": "querystring",
            "locationName": "namespace"
          },
          "AdditionalDashboardIds": {
            "location": "querystring",
            "locationName": "additional-dashboard-ids",
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "EmbedUrl": {
            "shape": "Sbc"
          },
          "Status": {
            "location": "statusCode",
            "type": "integer"
          },
          "RequestId": {}
        }
      }
    },
    "GetSessionEmbedUrl": {
      "http": {
        "method": "GET",
        "requestUri": "/accounts/{AwsAccountId}/session-embed-url"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "EntryPoint": {
            "location": "querystring",
            "locationName": "entry-point"
          },
          "SessionLifetimeInMinutes": {
            "location": "querystring",
            "locationName": "session-lifetime",
            "type": "long"
          },
          "UserArn": {
            "location": "querystring",
            "locationName": "user-arn"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "EmbedUrl": {
            "shape": "Sbc"
          },
          "Status": {
            "location": "statusCode",
            "type": "integer"
          },
          "RequestId": {}
        }
      }
    },
    "ListAnalyses": {
      "http": {
        "method": "GET",
        "requestUri": "/accounts/{AwsAccountId}/analyses"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "next-token"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "max-results",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AnalysisSummaryList": {
            "shape": "Sbu"
          },
          "NextToken": {},
          "Status": {
            "location": "statusCode",
            "type": "integer"
          },
          "RequestId": {}
        }
      }
    },
    "ListDashboardVersions": {
      "http": {
        "method": "GET",
        "requestUri": "/accounts/{AwsAccountId}/dashboards/{DashboardId}/versions"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId",
          "DashboardId"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "DashboardId": {
            "location": "uri",
            "locationName": "DashboardId"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "next-token"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "max-results",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DashboardVersionSummaryList": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Arn": {},
                "CreatedTime": {
                  "type": "timestamp"
                },
                "VersionNumber": {
                  "type": "long"
                },
                "Status": {},
                "SourceEntityArn": {},
                "Description": {}
              }
            }
          },
          "NextToken": {},
          "Status": {
            "location": "statusCode",
            "type": "integer"
          },
          "RequestId": {}
        }
      }
    },
    "ListDashboards": {
      "http": {
        "method": "GET",
        "requestUri": "/accounts/{AwsAccountId}/dashboards"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "next-token"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "max-results",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DashboardSummaryList": {
            "shape": "Sc2"
          },
          "NextToken": {},
          "Status": {
            "location": "statusCode",
            "type": "integer"
          },
          "RequestId": {}
        }
      }
    },
    "ListDataSets": {
      "http": {
        "method": "GET",
        "requestUri": "/accounts/{AwsAccountId}/data-sets"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "next-token"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "max-results",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DataSetSummaries": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Arn": {},
                "DataSetId": {},
                "Name": {},
                "CreatedTime": {
                  "type": "timestamp"
                },
                "LastUpdatedTime": {
                  "type": "timestamp"
                },
                "ImportMode": {},
                "RowLevelPermissionDataSet": {
                  "shape": "S3i"
                },
                "RowLevelPermissionTagConfigurationApplied": {
                  "type": "boolean"
                },
                "ColumnLevelPermissionRulesApplied": {
                  "type": "boolean"
                }
              }
            }
          },
          "NextToken": {},
          "RequestId": {},
          "Status": {
            "location": "statusCode",
            "type": "integer"
          }
        }
      }
    },
    "ListDataSources": {
      "http": {
        "method": "GET",
        "requestUri": "/accounts/{AwsAccountId}/data-sources"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "next-token"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "max-results",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DataSources": {
            "type": "list",
            "member": {
              "shape": "S8z"
            }
          },
          "NextToken": {},
          "RequestId": {},
          "Status": {
            "location": "statusCode",
            "type": "integer"
          }
        }
      }
    },
    "ListFolderMembers": {
      "http": {
        "method": "GET",
        "requestUri": "/accounts/{AwsAccountId}/folders/{FolderId}/members"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId",
          "FolderId"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "FolderId": {
            "location": "uri",
            "locationName": "FolderId"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "next-token"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "max-results",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Status": {
            "location": "statusCode",
            "type": "integer"
          },
          "FolderMemberList": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "MemberId": {},
                "MemberArn": {}
              }
            }
          },
          "NextToken": {},
          "RequestId": {}
        }
      }
    },
    "ListFolders": {
      "http": {
        "method": "GET",
        "requestUri": "/accounts/{AwsAccountId}/folders"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "next-token"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "max-results",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Status": {
            "location": "statusCode",
            "type": "integer"
          },
          "FolderSummaryList": {
            "shape": "Sch"
          },
          "NextToken": {},
          "RequestId": {}
        }
      }
    },
    "ListGroupMemberships": {
      "http": {
        "method": "GET",
        "requestUri": "/accounts/{AwsAccountId}/namespaces/{Namespace}/groups/{GroupName}/members"
      },
      "input": {
        "type": "structure",
        "required": [
          "GroupName",
          "AwsAccountId",
          "Namespace"
        ],
        "members": {
          "GroupName": {
            "location": "uri",
            "locationName": "GroupName"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "next-token"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "max-results",
            "type": "integer"
          },
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "Namespace": {
            "location": "uri",
            "locationName": "Namespace"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "GroupMemberList": {
            "type": "list",
            "member": {
              "shape": "S5r"
            }
          },
          "NextToken": {},
          "RequestId": {},
          "Status": {
            "location": "statusCode",
            "type": "integer"
          }
        }
      }
    },
    "ListGroups": {
      "http": {
        "method": "GET",
        "requestUri": "/accounts/{AwsAccountId}/namespaces/{Namespace}/groups"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId",
          "Namespace"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "next-token"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "max-results",
            "type": "integer"
          },
          "Namespace": {
            "location": "uri",
            "locationName": "Namespace"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "GroupList": {
            "shape": "Sco"
          },
          "NextToken": {},
          "RequestId": {},
          "Status": {
            "location": "statusCode",
            "type": "integer"
          }
        }
      }
    },
    "ListIAMPolicyAssignments": {
      "http": {
        "method": "GET",
        "requestUri": "/accounts/{AwsAccountId}/namespaces/{Namespace}/iam-policy-assignments"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId",
          "Namespace"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "AssignmentStatus": {},
          "Namespace": {
            "location": "uri",
            "locationName": "Namespace"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "next-token"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "max-results",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "IAMPolicyAssignments": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "AssignmentName": {},
                "AssignmentStatus": {}
              }
            }
          },
          "NextToken": {},
          "RequestId": {},
          "Status": {
            "location": "statusCode",
            "type": "integer"
          }
        }
      }
    },
    "ListIAMPolicyAssignmentsForUser": {
      "http": {
        "method": "GET",
        "requestUri": "/accounts/{AwsAccountId}/namespaces/{Namespace}/users/{UserName}/iam-policy-assignments"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId",
          "UserName",
          "Namespace"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "UserName": {
            "location": "uri",
            "locationName": "UserName"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "next-token"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "max-results",
            "type": "integer"
          },
          "Namespace": {
            "location": "uri",
            "locationName": "Namespace"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ActiveAssignments": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "AssignmentName": {},
                "PolicyArn": {}
              }
            }
          },
          "RequestId": {},
          "NextToken": {},
          "Status": {
            "location": "statusCode",
            "type": "integer"
          }
        }
      }
    },
    "ListIngestions": {
      "http": {
        "method": "GET",
        "requestUri": "/accounts/{AwsAccountId}/data-sets/{DataSetId}/ingestions"
      },
      "input": {
        "type": "structure",
        "required": [
          "DataSetId",
          "AwsAccountId"
        ],
        "members": {
          "DataSetId": {
            "location": "uri",
            "locationName": "DataSetId"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "next-token"
          },
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "max-results",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Ingestions": {
            "type": "list",
            "member": {
              "shape": "S9j"
            }
          },
          "NextToken": {},
          "RequestId": {},
          "Status": {
            "location": "statusCode",
            "type": "integer"
          }
        }
      }
    },
    "ListNamespaces": {
      "http": {
        "method": "GET",
        "requestUri": "/accounts/{AwsAccountId}/namespaces"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "next-token"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "max-results",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Namespaces": {
            "type": "list",
            "member": {
              "shape": "S9y"
            }
          },
          "NextToken": {},
          "RequestId": {},
          "Status": {
            "location": "statusCode",
            "type": "integer"
          }
        }
      }
    },
    "ListTagsForResource": {
      "http": {
        "method": "GET",
        "requestUri": "/resources/{ResourceArn}/tags"
      },
      "input": {
        "type": "structure",
        "required": [
          "ResourceArn"
        ],
        "members": {
          "ResourceArn": {
            "location": "uri",
            "locationName": "ResourceArn"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Tags": {
            "shape": "Sb"
          },
          "RequestId": {},
          "Status": {
            "location": "statusCode",
            "type": "integer"
          }
        }
      }
    },
    "ListTemplateAliases": {
      "http": {
        "method": "GET",
        "requestUri": "/accounts/{AwsAccountId}/templates/{TemplateId}/aliases"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId",
          "TemplateId"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "TemplateId": {
            "location": "uri",
            "locationName": "TemplateId"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "next-token"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "max-result",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TemplateAliasList": {
            "type": "list",
            "member": {
              "shape": "S6h"
            }
          },
          "Status": {
            "location": "statusCode",
            "type": "integer"
          },
          "RequestId": {},
          "NextToken": {}
        }
      }
    },
    "ListTemplateVersions": {
      "http": {
        "method": "GET",
        "requestUri": "/accounts/{AwsAccountId}/templates/{TemplateId}/versions"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId",
          "TemplateId"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "TemplateId": {
            "location": "uri",
            "locationName": "TemplateId"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "next-token"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "max-results",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TemplateVersionSummaryList": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Arn": {},
                "VersionNumber": {
                  "type": "long"
                },
                "CreatedTime": {
                  "type": "timestamp"
                },
                "Status": {},
                "Description": {}
              }
            }
          },
          "NextToken": {},
          "Status": {
            "location": "statusCode",
            "type": "integer"
          },
          "RequestId": {}
        }
      }
    },
    "ListTemplates": {
      "http": {
        "method": "GET",
        "requestUri": "/accounts/{AwsAccountId}/templates"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "next-token"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "max-result",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TemplateSummaryList": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Arn": {},
                "TemplateId": {},
                "Name": {},
                "LatestVersionNumber": {
                  "type": "long"
                },
                "CreatedTime": {
                  "type": "timestamp"
                },
                "LastUpdatedTime": {
                  "type": "timestamp"
                }
              }
            }
          },
          "NextToken": {},
          "Status": {
            "location": "statusCode",
            "type": "integer"
          },
          "RequestId": {}
        }
      }
    },
    "ListThemeAliases": {
      "http": {
        "method": "GET",
        "requestUri": "/accounts/{AwsAccountId}/themes/{ThemeId}/aliases"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId",
          "ThemeId"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "ThemeId": {
            "location": "uri",
            "locationName": "ThemeId"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "next-token"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "max-result",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ThemeAliasList": {
            "type": "list",
            "member": {
              "shape": "S6z"
            }
          },
          "Status": {
            "location": "statusCode",
            "type": "integer"
          },
          "RequestId": {},
          "NextToken": {}
        }
      }
    },
    "ListThemeVersions": {
      "http": {
        "method": "GET",
        "requestUri": "/accounts/{AwsAccountId}/themes/{ThemeId}/versions"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId",
          "ThemeId"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "ThemeId": {
            "location": "uri",
            "locationName": "ThemeId"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "next-token"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "max-results",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ThemeVersionSummaryList": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "VersionNumber": {
                  "type": "long"
                },
                "Arn": {},
                "Description": {},
                "CreatedTime": {
                  "type": "timestamp"
                },
                "Status": {}
              }
            }
          },
          "NextToken": {},
          "Status": {
            "location": "statusCode",
            "type": "integer"
          },
          "RequestId": {}
        }
      }
    },
    "ListThemes": {
      "http": {
        "method": "GET",
        "requestUri": "/accounts/{AwsAccountId}/themes"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "next-token"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "max-results",
            "type": "integer"
          },
          "Type": {
            "location": "querystring",
            "locationName": "type"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ThemeSummaryList": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Arn": {},
                "Name": {},
                "ThemeId": {},
                "LatestVersionNumber": {
                  "type": "long"
                },
                "CreatedTime": {
                  "type": "timestamp"
                },
                "LastUpdatedTime": {
                  "type": "timestamp"
                }
              }
            }
          },
          "NextToken": {},
          "Status": {
            "location": "statusCode",
            "type": "integer"
          },
          "RequestId": {}
        }
      }
    },
    "ListUserGroups": {
      "http": {
        "method": "GET",
        "requestUri": "/accounts/{AwsAccountId}/namespaces/{Namespace}/users/{UserName}/groups"
      },
      "input": {
        "type": "structure",
        "required": [
          "UserName",
          "AwsAccountId",
          "Namespace"
        ],
        "members": {
          "UserName": {
            "location": "uri",
            "locationName": "UserName"
          },
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "Namespace": {
            "location": "uri",
            "locationName": "Namespace"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "next-token"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "max-results",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "GroupList": {
            "shape": "Sco"
          },
          "NextToken": {},
          "RequestId": {},
          "Status": {
            "location": "statusCode",
            "type": "integer"
          }
        }
      }
    },
    "ListUsers": {
      "http": {
        "method": "GET",
        "requestUri": "/accounts/{AwsAccountId}/namespaces/{Namespace}/users"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId",
          "Namespace"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "next-token"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "max-results",
            "type": "integer"
          },
          "Namespace": {
            "location": "uri",
            "locationName": "Namespace"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "UserList": {
            "type": "list",
            "member": {
              "shape": "Sb0"
            }
          },
          "NextToken": {},
          "RequestId": {},
          "Status": {
            "location": "statusCode",
            "type": "integer"
          }
        }
      }
    },
    "RegisterUser": {
      "http": {
        "requestUri": "/accounts/{AwsAccountId}/namespaces/{Namespace}/users"
      },
      "input": {
        "type": "structure",
        "required": [
          "IdentityType",
          "Email",
          "UserRole",
          "AwsAccountId",
          "Namespace"
        ],
        "members": {
          "IdentityType": {},
          "Email": {},
          "UserRole": {},
          "IamArn": {},
          "SessionName": {},
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "Namespace": {
            "location": "uri",
            "locationName": "Namespace"
          },
          "UserName": {},
          "CustomPermissionsName": {},
          "ExternalLoginFederationProviderType": {},
          "CustomFederationProviderUrl": {},
          "ExternalLoginId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "User": {
            "shape": "Sb0"
          },
          "UserInvitationUrl": {},
          "RequestId": {},
          "Status": {
            "location": "statusCode",
            "type": "integer"
          }
        }
      }
    },
    "RestoreAnalysis": {
      "http": {
        "requestUri": "/accounts/{AwsAccountId}/restore/analyses/{AnalysisId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId",
          "AnalysisId"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "AnalysisId": {
            "location": "uri",
            "locationName": "AnalysisId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Status": {
            "location": "statusCode",
            "type": "integer"
          },
          "Arn": {},
          "AnalysisId": {},
          "RequestId": {}
        }
      }
    },
    "SearchAnalyses": {
      "http": {
        "requestUri": "/accounts/{AwsAccountId}/search/analyses"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId",
          "Filters"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "Filters": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Operator": {},
                "Name": {},
                "Value": {}
              }
            }
          },
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AnalysisSummaryList": {
            "shape": "Sbu"
          },
          "NextToken": {},
          "Status": {
            "location": "statusCode",
            "type": "integer"
          },
          "RequestId": {}
        }
      }
    },
    "SearchDashboards": {
      "http": {
        "requestUri": "/accounts/{AwsAccountId}/search/dashboards"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId",
          "Filters"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "Filters": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "Operator"
              ],
              "members": {
                "Operator": {},
                "Name": {},
                "Value": {}
              }
            }
          },
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DashboardSummaryList": {
            "shape": "Sc2"
          },
          "NextToken": {},
          "Status": {
            "location": "statusCode",
            "type": "integer"
          },
          "RequestId": {}
        }
      }
    },
    "SearchFolders": {
      "http": {
        "requestUri": "/accounts/{AwsAccountId}/search/folders"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId",
          "Filters"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "Filters": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Operator": {},
                "Name": {},
                "Value": {}
              }
            }
          },
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Status": {
            "location": "statusCode",
            "type": "integer"
          },
          "FolderSummaryList": {
            "shape": "Sch"
          },
          "NextToken": {},
          "RequestId": {}
        }
      }
    },
    "TagResource": {
      "http": {
        "requestUri": "/resources/{ResourceArn}/tags"
      },
      "input": {
        "type": "structure",
        "required": [
          "ResourceArn",
          "Tags"
        ],
        "members": {
          "ResourceArn": {
            "location": "uri",
            "locationName": "ResourceArn"
          },
          "Tags": {
            "shape": "Sb"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "RequestId": {},
          "Status": {
            "location": "statusCode",
            "type": "integer"
          }
        }
      }
    },
    "UntagResource": {
      "http": {
        "method": "DELETE",
        "requestUri": "/resources/{ResourceArn}/tags"
      },
      "input": {
        "type": "structure",
        "required": [
          "ResourceArn",
          "TagKeys"
        ],
        "members": {
          "ResourceArn": {
            "location": "uri",
            "locationName": "ResourceArn"
          },
          "TagKeys": {
            "location": "querystring",
            "locationName": "keys",
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "RequestId": {},
          "Status": {
            "location": "statusCode",
            "type": "integer"
          }
        }
      }
    },
    "UpdateAccountCustomization": {
      "http": {
        "method": "PUT",
        "requestUri": "/accounts/{AwsAccountId}/customizations"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId",
          "AccountCustomization"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "Namespace": {
            "location": "querystring",
            "locationName": "namespace"
          },
          "AccountCustomization": {
            "shape": "Sa"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Arn": {},
          "AwsAccountId": {},
          "Namespace": {},
          "AccountCustomization": {
            "shape": "Sa"
          },
          "RequestId": {},
          "Status": {
            "location": "statusCode",
            "type": "integer"
          }
        }
      }
    },
    "UpdateAccountSettings": {
      "http": {
        "method": "PUT",
        "requestUri": "/accounts/{AwsAccountId}/settings"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId",
          "DefaultNamespace"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "DefaultNamespace": {},
          "NotificationEmail": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "RequestId": {},
          "Status": {
            "location": "statusCode",
            "type": "integer"
          }
        }
      }
    },
    "UpdateAnalysis": {
      "http": {
        "method": "PUT",
        "requestUri": "/accounts/{AwsAccountId}/analyses/{AnalysisId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId",
          "AnalysisId",
          "Name",
          "SourceEntity"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "AnalysisId": {
            "location": "uri",
            "locationName": "AnalysisId"
          },
          "Name": {},
          "Parameters": {
            "shape": "Sj"
          },
          "SourceEntity": {
            "shape": "S14"
          },
          "ThemeArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Arn": {},
          "AnalysisId": {},
          "UpdateStatus": {},
          "Status": {
            "location": "statusCode",
            "type": "integer"
          },
          "RequestId": {}
        }
      }
    },
    "UpdateAnalysisPermissions": {
      "http": {
        "method": "PUT",
        "requestUri": "/accounts/{AwsAccountId}/analyses/{AnalysisId}/permissions"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId",
          "AnalysisId"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "AnalysisId": {
            "location": "uri",
            "locationName": "AnalysisId"
          },
          "GrantPermissions": {
            "shape": "Seu"
          },
          "RevokePermissions": {
            "shape": "Seu"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AnalysisArn": {},
          "AnalysisId": {},
          "Permissions": {
            "shape": "S10"
          },
          "RequestId": {},
          "Status": {
            "location": "statusCode",
            "type": "integer"
          }
        }
      }
    },
    "UpdateDashboard": {
      "http": {
        "method": "PUT",
        "requestUri": "/accounts/{AwsAccountId}/dashboards/{DashboardId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId",
          "DashboardId",
          "Name",
          "SourceEntity"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "DashboardId": {
            "location": "uri",
            "locationName": "DashboardId"
          },
          "Name": {},
          "SourceEntity": {
            "shape": "S1c"
          },
          "Parameters": {
            "shape": "Sj"
          },
          "VersionDescription": {},
          "DashboardPublishOptions": {
            "shape": "S1f"
          },
          "ThemeArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Arn": {},
          "VersionArn": {},
          "DashboardId": {},
          "CreationStatus": {},
          "Status": {
            "type": "integer"
          },
          "RequestId": {}
        }
      }
    },
    "UpdateDashboardPermissions": {
      "http": {
        "method": "PUT",
        "requestUri": "/accounts/{AwsAccountId}/dashboards/{DashboardId}/permissions"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId",
          "DashboardId"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "DashboardId": {
            "location": "uri",
            "locationName": "DashboardId"
          },
          "GrantPermissions": {
            "shape": "Seu"
          },
          "RevokePermissions": {
            "shape": "Seu"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DashboardArn": {},
          "DashboardId": {},
          "Permissions": {
            "shape": "S10"
          },
          "RequestId": {},
          "Status": {
            "location": "statusCode",
            "type": "integer"
          }
        }
      }
    },
    "UpdateDashboardPublishedVersion": {
      "http": {
        "method": "PUT",
        "requestUri": "/accounts/{AwsAccountId}/dashboards/{DashboardId}/versions/{VersionNumber}"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId",
          "DashboardId",
          "VersionNumber"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "DashboardId": {
            "location": "uri",
            "locationName": "DashboardId"
          },
          "VersionNumber": {
            "location": "uri",
            "locationName": "VersionNumber",
            "type": "long"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DashboardId": {},
          "DashboardArn": {},
          "Status": {
            "location": "statusCode",
            "type": "integer"
          },
          "RequestId": {}
        }
      }
    },
    "UpdateDataSet": {
      "http": {
        "method": "PUT",
        "requestUri": "/accounts/{AwsAccountId}/data-sets/{DataSetId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId",
          "DataSetId",
          "Name",
          "PhysicalTableMap",
          "ImportMode"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "DataSetId": {
            "location": "uri",
            "locationName": "DataSetId"
          },
          "Name": {},
          "PhysicalTableMap": {
            "shape": "S1p"
          },
          "LogicalTableMap": {
            "shape": "S2a"
          },
          "ImportMode": {},
          "ColumnGroups": {
            "shape": "S37"
          },
          "FieldFolders": {
            "shape": "S3d"
          },
          "RowLevelPermissionDataSet": {
            "shape": "S3i"
          },
          "RowLevelPermissionTagConfiguration": {
            "shape": "S3m"
          },
          "ColumnLevelPermissionRules": {
            "shape": "S3s"
          },
          "DataSetUsageConfiguration": {
            "shape": "S3w"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Arn": {},
          "DataSetId": {},
          "IngestionArn": {},
          "IngestionId": {},
          "RequestId": {},
          "Status": {
            "location": "statusCode",
            "type": "integer"
          }
        }
      }
    },
    "UpdateDataSetPermissions": {
      "http": {
        "requestUri": "/accounts/{AwsAccountId}/data-sets/{DataSetId}/permissions"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId",
          "DataSetId"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "DataSetId": {
            "location": "uri",
            "locationName": "DataSetId"
          },
          "GrantPermissions": {
            "shape": "S10"
          },
          "RevokePermissions": {
            "shape": "S10"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DataSetArn": {},
          "DataSetId": {},
          "RequestId": {},
          "Status": {
            "location": "statusCode",
            "type": "integer"
          }
        }
      }
    },
    "UpdateDataSource": {
      "http": {
        "method": "PUT",
        "requestUri": "/accounts/{AwsAccountId}/data-sources/{DataSourceId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId",
          "DataSourceId",
          "Name"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "DataSourceId": {
            "location": "uri",
            "locationName": "DataSourceId"
          },
          "Name": {},
          "DataSourceParameters": {
            "shape": "S40"
          },
          "Credentials": {
            "shape": "S52"
          },
          "VpcConnectionProperties": {
            "shape": "S58"
          },
          "SslProperties": {
            "shape": "S59"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Arn": {},
          "DataSourceId": {},
          "UpdateStatus": {},
          "RequestId": {},
          "Status": {
            "location": "statusCode",
            "type": "integer"
          }
        }
      }
    },
    "UpdateDataSourcePermissions": {
      "http": {
        "requestUri": "/accounts/{AwsAccountId}/data-sources/{DataSourceId}/permissions"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId",
          "DataSourceId"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "DataSourceId": {
            "location": "uri",
            "locationName": "DataSourceId"
          },
          "GrantPermissions": {
            "shape": "S10"
          },
          "RevokePermissions": {
            "shape": "S10"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DataSourceArn": {},
          "DataSourceId": {},
          "RequestId": {},
          "Status": {
            "location": "statusCode",
            "type": "integer"
          }
        }
      }
    },
    "UpdateFolder": {
      "http": {
        "method": "PUT",
        "requestUri": "/accounts/{AwsAccountId}/folders/{FolderId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId",
          "FolderId",
          "Name"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "FolderId": {
            "location": "uri",
            "locationName": "FolderId"
          },
          "Name": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Status": {
            "location": "statusCode",
            "type": "integer"
          },
          "Arn": {},
          "FolderId": {},
          "RequestId": {}
        }
      }
    },
    "UpdateFolderPermissions": {
      "http": {
        "method": "PUT",
        "requestUri": "/accounts/{AwsAccountId}/folders/{FolderId}/permissions"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId",
          "FolderId"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "FolderId": {
            "location": "uri",
            "locationName": "FolderId"
          },
          "GrantPermissions": {
            "shape": "S10"
          },
          "RevokePermissions": {
            "shape": "S10"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Status": {
            "type": "integer"
          },
          "Arn": {},
          "FolderId": {},
          "Permissions": {
            "shape": "S10"
          },
          "RequestId": {}
        }
      }
    },
    "UpdateGroup": {
      "http": {
        "method": "PUT",
        "requestUri": "/accounts/{AwsAccountId}/namespaces/{Namespace}/groups/{GroupName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "GroupName",
          "AwsAccountId",
          "Namespace"
        ],
        "members": {
          "GroupName": {
            "location": "uri",
            "locationName": "GroupName"
          },
          "Description": {},
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "Namespace": {
            "location": "uri",
            "locationName": "Namespace"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Group": {
            "shape": "S5n"
          },
          "RequestId": {},
          "Status": {
            "location": "statusCode",
            "type": "integer"
          }
        }
      }
    },
    "UpdateIAMPolicyAssignment": {
      "http": {
        "method": "PUT",
        "requestUri": "/accounts/{AwsAccountId}/namespaces/{Namespace}/iam-policy-assignments/{AssignmentName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId",
          "AssignmentName",
          "Namespace"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "AssignmentName": {
            "location": "uri",
            "locationName": "AssignmentName"
          },
          "Namespace": {
            "location": "uri",
            "locationName": "Namespace"
          },
          "AssignmentStatus": {},
          "PolicyArn": {},
          "Identities": {
            "shape": "S5v"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AssignmentName": {},
          "AssignmentId": {},
          "PolicyArn": {},
          "Identities": {
            "shape": "S5v"
          },
          "AssignmentStatus": {},
          "RequestId": {},
          "Status": {
            "location": "statusCode",
            "type": "integer"
          }
        }
      }
    },
    "UpdateIpRestriction": {
      "http": {
        "requestUri": "/accounts/{AwsAccountId}/ip-restriction"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "IpRestrictionRuleMap": {
            "shape": "S9s"
          },
          "Enabled": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AwsAccountId": {},
          "RequestId": {},
          "Status": {
            "location": "statusCode",
            "type": "integer"
          }
        }
      }
    },
    "UpdateTemplate": {
      "http": {
        "method": "PUT",
        "requestUri": "/accounts/{AwsAccountId}/templates/{TemplateId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId",
          "TemplateId",
          "SourceEntity"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "TemplateId": {
            "location": "uri",
            "locationName": "TemplateId"
          },
          "SourceEntity": {
            "shape": "S69"
          },
          "VersionDescription": {},
          "Name": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TemplateId": {},
          "Arn": {},
          "VersionArn": {},
          "CreationStatus": {},
          "Status": {
            "location": "statusCode",
            "type": "integer"
          },
          "RequestId": {}
        }
      }
    },
    "UpdateTemplateAlias": {
      "http": {
        "method": "PUT",
        "requestUri": "/accounts/{AwsAccountId}/templates/{TemplateId}/aliases/{AliasName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId",
          "TemplateId",
          "AliasName",
          "TemplateVersionNumber"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "TemplateId": {
            "location": "uri",
            "locationName": "TemplateId"
          },
          "AliasName": {
            "location": "uri",
            "locationName": "AliasName"
          },
          "TemplateVersionNumber": {
            "type": "long"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TemplateAlias": {
            "shape": "S6h"
          },
          "Status": {
            "location": "statusCode",
            "type": "integer"
          },
          "RequestId": {}
        }
      }
    },
    "UpdateTemplatePermissions": {
      "http": {
        "method": "PUT",
        "requestUri": "/accounts/{AwsAccountId}/templates/{TemplateId}/permissions"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId",
          "TemplateId"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "TemplateId": {
            "location": "uri",
            "locationName": "TemplateId"
          },
          "GrantPermissions": {
            "shape": "Seu"
          },
          "RevokePermissions": {
            "shape": "Seu"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TemplateId": {},
          "TemplateArn": {},
          "Permissions": {
            "shape": "S10"
          },
          "RequestId": {},
          "Status": {
            "location": "statusCode",
            "type": "integer"
          }
        }
      }
    },
    "UpdateTheme": {
      "http": {
        "method": "PUT",
        "requestUri": "/accounts/{AwsAccountId}/themes/{ThemeId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId",
          "ThemeId",
          "BaseThemeId"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "ThemeId": {
            "location": "uri",
            "locationName": "ThemeId"
          },
          "Name": {},
          "BaseThemeId": {},
          "VersionDescription": {},
          "Configuration": {
            "shape": "S6k"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ThemeId": {},
          "Arn": {},
          "VersionArn": {},
          "CreationStatus": {},
          "Status": {
            "location": "statusCode",
            "type": "integer"
          },
          "RequestId": {}
        }
      }
    },
    "UpdateThemeAlias": {
      "http": {
        "method": "PUT",
        "requestUri": "/accounts/{AwsAccountId}/themes/{ThemeId}/aliases/{AliasName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId",
          "ThemeId",
          "AliasName",
          "ThemeVersionNumber"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "ThemeId": {
            "location": "uri",
            "locationName": "ThemeId"
          },
          "AliasName": {
            "location": "uri",
            "locationName": "AliasName"
          },
          "ThemeVersionNumber": {
            "type": "long"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ThemeAlias": {
            "shape": "S6z"
          },
          "Status": {
            "location": "statusCode",
            "type": "integer"
          },
          "RequestId": {}
        }
      }
    },
    "UpdateThemePermissions": {
      "http": {
        "method": "PUT",
        "requestUri": "/accounts/{AwsAccountId}/themes/{ThemeId}/permissions"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId",
          "ThemeId"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "ThemeId": {
            "location": "uri",
            "locationName": "ThemeId"
          },
          "GrantPermissions": {
            "shape": "Seu"
          },
          "RevokePermissions": {
            "shape": "Seu"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ThemeId": {},
          "ThemeArn": {},
          "Permissions": {
            "shape": "S10"
          },
          "RequestId": {},
          "Status": {
            "location": "statusCode",
            "type": "integer"
          }
        }
      }
    },
    "UpdateUser": {
      "http": {
        "method": "PUT",
        "requestUri": "/accounts/{AwsAccountId}/namespaces/{Namespace}/users/{UserName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "UserName",
          "AwsAccountId",
          "Namespace",
          "Email",
          "Role"
        ],
        "members": {
          "UserName": {
            "location": "uri",
            "locationName": "UserName"
          },
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "Namespace": {
            "location": "uri",
            "locationName": "Namespace"
          },
          "Email": {},
          "Role": {},
          "CustomPermissionsName": {},
          "UnapplyCustomPermissions": {
            "type": "boolean"
          },
          "ExternalLoginFederationProviderType": {},
          "CustomFederationProviderUrl": {},
          "ExternalLoginId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "User": {
            "shape": "Sb0"
          },
          "RequestId": {},
          "Status": {
            "location": "statusCode",
            "type": "integer"
          }
        }
      }
    }
  },
  "shapes": {
    "Sa": {
      "type": "structure",
      "members": {
        "DefaultTheme": {}
      }
    },
    "Sb": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "Key",
          "Value"
        ],
        "members": {
          "Key": {},
          "Value": {}
        }
      }
    },
    "Sj": {
      "type": "structure",
      "members": {
        "StringParameters": {
          "type": "list",
          "member": {
            "type": "structure",
            "required": [
              "Name",
              "Values"
            ],
            "members": {
              "Name": {},
              "Values": {
                "type": "list",
                "member": {}
              }
            }
          }
        },
        "IntegerParameters": {
          "type": "list",
          "member": {
            "type": "structure",
            "required": [
              "Name",
              "Values"
            ],
            "members": {
              "Name": {},
              "Values": {
                "type": "list",
                "member": {
                  "type": "long"
                }
              }
            }
          }
        },
        "DecimalParameters": {
          "type": "list",
          "member": {
            "type": "structure",
            "required": [
              "Name",
              "Values"
            ],
            "members": {
              "Name": {},
              "Values": {
                "type": "list",
                "member": {
                  "type": "double"
                }
              }
            }
          }
        },
        "DateTimeParameters": {
          "type": "list",
          "member": {
            "type": "structure",
            "required": [
              "Name",
              "Values"
            ],
            "members": {
              "Name": {},
              "Values": {
                "type": "list",
                "member": {
                  "type": "timestamp"
                }
              }
            }
          }
        }
      }
    },
    "S10": {
      "type": "list",
      "member": {
        "shape": "S11"
      }
    },
    "S11": {
      "type": "structure",
      "required": [
        "Principal",
        "Actions"
      ],
      "members": {
        "Principal": {},
        "Actions": {
          "type": "list",
          "member": {}
        }
      }
    },
    "S14": {
      "type": "structure",
      "members": {
        "SourceTemplate": {
          "type": "structure",
          "required": [
            "DataSetReferences",
            "Arn"
          ],
          "members": {
            "DataSetReferences": {
              "shape": "S16"
            },
            "Arn": {}
          }
        }
      }
    },
    "S16": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "DataSetPlaceholder",
          "DataSetArn"
        ],
        "members": {
          "DataSetPlaceholder": {},
          "DataSetArn": {}
        }
      }
    },
    "S1c": {
      "type": "structure",
      "members": {
        "SourceTemplate": {
          "type": "structure",
          "required": [
            "DataSetReferences",
            "Arn"
          ],
          "members": {
            "DataSetReferences": {
              "shape": "S16"
            },
            "Arn": {}
          }
        }
      }
    },
    "S1f": {
      "type": "structure",
      "members": {
        "AdHocFilteringOption": {
          "type": "structure",
          "members": {
            "AvailabilityStatus": {}
          }
        },
        "ExportToCSVOption": {
          "type": "structure",
          "members": {
            "AvailabilityStatus": {}
          }
        },
        "SheetControlsOption": {
          "type": "structure",
          "members": {
            "VisibilityState": {}
          }
        }
      }
    },
    "S1p": {
      "type": "map",
      "key": {},
      "value": {
        "type": "structure",
        "members": {
          "RelationalTable": {
            "type": "structure",
            "required": [
              "DataSourceArn",
              "Name",
              "InputColumns"
            ],
            "members": {
              "DataSourceArn": {},
              "Catalog": {},
              "Schema": {},
              "Name": {},
              "InputColumns": {
                "shape": "S1w"
              }
            }
          },
          "CustomSql": {
            "type": "structure",
            "required": [
              "DataSourceArn",
              "Name",
              "SqlQuery"
            ],
            "members": {
              "DataSourceArn": {},
              "Name": {},
              "SqlQuery": {},
              "Columns": {
                "shape": "S1w"
              }
            }
          },
          "S3Source": {
            "type": "structure",
            "required": [
              "DataSourceArn",
              "InputColumns"
            ],
            "members": {
              "DataSourceArn": {},
              "UploadSettings": {
                "type": "structure",
                "members": {
                  "Format": {},
                  "StartFromRow": {
                    "type": "integer"
                  },
                  "ContainsHeader": {
                    "type": "boolean"
                  },
                  "TextQualifier": {},
                  "Delimiter": {}
                }
              },
              "InputColumns": {
                "shape": "S1w"
              }
            }
          }
        }
      }
    },
    "S1w": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "Name",
          "Type"
        ],
        "members": {
          "Name": {},
          "Type": {}
        }
      }
    },
    "S2a": {
      "type": "map",
      "key": {},
      "value": {
        "type": "structure",
        "required": [
          "Alias",
          "Source"
        ],
        "members": {
          "Alias": {},
          "DataTransforms": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "ProjectOperation": {
                  "type": "structure",
                  "required": [
                    "ProjectedColumns"
                  ],
                  "members": {
                    "ProjectedColumns": {
                      "type": "list",
                      "member": {}
                    }
                  }
                },
                "FilterOperation": {
                  "type": "structure",
                  "required": [
                    "ConditionExpression"
                  ],
                  "members": {
                    "ConditionExpression": {}
                  }
                },
                "CreateColumnsOperation": {
                  "type": "structure",
                  "required": [
                    "Columns"
                  ],
                  "members": {
                    "Columns": {
                      "type": "list",
                      "member": {
                        "type": "structure",
                        "required": [
                          "ColumnName",
                          "ColumnId",
                          "Expression"
                        ],
                        "members": {
                          "ColumnName": {},
                          "ColumnId": {},
                          "Expression": {}
                        }
                      }
                    }
                  }
                },
                "RenameColumnOperation": {
                  "type": "structure",
                  "required": [
                    "ColumnName",
                    "NewColumnName"
                  ],
                  "members": {
                    "ColumnName": {},
                    "NewColumnName": {}
                  }
                },
                "CastColumnTypeOperation": {
                  "type": "structure",
                  "required": [
                    "ColumnName",
                    "NewColumnType"
                  ],
                  "members": {
                    "ColumnName": {},
                    "NewColumnType": {},
                    "Format": {}
                  }
                },
                "TagColumnOperation": {
                  "type": "structure",
                  "required": [
                    "ColumnName",
                    "Tags"
                  ],
                  "members": {
                    "ColumnName": {},
                    "Tags": {
                      "type": "list",
                      "member": {
                        "type": "structure",
                        "members": {
                          "ColumnGeographicRole": {},
                          "ColumnDescription": {
                            "type": "structure",
                            "members": {
                              "Text": {}
                            }
                          }
                        }
                      }
                    }
                  }
                },
                "UntagColumnOperation": {
                  "type": "structure",
                  "required": [
                    "ColumnName",
                    "TagNames"
                  ],
                  "members": {
                    "ColumnName": {},
                    "TagNames": {
                      "type": "list",
                      "member": {}
                    }
                  }
                }
              }
            }
          },
          "Source": {
            "type": "structure",
            "members": {
              "JoinInstruction": {
                "type": "structure",
                "required": [
                  "LeftOperand",
                  "RightOperand",
                  "Type",
                  "OnClause"
                ],
                "members": {
                  "LeftOperand": {},
                  "RightOperand": {},
                  "LeftJoinKeyProperties": {
                    "shape": "S33"
                  },
                  "RightJoinKeyProperties": {
                    "shape": "S33"
                  },
                  "Type": {},
                  "OnClause": {}
                }
              },
              "PhysicalTableId": {},
              "DataSetArn": {}
            }
          }
        }
      }
    },
    "S33": {
      "type": "structure",
      "members": {
        "UniqueKey": {
          "type": "boolean"
        }
      }
    },
    "S37": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "GeoSpatialColumnGroup": {
            "type": "structure",
            "required": [
              "Name",
              "CountryCode",
              "Columns"
            ],
            "members": {
              "Name": {},
              "CountryCode": {},
              "Columns": {
                "type": "list",
                "member": {}
              }
            }
          }
        }
      }
    },
    "S3d": {
      "type": "map",
      "key": {},
      "value": {
        "type": "structure",
        "members": {
          "description": {},
          "columns": {
            "type": "list",
            "member": {}
          }
        }
      }
    },
    "S3i": {
      "type": "structure",
      "required": [
        "Arn",
        "PermissionPolicy"
      ],
      "members": {
        "Namespace": {},
        "Arn": {},
        "PermissionPolicy": {},
        "FormatVersion": {},
        "Status": {}
      }
    },
    "S3m": {
      "type": "structure",
      "required": [
        "TagRules"
      ],
      "members": {
        "Status": {},
        "TagRules": {
          "type": "list",
          "member": {
            "type": "structure",
            "required": [
              "TagKey",
              "ColumnName"
            ],
            "members": {
              "TagKey": {},
              "ColumnName": {},
              "TagMultiValueDelimiter": {},
              "MatchAllValue": {
                "shape": "S3r"
              }
            }
          }
        }
      }
    },
    "S3r": {
      "type": "string",
      "sensitive": true
    },
    "S3s": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "Principals": {
            "type": "list",
            "member": {}
          },
          "ColumnNames": {
            "type": "list",
            "member": {}
          }
        }
      }
    },
    "S3w": {
      "type": "structure",
      "members": {
        "DisableUseAsDirectQuerySource": {
          "type": "boolean"
        },
        "DisableUseAsImportedSource": {
          "type": "boolean"
        }
      }
    },
    "S40": {
      "type": "structure",
      "members": {
        "AmazonElasticsearchParameters": {
          "type": "structure",
          "required": [
            "Domain"
          ],
          "members": {
            "Domain": {}
          }
        },
        "AthenaParameters": {
          "type": "structure",
          "members": {
            "WorkGroup": {}
          }
        },
        "AuroraParameters": {
          "type": "structure",
          "required": [
            "Host",
            "Port",
            "Database"
          ],
          "members": {
            "Host": {},
            "Port": {
              "type": "integer"
            },
            "Database": {}
          }
        },
        "AuroraPostgreSqlParameters": {
          "type": "structure",
          "required": [
            "Host",
            "Port",
            "Database"
          ],
          "members": {
            "Host": {},
            "Port": {
              "type": "integer"
            },
            "Database": {}
          }
        },
        "AwsIotAnalyticsParameters": {
          "type": "structure",
          "required": [
            "DataSetName"
          ],
          "members": {
            "DataSetName": {}
          }
        },
        "JiraParameters": {
          "type": "structure",
          "required": [
            "SiteBaseUrl"
          ],
          "members": {
            "SiteBaseUrl": {}
          }
        },
        "MariaDbParameters": {
          "type": "structure",
          "required": [
            "Host",
            "Port",
            "Database"
          ],
          "members": {
            "Host": {},
            "Port": {
              "type": "integer"
            },
            "Database": {}
          }
        },
        "MySqlParameters": {
          "type": "structure",
          "required": [
            "Host",
            "Port",
            "Database"
          ],
          "members": {
            "Host": {},
            "Port": {
              "type": "integer"
            },
            "Database": {}
          }
        },
        "OracleParameters": {
          "type": "structure",
          "required": [
            "Host",
            "Port",
            "Database"
          ],
          "members": {
            "Host": {},
            "Port": {
              "type": "integer"
            },
            "Database": {}
          }
        },
        "PostgreSqlParameters": {
          "type": "structure",
          "required": [
            "Host",
            "Port",
            "Database"
          ],
          "members": {
            "Host": {},
            "Port": {
              "type": "integer"
            },
            "Database": {}
          }
        },
        "PrestoParameters": {
          "type": "structure",
          "required": [
            "Host",
            "Port",
            "Catalog"
          ],
          "members": {
            "Host": {},
            "Port": {
              "type": "integer"
            },
            "Catalog": {}
          }
        },
        "RdsParameters": {
          "type": "structure",
          "required": [
            "InstanceId",
            "Database"
          ],
          "members": {
            "InstanceId": {},
            "Database": {}
          }
        },
        "RedshiftParameters": {
          "type": "structure",
          "required": [
            "Database"
          ],
          "members": {
            "Host": {},
            "Port": {
              "type": "integer"
            },
            "Database": {},
            "ClusterId": {}
          }
        },
        "S3Parameters": {
          "type": "structure",
          "required": [
            "ManifestFileLocation"
          ],
          "members": {
            "ManifestFileLocation": {
              "type": "structure",
              "required": [
                "Bucket",
                "Key"
              ],
              "members": {
                "Bucket": {},
                "Key": {}
              }
            }
          }
        },
        "ServiceNowParameters": {
          "type": "structure",
          "required": [
            "SiteBaseUrl"
          ],
          "members": {
            "SiteBaseUrl": {}
          }
        },
        "SnowflakeParameters": {
          "type": "structure",
          "required": [
            "Host",
            "Database",
            "Warehouse"
          ],
          "members": {
            "Host": {},
            "Database": {},
            "Warehouse": {}
          }
        },
        "SparkParameters": {
          "type": "structure",
          "required": [
            "Host",
            "Port"
          ],
          "members": {
            "Host": {},
            "Port": {
              "type": "integer"
            }
          }
        },
        "SqlServerParameters": {
          "type": "structure",
          "required": [
            "Host",
            "Port",
            "Database"
          ],
          "members": {
            "Host": {},
            "Port": {
              "type": "integer"
            },
            "Database": {}
          }
        },
        "TeradataParameters": {
          "type": "structure",
          "required": [
            "Host",
            "Port",
            "Database"
          ],
          "members": {
            "Host": {},
            "Port": {
              "type": "integer"
            },
            "Database": {}
          }
        },
        "TwitterParameters": {
          "type": "structure",
          "required": [
            "Query",
            "MaxRows"
          ],
          "members": {
            "Query": {},
            "MaxRows": {
              "type": "integer"
            }
          }
        },
        "AmazonOpenSearchParameters": {
          "type": "structure",
          "required": [
            "Domain"
          ],
          "members": {
            "Domain": {}
          }
        }
      }
    },
    "S52": {
      "type": "structure",
      "members": {
        "CredentialPair": {
          "type": "structure",
          "required": [
            "Username",
            "Password"
          ],
          "members": {
            "Username": {},
            "Password": {},
            "AlternateDataSourceParameters": {
              "shape": "S56"
            }
          }
        },
        "CopySourceArn": {}
      },
      "sensitive": true
    },
    "S56": {
      "type": "list",
      "member": {
        "shape": "S40"
      }
    },
    "S58": {
      "type": "structure",
      "required": [
        "VpcConnectionArn"
      ],
      "members": {
        "VpcConnectionArn": {}
      }
    },
    "S59": {
      "type": "structure",
      "members": {
        "DisableSsl": {
          "type": "boolean"
        }
      }
    },
    "S5n": {
      "type": "structure",
      "members": {
        "Arn": {},
        "GroupName": {},
        "Description": {},
        "PrincipalId": {}
      }
    },
    "S5r": {
      "type": "structure",
      "members": {
        "Arn": {},
        "MemberName": {}
      }
    },
    "S5v": {
      "type": "map",
      "key": {},
      "value": {
        "type": "list",
        "member": {}
      }
    },
    "S69": {
      "type": "structure",
      "members": {
        "SourceAnalysis": {
          "type": "structure",
          "required": [
            "Arn",
            "DataSetReferences"
          ],
          "members": {
            "Arn": {},
            "DataSetReferences": {
              "shape": "S16"
            }
          }
        },
        "SourceTemplate": {
          "type": "structure",
          "required": [
            "Arn"
          ],
          "members": {
            "Arn": {}
          }
        }
      }
    },
    "S6h": {
      "type": "structure",
      "members": {
        "AliasName": {},
        "Arn": {},
        "TemplateVersionNumber": {
          "type": "long"
        }
      }
    },
    "S6k": {
      "type": "structure",
      "members": {
        "DataColorPalette": {
          "type": "structure",
          "members": {
            "Colors": {
              "shape": "S6m"
            },
            "MinMaxGradient": {
              "shape": "S6m"
            },
            "EmptyFillColor": {}
          }
        },
        "UIColorPalette": {
          "type": "structure",
          "members": {
            "PrimaryForeground": {},
            "PrimaryBackground": {},
            "SecondaryForeground": {},
            "SecondaryBackground": {},
            "Accent": {},
            "AccentForeground": {},
            "Danger": {},
            "DangerForeground": {},
            "Warning": {},
            "WarningForeground": {},
            "Success": {},
            "SuccessForeground": {},
            "Dimension": {},
            "DimensionForeground": {},
            "Measure": {},
            "MeasureForeground": {}
          }
        },
        "Sheet": {
          "type": "structure",
          "members": {
            "Tile": {
              "type": "structure",
              "members": {
                "Border": {
                  "type": "structure",
                  "members": {
                    "Show": {
                      "type": "boolean"
                    }
                  }
                }
              }
            },
            "TileLayout": {
              "type": "structure",
              "members": {
                "Gutter": {
                  "type": "structure",
                  "members": {
                    "Show": {
                      "type": "boolean"
                    }
                  }
                },
                "Margin": {
                  "type": "structure",
                  "members": {
                    "Show": {
                      "type": "boolean"
                    }
                  }
                }
              }
            }
          }
        }
      }
    },
    "S6m": {
      "type": "list",
      "member": {}
    },
    "S6z": {
      "type": "structure",
      "members": {
        "Arn": {},
        "AliasName": {},
        "ThemeVersionNumber": {
          "type": "long"
        }
      }
    },
    "S8c": {
      "type": "list",
      "member": {}
    },
    "S8d": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "SheetId": {},
          "Name": {}
        }
      }
    },
    "S8z": {
      "type": "structure",
      "members": {
        "Arn": {},
        "DataSourceId": {},
        "Name": {},
        "Type": {},
        "Status": {},
        "CreatedTime": {
          "type": "timestamp"
        },
        "LastUpdatedTime": {
          "type": "timestamp"
        },
        "DataSourceParameters": {
          "shape": "S40"
        },
        "AlternateDataSourceParameters": {
          "shape": "S56"
        },
        "VpcConnectionProperties": {
          "shape": "S58"
        },
        "SslProperties": {
          "shape": "S59"
        },
        "ErrorInfo": {
          "type": "structure",
          "members": {
            "Type": {},
            "Message": {}
          }
        }
      }
    },
    "S9j": {
      "type": "structure",
      "required": [
        "Arn",
        "IngestionStatus",
        "CreatedTime"
      ],
      "members": {
        "Arn": {},
        "IngestionId": {},
        "IngestionStatus": {},
        "ErrorInfo": {
          "type": "structure",
          "members": {
            "Type": {},
            "Message": {}
          }
        },
        "RowInfo": {
          "type": "structure",
          "members": {
            "RowsIngested": {
              "type": "long"
            },
            "RowsDropped": {
              "type": "long"
            },
            "TotalRowsInDataset": {
              "type": "long"
            }
          }
        },
        "QueueInfo": {
          "type": "structure",
          "required": [
            "WaitingOnIngestion",
            "QueuedIngestion"
          ],
          "members": {
            "WaitingOnIngestion": {},
            "QueuedIngestion": {}
          }
        },
        "CreatedTime": {
          "type": "timestamp"
        },
        "IngestionTimeInSeconds": {
          "type": "long"
        },
        "IngestionSizeInBytes": {
          "type": "long"
        },
        "RequestSource": {},
        "RequestType": {}
      }
    },
    "S9s": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "S9y": {
      "type": "structure",
      "members": {
        "Name": {},
        "Arn": {},
        "CapacityRegion": {},
        "CreationStatus": {},
        "IdentityStore": {},
        "NamespaceError": {
          "type": "structure",
          "members": {
            "Type": {},
            "Message": {}
          }
        }
      }
    },
    "Sb0": {
      "type": "structure",
      "members": {
        "Arn": {},
        "UserName": {},
        "Email": {},
        "Role": {},
        "IdentityType": {},
        "Active": {
          "type": "boolean"
        },
        "PrincipalId": {},
        "CustomPermissionsName": {},
        "ExternalLoginFederationProviderType": {},
        "ExternalLoginFederationProviderUrl": {},
        "ExternalLoginId": {}
      }
    },
    "Sbc": {
      "type": "string",
      "sensitive": true
    },
    "Sbu": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "Arn": {},
          "AnalysisId": {},
          "Name": {},
          "Status": {},
          "CreatedTime": {
            "type": "timestamp"
          },
          "LastUpdatedTime": {
            "type": "timestamp"
          }
        }
      }
    },
    "Sc2": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "Arn": {},
          "DashboardId": {},
          "Name": {},
          "CreatedTime": {
            "type": "timestamp"
          },
          "LastUpdatedTime": {
            "type": "timestamp"
          },
          "PublishedVersionNumber": {
            "type": "long"
          },
          "LastPublishedTime": {
            "type": "timestamp"
          }
        }
      }
    },
    "Sch": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "Arn": {},
          "FolderId": {},
          "Name": {},
          "FolderType": {},
          "CreatedTime": {
            "type": "timestamp"
          },
          "LastUpdatedTime": {
            "type": "timestamp"
          }
        }
      }
    },
    "Sco": {
      "type": "list",
      "member": {
        "shape": "S5n"
      }
    },
    "Seu": {
      "type": "list",
      "member": {
        "shape": "S11"
      }
    }
  }
}