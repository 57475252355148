{
  "version": "2.0",
  "metadata": {
    "apiVersion": "2021-01-01",
    "endpointPrefix": "es",
    "protocol": "rest-json",
    "serviceFullName": "Amazon OpenSearch Service",
    "serviceId": "OpenSearch",
    "signatureVersion": "v4",
    "uid": "opensearch-2021-01-01"
  },
  "operations": {
    "AcceptInboundConnection": {
      "http": {
        "method": "PUT",
        "requestUri": "/2021-01-01/opensearch/cc/inboundConnection/{ConnectionId}/accept"
      },
      "input": {
        "type": "structure",
        "required": [
          "ConnectionId"
        ],
        "members": {
          "ConnectionId": {
            "location": "uri",
            "locationName": "ConnectionId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Connection": {
            "shape": "S4"
          }
        }
      }
    },
    "AddTags": {
      "http": {
        "requestUri": "/2021-01-01/tags"
      },
      "input": {
        "type": "structure",
        "required": [
          "ARN",
          "TagList"
        ],
        "members": {
          "ARN": {},
          "TagList": {
            "shape": "Sf"
          }
        }
      }
    },
    "AssociatePackage": {
      "http": {
        "requestUri": "/2021-01-01/packages/associate/{PackageID}/{DomainName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "PackageID",
          "DomainName"
        ],
        "members": {
          "PackageID": {
            "location": "uri",
            "locationName": "PackageID"
          },
          "DomainName": {
            "location": "uri",
            "locationName": "DomainName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DomainPackageDetails": {
            "shape": "Sm"
          }
        }
      }
    },
    "CancelServiceSoftwareUpdate": {
      "http": {
        "requestUri": "/2021-01-01/opensearch/serviceSoftwareUpdate/cancel"
      },
      "input": {
        "type": "structure",
        "required": [
          "DomainName"
        ],
        "members": {
          "DomainName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ServiceSoftwareOptions": {
            "shape": "Sy"
          }
        }
      }
    },
    "CreateDomain": {
      "http": {
        "requestUri": "/2021-01-01/opensearch/domain"
      },
      "input": {
        "type": "structure",
        "required": [
          "DomainName"
        ],
        "members": {
          "DomainName": {},
          "EngineVersion": {},
          "ClusterConfig": {
            "shape": "S15"
          },
          "EBSOptions": {
            "shape": "S1b"
          },
          "AccessPolicies": {},
          "SnapshotOptions": {
            "shape": "S1e"
          },
          "VPCOptions": {
            "shape": "S1f"
          },
          "CognitoOptions": {
            "shape": "S1h"
          },
          "EncryptionAtRestOptions": {
            "shape": "S1l"
          },
          "NodeToNodeEncryptionOptions": {
            "shape": "S1n"
          },
          "AdvancedOptions": {
            "shape": "S1o"
          },
          "LogPublishingOptions": {
            "shape": "S1p"
          },
          "DomainEndpointOptions": {
            "shape": "S1t"
          },
          "AdvancedSecurityOptions": {
            "shape": "S1w"
          },
          "TagList": {
            "shape": "Sf"
          },
          "AutoTuneOptions": {
            "type": "structure",
            "members": {
              "DesiredState": {},
              "MaintenanceSchedules": {
                "shape": "S27"
              }
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DomainStatus": {
            "shape": "S2e"
          }
        }
      }
    },
    "CreateOutboundConnection": {
      "http": {
        "requestUri": "/2021-01-01/opensearch/cc/outboundConnection"
      },
      "input": {
        "type": "structure",
        "required": [
          "LocalDomainInfo",
          "RemoteDomainInfo",
          "ConnectionAlias"
        ],
        "members": {
          "LocalDomainInfo": {
            "shape": "S5"
          },
          "RemoteDomainInfo": {
            "shape": "S5"
          },
          "ConnectionAlias": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "LocalDomainInfo": {
            "shape": "S5"
          },
          "RemoteDomainInfo": {
            "shape": "S5"
          },
          "ConnectionAlias": {},
          "ConnectionStatus": {
            "shape": "S2q"
          },
          "ConnectionId": {}
        }
      }
    },
    "CreatePackage": {
      "http": {
        "requestUri": "/2021-01-01/packages"
      },
      "input": {
        "type": "structure",
        "required": [
          "PackageName",
          "PackageType",
          "PackageSource"
        ],
        "members": {
          "PackageName": {},
          "PackageType": {},
          "PackageDescription": {},
          "PackageSource": {
            "shape": "S2u"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "PackageDetails": {
            "shape": "S2y"
          }
        }
      }
    },
    "DeleteDomain": {
      "http": {
        "method": "DELETE",
        "requestUri": "/2021-01-01/opensearch/domain/{DomainName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "DomainName"
        ],
        "members": {
          "DomainName": {
            "location": "uri",
            "locationName": "DomainName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DomainStatus": {
            "shape": "S2e"
          }
        }
      }
    },
    "DeleteInboundConnection": {
      "http": {
        "method": "DELETE",
        "requestUri": "/2021-01-01/opensearch/cc/inboundConnection/{ConnectionId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "ConnectionId"
        ],
        "members": {
          "ConnectionId": {
            "location": "uri",
            "locationName": "ConnectionId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Connection": {
            "shape": "S4"
          }
        }
      }
    },
    "DeleteOutboundConnection": {
      "http": {
        "method": "DELETE",
        "requestUri": "/2021-01-01/opensearch/cc/outboundConnection/{ConnectionId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "ConnectionId"
        ],
        "members": {
          "ConnectionId": {
            "location": "uri",
            "locationName": "ConnectionId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Connection": {
            "shape": "S37"
          }
        }
      }
    },
    "DeletePackage": {
      "http": {
        "method": "DELETE",
        "requestUri": "/2021-01-01/packages/{PackageID}"
      },
      "input": {
        "type": "structure",
        "required": [
          "PackageID"
        ],
        "members": {
          "PackageID": {
            "location": "uri",
            "locationName": "PackageID"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "PackageDetails": {
            "shape": "S2y"
          }
        }
      }
    },
    "DescribeDomain": {
      "http": {
        "method": "GET",
        "requestUri": "/2021-01-01/opensearch/domain/{DomainName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "DomainName"
        ],
        "members": {
          "DomainName": {
            "location": "uri",
            "locationName": "DomainName"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "DomainStatus"
        ],
        "members": {
          "DomainStatus": {
            "shape": "S2e"
          }
        }
      }
    },
    "DescribeDomainAutoTunes": {
      "http": {
        "method": "GET",
        "requestUri": "/2021-01-01/opensearch/domain/{DomainName}/autoTunes"
      },
      "input": {
        "type": "structure",
        "required": [
          "DomainName"
        ],
        "members": {
          "DomainName": {
            "location": "uri",
            "locationName": "DomainName"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AutoTunes": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "AutoTuneType": {},
                "AutoTuneDetails": {
                  "type": "structure",
                  "members": {
                    "ScheduledAutoTuneDetails": {
                      "type": "structure",
                      "members": {
                        "Date": {
                          "type": "timestamp"
                        },
                        "ActionType": {},
                        "Action": {},
                        "Severity": {}
                      }
                    }
                  }
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribeDomainConfig": {
      "http": {
        "method": "GET",
        "requestUri": "/2021-01-01/opensearch/domain/{DomainName}/config"
      },
      "input": {
        "type": "structure",
        "required": [
          "DomainName"
        ],
        "members": {
          "DomainName": {
            "location": "uri",
            "locationName": "DomainName"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "DomainConfig"
        ],
        "members": {
          "DomainConfig": {
            "shape": "S3r"
          }
        }
      }
    },
    "DescribeDomains": {
      "http": {
        "requestUri": "/2021-01-01/opensearch/domain-info"
      },
      "input": {
        "type": "structure",
        "required": [
          "DomainNames"
        ],
        "members": {
          "DomainNames": {
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "DomainStatusList"
        ],
        "members": {
          "DomainStatusList": {
            "type": "list",
            "member": {
              "shape": "S2e"
            }
          }
        }
      }
    },
    "DescribeInboundConnections": {
      "http": {
        "requestUri": "/2021-01-01/opensearch/cc/inboundConnection/search"
      },
      "input": {
        "type": "structure",
        "members": {
          "Filters": {
            "shape": "S4i"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Connections": {
            "type": "list",
            "member": {
              "shape": "S4"
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribeInstanceTypeLimits": {
      "http": {
        "method": "GET",
        "requestUri": "/2021-01-01/opensearch/instanceTypeLimits/{EngineVersion}/{InstanceType}"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceType",
          "EngineVersion"
        ],
        "members": {
          "DomainName": {
            "location": "querystring",
            "locationName": "domainName"
          },
          "InstanceType": {
            "location": "uri",
            "locationName": "InstanceType"
          },
          "EngineVersion": {
            "location": "uri",
            "locationName": "EngineVersion"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "LimitsByRole": {
            "type": "map",
            "key": {},
            "value": {
              "type": "structure",
              "members": {
                "StorageTypes": {
                  "type": "list",
                  "member": {
                    "type": "structure",
                    "members": {
                      "StorageTypeName": {},
                      "StorageSubTypeName": {},
                      "StorageTypeLimits": {
                        "type": "list",
                        "member": {
                          "type": "structure",
                          "members": {
                            "LimitName": {},
                            "LimitValues": {
                              "shape": "S50"
                            }
                          }
                        }
                      }
                    }
                  }
                },
                "InstanceLimits": {
                  "type": "structure",
                  "members": {
                    "InstanceCountLimits": {
                      "type": "structure",
                      "members": {
                        "MinimumInstanceCount": {
                          "type": "integer"
                        },
                        "MaximumInstanceCount": {
                          "type": "integer"
                        }
                      }
                    }
                  }
                },
                "AdditionalLimits": {
                  "type": "list",
                  "member": {
                    "type": "structure",
                    "members": {
                      "LimitName": {},
                      "LimitValues": {
                        "shape": "S50"
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    },
    "DescribeOutboundConnections": {
      "http": {
        "requestUri": "/2021-01-01/opensearch/cc/outboundConnection/search"
      },
      "input": {
        "type": "structure",
        "members": {
          "Filters": {
            "shape": "S4i"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Connections": {
            "type": "list",
            "member": {
              "shape": "S37"
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribePackages": {
      "http": {
        "requestUri": "/2021-01-01/packages/describe"
      },
      "input": {
        "type": "structure",
        "members": {
          "Filters": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Name": {},
                "Value": {
                  "type": "list",
                  "member": {}
                }
              }
            }
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "PackageDetailsList": {
            "type": "list",
            "member": {
              "shape": "S2y"
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribeReservedInstanceOfferings": {
      "http": {
        "method": "GET",
        "requestUri": "/2021-01-01/opensearch/reservedInstanceOfferings"
      },
      "input": {
        "type": "structure",
        "members": {
          "ReservedInstanceOfferingId": {
            "location": "querystring",
            "locationName": "offeringId"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "ReservedInstanceOfferings": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "ReservedInstanceOfferingId": {},
                "InstanceType": {},
                "Duration": {
                  "type": "integer"
                },
                "FixedPrice": {
                  "type": "double"
                },
                "UsagePrice": {
                  "type": "double"
                },
                "CurrencyCode": {},
                "PaymentOption": {},
                "RecurringCharges": {
                  "shape": "S5r"
                }
              }
            }
          }
        }
      }
    },
    "DescribeReservedInstances": {
      "http": {
        "method": "GET",
        "requestUri": "/2021-01-01/opensearch/reservedInstances"
      },
      "input": {
        "type": "structure",
        "members": {
          "ReservedInstanceId": {
            "location": "querystring",
            "locationName": "reservationId"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "ReservedInstances": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "ReservationName": {},
                "ReservedInstanceId": {},
                "BillingSubscriptionId": {
                  "type": "long"
                },
                "ReservedInstanceOfferingId": {},
                "InstanceType": {},
                "StartTime": {
                  "type": "timestamp"
                },
                "Duration": {
                  "type": "integer"
                },
                "FixedPrice": {
                  "type": "double"
                },
                "UsagePrice": {
                  "type": "double"
                },
                "CurrencyCode": {},
                "InstanceCount": {
                  "type": "integer"
                },
                "State": {},
                "PaymentOption": {},
                "RecurringCharges": {
                  "shape": "S5r"
                }
              }
            }
          }
        }
      }
    },
    "DissociatePackage": {
      "http": {
        "requestUri": "/2021-01-01/packages/dissociate/{PackageID}/{DomainName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "PackageID",
          "DomainName"
        ],
        "members": {
          "PackageID": {
            "location": "uri",
            "locationName": "PackageID"
          },
          "DomainName": {
            "location": "uri",
            "locationName": "DomainName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DomainPackageDetails": {
            "shape": "Sm"
          }
        }
      }
    },
    "GetCompatibleVersions": {
      "http": {
        "method": "GET",
        "requestUri": "/2021-01-01/opensearch/compatibleVersions"
      },
      "input": {
        "type": "structure",
        "members": {
          "DomainName": {
            "location": "querystring",
            "locationName": "domainName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "CompatibleVersions": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "SourceVersion": {},
                "TargetVersions": {
                  "shape": "S65"
                }
              }
            }
          }
        }
      }
    },
    "GetPackageVersionHistory": {
      "http": {
        "method": "GET",
        "requestUri": "/2021-01-01/packages/{PackageID}/history"
      },
      "input": {
        "type": "structure",
        "required": [
          "PackageID"
        ],
        "members": {
          "PackageID": {
            "location": "uri",
            "locationName": "PackageID"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "PackageID": {},
          "PackageVersionHistoryList": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "PackageVersion": {},
                "CommitMessage": {},
                "CreatedAt": {
                  "type": "timestamp"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "GetUpgradeHistory": {
      "http": {
        "method": "GET",
        "requestUri": "/2021-01-01/opensearch/upgradeDomain/{DomainName}/history"
      },
      "input": {
        "type": "structure",
        "required": [
          "DomainName"
        ],
        "members": {
          "DomainName": {
            "location": "uri",
            "locationName": "DomainName"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "UpgradeHistories": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "UpgradeName": {},
                "StartTimestamp": {
                  "type": "timestamp"
                },
                "UpgradeStatus": {},
                "StepsList": {
                  "type": "list",
                  "member": {
                    "type": "structure",
                    "members": {
                      "UpgradeStep": {},
                      "UpgradeStepStatus": {},
                      "Issues": {
                        "type": "list",
                        "member": {}
                      },
                      "ProgressPercent": {
                        "type": "double"
                      }
                    }
                  }
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "GetUpgradeStatus": {
      "http": {
        "method": "GET",
        "requestUri": "/2021-01-01/opensearch/upgradeDomain/{DomainName}/status"
      },
      "input": {
        "type": "structure",
        "required": [
          "DomainName"
        ],
        "members": {
          "DomainName": {
            "location": "uri",
            "locationName": "DomainName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "UpgradeStep": {},
          "StepStatus": {},
          "UpgradeName": {}
        }
      }
    },
    "ListDomainNames": {
      "http": {
        "method": "GET",
        "requestUri": "/2021-01-01/domain"
      },
      "input": {
        "type": "structure",
        "members": {
          "EngineType": {
            "location": "querystring",
            "locationName": "engineType"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DomainNames": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "DomainName": {},
                "EngineType": {}
              }
            }
          }
        }
      }
    },
    "ListDomainsForPackage": {
      "http": {
        "method": "GET",
        "requestUri": "/2021-01-01/packages/{PackageID}/domains"
      },
      "input": {
        "type": "structure",
        "required": [
          "PackageID"
        ],
        "members": {
          "PackageID": {
            "location": "uri",
            "locationName": "PackageID"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DomainPackageDetailsList": {
            "shape": "S6w"
          },
          "NextToken": {}
        }
      }
    },
    "ListInstanceTypeDetails": {
      "http": {
        "method": "GET",
        "requestUri": "/2021-01-01/opensearch/instanceTypeDetails/{EngineVersion}"
      },
      "input": {
        "type": "structure",
        "required": [
          "EngineVersion"
        ],
        "members": {
          "EngineVersion": {
            "location": "uri",
            "locationName": "EngineVersion"
          },
          "DomainName": {
            "location": "querystring",
            "locationName": "domainName"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "InstanceTypeDetails": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "InstanceType": {},
                "EncryptionEnabled": {
                  "type": "boolean"
                },
                "CognitoEnabled": {
                  "type": "boolean"
                },
                "AppLogsEnabled": {
                  "type": "boolean"
                },
                "AdvancedSecurityEnabled": {
                  "type": "boolean"
                },
                "WarmEnabled": {
                  "type": "boolean"
                },
                "InstanceRole": {
                  "type": "list",
                  "member": {}
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListPackagesForDomain": {
      "http": {
        "method": "GET",
        "requestUri": "/2021-01-01/domain/{DomainName}/packages"
      },
      "input": {
        "type": "structure",
        "required": [
          "DomainName"
        ],
        "members": {
          "DomainName": {
            "location": "uri",
            "locationName": "DomainName"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DomainPackageDetailsList": {
            "shape": "S6w"
          },
          "NextToken": {}
        }
      }
    },
    "ListTags": {
      "http": {
        "method": "GET",
        "requestUri": "/2021-01-01/tags/"
      },
      "input": {
        "type": "structure",
        "required": [
          "ARN"
        ],
        "members": {
          "ARN": {
            "location": "querystring",
            "locationName": "arn"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TagList": {
            "shape": "Sf"
          }
        }
      }
    },
    "ListVersions": {
      "http": {
        "method": "GET",
        "requestUri": "/2021-01-01/opensearch/versions"
      },
      "input": {
        "type": "structure",
        "members": {
          "MaxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Versions": {
            "shape": "S65"
          },
          "NextToken": {}
        }
      }
    },
    "PurchaseReservedInstanceOffering": {
      "http": {
        "requestUri": "/2021-01-01/opensearch/purchaseReservedInstanceOffering"
      },
      "input": {
        "type": "structure",
        "required": [
          "ReservedInstanceOfferingId",
          "ReservationName"
        ],
        "members": {
          "ReservedInstanceOfferingId": {},
          "ReservationName": {},
          "InstanceCount": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ReservedInstanceId": {},
          "ReservationName": {}
        }
      }
    },
    "RejectInboundConnection": {
      "http": {
        "method": "PUT",
        "requestUri": "/2021-01-01/opensearch/cc/inboundConnection/{ConnectionId}/reject"
      },
      "input": {
        "type": "structure",
        "required": [
          "ConnectionId"
        ],
        "members": {
          "ConnectionId": {
            "location": "uri",
            "locationName": "ConnectionId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Connection": {
            "shape": "S4"
          }
        }
      }
    },
    "RemoveTags": {
      "http": {
        "requestUri": "/2021-01-01/tags-removal"
      },
      "input": {
        "type": "structure",
        "required": [
          "ARN",
          "TagKeys"
        ],
        "members": {
          "ARN": {},
          "TagKeys": {
            "shape": "S1g"
          }
        }
      }
    },
    "StartServiceSoftwareUpdate": {
      "http": {
        "requestUri": "/2021-01-01/opensearch/serviceSoftwareUpdate/start"
      },
      "input": {
        "type": "structure",
        "required": [
          "DomainName"
        ],
        "members": {
          "DomainName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ServiceSoftwareOptions": {
            "shape": "Sy"
          }
        }
      }
    },
    "UpdateDomainConfig": {
      "http": {
        "requestUri": "/2021-01-01/opensearch/domain/{DomainName}/config"
      },
      "input": {
        "type": "structure",
        "required": [
          "DomainName"
        ],
        "members": {
          "DomainName": {
            "location": "uri",
            "locationName": "DomainName"
          },
          "ClusterConfig": {
            "shape": "S15"
          },
          "EBSOptions": {
            "shape": "S1b"
          },
          "SnapshotOptions": {
            "shape": "S1e"
          },
          "VPCOptions": {
            "shape": "S1f"
          },
          "CognitoOptions": {
            "shape": "S1h"
          },
          "AdvancedOptions": {
            "shape": "S1o"
          },
          "AccessPolicies": {},
          "LogPublishingOptions": {
            "shape": "S1p"
          },
          "EncryptionAtRestOptions": {
            "shape": "S1l"
          },
          "DomainEndpointOptions": {
            "shape": "S1t"
          },
          "NodeToNodeEncryptionOptions": {
            "shape": "S1n"
          },
          "AdvancedSecurityOptions": {
            "shape": "S1w"
          },
          "AutoTuneOptions": {
            "shape": "S4a"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "DomainConfig"
        ],
        "members": {
          "DomainConfig": {
            "shape": "S3r"
          }
        }
      }
    },
    "UpdatePackage": {
      "http": {
        "requestUri": "/2021-01-01/packages/update"
      },
      "input": {
        "type": "structure",
        "required": [
          "PackageID",
          "PackageSource"
        ],
        "members": {
          "PackageID": {},
          "PackageSource": {
            "shape": "S2u"
          },
          "PackageDescription": {},
          "CommitMessage": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "PackageDetails": {
            "shape": "S2y"
          }
        }
      }
    },
    "UpgradeDomain": {
      "http": {
        "requestUri": "/2021-01-01/opensearch/upgradeDomain"
      },
      "input": {
        "type": "structure",
        "required": [
          "DomainName",
          "TargetVersion"
        ],
        "members": {
          "DomainName": {},
          "TargetVersion": {},
          "PerformCheckOnly": {
            "type": "boolean"
          },
          "AdvancedOptions": {
            "shape": "S1o"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "UpgradeId": {},
          "DomainName": {},
          "TargetVersion": {},
          "PerformCheckOnly": {
            "type": "boolean"
          },
          "AdvancedOptions": {
            "shape": "S1o"
          }
        }
      }
    }
  },
  "shapes": {
    "S4": {
      "type": "structure",
      "members": {
        "LocalDomainInfo": {
          "shape": "S5"
        },
        "RemoteDomainInfo": {
          "shape": "S5"
        },
        "ConnectionId": {},
        "ConnectionStatus": {
          "type": "structure",
          "members": {
            "StatusCode": {},
            "Message": {}
          }
        }
      }
    },
    "S5": {
      "type": "structure",
      "members": {
        "AWSDomainInformation": {
          "type": "structure",
          "required": [
            "DomainName"
          ],
          "members": {
            "OwnerId": {},
            "DomainName": {},
            "Region": {}
          }
        }
      }
    },
    "Sf": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "Key",
          "Value"
        ],
        "members": {
          "Key": {},
          "Value": {}
        }
      }
    },
    "Sm": {
      "type": "structure",
      "members": {
        "PackageID": {},
        "PackageName": {},
        "PackageType": {},
        "LastUpdated": {
          "type": "timestamp"
        },
        "DomainName": {},
        "DomainPackageStatus": {},
        "PackageVersion": {},
        "ReferencePath": {},
        "ErrorDetails": {
          "shape": "St"
        }
      }
    },
    "St": {
      "type": "structure",
      "members": {
        "ErrorType": {},
        "ErrorMessage": {}
      }
    },
    "Sy": {
      "type": "structure",
      "members": {
        "CurrentVersion": {},
        "NewVersion": {},
        "UpdateAvailable": {
          "type": "boolean"
        },
        "Cancellable": {
          "type": "boolean"
        },
        "UpdateStatus": {},
        "Description": {},
        "AutomatedUpdateDate": {
          "type": "timestamp"
        },
        "OptionalDeployment": {
          "type": "boolean"
        }
      }
    },
    "S15": {
      "type": "structure",
      "members": {
        "InstanceType": {},
        "InstanceCount": {
          "type": "integer"
        },
        "DedicatedMasterEnabled": {
          "type": "boolean"
        },
        "ZoneAwarenessEnabled": {
          "type": "boolean"
        },
        "ZoneAwarenessConfig": {
          "type": "structure",
          "members": {
            "AvailabilityZoneCount": {
              "type": "integer"
            }
          }
        },
        "DedicatedMasterType": {},
        "DedicatedMasterCount": {
          "type": "integer"
        },
        "WarmEnabled": {
          "type": "boolean"
        },
        "WarmType": {},
        "WarmCount": {
          "type": "integer"
        },
        "ColdStorageOptions": {
          "type": "structure",
          "required": [
            "Enabled"
          ],
          "members": {
            "Enabled": {
              "type": "boolean"
            }
          }
        }
      }
    },
    "S1b": {
      "type": "structure",
      "members": {
        "EBSEnabled": {
          "type": "boolean"
        },
        "VolumeType": {},
        "VolumeSize": {
          "type": "integer"
        },
        "Iops": {
          "type": "integer"
        }
      }
    },
    "S1e": {
      "type": "structure",
      "members": {
        "AutomatedSnapshotStartHour": {
          "type": "integer"
        }
      }
    },
    "S1f": {
      "type": "structure",
      "members": {
        "SubnetIds": {
          "shape": "S1g"
        },
        "SecurityGroupIds": {
          "shape": "S1g"
        }
      }
    },
    "S1g": {
      "type": "list",
      "member": {}
    },
    "S1h": {
      "type": "structure",
      "members": {
        "Enabled": {
          "type": "boolean"
        },
        "UserPoolId": {},
        "IdentityPoolId": {},
        "RoleArn": {}
      }
    },
    "S1l": {
      "type": "structure",
      "members": {
        "Enabled": {
          "type": "boolean"
        },
        "KmsKeyId": {}
      }
    },
    "S1n": {
      "type": "structure",
      "members": {
        "Enabled": {
          "type": "boolean"
        }
      }
    },
    "S1o": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "S1p": {
      "type": "map",
      "key": {},
      "value": {
        "type": "structure",
        "members": {
          "CloudWatchLogsLogGroupArn": {},
          "Enabled": {
            "type": "boolean"
          }
        }
      }
    },
    "S1t": {
      "type": "structure",
      "members": {
        "EnforceHTTPS": {
          "type": "boolean"
        },
        "TLSSecurityPolicy": {},
        "CustomEndpointEnabled": {
          "type": "boolean"
        },
        "CustomEndpoint": {},
        "CustomEndpointCertificateArn": {}
      }
    },
    "S1w": {
      "type": "structure",
      "members": {
        "Enabled": {
          "type": "boolean"
        },
        "InternalUserDatabaseEnabled": {
          "type": "boolean"
        },
        "MasterUserOptions": {
          "type": "structure",
          "members": {
            "MasterUserARN": {},
            "MasterUserName": {
              "shape": "S1y"
            },
            "MasterUserPassword": {
              "type": "string",
              "sensitive": true
            }
          }
        },
        "SAMLOptions": {
          "type": "structure",
          "members": {
            "Enabled": {
              "type": "boolean"
            },
            "Idp": {
              "shape": "S21"
            },
            "MasterUserName": {
              "shape": "S1y"
            },
            "MasterBackendRole": {},
            "SubjectKey": {},
            "RolesKey": {},
            "SessionTimeoutMinutes": {
              "type": "integer"
            }
          }
        }
      }
    },
    "S1y": {
      "type": "string",
      "sensitive": true
    },
    "S21": {
      "type": "structure",
      "required": [
        "MetadataContent",
        "EntityId"
      ],
      "members": {
        "MetadataContent": {},
        "EntityId": {}
      }
    },
    "S27": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "StartAt": {
            "type": "timestamp"
          },
          "Duration": {
            "type": "structure",
            "members": {
              "Value": {
                "type": "long"
              },
              "Unit": {}
            }
          },
          "CronExpressionForRecurrence": {}
        }
      }
    },
    "S2e": {
      "type": "structure",
      "required": [
        "DomainId",
        "DomainName",
        "ARN",
        "ClusterConfig"
      ],
      "members": {
        "DomainId": {},
        "DomainName": {},
        "ARN": {},
        "Created": {
          "type": "boolean"
        },
        "Deleted": {
          "type": "boolean"
        },
        "Endpoint": {},
        "Endpoints": {
          "type": "map",
          "key": {},
          "value": {}
        },
        "Processing": {
          "type": "boolean"
        },
        "UpgradeProcessing": {
          "type": "boolean"
        },
        "EngineVersion": {},
        "ClusterConfig": {
          "shape": "S15"
        },
        "EBSOptions": {
          "shape": "S1b"
        },
        "AccessPolicies": {},
        "SnapshotOptions": {
          "shape": "S1e"
        },
        "VPCOptions": {
          "shape": "S2i"
        },
        "CognitoOptions": {
          "shape": "S1h"
        },
        "EncryptionAtRestOptions": {
          "shape": "S1l"
        },
        "NodeToNodeEncryptionOptions": {
          "shape": "S1n"
        },
        "AdvancedOptions": {
          "shape": "S1o"
        },
        "LogPublishingOptions": {
          "shape": "S1p"
        },
        "ServiceSoftwareOptions": {
          "shape": "Sy"
        },
        "DomainEndpointOptions": {
          "shape": "S1t"
        },
        "AdvancedSecurityOptions": {
          "shape": "S2j"
        },
        "AutoTuneOptions": {
          "type": "structure",
          "members": {
            "State": {},
            "ErrorMessage": {}
          }
        }
      }
    },
    "S2i": {
      "type": "structure",
      "members": {
        "VPCId": {},
        "SubnetIds": {
          "shape": "S1g"
        },
        "AvailabilityZones": {
          "shape": "S1g"
        },
        "SecurityGroupIds": {
          "shape": "S1g"
        }
      }
    },
    "S2j": {
      "type": "structure",
      "members": {
        "Enabled": {
          "type": "boolean"
        },
        "InternalUserDatabaseEnabled": {
          "type": "boolean"
        },
        "SAMLOptions": {
          "type": "structure",
          "members": {
            "Enabled": {
              "type": "boolean"
            },
            "Idp": {
              "shape": "S21"
            },
            "SubjectKey": {},
            "RolesKey": {},
            "SessionTimeoutMinutes": {
              "type": "integer"
            }
          }
        }
      }
    },
    "S2q": {
      "type": "structure",
      "members": {
        "StatusCode": {},
        "Message": {}
      }
    },
    "S2u": {
      "type": "structure",
      "members": {
        "S3BucketName": {},
        "S3Key": {}
      }
    },
    "S2y": {
      "type": "structure",
      "members": {
        "PackageID": {},
        "PackageName": {},
        "PackageType": {},
        "PackageDescription": {},
        "PackageStatus": {},
        "CreatedAt": {
          "type": "timestamp"
        },
        "LastUpdatedAt": {
          "type": "timestamp"
        },
        "AvailablePackageVersion": {},
        "ErrorDetails": {
          "shape": "St"
        }
      }
    },
    "S37": {
      "type": "structure",
      "members": {
        "LocalDomainInfo": {
          "shape": "S5"
        },
        "RemoteDomainInfo": {
          "shape": "S5"
        },
        "ConnectionId": {},
        "ConnectionAlias": {},
        "ConnectionStatus": {
          "shape": "S2q"
        }
      }
    },
    "S3r": {
      "type": "structure",
      "members": {
        "EngineVersion": {
          "type": "structure",
          "required": [
            "Options",
            "Status"
          ],
          "members": {
            "Options": {},
            "Status": {
              "shape": "S3t"
            }
          }
        },
        "ClusterConfig": {
          "type": "structure",
          "required": [
            "Options",
            "Status"
          ],
          "members": {
            "Options": {
              "shape": "S15"
            },
            "Status": {
              "shape": "S3t"
            }
          }
        },
        "EBSOptions": {
          "type": "structure",
          "required": [
            "Options",
            "Status"
          ],
          "members": {
            "Options": {
              "shape": "S1b"
            },
            "Status": {
              "shape": "S3t"
            }
          }
        },
        "AccessPolicies": {
          "type": "structure",
          "required": [
            "Options",
            "Status"
          ],
          "members": {
            "Options": {},
            "Status": {
              "shape": "S3t"
            }
          }
        },
        "SnapshotOptions": {
          "type": "structure",
          "required": [
            "Options",
            "Status"
          ],
          "members": {
            "Options": {
              "shape": "S1e"
            },
            "Status": {
              "shape": "S3t"
            }
          }
        },
        "VPCOptions": {
          "type": "structure",
          "required": [
            "Options",
            "Status"
          ],
          "members": {
            "Options": {
              "shape": "S2i"
            },
            "Status": {
              "shape": "S3t"
            }
          }
        },
        "CognitoOptions": {
          "type": "structure",
          "required": [
            "Options",
            "Status"
          ],
          "members": {
            "Options": {
              "shape": "S1h"
            },
            "Status": {
              "shape": "S3t"
            }
          }
        },
        "EncryptionAtRestOptions": {
          "type": "structure",
          "required": [
            "Options",
            "Status"
          ],
          "members": {
            "Options": {
              "shape": "S1l"
            },
            "Status": {
              "shape": "S3t"
            }
          }
        },
        "NodeToNodeEncryptionOptions": {
          "type": "structure",
          "required": [
            "Options",
            "Status"
          ],
          "members": {
            "Options": {
              "shape": "S1n"
            },
            "Status": {
              "shape": "S3t"
            }
          }
        },
        "AdvancedOptions": {
          "type": "structure",
          "required": [
            "Options",
            "Status"
          ],
          "members": {
            "Options": {
              "shape": "S1o"
            },
            "Status": {
              "shape": "S3t"
            }
          }
        },
        "LogPublishingOptions": {
          "type": "structure",
          "members": {
            "Options": {
              "shape": "S1p"
            },
            "Status": {
              "shape": "S3t"
            }
          }
        },
        "DomainEndpointOptions": {
          "type": "structure",
          "required": [
            "Options",
            "Status"
          ],
          "members": {
            "Options": {
              "shape": "S1t"
            },
            "Status": {
              "shape": "S3t"
            }
          }
        },
        "AdvancedSecurityOptions": {
          "type": "structure",
          "required": [
            "Options",
            "Status"
          ],
          "members": {
            "Options": {
              "shape": "S2j"
            },
            "Status": {
              "shape": "S3t"
            }
          }
        },
        "AutoTuneOptions": {
          "type": "structure",
          "members": {
            "Options": {
              "shape": "S4a"
            },
            "Status": {
              "type": "structure",
              "required": [
                "CreationDate",
                "UpdateDate",
                "State"
              ],
              "members": {
                "CreationDate": {
                  "type": "timestamp"
                },
                "UpdateDate": {
                  "type": "timestamp"
                },
                "UpdateVersion": {
                  "type": "integer"
                },
                "State": {},
                "ErrorMessage": {},
                "PendingDeletion": {
                  "type": "boolean"
                }
              }
            }
          }
        }
      }
    },
    "S3t": {
      "type": "structure",
      "required": [
        "CreationDate",
        "UpdateDate",
        "State"
      ],
      "members": {
        "CreationDate": {
          "type": "timestamp"
        },
        "UpdateDate": {
          "type": "timestamp"
        },
        "UpdateVersion": {
          "type": "integer"
        },
        "State": {},
        "PendingDeletion": {
          "type": "boolean"
        }
      }
    },
    "S4a": {
      "type": "structure",
      "members": {
        "DesiredState": {},
        "RollbackOnDisable": {},
        "MaintenanceSchedules": {
          "shape": "S27"
        }
      }
    },
    "S4i": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "Name": {},
          "Values": {
            "type": "list",
            "member": {}
          }
        }
      }
    },
    "S50": {
      "type": "list",
      "member": {}
    },
    "S5r": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "RecurringChargeAmount": {
            "type": "double"
          },
          "RecurringChargeFrequency": {}
        }
      }
    },
    "S65": {
      "type": "list",
      "member": {}
    },
    "S6w": {
      "type": "list",
      "member": {
        "shape": "Sm"
      }
    }
  }
}