{
  "version": "2.0",
  "metadata": {
    "apiVersion": "2018-08-08",
    "endpointPrefix": "globalaccelerator",
    "jsonVersion": "1.1",
    "protocol": "json",
    "serviceFullName": "AWS Global Accelerator",
    "serviceId": "Global Accelerator",
    "signatureVersion": "v4",
    "signingName": "globalaccelerator",
    "targetPrefix": "GlobalAccelerator_V20180706",
    "uid": "globalaccelerator-2018-08-08"
  },
  "operations": {
    "AddCustomRoutingEndpoints": {
      "input": {
        "type": "structure",
        "required": [
          "EndpointConfigurations",
          "EndpointGroupArn"
        ],
        "members": {
          "EndpointConfigurations": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "EndpointId": {}
              }
            }
          },
          "EndpointGroupArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "EndpointDescriptions": {
            "shape": "S6"
          },
          "EndpointGroupArn": {}
        }
      }
    },
    "AdvertiseByoipCidr": {
      "input": {
        "type": "structure",
        "required": [
          "Cidr"
        ],
        "members": {
          "Cidr": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ByoipCidr": {
            "shape": "Sa"
          }
        }
      }
    },
    "AllowCustomRoutingTraffic": {
      "input": {
        "type": "structure",
        "required": [
          "EndpointGroupArn",
          "EndpointId"
        ],
        "members": {
          "EndpointGroupArn": {},
          "EndpointId": {},
          "DestinationAddresses": {
            "shape": "Sg"
          },
          "DestinationPorts": {
            "shape": "Si"
          },
          "AllowAllTrafficToEndpoint": {
            "type": "boolean"
          }
        }
      }
    },
    "CreateAccelerator": {
      "input": {
        "type": "structure",
        "required": [
          "Name",
          "IdempotencyToken"
        ],
        "members": {
          "Name": {},
          "IpAddressType": {},
          "IpAddresses": {
            "shape": "Sn"
          },
          "Enabled": {
            "type": "boolean"
          },
          "IdempotencyToken": {
            "idempotencyToken": true
          },
          "Tags": {
            "shape": "Sp"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Accelerator": {
            "shape": "Su"
          }
        }
      }
    },
    "CreateCustomRoutingAccelerator": {
      "input": {
        "type": "structure",
        "required": [
          "Name",
          "IdempotencyToken"
        ],
        "members": {
          "Name": {},
          "IpAddressType": {},
          "IpAddresses": {
            "shape": "Sn"
          },
          "Enabled": {
            "type": "boolean"
          },
          "IdempotencyToken": {
            "idempotencyToken": true
          },
          "Tags": {
            "shape": "Sp"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Accelerator": {
            "shape": "S10"
          }
        }
      }
    },
    "CreateCustomRoutingEndpointGroup": {
      "input": {
        "type": "structure",
        "required": [
          "ListenerArn",
          "EndpointGroupRegion",
          "DestinationConfigurations",
          "IdempotencyToken"
        ],
        "members": {
          "ListenerArn": {},
          "EndpointGroupRegion": {},
          "DestinationConfigurations": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "FromPort",
                "ToPort",
                "Protocols"
              ],
              "members": {
                "FromPort": {
                  "type": "integer"
                },
                "ToPort": {
                  "type": "integer"
                },
                "Protocols": {
                  "shape": "S15"
                }
              }
            }
          },
          "IdempotencyToken": {
            "idempotencyToken": true
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "EndpointGroup": {
            "shape": "S18"
          }
        }
      }
    },
    "CreateCustomRoutingListener": {
      "input": {
        "type": "structure",
        "required": [
          "AcceleratorArn",
          "PortRanges",
          "IdempotencyToken"
        ],
        "members": {
          "AcceleratorArn": {},
          "PortRanges": {
            "shape": "S1e"
          },
          "IdempotencyToken": {
            "idempotencyToken": true
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Listener": {
            "shape": "S1h"
          }
        }
      }
    },
    "CreateEndpointGroup": {
      "input": {
        "type": "structure",
        "required": [
          "ListenerArn",
          "EndpointGroupRegion",
          "IdempotencyToken"
        ],
        "members": {
          "ListenerArn": {},
          "EndpointGroupRegion": {},
          "EndpointConfigurations": {
            "shape": "S1j"
          },
          "TrafficDialPercentage": {
            "type": "float"
          },
          "HealthCheckPort": {
            "type": "integer"
          },
          "HealthCheckProtocol": {},
          "HealthCheckPath": {},
          "HealthCheckIntervalSeconds": {
            "type": "integer"
          },
          "ThresholdCount": {
            "type": "integer"
          },
          "IdempotencyToken": {
            "idempotencyToken": true
          },
          "PortOverrides": {
            "shape": "S1s"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "EndpointGroup": {
            "shape": "S1v"
          }
        }
      }
    },
    "CreateListener": {
      "input": {
        "type": "structure",
        "required": [
          "AcceleratorArn",
          "PortRanges",
          "Protocol",
          "IdempotencyToken"
        ],
        "members": {
          "AcceleratorArn": {},
          "PortRanges": {
            "shape": "S1e"
          },
          "Protocol": {},
          "ClientAffinity": {},
          "IdempotencyToken": {
            "idempotencyToken": true
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Listener": {
            "shape": "S22"
          }
        }
      }
    },
    "DeleteAccelerator": {
      "input": {
        "type": "structure",
        "required": [
          "AcceleratorArn"
        ],
        "members": {
          "AcceleratorArn": {}
        }
      }
    },
    "DeleteCustomRoutingAccelerator": {
      "input": {
        "type": "structure",
        "required": [
          "AcceleratorArn"
        ],
        "members": {
          "AcceleratorArn": {}
        }
      }
    },
    "DeleteCustomRoutingEndpointGroup": {
      "input": {
        "type": "structure",
        "required": [
          "EndpointGroupArn"
        ],
        "members": {
          "EndpointGroupArn": {}
        }
      }
    },
    "DeleteCustomRoutingListener": {
      "input": {
        "type": "structure",
        "required": [
          "ListenerArn"
        ],
        "members": {
          "ListenerArn": {}
        }
      }
    },
    "DeleteEndpointGroup": {
      "input": {
        "type": "structure",
        "required": [
          "EndpointGroupArn"
        ],
        "members": {
          "EndpointGroupArn": {}
        }
      }
    },
    "DeleteListener": {
      "input": {
        "type": "structure",
        "required": [
          "ListenerArn"
        ],
        "members": {
          "ListenerArn": {}
        }
      }
    },
    "DenyCustomRoutingTraffic": {
      "input": {
        "type": "structure",
        "required": [
          "EndpointGroupArn",
          "EndpointId"
        ],
        "members": {
          "EndpointGroupArn": {},
          "EndpointId": {},
          "DestinationAddresses": {
            "shape": "Sg"
          },
          "DestinationPorts": {
            "shape": "Si"
          },
          "DenyAllTrafficToEndpoint": {
            "type": "boolean"
          }
        }
      }
    },
    "DeprovisionByoipCidr": {
      "input": {
        "type": "structure",
        "required": [
          "Cidr"
        ],
        "members": {
          "Cidr": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ByoipCidr": {
            "shape": "Sa"
          }
        }
      }
    },
    "DescribeAccelerator": {
      "input": {
        "type": "structure",
        "required": [
          "AcceleratorArn"
        ],
        "members": {
          "AcceleratorArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Accelerator": {
            "shape": "Su"
          }
        }
      }
    },
    "DescribeAcceleratorAttributes": {
      "input": {
        "type": "structure",
        "required": [
          "AcceleratorArn"
        ],
        "members": {
          "AcceleratorArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AcceleratorAttributes": {
            "shape": "S2g"
          }
        }
      }
    },
    "DescribeCustomRoutingAccelerator": {
      "input": {
        "type": "structure",
        "required": [
          "AcceleratorArn"
        ],
        "members": {
          "AcceleratorArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Accelerator": {
            "shape": "S10"
          }
        }
      }
    },
    "DescribeCustomRoutingAcceleratorAttributes": {
      "input": {
        "type": "structure",
        "required": [
          "AcceleratorArn"
        ],
        "members": {
          "AcceleratorArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AcceleratorAttributes": {
            "shape": "S2l"
          }
        }
      }
    },
    "DescribeCustomRoutingEndpointGroup": {
      "input": {
        "type": "structure",
        "required": [
          "EndpointGroupArn"
        ],
        "members": {
          "EndpointGroupArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "EndpointGroup": {
            "shape": "S18"
          }
        }
      }
    },
    "DescribeCustomRoutingListener": {
      "input": {
        "type": "structure",
        "required": [
          "ListenerArn"
        ],
        "members": {
          "ListenerArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Listener": {
            "shape": "S1h"
          }
        }
      }
    },
    "DescribeEndpointGroup": {
      "input": {
        "type": "structure",
        "required": [
          "EndpointGroupArn"
        ],
        "members": {
          "EndpointGroupArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "EndpointGroup": {
            "shape": "S1v"
          }
        }
      }
    },
    "DescribeListener": {
      "input": {
        "type": "structure",
        "required": [
          "ListenerArn"
        ],
        "members": {
          "ListenerArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Listener": {
            "shape": "S22"
          }
        }
      }
    },
    "ListAccelerators": {
      "input": {
        "type": "structure",
        "members": {
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Accelerators": {
            "type": "list",
            "member": {
              "shape": "Su"
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListByoipCidrs": {
      "input": {
        "type": "structure",
        "members": {
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ByoipCidrs": {
            "type": "list",
            "member": {
              "shape": "Sa"
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListCustomRoutingAccelerators": {
      "input": {
        "type": "structure",
        "members": {
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Accelerators": {
            "type": "list",
            "member": {
              "shape": "S10"
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListCustomRoutingEndpointGroups": {
      "input": {
        "type": "structure",
        "required": [
          "ListenerArn"
        ],
        "members": {
          "ListenerArn": {},
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "EndpointGroups": {
            "type": "list",
            "member": {
              "shape": "S18"
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListCustomRoutingListeners": {
      "input": {
        "type": "structure",
        "required": [
          "AcceleratorArn"
        ],
        "members": {
          "AcceleratorArn": {},
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Listeners": {
            "type": "list",
            "member": {
              "shape": "S1h"
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListCustomRoutingPortMappings": {
      "input": {
        "type": "structure",
        "required": [
          "AcceleratorArn"
        ],
        "members": {
          "AcceleratorArn": {},
          "EndpointGroupArn": {},
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "PortMappings": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "AcceleratorPort": {
                  "type": "integer"
                },
                "EndpointGroupArn": {},
                "EndpointId": {},
                "DestinationSocketAddress": {
                  "shape": "S3f"
                },
                "Protocols": {
                  "shape": "S15"
                },
                "DestinationTrafficState": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListCustomRoutingPortMappingsByDestination": {
      "input": {
        "type": "structure",
        "required": [
          "EndpointId",
          "DestinationAddress"
        ],
        "members": {
          "EndpointId": {},
          "DestinationAddress": {},
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DestinationPortMappings": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "AcceleratorArn": {},
                "AcceleratorSocketAddresses": {
                  "type": "list",
                  "member": {
                    "shape": "S3f"
                  }
                },
                "EndpointGroupArn": {},
                "EndpointId": {},
                "EndpointGroupRegion": {},
                "DestinationSocketAddress": {
                  "shape": "S3f"
                },
                "IpAddressType": {},
                "DestinationTrafficState": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListEndpointGroups": {
      "input": {
        "type": "structure",
        "required": [
          "ListenerArn"
        ],
        "members": {
          "ListenerArn": {},
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "EndpointGroups": {
            "type": "list",
            "member": {
              "shape": "S1v"
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListListeners": {
      "input": {
        "type": "structure",
        "required": [
          "AcceleratorArn"
        ],
        "members": {
          "AcceleratorArn": {},
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Listeners": {
            "type": "list",
            "member": {
              "shape": "S22"
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListTagsForResource": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceArn"
        ],
        "members": {
          "ResourceArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Tags": {
            "shape": "Sp"
          }
        }
      }
    },
    "ProvisionByoipCidr": {
      "input": {
        "type": "structure",
        "required": [
          "Cidr",
          "CidrAuthorizationContext"
        ],
        "members": {
          "Cidr": {},
          "CidrAuthorizationContext": {
            "type": "structure",
            "required": [
              "Message",
              "Signature"
            ],
            "members": {
              "Message": {},
              "Signature": {}
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ByoipCidr": {
            "shape": "Sa"
          }
        }
      }
    },
    "RemoveCustomRoutingEndpoints": {
      "input": {
        "type": "structure",
        "required": [
          "EndpointIds",
          "EndpointGroupArn"
        ],
        "members": {
          "EndpointIds": {
            "type": "list",
            "member": {}
          },
          "EndpointGroupArn": {}
        }
      }
    },
    "TagResource": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceArn",
          "Tags"
        ],
        "members": {
          "ResourceArn": {},
          "Tags": {
            "shape": "Sp"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UntagResource": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceArn",
          "TagKeys"
        ],
        "members": {
          "ResourceArn": {},
          "TagKeys": {
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UpdateAccelerator": {
      "input": {
        "type": "structure",
        "required": [
          "AcceleratorArn"
        ],
        "members": {
          "AcceleratorArn": {},
          "Name": {},
          "IpAddressType": {},
          "Enabled": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Accelerator": {
            "shape": "Su"
          }
        }
      }
    },
    "UpdateAcceleratorAttributes": {
      "input": {
        "type": "structure",
        "required": [
          "AcceleratorArn"
        ],
        "members": {
          "AcceleratorArn": {},
          "FlowLogsEnabled": {
            "type": "boolean"
          },
          "FlowLogsS3Bucket": {},
          "FlowLogsS3Prefix": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AcceleratorAttributes": {
            "shape": "S2g"
          }
        }
      }
    },
    "UpdateCustomRoutingAccelerator": {
      "input": {
        "type": "structure",
        "required": [
          "AcceleratorArn"
        ],
        "members": {
          "AcceleratorArn": {},
          "Name": {},
          "IpAddressType": {},
          "Enabled": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Accelerator": {
            "shape": "S10"
          }
        }
      }
    },
    "UpdateCustomRoutingAcceleratorAttributes": {
      "input": {
        "type": "structure",
        "required": [
          "AcceleratorArn"
        ],
        "members": {
          "AcceleratorArn": {},
          "FlowLogsEnabled": {
            "type": "boolean"
          },
          "FlowLogsS3Bucket": {},
          "FlowLogsS3Prefix": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AcceleratorAttributes": {
            "shape": "S2l"
          }
        }
      }
    },
    "UpdateCustomRoutingListener": {
      "input": {
        "type": "structure",
        "required": [
          "ListenerArn",
          "PortRanges"
        ],
        "members": {
          "ListenerArn": {},
          "PortRanges": {
            "shape": "S1e"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Listener": {
            "shape": "S1h"
          }
        }
      }
    },
    "UpdateEndpointGroup": {
      "input": {
        "type": "structure",
        "required": [
          "EndpointGroupArn"
        ],
        "members": {
          "EndpointGroupArn": {},
          "EndpointConfigurations": {
            "shape": "S1j"
          },
          "TrafficDialPercentage": {
            "type": "float"
          },
          "HealthCheckPort": {
            "type": "integer"
          },
          "HealthCheckProtocol": {},
          "HealthCheckPath": {},
          "HealthCheckIntervalSeconds": {
            "type": "integer"
          },
          "ThresholdCount": {
            "type": "integer"
          },
          "PortOverrides": {
            "shape": "S1s"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "EndpointGroup": {
            "shape": "S1v"
          }
        }
      }
    },
    "UpdateListener": {
      "input": {
        "type": "structure",
        "required": [
          "ListenerArn"
        ],
        "members": {
          "ListenerArn": {},
          "PortRanges": {
            "shape": "S1e"
          },
          "Protocol": {},
          "ClientAffinity": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Listener": {
            "shape": "S22"
          }
        }
      }
    },
    "WithdrawByoipCidr": {
      "input": {
        "type": "structure",
        "required": [
          "Cidr"
        ],
        "members": {
          "Cidr": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ByoipCidr": {
            "shape": "Sa"
          }
        }
      }
    }
  },
  "shapes": {
    "S6": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "EndpointId": {}
        }
      }
    },
    "Sa": {
      "type": "structure",
      "members": {
        "Cidr": {},
        "State": {},
        "Events": {
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "Message": {},
              "Timestamp": {
                "type": "timestamp"
              }
            }
          }
        }
      }
    },
    "Sg": {
      "type": "list",
      "member": {}
    },
    "Si": {
      "type": "list",
      "member": {
        "type": "integer"
      }
    },
    "Sn": {
      "type": "list",
      "member": {}
    },
    "Sp": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "Key",
          "Value"
        ],
        "members": {
          "Key": {},
          "Value": {}
        }
      }
    },
    "Su": {
      "type": "structure",
      "members": {
        "AcceleratorArn": {},
        "Name": {},
        "IpAddressType": {},
        "Enabled": {
          "type": "boolean"
        },
        "IpSets": {
          "shape": "Sv"
        },
        "DnsName": {},
        "Status": {},
        "CreatedTime": {
          "type": "timestamp"
        },
        "LastModifiedTime": {
          "type": "timestamp"
        }
      }
    },
    "Sv": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "IpFamily": {},
          "IpAddresses": {
            "shape": "Sn"
          }
        }
      }
    },
    "S10": {
      "type": "structure",
      "members": {
        "AcceleratorArn": {},
        "Name": {},
        "IpAddressType": {},
        "Enabled": {
          "type": "boolean"
        },
        "IpSets": {
          "shape": "Sv"
        },
        "DnsName": {},
        "Status": {},
        "CreatedTime": {
          "type": "timestamp"
        },
        "LastModifiedTime": {
          "type": "timestamp"
        }
      }
    },
    "S15": {
      "type": "list",
      "member": {}
    },
    "S18": {
      "type": "structure",
      "members": {
        "EndpointGroupArn": {},
        "EndpointGroupRegion": {},
        "DestinationDescriptions": {
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "FromPort": {
                "type": "integer"
              },
              "ToPort": {
                "type": "integer"
              },
              "Protocols": {
                "type": "list",
                "member": {}
              }
            }
          }
        },
        "EndpointDescriptions": {
          "shape": "S6"
        }
      }
    },
    "S1e": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "FromPort": {
            "type": "integer"
          },
          "ToPort": {
            "type": "integer"
          }
        }
      }
    },
    "S1h": {
      "type": "structure",
      "members": {
        "ListenerArn": {},
        "PortRanges": {
          "shape": "S1e"
        }
      }
    },
    "S1j": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "EndpointId": {},
          "Weight": {
            "type": "integer"
          },
          "ClientIPPreservationEnabled": {
            "type": "boolean"
          }
        }
      }
    },
    "S1s": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "ListenerPort": {
            "type": "integer"
          },
          "EndpointPort": {
            "type": "integer"
          }
        }
      }
    },
    "S1v": {
      "type": "structure",
      "members": {
        "EndpointGroupArn": {},
        "EndpointGroupRegion": {},
        "EndpointDescriptions": {
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "EndpointId": {},
              "Weight": {
                "type": "integer"
              },
              "HealthState": {},
              "HealthReason": {},
              "ClientIPPreservationEnabled": {
                "type": "boolean"
              }
            }
          }
        },
        "TrafficDialPercentage": {
          "type": "float"
        },
        "HealthCheckPort": {
          "type": "integer"
        },
        "HealthCheckProtocol": {},
        "HealthCheckPath": {},
        "HealthCheckIntervalSeconds": {
          "type": "integer"
        },
        "ThresholdCount": {
          "type": "integer"
        },
        "PortOverrides": {
          "shape": "S1s"
        }
      }
    },
    "S22": {
      "type": "structure",
      "members": {
        "ListenerArn": {},
        "PortRanges": {
          "shape": "S1e"
        },
        "Protocol": {},
        "ClientAffinity": {}
      }
    },
    "S2g": {
      "type": "structure",
      "members": {
        "FlowLogsEnabled": {
          "type": "boolean"
        },
        "FlowLogsS3Bucket": {},
        "FlowLogsS3Prefix": {}
      }
    },
    "S2l": {
      "type": "structure",
      "members": {
        "FlowLogsEnabled": {
          "type": "boolean"
        },
        "FlowLogsS3Bucket": {},
        "FlowLogsS3Prefix": {}
      }
    },
    "S3f": {
      "type": "structure",
      "members": {
        "IpAddress": {},
        "Port": {
          "type": "integer"
        }
      }
    }
  }
}