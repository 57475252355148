import { action } from 'mobx'
import { observer } from 'mobx-react-lite'
import { Button, Icon, AnchorButton } from '@blueprintjs/core'
import { useStore } from '~/src/app/store'
import { useHistory } from 'react-router-dom'
import { useSaveTraining } from '~/src/hooks/training'
import CustomIcon from '~/src/app/components/Icon'
import Tooltip from '~/src/utils/components/Tooltip'

const icon = name => <Icon icon={name} iconSize={25} />

const toggleDrawerButton = (appState, iconName, drawerName) => {
  const isActive = appState.sidebarDrawer === drawerName
  const toggleDrawer = () =>
    (appState.sidebarDrawer = isActive ? undefined : drawerName)
  return (
    <Tooltip content={tooltip}>
      <Button
        active={isActive}
        minimal
        large
        icon={icon(iconName)}
        onClick={toggleDrawer}
      />
    </Tooltip>
  )
}

const LeftSidebar = () => {
  const history = useHistory()
  const { appState, toolbar, undo, animator } = useStore()

  // actions
  const goHome = () => history.push('/')
  const saveTraining = useSaveTraining()
  const onActivatePreview = action(() => {
    toolbar.unselectNode()
    appState.previewMode()
  })

  // TODO: deleteme
  const placeholderAction = action => () =>
    alert(`placeholder action: ${action}`)
  return (
    <div className="left-sidebar">
      <Tooltip content="Go to manager">
        <Button
          minimal
          large
          icon={<CustomIcon icon="home" />}
          onClick={goHome}
        />
      </Tooltip>

      {toggleDrawerButton(
        appState,
        <CustomIcon icon="settings" />,
        'settings',
        (tooltip = 'Settings'),
      )}
      {toggleDrawerButton(
        appState,
        <CustomIcon icon="add" />,
        'add-model',
        (tooltip = 'Add 3D object (M)'),
      )}
      <Tooltip content="Preview (Ctrl + Enter)">
        <Button
          minimal
          large
          icon={<CustomIcon icon="view" />}
          onClick={onActivatePreview}
        />
      </Tooltip>
      <div className="bottom-group">
        <Tooltip content="Undo (Ctrl + Z)">
          <AnchorButton
            className="bottom-button"
            minimal
            large
            icon={icon('undo')}
            disabled={!undo.canUndo()}
            onClick={undo.restorePreviousSnapshot}
          />
        </Tooltip>
        <Tooltip content="Redo (Ctrl + Y)">
          <AnchorButton
            className="bottom-button"
            minimal
            large
            icon={icon('redo')}
            disabled={!undo.canRedo()}
            onClick={undo.restoreNextSnapshot}
          />
        </Tooltip>
        <Tooltip content="Help">
          <a
            className="bottom-button"
            href="https://auctaio.notion.site/auctaio/AUCTA-Docs-c3c80d2b83ea4f4b87ff8f2c1ced1369"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Icon icon="help" />
          </a>
        </Tooltip>
      </div>
    </div>
  )
}

export default observer(LeftSidebar)
