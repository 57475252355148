{
  "pagination": {
    "ListAccountAssignmentCreationStatus": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "AccountAssignmentsCreationStatus"
    },
    "ListAccountAssignmentDeletionStatus": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "AccountAssignmentsDeletionStatus"
    },
    "ListAccountAssignments": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "AccountAssignments"
    },
    "ListAccountsForProvisionedPermissionSet": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "AccountIds"
    },
    "ListInstances": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "Instances"
    },
    "ListManagedPoliciesInPermissionSet": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "AttachedManagedPolicies"
    },
    "ListPermissionSetProvisioningStatus": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "PermissionSetsProvisioningStatus"
    },
    "ListPermissionSets": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "PermissionSets"
    },
    "ListPermissionSetsProvisionedToAccount": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "PermissionSets"
    },
    "ListTagsForResource": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "result_key": "Tags"
    }
  }
}