{
  "version": "2.0",
  "metadata": {
    "apiVersion": "2020-02-26",
    "endpointPrefix": "mgn",
    "jsonVersion": "1.1",
    "protocol": "rest-json",
    "serviceAbbreviation": "mgn",
    "serviceFullName": "Application Migration Service",
    "serviceId": "mgn",
    "signatureVersion": "v4",
    "signingName": "mgn",
    "uid": "mgn-2020-02-26"
  },
  "operations": {
    "ChangeServerLifeCycleState": {
      "http": {
        "requestUri": "/ChangeServerLifeCycleState",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "lifeCycle",
          "sourceServerID"
        ],
        "members": {
          "lifeCycle": {
            "type": "structure",
            "required": [
              "state"
            ],
            "members": {
              "state": {}
            }
          },
          "sourceServerID": {}
        }
      },
      "output": {
        "shape": "S5"
      }
    },
    "CreateReplicationConfigurationTemplate": {
      "http": {
        "requestUri": "/CreateReplicationConfigurationTemplate",
        "responseCode": 201
      },
      "input": {
        "type": "structure",
        "required": [
          "associateDefaultSecurityGroup",
          "bandwidthThrottling",
          "createPublicIP",
          "dataPlaneRouting",
          "defaultLargeStagingDiskType",
          "ebsEncryption",
          "replicationServerInstanceType",
          "replicationServersSecurityGroupsIDs",
          "stagingAreaSubnetId",
          "stagingAreaTags",
          "useDedicatedReplicationServer"
        ],
        "members": {
          "associateDefaultSecurityGroup": {
            "type": "boolean"
          },
          "bandwidthThrottling": {
            "type": "long"
          },
          "createPublicIP": {
            "type": "boolean"
          },
          "dataPlaneRouting": {},
          "defaultLargeStagingDiskType": {},
          "ebsEncryption": {},
          "ebsEncryptionKeyArn": {},
          "replicationServerInstanceType": {},
          "replicationServersSecurityGroupsIDs": {
            "shape": "S1j"
          },
          "stagingAreaSubnetId": {},
          "stagingAreaTags": {
            "shape": "S1c"
          },
          "tags": {
            "shape": "S1c"
          },
          "useDedicatedReplicationServer": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "shape": "S1m"
      }
    },
    "DeleteJob": {
      "http": {
        "requestUri": "/DeleteJob",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "jobID"
        ],
        "members": {
          "jobID": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    },
    "DeleteReplicationConfigurationTemplate": {
      "http": {
        "requestUri": "/DeleteReplicationConfigurationTemplate",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "replicationConfigurationTemplateID"
        ],
        "members": {
          "replicationConfigurationTemplateID": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    },
    "DeleteSourceServer": {
      "http": {
        "requestUri": "/DeleteSourceServer",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "sourceServerID"
        ],
        "members": {
          "sourceServerID": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    },
    "DescribeJobLogItems": {
      "http": {
        "requestUri": "/DescribeJobLogItems",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "jobID"
        ],
        "members": {
          "jobID": {},
          "maxResults": {
            "type": "integer"
          },
          "nextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "items": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "event": {},
                "eventData": {
                  "type": "structure",
                  "members": {
                    "conversionServerID": {},
                    "rawError": {},
                    "sourceServerID": {},
                    "targetInstanceID": {}
                  }
                },
                "logDateTime": {}
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "DescribeJobs": {
      "http": {
        "requestUri": "/DescribeJobs",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "filters"
        ],
        "members": {
          "filters": {
            "type": "structure",
            "members": {
              "fromDate": {},
              "jobIDs": {
                "type": "list",
                "member": {}
              },
              "toDate": {}
            }
          },
          "maxResults": {
            "type": "integer"
          },
          "nextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "items": {
            "type": "list",
            "member": {
              "shape": "S27"
            }
          },
          "nextToken": {}
        }
      }
    },
    "DescribeReplicationConfigurationTemplates": {
      "http": {
        "requestUri": "/DescribeReplicationConfigurationTemplates",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "replicationConfigurationTemplateIDs"
        ],
        "members": {
          "maxResults": {
            "type": "integer"
          },
          "nextToken": {},
          "replicationConfigurationTemplateIDs": {
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "items": {
            "type": "list",
            "member": {
              "shape": "S1m"
            }
          },
          "nextToken": {}
        }
      }
    },
    "DescribeSourceServers": {
      "http": {
        "requestUri": "/DescribeSourceServers",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "filters"
        ],
        "members": {
          "filters": {
            "type": "structure",
            "members": {
              "isArchived": {
                "type": "boolean"
              },
              "sourceServerIDs": {
                "type": "list",
                "member": {}
              }
            }
          },
          "maxResults": {
            "type": "integer"
          },
          "nextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "items": {
            "type": "list",
            "member": {
              "shape": "S5"
            }
          },
          "nextToken": {}
        }
      }
    },
    "DisconnectFromService": {
      "http": {
        "requestUri": "/DisconnectFromService",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "sourceServerID"
        ],
        "members": {
          "sourceServerID": {}
        }
      },
      "output": {
        "shape": "S5"
      }
    },
    "FinalizeCutover": {
      "http": {
        "requestUri": "/FinalizeCutover",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "sourceServerID"
        ],
        "members": {
          "sourceServerID": {}
        }
      },
      "output": {
        "shape": "S5"
      }
    },
    "GetLaunchConfiguration": {
      "http": {
        "requestUri": "/GetLaunchConfiguration",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "sourceServerID"
        ],
        "members": {
          "sourceServerID": {}
        }
      },
      "output": {
        "shape": "S2q"
      }
    },
    "GetReplicationConfiguration": {
      "http": {
        "requestUri": "/GetReplicationConfiguration",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "sourceServerID"
        ],
        "members": {
          "sourceServerID": {}
        }
      },
      "output": {
        "shape": "S2w"
      }
    },
    "InitializeService": {
      "http": {
        "requestUri": "/InitializeService",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "members": {}
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "ListTagsForResource": {
      "http": {
        "method": "GET",
        "requestUri": "/tags/{resourceArn}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "resourceArn"
        ],
        "members": {
          "resourceArn": {
            "location": "uri",
            "locationName": "resourceArn"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "tags": {
            "shape": "S1c"
          }
        }
      }
    },
    "MarkAsArchived": {
      "http": {
        "requestUri": "/MarkAsArchived",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "sourceServerID"
        ],
        "members": {
          "sourceServerID": {}
        }
      },
      "output": {
        "shape": "S5"
      }
    },
    "RetryDataReplication": {
      "http": {
        "requestUri": "/RetryDataReplication",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "sourceServerID"
        ],
        "members": {
          "sourceServerID": {}
        }
      },
      "output": {
        "shape": "S5"
      }
    },
    "StartCutover": {
      "http": {
        "requestUri": "/StartCutover",
        "responseCode": 202
      },
      "input": {
        "type": "structure",
        "required": [
          "sourceServerIDs"
        ],
        "members": {
          "sourceServerIDs": {
            "type": "list",
            "member": {}
          },
          "tags": {
            "shape": "S1c"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "job": {
            "shape": "S27"
          }
        }
      }
    },
    "StartTest": {
      "http": {
        "requestUri": "/StartTest",
        "responseCode": 202
      },
      "input": {
        "type": "structure",
        "required": [
          "sourceServerIDs"
        ],
        "members": {
          "sourceServerIDs": {
            "type": "list",
            "member": {}
          },
          "tags": {
            "shape": "S1c"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "job": {
            "shape": "S27"
          }
        }
      }
    },
    "TagResource": {
      "http": {
        "requestUri": "/tags/{resourceArn}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "resourceArn",
          "tags"
        ],
        "members": {
          "resourceArn": {
            "location": "uri",
            "locationName": "resourceArn"
          },
          "tags": {
            "shape": "S1c"
          }
        }
      },
      "idempotent": true
    },
    "TerminateTargetInstances": {
      "http": {
        "requestUri": "/TerminateTargetInstances",
        "responseCode": 202
      },
      "input": {
        "type": "structure",
        "required": [
          "sourceServerIDs"
        ],
        "members": {
          "sourceServerIDs": {
            "type": "list",
            "member": {}
          },
          "tags": {
            "shape": "S1c"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "job": {
            "shape": "S27"
          }
        }
      }
    },
    "UntagResource": {
      "http": {
        "method": "DELETE",
        "requestUri": "/tags/{resourceArn}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "resourceArn",
          "tagKeys"
        ],
        "members": {
          "resourceArn": {
            "location": "uri",
            "locationName": "resourceArn"
          },
          "tagKeys": {
            "location": "querystring",
            "locationName": "tagKeys",
            "type": "list",
            "member": {},
            "sensitive": true
          }
        }
      },
      "idempotent": true
    },
    "UpdateLaunchConfiguration": {
      "http": {
        "requestUri": "/UpdateLaunchConfiguration",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "sourceServerID"
        ],
        "members": {
          "copyPrivateIp": {
            "type": "boolean"
          },
          "copyTags": {
            "type": "boolean"
          },
          "launchDisposition": {},
          "licensing": {
            "shape": "S2s"
          },
          "name": {},
          "sourceServerID": {},
          "targetInstanceTypeRightSizingMethod": {}
        }
      },
      "output": {
        "shape": "S2q"
      },
      "idempotent": true
    },
    "UpdateReplicationConfiguration": {
      "http": {
        "requestUri": "/UpdateReplicationConfiguration",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "sourceServerID"
        ],
        "members": {
          "associateDefaultSecurityGroup": {
            "type": "boolean"
          },
          "bandwidthThrottling": {
            "type": "long"
          },
          "createPublicIP": {
            "type": "boolean"
          },
          "dataPlaneRouting": {},
          "defaultLargeStagingDiskType": {},
          "ebsEncryption": {},
          "ebsEncryptionKeyArn": {},
          "name": {},
          "replicatedDisks": {
            "shape": "S2x"
          },
          "replicationServerInstanceType": {},
          "replicationServersSecurityGroupsIDs": {
            "shape": "S1j"
          },
          "sourceServerID": {},
          "stagingAreaSubnetId": {},
          "stagingAreaTags": {
            "shape": "S1c"
          },
          "useDedicatedReplicationServer": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "shape": "S2w"
      },
      "idempotent": true
    },
    "UpdateReplicationConfigurationTemplate": {
      "http": {
        "requestUri": "/UpdateReplicationConfigurationTemplate",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "replicationConfigurationTemplateID"
        ],
        "members": {
          "arn": {},
          "associateDefaultSecurityGroup": {
            "type": "boolean"
          },
          "bandwidthThrottling": {
            "type": "long"
          },
          "createPublicIP": {
            "type": "boolean"
          },
          "dataPlaneRouting": {},
          "defaultLargeStagingDiskType": {},
          "ebsEncryption": {},
          "ebsEncryptionKeyArn": {},
          "replicationConfigurationTemplateID": {},
          "replicationServerInstanceType": {},
          "replicationServersSecurityGroupsIDs": {
            "shape": "S1j"
          },
          "stagingAreaSubnetId": {},
          "stagingAreaTags": {
            "shape": "S1c"
          },
          "useDedicatedReplicationServer": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "shape": "S1m"
      }
    }
  },
  "shapes": {
    "S5": {
      "type": "structure",
      "members": {
        "arn": {},
        "dataReplicationInfo": {
          "type": "structure",
          "members": {
            "dataReplicationError": {
              "type": "structure",
              "members": {
                "error": {},
                "rawError": {}
              }
            },
            "dataReplicationInitiation": {
              "type": "structure",
              "members": {
                "nextAttemptDateTime": {},
                "startDateTime": {},
                "steps": {
                  "type": "list",
                  "member": {
                    "type": "structure",
                    "members": {
                      "name": {},
                      "status": {}
                    }
                  }
                }
              }
            },
            "dataReplicationState": {},
            "etaDateTime": {},
            "lagDuration": {},
            "replicatedDisks": {
              "type": "list",
              "member": {
                "type": "structure",
                "members": {
                  "backloggedStorageBytes": {
                    "type": "long"
                  },
                  "deviceName": {},
                  "replicatedStorageBytes": {
                    "type": "long"
                  },
                  "rescannedStorageBytes": {
                    "type": "long"
                  },
                  "totalStorageBytes": {
                    "type": "long"
                  }
                }
              }
            }
          }
        },
        "isArchived": {
          "type": "boolean"
        },
        "launchedInstance": {
          "type": "structure",
          "members": {
            "ec2InstanceID": {},
            "firstBoot": {},
            "jobID": {}
          }
        },
        "lifeCycle": {
          "type": "structure",
          "members": {
            "addedToServiceDateTime": {},
            "elapsedReplicationDuration": {},
            "firstByteDateTime": {},
            "lastCutover": {
              "type": "structure",
              "members": {
                "finalized": {
                  "type": "structure",
                  "members": {
                    "apiCallDateTime": {}
                  }
                },
                "initiated": {
                  "type": "structure",
                  "members": {
                    "apiCallDateTime": {},
                    "jobID": {}
                  }
                },
                "reverted": {
                  "type": "structure",
                  "members": {
                    "apiCallDateTime": {}
                  }
                }
              }
            },
            "lastSeenByServiceDateTime": {},
            "lastTest": {
              "type": "structure",
              "members": {
                "finalized": {
                  "type": "structure",
                  "members": {
                    "apiCallDateTime": {}
                  }
                },
                "initiated": {
                  "type": "structure",
                  "members": {
                    "apiCallDateTime": {},
                    "jobID": {}
                  }
                },
                "reverted": {
                  "type": "structure",
                  "members": {
                    "apiCallDateTime": {}
                  }
                }
              }
            },
            "state": {}
          }
        },
        "sourceProperties": {
          "type": "structure",
          "members": {
            "cpus": {
              "type": "list",
              "member": {
                "type": "structure",
                "members": {
                  "cores": {
                    "type": "long"
                  },
                  "modelName": {}
                }
              }
            },
            "disks": {
              "type": "list",
              "member": {
                "type": "structure",
                "members": {
                  "bytes": {
                    "type": "long"
                  },
                  "deviceName": {}
                }
              }
            },
            "identificationHints": {
              "type": "structure",
              "members": {
                "awsInstanceID": {},
                "fqdn": {},
                "hostname": {},
                "vmWareUuid": {}
              }
            },
            "lastUpdatedDateTime": {},
            "networkInterfaces": {
              "type": "list",
              "member": {
                "type": "structure",
                "members": {
                  "ips": {
                    "type": "list",
                    "member": {}
                  },
                  "isPrimary": {
                    "type": "boolean"
                  },
                  "macAddress": {}
                }
              }
            },
            "os": {
              "type": "structure",
              "members": {
                "fullString": {}
              }
            },
            "ramBytes": {
              "type": "long"
            },
            "recommendedInstanceType": {}
          }
        },
        "sourceServerID": {},
        "tags": {
          "shape": "S1c"
        }
      }
    },
    "S1c": {
      "type": "map",
      "key": {},
      "value": {},
      "sensitive": true
    },
    "S1j": {
      "type": "list",
      "member": {}
    },
    "S1m": {
      "type": "structure",
      "required": [
        "replicationConfigurationTemplateID"
      ],
      "members": {
        "arn": {},
        "associateDefaultSecurityGroup": {
          "type": "boolean"
        },
        "bandwidthThrottling": {
          "type": "long"
        },
        "createPublicIP": {
          "type": "boolean"
        },
        "dataPlaneRouting": {},
        "defaultLargeStagingDiskType": {},
        "ebsEncryption": {},
        "ebsEncryptionKeyArn": {},
        "replicationConfigurationTemplateID": {},
        "replicationServerInstanceType": {},
        "replicationServersSecurityGroupsIDs": {
          "shape": "S1j"
        },
        "stagingAreaSubnetId": {},
        "stagingAreaTags": {
          "shape": "S1c"
        },
        "tags": {
          "shape": "S1c"
        },
        "useDedicatedReplicationServer": {
          "type": "boolean"
        }
      }
    },
    "S27": {
      "type": "structure",
      "required": [
        "jobID"
      ],
      "members": {
        "arn": {},
        "creationDateTime": {},
        "endDateTime": {},
        "initiatedBy": {},
        "jobID": {},
        "participatingServers": {
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "launchStatus": {},
              "sourceServerID": {}
            }
          }
        },
        "status": {},
        "tags": {
          "shape": "S1c"
        },
        "type": {}
      }
    },
    "S2q": {
      "type": "structure",
      "members": {
        "copyPrivateIp": {
          "type": "boolean"
        },
        "copyTags": {
          "type": "boolean"
        },
        "ec2LaunchTemplateID": {},
        "launchDisposition": {},
        "licensing": {
          "shape": "S2s"
        },
        "name": {},
        "sourceServerID": {},
        "targetInstanceTypeRightSizingMethod": {}
      }
    },
    "S2s": {
      "type": "structure",
      "members": {
        "osByol": {
          "type": "boolean"
        }
      }
    },
    "S2w": {
      "type": "structure",
      "members": {
        "associateDefaultSecurityGroup": {
          "type": "boolean"
        },
        "bandwidthThrottling": {
          "type": "long"
        },
        "createPublicIP": {
          "type": "boolean"
        },
        "dataPlaneRouting": {},
        "defaultLargeStagingDiskType": {},
        "ebsEncryption": {},
        "ebsEncryptionKeyArn": {},
        "name": {},
        "replicatedDisks": {
          "shape": "S2x"
        },
        "replicationServerInstanceType": {},
        "replicationServersSecurityGroupsIDs": {
          "shape": "S1j"
        },
        "sourceServerID": {},
        "stagingAreaSubnetId": {},
        "stagingAreaTags": {
          "shape": "S1c"
        },
        "useDedicatedReplicationServer": {
          "type": "boolean"
        }
      }
    },
    "S2x": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "deviceName": {},
          "iops": {
            "type": "long"
          },
          "isBootDisk": {
            "type": "boolean"
          },
          "stagingDiskType": {}
        }
      }
    }
  }
}