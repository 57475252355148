{
  "version": "2.0",
  "metadata": {
    "apiVersion": "2017-03-31",
    "endpointPrefix": "glue",
    "jsonVersion": "1.1",
    "protocol": "json",
    "serviceFullName": "AWS Glue",
    "serviceId": "Glue",
    "signatureVersion": "v4",
    "targetPrefix": "AWSGlue",
    "uid": "glue-2017-03-31"
  },
  "operations": {
    "BatchCreatePartition": {
      "input": {
        "type": "structure",
        "required": [
          "DatabaseName",
          "TableName",
          "PartitionInputList"
        ],
        "members": {
          "CatalogId": {},
          "DatabaseName": {},
          "TableName": {},
          "PartitionInputList": {
            "type": "list",
            "member": {
              "shape": "S5"
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Errors": {
            "shape": "S11"
          }
        }
      }
    },
    "BatchDeleteConnection": {
      "input": {
        "type": "structure",
        "required": [
          "ConnectionNameList"
        ],
        "members": {
          "CatalogId": {},
          "ConnectionNameList": {
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Succeeded": {
            "shape": "Sm"
          },
          "Errors": {
            "type": "map",
            "key": {},
            "value": {
              "shape": "S13"
            }
          }
        }
      }
    },
    "BatchDeletePartition": {
      "input": {
        "type": "structure",
        "required": [
          "DatabaseName",
          "TableName",
          "PartitionsToDelete"
        ],
        "members": {
          "CatalogId": {},
          "DatabaseName": {},
          "TableName": {},
          "PartitionsToDelete": {
            "type": "list",
            "member": {
              "shape": "S1b"
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Errors": {
            "shape": "S11"
          }
        }
      }
    },
    "BatchDeleteTable": {
      "input": {
        "type": "structure",
        "required": [
          "DatabaseName",
          "TablesToDelete"
        ],
        "members": {
          "CatalogId": {},
          "DatabaseName": {},
          "TablesToDelete": {
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Errors": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "TableName": {},
                "ErrorDetail": {
                  "shape": "S13"
                }
              }
            }
          }
        }
      }
    },
    "BatchDeleteTableVersion": {
      "input": {
        "type": "structure",
        "required": [
          "DatabaseName",
          "TableName",
          "VersionIds"
        ],
        "members": {
          "CatalogId": {},
          "DatabaseName": {},
          "TableName": {},
          "VersionIds": {
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Errors": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "TableName": {},
                "VersionId": {},
                "ErrorDetail": {
                  "shape": "S13"
                }
              }
            }
          }
        }
      }
    },
    "BatchGetBlueprints": {
      "input": {
        "type": "structure",
        "required": [
          "Names"
        ],
        "members": {
          "Names": {
            "type": "list",
            "member": {}
          },
          "IncludeBlueprint": {
            "type": "boolean"
          },
          "IncludeParameterSpec": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Blueprints": {
            "type": "list",
            "member": {
              "shape": "S1u"
            }
          },
          "MissingBlueprints": {
            "shape": "S22"
          }
        }
      }
    },
    "BatchGetCrawlers": {
      "input": {
        "type": "structure",
        "required": [
          "CrawlerNames"
        ],
        "members": {
          "CrawlerNames": {
            "shape": "S24"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Crawlers": {
            "shape": "S26"
          },
          "CrawlersNotFound": {
            "shape": "S24"
          }
        }
      }
    },
    "BatchGetDevEndpoints": {
      "input": {
        "type": "structure",
        "required": [
          "DevEndpointNames"
        ],
        "members": {
          "DevEndpointNames": {
            "shape": "S3f"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DevEndpoints": {
            "shape": "S3h"
          },
          "DevEndpointsNotFound": {
            "shape": "S3f"
          }
        }
      }
    },
    "BatchGetJobs": {
      "input": {
        "type": "structure",
        "required": [
          "JobNames"
        ],
        "members": {
          "JobNames": {
            "shape": "S3r"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Jobs": {
            "shape": "S3t"
          },
          "JobsNotFound": {
            "shape": "S3r"
          }
        }
      }
    },
    "BatchGetPartition": {
      "input": {
        "type": "structure",
        "required": [
          "DatabaseName",
          "TableName",
          "PartitionsToGet"
        ],
        "members": {
          "CatalogId": {},
          "DatabaseName": {},
          "TableName": {},
          "PartitionsToGet": {
            "shape": "S4a"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Partitions": {
            "shape": "S4c"
          },
          "UnprocessedKeys": {
            "shape": "S4a"
          }
        }
      }
    },
    "BatchGetTriggers": {
      "input": {
        "type": "structure",
        "required": [
          "TriggerNames"
        ],
        "members": {
          "TriggerNames": {
            "shape": "S4f"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Triggers": {
            "shape": "S4h"
          },
          "TriggersNotFound": {
            "shape": "S4f"
          }
        }
      }
    },
    "BatchGetWorkflows": {
      "input": {
        "type": "structure",
        "required": [
          "Names"
        ],
        "members": {
          "Names": {
            "shape": "S4z"
          },
          "IncludeGraph": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Workflows": {
            "type": "list",
            "member": {
              "shape": "S52"
            }
          },
          "MissingWorkflows": {
            "shape": "S4z"
          }
        }
      }
    },
    "BatchStopJobRun": {
      "input": {
        "type": "structure",
        "required": [
          "JobName",
          "JobRunIds"
        ],
        "members": {
          "JobName": {},
          "JobRunIds": {
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "SuccessfulSubmissions": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "JobName": {},
                "JobRunId": {}
              }
            }
          },
          "Errors": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "JobName": {},
                "JobRunId": {},
                "ErrorDetail": {
                  "shape": "S13"
                }
              }
            }
          }
        }
      }
    },
    "BatchUpdatePartition": {
      "input": {
        "type": "structure",
        "required": [
          "DatabaseName",
          "TableName",
          "Entries"
        ],
        "members": {
          "CatalogId": {},
          "DatabaseName": {},
          "TableName": {},
          "Entries": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "PartitionValueList",
                "PartitionInput"
              ],
              "members": {
                "PartitionValueList": {
                  "shape": "S60"
                },
                "PartitionInput": {
                  "shape": "S5"
                }
              }
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Errors": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "PartitionValueList": {
                  "shape": "S60"
                },
                "ErrorDetail": {
                  "shape": "S13"
                }
              }
            }
          }
        }
      }
    },
    "CancelMLTaskRun": {
      "input": {
        "type": "structure",
        "required": [
          "TransformId",
          "TaskRunId"
        ],
        "members": {
          "TransformId": {},
          "TaskRunId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TransformId": {},
          "TaskRunId": {},
          "Status": {}
        }
      }
    },
    "CheckSchemaVersionValidity": {
      "input": {
        "type": "structure",
        "required": [
          "DataFormat",
          "SchemaDefinition"
        ],
        "members": {
          "DataFormat": {},
          "SchemaDefinition": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Valid": {
            "type": "boolean"
          },
          "Error": {}
        }
      }
    },
    "CreateBlueprint": {
      "input": {
        "type": "structure",
        "required": [
          "Name",
          "BlueprintLocation"
        ],
        "members": {
          "Name": {},
          "Description": {},
          "BlueprintLocation": {},
          "Tags": {
            "shape": "S6g"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Name": {}
        }
      }
    },
    "CreateClassifier": {
      "input": {
        "type": "structure",
        "members": {
          "GrokClassifier": {
            "type": "structure",
            "required": [
              "Classification",
              "Name",
              "GrokPattern"
            ],
            "members": {
              "Classification": {},
              "Name": {},
              "GrokPattern": {},
              "CustomPatterns": {}
            }
          },
          "XMLClassifier": {
            "type": "structure",
            "required": [
              "Classification",
              "Name"
            ],
            "members": {
              "Classification": {},
              "Name": {},
              "RowTag": {}
            }
          },
          "JsonClassifier": {
            "type": "structure",
            "required": [
              "Name",
              "JsonPath"
            ],
            "members": {
              "Name": {},
              "JsonPath": {}
            }
          },
          "CsvClassifier": {
            "type": "structure",
            "required": [
              "Name"
            ],
            "members": {
              "Name": {},
              "Delimiter": {},
              "QuoteSymbol": {},
              "ContainsHeader": {},
              "Header": {
                "shape": "S6x"
              },
              "DisableValueTrimming": {
                "type": "boolean"
              },
              "AllowSingleColumn": {
                "type": "boolean"
              }
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "CreateConnection": {
      "input": {
        "type": "structure",
        "required": [
          "ConnectionInput"
        ],
        "members": {
          "CatalogId": {},
          "ConnectionInput": {
            "shape": "S70"
          },
          "Tags": {
            "shape": "S6g"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "CreateCrawler": {
      "input": {
        "type": "structure",
        "required": [
          "Name",
          "Role",
          "Targets"
        ],
        "members": {
          "Name": {},
          "Role": {},
          "DatabaseName": {},
          "Description": {},
          "Targets": {
            "shape": "S29"
          },
          "Schedule": {},
          "Classifiers": {
            "shape": "S2s"
          },
          "TablePrefix": {},
          "SchemaChangePolicy": {
            "shape": "S2v"
          },
          "RecrawlPolicy": {
            "shape": "S2t"
          },
          "LineageConfiguration": {
            "shape": "S2y"
          },
          "Configuration": {},
          "CrawlerSecurityConfiguration": {},
          "Tags": {
            "shape": "S6g"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "CreateDatabase": {
      "input": {
        "type": "structure",
        "required": [
          "DatabaseInput"
        ],
        "members": {
          "CatalogId": {},
          "DatabaseInput": {
            "shape": "S7b"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "CreateDevEndpoint": {
      "input": {
        "type": "structure",
        "required": [
          "EndpointName",
          "RoleArn"
        ],
        "members": {
          "EndpointName": {},
          "RoleArn": {},
          "SecurityGroupIds": {
            "shape": "S3k"
          },
          "SubnetId": {},
          "PublicKey": {},
          "PublicKeys": {
            "shape": "S3o"
          },
          "NumberOfNodes": {
            "type": "integer"
          },
          "WorkerType": {},
          "GlueVersion": {},
          "NumberOfWorkers": {
            "type": "integer"
          },
          "ExtraPythonLibsS3Path": {},
          "ExtraJarsS3Path": {},
          "SecurityConfiguration": {},
          "Tags": {
            "shape": "S6g"
          },
          "Arguments": {
            "shape": "S3p"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "EndpointName": {},
          "Status": {},
          "SecurityGroupIds": {
            "shape": "S3k"
          },
          "SubnetId": {},
          "RoleArn": {},
          "YarnEndpointAddress": {},
          "ZeppelinRemoteSparkInterpreterPort": {
            "type": "integer"
          },
          "NumberOfNodes": {
            "type": "integer"
          },
          "WorkerType": {},
          "GlueVersion": {},
          "NumberOfWorkers": {
            "type": "integer"
          },
          "AvailabilityZone": {},
          "VpcId": {},
          "ExtraPythonLibsS3Path": {},
          "ExtraJarsS3Path": {},
          "FailureReason": {},
          "SecurityConfiguration": {},
          "CreatedTimestamp": {
            "type": "timestamp"
          },
          "Arguments": {
            "shape": "S3p"
          }
        }
      }
    },
    "CreateJob": {
      "input": {
        "type": "structure",
        "required": [
          "Name",
          "Role",
          "Command"
        ],
        "members": {
          "Name": {},
          "Description": {},
          "LogUri": {},
          "Role": {},
          "ExecutionProperty": {
            "shape": "S3x"
          },
          "Command": {
            "shape": "S3z"
          },
          "DefaultArguments": {
            "shape": "S42"
          },
          "NonOverridableArguments": {
            "shape": "S42"
          },
          "Connections": {
            "shape": "S43"
          },
          "MaxRetries": {
            "type": "integer"
          },
          "AllocatedCapacity": {
            "deprecated": true,
            "deprecatedMessage": "This property is deprecated, use MaxCapacity instead.",
            "type": "integer"
          },
          "Timeout": {
            "type": "integer"
          },
          "MaxCapacity": {
            "type": "double"
          },
          "SecurityConfiguration": {},
          "Tags": {
            "shape": "S6g"
          },
          "NotificationProperty": {
            "shape": "S47"
          },
          "GlueVersion": {},
          "NumberOfWorkers": {
            "type": "integer"
          },
          "WorkerType": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Name": {}
        }
      }
    },
    "CreateMLTransform": {
      "input": {
        "type": "structure",
        "required": [
          "Name",
          "InputRecordTables",
          "Parameters",
          "Role"
        ],
        "members": {
          "Name": {},
          "Description": {},
          "InputRecordTables": {
            "shape": "S7q"
          },
          "Parameters": {
            "shape": "S7s"
          },
          "Role": {},
          "GlueVersion": {},
          "MaxCapacity": {
            "type": "double"
          },
          "WorkerType": {},
          "NumberOfWorkers": {
            "type": "integer"
          },
          "Timeout": {
            "type": "integer"
          },
          "MaxRetries": {
            "type": "integer"
          },
          "Tags": {
            "shape": "S6g"
          },
          "TransformEncryption": {
            "shape": "S7x"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TransformId": {}
        }
      }
    },
    "CreatePartition": {
      "input": {
        "type": "structure",
        "required": [
          "DatabaseName",
          "TableName",
          "PartitionInput"
        ],
        "members": {
          "CatalogId": {},
          "DatabaseName": {},
          "TableName": {},
          "PartitionInput": {
            "shape": "S5"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "CreatePartitionIndex": {
      "input": {
        "type": "structure",
        "required": [
          "DatabaseName",
          "TableName",
          "PartitionIndex"
        ],
        "members": {
          "CatalogId": {},
          "DatabaseName": {},
          "TableName": {},
          "PartitionIndex": {
            "shape": "S84"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "CreateRegistry": {
      "input": {
        "type": "structure",
        "required": [
          "RegistryName"
        ],
        "members": {
          "RegistryName": {},
          "Description": {},
          "Tags": {
            "shape": "S6g"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "RegistryArn": {},
          "RegistryName": {},
          "Description": {},
          "Tags": {
            "shape": "S6g"
          }
        }
      }
    },
    "CreateSchema": {
      "input": {
        "type": "structure",
        "required": [
          "SchemaName",
          "DataFormat"
        ],
        "members": {
          "RegistryId": {
            "shape": "S8a"
          },
          "SchemaName": {},
          "DataFormat": {},
          "Compatibility": {},
          "Description": {},
          "Tags": {
            "shape": "S6g"
          },
          "SchemaDefinition": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "RegistryName": {},
          "RegistryArn": {},
          "SchemaName": {},
          "SchemaArn": {},
          "Description": {},
          "DataFormat": {},
          "Compatibility": {},
          "SchemaCheckpoint": {
            "type": "long"
          },
          "LatestSchemaVersion": {
            "type": "long"
          },
          "NextSchemaVersion": {
            "type": "long"
          },
          "SchemaStatus": {},
          "Tags": {
            "shape": "S6g"
          },
          "SchemaVersionId": {},
          "SchemaVersionStatus": {}
        }
      }
    },
    "CreateScript": {
      "input": {
        "type": "structure",
        "members": {
          "DagNodes": {
            "shape": "S8h"
          },
          "DagEdges": {
            "shape": "S8p"
          },
          "Language": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "PythonScript": {},
          "ScalaCode": {}
        }
      }
    },
    "CreateSecurityConfiguration": {
      "input": {
        "type": "structure",
        "required": [
          "Name",
          "EncryptionConfiguration"
        ],
        "members": {
          "Name": {},
          "EncryptionConfiguration": {
            "shape": "S8w"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Name": {},
          "CreatedTimestamp": {
            "type": "timestamp"
          }
        }
      }
    },
    "CreateTable": {
      "input": {
        "type": "structure",
        "required": [
          "DatabaseName",
          "TableInput"
        ],
        "members": {
          "CatalogId": {},
          "DatabaseName": {},
          "TableInput": {
            "shape": "S97"
          },
          "PartitionIndexes": {
            "type": "list",
            "member": {
              "shape": "S84"
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "CreateTrigger": {
      "input": {
        "type": "structure",
        "required": [
          "Name",
          "Type",
          "Actions"
        ],
        "members": {
          "Name": {},
          "WorkflowName": {},
          "Type": {},
          "Schedule": {},
          "Predicate": {
            "shape": "S4o"
          },
          "Actions": {
            "shape": "S4m"
          },
          "Description": {},
          "StartOnCreation": {
            "type": "boolean"
          },
          "Tags": {
            "shape": "S6g"
          },
          "EventBatchingCondition": {
            "shape": "S4v"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Name": {}
        }
      }
    },
    "CreateUserDefinedFunction": {
      "input": {
        "type": "structure",
        "required": [
          "DatabaseName",
          "FunctionInput"
        ],
        "members": {
          "CatalogId": {},
          "DatabaseName": {},
          "FunctionInput": {
            "shape": "S9i"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "CreateWorkflow": {
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {},
          "Description": {},
          "DefaultRunProperties": {
            "shape": "S53"
          },
          "Tags": {
            "shape": "S6g"
          },
          "MaxConcurrentRuns": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Name": {}
        }
      }
    },
    "DeleteBlueprint": {
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Name": {}
        }
      }
    },
    "DeleteClassifier": {
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteColumnStatisticsForPartition": {
      "input": {
        "type": "structure",
        "required": [
          "DatabaseName",
          "TableName",
          "PartitionValues",
          "ColumnName"
        ],
        "members": {
          "CatalogId": {},
          "DatabaseName": {},
          "TableName": {},
          "PartitionValues": {
            "shape": "S6"
          },
          "ColumnName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteColumnStatisticsForTable": {
      "input": {
        "type": "structure",
        "required": [
          "DatabaseName",
          "TableName",
          "ColumnName"
        ],
        "members": {
          "CatalogId": {},
          "DatabaseName": {},
          "TableName": {},
          "ColumnName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteConnection": {
      "input": {
        "type": "structure",
        "required": [
          "ConnectionName"
        ],
        "members": {
          "CatalogId": {},
          "ConnectionName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteCrawler": {
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteDatabase": {
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "CatalogId": {},
          "Name": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteDevEndpoint": {
      "input": {
        "type": "structure",
        "required": [
          "EndpointName"
        ],
        "members": {
          "EndpointName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteJob": {
      "input": {
        "type": "structure",
        "required": [
          "JobName"
        ],
        "members": {
          "JobName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "JobName": {}
        }
      }
    },
    "DeleteMLTransform": {
      "input": {
        "type": "structure",
        "required": [
          "TransformId"
        ],
        "members": {
          "TransformId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TransformId": {}
        }
      }
    },
    "DeletePartition": {
      "input": {
        "type": "structure",
        "required": [
          "DatabaseName",
          "TableName",
          "PartitionValues"
        ],
        "members": {
          "CatalogId": {},
          "DatabaseName": {},
          "TableName": {},
          "PartitionValues": {
            "shape": "S6"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeletePartitionIndex": {
      "input": {
        "type": "structure",
        "required": [
          "DatabaseName",
          "TableName",
          "IndexName"
        ],
        "members": {
          "CatalogId": {},
          "DatabaseName": {},
          "TableName": {},
          "IndexName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteRegistry": {
      "input": {
        "type": "structure",
        "required": [
          "RegistryId"
        ],
        "members": {
          "RegistryId": {
            "shape": "S8a"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "RegistryName": {},
          "RegistryArn": {},
          "Status": {}
        }
      }
    },
    "DeleteResourcePolicy": {
      "input": {
        "type": "structure",
        "members": {
          "PolicyHashCondition": {},
          "ResourceArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteSchema": {
      "input": {
        "type": "structure",
        "required": [
          "SchemaId"
        ],
        "members": {
          "SchemaId": {
            "shape": "Sv"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "SchemaArn": {},
          "SchemaName": {},
          "Status": {}
        }
      }
    },
    "DeleteSchemaVersions": {
      "input": {
        "type": "structure",
        "required": [
          "SchemaId",
          "Versions"
        ],
        "members": {
          "SchemaId": {
            "shape": "Sv"
          },
          "Versions": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "SchemaVersionErrors": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "VersionNumber": {
                  "type": "long"
                },
                "ErrorDetails": {
                  "type": "structure",
                  "members": {
                    "ErrorCode": {},
                    "ErrorMessage": {}
                  }
                }
              }
            }
          }
        }
      }
    },
    "DeleteSecurityConfiguration": {
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteTable": {
      "input": {
        "type": "structure",
        "required": [
          "DatabaseName",
          "Name"
        ],
        "members": {
          "CatalogId": {},
          "DatabaseName": {},
          "Name": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteTableVersion": {
      "input": {
        "type": "structure",
        "required": [
          "DatabaseName",
          "TableName",
          "VersionId"
        ],
        "members": {
          "CatalogId": {},
          "DatabaseName": {},
          "TableName": {},
          "VersionId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteTrigger": {
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Name": {}
        }
      }
    },
    "DeleteUserDefinedFunction": {
      "input": {
        "type": "structure",
        "required": [
          "DatabaseName",
          "FunctionName"
        ],
        "members": {
          "CatalogId": {},
          "DatabaseName": {},
          "FunctionName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteWorkflow": {
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Name": {}
        }
      }
    },
    "GetBlueprint": {
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {},
          "IncludeBlueprint": {
            "type": "boolean"
          },
          "IncludeParameterSpec": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Blueprint": {
            "shape": "S1u"
          }
        }
      }
    },
    "GetBlueprintRun": {
      "input": {
        "type": "structure",
        "required": [
          "BlueprintName",
          "RunId"
        ],
        "members": {
          "BlueprintName": {},
          "RunId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "BlueprintRun": {
            "shape": "Sb9"
          }
        }
      }
    },
    "GetBlueprintRuns": {
      "input": {
        "type": "structure",
        "required": [
          "BlueprintName"
        ],
        "members": {
          "BlueprintName": {},
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "BlueprintRuns": {
            "type": "list",
            "member": {
              "shape": "Sb9"
            }
          },
          "NextToken": {}
        }
      }
    },
    "GetCatalogImportStatus": {
      "input": {
        "type": "structure",
        "members": {
          "CatalogId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ImportStatus": {
            "type": "structure",
            "members": {
              "ImportCompleted": {
                "type": "boolean"
              },
              "ImportTime": {
                "type": "timestamp"
              },
              "ImportedBy": {}
            }
          }
        }
      }
    },
    "GetClassifier": {
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Classifier": {
            "shape": "Sbn"
          }
        }
      }
    },
    "GetClassifiers": {
      "input": {
        "type": "structure",
        "members": {
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Classifiers": {
            "type": "list",
            "member": {
              "shape": "Sbn"
            }
          },
          "NextToken": {}
        }
      }
    },
    "GetColumnStatisticsForPartition": {
      "input": {
        "type": "structure",
        "required": [
          "DatabaseName",
          "TableName",
          "PartitionValues",
          "ColumnNames"
        ],
        "members": {
          "CatalogId": {},
          "DatabaseName": {},
          "TableName": {},
          "PartitionValues": {
            "shape": "S6"
          },
          "ColumnNames": {
            "shape": "Sbx"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ColumnStatisticsList": {
            "shape": "Sbz"
          },
          "Errors": {
            "shape": "Sch"
          }
        }
      }
    },
    "GetColumnStatisticsForTable": {
      "input": {
        "type": "structure",
        "required": [
          "DatabaseName",
          "TableName",
          "ColumnNames"
        ],
        "members": {
          "CatalogId": {},
          "DatabaseName": {},
          "TableName": {},
          "ColumnNames": {
            "shape": "Sbx"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ColumnStatisticsList": {
            "shape": "Sbz"
          },
          "Errors": {
            "shape": "Sch"
          }
        }
      }
    },
    "GetConnection": {
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "CatalogId": {},
          "Name": {},
          "HidePassword": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Connection": {
            "shape": "Scn"
          }
        }
      }
    },
    "GetConnections": {
      "input": {
        "type": "structure",
        "members": {
          "CatalogId": {},
          "Filter": {
            "type": "structure",
            "members": {
              "MatchCriteria": {
                "shape": "S72"
              },
              "ConnectionType": {}
            }
          },
          "HidePassword": {
            "type": "boolean"
          },
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ConnectionList": {
            "type": "list",
            "member": {
              "shape": "Scn"
            }
          },
          "NextToken": {}
        }
      }
    },
    "GetCrawler": {
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Crawler": {
            "shape": "S27"
          }
        }
      }
    },
    "GetCrawlerMetrics": {
      "input": {
        "type": "structure",
        "members": {
          "CrawlerNameList": {
            "shape": "S24"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "CrawlerMetricsList": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "CrawlerName": {},
                "TimeLeftSeconds": {
                  "type": "double"
                },
                "StillEstimating": {
                  "type": "boolean"
                },
                "LastRuntimeSeconds": {
                  "type": "double"
                },
                "MedianRuntimeSeconds": {
                  "type": "double"
                },
                "TablesCreated": {
                  "type": "integer"
                },
                "TablesUpdated": {
                  "type": "integer"
                },
                "TablesDeleted": {
                  "type": "integer"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "GetCrawlers": {
      "input": {
        "type": "structure",
        "members": {
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Crawlers": {
            "shape": "S26"
          },
          "NextToken": {}
        }
      }
    },
    "GetDataCatalogEncryptionSettings": {
      "input": {
        "type": "structure",
        "members": {
          "CatalogId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DataCatalogEncryptionSettings": {
            "shape": "Sd2"
          }
        }
      }
    },
    "GetDatabase": {
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "CatalogId": {},
          "Name": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Database": {
            "shape": "Sd8"
          }
        }
      }
    },
    "GetDatabases": {
      "input": {
        "type": "structure",
        "members": {
          "CatalogId": {},
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          },
          "ResourceShareType": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "DatabaseList"
        ],
        "members": {
          "DatabaseList": {
            "type": "list",
            "member": {
              "shape": "Sd8"
            }
          },
          "NextToken": {}
        }
      }
    },
    "GetDataflowGraph": {
      "input": {
        "type": "structure",
        "members": {
          "PythonScript": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DagNodes": {
            "shape": "S8h"
          },
          "DagEdges": {
            "shape": "S8p"
          }
        }
      }
    },
    "GetDevEndpoint": {
      "input": {
        "type": "structure",
        "required": [
          "EndpointName"
        ],
        "members": {
          "EndpointName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DevEndpoint": {
            "shape": "S3i"
          }
        }
      }
    },
    "GetDevEndpoints": {
      "input": {
        "type": "structure",
        "members": {
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DevEndpoints": {
            "shape": "S3h"
          },
          "NextToken": {}
        }
      }
    },
    "GetJob": {
      "input": {
        "type": "structure",
        "required": [
          "JobName"
        ],
        "members": {
          "JobName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Job": {
            "shape": "S3u"
          }
        }
      }
    },
    "GetJobBookmark": {
      "input": {
        "type": "structure",
        "required": [
          "JobName"
        ],
        "members": {
          "JobName": {},
          "RunId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "JobBookmarkEntry": {
            "shape": "Sdq"
          }
        }
      }
    },
    "GetJobRun": {
      "input": {
        "type": "structure",
        "required": [
          "JobName",
          "RunId"
        ],
        "members": {
          "JobName": {},
          "RunId": {},
          "PredecessorsIncluded": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "JobRun": {
            "shape": "S5e"
          }
        }
      }
    },
    "GetJobRuns": {
      "input": {
        "type": "structure",
        "required": [
          "JobName"
        ],
        "members": {
          "JobName": {},
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "JobRuns": {
            "shape": "S5d"
          },
          "NextToken": {}
        }
      }
    },
    "GetJobs": {
      "input": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Jobs": {
            "shape": "S3t"
          },
          "NextToken": {}
        }
      }
    },
    "GetMLTaskRun": {
      "input": {
        "type": "structure",
        "required": [
          "TransformId",
          "TaskRunId"
        ],
        "members": {
          "TransformId": {},
          "TaskRunId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TransformId": {},
          "TaskRunId": {},
          "Status": {},
          "LogGroupName": {},
          "Properties": {
            "shape": "Se0"
          },
          "ErrorString": {},
          "StartedOn": {
            "type": "timestamp"
          },
          "LastModifiedOn": {
            "type": "timestamp"
          },
          "CompletedOn": {
            "type": "timestamp"
          },
          "ExecutionTime": {
            "type": "integer"
          }
        }
      }
    },
    "GetMLTaskRuns": {
      "input": {
        "type": "structure",
        "required": [
          "TransformId"
        ],
        "members": {
          "TransformId": {},
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          },
          "Filter": {
            "type": "structure",
            "members": {
              "TaskRunType": {},
              "Status": {},
              "StartedBefore": {
                "type": "timestamp"
              },
              "StartedAfter": {
                "type": "timestamp"
              }
            }
          },
          "Sort": {
            "type": "structure",
            "required": [
              "Column",
              "SortDirection"
            ],
            "members": {
              "Column": {},
              "SortDirection": {}
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TaskRuns": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "TransformId": {},
                "TaskRunId": {},
                "Status": {},
                "LogGroupName": {},
                "Properties": {
                  "shape": "Se0"
                },
                "ErrorString": {},
                "StartedOn": {
                  "type": "timestamp"
                },
                "LastModifiedOn": {
                  "type": "timestamp"
                },
                "CompletedOn": {
                  "type": "timestamp"
                },
                "ExecutionTime": {
                  "type": "integer"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "GetMLTransform": {
      "input": {
        "type": "structure",
        "required": [
          "TransformId"
        ],
        "members": {
          "TransformId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TransformId": {},
          "Name": {},
          "Description": {},
          "Status": {},
          "CreatedOn": {
            "type": "timestamp"
          },
          "LastModifiedOn": {
            "type": "timestamp"
          },
          "InputRecordTables": {
            "shape": "S7q"
          },
          "Parameters": {
            "shape": "S7s"
          },
          "EvaluationMetrics": {
            "shape": "Sej"
          },
          "LabelCount": {
            "type": "integer"
          },
          "Schema": {
            "shape": "Seq"
          },
          "Role": {},
          "GlueVersion": {},
          "MaxCapacity": {
            "type": "double"
          },
          "WorkerType": {},
          "NumberOfWorkers": {
            "type": "integer"
          },
          "Timeout": {
            "type": "integer"
          },
          "MaxRetries": {
            "type": "integer"
          },
          "TransformEncryption": {
            "shape": "S7x"
          }
        }
      }
    },
    "GetMLTransforms": {
      "input": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          },
          "Filter": {
            "shape": "Set"
          },
          "Sort": {
            "shape": "Seu"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "Transforms"
        ],
        "members": {
          "Transforms": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "TransformId": {},
                "Name": {},
                "Description": {},
                "Status": {},
                "CreatedOn": {
                  "type": "timestamp"
                },
                "LastModifiedOn": {
                  "type": "timestamp"
                },
                "InputRecordTables": {
                  "shape": "S7q"
                },
                "Parameters": {
                  "shape": "S7s"
                },
                "EvaluationMetrics": {
                  "shape": "Sej"
                },
                "LabelCount": {
                  "type": "integer"
                },
                "Schema": {
                  "shape": "Seq"
                },
                "Role": {},
                "GlueVersion": {},
                "MaxCapacity": {
                  "type": "double"
                },
                "WorkerType": {},
                "NumberOfWorkers": {
                  "type": "integer"
                },
                "Timeout": {
                  "type": "integer"
                },
                "MaxRetries": {
                  "type": "integer"
                },
                "TransformEncryption": {
                  "shape": "S7x"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "GetMapping": {
      "input": {
        "type": "structure",
        "required": [
          "Source"
        ],
        "members": {
          "Source": {
            "shape": "Sf0"
          },
          "Sinks": {
            "shape": "Sf1"
          },
          "Location": {
            "shape": "Sf2"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "Mapping"
        ],
        "members": {
          "Mapping": {
            "shape": "Sf4"
          }
        }
      }
    },
    "GetPartition": {
      "input": {
        "type": "structure",
        "required": [
          "DatabaseName",
          "TableName",
          "PartitionValues"
        ],
        "members": {
          "CatalogId": {},
          "DatabaseName": {},
          "TableName": {},
          "PartitionValues": {
            "shape": "S6"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Partition": {
            "shape": "S4d"
          }
        }
      }
    },
    "GetPartitionIndexes": {
      "input": {
        "type": "structure",
        "required": [
          "DatabaseName",
          "TableName"
        ],
        "members": {
          "CatalogId": {},
          "DatabaseName": {},
          "TableName": {},
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "PartitionIndexDescriptorList": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "IndexName",
                "Keys",
                "IndexStatus"
              ],
              "members": {
                "IndexName": {},
                "Keys": {
                  "type": "list",
                  "member": {
                    "type": "structure",
                    "required": [
                      "Name",
                      "Type"
                    ],
                    "members": {
                      "Name": {},
                      "Type": {}
                    }
                  }
                },
                "IndexStatus": {},
                "BackfillErrors": {
                  "type": "list",
                  "member": {
                    "type": "structure",
                    "members": {
                      "Code": {},
                      "Partitions": {
                        "type": "list",
                        "member": {
                          "shape": "S1b"
                        }
                      }
                    }
                  }
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "GetPartitions": {
      "input": {
        "type": "structure",
        "required": [
          "DatabaseName",
          "TableName"
        ],
        "members": {
          "CatalogId": {},
          "DatabaseName": {},
          "TableName": {},
          "Expression": {},
          "NextToken": {},
          "Segment": {
            "type": "structure",
            "required": [
              "SegmentNumber",
              "TotalSegments"
            ],
            "members": {
              "SegmentNumber": {
                "type": "integer"
              },
              "TotalSegments": {
                "type": "integer"
              }
            }
          },
          "MaxResults": {
            "type": "integer"
          },
          "ExcludeColumnSchema": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Partitions": {
            "shape": "S4c"
          },
          "NextToken": {}
        }
      }
    },
    "GetPlan": {
      "input": {
        "type": "structure",
        "required": [
          "Mapping",
          "Source"
        ],
        "members": {
          "Mapping": {
            "shape": "Sf4"
          },
          "Source": {
            "shape": "Sf0"
          },
          "Sinks": {
            "shape": "Sf1"
          },
          "Location": {
            "shape": "Sf2"
          },
          "Language": {},
          "AdditionalPlanOptionsMap": {
            "type": "map",
            "key": {},
            "value": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "PythonScript": {},
          "ScalaCode": {}
        }
      }
    },
    "GetRegistry": {
      "input": {
        "type": "structure",
        "required": [
          "RegistryId"
        ],
        "members": {
          "RegistryId": {
            "shape": "S8a"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "RegistryName": {},
          "RegistryArn": {},
          "Description": {},
          "Status": {},
          "CreatedTime": {},
          "UpdatedTime": {}
        }
      }
    },
    "GetResourcePolicies": {
      "input": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "GetResourcePoliciesResponseList": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "PolicyInJson": {},
                "PolicyHash": {},
                "CreateTime": {
                  "type": "timestamp"
                },
                "UpdateTime": {
                  "type": "timestamp"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "GetResourcePolicy": {
      "input": {
        "type": "structure",
        "members": {
          "ResourceArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "PolicyInJson": {},
          "PolicyHash": {},
          "CreateTime": {
            "type": "timestamp"
          },
          "UpdateTime": {
            "type": "timestamp"
          }
        }
      }
    },
    "GetSchema": {
      "input": {
        "type": "structure",
        "required": [
          "SchemaId"
        ],
        "members": {
          "SchemaId": {
            "shape": "Sv"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "RegistryName": {},
          "RegistryArn": {},
          "SchemaName": {},
          "SchemaArn": {},
          "Description": {},
          "DataFormat": {},
          "Compatibility": {},
          "SchemaCheckpoint": {
            "type": "long"
          },
          "LatestSchemaVersion": {
            "type": "long"
          },
          "NextSchemaVersion": {
            "type": "long"
          },
          "SchemaStatus": {},
          "CreatedTime": {},
          "UpdatedTime": {}
        }
      }
    },
    "GetSchemaByDefinition": {
      "input": {
        "type": "structure",
        "required": [
          "SchemaId",
          "SchemaDefinition"
        ],
        "members": {
          "SchemaId": {
            "shape": "Sv"
          },
          "SchemaDefinition": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "SchemaVersionId": {},
          "SchemaArn": {},
          "DataFormat": {},
          "Status": {},
          "CreatedTime": {}
        }
      }
    },
    "GetSchemaVersion": {
      "input": {
        "type": "structure",
        "members": {
          "SchemaId": {
            "shape": "Sv"
          },
          "SchemaVersionId": {},
          "SchemaVersionNumber": {
            "shape": "Sgb"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "SchemaVersionId": {},
          "SchemaDefinition": {},
          "DataFormat": {},
          "SchemaArn": {},
          "VersionNumber": {
            "type": "long"
          },
          "Status": {},
          "CreatedTime": {}
        }
      }
    },
    "GetSchemaVersionsDiff": {
      "input": {
        "type": "structure",
        "required": [
          "SchemaId",
          "FirstSchemaVersionNumber",
          "SecondSchemaVersionNumber",
          "SchemaDiffType"
        ],
        "members": {
          "SchemaId": {
            "shape": "Sv"
          },
          "FirstSchemaVersionNumber": {
            "shape": "Sgb"
          },
          "SecondSchemaVersionNumber": {
            "shape": "Sgb"
          },
          "SchemaDiffType": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Diff": {}
        }
      }
    },
    "GetSecurityConfiguration": {
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "SecurityConfiguration": {
            "shape": "Sgk"
          }
        }
      }
    },
    "GetSecurityConfigurations": {
      "input": {
        "type": "structure",
        "members": {
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "SecurityConfigurations": {
            "type": "list",
            "member": {
              "shape": "Sgk"
            }
          },
          "NextToken": {}
        }
      }
    },
    "GetTable": {
      "input": {
        "type": "structure",
        "required": [
          "DatabaseName",
          "Name"
        ],
        "members": {
          "CatalogId": {},
          "DatabaseName": {},
          "Name": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Table": {
            "shape": "Sgq"
          }
        }
      }
    },
    "GetTableVersion": {
      "input": {
        "type": "structure",
        "required": [
          "DatabaseName",
          "TableName"
        ],
        "members": {
          "CatalogId": {},
          "DatabaseName": {},
          "TableName": {},
          "VersionId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TableVersion": {
            "shape": "Sgt"
          }
        }
      }
    },
    "GetTableVersions": {
      "input": {
        "type": "structure",
        "required": [
          "DatabaseName",
          "TableName"
        ],
        "members": {
          "CatalogId": {},
          "DatabaseName": {},
          "TableName": {},
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TableVersions": {
            "type": "list",
            "member": {
              "shape": "Sgt"
            }
          },
          "NextToken": {}
        }
      }
    },
    "GetTables": {
      "input": {
        "type": "structure",
        "required": [
          "DatabaseName"
        ],
        "members": {
          "CatalogId": {},
          "DatabaseName": {},
          "Expression": {},
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TableList": {
            "shape": "Sh0"
          },
          "NextToken": {}
        }
      }
    },
    "GetTags": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceArn"
        ],
        "members": {
          "ResourceArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Tags": {
            "shape": "S6g"
          }
        }
      }
    },
    "GetTrigger": {
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Trigger": {
            "shape": "S4i"
          }
        }
      }
    },
    "GetTriggers": {
      "input": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "DependentJobName": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Triggers": {
            "shape": "S4h"
          },
          "NextToken": {}
        }
      }
    },
    "GetUserDefinedFunction": {
      "input": {
        "type": "structure",
        "required": [
          "DatabaseName",
          "FunctionName"
        ],
        "members": {
          "CatalogId": {},
          "DatabaseName": {},
          "FunctionName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "UserDefinedFunction": {
            "shape": "Sh9"
          }
        }
      }
    },
    "GetUserDefinedFunctions": {
      "input": {
        "type": "structure",
        "required": [
          "Pattern"
        ],
        "members": {
          "CatalogId": {},
          "DatabaseName": {},
          "Pattern": {},
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "UserDefinedFunctions": {
            "type": "list",
            "member": {
              "shape": "Sh9"
            }
          },
          "NextToken": {}
        }
      }
    },
    "GetWorkflow": {
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {},
          "IncludeGraph": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Workflow": {
            "shape": "S52"
          }
        }
      }
    },
    "GetWorkflowRun": {
      "input": {
        "type": "structure",
        "required": [
          "Name",
          "RunId"
        ],
        "members": {
          "Name": {},
          "RunId": {},
          "IncludeGraph": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Run": {
            "shape": "S54"
          }
        }
      }
    },
    "GetWorkflowRunProperties": {
      "input": {
        "type": "structure",
        "required": [
          "Name",
          "RunId"
        ],
        "members": {
          "Name": {},
          "RunId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "RunProperties": {
            "shape": "S53"
          }
        }
      }
    },
    "GetWorkflowRuns": {
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {},
          "IncludeGraph": {
            "type": "boolean"
          },
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Runs": {
            "type": "list",
            "member": {
              "shape": "S54"
            }
          },
          "NextToken": {}
        }
      }
    },
    "ImportCatalogToGlue": {
      "input": {
        "type": "structure",
        "members": {
          "CatalogId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "ListBlueprints": {
      "input": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          },
          "Tags": {
            "shape": "S6g"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Blueprints": {
            "shape": "S22"
          },
          "NextToken": {}
        }
      }
    },
    "ListCrawlers": {
      "input": {
        "type": "structure",
        "members": {
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {},
          "Tags": {
            "shape": "S6g"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "CrawlerNames": {
            "shape": "S24"
          },
          "NextToken": {}
        }
      }
    },
    "ListDevEndpoints": {
      "input": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          },
          "Tags": {
            "shape": "S6g"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DevEndpointNames": {
            "type": "list",
            "member": {}
          },
          "NextToken": {}
        }
      }
    },
    "ListJobs": {
      "input": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          },
          "Tags": {
            "shape": "S6g"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "JobNames": {
            "shape": "S3r"
          },
          "NextToken": {}
        }
      }
    },
    "ListMLTransforms": {
      "input": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          },
          "Filter": {
            "shape": "Set"
          },
          "Sort": {
            "shape": "Seu"
          },
          "Tags": {
            "shape": "S6g"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "TransformIds"
        ],
        "members": {
          "TransformIds": {
            "type": "list",
            "member": {}
          },
          "NextToken": {}
        }
      }
    },
    "ListRegistries": {
      "input": {
        "type": "structure",
        "members": {
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Registries": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "RegistryName": {},
                "RegistryArn": {},
                "Description": {},
                "Status": {},
                "CreatedTime": {},
                "UpdatedTime": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListSchemaVersions": {
      "input": {
        "type": "structure",
        "required": [
          "SchemaId"
        ],
        "members": {
          "SchemaId": {
            "shape": "Sv"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Schemas": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "SchemaArn": {},
                "SchemaVersionId": {},
                "VersionNumber": {
                  "type": "long"
                },
                "Status": {},
                "CreatedTime": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListSchemas": {
      "input": {
        "type": "structure",
        "members": {
          "RegistryId": {
            "shape": "S8a"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Schemas": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "RegistryName": {},
                "SchemaName": {},
                "SchemaArn": {},
                "Description": {},
                "SchemaStatus": {},
                "CreatedTime": {},
                "UpdatedTime": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListTriggers": {
      "input": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "DependentJobName": {},
          "MaxResults": {
            "type": "integer"
          },
          "Tags": {
            "shape": "S6g"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TriggerNames": {
            "shape": "S4f"
          },
          "NextToken": {}
        }
      }
    },
    "ListWorkflows": {
      "input": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Workflows": {
            "shape": "S4z"
          },
          "NextToken": {}
        }
      }
    },
    "PutDataCatalogEncryptionSettings": {
      "input": {
        "type": "structure",
        "required": [
          "DataCatalogEncryptionSettings"
        ],
        "members": {
          "CatalogId": {},
          "DataCatalogEncryptionSettings": {
            "shape": "Sd2"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "PutResourcePolicy": {
      "input": {
        "type": "structure",
        "required": [
          "PolicyInJson"
        ],
        "members": {
          "PolicyInJson": {},
          "ResourceArn": {},
          "PolicyHashCondition": {},
          "PolicyExistsCondition": {},
          "EnableHybrid": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "PolicyHash": {}
        }
      }
    },
    "PutSchemaVersionMetadata": {
      "input": {
        "type": "structure",
        "required": [
          "MetadataKeyValue"
        ],
        "members": {
          "SchemaId": {
            "shape": "Sv"
          },
          "SchemaVersionNumber": {
            "shape": "Sgb"
          },
          "SchemaVersionId": {},
          "MetadataKeyValue": {
            "shape": "Sip"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "SchemaArn": {},
          "SchemaName": {},
          "RegistryName": {},
          "LatestVersion": {
            "type": "boolean"
          },
          "VersionNumber": {
            "type": "long"
          },
          "SchemaVersionId": {},
          "MetadataKey": {},
          "MetadataValue": {}
        }
      }
    },
    "PutWorkflowRunProperties": {
      "input": {
        "type": "structure",
        "required": [
          "Name",
          "RunId",
          "RunProperties"
        ],
        "members": {
          "Name": {},
          "RunId": {},
          "RunProperties": {
            "shape": "S53"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "QuerySchemaVersionMetadata": {
      "input": {
        "type": "structure",
        "members": {
          "SchemaId": {
            "shape": "Sv"
          },
          "SchemaVersionNumber": {
            "shape": "Sgb"
          },
          "SchemaVersionId": {},
          "MetadataList": {
            "type": "list",
            "member": {
              "shape": "Sip"
            }
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "MetadataInfoMap": {
            "type": "map",
            "key": {},
            "value": {
              "type": "structure",
              "members": {
                "MetadataValue": {},
                "CreatedTime": {},
                "OtherMetadataValueList": {
                  "type": "list",
                  "member": {
                    "type": "structure",
                    "members": {
                      "MetadataValue": {},
                      "CreatedTime": {}
                    }
                  }
                }
              }
            }
          },
          "SchemaVersionId": {},
          "NextToken": {}
        }
      }
    },
    "RegisterSchemaVersion": {
      "input": {
        "type": "structure",
        "required": [
          "SchemaId",
          "SchemaDefinition"
        ],
        "members": {
          "SchemaId": {
            "shape": "Sv"
          },
          "SchemaDefinition": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "SchemaVersionId": {},
          "VersionNumber": {
            "type": "long"
          },
          "Status": {}
        }
      }
    },
    "RemoveSchemaVersionMetadata": {
      "input": {
        "type": "structure",
        "required": [
          "MetadataKeyValue"
        ],
        "members": {
          "SchemaId": {
            "shape": "Sv"
          },
          "SchemaVersionNumber": {
            "shape": "Sgb"
          },
          "SchemaVersionId": {},
          "MetadataKeyValue": {
            "shape": "Sip"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "SchemaArn": {},
          "SchemaName": {},
          "RegistryName": {},
          "LatestVersion": {
            "type": "boolean"
          },
          "VersionNumber": {
            "type": "long"
          },
          "SchemaVersionId": {},
          "MetadataKey": {},
          "MetadataValue": {}
        }
      }
    },
    "ResetJobBookmark": {
      "input": {
        "type": "structure",
        "required": [
          "JobName"
        ],
        "members": {
          "JobName": {},
          "RunId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "JobBookmarkEntry": {
            "shape": "Sdq"
          }
        }
      }
    },
    "ResumeWorkflowRun": {
      "input": {
        "type": "structure",
        "required": [
          "Name",
          "RunId",
          "NodeIds"
        ],
        "members": {
          "Name": {},
          "RunId": {},
          "NodeIds": {
            "shape": "Sja"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "RunId": {},
          "NodeIds": {
            "shape": "Sja"
          }
        }
      }
    },
    "SearchTables": {
      "input": {
        "type": "structure",
        "members": {
          "CatalogId": {},
          "NextToken": {},
          "Filters": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Key": {},
                "Value": {},
                "Comparator": {}
              }
            }
          },
          "SearchText": {},
          "SortCriteria": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "FieldName": {},
                "Sort": {}
              }
            }
          },
          "MaxResults": {
            "type": "integer"
          },
          "ResourceShareType": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "TableList": {
            "shape": "Sh0"
          }
        }
      }
    },
    "StartBlueprintRun": {
      "input": {
        "type": "structure",
        "required": [
          "BlueprintName",
          "RoleArn"
        ],
        "members": {
          "BlueprintName": {},
          "Parameters": {},
          "RoleArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "RunId": {}
        }
      }
    },
    "StartCrawler": {
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "StartCrawlerSchedule": {
      "input": {
        "type": "structure",
        "required": [
          "CrawlerName"
        ],
        "members": {
          "CrawlerName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "StartExportLabelsTaskRun": {
      "input": {
        "type": "structure",
        "required": [
          "TransformId",
          "OutputS3Path"
        ],
        "members": {
          "TransformId": {},
          "OutputS3Path": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TaskRunId": {}
        }
      }
    },
    "StartImportLabelsTaskRun": {
      "input": {
        "type": "structure",
        "required": [
          "TransformId",
          "InputS3Path"
        ],
        "members": {
          "TransformId": {},
          "InputS3Path": {},
          "ReplaceAllLabels": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TaskRunId": {}
        }
      }
    },
    "StartJobRun": {
      "input": {
        "type": "structure",
        "required": [
          "JobName"
        ],
        "members": {
          "JobName": {},
          "JobRunId": {},
          "Arguments": {
            "shape": "S42"
          },
          "AllocatedCapacity": {
            "deprecated": true,
            "deprecatedMessage": "This property is deprecated, use MaxCapacity instead.",
            "type": "integer"
          },
          "Timeout": {
            "type": "integer"
          },
          "MaxCapacity": {
            "type": "double"
          },
          "SecurityConfiguration": {},
          "NotificationProperty": {
            "shape": "S47"
          },
          "WorkerType": {},
          "NumberOfWorkers": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "JobRunId": {}
        }
      }
    },
    "StartMLEvaluationTaskRun": {
      "input": {
        "type": "structure",
        "required": [
          "TransformId"
        ],
        "members": {
          "TransformId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TaskRunId": {}
        }
      }
    },
    "StartMLLabelingSetGenerationTaskRun": {
      "input": {
        "type": "structure",
        "required": [
          "TransformId",
          "OutputS3Path"
        ],
        "members": {
          "TransformId": {},
          "OutputS3Path": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TaskRunId": {}
        }
      }
    },
    "StartTrigger": {
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Name": {}
        }
      }
    },
    "StartWorkflowRun": {
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "RunId": {}
        }
      }
    },
    "StopCrawler": {
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "StopCrawlerSchedule": {
      "input": {
        "type": "structure",
        "required": [
          "CrawlerName"
        ],
        "members": {
          "CrawlerName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "StopTrigger": {
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Name": {}
        }
      }
    },
    "StopWorkflowRun": {
      "input": {
        "type": "structure",
        "required": [
          "Name",
          "RunId"
        ],
        "members": {
          "Name": {},
          "RunId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "TagResource": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceArn",
          "TagsToAdd"
        ],
        "members": {
          "ResourceArn": {},
          "TagsToAdd": {
            "shape": "S6g"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UntagResource": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceArn",
          "TagsToRemove"
        ],
        "members": {
          "ResourceArn": {},
          "TagsToRemove": {
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UpdateBlueprint": {
      "input": {
        "type": "structure",
        "required": [
          "Name",
          "BlueprintLocation"
        ],
        "members": {
          "Name": {},
          "Description": {},
          "BlueprintLocation": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Name": {}
        }
      }
    },
    "UpdateClassifier": {
      "input": {
        "type": "structure",
        "members": {
          "GrokClassifier": {
            "type": "structure",
            "required": [
              "Name"
            ],
            "members": {
              "Name": {},
              "Classification": {},
              "GrokPattern": {},
              "CustomPatterns": {}
            }
          },
          "XMLClassifier": {
            "type": "structure",
            "required": [
              "Name"
            ],
            "members": {
              "Name": {},
              "Classification": {},
              "RowTag": {}
            }
          },
          "JsonClassifier": {
            "type": "structure",
            "required": [
              "Name"
            ],
            "members": {
              "Name": {},
              "JsonPath": {}
            }
          },
          "CsvClassifier": {
            "type": "structure",
            "required": [
              "Name"
            ],
            "members": {
              "Name": {},
              "Delimiter": {},
              "QuoteSymbol": {},
              "ContainsHeader": {},
              "Header": {
                "shape": "S6x"
              },
              "DisableValueTrimming": {
                "type": "boolean"
              },
              "AllowSingleColumn": {
                "type": "boolean"
              }
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UpdateColumnStatisticsForPartition": {
      "input": {
        "type": "structure",
        "required": [
          "DatabaseName",
          "TableName",
          "PartitionValues",
          "ColumnStatisticsList"
        ],
        "members": {
          "CatalogId": {},
          "DatabaseName": {},
          "TableName": {},
          "PartitionValues": {
            "shape": "S6"
          },
          "ColumnStatisticsList": {
            "shape": "Skq"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Errors": {
            "shape": "Sks"
          }
        }
      }
    },
    "UpdateColumnStatisticsForTable": {
      "input": {
        "type": "structure",
        "required": [
          "DatabaseName",
          "TableName",
          "ColumnStatisticsList"
        ],
        "members": {
          "CatalogId": {},
          "DatabaseName": {},
          "TableName": {},
          "ColumnStatisticsList": {
            "shape": "Skq"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Errors": {
            "shape": "Sks"
          }
        }
      }
    },
    "UpdateConnection": {
      "input": {
        "type": "structure",
        "required": [
          "Name",
          "ConnectionInput"
        ],
        "members": {
          "CatalogId": {},
          "Name": {},
          "ConnectionInput": {
            "shape": "S70"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UpdateCrawler": {
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {},
          "Role": {},
          "DatabaseName": {},
          "Description": {},
          "Targets": {
            "shape": "S29"
          },
          "Schedule": {},
          "Classifiers": {
            "shape": "S2s"
          },
          "TablePrefix": {},
          "SchemaChangePolicy": {
            "shape": "S2v"
          },
          "RecrawlPolicy": {
            "shape": "S2t"
          },
          "LineageConfiguration": {
            "shape": "S2y"
          },
          "Configuration": {},
          "CrawlerSecurityConfiguration": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UpdateCrawlerSchedule": {
      "input": {
        "type": "structure",
        "required": [
          "CrawlerName"
        ],
        "members": {
          "CrawlerName": {},
          "Schedule": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UpdateDatabase": {
      "input": {
        "type": "structure",
        "required": [
          "Name",
          "DatabaseInput"
        ],
        "members": {
          "CatalogId": {},
          "Name": {},
          "DatabaseInput": {
            "shape": "S7b"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UpdateDevEndpoint": {
      "input": {
        "type": "structure",
        "required": [
          "EndpointName"
        ],
        "members": {
          "EndpointName": {},
          "PublicKey": {},
          "AddPublicKeys": {
            "shape": "S3o"
          },
          "DeletePublicKeys": {
            "shape": "S3o"
          },
          "CustomLibraries": {
            "type": "structure",
            "members": {
              "ExtraPythonLibsS3Path": {},
              "ExtraJarsS3Path": {}
            }
          },
          "UpdateEtlLibraries": {
            "type": "boolean"
          },
          "DeleteArguments": {
            "shape": "S3k"
          },
          "AddArguments": {
            "shape": "S3p"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UpdateJob": {
      "input": {
        "type": "structure",
        "required": [
          "JobName",
          "JobUpdate"
        ],
        "members": {
          "JobName": {},
          "JobUpdate": {
            "type": "structure",
            "members": {
              "Description": {},
              "LogUri": {},
              "Role": {},
              "ExecutionProperty": {
                "shape": "S3x"
              },
              "Command": {
                "shape": "S3z"
              },
              "DefaultArguments": {
                "shape": "S42"
              },
              "NonOverridableArguments": {
                "shape": "S42"
              },
              "Connections": {
                "shape": "S43"
              },
              "MaxRetries": {
                "type": "integer"
              },
              "AllocatedCapacity": {
                "deprecated": true,
                "deprecatedMessage": "This property is deprecated, use MaxCapacity instead.",
                "type": "integer"
              },
              "Timeout": {
                "type": "integer"
              },
              "MaxCapacity": {
                "type": "double"
              },
              "WorkerType": {},
              "NumberOfWorkers": {
                "type": "integer"
              },
              "SecurityConfiguration": {},
              "NotificationProperty": {
                "shape": "S47"
              },
              "GlueVersion": {}
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "JobName": {}
        }
      }
    },
    "UpdateMLTransform": {
      "input": {
        "type": "structure",
        "required": [
          "TransformId"
        ],
        "members": {
          "TransformId": {},
          "Name": {},
          "Description": {},
          "Parameters": {
            "shape": "S7s"
          },
          "Role": {},
          "GlueVersion": {},
          "MaxCapacity": {
            "type": "double"
          },
          "WorkerType": {},
          "NumberOfWorkers": {
            "type": "integer"
          },
          "Timeout": {
            "type": "integer"
          },
          "MaxRetries": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TransformId": {}
        }
      }
    },
    "UpdatePartition": {
      "input": {
        "type": "structure",
        "required": [
          "DatabaseName",
          "TableName",
          "PartitionValueList",
          "PartitionInput"
        ],
        "members": {
          "CatalogId": {},
          "DatabaseName": {},
          "TableName": {},
          "PartitionValueList": {
            "shape": "S60"
          },
          "PartitionInput": {
            "shape": "S5"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UpdateRegistry": {
      "input": {
        "type": "structure",
        "required": [
          "RegistryId",
          "Description"
        ],
        "members": {
          "RegistryId": {
            "shape": "S8a"
          },
          "Description": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "RegistryName": {},
          "RegistryArn": {}
        }
      }
    },
    "UpdateSchema": {
      "input": {
        "type": "structure",
        "required": [
          "SchemaId"
        ],
        "members": {
          "SchemaId": {
            "shape": "Sv"
          },
          "SchemaVersionNumber": {
            "shape": "Sgb"
          },
          "Compatibility": {},
          "Description": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "SchemaArn": {},
          "SchemaName": {},
          "RegistryName": {}
        }
      }
    },
    "UpdateTable": {
      "input": {
        "type": "structure",
        "required": [
          "DatabaseName",
          "TableInput"
        ],
        "members": {
          "CatalogId": {},
          "DatabaseName": {},
          "TableInput": {
            "shape": "S97"
          },
          "SkipArchive": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UpdateTrigger": {
      "input": {
        "type": "structure",
        "required": [
          "Name",
          "TriggerUpdate"
        ],
        "members": {
          "Name": {},
          "TriggerUpdate": {
            "type": "structure",
            "members": {
              "Name": {},
              "Description": {},
              "Schedule": {},
              "Actions": {
                "shape": "S4m"
              },
              "Predicate": {
                "shape": "S4o"
              },
              "EventBatchingCondition": {
                "shape": "S4v"
              }
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Trigger": {
            "shape": "S4i"
          }
        }
      }
    },
    "UpdateUserDefinedFunction": {
      "input": {
        "type": "structure",
        "required": [
          "DatabaseName",
          "FunctionName",
          "FunctionInput"
        ],
        "members": {
          "CatalogId": {},
          "DatabaseName": {},
          "FunctionName": {},
          "FunctionInput": {
            "shape": "S9i"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UpdateWorkflow": {
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {},
          "Description": {},
          "DefaultRunProperties": {
            "shape": "S53"
          },
          "MaxConcurrentRuns": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Name": {}
        }
      }
    }
  },
  "shapes": {
    "S5": {
      "type": "structure",
      "members": {
        "Values": {
          "shape": "S6"
        },
        "LastAccessTime": {
          "type": "timestamp"
        },
        "StorageDescriptor": {
          "shape": "S9"
        },
        "Parameters": {
          "shape": "Se"
        },
        "LastAnalyzedTime": {
          "type": "timestamp"
        }
      }
    },
    "S6": {
      "type": "list",
      "member": {}
    },
    "S9": {
      "type": "structure",
      "members": {
        "Columns": {
          "shape": "Sa"
        },
        "Location": {},
        "InputFormat": {},
        "OutputFormat": {},
        "Compressed": {
          "type": "boolean"
        },
        "NumberOfBuckets": {
          "type": "integer"
        },
        "SerdeInfo": {
          "type": "structure",
          "members": {
            "Name": {},
            "SerializationLibrary": {},
            "Parameters": {
              "shape": "Se"
            }
          }
        },
        "BucketColumns": {
          "shape": "Sm"
        },
        "SortColumns": {
          "type": "list",
          "member": {
            "type": "structure",
            "required": [
              "Column",
              "SortOrder"
            ],
            "members": {
              "Column": {},
              "SortOrder": {
                "type": "integer"
              }
            }
          }
        },
        "Parameters": {
          "shape": "Se"
        },
        "SkewedInfo": {
          "type": "structure",
          "members": {
            "SkewedColumnNames": {
              "shape": "Sm"
            },
            "SkewedColumnValues": {
              "type": "list",
              "member": {}
            },
            "SkewedColumnValueLocationMaps": {
              "type": "map",
              "key": {},
              "value": {}
            }
          }
        },
        "StoredAsSubDirectories": {
          "type": "boolean"
        },
        "SchemaReference": {
          "type": "structure",
          "members": {
            "SchemaId": {
              "shape": "Sv"
            },
            "SchemaVersionId": {},
            "SchemaVersionNumber": {
              "type": "long"
            }
          }
        }
      }
    },
    "Sa": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {},
          "Type": {},
          "Comment": {},
          "Parameters": {
            "shape": "Se"
          }
        }
      }
    },
    "Se": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "Sm": {
      "type": "list",
      "member": {}
    },
    "Sv": {
      "type": "structure",
      "members": {
        "SchemaArn": {},
        "SchemaName": {},
        "RegistryName": {}
      }
    },
    "S11": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "PartitionValues": {
            "shape": "S6"
          },
          "ErrorDetail": {
            "shape": "S13"
          }
        }
      }
    },
    "S13": {
      "type": "structure",
      "members": {
        "ErrorCode": {},
        "ErrorMessage": {}
      }
    },
    "S1b": {
      "type": "structure",
      "required": [
        "Values"
      ],
      "members": {
        "Values": {
          "shape": "S6"
        }
      }
    },
    "S1u": {
      "type": "structure",
      "members": {
        "Name": {},
        "Description": {},
        "CreatedOn": {
          "type": "timestamp"
        },
        "LastModifiedOn": {
          "type": "timestamp"
        },
        "ParameterSpec": {},
        "BlueprintLocation": {},
        "BlueprintServiceLocation": {},
        "Status": {},
        "ErrorMessage": {},
        "LastActiveDefinition": {
          "type": "structure",
          "members": {
            "Description": {},
            "LastModifiedOn": {
              "type": "timestamp"
            },
            "ParameterSpec": {},
            "BlueprintLocation": {},
            "BlueprintServiceLocation": {}
          }
        }
      }
    },
    "S22": {
      "type": "list",
      "member": {}
    },
    "S24": {
      "type": "list",
      "member": {}
    },
    "S26": {
      "type": "list",
      "member": {
        "shape": "S27"
      }
    },
    "S27": {
      "type": "structure",
      "members": {
        "Name": {},
        "Role": {},
        "Targets": {
          "shape": "S29"
        },
        "DatabaseName": {},
        "Description": {},
        "Classifiers": {
          "shape": "S2s"
        },
        "RecrawlPolicy": {
          "shape": "S2t"
        },
        "SchemaChangePolicy": {
          "shape": "S2v"
        },
        "LineageConfiguration": {
          "shape": "S2y"
        },
        "State": {},
        "TablePrefix": {},
        "Schedule": {
          "type": "structure",
          "members": {
            "ScheduleExpression": {},
            "State": {}
          }
        },
        "CrawlElapsedTime": {
          "type": "long"
        },
        "CreationTime": {
          "type": "timestamp"
        },
        "LastUpdated": {
          "type": "timestamp"
        },
        "LastCrawl": {
          "type": "structure",
          "members": {
            "Status": {},
            "ErrorMessage": {},
            "LogGroup": {},
            "LogStream": {},
            "MessagePrefix": {},
            "StartTime": {
              "type": "timestamp"
            }
          }
        },
        "Version": {
          "type": "long"
        },
        "Configuration": {},
        "CrawlerSecurityConfiguration": {}
      }
    },
    "S29": {
      "type": "structure",
      "members": {
        "S3Targets": {
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "Path": {},
              "Exclusions": {
                "shape": "S2d"
              },
              "ConnectionName": {},
              "SampleSize": {
                "type": "integer"
              },
              "EventQueueArn": {},
              "DlqEventQueueArn": {}
            }
          }
        },
        "JdbcTargets": {
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "ConnectionName": {},
              "Path": {},
              "Exclusions": {
                "shape": "S2d"
              }
            }
          }
        },
        "MongoDBTargets": {
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "ConnectionName": {},
              "Path": {},
              "ScanAll": {
                "type": "boolean"
              }
            }
          }
        },
        "DynamoDBTargets": {
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "Path": {},
              "scanAll": {
                "type": "boolean"
              },
              "scanRate": {
                "type": "double"
              }
            }
          }
        },
        "CatalogTargets": {
          "type": "list",
          "member": {
            "type": "structure",
            "required": [
              "DatabaseName",
              "Tables"
            ],
            "members": {
              "DatabaseName": {},
              "Tables": {
                "type": "list",
                "member": {}
              }
            }
          }
        }
      }
    },
    "S2d": {
      "type": "list",
      "member": {}
    },
    "S2s": {
      "type": "list",
      "member": {}
    },
    "S2t": {
      "type": "structure",
      "members": {
        "RecrawlBehavior": {}
      }
    },
    "S2v": {
      "type": "structure",
      "members": {
        "UpdateBehavior": {},
        "DeleteBehavior": {}
      }
    },
    "S2y": {
      "type": "structure",
      "members": {
        "CrawlerLineageSettings": {}
      }
    },
    "S3f": {
      "type": "list",
      "member": {}
    },
    "S3h": {
      "type": "list",
      "member": {
        "shape": "S3i"
      }
    },
    "S3i": {
      "type": "structure",
      "members": {
        "EndpointName": {},
        "RoleArn": {},
        "SecurityGroupIds": {
          "shape": "S3k"
        },
        "SubnetId": {},
        "YarnEndpointAddress": {},
        "PrivateAddress": {},
        "ZeppelinRemoteSparkInterpreterPort": {
          "type": "integer"
        },
        "PublicAddress": {},
        "Status": {},
        "WorkerType": {},
        "GlueVersion": {},
        "NumberOfWorkers": {
          "type": "integer"
        },
        "NumberOfNodes": {
          "type": "integer"
        },
        "AvailabilityZone": {},
        "VpcId": {},
        "ExtraPythonLibsS3Path": {},
        "ExtraJarsS3Path": {},
        "FailureReason": {},
        "LastUpdateStatus": {},
        "CreatedTimestamp": {
          "type": "timestamp"
        },
        "LastModifiedTimestamp": {
          "type": "timestamp"
        },
        "PublicKey": {},
        "PublicKeys": {
          "shape": "S3o"
        },
        "SecurityConfiguration": {},
        "Arguments": {
          "shape": "S3p"
        }
      }
    },
    "S3k": {
      "type": "list",
      "member": {}
    },
    "S3o": {
      "type": "list",
      "member": {}
    },
    "S3p": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "S3r": {
      "type": "list",
      "member": {}
    },
    "S3t": {
      "type": "list",
      "member": {
        "shape": "S3u"
      }
    },
    "S3u": {
      "type": "structure",
      "members": {
        "Name": {},
        "Description": {},
        "LogUri": {},
        "Role": {},
        "CreatedOn": {
          "type": "timestamp"
        },
        "LastModifiedOn": {
          "type": "timestamp"
        },
        "ExecutionProperty": {
          "shape": "S3x"
        },
        "Command": {
          "shape": "S3z"
        },
        "DefaultArguments": {
          "shape": "S42"
        },
        "NonOverridableArguments": {
          "shape": "S42"
        },
        "Connections": {
          "shape": "S43"
        },
        "MaxRetries": {
          "type": "integer"
        },
        "AllocatedCapacity": {
          "deprecated": true,
          "deprecatedMessage": "This property is deprecated, use MaxCapacity instead.",
          "type": "integer"
        },
        "Timeout": {
          "type": "integer"
        },
        "MaxCapacity": {
          "type": "double"
        },
        "WorkerType": {},
        "NumberOfWorkers": {
          "type": "integer"
        },
        "SecurityConfiguration": {},
        "NotificationProperty": {
          "shape": "S47"
        },
        "GlueVersion": {}
      }
    },
    "S3x": {
      "type": "structure",
      "members": {
        "MaxConcurrentRuns": {
          "type": "integer"
        }
      }
    },
    "S3z": {
      "type": "structure",
      "members": {
        "Name": {},
        "ScriptLocation": {},
        "PythonVersion": {}
      }
    },
    "S42": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "S43": {
      "type": "structure",
      "members": {
        "Connections": {
          "type": "list",
          "member": {}
        }
      }
    },
    "S47": {
      "type": "structure",
      "members": {
        "NotifyDelayAfter": {
          "type": "integer"
        }
      }
    },
    "S4a": {
      "type": "list",
      "member": {
        "shape": "S1b"
      }
    },
    "S4c": {
      "type": "list",
      "member": {
        "shape": "S4d"
      }
    },
    "S4d": {
      "type": "structure",
      "members": {
        "Values": {
          "shape": "S6"
        },
        "DatabaseName": {},
        "TableName": {},
        "CreationTime": {
          "type": "timestamp"
        },
        "LastAccessTime": {
          "type": "timestamp"
        },
        "StorageDescriptor": {
          "shape": "S9"
        },
        "Parameters": {
          "shape": "Se"
        },
        "LastAnalyzedTime": {
          "type": "timestamp"
        },
        "CatalogId": {}
      }
    },
    "S4f": {
      "type": "list",
      "member": {}
    },
    "S4h": {
      "type": "list",
      "member": {
        "shape": "S4i"
      }
    },
    "S4i": {
      "type": "structure",
      "members": {
        "Name": {},
        "WorkflowName": {},
        "Id": {},
        "Type": {},
        "State": {},
        "Description": {},
        "Schedule": {},
        "Actions": {
          "shape": "S4m"
        },
        "Predicate": {
          "shape": "S4o"
        },
        "EventBatchingCondition": {
          "shape": "S4v"
        }
      }
    },
    "S4m": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "JobName": {},
          "Arguments": {
            "shape": "S42"
          },
          "Timeout": {
            "type": "integer"
          },
          "SecurityConfiguration": {},
          "NotificationProperty": {
            "shape": "S47"
          },
          "CrawlerName": {}
        }
      }
    },
    "S4o": {
      "type": "structure",
      "members": {
        "Logical": {},
        "Conditions": {
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "LogicalOperator": {},
              "JobName": {},
              "State": {},
              "CrawlerName": {},
              "CrawlState": {}
            }
          }
        }
      }
    },
    "S4v": {
      "type": "structure",
      "required": [
        "BatchSize"
      ],
      "members": {
        "BatchSize": {
          "type": "integer"
        },
        "BatchWindow": {
          "type": "integer"
        }
      }
    },
    "S4z": {
      "type": "list",
      "member": {}
    },
    "S52": {
      "type": "structure",
      "members": {
        "Name": {},
        "Description": {},
        "DefaultRunProperties": {
          "shape": "S53"
        },
        "CreatedOn": {
          "type": "timestamp"
        },
        "LastModifiedOn": {
          "type": "timestamp"
        },
        "LastRun": {
          "shape": "S54"
        },
        "Graph": {
          "shape": "S57"
        },
        "MaxConcurrentRuns": {
          "type": "integer"
        },
        "BlueprintDetails": {
          "type": "structure",
          "members": {
            "BlueprintName": {},
            "RunId": {}
          }
        }
      }
    },
    "S53": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "S54": {
      "type": "structure",
      "members": {
        "Name": {},
        "WorkflowRunId": {},
        "PreviousRunId": {},
        "WorkflowRunProperties": {
          "shape": "S53"
        },
        "StartedOn": {
          "type": "timestamp"
        },
        "CompletedOn": {
          "type": "timestamp"
        },
        "Status": {},
        "ErrorMessage": {},
        "Statistics": {
          "type": "structure",
          "members": {
            "TotalActions": {
              "type": "integer"
            },
            "TimeoutActions": {
              "type": "integer"
            },
            "FailedActions": {
              "type": "integer"
            },
            "StoppedActions": {
              "type": "integer"
            },
            "SucceededActions": {
              "type": "integer"
            },
            "RunningActions": {
              "type": "integer"
            }
          }
        },
        "Graph": {
          "shape": "S57"
        },
        "StartingEventBatchCondition": {
          "type": "structure",
          "members": {
            "BatchSize": {
              "type": "integer"
            },
            "BatchWindow": {
              "type": "integer"
            }
          }
        }
      }
    },
    "S57": {
      "type": "structure",
      "members": {
        "Nodes": {
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "Type": {},
              "Name": {},
              "UniqueId": {},
              "TriggerDetails": {
                "type": "structure",
                "members": {
                  "Trigger": {
                    "shape": "S4i"
                  }
                }
              },
              "JobDetails": {
                "type": "structure",
                "members": {
                  "JobRuns": {
                    "shape": "S5d"
                  }
                }
              },
              "CrawlerDetails": {
                "type": "structure",
                "members": {
                  "Crawls": {
                    "type": "list",
                    "member": {
                      "type": "structure",
                      "members": {
                        "State": {},
                        "StartedOn": {
                          "type": "timestamp"
                        },
                        "CompletedOn": {
                          "type": "timestamp"
                        },
                        "ErrorMessage": {},
                        "LogGroup": {},
                        "LogStream": {}
                      }
                    }
                  }
                }
              }
            }
          }
        },
        "Edges": {
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "SourceId": {},
              "DestinationId": {}
            }
          }
        }
      }
    },
    "S5d": {
      "type": "list",
      "member": {
        "shape": "S5e"
      }
    },
    "S5e": {
      "type": "structure",
      "members": {
        "Id": {},
        "Attempt": {
          "type": "integer"
        },
        "PreviousRunId": {},
        "TriggerName": {},
        "JobName": {},
        "StartedOn": {
          "type": "timestamp"
        },
        "LastModifiedOn": {
          "type": "timestamp"
        },
        "CompletedOn": {
          "type": "timestamp"
        },
        "JobRunState": {},
        "Arguments": {
          "shape": "S42"
        },
        "ErrorMessage": {},
        "PredecessorRuns": {
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "JobName": {},
              "RunId": {}
            }
          }
        },
        "AllocatedCapacity": {
          "deprecated": true,
          "deprecatedMessage": "This property is deprecated, use MaxCapacity instead.",
          "type": "integer"
        },
        "ExecutionTime": {
          "type": "integer"
        },
        "Timeout": {
          "type": "integer"
        },
        "MaxCapacity": {
          "type": "double"
        },
        "WorkerType": {},
        "NumberOfWorkers": {
          "type": "integer"
        },
        "SecurityConfiguration": {},
        "LogGroupName": {},
        "NotificationProperty": {
          "shape": "S47"
        },
        "GlueVersion": {}
      }
    },
    "S60": {
      "type": "list",
      "member": {}
    },
    "S6g": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "S6x": {
      "type": "list",
      "member": {}
    },
    "S70": {
      "type": "structure",
      "required": [
        "Name",
        "ConnectionType",
        "ConnectionProperties"
      ],
      "members": {
        "Name": {},
        "Description": {},
        "ConnectionType": {},
        "MatchCriteria": {
          "shape": "S72"
        },
        "ConnectionProperties": {
          "shape": "S73"
        },
        "PhysicalConnectionRequirements": {
          "shape": "S75"
        }
      }
    },
    "S72": {
      "type": "list",
      "member": {}
    },
    "S73": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "S75": {
      "type": "structure",
      "members": {
        "SubnetId": {},
        "SecurityGroupIdList": {
          "type": "list",
          "member": {}
        },
        "AvailabilityZone": {}
      }
    },
    "S7b": {
      "type": "structure",
      "required": [
        "Name"
      ],
      "members": {
        "Name": {},
        "Description": {},
        "LocationUri": {},
        "Parameters": {
          "shape": "Se"
        },
        "CreateTableDefaultPermissions": {
          "shape": "S7d"
        },
        "TargetDatabase": {
          "shape": "S7j"
        }
      }
    },
    "S7d": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "Principal": {
            "type": "structure",
            "members": {
              "DataLakePrincipalIdentifier": {}
            }
          },
          "Permissions": {
            "type": "list",
            "member": {}
          }
        }
      }
    },
    "S7j": {
      "type": "structure",
      "members": {
        "CatalogId": {},
        "DatabaseName": {}
      }
    },
    "S7q": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "DatabaseName",
          "TableName"
        ],
        "members": {
          "DatabaseName": {},
          "TableName": {},
          "CatalogId": {},
          "ConnectionName": {}
        }
      }
    },
    "S7s": {
      "type": "structure",
      "required": [
        "TransformType"
      ],
      "members": {
        "TransformType": {},
        "FindMatchesParameters": {
          "type": "structure",
          "members": {
            "PrimaryKeyColumnName": {},
            "PrecisionRecallTradeoff": {
              "type": "double"
            },
            "AccuracyCostTradeoff": {
              "type": "double"
            },
            "EnforceProvidedLabels": {
              "type": "boolean"
            }
          }
        }
      }
    },
    "S7x": {
      "type": "structure",
      "members": {
        "MlUserDataEncryption": {
          "type": "structure",
          "required": [
            "MlUserDataEncryptionMode"
          ],
          "members": {
            "MlUserDataEncryptionMode": {},
            "KmsKeyId": {}
          }
        },
        "TaskRunSecurityConfigurationName": {}
      }
    },
    "S84": {
      "type": "structure",
      "required": [
        "Keys",
        "IndexName"
      ],
      "members": {
        "Keys": {
          "type": "list",
          "member": {}
        },
        "IndexName": {}
      }
    },
    "S8a": {
      "type": "structure",
      "members": {
        "RegistryName": {},
        "RegistryArn": {}
      }
    },
    "S8h": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "Id",
          "NodeType",
          "Args"
        ],
        "members": {
          "Id": {},
          "NodeType": {},
          "Args": {
            "shape": "S8l"
          },
          "LineNumber": {
            "type": "integer"
          }
        }
      }
    },
    "S8l": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "Name",
          "Value"
        ],
        "members": {
          "Name": {},
          "Value": {},
          "Param": {
            "type": "boolean"
          }
        }
      }
    },
    "S8p": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "Source",
          "Target"
        ],
        "members": {
          "Source": {},
          "Target": {},
          "TargetParameter": {}
        }
      }
    },
    "S8w": {
      "type": "structure",
      "members": {
        "S3Encryption": {
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "S3EncryptionMode": {},
              "KmsKeyArn": {}
            }
          }
        },
        "CloudWatchEncryption": {
          "type": "structure",
          "members": {
            "CloudWatchEncryptionMode": {},
            "KmsKeyArn": {}
          }
        },
        "JobBookmarksEncryption": {
          "type": "structure",
          "members": {
            "JobBookmarksEncryptionMode": {},
            "KmsKeyArn": {}
          }
        }
      }
    },
    "S97": {
      "type": "structure",
      "required": [
        "Name"
      ],
      "members": {
        "Name": {},
        "Description": {},
        "Owner": {},
        "LastAccessTime": {
          "type": "timestamp"
        },
        "LastAnalyzedTime": {
          "type": "timestamp"
        },
        "Retention": {
          "type": "integer"
        },
        "StorageDescriptor": {
          "shape": "S9"
        },
        "PartitionKeys": {
          "shape": "Sa"
        },
        "ViewOriginalText": {},
        "ViewExpandedText": {},
        "TableType": {},
        "Parameters": {
          "shape": "Se"
        },
        "TargetTable": {
          "shape": "S9b"
        }
      }
    },
    "S9b": {
      "type": "structure",
      "members": {
        "CatalogId": {},
        "DatabaseName": {},
        "Name": {}
      }
    },
    "S9i": {
      "type": "structure",
      "members": {
        "FunctionName": {},
        "ClassName": {},
        "OwnerName": {},
        "OwnerType": {},
        "ResourceUris": {
          "shape": "S9k"
        }
      }
    },
    "S9k": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "ResourceType": {},
          "Uri": {}
        }
      }
    },
    "Sb9": {
      "type": "structure",
      "members": {
        "BlueprintName": {},
        "RunId": {},
        "WorkflowName": {},
        "State": {},
        "StartedOn": {
          "type": "timestamp"
        },
        "CompletedOn": {
          "type": "timestamp"
        },
        "ErrorMessage": {},
        "RollbackErrorMessage": {},
        "Parameters": {},
        "RoleArn": {}
      }
    },
    "Sbn": {
      "type": "structure",
      "members": {
        "GrokClassifier": {
          "type": "structure",
          "required": [
            "Name",
            "Classification",
            "GrokPattern"
          ],
          "members": {
            "Name": {},
            "Classification": {},
            "CreationTime": {
              "type": "timestamp"
            },
            "LastUpdated": {
              "type": "timestamp"
            },
            "Version": {
              "type": "long"
            },
            "GrokPattern": {},
            "CustomPatterns": {}
          }
        },
        "XMLClassifier": {
          "type": "structure",
          "required": [
            "Name",
            "Classification"
          ],
          "members": {
            "Name": {},
            "Classification": {},
            "CreationTime": {
              "type": "timestamp"
            },
            "LastUpdated": {
              "type": "timestamp"
            },
            "Version": {
              "type": "long"
            },
            "RowTag": {}
          }
        },
        "JsonClassifier": {
          "type": "structure",
          "required": [
            "Name",
            "JsonPath"
          ],
          "members": {
            "Name": {},
            "CreationTime": {
              "type": "timestamp"
            },
            "LastUpdated": {
              "type": "timestamp"
            },
            "Version": {
              "type": "long"
            },
            "JsonPath": {}
          }
        },
        "CsvClassifier": {
          "type": "structure",
          "required": [
            "Name"
          ],
          "members": {
            "Name": {},
            "CreationTime": {
              "type": "timestamp"
            },
            "LastUpdated": {
              "type": "timestamp"
            },
            "Version": {
              "type": "long"
            },
            "Delimiter": {},
            "QuoteSymbol": {},
            "ContainsHeader": {},
            "Header": {
              "shape": "S6x"
            },
            "DisableValueTrimming": {
              "type": "boolean"
            },
            "AllowSingleColumn": {
              "type": "boolean"
            }
          }
        }
      }
    },
    "Sbx": {
      "type": "list",
      "member": {}
    },
    "Sbz": {
      "type": "list",
      "member": {
        "shape": "Sc0"
      }
    },
    "Sc0": {
      "type": "structure",
      "required": [
        "ColumnName",
        "ColumnType",
        "AnalyzedTime",
        "StatisticsData"
      ],
      "members": {
        "ColumnName": {},
        "ColumnType": {},
        "AnalyzedTime": {
          "type": "timestamp"
        },
        "StatisticsData": {
          "type": "structure",
          "required": [
            "Type"
          ],
          "members": {
            "Type": {},
            "BooleanColumnStatisticsData": {
              "type": "structure",
              "required": [
                "NumberOfTrues",
                "NumberOfFalses",
                "NumberOfNulls"
              ],
              "members": {
                "NumberOfTrues": {
                  "type": "long"
                },
                "NumberOfFalses": {
                  "type": "long"
                },
                "NumberOfNulls": {
                  "type": "long"
                }
              }
            },
            "DateColumnStatisticsData": {
              "type": "structure",
              "required": [
                "NumberOfNulls",
                "NumberOfDistinctValues"
              ],
              "members": {
                "MinimumValue": {
                  "type": "timestamp"
                },
                "MaximumValue": {
                  "type": "timestamp"
                },
                "NumberOfNulls": {
                  "type": "long"
                },
                "NumberOfDistinctValues": {
                  "type": "long"
                }
              }
            },
            "DecimalColumnStatisticsData": {
              "type": "structure",
              "required": [
                "NumberOfNulls",
                "NumberOfDistinctValues"
              ],
              "members": {
                "MinimumValue": {
                  "shape": "Sc8"
                },
                "MaximumValue": {
                  "shape": "Sc8"
                },
                "NumberOfNulls": {
                  "type": "long"
                },
                "NumberOfDistinctValues": {
                  "type": "long"
                }
              }
            },
            "DoubleColumnStatisticsData": {
              "type": "structure",
              "required": [
                "NumberOfNulls",
                "NumberOfDistinctValues"
              ],
              "members": {
                "MinimumValue": {
                  "type": "double"
                },
                "MaximumValue": {
                  "type": "double"
                },
                "NumberOfNulls": {
                  "type": "long"
                },
                "NumberOfDistinctValues": {
                  "type": "long"
                }
              }
            },
            "LongColumnStatisticsData": {
              "type": "structure",
              "required": [
                "NumberOfNulls",
                "NumberOfDistinctValues"
              ],
              "members": {
                "MinimumValue": {
                  "type": "long"
                },
                "MaximumValue": {
                  "type": "long"
                },
                "NumberOfNulls": {
                  "type": "long"
                },
                "NumberOfDistinctValues": {
                  "type": "long"
                }
              }
            },
            "StringColumnStatisticsData": {
              "type": "structure",
              "required": [
                "MaximumLength",
                "AverageLength",
                "NumberOfNulls",
                "NumberOfDistinctValues"
              ],
              "members": {
                "MaximumLength": {
                  "type": "long"
                },
                "AverageLength": {
                  "type": "double"
                },
                "NumberOfNulls": {
                  "type": "long"
                },
                "NumberOfDistinctValues": {
                  "type": "long"
                }
              }
            },
            "BinaryColumnStatisticsData": {
              "type": "structure",
              "required": [
                "MaximumLength",
                "AverageLength",
                "NumberOfNulls"
              ],
              "members": {
                "MaximumLength": {
                  "type": "long"
                },
                "AverageLength": {
                  "type": "double"
                },
                "NumberOfNulls": {
                  "type": "long"
                }
              }
            }
          }
        }
      }
    },
    "Sc8": {
      "type": "structure",
      "required": [
        "UnscaledValue",
        "Scale"
      ],
      "members": {
        "UnscaledValue": {
          "type": "blob"
        },
        "Scale": {
          "type": "integer"
        }
      }
    },
    "Sch": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "ColumnName": {},
          "Error": {
            "shape": "S13"
          }
        }
      }
    },
    "Scn": {
      "type": "structure",
      "members": {
        "Name": {},
        "Description": {},
        "ConnectionType": {},
        "MatchCriteria": {
          "shape": "S72"
        },
        "ConnectionProperties": {
          "shape": "S73"
        },
        "PhysicalConnectionRequirements": {
          "shape": "S75"
        },
        "CreationTime": {
          "type": "timestamp"
        },
        "LastUpdatedTime": {
          "type": "timestamp"
        },
        "LastUpdatedBy": {}
      }
    },
    "Sd2": {
      "type": "structure",
      "members": {
        "EncryptionAtRest": {
          "type": "structure",
          "required": [
            "CatalogEncryptionMode"
          ],
          "members": {
            "CatalogEncryptionMode": {},
            "SseAwsKmsKeyId": {}
          }
        },
        "ConnectionPasswordEncryption": {
          "type": "structure",
          "required": [
            "ReturnConnectionPasswordEncrypted"
          ],
          "members": {
            "ReturnConnectionPasswordEncrypted": {
              "type": "boolean"
            },
            "AwsKmsKeyId": {}
          }
        }
      }
    },
    "Sd8": {
      "type": "structure",
      "required": [
        "Name"
      ],
      "members": {
        "Name": {},
        "Description": {},
        "LocationUri": {},
        "Parameters": {
          "shape": "Se"
        },
        "CreateTime": {
          "type": "timestamp"
        },
        "CreateTableDefaultPermissions": {
          "shape": "S7d"
        },
        "TargetDatabase": {
          "shape": "S7j"
        },
        "CatalogId": {}
      }
    },
    "Sdq": {
      "type": "structure",
      "members": {
        "JobName": {},
        "Version": {
          "type": "integer"
        },
        "Run": {
          "type": "integer"
        },
        "Attempt": {
          "type": "integer"
        },
        "PreviousRunId": {},
        "RunId": {},
        "JobBookmark": {}
      }
    },
    "Se0": {
      "type": "structure",
      "members": {
        "TaskType": {},
        "ImportLabelsTaskRunProperties": {
          "type": "structure",
          "members": {
            "InputS3Path": {},
            "Replace": {
              "type": "boolean"
            }
          }
        },
        "ExportLabelsTaskRunProperties": {
          "type": "structure",
          "members": {
            "OutputS3Path": {}
          }
        },
        "LabelingSetGenerationTaskRunProperties": {
          "type": "structure",
          "members": {
            "OutputS3Path": {}
          }
        },
        "FindMatchesTaskRunProperties": {
          "type": "structure",
          "members": {
            "JobId": {},
            "JobName": {},
            "JobRunId": {}
          }
        }
      }
    },
    "Sej": {
      "type": "structure",
      "required": [
        "TransformType"
      ],
      "members": {
        "TransformType": {},
        "FindMatchesMetrics": {
          "type": "structure",
          "members": {
            "AreaUnderPRCurve": {
              "type": "double"
            },
            "Precision": {
              "type": "double"
            },
            "Recall": {
              "type": "double"
            },
            "F1": {
              "type": "double"
            },
            "ConfusionMatrix": {
              "type": "structure",
              "members": {
                "NumTruePositives": {
                  "type": "long"
                },
                "NumFalsePositives": {
                  "type": "long"
                },
                "NumTrueNegatives": {
                  "type": "long"
                },
                "NumFalseNegatives": {
                  "type": "long"
                }
              }
            },
            "ColumnImportances": {
              "type": "list",
              "member": {
                "type": "structure",
                "members": {
                  "ColumnName": {},
                  "Importance": {
                    "type": "double"
                  }
                }
              }
            }
          }
        }
      }
    },
    "Seq": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "Name": {},
          "DataType": {}
        }
      }
    },
    "Set": {
      "type": "structure",
      "members": {
        "Name": {},
        "TransformType": {},
        "Status": {},
        "GlueVersion": {},
        "CreatedBefore": {
          "type": "timestamp"
        },
        "CreatedAfter": {
          "type": "timestamp"
        },
        "LastModifiedBefore": {
          "type": "timestamp"
        },
        "LastModifiedAfter": {
          "type": "timestamp"
        },
        "Schema": {
          "shape": "Seq"
        }
      }
    },
    "Seu": {
      "type": "structure",
      "required": [
        "Column",
        "SortDirection"
      ],
      "members": {
        "Column": {},
        "SortDirection": {}
      }
    },
    "Sf0": {
      "type": "structure",
      "required": [
        "DatabaseName",
        "TableName"
      ],
      "members": {
        "DatabaseName": {},
        "TableName": {}
      }
    },
    "Sf1": {
      "type": "list",
      "member": {
        "shape": "Sf0"
      }
    },
    "Sf2": {
      "type": "structure",
      "members": {
        "Jdbc": {
          "shape": "S8l"
        },
        "S3": {
          "shape": "S8l"
        },
        "DynamoDB": {
          "shape": "S8l"
        }
      }
    },
    "Sf4": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "SourceTable": {},
          "SourcePath": {},
          "SourceType": {},
          "TargetTable": {},
          "TargetPath": {},
          "TargetType": {}
        }
      }
    },
    "Sgb": {
      "type": "structure",
      "members": {
        "LatestVersion": {
          "type": "boolean"
        },
        "VersionNumber": {
          "type": "long"
        }
      }
    },
    "Sgk": {
      "type": "structure",
      "members": {
        "Name": {},
        "CreatedTimeStamp": {
          "type": "timestamp"
        },
        "EncryptionConfiguration": {
          "shape": "S8w"
        }
      }
    },
    "Sgq": {
      "type": "structure",
      "required": [
        "Name"
      ],
      "members": {
        "Name": {},
        "DatabaseName": {},
        "Description": {},
        "Owner": {},
        "CreateTime": {
          "type": "timestamp"
        },
        "UpdateTime": {
          "type": "timestamp"
        },
        "LastAccessTime": {
          "type": "timestamp"
        },
        "LastAnalyzedTime": {
          "type": "timestamp"
        },
        "Retention": {
          "type": "integer"
        },
        "StorageDescriptor": {
          "shape": "S9"
        },
        "PartitionKeys": {
          "shape": "Sa"
        },
        "ViewOriginalText": {},
        "ViewExpandedText": {},
        "TableType": {},
        "Parameters": {
          "shape": "Se"
        },
        "CreatedBy": {},
        "IsRegisteredWithLakeFormation": {
          "type": "boolean"
        },
        "TargetTable": {
          "shape": "S9b"
        },
        "CatalogId": {}
      }
    },
    "Sgt": {
      "type": "structure",
      "members": {
        "Table": {
          "shape": "Sgq"
        },
        "VersionId": {}
      }
    },
    "Sh0": {
      "type": "list",
      "member": {
        "shape": "Sgq"
      }
    },
    "Sh9": {
      "type": "structure",
      "members": {
        "FunctionName": {},
        "DatabaseName": {},
        "ClassName": {},
        "OwnerName": {},
        "OwnerType": {},
        "CreateTime": {
          "type": "timestamp"
        },
        "ResourceUris": {
          "shape": "S9k"
        },
        "CatalogId": {}
      }
    },
    "Sip": {
      "type": "structure",
      "members": {
        "MetadataKey": {},
        "MetadataValue": {}
      }
    },
    "Sja": {
      "type": "list",
      "member": {}
    },
    "Skq": {
      "type": "list",
      "member": {
        "shape": "Sc0"
      }
    },
    "Sks": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "ColumnStatistics": {
            "shape": "Sc0"
          },
          "Error": {
            "shape": "S13"
          }
        }
      }
    }
  }
}