{
  "version": "2.0",
  "metadata": {
    "apiVersion": "2018-06-27",
    "endpointPrefix": "textract",
    "jsonVersion": "1.1",
    "protocol": "json",
    "serviceFullName": "Amazon Textract",
    "serviceId": "Textract",
    "signatureVersion": "v4",
    "targetPrefix": "Textract",
    "uid": "textract-2018-06-27"
  },
  "operations": {
    "AnalyzeDocument": {
      "input": {
        "type": "structure",
        "required": [
          "Document",
          "FeatureTypes"
        ],
        "members": {
          "Document": {
            "shape": "S2"
          },
          "FeatureTypes": {
            "shape": "S8"
          },
          "HumanLoopConfig": {
            "type": "structure",
            "required": [
              "HumanLoopName",
              "FlowDefinitionArn"
            ],
            "members": {
              "HumanLoopName": {},
              "FlowDefinitionArn": {},
              "DataAttributes": {
                "type": "structure",
                "members": {
                  "ContentClassifiers": {
                    "type": "list",
                    "member": {}
                  }
                }
              }
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DocumentMetadata": {
            "shape": "Sh"
          },
          "Blocks": {
            "shape": "Sj"
          },
          "HumanLoopActivationOutput": {
            "type": "structure",
            "members": {
              "HumanLoopArn": {},
              "HumanLoopActivationReasons": {
                "type": "list",
                "member": {}
              },
              "HumanLoopActivationConditionsEvaluationResults": {
                "jsonvalue": true
              }
            }
          },
          "AnalyzeDocumentModelVersion": {}
        }
      }
    },
    "AnalyzeExpense": {
      "input": {
        "type": "structure",
        "required": [
          "Document"
        ],
        "members": {
          "Document": {
            "shape": "S2"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DocumentMetadata": {
            "shape": "Sh"
          },
          "ExpenseDocuments": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "ExpenseIndex": {
                  "type": "integer"
                },
                "SummaryFields": {
                  "shape": "S1b"
                },
                "LineItemGroups": {
                  "type": "list",
                  "member": {
                    "type": "structure",
                    "members": {
                      "LineItemGroupIndex": {
                        "type": "integer"
                      },
                      "LineItems": {
                        "type": "list",
                        "member": {
                          "type": "structure",
                          "members": {
                            "LineItemExpenseFields": {
                              "shape": "S1b"
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    },
    "DetectDocumentText": {
      "input": {
        "type": "structure",
        "required": [
          "Document"
        ],
        "members": {
          "Document": {
            "shape": "S2"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DocumentMetadata": {
            "shape": "Sh"
          },
          "Blocks": {
            "shape": "Sj"
          },
          "DetectDocumentTextModelVersion": {}
        }
      }
    },
    "GetDocumentAnalysis": {
      "input": {
        "type": "structure",
        "required": [
          "JobId"
        ],
        "members": {
          "JobId": {},
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DocumentMetadata": {
            "shape": "Sh"
          },
          "JobStatus": {},
          "NextToken": {},
          "Blocks": {
            "shape": "Sj"
          },
          "Warnings": {
            "shape": "S1r"
          },
          "StatusMessage": {},
          "AnalyzeDocumentModelVersion": {}
        }
      }
    },
    "GetDocumentTextDetection": {
      "input": {
        "type": "structure",
        "required": [
          "JobId"
        ],
        "members": {
          "JobId": {},
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DocumentMetadata": {
            "shape": "Sh"
          },
          "JobStatus": {},
          "NextToken": {},
          "Blocks": {
            "shape": "Sj"
          },
          "Warnings": {
            "shape": "S1r"
          },
          "StatusMessage": {},
          "DetectDocumentTextModelVersion": {}
        }
      }
    },
    "StartDocumentAnalysis": {
      "input": {
        "type": "structure",
        "required": [
          "DocumentLocation",
          "FeatureTypes"
        ],
        "members": {
          "DocumentLocation": {
            "shape": "S1z"
          },
          "FeatureTypes": {
            "shape": "S8"
          },
          "ClientRequestToken": {},
          "JobTag": {},
          "NotificationChannel": {
            "shape": "S22"
          },
          "OutputConfig": {
            "shape": "S25"
          },
          "KMSKeyId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "JobId": {}
        }
      }
    },
    "StartDocumentTextDetection": {
      "input": {
        "type": "structure",
        "required": [
          "DocumentLocation"
        ],
        "members": {
          "DocumentLocation": {
            "shape": "S1z"
          },
          "ClientRequestToken": {},
          "JobTag": {},
          "NotificationChannel": {
            "shape": "S22"
          },
          "OutputConfig": {
            "shape": "S25"
          },
          "KMSKeyId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "JobId": {}
        }
      }
    }
  },
  "shapes": {
    "S2": {
      "type": "structure",
      "members": {
        "Bytes": {
          "type": "blob"
        },
        "S3Object": {
          "shape": "S4"
        }
      }
    },
    "S4": {
      "type": "structure",
      "members": {
        "Bucket": {},
        "Name": {},
        "Version": {}
      }
    },
    "S8": {
      "type": "list",
      "member": {}
    },
    "Sh": {
      "type": "structure",
      "members": {
        "Pages": {
          "type": "integer"
        }
      }
    },
    "Sj": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "BlockType": {},
          "Confidence": {
            "type": "float"
          },
          "Text": {},
          "TextType": {},
          "RowIndex": {
            "type": "integer"
          },
          "ColumnIndex": {
            "type": "integer"
          },
          "RowSpan": {
            "type": "integer"
          },
          "ColumnSpan": {
            "type": "integer"
          },
          "Geometry": {
            "shape": "Sp"
          },
          "Id": {},
          "Relationships": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Type": {},
                "Ids": {
                  "type": "list",
                  "member": {}
                }
              }
            }
          },
          "EntityTypes": {
            "type": "list",
            "member": {}
          },
          "SelectionStatus": {},
          "Page": {
            "type": "integer"
          }
        }
      }
    },
    "Sp": {
      "type": "structure",
      "members": {
        "BoundingBox": {
          "type": "structure",
          "members": {
            "Width": {
              "type": "float"
            },
            "Height": {
              "type": "float"
            },
            "Left": {
              "type": "float"
            },
            "Top": {
              "type": "float"
            }
          }
        },
        "Polygon": {
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "X": {
                "type": "float"
              },
              "Y": {
                "type": "float"
              }
            }
          }
        }
      }
    },
    "S1b": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "Type": {
            "type": "structure",
            "members": {
              "Text": {},
              "Confidence": {
                "type": "float"
              }
            }
          },
          "LabelDetection": {
            "shape": "S1e"
          },
          "ValueDetection": {
            "shape": "S1e"
          },
          "PageNumber": {
            "type": "integer"
          }
        }
      }
    },
    "S1e": {
      "type": "structure",
      "members": {
        "Text": {},
        "Geometry": {
          "shape": "Sp"
        },
        "Confidence": {
          "type": "float"
        }
      }
    },
    "S1r": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "ErrorCode": {},
          "Pages": {
            "type": "list",
            "member": {
              "type": "integer"
            }
          }
        }
      }
    },
    "S1z": {
      "type": "structure",
      "members": {
        "S3Object": {
          "shape": "S4"
        }
      }
    },
    "S22": {
      "type": "structure",
      "required": [
        "SNSTopicArn",
        "RoleArn"
      ],
      "members": {
        "SNSTopicArn": {},
        "RoleArn": {}
      }
    },
    "S25": {
      "type": "structure",
      "required": [
        "S3Bucket"
      ],
      "members": {
        "S3Bucket": {},
        "S3Prefix": {}
      }
    }
  }
}