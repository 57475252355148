{
  "version": "2.0",
  "metadata": {
    "apiVersion": "2017-07-25",
    "endpointPrefix": "databrew",
    "jsonVersion": "1.1",
    "protocol": "rest-json",
    "serviceFullName": "AWS Glue DataBrew",
    "serviceId": "DataBrew",
    "signatureVersion": "v4",
    "signingName": "databrew",
    "uid": "databrew-2017-07-25"
  },
  "operations": {
    "BatchDeleteRecipeVersion": {
      "http": {
        "requestUri": "/recipes/{name}/batchDeleteRecipeVersion"
      },
      "input": {
        "type": "structure",
        "required": [
          "Name",
          "RecipeVersions"
        ],
        "members": {
          "Name": {
            "location": "uri",
            "locationName": "name"
          },
          "RecipeVersions": {
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {},
          "Errors": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "ErrorCode": {},
                "ErrorMessage": {},
                "RecipeVersion": {}
              }
            }
          }
        }
      }
    },
    "CreateDataset": {
      "http": {
        "requestUri": "/datasets"
      },
      "input": {
        "type": "structure",
        "required": [
          "Name",
          "Input"
        ],
        "members": {
          "Name": {},
          "Format": {},
          "FormatOptions": {
            "shape": "Sd"
          },
          "Input": {
            "shape": "So"
          },
          "PathOptions": {
            "shape": "Sz"
          },
          "Tags": {
            "shape": "S1i"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {}
        }
      }
    },
    "CreateProfileJob": {
      "http": {
        "requestUri": "/profileJobs"
      },
      "input": {
        "type": "structure",
        "required": [
          "DatasetName",
          "Name",
          "OutputLocation",
          "RoleArn"
        ],
        "members": {
          "DatasetName": {},
          "EncryptionKeyArn": {},
          "EncryptionMode": {},
          "Name": {},
          "LogSubscription": {},
          "MaxCapacity": {
            "type": "integer"
          },
          "MaxRetries": {
            "type": "integer"
          },
          "OutputLocation": {
            "shape": "Sp"
          },
          "Configuration": {
            "shape": "S1t"
          },
          "RoleArn": {},
          "Tags": {
            "shape": "S1i"
          },
          "Timeout": {
            "type": "integer"
          },
          "JobSample": {
            "shape": "S29"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {}
        }
      }
    },
    "CreateProject": {
      "http": {
        "requestUri": "/projects"
      },
      "input": {
        "type": "structure",
        "required": [
          "DatasetName",
          "Name",
          "RecipeName",
          "RoleArn"
        ],
        "members": {
          "DatasetName": {},
          "Name": {},
          "RecipeName": {},
          "Sample": {
            "shape": "S2f"
          },
          "RoleArn": {},
          "Tags": {
            "shape": "S1i"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {}
        }
      }
    },
    "CreateRecipe": {
      "http": {
        "requestUri": "/recipes"
      },
      "input": {
        "type": "structure",
        "required": [
          "Name",
          "Steps"
        ],
        "members": {
          "Description": {},
          "Name": {},
          "Steps": {
            "shape": "S2l"
          },
          "Tags": {
            "shape": "S1i"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {}
        }
      }
    },
    "CreateRecipeJob": {
      "http": {
        "requestUri": "/recipeJobs"
      },
      "input": {
        "type": "structure",
        "required": [
          "Name",
          "RoleArn"
        ],
        "members": {
          "DatasetName": {},
          "EncryptionKeyArn": {},
          "EncryptionMode": {},
          "Name": {},
          "LogSubscription": {},
          "MaxCapacity": {
            "type": "integer"
          },
          "MaxRetries": {
            "type": "integer"
          },
          "Outputs": {
            "shape": "S2v"
          },
          "DataCatalogOutputs": {
            "shape": "S33"
          },
          "DatabaseOutputs": {
            "shape": "S37"
          },
          "ProjectName": {},
          "RecipeReference": {
            "shape": "S3a"
          },
          "RoleArn": {},
          "Tags": {
            "shape": "S1i"
          },
          "Timeout": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {}
        }
      }
    },
    "CreateSchedule": {
      "http": {
        "requestUri": "/schedules"
      },
      "input": {
        "type": "structure",
        "required": [
          "CronExpression",
          "Name"
        ],
        "members": {
          "JobNames": {
            "shape": "S3d"
          },
          "CronExpression": {},
          "Tags": {
            "shape": "S1i"
          },
          "Name": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {}
        }
      }
    },
    "DeleteDataset": {
      "http": {
        "method": "DELETE",
        "requestUri": "/datasets/{name}"
      },
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {
            "location": "uri",
            "locationName": "name"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {}
        }
      }
    },
    "DeleteJob": {
      "http": {
        "method": "DELETE",
        "requestUri": "/jobs/{name}"
      },
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {
            "location": "uri",
            "locationName": "name"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {}
        }
      }
    },
    "DeleteProject": {
      "http": {
        "method": "DELETE",
        "requestUri": "/projects/{name}"
      },
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {
            "location": "uri",
            "locationName": "name"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {}
        }
      }
    },
    "DeleteRecipeVersion": {
      "http": {
        "method": "DELETE",
        "requestUri": "/recipes/{name}/recipeVersion/{recipeVersion}"
      },
      "input": {
        "type": "structure",
        "required": [
          "Name",
          "RecipeVersion"
        ],
        "members": {
          "Name": {
            "location": "uri",
            "locationName": "name"
          },
          "RecipeVersion": {
            "location": "uri",
            "locationName": "recipeVersion"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "Name",
          "RecipeVersion"
        ],
        "members": {
          "Name": {},
          "RecipeVersion": {}
        }
      }
    },
    "DeleteSchedule": {
      "http": {
        "method": "DELETE",
        "requestUri": "/schedules/{name}"
      },
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {
            "location": "uri",
            "locationName": "name"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {}
        }
      }
    },
    "DescribeDataset": {
      "http": {
        "method": "GET",
        "requestUri": "/datasets/{name}"
      },
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {
            "location": "uri",
            "locationName": "name"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "Name",
          "Input"
        ],
        "members": {
          "CreatedBy": {},
          "CreateDate": {
            "type": "timestamp"
          },
          "Name": {},
          "Format": {},
          "FormatOptions": {
            "shape": "Sd"
          },
          "Input": {
            "shape": "So"
          },
          "LastModifiedDate": {
            "type": "timestamp"
          },
          "LastModifiedBy": {},
          "Source": {},
          "PathOptions": {
            "shape": "Sz"
          },
          "Tags": {
            "shape": "S1i"
          },
          "ResourceArn": {}
        }
      }
    },
    "DescribeJob": {
      "http": {
        "method": "GET",
        "requestUri": "/jobs/{name}"
      },
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {
            "location": "uri",
            "locationName": "name"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "CreateDate": {
            "type": "timestamp"
          },
          "CreatedBy": {},
          "DatasetName": {},
          "EncryptionKeyArn": {},
          "EncryptionMode": {},
          "Name": {},
          "Type": {},
          "LastModifiedBy": {},
          "LastModifiedDate": {
            "type": "timestamp"
          },
          "LogSubscription": {},
          "MaxCapacity": {
            "type": "integer"
          },
          "MaxRetries": {
            "type": "integer"
          },
          "Outputs": {
            "shape": "S2v"
          },
          "DataCatalogOutputs": {
            "shape": "S33"
          },
          "DatabaseOutputs": {
            "shape": "S37"
          },
          "ProjectName": {},
          "ProfileConfiguration": {
            "shape": "S1t"
          },
          "RecipeReference": {
            "shape": "S3a"
          },
          "ResourceArn": {},
          "RoleArn": {},
          "Tags": {
            "shape": "S1i"
          },
          "Timeout": {
            "type": "integer"
          },
          "JobSample": {
            "shape": "S29"
          }
        }
      }
    },
    "DescribeJobRun": {
      "http": {
        "method": "GET",
        "requestUri": "/jobs/{name}/jobRun/{runId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "Name",
          "RunId"
        ],
        "members": {
          "Name": {
            "location": "uri",
            "locationName": "name"
          },
          "RunId": {
            "location": "uri",
            "locationName": "runId"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "JobName"
        ],
        "members": {
          "Attempt": {
            "type": "integer"
          },
          "CompletedOn": {
            "type": "timestamp"
          },
          "DatasetName": {},
          "ErrorMessage": {},
          "ExecutionTime": {
            "type": "integer"
          },
          "JobName": {},
          "ProfileConfiguration": {
            "shape": "S1t"
          },
          "RunId": {},
          "State": {},
          "LogSubscription": {},
          "LogGroupName": {},
          "Outputs": {
            "shape": "S2v"
          },
          "DataCatalogOutputs": {
            "shape": "S33"
          },
          "DatabaseOutputs": {
            "shape": "S37"
          },
          "RecipeReference": {
            "shape": "S3a"
          },
          "StartedBy": {},
          "StartedOn": {
            "type": "timestamp"
          },
          "JobSample": {
            "shape": "S29"
          }
        }
      }
    },
    "DescribeProject": {
      "http": {
        "method": "GET",
        "requestUri": "/projects/{name}"
      },
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {
            "location": "uri",
            "locationName": "name"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "CreateDate": {
            "type": "timestamp"
          },
          "CreatedBy": {},
          "DatasetName": {},
          "LastModifiedDate": {
            "type": "timestamp"
          },
          "LastModifiedBy": {},
          "Name": {},
          "RecipeName": {},
          "ResourceArn": {},
          "Sample": {
            "shape": "S2f"
          },
          "RoleArn": {},
          "Tags": {
            "shape": "S1i"
          },
          "SessionStatus": {},
          "OpenedBy": {},
          "OpenDate": {
            "type": "timestamp"
          }
        }
      }
    },
    "DescribeRecipe": {
      "http": {
        "method": "GET",
        "requestUri": "/recipes/{name}"
      },
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {
            "location": "uri",
            "locationName": "name"
          },
          "RecipeVersion": {
            "location": "querystring",
            "locationName": "recipeVersion"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "CreatedBy": {},
          "CreateDate": {
            "type": "timestamp"
          },
          "LastModifiedBy": {},
          "LastModifiedDate": {
            "type": "timestamp"
          },
          "ProjectName": {},
          "PublishedBy": {},
          "PublishedDate": {
            "type": "timestamp"
          },
          "Description": {},
          "Name": {},
          "Steps": {
            "shape": "S2l"
          },
          "Tags": {
            "shape": "S1i"
          },
          "ResourceArn": {},
          "RecipeVersion": {}
        }
      }
    },
    "DescribeSchedule": {
      "http": {
        "method": "GET",
        "requestUri": "/schedules/{name}"
      },
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {
            "location": "uri",
            "locationName": "name"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "CreateDate": {
            "type": "timestamp"
          },
          "CreatedBy": {},
          "JobNames": {
            "shape": "S3d"
          },
          "LastModifiedBy": {},
          "LastModifiedDate": {
            "type": "timestamp"
          },
          "ResourceArn": {},
          "CronExpression": {},
          "Tags": {
            "shape": "S1i"
          },
          "Name": {}
        }
      }
    },
    "ListDatasets": {
      "http": {
        "method": "GET",
        "requestUri": "/datasets"
      },
      "input": {
        "type": "structure",
        "members": {
          "MaxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "Datasets"
        ],
        "members": {
          "Datasets": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "Name",
                "Input"
              ],
              "members": {
                "AccountId": {},
                "CreatedBy": {},
                "CreateDate": {
                  "type": "timestamp"
                },
                "Name": {},
                "Format": {},
                "FormatOptions": {
                  "shape": "Sd"
                },
                "Input": {
                  "shape": "So"
                },
                "LastModifiedDate": {
                  "type": "timestamp"
                },
                "LastModifiedBy": {},
                "Source": {},
                "PathOptions": {
                  "shape": "Sz"
                },
                "Tags": {
                  "shape": "S1i"
                },
                "ResourceArn": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListJobRuns": {
      "http": {
        "method": "GET",
        "requestUri": "/jobs/{name}/jobRuns"
      },
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {
            "location": "uri",
            "locationName": "name"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "JobRuns"
        ],
        "members": {
          "JobRuns": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Attempt": {
                  "type": "integer"
                },
                "CompletedOn": {
                  "type": "timestamp"
                },
                "DatasetName": {},
                "ErrorMessage": {},
                "ExecutionTime": {
                  "type": "integer"
                },
                "JobName": {},
                "RunId": {},
                "State": {},
                "LogSubscription": {},
                "LogGroupName": {},
                "Outputs": {
                  "shape": "S2v"
                },
                "DataCatalogOutputs": {
                  "shape": "S33"
                },
                "DatabaseOutputs": {
                  "shape": "S37"
                },
                "RecipeReference": {
                  "shape": "S3a"
                },
                "StartedBy": {},
                "StartedOn": {
                  "type": "timestamp"
                },
                "JobSample": {
                  "shape": "S29"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListJobs": {
      "http": {
        "method": "GET",
        "requestUri": "/jobs"
      },
      "input": {
        "type": "structure",
        "members": {
          "DatasetName": {
            "location": "querystring",
            "locationName": "datasetName"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "ProjectName": {
            "location": "querystring",
            "locationName": "projectName"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "Jobs"
        ],
        "members": {
          "Jobs": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "Name"
              ],
              "members": {
                "AccountId": {},
                "CreatedBy": {},
                "CreateDate": {
                  "type": "timestamp"
                },
                "DatasetName": {},
                "EncryptionKeyArn": {},
                "EncryptionMode": {},
                "Name": {},
                "Type": {},
                "LastModifiedBy": {},
                "LastModifiedDate": {
                  "type": "timestamp"
                },
                "LogSubscription": {},
                "MaxCapacity": {
                  "type": "integer"
                },
                "MaxRetries": {
                  "type": "integer"
                },
                "Outputs": {
                  "shape": "S2v"
                },
                "DataCatalogOutputs": {
                  "shape": "S33"
                },
                "DatabaseOutputs": {
                  "shape": "S37"
                },
                "ProjectName": {},
                "RecipeReference": {
                  "shape": "S3a"
                },
                "ResourceArn": {},
                "RoleArn": {},
                "Timeout": {
                  "type": "integer"
                },
                "Tags": {
                  "shape": "S1i"
                },
                "JobSample": {
                  "shape": "S29"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListProjects": {
      "http": {
        "method": "GET",
        "requestUri": "/projects"
      },
      "input": {
        "type": "structure",
        "members": {
          "NextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "Projects"
        ],
        "members": {
          "Projects": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "Name",
                "RecipeName"
              ],
              "members": {
                "AccountId": {},
                "CreateDate": {
                  "type": "timestamp"
                },
                "CreatedBy": {},
                "DatasetName": {},
                "LastModifiedDate": {
                  "type": "timestamp"
                },
                "LastModifiedBy": {},
                "Name": {},
                "RecipeName": {},
                "ResourceArn": {},
                "Sample": {
                  "shape": "S2f"
                },
                "Tags": {
                  "shape": "S1i"
                },
                "RoleArn": {},
                "OpenedBy": {},
                "OpenDate": {
                  "type": "timestamp"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListRecipeVersions": {
      "http": {
        "method": "GET",
        "requestUri": "/recipeVersions"
      },
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "MaxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "Name": {
            "location": "querystring",
            "locationName": "name"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "Recipes"
        ],
        "members": {
          "NextToken": {},
          "Recipes": {
            "shape": "S53"
          }
        }
      }
    },
    "ListRecipes": {
      "http": {
        "method": "GET",
        "requestUri": "/recipes"
      },
      "input": {
        "type": "structure",
        "members": {
          "MaxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "RecipeVersion": {
            "location": "querystring",
            "locationName": "recipeVersion"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "Recipes"
        ],
        "members": {
          "Recipes": {
            "shape": "S53"
          },
          "NextToken": {}
        }
      }
    },
    "ListSchedules": {
      "http": {
        "method": "GET",
        "requestUri": "/schedules"
      },
      "input": {
        "type": "structure",
        "members": {
          "JobName": {
            "location": "querystring",
            "locationName": "jobName"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "Schedules"
        ],
        "members": {
          "Schedules": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "Name"
              ],
              "members": {
                "AccountId": {},
                "CreatedBy": {},
                "CreateDate": {
                  "type": "timestamp"
                },
                "JobNames": {
                  "shape": "S3d"
                },
                "LastModifiedBy": {},
                "LastModifiedDate": {
                  "type": "timestamp"
                },
                "ResourceArn": {},
                "CronExpression": {},
                "Tags": {
                  "shape": "S1i"
                },
                "Name": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListTagsForResource": {
      "http": {
        "method": "GET",
        "requestUri": "/tags/{ResourceArn}"
      },
      "input": {
        "type": "structure",
        "required": [
          "ResourceArn"
        ],
        "members": {
          "ResourceArn": {
            "location": "uri",
            "locationName": "ResourceArn"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Tags": {
            "shape": "S1i"
          }
        }
      }
    },
    "PublishRecipe": {
      "http": {
        "requestUri": "/recipes/{name}/publishRecipe"
      },
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Description": {},
          "Name": {
            "location": "uri",
            "locationName": "name"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {}
        }
      }
    },
    "SendProjectSessionAction": {
      "http": {
        "method": "PUT",
        "requestUri": "/projects/{name}/sendProjectSessionAction"
      },
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Preview": {
            "type": "boolean"
          },
          "Name": {
            "location": "uri",
            "locationName": "name"
          },
          "RecipeStep": {
            "shape": "S2m"
          },
          "StepIndex": {
            "type": "integer"
          },
          "ClientSessionId": {},
          "ViewFrame": {
            "type": "structure",
            "required": [
              "StartColumnIndex"
            ],
            "members": {
              "StartColumnIndex": {
                "type": "integer"
              },
              "ColumnRange": {
                "type": "integer"
              },
              "HiddenColumns": {
                "type": "list",
                "member": {}
              }
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Result": {},
          "Name": {},
          "ActionId": {
            "type": "integer"
          }
        }
      }
    },
    "StartJobRun": {
      "http": {
        "requestUri": "/jobs/{name}/startJobRun"
      },
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {
            "location": "uri",
            "locationName": "name"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "RunId"
        ],
        "members": {
          "RunId": {}
        }
      }
    },
    "StartProjectSession": {
      "http": {
        "method": "PUT",
        "requestUri": "/projects/{name}/startProjectSession"
      },
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {
            "location": "uri",
            "locationName": "name"
          },
          "AssumeControl": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {},
          "ClientSessionId": {}
        }
      }
    },
    "StopJobRun": {
      "http": {
        "requestUri": "/jobs/{name}/jobRun/{runId}/stopJobRun"
      },
      "input": {
        "type": "structure",
        "required": [
          "Name",
          "RunId"
        ],
        "members": {
          "Name": {
            "location": "uri",
            "locationName": "name"
          },
          "RunId": {
            "location": "uri",
            "locationName": "runId"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "RunId"
        ],
        "members": {
          "RunId": {}
        }
      }
    },
    "TagResource": {
      "http": {
        "requestUri": "/tags/{ResourceArn}"
      },
      "input": {
        "type": "structure",
        "required": [
          "ResourceArn",
          "Tags"
        ],
        "members": {
          "ResourceArn": {
            "location": "uri",
            "locationName": "ResourceArn"
          },
          "Tags": {
            "shape": "S1i"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UntagResource": {
      "http": {
        "method": "DELETE",
        "requestUri": "/tags/{ResourceArn}"
      },
      "input": {
        "type": "structure",
        "required": [
          "ResourceArn",
          "TagKeys"
        ],
        "members": {
          "ResourceArn": {
            "location": "uri",
            "locationName": "ResourceArn"
          },
          "TagKeys": {
            "location": "querystring",
            "locationName": "tagKeys",
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UpdateDataset": {
      "http": {
        "method": "PUT",
        "requestUri": "/datasets/{name}"
      },
      "input": {
        "type": "structure",
        "required": [
          "Name",
          "Input"
        ],
        "members": {
          "Name": {
            "location": "uri",
            "locationName": "name"
          },
          "Format": {},
          "FormatOptions": {
            "shape": "Sd"
          },
          "Input": {
            "shape": "So"
          },
          "PathOptions": {
            "shape": "Sz"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {}
        }
      }
    },
    "UpdateProfileJob": {
      "http": {
        "method": "PUT",
        "requestUri": "/profileJobs/{name}"
      },
      "input": {
        "type": "structure",
        "required": [
          "Name",
          "OutputLocation",
          "RoleArn"
        ],
        "members": {
          "Configuration": {
            "shape": "S1t"
          },
          "EncryptionKeyArn": {},
          "EncryptionMode": {},
          "Name": {
            "location": "uri",
            "locationName": "name"
          },
          "LogSubscription": {},
          "MaxCapacity": {
            "type": "integer"
          },
          "MaxRetries": {
            "type": "integer"
          },
          "OutputLocation": {
            "shape": "Sp"
          },
          "RoleArn": {},
          "Timeout": {
            "type": "integer"
          },
          "JobSample": {
            "shape": "S29"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {}
        }
      }
    },
    "UpdateProject": {
      "http": {
        "method": "PUT",
        "requestUri": "/projects/{name}"
      },
      "input": {
        "type": "structure",
        "required": [
          "RoleArn",
          "Name"
        ],
        "members": {
          "Sample": {
            "shape": "S2f"
          },
          "RoleArn": {},
          "Name": {
            "location": "uri",
            "locationName": "name"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "LastModifiedDate": {
            "type": "timestamp"
          },
          "Name": {}
        }
      }
    },
    "UpdateRecipe": {
      "http": {
        "method": "PUT",
        "requestUri": "/recipes/{name}"
      },
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Description": {},
          "Name": {
            "location": "uri",
            "locationName": "name"
          },
          "Steps": {
            "shape": "S2l"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {}
        }
      }
    },
    "UpdateRecipeJob": {
      "http": {
        "method": "PUT",
        "requestUri": "/recipeJobs/{name}"
      },
      "input": {
        "type": "structure",
        "required": [
          "Name",
          "RoleArn"
        ],
        "members": {
          "EncryptionKeyArn": {},
          "EncryptionMode": {},
          "Name": {
            "location": "uri",
            "locationName": "name"
          },
          "LogSubscription": {},
          "MaxCapacity": {
            "type": "integer"
          },
          "MaxRetries": {
            "type": "integer"
          },
          "Outputs": {
            "shape": "S2v"
          },
          "DataCatalogOutputs": {
            "shape": "S33"
          },
          "DatabaseOutputs": {
            "shape": "S37"
          },
          "RoleArn": {},
          "Timeout": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {}
        }
      }
    },
    "UpdateSchedule": {
      "http": {
        "method": "PUT",
        "requestUri": "/schedules/{name}"
      },
      "input": {
        "type": "structure",
        "required": [
          "CronExpression",
          "Name"
        ],
        "members": {
          "JobNames": {
            "shape": "S3d"
          },
          "CronExpression": {},
          "Name": {
            "location": "uri",
            "locationName": "name"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {}
        }
      }
    }
  },
  "shapes": {
    "Sd": {
      "type": "structure",
      "members": {
        "Json": {
          "type": "structure",
          "members": {
            "MultiLine": {
              "type": "boolean"
            }
          }
        },
        "Excel": {
          "type": "structure",
          "members": {
            "SheetNames": {
              "type": "list",
              "member": {}
            },
            "SheetIndexes": {
              "type": "list",
              "member": {
                "type": "integer"
              }
            },
            "HeaderRow": {
              "type": "boolean"
            }
          }
        },
        "Csv": {
          "type": "structure",
          "members": {
            "Delimiter": {},
            "HeaderRow": {
              "type": "boolean"
            }
          }
        }
      }
    },
    "So": {
      "type": "structure",
      "members": {
        "S3InputDefinition": {
          "shape": "Sp"
        },
        "DataCatalogInputDefinition": {
          "type": "structure",
          "required": [
            "DatabaseName",
            "TableName"
          ],
          "members": {
            "CatalogId": {},
            "DatabaseName": {},
            "TableName": {},
            "TempDirectory": {
              "shape": "Sp"
            }
          }
        },
        "DatabaseInputDefinition": {
          "type": "structure",
          "required": [
            "GlueConnectionName",
            "DatabaseTableName"
          ],
          "members": {
            "GlueConnectionName": {},
            "DatabaseTableName": {},
            "TempDirectory": {
              "shape": "Sp"
            }
          }
        }
      }
    },
    "Sp": {
      "type": "structure",
      "required": [
        "Bucket"
      ],
      "members": {
        "Bucket": {},
        "Key": {}
      }
    },
    "Sz": {
      "type": "structure",
      "members": {
        "LastModifiedDateCondition": {
          "shape": "S10"
        },
        "FilesLimit": {
          "type": "structure",
          "required": [
            "MaxFiles"
          ],
          "members": {
            "MaxFiles": {
              "type": "integer"
            },
            "OrderedBy": {},
            "Order": {}
          }
        },
        "Parameters": {
          "type": "map",
          "key": {},
          "value": {
            "type": "structure",
            "required": [
              "Name",
              "Type"
            ],
            "members": {
              "Name": {},
              "Type": {},
              "DatetimeOptions": {
                "type": "structure",
                "required": [
                  "Format"
                ],
                "members": {
                  "Format": {},
                  "TimezoneOffset": {},
                  "LocaleCode": {}
                }
              },
              "CreateColumn": {
                "type": "boolean"
              },
              "Filter": {
                "shape": "S10"
              }
            }
          }
        }
      }
    },
    "S10": {
      "type": "structure",
      "required": [
        "Expression",
        "ValuesMap"
      ],
      "members": {
        "Expression": {},
        "ValuesMap": {
          "type": "map",
          "key": {},
          "value": {}
        }
      }
    },
    "S1i": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "S1t": {
      "type": "structure",
      "members": {
        "DatasetStatisticsConfiguration": {
          "shape": "S1u"
        },
        "ProfileColumns": {
          "shape": "S22"
        },
        "ColumnStatisticsConfigurations": {
          "type": "list",
          "member": {
            "type": "structure",
            "required": [
              "Statistics"
            ],
            "members": {
              "Selectors": {
                "shape": "S22"
              },
              "Statistics": {
                "shape": "S1u"
              }
            }
          }
        }
      }
    },
    "S1u": {
      "type": "structure",
      "members": {
        "IncludedStatistics": {
          "type": "list",
          "member": {}
        },
        "Overrides": {
          "type": "list",
          "member": {
            "type": "structure",
            "required": [
              "Statistic",
              "Parameters"
            ],
            "members": {
              "Statistic": {},
              "Parameters": {
                "shape": "S1z"
              }
            }
          }
        }
      }
    },
    "S1z": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "S22": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "Regex": {},
          "Name": {}
        }
      }
    },
    "S29": {
      "type": "structure",
      "members": {
        "Mode": {},
        "Size": {
          "type": "long"
        }
      }
    },
    "S2f": {
      "type": "structure",
      "required": [
        "Type"
      ],
      "members": {
        "Size": {
          "type": "integer"
        },
        "Type": {}
      }
    },
    "S2l": {
      "type": "list",
      "member": {
        "shape": "S2m"
      }
    },
    "S2m": {
      "type": "structure",
      "required": [
        "Action"
      ],
      "members": {
        "Action": {
          "type": "structure",
          "required": [
            "Operation"
          ],
          "members": {
            "Operation": {},
            "Parameters": {
              "shape": "S1z"
            }
          }
        },
        "ConditionExpressions": {
          "type": "list",
          "member": {
            "type": "structure",
            "required": [
              "Condition",
              "TargetColumn"
            ],
            "members": {
              "Condition": {},
              "Value": {},
              "TargetColumn": {}
            }
          }
        }
      }
    },
    "S2v": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "Location"
        ],
        "members": {
          "CompressionFormat": {},
          "Format": {},
          "PartitionColumns": {
            "type": "list",
            "member": {}
          },
          "Location": {
            "shape": "Sp"
          },
          "Overwrite": {
            "type": "boolean"
          },
          "FormatOptions": {
            "type": "structure",
            "members": {
              "Csv": {
                "type": "structure",
                "members": {
                  "Delimiter": {}
                }
              }
            }
          }
        }
      }
    },
    "S33": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "DatabaseName",
          "TableName"
        ],
        "members": {
          "CatalogId": {},
          "DatabaseName": {},
          "TableName": {},
          "S3Options": {
            "type": "structure",
            "required": [
              "Location"
            ],
            "members": {
              "Location": {
                "shape": "Sp"
              }
            }
          },
          "DatabaseOptions": {
            "shape": "S36"
          },
          "Overwrite": {
            "type": "boolean"
          }
        }
      }
    },
    "S36": {
      "type": "structure",
      "required": [
        "TableName"
      ],
      "members": {
        "TempDirectory": {
          "shape": "Sp"
        },
        "TableName": {}
      }
    },
    "S37": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "GlueConnectionName",
          "DatabaseOptions"
        ],
        "members": {
          "GlueConnectionName": {},
          "DatabaseOptions": {
            "shape": "S36"
          },
          "DatabaseOutputMode": {}
        }
      }
    },
    "S3a": {
      "type": "structure",
      "required": [
        "Name"
      ],
      "members": {
        "Name": {},
        "RecipeVersion": {}
      }
    },
    "S3d": {
      "type": "list",
      "member": {}
    },
    "S53": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "CreatedBy": {},
          "CreateDate": {
            "type": "timestamp"
          },
          "LastModifiedBy": {},
          "LastModifiedDate": {
            "type": "timestamp"
          },
          "ProjectName": {},
          "PublishedBy": {},
          "PublishedDate": {
            "type": "timestamp"
          },
          "Description": {},
          "Name": {},
          "ResourceArn": {},
          "Steps": {
            "shape": "S2l"
          },
          "Tags": {
            "shape": "S1i"
          },
          "RecipeVersion": {}
        }
      }
    }
  }
}