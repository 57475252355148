{
  "version": "2.0",
  "metadata": {
    "apiVersion": "2019-09-01",
    "endpointPrefix": "braket",
    "jsonVersion": "1.1",
    "protocol": "rest-json",
    "serviceFullName": "Braket",
    "serviceId": "Braket",
    "signatureVersion": "v4",
    "signingName": "braket",
    "uid": "braket-2019-09-01"
  },
  "operations": {
    "CancelQuantumTask": {
      "http": {
        "method": "PUT",
        "requestUri": "/quantum-task/{quantumTaskArn}/cancel",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "clientToken",
          "quantumTaskArn"
        ],
        "members": {
          "clientToken": {
            "idempotencyToken": true
          },
          "quantumTaskArn": {
            "location": "uri",
            "locationName": "quantumTaskArn"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "cancellationStatus",
          "quantumTaskArn"
        ],
        "members": {
          "cancellationStatus": {},
          "quantumTaskArn": {}
        }
      },
      "idempotent": true
    },
    "CreateQuantumTask": {
      "http": {
        "requestUri": "/quantum-task",
        "responseCode": 201
      },
      "input": {
        "type": "structure",
        "required": [
          "action",
          "clientToken",
          "deviceArn",
          "outputS3Bucket",
          "outputS3KeyPrefix",
          "shots"
        ],
        "members": {
          "action": {
            "jsonvalue": true
          },
          "clientToken": {
            "idempotencyToken": true
          },
          "deviceArn": {},
          "deviceParameters": {
            "jsonvalue": true
          },
          "outputS3Bucket": {},
          "outputS3KeyPrefix": {},
          "shots": {
            "type": "long"
          },
          "tags": {
            "shape": "Sd"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "quantumTaskArn"
        ],
        "members": {
          "quantumTaskArn": {}
        }
      }
    },
    "GetDevice": {
      "http": {
        "method": "GET",
        "requestUri": "/device/{deviceArn}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "deviceArn"
        ],
        "members": {
          "deviceArn": {
            "location": "uri",
            "locationName": "deviceArn"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "deviceArn",
          "deviceCapabilities",
          "deviceName",
          "deviceStatus",
          "deviceType",
          "providerName"
        ],
        "members": {
          "deviceArn": {},
          "deviceCapabilities": {
            "jsonvalue": true
          },
          "deviceName": {},
          "deviceStatus": {},
          "deviceType": {},
          "providerName": {}
        }
      }
    },
    "GetQuantumTask": {
      "http": {
        "method": "GET",
        "requestUri": "/quantum-task/{quantumTaskArn}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "quantumTaskArn"
        ],
        "members": {
          "quantumTaskArn": {
            "location": "uri",
            "locationName": "quantumTaskArn"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "createdAt",
          "deviceArn",
          "deviceParameters",
          "outputS3Bucket",
          "outputS3Directory",
          "quantumTaskArn",
          "shots",
          "status"
        ],
        "members": {
          "createdAt": {
            "shape": "Sm"
          },
          "deviceArn": {},
          "deviceParameters": {
            "jsonvalue": true
          },
          "endedAt": {
            "shape": "Sm"
          },
          "failureReason": {},
          "outputS3Bucket": {},
          "outputS3Directory": {},
          "quantumTaskArn": {},
          "shots": {
            "type": "long"
          },
          "status": {},
          "tags": {
            "shape": "Sd"
          }
        }
      }
    },
    "ListTagsForResource": {
      "http": {
        "method": "GET",
        "requestUri": "/tags/{resourceArn}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "resourceArn"
        ],
        "members": {
          "resourceArn": {
            "location": "uri",
            "locationName": "resourceArn"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "tags": {
            "shape": "Sd"
          }
        }
      }
    },
    "SearchDevices": {
      "http": {
        "requestUri": "/devices",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "filters"
        ],
        "members": {
          "filters": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "name",
                "values"
              ],
              "members": {
                "name": {},
                "values": {
                  "type": "list",
                  "member": {}
                }
              }
            }
          },
          "maxResults": {
            "type": "integer"
          },
          "nextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "devices"
        ],
        "members": {
          "devices": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "deviceArn",
                "deviceName",
                "deviceStatus",
                "deviceType",
                "providerName"
              ],
              "members": {
                "deviceArn": {},
                "deviceName": {},
                "deviceStatus": {},
                "deviceType": {},
                "providerName": {}
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "SearchQuantumTasks": {
      "http": {
        "requestUri": "/quantum-tasks",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "filters"
        ],
        "members": {
          "filters": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "name",
                "operator",
                "values"
              ],
              "members": {
                "name": {},
                "operator": {},
                "values": {
                  "type": "list",
                  "member": {}
                }
              }
            }
          },
          "maxResults": {
            "type": "integer"
          },
          "nextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "quantumTasks"
        ],
        "members": {
          "nextToken": {},
          "quantumTasks": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "createdAt",
                "deviceArn",
                "outputS3Bucket",
                "outputS3Directory",
                "quantumTaskArn",
                "shots",
                "status"
              ],
              "members": {
                "createdAt": {
                  "shape": "Sm"
                },
                "deviceArn": {},
                "endedAt": {
                  "shape": "Sm"
                },
                "outputS3Bucket": {},
                "outputS3Directory": {},
                "quantumTaskArn": {},
                "shots": {
                  "type": "long"
                },
                "status": {},
                "tags": {
                  "shape": "Sd"
                }
              }
            }
          }
        }
      }
    },
    "TagResource": {
      "http": {
        "requestUri": "/tags/{resourceArn}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "resourceArn",
          "tags"
        ],
        "members": {
          "resourceArn": {
            "location": "uri",
            "locationName": "resourceArn"
          },
          "tags": {
            "shape": "Sd"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UntagResource": {
      "http": {
        "method": "DELETE",
        "requestUri": "/tags/{resourceArn}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "resourceArn",
          "tagKeys"
        ],
        "members": {
          "resourceArn": {
            "location": "uri",
            "locationName": "resourceArn"
          },
          "tagKeys": {
            "location": "querystring",
            "locationName": "tagKeys",
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    }
  },
  "shapes": {
    "Sd": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "Sm": {
      "type": "timestamp",
      "timestampFormat": "iso8601"
    }
  }
}