{
  "version": "2.0",
  "metadata": {
    "apiVersion": "2019-07-05",
    "endpointPrefix": "networkmanager",
    "jsonVersion": "1.1",
    "protocol": "rest-json",
    "serviceAbbreviation": "NetworkManager",
    "serviceFullName": "AWS Network Manager",
    "serviceId": "NetworkManager",
    "signatureVersion": "v4",
    "signingName": "networkmanager",
    "uid": "networkmanager-2019-07-05"
  },
  "operations": {
    "AssociateCustomerGateway": {
      "http": {
        "requestUri": "/global-networks/{globalNetworkId}/customer-gateway-associations"
      },
      "input": {
        "type": "structure",
        "required": [
          "CustomerGatewayArn",
          "GlobalNetworkId",
          "DeviceId"
        ],
        "members": {
          "CustomerGatewayArn": {},
          "GlobalNetworkId": {
            "location": "uri",
            "locationName": "globalNetworkId"
          },
          "DeviceId": {},
          "LinkId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "CustomerGatewayAssociation": {
            "shape": "S4"
          }
        }
      }
    },
    "AssociateLink": {
      "http": {
        "requestUri": "/global-networks/{globalNetworkId}/link-associations"
      },
      "input": {
        "type": "structure",
        "required": [
          "GlobalNetworkId",
          "DeviceId",
          "LinkId"
        ],
        "members": {
          "GlobalNetworkId": {
            "location": "uri",
            "locationName": "globalNetworkId"
          },
          "DeviceId": {},
          "LinkId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "LinkAssociation": {
            "shape": "S8"
          }
        }
      }
    },
    "AssociateTransitGatewayConnectPeer": {
      "http": {
        "requestUri": "/global-networks/{globalNetworkId}/transit-gateway-connect-peer-associations"
      },
      "input": {
        "type": "structure",
        "required": [
          "GlobalNetworkId",
          "TransitGatewayConnectPeerArn",
          "DeviceId"
        ],
        "members": {
          "GlobalNetworkId": {
            "location": "uri",
            "locationName": "globalNetworkId"
          },
          "TransitGatewayConnectPeerArn": {},
          "DeviceId": {},
          "LinkId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TransitGatewayConnectPeerAssociation": {
            "shape": "Sc"
          }
        }
      }
    },
    "CreateConnection": {
      "http": {
        "requestUri": "/global-networks/{globalNetworkId}/connections"
      },
      "input": {
        "type": "structure",
        "required": [
          "GlobalNetworkId",
          "DeviceId",
          "ConnectedDeviceId"
        ],
        "members": {
          "GlobalNetworkId": {
            "location": "uri",
            "locationName": "globalNetworkId"
          },
          "DeviceId": {},
          "ConnectedDeviceId": {},
          "LinkId": {},
          "ConnectedLinkId": {},
          "Description": {},
          "Tags": {
            "shape": "Sf"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Connection": {
            "shape": "Sk"
          }
        }
      }
    },
    "CreateDevice": {
      "http": {
        "requestUri": "/global-networks/{globalNetworkId}/devices"
      },
      "input": {
        "type": "structure",
        "required": [
          "GlobalNetworkId"
        ],
        "members": {
          "GlobalNetworkId": {
            "location": "uri",
            "locationName": "globalNetworkId"
          },
          "AWSLocation": {
            "shape": "So"
          },
          "Description": {},
          "Type": {},
          "Vendor": {},
          "Model": {},
          "SerialNumber": {},
          "Location": {
            "shape": "Sp"
          },
          "SiteId": {},
          "Tags": {
            "shape": "Sf"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Device": {
            "shape": "Sr"
          }
        }
      }
    },
    "CreateGlobalNetwork": {
      "http": {
        "requestUri": "/global-networks"
      },
      "input": {
        "type": "structure",
        "members": {
          "Description": {},
          "Tags": {
            "shape": "Sf"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "GlobalNetwork": {
            "shape": "Sv"
          }
        }
      }
    },
    "CreateLink": {
      "http": {
        "requestUri": "/global-networks/{globalNetworkId}/links"
      },
      "input": {
        "type": "structure",
        "required": [
          "GlobalNetworkId",
          "Bandwidth",
          "SiteId"
        ],
        "members": {
          "GlobalNetworkId": {
            "location": "uri",
            "locationName": "globalNetworkId"
          },
          "Description": {},
          "Type": {},
          "Bandwidth": {
            "shape": "Sy"
          },
          "Provider": {},
          "SiteId": {},
          "Tags": {
            "shape": "Sf"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Link": {
            "shape": "S11"
          }
        }
      }
    },
    "CreateSite": {
      "http": {
        "requestUri": "/global-networks/{globalNetworkId}/sites"
      },
      "input": {
        "type": "structure",
        "required": [
          "GlobalNetworkId"
        ],
        "members": {
          "GlobalNetworkId": {
            "location": "uri",
            "locationName": "globalNetworkId"
          },
          "Description": {},
          "Location": {
            "shape": "Sp"
          },
          "Tags": {
            "shape": "Sf"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Site": {
            "shape": "S15"
          }
        }
      }
    },
    "DeleteConnection": {
      "http": {
        "method": "DELETE",
        "requestUri": "/global-networks/{globalNetworkId}/connections/{connectionId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "GlobalNetworkId",
          "ConnectionId"
        ],
        "members": {
          "GlobalNetworkId": {
            "location": "uri",
            "locationName": "globalNetworkId"
          },
          "ConnectionId": {
            "location": "uri",
            "locationName": "connectionId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Connection": {
            "shape": "Sk"
          }
        }
      }
    },
    "DeleteDevice": {
      "http": {
        "method": "DELETE",
        "requestUri": "/global-networks/{globalNetworkId}/devices/{deviceId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "GlobalNetworkId",
          "DeviceId"
        ],
        "members": {
          "GlobalNetworkId": {
            "location": "uri",
            "locationName": "globalNetworkId"
          },
          "DeviceId": {
            "location": "uri",
            "locationName": "deviceId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Device": {
            "shape": "Sr"
          }
        }
      }
    },
    "DeleteGlobalNetwork": {
      "http": {
        "method": "DELETE",
        "requestUri": "/global-networks/{globalNetworkId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "GlobalNetworkId"
        ],
        "members": {
          "GlobalNetworkId": {
            "location": "uri",
            "locationName": "globalNetworkId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "GlobalNetwork": {
            "shape": "Sv"
          }
        }
      }
    },
    "DeleteLink": {
      "http": {
        "method": "DELETE",
        "requestUri": "/global-networks/{globalNetworkId}/links/{linkId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "GlobalNetworkId",
          "LinkId"
        ],
        "members": {
          "GlobalNetworkId": {
            "location": "uri",
            "locationName": "globalNetworkId"
          },
          "LinkId": {
            "location": "uri",
            "locationName": "linkId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Link": {
            "shape": "S11"
          }
        }
      }
    },
    "DeleteSite": {
      "http": {
        "method": "DELETE",
        "requestUri": "/global-networks/{globalNetworkId}/sites/{siteId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "GlobalNetworkId",
          "SiteId"
        ],
        "members": {
          "GlobalNetworkId": {
            "location": "uri",
            "locationName": "globalNetworkId"
          },
          "SiteId": {
            "location": "uri",
            "locationName": "siteId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Site": {
            "shape": "S15"
          }
        }
      }
    },
    "DeregisterTransitGateway": {
      "http": {
        "method": "DELETE",
        "requestUri": "/global-networks/{globalNetworkId}/transit-gateway-registrations/{transitGatewayArn}"
      },
      "input": {
        "type": "structure",
        "required": [
          "GlobalNetworkId",
          "TransitGatewayArn"
        ],
        "members": {
          "GlobalNetworkId": {
            "location": "uri",
            "locationName": "globalNetworkId"
          },
          "TransitGatewayArn": {
            "location": "uri",
            "locationName": "transitGatewayArn"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TransitGatewayRegistration": {
            "shape": "S1j"
          }
        }
      }
    },
    "DescribeGlobalNetworks": {
      "http": {
        "method": "GET",
        "requestUri": "/global-networks"
      },
      "input": {
        "type": "structure",
        "members": {
          "GlobalNetworkIds": {
            "shape": "S1n",
            "location": "querystring",
            "locationName": "globalNetworkIds"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "GlobalNetworks": {
            "type": "list",
            "member": {
              "shape": "Sv"
            }
          },
          "NextToken": {}
        }
      }
    },
    "DisassociateCustomerGateway": {
      "http": {
        "method": "DELETE",
        "requestUri": "/global-networks/{globalNetworkId}/customer-gateway-associations/{customerGatewayArn}"
      },
      "input": {
        "type": "structure",
        "required": [
          "GlobalNetworkId",
          "CustomerGatewayArn"
        ],
        "members": {
          "GlobalNetworkId": {
            "location": "uri",
            "locationName": "globalNetworkId"
          },
          "CustomerGatewayArn": {
            "location": "uri",
            "locationName": "customerGatewayArn"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "CustomerGatewayAssociation": {
            "shape": "S4"
          }
        }
      }
    },
    "DisassociateLink": {
      "http": {
        "method": "DELETE",
        "requestUri": "/global-networks/{globalNetworkId}/link-associations"
      },
      "input": {
        "type": "structure",
        "required": [
          "GlobalNetworkId",
          "DeviceId",
          "LinkId"
        ],
        "members": {
          "GlobalNetworkId": {
            "location": "uri",
            "locationName": "globalNetworkId"
          },
          "DeviceId": {
            "location": "querystring",
            "locationName": "deviceId"
          },
          "LinkId": {
            "location": "querystring",
            "locationName": "linkId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "LinkAssociation": {
            "shape": "S8"
          }
        }
      }
    },
    "DisassociateTransitGatewayConnectPeer": {
      "http": {
        "method": "DELETE",
        "requestUri": "/global-networks/{globalNetworkId}/transit-gateway-connect-peer-associations/{transitGatewayConnectPeerArn}"
      },
      "input": {
        "type": "structure",
        "required": [
          "GlobalNetworkId",
          "TransitGatewayConnectPeerArn"
        ],
        "members": {
          "GlobalNetworkId": {
            "location": "uri",
            "locationName": "globalNetworkId"
          },
          "TransitGatewayConnectPeerArn": {
            "location": "uri",
            "locationName": "transitGatewayConnectPeerArn"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TransitGatewayConnectPeerAssociation": {
            "shape": "Sc"
          }
        }
      }
    },
    "GetConnections": {
      "http": {
        "method": "GET",
        "requestUri": "/global-networks/{globalNetworkId}/connections"
      },
      "input": {
        "type": "structure",
        "required": [
          "GlobalNetworkId"
        ],
        "members": {
          "GlobalNetworkId": {
            "location": "uri",
            "locationName": "globalNetworkId"
          },
          "ConnectionIds": {
            "shape": "S1n",
            "location": "querystring",
            "locationName": "connectionIds"
          },
          "DeviceId": {
            "location": "querystring",
            "locationName": "deviceId"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Connections": {
            "type": "list",
            "member": {
              "shape": "Sk"
            }
          },
          "NextToken": {}
        }
      }
    },
    "GetCustomerGatewayAssociations": {
      "http": {
        "method": "GET",
        "requestUri": "/global-networks/{globalNetworkId}/customer-gateway-associations"
      },
      "input": {
        "type": "structure",
        "required": [
          "GlobalNetworkId"
        ],
        "members": {
          "GlobalNetworkId": {
            "location": "uri",
            "locationName": "globalNetworkId"
          },
          "CustomerGatewayArns": {
            "shape": "S1n",
            "location": "querystring",
            "locationName": "customerGatewayArns"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "CustomerGatewayAssociations": {
            "type": "list",
            "member": {
              "shape": "S4"
            }
          },
          "NextToken": {}
        }
      }
    },
    "GetDevices": {
      "http": {
        "method": "GET",
        "requestUri": "/global-networks/{globalNetworkId}/devices"
      },
      "input": {
        "type": "structure",
        "required": [
          "GlobalNetworkId"
        ],
        "members": {
          "GlobalNetworkId": {
            "location": "uri",
            "locationName": "globalNetworkId"
          },
          "DeviceIds": {
            "shape": "S1n",
            "location": "querystring",
            "locationName": "deviceIds"
          },
          "SiteId": {
            "location": "querystring",
            "locationName": "siteId"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Devices": {
            "type": "list",
            "member": {
              "shape": "Sr"
            }
          },
          "NextToken": {}
        }
      }
    },
    "GetLinkAssociations": {
      "http": {
        "method": "GET",
        "requestUri": "/global-networks/{globalNetworkId}/link-associations"
      },
      "input": {
        "type": "structure",
        "required": [
          "GlobalNetworkId"
        ],
        "members": {
          "GlobalNetworkId": {
            "location": "uri",
            "locationName": "globalNetworkId"
          },
          "DeviceId": {
            "location": "querystring",
            "locationName": "deviceId"
          },
          "LinkId": {
            "location": "querystring",
            "locationName": "linkId"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "LinkAssociations": {
            "type": "list",
            "member": {
              "shape": "S8"
            }
          },
          "NextToken": {}
        }
      }
    },
    "GetLinks": {
      "http": {
        "method": "GET",
        "requestUri": "/global-networks/{globalNetworkId}/links"
      },
      "input": {
        "type": "structure",
        "required": [
          "GlobalNetworkId"
        ],
        "members": {
          "GlobalNetworkId": {
            "location": "uri",
            "locationName": "globalNetworkId"
          },
          "LinkIds": {
            "shape": "S1n",
            "location": "querystring",
            "locationName": "linkIds"
          },
          "SiteId": {
            "location": "querystring",
            "locationName": "siteId"
          },
          "Type": {
            "location": "querystring",
            "locationName": "type"
          },
          "Provider": {
            "location": "querystring",
            "locationName": "provider"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Links": {
            "type": "list",
            "member": {
              "shape": "S11"
            }
          },
          "NextToken": {}
        }
      }
    },
    "GetSites": {
      "http": {
        "method": "GET",
        "requestUri": "/global-networks/{globalNetworkId}/sites"
      },
      "input": {
        "type": "structure",
        "required": [
          "GlobalNetworkId"
        ],
        "members": {
          "GlobalNetworkId": {
            "location": "uri",
            "locationName": "globalNetworkId"
          },
          "SiteIds": {
            "shape": "S1n",
            "location": "querystring",
            "locationName": "siteIds"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Sites": {
            "type": "list",
            "member": {
              "shape": "S15"
            }
          },
          "NextToken": {}
        }
      }
    },
    "GetTransitGatewayConnectPeerAssociations": {
      "http": {
        "method": "GET",
        "requestUri": "/global-networks/{globalNetworkId}/transit-gateway-connect-peer-associations"
      },
      "input": {
        "type": "structure",
        "required": [
          "GlobalNetworkId"
        ],
        "members": {
          "GlobalNetworkId": {
            "location": "uri",
            "locationName": "globalNetworkId"
          },
          "TransitGatewayConnectPeerArns": {
            "shape": "S1n",
            "location": "querystring",
            "locationName": "transitGatewayConnectPeerArns"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TransitGatewayConnectPeerAssociations": {
            "type": "list",
            "member": {
              "shape": "Sc"
            }
          },
          "NextToken": {}
        }
      }
    },
    "GetTransitGatewayRegistrations": {
      "http": {
        "method": "GET",
        "requestUri": "/global-networks/{globalNetworkId}/transit-gateway-registrations"
      },
      "input": {
        "type": "structure",
        "required": [
          "GlobalNetworkId"
        ],
        "members": {
          "GlobalNetworkId": {
            "location": "uri",
            "locationName": "globalNetworkId"
          },
          "TransitGatewayArns": {
            "shape": "S1n",
            "location": "querystring",
            "locationName": "transitGatewayArns"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TransitGatewayRegistrations": {
            "type": "list",
            "member": {
              "shape": "S1j"
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListTagsForResource": {
      "http": {
        "method": "GET",
        "requestUri": "/tags/{resourceArn}"
      },
      "input": {
        "type": "structure",
        "required": [
          "ResourceArn"
        ],
        "members": {
          "ResourceArn": {
            "location": "uri",
            "locationName": "resourceArn"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TagList": {
            "shape": "Sf"
          }
        }
      }
    },
    "RegisterTransitGateway": {
      "http": {
        "requestUri": "/global-networks/{globalNetworkId}/transit-gateway-registrations"
      },
      "input": {
        "type": "structure",
        "required": [
          "GlobalNetworkId",
          "TransitGatewayArn"
        ],
        "members": {
          "GlobalNetworkId": {
            "location": "uri",
            "locationName": "globalNetworkId"
          },
          "TransitGatewayArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TransitGatewayRegistration": {
            "shape": "S1j"
          }
        }
      }
    },
    "TagResource": {
      "http": {
        "requestUri": "/tags/{resourceArn}"
      },
      "input": {
        "type": "structure",
        "required": [
          "ResourceArn",
          "Tags"
        ],
        "members": {
          "ResourceArn": {
            "location": "uri",
            "locationName": "resourceArn"
          },
          "Tags": {
            "shape": "Sf"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UntagResource": {
      "http": {
        "method": "DELETE",
        "requestUri": "/tags/{resourceArn}"
      },
      "input": {
        "type": "structure",
        "required": [
          "ResourceArn",
          "TagKeys"
        ],
        "members": {
          "ResourceArn": {
            "location": "uri",
            "locationName": "resourceArn"
          },
          "TagKeys": {
            "location": "querystring",
            "locationName": "tagKeys",
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UpdateConnection": {
      "http": {
        "method": "PATCH",
        "requestUri": "/global-networks/{globalNetworkId}/connections/{connectionId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "GlobalNetworkId",
          "ConnectionId"
        ],
        "members": {
          "GlobalNetworkId": {
            "location": "uri",
            "locationName": "globalNetworkId"
          },
          "ConnectionId": {
            "location": "uri",
            "locationName": "connectionId"
          },
          "LinkId": {},
          "ConnectedLinkId": {},
          "Description": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Connection": {
            "shape": "Sk"
          }
        }
      }
    },
    "UpdateDevice": {
      "http": {
        "method": "PATCH",
        "requestUri": "/global-networks/{globalNetworkId}/devices/{deviceId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "GlobalNetworkId",
          "DeviceId"
        ],
        "members": {
          "GlobalNetworkId": {
            "location": "uri",
            "locationName": "globalNetworkId"
          },
          "DeviceId": {
            "location": "uri",
            "locationName": "deviceId"
          },
          "AWSLocation": {
            "shape": "So"
          },
          "Description": {},
          "Type": {},
          "Vendor": {},
          "Model": {},
          "SerialNumber": {},
          "Location": {
            "shape": "Sp"
          },
          "SiteId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Device": {
            "shape": "Sr"
          }
        }
      }
    },
    "UpdateGlobalNetwork": {
      "http": {
        "method": "PATCH",
        "requestUri": "/global-networks/{globalNetworkId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "GlobalNetworkId"
        ],
        "members": {
          "GlobalNetworkId": {
            "location": "uri",
            "locationName": "globalNetworkId"
          },
          "Description": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "GlobalNetwork": {
            "shape": "Sv"
          }
        }
      }
    },
    "UpdateLink": {
      "http": {
        "method": "PATCH",
        "requestUri": "/global-networks/{globalNetworkId}/links/{linkId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "GlobalNetworkId",
          "LinkId"
        ],
        "members": {
          "GlobalNetworkId": {
            "location": "uri",
            "locationName": "globalNetworkId"
          },
          "LinkId": {
            "location": "uri",
            "locationName": "linkId"
          },
          "Description": {},
          "Type": {},
          "Bandwidth": {
            "shape": "Sy"
          },
          "Provider": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Link": {
            "shape": "S11"
          }
        }
      }
    },
    "UpdateSite": {
      "http": {
        "method": "PATCH",
        "requestUri": "/global-networks/{globalNetworkId}/sites/{siteId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "GlobalNetworkId",
          "SiteId"
        ],
        "members": {
          "GlobalNetworkId": {
            "location": "uri",
            "locationName": "globalNetworkId"
          },
          "SiteId": {
            "location": "uri",
            "locationName": "siteId"
          },
          "Description": {},
          "Location": {
            "shape": "Sp"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Site": {
            "shape": "S15"
          }
        }
      }
    }
  },
  "shapes": {
    "S4": {
      "type": "structure",
      "members": {
        "CustomerGatewayArn": {},
        "GlobalNetworkId": {},
        "DeviceId": {},
        "LinkId": {},
        "State": {}
      }
    },
    "S8": {
      "type": "structure",
      "members": {
        "GlobalNetworkId": {},
        "DeviceId": {},
        "LinkId": {},
        "LinkAssociationState": {}
      }
    },
    "Sc": {
      "type": "structure",
      "members": {
        "TransitGatewayConnectPeerArn": {},
        "GlobalNetworkId": {},
        "DeviceId": {},
        "LinkId": {},
        "State": {}
      }
    },
    "Sf": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "Key": {},
          "Value": {}
        }
      }
    },
    "Sk": {
      "type": "structure",
      "members": {
        "ConnectionId": {},
        "ConnectionArn": {},
        "GlobalNetworkId": {},
        "DeviceId": {},
        "ConnectedDeviceId": {},
        "LinkId": {},
        "ConnectedLinkId": {},
        "Description": {},
        "CreatedAt": {
          "type": "timestamp"
        },
        "State": {},
        "Tags": {
          "shape": "Sf"
        }
      }
    },
    "So": {
      "type": "structure",
      "members": {
        "Zone": {},
        "SubnetArn": {}
      }
    },
    "Sp": {
      "type": "structure",
      "members": {
        "Address": {},
        "Latitude": {},
        "Longitude": {}
      },
      "sensitive": true
    },
    "Sr": {
      "type": "structure",
      "members": {
        "DeviceId": {},
        "DeviceArn": {},
        "GlobalNetworkId": {},
        "AWSLocation": {
          "shape": "So"
        },
        "Description": {},
        "Type": {},
        "Vendor": {},
        "Model": {},
        "SerialNumber": {},
        "Location": {
          "shape": "Sp"
        },
        "SiteId": {},
        "CreatedAt": {
          "type": "timestamp"
        },
        "State": {},
        "Tags": {
          "shape": "Sf"
        }
      }
    },
    "Sv": {
      "type": "structure",
      "members": {
        "GlobalNetworkId": {},
        "GlobalNetworkArn": {},
        "Description": {},
        "CreatedAt": {
          "type": "timestamp"
        },
        "State": {},
        "Tags": {
          "shape": "Sf"
        }
      }
    },
    "Sy": {
      "type": "structure",
      "members": {
        "UploadSpeed": {
          "type": "integer"
        },
        "DownloadSpeed": {
          "type": "integer"
        }
      }
    },
    "S11": {
      "type": "structure",
      "members": {
        "LinkId": {},
        "LinkArn": {},
        "GlobalNetworkId": {},
        "SiteId": {},
        "Description": {},
        "Type": {},
        "Bandwidth": {
          "shape": "Sy"
        },
        "Provider": {},
        "CreatedAt": {
          "type": "timestamp"
        },
        "State": {},
        "Tags": {
          "shape": "Sf"
        }
      }
    },
    "S15": {
      "type": "structure",
      "members": {
        "SiteId": {},
        "SiteArn": {},
        "GlobalNetworkId": {},
        "Description": {},
        "Location": {
          "shape": "Sp"
        },
        "CreatedAt": {
          "type": "timestamp"
        },
        "State": {},
        "Tags": {
          "shape": "Sf"
        }
      }
    },
    "S1j": {
      "type": "structure",
      "members": {
        "GlobalNetworkId": {},
        "TransitGatewayArn": {},
        "State": {
          "type": "structure",
          "members": {
            "Code": {},
            "Message": {}
          }
        }
      }
    },
    "S1n": {
      "type": "list",
      "member": {}
    }
  }
}