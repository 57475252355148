{
  "version": "2.0",
  "metadata": {
    "apiVersion": "2018-11-01",
    "endpointPrefix": "query.timestream",
    "jsonVersion": "1.0",
    "protocol": "json",
    "serviceAbbreviation": "Timestream Query",
    "serviceFullName": "Amazon Timestream Query",
    "serviceId": "Timestream Query",
    "signatureVersion": "v4",
    "signingName": "timestream",
    "targetPrefix": "Timestream_20181101",
    "uid": "timestream-query-2018-11-01"
  },
  "operations": {
    "CancelQuery": {
      "input": {
        "type": "structure",
        "required": [
          "QueryId"
        ],
        "members": {
          "QueryId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "CancellationMessage": {}
        }
      },
      "endpointdiscovery": {
        "required": true
      },
      "idempotent": true
    },
    "DescribeEndpoints": {
      "input": {
        "type": "structure",
        "members": {}
      },
      "output": {
        "type": "structure",
        "required": [
          "Endpoints"
        ],
        "members": {
          "Endpoints": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "Address",
                "CachePeriodInMinutes"
              ],
              "members": {
                "Address": {},
                "CachePeriodInMinutes": {
                  "type": "long"
                }
              }
            }
          }
        }
      },
      "endpointoperation": true
    },
    "Query": {
      "input": {
        "type": "structure",
        "required": [
          "QueryString"
        ],
        "members": {
          "QueryString": {
            "type": "string",
            "sensitive": true
          },
          "ClientToken": {
            "idempotencyToken": true,
            "type": "string",
            "sensitive": true
          },
          "NextToken": {},
          "MaxRows": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "QueryId",
          "Rows",
          "ColumnInfo"
        ],
        "members": {
          "QueryId": {},
          "NextToken": {},
          "Rows": {
            "type": "list",
            "member": {
              "shape": "Sg"
            }
          },
          "ColumnInfo": {
            "shape": "So"
          },
          "QueryStatus": {
            "type": "structure",
            "members": {
              "ProgressPercentage": {
                "type": "double"
              },
              "CumulativeBytesScanned": {
                "type": "long"
              },
              "CumulativeBytesMetered": {
                "type": "long"
              }
            }
          }
        }
      },
      "endpointdiscovery": {
        "required": true
      },
      "idempotent": true
    }
  },
  "shapes": {
    "Sg": {
      "type": "structure",
      "required": [
        "Data"
      ],
      "members": {
        "Data": {
          "shape": "Sh"
        }
      }
    },
    "Sh": {
      "type": "list",
      "member": {
        "shape": "Si"
      }
    },
    "Si": {
      "type": "structure",
      "members": {
        "ScalarValue": {},
        "TimeSeriesValue": {
          "type": "list",
          "member": {
            "type": "structure",
            "required": [
              "Time",
              "Value"
            ],
            "members": {
              "Time": {},
              "Value": {
                "shape": "Si"
              }
            }
          }
        },
        "ArrayValue": {
          "shape": "Sh"
        },
        "RowValue": {
          "shape": "Sg"
        },
        "NullValue": {
          "type": "boolean"
        }
      }
    },
    "So": {
      "type": "list",
      "member": {
        "shape": "Sp"
      }
    },
    "Sp": {
      "type": "structure",
      "required": [
        "Type"
      ],
      "members": {
        "Name": {},
        "Type": {
          "type": "structure",
          "members": {
            "ScalarType": {},
            "ArrayColumnInfo": {
              "shape": "Sp"
            },
            "TimeSeriesMeasureValueColumnInfo": {
              "shape": "Sp"
            },
            "RowColumnInfo": {
              "shape": "So"
            }
          }
        }
      }
    }
  }
}