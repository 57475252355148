import { makeAutoObservable, flow, autorun } from 'mobx'
import { debounce, head, isEmpty, reduce, size, assign, pick } from 'lodash'
import { env } from '../features/backend/env'
import { addInputListeners } from './userInput'

class AppState {
  constructor(rootStore) {
    makeAutoObservable(this, {})
    this.rootStore = rootStore
  }

  get unsavedChanges() {
    return (
      this.rootStore.persistence.lastSavedTrainingHash !==
      JSON.stringify(this.rootStore.training.toJSON())
    )
  }

  initialize() {
    this.selectFirstStep()
    addInputListeners()
    autorun(() => {
      if (this.unsavedChanges && env.environment !== 'development') {
        this.autosave()
      }
    })
  }

  reset() {
    this.loading = {}
    this.mode = 'editor'
    this.selectedStepId = null
    this.labelsVisible = true
    this.fullscreen = false
    this.sidebarDrawer = null
  }

  // loading

  loading = {}
  setLoading = (key, value) => (this.loading[key] = value)
  clearLoading = key => delete this.loading[key]
  isLoading = () => !isEmpty(this.loading)
  getLoadingProgress = () => {
    if (!this.isLoading) return 1
    const total = reduce(this.loading, (acc, value) => acc + value, 0)
    return total / size(this.loading)
  }

  // autosave

  autosave = debounce(() => this.rootStore.persistence.saveTraining(), 3000)

  // drawer

  sidebarDrawer = null

  // app mode

  mode = null
  // mode = 'player'
  editorMode() {
    this.mode = 'editor'
  }
  previewMode() {
    this.sidebarDrawer = null
    this.mode = 'preview'
  }
  playerMode() {
    this.sidebarDrawer = null
    this.mode = 'player'
  }

  // steps UI

  selectedStepId = null
  setSelectedStepId = stepId => {
    const { undo } = this.rootStore
    undo.saveSnapshot()
    this.selectedStepId = stepId
  }
  draggingStepId = null

  selectFirstStep() {
    // aoviding setSelectedStepId becaus this can't be undone
    const { training } = this.rootStore
    this.selectedStepId = head(training.stepSequence)
  }

  updateStepThumbnail = debounce(
    flow(function* () {
      const { screenshots, training } = this.rootStore
      const selectedStep = training.findStep(this.selectedStepId)
      selectedStep.thumbnail = yield screenshots.generateThumbnail(
        `${this.selectedStepId}-thumbnail.png`,
      )
    }),
    1 * 1000,
  )

  // player UI helpers

  fullscreen = false
  enableFullscreen = flow(function* () {
    try {
      yield document.body.requestFullscreen()
      this.fullscreen = true
    } catch (e) {
      this.fullscreen = false
    }
  })
  disableFullscreen() {
    document.exitFullscreen()
    this.fullscreen = false
  }

  // labels UI control

  labelsVisible = true
  hideLabels() {
    const { labels } = this.rootStore
    labels.hideMarkers()
    this.labelsVisible = false
  }
  showLabels() {
    const { labels } = this.rootStore
    labels.showMarkers()
    this.labelsVisible = true
  }

  // serialization

  serialize() {
    return pick(this, ['selectedStepId', 'labelsVisible'])
  }

  deserialize(data) {
    assign(this, pick(data, ['labelIsVisible']))
  }
}

export default AppState
