{
  "version": "2.0",
  "metadata": {
    "apiVersion": "2018-06-26",
    "endpointPrefix": "forecast",
    "jsonVersion": "1.1",
    "protocol": "json",
    "serviceFullName": "Amazon Forecast Service",
    "serviceId": "forecast",
    "signatureVersion": "v4",
    "signingName": "forecast",
    "targetPrefix": "AmazonForecast",
    "uid": "forecast-2018-06-26"
  },
  "operations": {
    "CreateDataset": {
      "input": {
        "type": "structure",
        "required": [
          "DatasetName",
          "Domain",
          "DatasetType",
          "Schema"
        ],
        "members": {
          "DatasetName": {},
          "Domain": {},
          "DatasetType": {},
          "DataFrequency": {},
          "Schema": {
            "shape": "S6"
          },
          "EncryptionConfig": {
            "shape": "Sa"
          },
          "Tags": {
            "shape": "Sd"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DatasetArn": {}
        }
      }
    },
    "CreateDatasetGroup": {
      "input": {
        "type": "structure",
        "required": [
          "DatasetGroupName",
          "Domain"
        ],
        "members": {
          "DatasetGroupName": {},
          "Domain": {},
          "DatasetArns": {
            "shape": "Sj"
          },
          "Tags": {
            "shape": "Sd"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DatasetGroupArn": {}
        }
      }
    },
    "CreateDatasetImportJob": {
      "input": {
        "type": "structure",
        "required": [
          "DatasetImportJobName",
          "DatasetArn",
          "DataSource"
        ],
        "members": {
          "DatasetImportJobName": {},
          "DatasetArn": {},
          "DataSource": {
            "shape": "Sm"
          },
          "TimestampFormat": {},
          "TimeZone": {},
          "UseGeolocationForTimeZone": {
            "type": "boolean"
          },
          "GeolocationFormat": {},
          "Tags": {
            "shape": "Sd"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DatasetImportJobArn": {}
        }
      }
    },
    "CreateForecast": {
      "input": {
        "type": "structure",
        "required": [
          "ForecastName",
          "PredictorArn"
        ],
        "members": {
          "ForecastName": {},
          "PredictorArn": {},
          "ForecastTypes": {
            "shape": "Sv"
          },
          "Tags": {
            "shape": "Sd"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ForecastArn": {}
        }
      }
    },
    "CreateForecastExportJob": {
      "input": {
        "type": "structure",
        "required": [
          "ForecastExportJobName",
          "ForecastArn",
          "Destination"
        ],
        "members": {
          "ForecastExportJobName": {},
          "ForecastArn": {},
          "Destination": {
            "shape": "Sz"
          },
          "Tags": {
            "shape": "Sd"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ForecastExportJobArn": {}
        }
      }
    },
    "CreatePredictor": {
      "input": {
        "type": "structure",
        "required": [
          "PredictorName",
          "ForecastHorizon",
          "InputDataConfig",
          "FeaturizationConfig"
        ],
        "members": {
          "PredictorName": {},
          "AlgorithmArn": {},
          "ForecastHorizon": {
            "type": "integer"
          },
          "ForecastTypes": {
            "shape": "Sv"
          },
          "PerformAutoML": {
            "type": "boolean"
          },
          "AutoMLOverrideStrategy": {},
          "PerformHPO": {
            "type": "boolean"
          },
          "TrainingParameters": {
            "shape": "S15"
          },
          "EvaluationParameters": {
            "shape": "S18"
          },
          "HPOConfig": {
            "shape": "S19"
          },
          "InputDataConfig": {
            "shape": "S1l"
          },
          "FeaturizationConfig": {
            "shape": "S1o"
          },
          "EncryptionConfig": {
            "shape": "Sa"
          },
          "Tags": {
            "shape": "Sd"
          },
          "OptimizationMetric": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "PredictorArn": {}
        }
      }
    },
    "CreatePredictorBacktestExportJob": {
      "input": {
        "type": "structure",
        "required": [
          "PredictorBacktestExportJobName",
          "PredictorArn",
          "Destination"
        ],
        "members": {
          "PredictorBacktestExportJobName": {},
          "PredictorArn": {},
          "Destination": {
            "shape": "Sz"
          },
          "Tags": {
            "shape": "Sd"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "PredictorBacktestExportJobArn": {}
        }
      }
    },
    "DeleteDataset": {
      "input": {
        "type": "structure",
        "required": [
          "DatasetArn"
        ],
        "members": {
          "DatasetArn": {}
        }
      },
      "idempotent": true
    },
    "DeleteDatasetGroup": {
      "input": {
        "type": "structure",
        "required": [
          "DatasetGroupArn"
        ],
        "members": {
          "DatasetGroupArn": {}
        }
      },
      "idempotent": true
    },
    "DeleteDatasetImportJob": {
      "input": {
        "type": "structure",
        "required": [
          "DatasetImportJobArn"
        ],
        "members": {
          "DatasetImportJobArn": {}
        }
      },
      "idempotent": true
    },
    "DeleteForecast": {
      "input": {
        "type": "structure",
        "required": [
          "ForecastArn"
        ],
        "members": {
          "ForecastArn": {}
        }
      },
      "idempotent": true
    },
    "DeleteForecastExportJob": {
      "input": {
        "type": "structure",
        "required": [
          "ForecastExportJobArn"
        ],
        "members": {
          "ForecastExportJobArn": {}
        }
      },
      "idempotent": true
    },
    "DeletePredictor": {
      "input": {
        "type": "structure",
        "required": [
          "PredictorArn"
        ],
        "members": {
          "PredictorArn": {}
        }
      },
      "idempotent": true
    },
    "DeletePredictorBacktestExportJob": {
      "input": {
        "type": "structure",
        "required": [
          "PredictorBacktestExportJobArn"
        ],
        "members": {
          "PredictorBacktestExportJobArn": {}
        }
      },
      "idempotent": true
    },
    "DeleteResourceTree": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceArn"
        ],
        "members": {
          "ResourceArn": {}
        }
      },
      "idempotent": true
    },
    "DescribeDataset": {
      "input": {
        "type": "structure",
        "required": [
          "DatasetArn"
        ],
        "members": {
          "DatasetArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DatasetArn": {},
          "DatasetName": {},
          "Domain": {},
          "DatasetType": {},
          "DataFrequency": {},
          "Schema": {
            "shape": "S6"
          },
          "EncryptionConfig": {
            "shape": "Sa"
          },
          "Status": {},
          "CreationTime": {
            "type": "timestamp"
          },
          "LastModificationTime": {
            "type": "timestamp"
          }
        }
      },
      "idempotent": true
    },
    "DescribeDatasetGroup": {
      "input": {
        "type": "structure",
        "required": [
          "DatasetGroupArn"
        ],
        "members": {
          "DatasetGroupArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DatasetGroupName": {},
          "DatasetGroupArn": {},
          "DatasetArns": {
            "shape": "Sj"
          },
          "Domain": {},
          "Status": {},
          "CreationTime": {
            "type": "timestamp"
          },
          "LastModificationTime": {
            "type": "timestamp"
          }
        }
      },
      "idempotent": true
    },
    "DescribeDatasetImportJob": {
      "input": {
        "type": "structure",
        "required": [
          "DatasetImportJobArn"
        ],
        "members": {
          "DatasetImportJobArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DatasetImportJobName": {},
          "DatasetImportJobArn": {},
          "DatasetArn": {},
          "TimestampFormat": {},
          "TimeZone": {},
          "UseGeolocationForTimeZone": {
            "type": "boolean"
          },
          "GeolocationFormat": {},
          "DataSource": {
            "shape": "Sm"
          },
          "EstimatedTimeRemainingInMinutes": {
            "type": "long"
          },
          "FieldStatistics": {
            "type": "map",
            "key": {},
            "value": {
              "type": "structure",
              "members": {
                "Count": {
                  "type": "integer"
                },
                "CountDistinct": {
                  "type": "integer"
                },
                "CountNull": {
                  "type": "integer"
                },
                "CountNan": {
                  "type": "integer"
                },
                "Min": {},
                "Max": {},
                "Avg": {
                  "type": "double"
                },
                "Stddev": {
                  "type": "double"
                },
                "CountLong": {
                  "type": "long"
                },
                "CountDistinctLong": {
                  "type": "long"
                },
                "CountNullLong": {
                  "type": "long"
                },
                "CountNanLong": {
                  "type": "long"
                }
              }
            }
          },
          "DataSize": {
            "type": "double"
          },
          "Status": {},
          "Message": {},
          "CreationTime": {
            "type": "timestamp"
          },
          "LastModificationTime": {
            "type": "timestamp"
          }
        }
      },
      "idempotent": true
    },
    "DescribeForecast": {
      "input": {
        "type": "structure",
        "required": [
          "ForecastArn"
        ],
        "members": {
          "ForecastArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ForecastArn": {},
          "ForecastName": {},
          "ForecastTypes": {
            "shape": "Sv"
          },
          "PredictorArn": {},
          "DatasetGroupArn": {},
          "EstimatedTimeRemainingInMinutes": {
            "type": "long"
          },
          "Status": {},
          "Message": {},
          "CreationTime": {
            "type": "timestamp"
          },
          "LastModificationTime": {
            "type": "timestamp"
          }
        }
      },
      "idempotent": true
    },
    "DescribeForecastExportJob": {
      "input": {
        "type": "structure",
        "required": [
          "ForecastExportJobArn"
        ],
        "members": {
          "ForecastExportJobArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ForecastExportJobArn": {},
          "ForecastExportJobName": {},
          "ForecastArn": {},
          "Destination": {
            "shape": "Sz"
          },
          "Message": {},
          "Status": {},
          "CreationTime": {
            "type": "timestamp"
          },
          "LastModificationTime": {
            "type": "timestamp"
          }
        }
      },
      "idempotent": true
    },
    "DescribePredictor": {
      "input": {
        "type": "structure",
        "required": [
          "PredictorArn"
        ],
        "members": {
          "PredictorArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "PredictorArn": {},
          "PredictorName": {},
          "AlgorithmArn": {},
          "ForecastHorizon": {
            "type": "integer"
          },
          "ForecastTypes": {
            "shape": "Sv"
          },
          "PerformAutoML": {
            "type": "boolean"
          },
          "AutoMLOverrideStrategy": {},
          "PerformHPO": {
            "type": "boolean"
          },
          "TrainingParameters": {
            "shape": "S15"
          },
          "EvaluationParameters": {
            "shape": "S18"
          },
          "HPOConfig": {
            "shape": "S19"
          },
          "InputDataConfig": {
            "shape": "S1l"
          },
          "FeaturizationConfig": {
            "shape": "S1o"
          },
          "EncryptionConfig": {
            "shape": "Sa"
          },
          "PredictorExecutionDetails": {
            "type": "structure",
            "members": {
              "PredictorExecutions": {
                "type": "list",
                "member": {
                  "type": "structure",
                  "members": {
                    "AlgorithmArn": {},
                    "TestWindows": {
                      "type": "list",
                      "member": {
                        "type": "structure",
                        "members": {
                          "TestWindowStart": {
                            "type": "timestamp"
                          },
                          "TestWindowEnd": {
                            "type": "timestamp"
                          },
                          "Status": {},
                          "Message": {}
                        }
                      }
                    }
                  }
                }
              }
            }
          },
          "EstimatedTimeRemainingInMinutes": {
            "type": "long"
          },
          "DatasetImportJobArns": {
            "shape": "Sj"
          },
          "AutoMLAlgorithmArns": {
            "shape": "Sj"
          },
          "Status": {},
          "Message": {},
          "CreationTime": {
            "type": "timestamp"
          },
          "LastModificationTime": {
            "type": "timestamp"
          },
          "OptimizationMetric": {}
        }
      },
      "idempotent": true
    },
    "DescribePredictorBacktestExportJob": {
      "input": {
        "type": "structure",
        "required": [
          "PredictorBacktestExportJobArn"
        ],
        "members": {
          "PredictorBacktestExportJobArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "PredictorBacktestExportJobArn": {},
          "PredictorBacktestExportJobName": {},
          "PredictorArn": {},
          "Destination": {
            "shape": "Sz"
          },
          "Message": {},
          "Status": {},
          "CreationTime": {
            "type": "timestamp"
          },
          "LastModificationTime": {
            "type": "timestamp"
          }
        }
      },
      "idempotent": true
    },
    "GetAccuracyMetrics": {
      "input": {
        "type": "structure",
        "required": [
          "PredictorArn"
        ],
        "members": {
          "PredictorArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "PredictorEvaluationResults": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "AlgorithmArn": {},
                "TestWindows": {
                  "type": "list",
                  "member": {
                    "type": "structure",
                    "members": {
                      "TestWindowStart": {
                        "type": "timestamp"
                      },
                      "TestWindowEnd": {
                        "type": "timestamp"
                      },
                      "ItemCount": {
                        "type": "integer"
                      },
                      "EvaluationType": {},
                      "Metrics": {
                        "type": "structure",
                        "members": {
                          "RMSE": {
                            "deprecated": true,
                            "deprecatedMessage": "This property is deprecated, please refer to ErrorMetrics for both RMSE and WAPE",
                            "type": "double"
                          },
                          "WeightedQuantileLosses": {
                            "type": "list",
                            "member": {
                              "type": "structure",
                              "members": {
                                "Quantile": {
                                  "type": "double"
                                },
                                "LossValue": {
                                  "type": "double"
                                }
                              }
                            }
                          },
                          "ErrorMetrics": {
                            "type": "list",
                            "member": {
                              "type": "structure",
                              "members": {
                                "ForecastType": {},
                                "WAPE": {
                                  "type": "double"
                                },
                                "RMSE": {
                                  "type": "double"
                                },
                                "MASE": {
                                  "type": "double"
                                },
                                "MAPE": {
                                  "type": "double"
                                }
                              }
                            }
                          },
                          "AverageWeightedQuantileLoss": {
                            "type": "double"
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          },
          "AutoMLOverrideStrategy": {},
          "OptimizationMetric": {}
        }
      },
      "idempotent": true
    },
    "ListDatasetGroups": {
      "input": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DatasetGroups": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "DatasetGroupArn": {},
                "DatasetGroupName": {},
                "CreationTime": {
                  "type": "timestamp"
                },
                "LastModificationTime": {
                  "type": "timestamp"
                }
              }
            }
          },
          "NextToken": {}
        }
      },
      "idempotent": true
    },
    "ListDatasetImportJobs": {
      "input": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          },
          "Filters": {
            "shape": "S3i"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DatasetImportJobs": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "DatasetImportJobArn": {},
                "DatasetImportJobName": {},
                "DataSource": {
                  "shape": "Sm"
                },
                "Status": {},
                "Message": {},
                "CreationTime": {
                  "type": "timestamp"
                },
                "LastModificationTime": {
                  "type": "timestamp"
                }
              }
            }
          },
          "NextToken": {}
        }
      },
      "idempotent": true
    },
    "ListDatasets": {
      "input": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Datasets": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "DatasetArn": {},
                "DatasetName": {},
                "DatasetType": {},
                "Domain": {},
                "CreationTime": {
                  "type": "timestamp"
                },
                "LastModificationTime": {
                  "type": "timestamp"
                }
              }
            }
          },
          "NextToken": {}
        }
      },
      "idempotent": true
    },
    "ListForecastExportJobs": {
      "input": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          },
          "Filters": {
            "shape": "S3i"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ForecastExportJobs": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "ForecastExportJobArn": {},
                "ForecastExportJobName": {},
                "Destination": {
                  "shape": "Sz"
                },
                "Status": {},
                "Message": {},
                "CreationTime": {
                  "type": "timestamp"
                },
                "LastModificationTime": {
                  "type": "timestamp"
                }
              }
            }
          },
          "NextToken": {}
        }
      },
      "idempotent": true
    },
    "ListForecasts": {
      "input": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          },
          "Filters": {
            "shape": "S3i"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Forecasts": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "ForecastArn": {},
                "ForecastName": {},
                "PredictorArn": {},
                "DatasetGroupArn": {},
                "Status": {},
                "Message": {},
                "CreationTime": {
                  "type": "timestamp"
                },
                "LastModificationTime": {
                  "type": "timestamp"
                }
              }
            }
          },
          "NextToken": {}
        }
      },
      "idempotent": true
    },
    "ListPredictorBacktestExportJobs": {
      "input": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          },
          "Filters": {
            "shape": "S3i"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "PredictorBacktestExportJobs": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "PredictorBacktestExportJobArn": {},
                "PredictorBacktestExportJobName": {},
                "Destination": {
                  "shape": "Sz"
                },
                "Status": {},
                "Message": {},
                "CreationTime": {
                  "type": "timestamp"
                },
                "LastModificationTime": {
                  "type": "timestamp"
                }
              }
            }
          },
          "NextToken": {}
        }
      },
      "idempotent": true
    },
    "ListPredictors": {
      "input": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          },
          "Filters": {
            "shape": "S3i"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Predictors": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "PredictorArn": {},
                "PredictorName": {},
                "DatasetGroupArn": {},
                "Status": {},
                "Message": {},
                "CreationTime": {
                  "type": "timestamp"
                },
                "LastModificationTime": {
                  "type": "timestamp"
                }
              }
            }
          },
          "NextToken": {}
        }
      },
      "idempotent": true
    },
    "ListTagsForResource": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceArn"
        ],
        "members": {
          "ResourceArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Tags": {
            "shape": "Sd"
          }
        }
      }
    },
    "StopResource": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceArn"
        ],
        "members": {
          "ResourceArn": {}
        }
      },
      "idempotent": true
    },
    "TagResource": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceArn",
          "Tags"
        ],
        "members": {
          "ResourceArn": {},
          "Tags": {
            "shape": "Sd"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UntagResource": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceArn",
          "TagKeys"
        ],
        "members": {
          "ResourceArn": {},
          "TagKeys": {
            "type": "list",
            "member": {
              "shape": "Sf"
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UpdateDatasetGroup": {
      "input": {
        "type": "structure",
        "required": [
          "DatasetGroupArn",
          "DatasetArns"
        ],
        "members": {
          "DatasetGroupArn": {},
          "DatasetArns": {
            "shape": "Sj"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    }
  },
  "shapes": {
    "S6": {
      "type": "structure",
      "members": {
        "Attributes": {
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "AttributeName": {},
              "AttributeType": {}
            }
          }
        }
      }
    },
    "Sa": {
      "type": "structure",
      "required": [
        "RoleArn",
        "KMSKeyArn"
      ],
      "members": {
        "RoleArn": {},
        "KMSKeyArn": {}
      }
    },
    "Sd": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "Key",
          "Value"
        ],
        "members": {
          "Key": {
            "shape": "Sf"
          },
          "Value": {
            "type": "string",
            "sensitive": true
          }
        }
      }
    },
    "Sf": {
      "type": "string",
      "sensitive": true
    },
    "Sj": {
      "type": "list",
      "member": {}
    },
    "Sm": {
      "type": "structure",
      "required": [
        "S3Config"
      ],
      "members": {
        "S3Config": {
          "shape": "Sn"
        }
      }
    },
    "Sn": {
      "type": "structure",
      "required": [
        "Path",
        "RoleArn"
      ],
      "members": {
        "Path": {},
        "RoleArn": {},
        "KMSKeyArn": {}
      }
    },
    "Sv": {
      "type": "list",
      "member": {}
    },
    "Sz": {
      "type": "structure",
      "required": [
        "S3Config"
      ],
      "members": {
        "S3Config": {
          "shape": "Sn"
        }
      }
    },
    "S15": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "S18": {
      "type": "structure",
      "members": {
        "NumberOfBacktestWindows": {
          "type": "integer"
        },
        "BackTestWindowOffset": {
          "type": "integer"
        }
      }
    },
    "S19": {
      "type": "structure",
      "members": {
        "ParameterRanges": {
          "type": "structure",
          "members": {
            "CategoricalParameterRanges": {
              "type": "list",
              "member": {
                "type": "structure",
                "required": [
                  "Name",
                  "Values"
                ],
                "members": {
                  "Name": {},
                  "Values": {
                    "type": "list",
                    "member": {}
                  }
                }
              }
            },
            "ContinuousParameterRanges": {
              "type": "list",
              "member": {
                "type": "structure",
                "required": [
                  "Name",
                  "MaxValue",
                  "MinValue"
                ],
                "members": {
                  "Name": {},
                  "MaxValue": {
                    "type": "double"
                  },
                  "MinValue": {
                    "type": "double"
                  },
                  "ScalingType": {}
                }
              }
            },
            "IntegerParameterRanges": {
              "type": "list",
              "member": {
                "type": "structure",
                "required": [
                  "Name",
                  "MaxValue",
                  "MinValue"
                ],
                "members": {
                  "Name": {},
                  "MaxValue": {
                    "type": "integer"
                  },
                  "MinValue": {
                    "type": "integer"
                  },
                  "ScalingType": {}
                }
              }
            }
          }
        }
      }
    },
    "S1l": {
      "type": "structure",
      "required": [
        "DatasetGroupArn"
      ],
      "members": {
        "DatasetGroupArn": {},
        "SupplementaryFeatures": {
          "type": "list",
          "member": {
            "type": "structure",
            "required": [
              "Name",
              "Value"
            ],
            "members": {
              "Name": {},
              "Value": {}
            }
          }
        }
      }
    },
    "S1o": {
      "type": "structure",
      "required": [
        "ForecastFrequency"
      ],
      "members": {
        "ForecastFrequency": {},
        "ForecastDimensions": {
          "type": "list",
          "member": {}
        },
        "Featurizations": {
          "type": "list",
          "member": {
            "type": "structure",
            "required": [
              "AttributeName"
            ],
            "members": {
              "AttributeName": {},
              "FeaturizationPipeline": {
                "type": "list",
                "member": {
                  "type": "structure",
                  "required": [
                    "FeaturizationMethodName"
                  ],
                  "members": {
                    "FeaturizationMethodName": {},
                    "FeaturizationMethodParameters": {
                      "type": "map",
                      "key": {},
                      "value": {}
                    }
                  }
                }
              }
            }
          }
        }
      }
    },
    "S3i": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "Key",
          "Value",
          "Condition"
        ],
        "members": {
          "Key": {},
          "Value": {},
          "Condition": {}
        }
      }
    }
  }
}