{
  "version": "2.0",
  "metadata": {
    "apiVersion": "2015-03-31",
    "endpointPrefix": "lambda",
    "protocol": "rest-json",
    "serviceFullName": "AWS Lambda",
    "serviceId": "Lambda",
    "signatureVersion": "v4",
    "uid": "lambda-2015-03-31"
  },
  "operations": {
    "AddLayerVersionPermission": {
      "http": {
        "requestUri": "/2018-10-31/layers/{LayerName}/versions/{VersionNumber}/policy",
        "responseCode": 201
      },
      "input": {
        "type": "structure",
        "required": [
          "LayerName",
          "VersionNumber",
          "StatementId",
          "Action",
          "Principal"
        ],
        "members": {
          "LayerName": {
            "location": "uri",
            "locationName": "LayerName"
          },
          "VersionNumber": {
            "location": "uri",
            "locationName": "VersionNumber",
            "type": "long"
          },
          "StatementId": {},
          "Action": {},
          "Principal": {},
          "OrganizationId": {},
          "RevisionId": {
            "location": "querystring",
            "locationName": "RevisionId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Statement": {},
          "RevisionId": {}
        }
      }
    },
    "AddPermission": {
      "http": {
        "requestUri": "/2015-03-31/functions/{FunctionName}/policy",
        "responseCode": 201
      },
      "input": {
        "type": "structure",
        "required": [
          "FunctionName",
          "StatementId",
          "Action",
          "Principal"
        ],
        "members": {
          "FunctionName": {
            "location": "uri",
            "locationName": "FunctionName"
          },
          "StatementId": {},
          "Action": {},
          "Principal": {},
          "SourceArn": {},
          "SourceAccount": {},
          "EventSourceToken": {},
          "Qualifier": {
            "location": "querystring",
            "locationName": "Qualifier"
          },
          "RevisionId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Statement": {}
        }
      }
    },
    "CreateAlias": {
      "http": {
        "requestUri": "/2015-03-31/functions/{FunctionName}/aliases",
        "responseCode": 201
      },
      "input": {
        "type": "structure",
        "required": [
          "FunctionName",
          "Name",
          "FunctionVersion"
        ],
        "members": {
          "FunctionName": {
            "location": "uri",
            "locationName": "FunctionName"
          },
          "Name": {},
          "FunctionVersion": {},
          "Description": {},
          "RoutingConfig": {
            "shape": "Sn"
          }
        }
      },
      "output": {
        "shape": "Sr"
      }
    },
    "CreateCodeSigningConfig": {
      "http": {
        "requestUri": "/2020-04-22/code-signing-configs/",
        "responseCode": 201
      },
      "input": {
        "type": "structure",
        "required": [
          "AllowedPublishers"
        ],
        "members": {
          "Description": {},
          "AllowedPublishers": {
            "shape": "Su"
          },
          "CodeSigningPolicies": {
            "shape": "Sw"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "CodeSigningConfig"
        ],
        "members": {
          "CodeSigningConfig": {
            "shape": "Sz"
          }
        }
      }
    },
    "CreateEventSourceMapping": {
      "http": {
        "requestUri": "/2015-03-31/event-source-mappings/",
        "responseCode": 202
      },
      "input": {
        "type": "structure",
        "required": [
          "FunctionName"
        ],
        "members": {
          "EventSourceArn": {},
          "FunctionName": {},
          "Enabled": {
            "type": "boolean"
          },
          "BatchSize": {
            "type": "integer"
          },
          "MaximumBatchingWindowInSeconds": {
            "type": "integer"
          },
          "ParallelizationFactor": {
            "type": "integer"
          },
          "StartingPosition": {},
          "StartingPositionTimestamp": {
            "type": "timestamp"
          },
          "DestinationConfig": {
            "shape": "S1a"
          },
          "MaximumRecordAgeInSeconds": {
            "type": "integer"
          },
          "BisectBatchOnFunctionError": {
            "type": "boolean"
          },
          "MaximumRetryAttempts": {
            "type": "integer"
          },
          "TumblingWindowInSeconds": {
            "type": "integer"
          },
          "Topics": {
            "shape": "S1i"
          },
          "Queues": {
            "shape": "S1k"
          },
          "SourceAccessConfigurations": {
            "shape": "S1m"
          },
          "SelfManagedEventSource": {
            "shape": "S1q"
          },
          "FunctionResponseTypes": {
            "shape": "S1v"
          }
        }
      },
      "output": {
        "shape": "S1x"
      }
    },
    "CreateFunction": {
      "http": {
        "requestUri": "/2015-03-31/functions",
        "responseCode": 201
      },
      "input": {
        "type": "structure",
        "required": [
          "FunctionName",
          "Role",
          "Code"
        ],
        "members": {
          "FunctionName": {},
          "Runtime": {},
          "Role": {},
          "Handler": {},
          "Code": {
            "type": "structure",
            "members": {
              "ZipFile": {
                "shape": "S23"
              },
              "S3Bucket": {},
              "S3Key": {},
              "S3ObjectVersion": {},
              "ImageUri": {}
            }
          },
          "Description": {},
          "Timeout": {
            "type": "integer"
          },
          "MemorySize": {
            "type": "integer"
          },
          "Publish": {
            "type": "boolean"
          },
          "VpcConfig": {
            "shape": "S2a"
          },
          "PackageType": {},
          "DeadLetterConfig": {
            "shape": "S2g"
          },
          "Environment": {
            "shape": "S2i"
          },
          "KMSKeyArn": {},
          "TracingConfig": {
            "shape": "S2n"
          },
          "Tags": {
            "shape": "S2p"
          },
          "Layers": {
            "shape": "S2s"
          },
          "FileSystemConfigs": {
            "shape": "S2u"
          },
          "ImageConfig": {
            "shape": "S2y"
          },
          "CodeSigningConfigArn": {},
          "Architectures": {
            "shape": "S31"
          }
        }
      },
      "output": {
        "shape": "S33"
      }
    },
    "DeleteAlias": {
      "http": {
        "method": "DELETE",
        "requestUri": "/2015-03-31/functions/{FunctionName}/aliases/{Name}",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "FunctionName",
          "Name"
        ],
        "members": {
          "FunctionName": {
            "location": "uri",
            "locationName": "FunctionName"
          },
          "Name": {
            "location": "uri",
            "locationName": "Name"
          }
        }
      }
    },
    "DeleteCodeSigningConfig": {
      "http": {
        "method": "DELETE",
        "requestUri": "/2020-04-22/code-signing-configs/{CodeSigningConfigArn}",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "CodeSigningConfigArn"
        ],
        "members": {
          "CodeSigningConfigArn": {
            "location": "uri",
            "locationName": "CodeSigningConfigArn"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteEventSourceMapping": {
      "http": {
        "method": "DELETE",
        "requestUri": "/2015-03-31/event-source-mappings/{UUID}",
        "responseCode": 202
      },
      "input": {
        "type": "structure",
        "required": [
          "UUID"
        ],
        "members": {
          "UUID": {
            "location": "uri",
            "locationName": "UUID"
          }
        }
      },
      "output": {
        "shape": "S1x"
      }
    },
    "DeleteFunction": {
      "http": {
        "method": "DELETE",
        "requestUri": "/2015-03-31/functions/{FunctionName}",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "FunctionName"
        ],
        "members": {
          "FunctionName": {
            "location": "uri",
            "locationName": "FunctionName"
          },
          "Qualifier": {
            "location": "querystring",
            "locationName": "Qualifier"
          }
        }
      }
    },
    "DeleteFunctionCodeSigningConfig": {
      "http": {
        "method": "DELETE",
        "requestUri": "/2020-06-30/functions/{FunctionName}/code-signing-config",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "FunctionName"
        ],
        "members": {
          "FunctionName": {
            "location": "uri",
            "locationName": "FunctionName"
          }
        }
      }
    },
    "DeleteFunctionConcurrency": {
      "http": {
        "method": "DELETE",
        "requestUri": "/2017-10-31/functions/{FunctionName}/concurrency",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "FunctionName"
        ],
        "members": {
          "FunctionName": {
            "location": "uri",
            "locationName": "FunctionName"
          }
        }
      }
    },
    "DeleteFunctionEventInvokeConfig": {
      "http": {
        "method": "DELETE",
        "requestUri": "/2019-09-25/functions/{FunctionName}/event-invoke-config",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "FunctionName"
        ],
        "members": {
          "FunctionName": {
            "location": "uri",
            "locationName": "FunctionName"
          },
          "Qualifier": {
            "location": "querystring",
            "locationName": "Qualifier"
          }
        }
      }
    },
    "DeleteLayerVersion": {
      "http": {
        "method": "DELETE",
        "requestUri": "/2018-10-31/layers/{LayerName}/versions/{VersionNumber}",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "LayerName",
          "VersionNumber"
        ],
        "members": {
          "LayerName": {
            "location": "uri",
            "locationName": "LayerName"
          },
          "VersionNumber": {
            "location": "uri",
            "locationName": "VersionNumber",
            "type": "long"
          }
        }
      }
    },
    "DeleteProvisionedConcurrencyConfig": {
      "http": {
        "method": "DELETE",
        "requestUri": "/2019-09-30/functions/{FunctionName}/provisioned-concurrency",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "FunctionName",
          "Qualifier"
        ],
        "members": {
          "FunctionName": {
            "location": "uri",
            "locationName": "FunctionName"
          },
          "Qualifier": {
            "location": "querystring",
            "locationName": "Qualifier"
          }
        }
      }
    },
    "GetAccountSettings": {
      "http": {
        "method": "GET",
        "requestUri": "/2016-08-19/account-settings/",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {}
      },
      "output": {
        "type": "structure",
        "members": {
          "AccountLimit": {
            "type": "structure",
            "members": {
              "TotalCodeSize": {
                "type": "long"
              },
              "CodeSizeUnzipped": {
                "type": "long"
              },
              "CodeSizeZipped": {
                "type": "long"
              },
              "ConcurrentExecutions": {
                "type": "integer"
              },
              "UnreservedConcurrentExecutions": {
                "type": "integer"
              }
            }
          },
          "AccountUsage": {
            "type": "structure",
            "members": {
              "TotalCodeSize": {
                "type": "long"
              },
              "FunctionCount": {
                "type": "long"
              }
            }
          }
        }
      }
    },
    "GetAlias": {
      "http": {
        "method": "GET",
        "requestUri": "/2015-03-31/functions/{FunctionName}/aliases/{Name}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "FunctionName",
          "Name"
        ],
        "members": {
          "FunctionName": {
            "location": "uri",
            "locationName": "FunctionName"
          },
          "Name": {
            "location": "uri",
            "locationName": "Name"
          }
        }
      },
      "output": {
        "shape": "Sr"
      }
    },
    "GetCodeSigningConfig": {
      "http": {
        "method": "GET",
        "requestUri": "/2020-04-22/code-signing-configs/{CodeSigningConfigArn}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "CodeSigningConfigArn"
        ],
        "members": {
          "CodeSigningConfigArn": {
            "location": "uri",
            "locationName": "CodeSigningConfigArn"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "CodeSigningConfig"
        ],
        "members": {
          "CodeSigningConfig": {
            "shape": "Sz"
          }
        }
      }
    },
    "GetEventSourceMapping": {
      "http": {
        "method": "GET",
        "requestUri": "/2015-03-31/event-source-mappings/{UUID}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "UUID"
        ],
        "members": {
          "UUID": {
            "location": "uri",
            "locationName": "UUID"
          }
        }
      },
      "output": {
        "shape": "S1x"
      }
    },
    "GetFunction": {
      "http": {
        "method": "GET",
        "requestUri": "/2015-03-31/functions/{FunctionName}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "FunctionName"
        ],
        "members": {
          "FunctionName": {
            "location": "uri",
            "locationName": "FunctionName"
          },
          "Qualifier": {
            "location": "querystring",
            "locationName": "Qualifier"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Configuration": {
            "shape": "S33"
          },
          "Code": {
            "type": "structure",
            "members": {
              "RepositoryType": {},
              "Location": {},
              "ImageUri": {},
              "ResolvedImageUri": {}
            }
          },
          "Tags": {
            "shape": "S2p"
          },
          "Concurrency": {
            "shape": "S4a"
          }
        }
      }
    },
    "GetFunctionCodeSigningConfig": {
      "http": {
        "method": "GET",
        "requestUri": "/2020-06-30/functions/{FunctionName}/code-signing-config",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "FunctionName"
        ],
        "members": {
          "FunctionName": {
            "location": "uri",
            "locationName": "FunctionName"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "CodeSigningConfigArn",
          "FunctionName"
        ],
        "members": {
          "CodeSigningConfigArn": {},
          "FunctionName": {}
        }
      }
    },
    "GetFunctionConcurrency": {
      "http": {
        "method": "GET",
        "requestUri": "/2019-09-30/functions/{FunctionName}/concurrency",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "FunctionName"
        ],
        "members": {
          "FunctionName": {
            "location": "uri",
            "locationName": "FunctionName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ReservedConcurrentExecutions": {
            "type": "integer"
          }
        }
      }
    },
    "GetFunctionConfiguration": {
      "http": {
        "method": "GET",
        "requestUri": "/2015-03-31/functions/{FunctionName}/configuration",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "FunctionName"
        ],
        "members": {
          "FunctionName": {
            "location": "uri",
            "locationName": "FunctionName"
          },
          "Qualifier": {
            "location": "querystring",
            "locationName": "Qualifier"
          }
        }
      },
      "output": {
        "shape": "S33"
      }
    },
    "GetFunctionEventInvokeConfig": {
      "http": {
        "method": "GET",
        "requestUri": "/2019-09-25/functions/{FunctionName}/event-invoke-config",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "FunctionName"
        ],
        "members": {
          "FunctionName": {
            "location": "uri",
            "locationName": "FunctionName"
          },
          "Qualifier": {
            "location": "querystring",
            "locationName": "Qualifier"
          }
        }
      },
      "output": {
        "shape": "S4i"
      }
    },
    "GetLayerVersion": {
      "http": {
        "method": "GET",
        "requestUri": "/2018-10-31/layers/{LayerName}/versions/{VersionNumber}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "LayerName",
          "VersionNumber"
        ],
        "members": {
          "LayerName": {
            "location": "uri",
            "locationName": "LayerName"
          },
          "VersionNumber": {
            "location": "uri",
            "locationName": "VersionNumber",
            "type": "long"
          }
        }
      },
      "output": {
        "shape": "S4m"
      }
    },
    "GetLayerVersionByArn": {
      "http": {
        "method": "GET",
        "requestUri": "/2018-10-31/layers?find=LayerVersion",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "Arn"
        ],
        "members": {
          "Arn": {
            "location": "querystring",
            "locationName": "Arn"
          }
        }
      },
      "output": {
        "shape": "S4m"
      }
    },
    "GetLayerVersionPolicy": {
      "http": {
        "method": "GET",
        "requestUri": "/2018-10-31/layers/{LayerName}/versions/{VersionNumber}/policy",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "LayerName",
          "VersionNumber"
        ],
        "members": {
          "LayerName": {
            "location": "uri",
            "locationName": "LayerName"
          },
          "VersionNumber": {
            "location": "uri",
            "locationName": "VersionNumber",
            "type": "long"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Policy": {},
          "RevisionId": {}
        }
      }
    },
    "GetPolicy": {
      "http": {
        "method": "GET",
        "requestUri": "/2015-03-31/functions/{FunctionName}/policy",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "FunctionName"
        ],
        "members": {
          "FunctionName": {
            "location": "uri",
            "locationName": "FunctionName"
          },
          "Qualifier": {
            "location": "querystring",
            "locationName": "Qualifier"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Policy": {},
          "RevisionId": {}
        }
      }
    },
    "GetProvisionedConcurrencyConfig": {
      "http": {
        "method": "GET",
        "requestUri": "/2019-09-30/functions/{FunctionName}/provisioned-concurrency",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "FunctionName",
          "Qualifier"
        ],
        "members": {
          "FunctionName": {
            "location": "uri",
            "locationName": "FunctionName"
          },
          "Qualifier": {
            "location": "querystring",
            "locationName": "Qualifier"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "RequestedProvisionedConcurrentExecutions": {
            "type": "integer"
          },
          "AvailableProvisionedConcurrentExecutions": {
            "type": "integer"
          },
          "AllocatedProvisionedConcurrentExecutions": {
            "type": "integer"
          },
          "Status": {},
          "StatusReason": {},
          "LastModified": {}
        }
      }
    },
    "Invoke": {
      "http": {
        "requestUri": "/2015-03-31/functions/{FunctionName}/invocations"
      },
      "input": {
        "type": "structure",
        "required": [
          "FunctionName"
        ],
        "members": {
          "FunctionName": {
            "location": "uri",
            "locationName": "FunctionName"
          },
          "InvocationType": {
            "location": "header",
            "locationName": "X-Amz-Invocation-Type"
          },
          "LogType": {
            "location": "header",
            "locationName": "X-Amz-Log-Type"
          },
          "ClientContext": {
            "location": "header",
            "locationName": "X-Amz-Client-Context"
          },
          "Payload": {
            "shape": "S23"
          },
          "Qualifier": {
            "location": "querystring",
            "locationName": "Qualifier"
          }
        },
        "payload": "Payload"
      },
      "output": {
        "type": "structure",
        "members": {
          "StatusCode": {
            "location": "statusCode",
            "type": "integer"
          },
          "FunctionError": {
            "location": "header",
            "locationName": "X-Amz-Function-Error"
          },
          "LogResult": {
            "location": "header",
            "locationName": "X-Amz-Log-Result"
          },
          "Payload": {
            "shape": "S23"
          },
          "ExecutedVersion": {
            "location": "header",
            "locationName": "X-Amz-Executed-Version"
          }
        },
        "payload": "Payload"
      }
    },
    "InvokeAsync": {
      "http": {
        "requestUri": "/2014-11-13/functions/{FunctionName}/invoke-async/",
        "responseCode": 202
      },
      "input": {
        "type": "structure",
        "required": [
          "FunctionName",
          "InvokeArgs"
        ],
        "members": {
          "FunctionName": {
            "location": "uri",
            "locationName": "FunctionName"
          },
          "InvokeArgs": {
            "type": "blob",
            "streaming": true
          }
        },
        "deprecated": true,
        "payload": "InvokeArgs"
      },
      "output": {
        "type": "structure",
        "members": {
          "Status": {
            "location": "statusCode",
            "type": "integer"
          }
        },
        "deprecated": true
      },
      "deprecated": true
    },
    "ListAliases": {
      "http": {
        "method": "GET",
        "requestUri": "/2015-03-31/functions/{FunctionName}/aliases",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "FunctionName"
        ],
        "members": {
          "FunctionName": {
            "location": "uri",
            "locationName": "FunctionName"
          },
          "FunctionVersion": {
            "location": "querystring",
            "locationName": "FunctionVersion"
          },
          "Marker": {
            "location": "querystring",
            "locationName": "Marker"
          },
          "MaxItems": {
            "location": "querystring",
            "locationName": "MaxItems",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NextMarker": {},
          "Aliases": {
            "type": "list",
            "member": {
              "shape": "Sr"
            }
          }
        }
      }
    },
    "ListCodeSigningConfigs": {
      "http": {
        "method": "GET",
        "requestUri": "/2020-04-22/code-signing-configs/",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "Marker": {
            "location": "querystring",
            "locationName": "Marker"
          },
          "MaxItems": {
            "location": "querystring",
            "locationName": "MaxItems",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NextMarker": {},
          "CodeSigningConfigs": {
            "type": "list",
            "member": {
              "shape": "Sz"
            }
          }
        }
      }
    },
    "ListEventSourceMappings": {
      "http": {
        "method": "GET",
        "requestUri": "/2015-03-31/event-source-mappings/",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "EventSourceArn": {
            "location": "querystring",
            "locationName": "EventSourceArn"
          },
          "FunctionName": {
            "location": "querystring",
            "locationName": "FunctionName"
          },
          "Marker": {
            "location": "querystring",
            "locationName": "Marker"
          },
          "MaxItems": {
            "location": "querystring",
            "locationName": "MaxItems",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NextMarker": {},
          "EventSourceMappings": {
            "type": "list",
            "member": {
              "shape": "S1x"
            }
          }
        }
      }
    },
    "ListFunctionEventInvokeConfigs": {
      "http": {
        "method": "GET",
        "requestUri": "/2019-09-25/functions/{FunctionName}/event-invoke-config/list",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "FunctionName"
        ],
        "members": {
          "FunctionName": {
            "location": "uri",
            "locationName": "FunctionName"
          },
          "Marker": {
            "location": "querystring",
            "locationName": "Marker"
          },
          "MaxItems": {
            "location": "querystring",
            "locationName": "MaxItems",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "FunctionEventInvokeConfigs": {
            "type": "list",
            "member": {
              "shape": "S4i"
            }
          },
          "NextMarker": {}
        }
      }
    },
    "ListFunctions": {
      "http": {
        "method": "GET",
        "requestUri": "/2015-03-31/functions/",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "MasterRegion": {
            "location": "querystring",
            "locationName": "MasterRegion"
          },
          "FunctionVersion": {
            "location": "querystring",
            "locationName": "FunctionVersion"
          },
          "Marker": {
            "location": "querystring",
            "locationName": "Marker"
          },
          "MaxItems": {
            "location": "querystring",
            "locationName": "MaxItems",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NextMarker": {},
          "Functions": {
            "shape": "S5s"
          }
        }
      }
    },
    "ListFunctionsByCodeSigningConfig": {
      "http": {
        "method": "GET",
        "requestUri": "/2020-04-22/code-signing-configs/{CodeSigningConfigArn}/functions",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "CodeSigningConfigArn"
        ],
        "members": {
          "CodeSigningConfigArn": {
            "location": "uri",
            "locationName": "CodeSigningConfigArn"
          },
          "Marker": {
            "location": "querystring",
            "locationName": "Marker"
          },
          "MaxItems": {
            "location": "querystring",
            "locationName": "MaxItems",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NextMarker": {},
          "FunctionArns": {
            "type": "list",
            "member": {}
          }
        }
      }
    },
    "ListLayerVersions": {
      "http": {
        "method": "GET",
        "requestUri": "/2018-10-31/layers/{LayerName}/versions",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "LayerName"
        ],
        "members": {
          "CompatibleRuntime": {
            "location": "querystring",
            "locationName": "CompatibleRuntime"
          },
          "LayerName": {
            "location": "uri",
            "locationName": "LayerName"
          },
          "Marker": {
            "location": "querystring",
            "locationName": "Marker"
          },
          "MaxItems": {
            "location": "querystring",
            "locationName": "MaxItems",
            "type": "integer"
          },
          "CompatibleArchitecture": {
            "location": "querystring",
            "locationName": "CompatibleArchitecture"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NextMarker": {},
          "LayerVersions": {
            "type": "list",
            "member": {
              "shape": "S60"
            }
          }
        }
      }
    },
    "ListLayers": {
      "http": {
        "method": "GET",
        "requestUri": "/2018-10-31/layers",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "CompatibleRuntime": {
            "location": "querystring",
            "locationName": "CompatibleRuntime"
          },
          "Marker": {
            "location": "querystring",
            "locationName": "Marker"
          },
          "MaxItems": {
            "location": "querystring",
            "locationName": "MaxItems",
            "type": "integer"
          },
          "CompatibleArchitecture": {
            "location": "querystring",
            "locationName": "CompatibleArchitecture"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NextMarker": {},
          "Layers": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "LayerName": {},
                "LayerArn": {},
                "LatestMatchingVersion": {
                  "shape": "S60"
                }
              }
            }
          }
        }
      }
    },
    "ListProvisionedConcurrencyConfigs": {
      "http": {
        "method": "GET",
        "requestUri": "/2019-09-30/functions/{FunctionName}/provisioned-concurrency?List=ALL",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "FunctionName"
        ],
        "members": {
          "FunctionName": {
            "location": "uri",
            "locationName": "FunctionName"
          },
          "Marker": {
            "location": "querystring",
            "locationName": "Marker"
          },
          "MaxItems": {
            "location": "querystring",
            "locationName": "MaxItems",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ProvisionedConcurrencyConfigs": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "FunctionArn": {},
                "RequestedProvisionedConcurrentExecutions": {
                  "type": "integer"
                },
                "AvailableProvisionedConcurrentExecutions": {
                  "type": "integer"
                },
                "AllocatedProvisionedConcurrentExecutions": {
                  "type": "integer"
                },
                "Status": {},
                "StatusReason": {},
                "LastModified": {}
              }
            }
          },
          "NextMarker": {}
        }
      }
    },
    "ListTags": {
      "http": {
        "method": "GET",
        "requestUri": "/2017-03-31/tags/{ARN}"
      },
      "input": {
        "type": "structure",
        "required": [
          "Resource"
        ],
        "members": {
          "Resource": {
            "location": "uri",
            "locationName": "ARN"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Tags": {
            "shape": "S2p"
          }
        }
      }
    },
    "ListVersionsByFunction": {
      "http": {
        "method": "GET",
        "requestUri": "/2015-03-31/functions/{FunctionName}/versions",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "FunctionName"
        ],
        "members": {
          "FunctionName": {
            "location": "uri",
            "locationName": "FunctionName"
          },
          "Marker": {
            "location": "querystring",
            "locationName": "Marker"
          },
          "MaxItems": {
            "location": "querystring",
            "locationName": "MaxItems",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NextMarker": {},
          "Versions": {
            "shape": "S5s"
          }
        }
      }
    },
    "PublishLayerVersion": {
      "http": {
        "requestUri": "/2018-10-31/layers/{LayerName}/versions",
        "responseCode": 201
      },
      "input": {
        "type": "structure",
        "required": [
          "LayerName",
          "Content"
        ],
        "members": {
          "LayerName": {
            "location": "uri",
            "locationName": "LayerName"
          },
          "Description": {},
          "Content": {
            "type": "structure",
            "members": {
              "S3Bucket": {},
              "S3Key": {},
              "S3ObjectVersion": {},
              "ZipFile": {
                "shape": "S23"
              }
            }
          },
          "CompatibleRuntimes": {
            "shape": "S4p"
          },
          "LicenseInfo": {},
          "CompatibleArchitectures": {
            "shape": "S4r"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Content": {
            "shape": "S4n"
          },
          "LayerArn": {},
          "LayerVersionArn": {},
          "Description": {},
          "CreatedDate": {},
          "Version": {
            "type": "long"
          },
          "CompatibleRuntimes": {
            "shape": "S4p"
          },
          "LicenseInfo": {},
          "CompatibleArchitectures": {
            "shape": "S4r"
          }
        }
      }
    },
    "PublishVersion": {
      "http": {
        "requestUri": "/2015-03-31/functions/{FunctionName}/versions",
        "responseCode": 201
      },
      "input": {
        "type": "structure",
        "required": [
          "FunctionName"
        ],
        "members": {
          "FunctionName": {
            "location": "uri",
            "locationName": "FunctionName"
          },
          "CodeSha256": {},
          "Description": {},
          "RevisionId": {}
        }
      },
      "output": {
        "shape": "S33"
      }
    },
    "PutFunctionCodeSigningConfig": {
      "http": {
        "method": "PUT",
        "requestUri": "/2020-06-30/functions/{FunctionName}/code-signing-config",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "CodeSigningConfigArn",
          "FunctionName"
        ],
        "members": {
          "CodeSigningConfigArn": {},
          "FunctionName": {
            "location": "uri",
            "locationName": "FunctionName"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "CodeSigningConfigArn",
          "FunctionName"
        ],
        "members": {
          "CodeSigningConfigArn": {},
          "FunctionName": {}
        }
      }
    },
    "PutFunctionConcurrency": {
      "http": {
        "method": "PUT",
        "requestUri": "/2017-10-31/functions/{FunctionName}/concurrency",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "FunctionName",
          "ReservedConcurrentExecutions"
        ],
        "members": {
          "FunctionName": {
            "location": "uri",
            "locationName": "FunctionName"
          },
          "ReservedConcurrentExecutions": {
            "type": "integer"
          }
        }
      },
      "output": {
        "shape": "S4a"
      }
    },
    "PutFunctionEventInvokeConfig": {
      "http": {
        "method": "PUT",
        "requestUri": "/2019-09-25/functions/{FunctionName}/event-invoke-config",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "FunctionName"
        ],
        "members": {
          "FunctionName": {
            "location": "uri",
            "locationName": "FunctionName"
          },
          "Qualifier": {
            "location": "querystring",
            "locationName": "Qualifier"
          },
          "MaximumRetryAttempts": {
            "type": "integer"
          },
          "MaximumEventAgeInSeconds": {
            "type": "integer"
          },
          "DestinationConfig": {
            "shape": "S1a"
          }
        }
      },
      "output": {
        "shape": "S4i"
      }
    },
    "PutProvisionedConcurrencyConfig": {
      "http": {
        "method": "PUT",
        "requestUri": "/2019-09-30/functions/{FunctionName}/provisioned-concurrency",
        "responseCode": 202
      },
      "input": {
        "type": "structure",
        "required": [
          "FunctionName",
          "Qualifier",
          "ProvisionedConcurrentExecutions"
        ],
        "members": {
          "FunctionName": {
            "location": "uri",
            "locationName": "FunctionName"
          },
          "Qualifier": {
            "location": "querystring",
            "locationName": "Qualifier"
          },
          "ProvisionedConcurrentExecutions": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "RequestedProvisionedConcurrentExecutions": {
            "type": "integer"
          },
          "AvailableProvisionedConcurrentExecutions": {
            "type": "integer"
          },
          "AllocatedProvisionedConcurrentExecutions": {
            "type": "integer"
          },
          "Status": {},
          "StatusReason": {},
          "LastModified": {}
        }
      }
    },
    "RemoveLayerVersionPermission": {
      "http": {
        "method": "DELETE",
        "requestUri": "/2018-10-31/layers/{LayerName}/versions/{VersionNumber}/policy/{StatementId}",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "LayerName",
          "VersionNumber",
          "StatementId"
        ],
        "members": {
          "LayerName": {
            "location": "uri",
            "locationName": "LayerName"
          },
          "VersionNumber": {
            "location": "uri",
            "locationName": "VersionNumber",
            "type": "long"
          },
          "StatementId": {
            "location": "uri",
            "locationName": "StatementId"
          },
          "RevisionId": {
            "location": "querystring",
            "locationName": "RevisionId"
          }
        }
      }
    },
    "RemovePermission": {
      "http": {
        "method": "DELETE",
        "requestUri": "/2015-03-31/functions/{FunctionName}/policy/{StatementId}",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "FunctionName",
          "StatementId"
        ],
        "members": {
          "FunctionName": {
            "location": "uri",
            "locationName": "FunctionName"
          },
          "StatementId": {
            "location": "uri",
            "locationName": "StatementId"
          },
          "Qualifier": {
            "location": "querystring",
            "locationName": "Qualifier"
          },
          "RevisionId": {
            "location": "querystring",
            "locationName": "RevisionId"
          }
        }
      }
    },
    "TagResource": {
      "http": {
        "requestUri": "/2017-03-31/tags/{ARN}",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "Resource",
          "Tags"
        ],
        "members": {
          "Resource": {
            "location": "uri",
            "locationName": "ARN"
          },
          "Tags": {
            "shape": "S2p"
          }
        }
      }
    },
    "UntagResource": {
      "http": {
        "method": "DELETE",
        "requestUri": "/2017-03-31/tags/{ARN}",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "Resource",
          "TagKeys"
        ],
        "members": {
          "Resource": {
            "location": "uri",
            "locationName": "ARN"
          },
          "TagKeys": {
            "location": "querystring",
            "locationName": "tagKeys",
            "type": "list",
            "member": {}
          }
        }
      }
    },
    "UpdateAlias": {
      "http": {
        "method": "PUT",
        "requestUri": "/2015-03-31/functions/{FunctionName}/aliases/{Name}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "FunctionName",
          "Name"
        ],
        "members": {
          "FunctionName": {
            "location": "uri",
            "locationName": "FunctionName"
          },
          "Name": {
            "location": "uri",
            "locationName": "Name"
          },
          "FunctionVersion": {},
          "Description": {},
          "RoutingConfig": {
            "shape": "Sn"
          },
          "RevisionId": {}
        }
      },
      "output": {
        "shape": "Sr"
      }
    },
    "UpdateCodeSigningConfig": {
      "http": {
        "method": "PUT",
        "requestUri": "/2020-04-22/code-signing-configs/{CodeSigningConfigArn}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "CodeSigningConfigArn"
        ],
        "members": {
          "CodeSigningConfigArn": {
            "location": "uri",
            "locationName": "CodeSigningConfigArn"
          },
          "Description": {},
          "AllowedPublishers": {
            "shape": "Su"
          },
          "CodeSigningPolicies": {
            "shape": "Sw"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "CodeSigningConfig"
        ],
        "members": {
          "CodeSigningConfig": {
            "shape": "Sz"
          }
        }
      }
    },
    "UpdateEventSourceMapping": {
      "http": {
        "method": "PUT",
        "requestUri": "/2015-03-31/event-source-mappings/{UUID}",
        "responseCode": 202
      },
      "input": {
        "type": "structure",
        "required": [
          "UUID"
        ],
        "members": {
          "UUID": {
            "location": "uri",
            "locationName": "UUID"
          },
          "FunctionName": {},
          "Enabled": {
            "type": "boolean"
          },
          "BatchSize": {
            "type": "integer"
          },
          "MaximumBatchingWindowInSeconds": {
            "type": "integer"
          },
          "DestinationConfig": {
            "shape": "S1a"
          },
          "MaximumRecordAgeInSeconds": {
            "type": "integer"
          },
          "BisectBatchOnFunctionError": {
            "type": "boolean"
          },
          "MaximumRetryAttempts": {
            "type": "integer"
          },
          "ParallelizationFactor": {
            "type": "integer"
          },
          "SourceAccessConfigurations": {
            "shape": "S1m"
          },
          "TumblingWindowInSeconds": {
            "type": "integer"
          },
          "FunctionResponseTypes": {
            "shape": "S1v"
          }
        }
      },
      "output": {
        "shape": "S1x"
      }
    },
    "UpdateFunctionCode": {
      "http": {
        "method": "PUT",
        "requestUri": "/2015-03-31/functions/{FunctionName}/code",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "FunctionName"
        ],
        "members": {
          "FunctionName": {
            "location": "uri",
            "locationName": "FunctionName"
          },
          "ZipFile": {
            "shape": "S23"
          },
          "S3Bucket": {},
          "S3Key": {},
          "S3ObjectVersion": {},
          "ImageUri": {},
          "Publish": {
            "type": "boolean"
          },
          "DryRun": {
            "type": "boolean"
          },
          "RevisionId": {},
          "Architectures": {
            "shape": "S31"
          }
        }
      },
      "output": {
        "shape": "S33"
      }
    },
    "UpdateFunctionConfiguration": {
      "http": {
        "method": "PUT",
        "requestUri": "/2015-03-31/functions/{FunctionName}/configuration",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "FunctionName"
        ],
        "members": {
          "FunctionName": {
            "location": "uri",
            "locationName": "FunctionName"
          },
          "Role": {},
          "Handler": {},
          "Description": {},
          "Timeout": {
            "type": "integer"
          },
          "MemorySize": {
            "type": "integer"
          },
          "VpcConfig": {
            "shape": "S2a"
          },
          "Environment": {
            "shape": "S2i"
          },
          "Runtime": {},
          "DeadLetterConfig": {
            "shape": "S2g"
          },
          "KMSKeyArn": {},
          "TracingConfig": {
            "shape": "S2n"
          },
          "RevisionId": {},
          "Layers": {
            "shape": "S2s"
          },
          "FileSystemConfigs": {
            "shape": "S2u"
          },
          "ImageConfig": {
            "shape": "S2y"
          }
        }
      },
      "output": {
        "shape": "S33"
      }
    },
    "UpdateFunctionEventInvokeConfig": {
      "http": {
        "requestUri": "/2019-09-25/functions/{FunctionName}/event-invoke-config",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "FunctionName"
        ],
        "members": {
          "FunctionName": {
            "location": "uri",
            "locationName": "FunctionName"
          },
          "Qualifier": {
            "location": "querystring",
            "locationName": "Qualifier"
          },
          "MaximumRetryAttempts": {
            "type": "integer"
          },
          "MaximumEventAgeInSeconds": {
            "type": "integer"
          },
          "DestinationConfig": {
            "shape": "S1a"
          }
        }
      },
      "output": {
        "shape": "S4i"
      }
    }
  },
  "shapes": {
    "Sn": {
      "type": "structure",
      "members": {
        "AdditionalVersionWeights": {
          "type": "map",
          "key": {},
          "value": {
            "type": "double"
          }
        }
      }
    },
    "Sr": {
      "type": "structure",
      "members": {
        "AliasArn": {},
        "Name": {},
        "FunctionVersion": {},
        "Description": {},
        "RoutingConfig": {
          "shape": "Sn"
        },
        "RevisionId": {}
      }
    },
    "Su": {
      "type": "structure",
      "required": [
        "SigningProfileVersionArns"
      ],
      "members": {
        "SigningProfileVersionArns": {
          "type": "list",
          "member": {}
        }
      }
    },
    "Sw": {
      "type": "structure",
      "members": {
        "UntrustedArtifactOnDeployment": {}
      }
    },
    "Sz": {
      "type": "structure",
      "required": [
        "CodeSigningConfigId",
        "CodeSigningConfigArn",
        "AllowedPublishers",
        "CodeSigningPolicies",
        "LastModified"
      ],
      "members": {
        "CodeSigningConfigId": {},
        "CodeSigningConfigArn": {},
        "Description": {},
        "AllowedPublishers": {
          "shape": "Su"
        },
        "CodeSigningPolicies": {
          "shape": "Sw"
        },
        "LastModified": {}
      }
    },
    "S1a": {
      "type": "structure",
      "members": {
        "OnSuccess": {
          "type": "structure",
          "members": {
            "Destination": {}
          }
        },
        "OnFailure": {
          "type": "structure",
          "members": {
            "Destination": {}
          }
        }
      }
    },
    "S1i": {
      "type": "list",
      "member": {}
    },
    "S1k": {
      "type": "list",
      "member": {}
    },
    "S1m": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "Type": {},
          "URI": {}
        }
      }
    },
    "S1q": {
      "type": "structure",
      "members": {
        "Endpoints": {
          "type": "map",
          "key": {},
          "value": {
            "type": "list",
            "member": {}
          }
        }
      }
    },
    "S1v": {
      "type": "list",
      "member": {}
    },
    "S1x": {
      "type": "structure",
      "members": {
        "UUID": {},
        "StartingPosition": {},
        "StartingPositionTimestamp": {
          "type": "timestamp"
        },
        "BatchSize": {
          "type": "integer"
        },
        "MaximumBatchingWindowInSeconds": {
          "type": "integer"
        },
        "ParallelizationFactor": {
          "type": "integer"
        },
        "EventSourceArn": {},
        "FunctionArn": {},
        "LastModified": {
          "type": "timestamp"
        },
        "LastProcessingResult": {},
        "State": {},
        "StateTransitionReason": {},
        "DestinationConfig": {
          "shape": "S1a"
        },
        "Topics": {
          "shape": "S1i"
        },
        "Queues": {
          "shape": "S1k"
        },
        "SourceAccessConfigurations": {
          "shape": "S1m"
        },
        "SelfManagedEventSource": {
          "shape": "S1q"
        },
        "MaximumRecordAgeInSeconds": {
          "type": "integer"
        },
        "BisectBatchOnFunctionError": {
          "type": "boolean"
        },
        "MaximumRetryAttempts": {
          "type": "integer"
        },
        "TumblingWindowInSeconds": {
          "type": "integer"
        },
        "FunctionResponseTypes": {
          "shape": "S1v"
        }
      }
    },
    "S23": {
      "type": "blob",
      "sensitive": true
    },
    "S2a": {
      "type": "structure",
      "members": {
        "SubnetIds": {
          "shape": "S2b"
        },
        "SecurityGroupIds": {
          "shape": "S2d"
        }
      }
    },
    "S2b": {
      "type": "list",
      "member": {}
    },
    "S2d": {
      "type": "list",
      "member": {}
    },
    "S2g": {
      "type": "structure",
      "members": {
        "TargetArn": {}
      }
    },
    "S2i": {
      "type": "structure",
      "members": {
        "Variables": {
          "shape": "S2j"
        }
      }
    },
    "S2j": {
      "type": "map",
      "key": {
        "type": "string",
        "sensitive": true
      },
      "value": {
        "type": "string",
        "sensitive": true
      },
      "sensitive": true
    },
    "S2n": {
      "type": "structure",
      "members": {
        "Mode": {}
      }
    },
    "S2p": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "S2s": {
      "type": "list",
      "member": {}
    },
    "S2u": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "Arn",
          "LocalMountPath"
        ],
        "members": {
          "Arn": {},
          "LocalMountPath": {}
        }
      }
    },
    "S2y": {
      "type": "structure",
      "members": {
        "EntryPoint": {
          "shape": "S2z"
        },
        "Command": {
          "shape": "S2z"
        },
        "WorkingDirectory": {}
      }
    },
    "S2z": {
      "type": "list",
      "member": {}
    },
    "S31": {
      "type": "list",
      "member": {}
    },
    "S33": {
      "type": "structure",
      "members": {
        "FunctionName": {},
        "FunctionArn": {},
        "Runtime": {},
        "Role": {},
        "Handler": {},
        "CodeSize": {
          "type": "long"
        },
        "Description": {},
        "Timeout": {
          "type": "integer"
        },
        "MemorySize": {
          "type": "integer"
        },
        "LastModified": {},
        "CodeSha256": {},
        "Version": {},
        "VpcConfig": {
          "type": "structure",
          "members": {
            "SubnetIds": {
              "shape": "S2b"
            },
            "SecurityGroupIds": {
              "shape": "S2d"
            },
            "VpcId": {}
          }
        },
        "DeadLetterConfig": {
          "shape": "S2g"
        },
        "Environment": {
          "type": "structure",
          "members": {
            "Variables": {
              "shape": "S2j"
            },
            "Error": {
              "type": "structure",
              "members": {
                "ErrorCode": {},
                "Message": {
                  "shape": "S3b"
                }
              }
            }
          }
        },
        "KMSKeyArn": {},
        "TracingConfig": {
          "type": "structure",
          "members": {
            "Mode": {}
          }
        },
        "MasterArn": {},
        "RevisionId": {},
        "Layers": {
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "Arn": {},
              "CodeSize": {
                "type": "long"
              },
              "SigningProfileVersionArn": {},
              "SigningJobArn": {}
            }
          }
        },
        "State": {},
        "StateReason": {},
        "StateReasonCode": {},
        "LastUpdateStatus": {},
        "LastUpdateStatusReason": {},
        "LastUpdateStatusReasonCode": {},
        "FileSystemConfigs": {
          "shape": "S2u"
        },
        "PackageType": {},
        "ImageConfigResponse": {
          "type": "structure",
          "members": {
            "ImageConfig": {
              "shape": "S2y"
            },
            "Error": {
              "type": "structure",
              "members": {
                "ErrorCode": {},
                "Message": {
                  "shape": "S3b"
                }
              }
            }
          }
        },
        "SigningProfileVersionArn": {},
        "SigningJobArn": {},
        "Architectures": {
          "shape": "S31"
        }
      }
    },
    "S3b": {
      "type": "string",
      "sensitive": true
    },
    "S4a": {
      "type": "structure",
      "members": {
        "ReservedConcurrentExecutions": {
          "type": "integer"
        }
      }
    },
    "S4i": {
      "type": "structure",
      "members": {
        "LastModified": {
          "type": "timestamp"
        },
        "FunctionArn": {},
        "MaximumRetryAttempts": {
          "type": "integer"
        },
        "MaximumEventAgeInSeconds": {
          "type": "integer"
        },
        "DestinationConfig": {
          "shape": "S1a"
        }
      }
    },
    "S4m": {
      "type": "structure",
      "members": {
        "Content": {
          "shape": "S4n"
        },
        "LayerArn": {},
        "LayerVersionArn": {},
        "Description": {},
        "CreatedDate": {},
        "Version": {
          "type": "long"
        },
        "CompatibleRuntimes": {
          "shape": "S4p"
        },
        "LicenseInfo": {},
        "CompatibleArchitectures": {
          "shape": "S4r"
        }
      }
    },
    "S4n": {
      "type": "structure",
      "members": {
        "Location": {},
        "CodeSha256": {},
        "CodeSize": {
          "type": "long"
        },
        "SigningProfileVersionArn": {},
        "SigningJobArn": {}
      }
    },
    "S4p": {
      "type": "list",
      "member": {}
    },
    "S4r": {
      "type": "list",
      "member": {}
    },
    "S5s": {
      "type": "list",
      "member": {
        "shape": "S33"
      }
    },
    "S60": {
      "type": "structure",
      "members": {
        "LayerVersionArn": {},
        "Version": {
          "type": "long"
        },
        "Description": {},
        "CreatedDate": {},
        "CompatibleRuntimes": {
          "shape": "S4p"
        },
        "LicenseInfo": {},
        "CompatibleArchitectures": {
          "shape": "S4r"
        }
      }
    }
  }
}