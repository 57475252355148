{
    "metadata": {
        "endpointPrefix": "nimble",
        "serviceFullName": "AmazonNimbleStudio",
        "serviceId": "nimble",
        "uid": "nimble-2020-08-01"
    },
    "pagination": {
        "ListEulaAcceptances": {
            "input_token": "nextToken",
            "output_token": "nextToken",
            "result_key": "eulaAcceptances"
        },
        "ListEulas": {
            "input_token": "nextToken",
            "output_token": "nextToken",
            "result_key": "eulas"
        },
        "ListLaunchProfileMembers": {
            "input_token": "nextToken",
            "limit_key": "maxResults",
            "output_token": "nextToken",
            "result_key": "members"
        },
        "ListLaunchProfiles": {
            "input_token": "nextToken",
            "limit_key": "maxResults",
            "output_token": "nextToken",
            "result_key": "launchProfiles"
        },
        "ListStreamingImages": {
            "input_token": "nextToken",
            "output_token": "nextToken",
            "result_key": "streamingImages"
        },
        "ListStreamingSessions": {
            "input_token": "nextToken",
            "output_token": "nextToken",
            "result_key": "sessions"
        },
        "ListStudioComponents": {
            "input_token": "nextToken",
            "limit_key": "maxResults",
            "output_token": "nextToken",
            "result_key": "studioComponents"
        },
        "ListStudioMembers": {
            "input_token": "nextToken",
            "limit_key": "maxResults",
            "output_token": "nextToken",
            "result_key": "members"
        },
        "ListStudios": {
            "input_token": "nextToken",
            "output_token": "nextToken",
            "result_key": "studios"
        }
    }
}
