{
  "metadata": {
    "apiVersion": "2020-08-01",
    "endpointPrefix": "nimble",
    "signingName": "nimble",
    "serviceFullName": "AmazonNimbleStudio",
    "serviceId": "nimble",
    "protocol": "rest-json",
    "jsonVersion": "1.1",
    "uid": "nimble-2020-08-01",
    "signatureVersion": "v4"
  },
  "operations": {
    "AcceptEulas": {
      "http": {
        "requestUri": "/2020-08-01/studios/{studioId}/eula-acceptances",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "clientToken": {
            "location": "header",
            "locationName": "X-Amz-Client-Token",
            "idempotencyToken": true
          },
          "eulaIds": {
            "shape": "S3",
            "locationName": "eulaIds"
          },
          "studioId": {
            "location": "uri",
            "locationName": "studioId"
          }
        },
        "required": [
          "studioId"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "eulaAcceptances": {
            "shape": "S6",
            "locationName": "eulaAcceptances"
          }
        }
      }
    },
    "CreateLaunchProfile": {
      "http": {
        "requestUri": "/2020-08-01/studios/{studioId}/launch-profiles",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "clientToken": {
            "location": "header",
            "locationName": "X-Amz-Client-Token",
            "idempotencyToken": true
          },
          "description": {
            "locationName": "description"
          },
          "ec2SubnetIds": {
            "shape": "Sd",
            "locationName": "ec2SubnetIds"
          },
          "launchProfileProtocolVersions": {
            "shape": "Sf",
            "locationName": "launchProfileProtocolVersions"
          },
          "name": {
            "locationName": "name"
          },
          "streamConfiguration": {
            "shape": "Si",
            "locationName": "streamConfiguration"
          },
          "studioComponentIds": {
            "shape": "Sp",
            "locationName": "studioComponentIds"
          },
          "studioId": {
            "location": "uri",
            "locationName": "studioId"
          },
          "tags": {
            "shape": "Sq",
            "locationName": "tags"
          }
        },
        "required": [
          "ec2SubnetIds",
          "studioComponentIds",
          "studioId",
          "launchProfileProtocolVersions",
          "name",
          "streamConfiguration"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "launchProfile": {
            "shape": "Ss",
            "locationName": "launchProfile"
          }
        }
      }
    },
    "CreateStreamingImage": {
      "http": {
        "requestUri": "/2020-08-01/studios/{studioId}/streaming-images",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "clientToken": {
            "location": "header",
            "locationName": "X-Amz-Client-Token",
            "idempotencyToken": true
          },
          "description": {
            "locationName": "description"
          },
          "ec2ImageId": {
            "locationName": "ec2ImageId"
          },
          "name": {
            "locationName": "name"
          },
          "studioId": {
            "location": "uri",
            "locationName": "studioId"
          },
          "tags": {
            "shape": "Sq",
            "locationName": "tags"
          }
        },
        "required": [
          "studioId",
          "name",
          "ec2ImageId"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "streamingImage": {
            "shape": "S12",
            "locationName": "streamingImage"
          }
        }
      }
    },
    "CreateStreamingSession": {
      "http": {
        "requestUri": "/2020-08-01/studios/{studioId}/streaming-sessions",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "clientToken": {
            "location": "header",
            "locationName": "X-Amz-Client-Token",
            "idempotencyToken": true
          },
          "ec2InstanceType": {
            "locationName": "ec2InstanceType"
          },
          "launchProfileId": {
            "locationName": "launchProfileId"
          },
          "ownedBy": {
            "locationName": "ownedBy"
          },
          "streamingImageId": {
            "locationName": "streamingImageId"
          },
          "studioId": {
            "location": "uri",
            "locationName": "studioId"
          },
          "tags": {
            "shape": "Sq",
            "locationName": "tags"
          }
        },
        "required": [
          "studioId"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "session": {
            "shape": "S1c",
            "locationName": "session"
          }
        }
      }
    },
    "CreateStreamingSessionStream": {
      "http": {
        "requestUri": "/2020-08-01/studios/{studioId}/streaming-sessions/{sessionId}/streams",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "clientToken": {
            "location": "header",
            "locationName": "X-Amz-Client-Token",
            "idempotencyToken": true
          },
          "expirationInSeconds": {
            "locationName": "expirationInSeconds",
            "type": "integer"
          },
          "sessionId": {
            "location": "uri",
            "locationName": "sessionId"
          },
          "studioId": {
            "location": "uri",
            "locationName": "studioId"
          }
        },
        "required": [
          "studioId",
          "sessionId"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "stream": {
            "shape": "S1j",
            "locationName": "stream"
          }
        }
      }
    },
    "CreateStudio": {
      "http": {
        "requestUri": "/2020-08-01/studios",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "adminRoleArn": {
            "locationName": "adminRoleArn"
          },
          "clientToken": {
            "location": "header",
            "locationName": "X-Amz-Client-Token",
            "idempotencyToken": true
          },
          "displayName": {
            "locationName": "displayName"
          },
          "studioEncryptionConfiguration": {
            "shape": "S1o",
            "locationName": "studioEncryptionConfiguration"
          },
          "studioName": {
            "locationName": "studioName"
          },
          "tags": {
            "shape": "Sq",
            "locationName": "tags"
          },
          "userRoleArn": {
            "locationName": "userRoleArn"
          }
        },
        "required": [
          "displayName",
          "studioName",
          "userRoleArn",
          "adminRoleArn"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "studio": {
            "shape": "S1t",
            "locationName": "studio"
          }
        }
      }
    },
    "CreateStudioComponent": {
      "http": {
        "requestUri": "/2020-08-01/studios/{studioId}/studio-components",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "clientToken": {
            "location": "header",
            "locationName": "X-Amz-Client-Token",
            "idempotencyToken": true
          },
          "configuration": {
            "shape": "S1y",
            "locationName": "configuration"
          },
          "description": {
            "locationName": "description"
          },
          "ec2SecurityGroupIds": {
            "shape": "S2c",
            "locationName": "ec2SecurityGroupIds"
          },
          "initializationScripts": {
            "shape": "S2e",
            "locationName": "initializationScripts"
          },
          "name": {
            "locationName": "name"
          },
          "scriptParameters": {
            "shape": "S2k",
            "locationName": "scriptParameters"
          },
          "studioId": {
            "location": "uri",
            "locationName": "studioId"
          },
          "subtype": {
            "locationName": "subtype"
          },
          "tags": {
            "shape": "Sq",
            "locationName": "tags"
          },
          "type": {
            "locationName": "type"
          }
        },
        "required": [
          "studioId",
          "name",
          "type"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "studioComponent": {
            "shape": "S2r",
            "locationName": "studioComponent"
          }
        }
      }
    },
    "DeleteLaunchProfile": {
      "http": {
        "method": "DELETE",
        "requestUri": "/2020-08-01/studios/{studioId}/launch-profiles/{launchProfileId}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "clientToken": {
            "location": "header",
            "locationName": "X-Amz-Client-Token",
            "idempotencyToken": true
          },
          "launchProfileId": {
            "location": "uri",
            "locationName": "launchProfileId"
          },
          "studioId": {
            "location": "uri",
            "locationName": "studioId"
          }
        },
        "required": [
          "studioId",
          "launchProfileId"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "launchProfile": {
            "shape": "Ss",
            "locationName": "launchProfile"
          }
        }
      }
    },
    "DeleteLaunchProfileMember": {
      "http": {
        "method": "DELETE",
        "requestUri": "/2020-08-01/studios/{studioId}/launch-profiles/{launchProfileId}/membership/{principalId}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "clientToken": {
            "location": "header",
            "locationName": "X-Amz-Client-Token",
            "idempotencyToken": true
          },
          "launchProfileId": {
            "location": "uri",
            "locationName": "launchProfileId"
          },
          "principalId": {
            "location": "uri",
            "locationName": "principalId"
          },
          "studioId": {
            "location": "uri",
            "locationName": "studioId"
          }
        },
        "required": [
          "studioId",
          "principalId",
          "launchProfileId"
        ]
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteStreamingImage": {
      "http": {
        "method": "DELETE",
        "requestUri": "/2020-08-01/studios/{studioId}/streaming-images/{streamingImageId}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "clientToken": {
            "location": "header",
            "locationName": "X-Amz-Client-Token",
            "idempotencyToken": true
          },
          "streamingImageId": {
            "location": "uri",
            "locationName": "streamingImageId"
          },
          "studioId": {
            "location": "uri",
            "locationName": "studioId"
          }
        },
        "required": [
          "studioId",
          "streamingImageId"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "streamingImage": {
            "shape": "S12",
            "locationName": "streamingImage"
          }
        }
      }
    },
    "DeleteStreamingSession": {
      "http": {
        "method": "DELETE",
        "requestUri": "/2020-08-01/studios/{studioId}/streaming-sessions/{sessionId}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "clientToken": {
            "location": "header",
            "locationName": "X-Amz-Client-Token",
            "idempotencyToken": true
          },
          "sessionId": {
            "location": "uri",
            "locationName": "sessionId"
          },
          "studioId": {
            "location": "uri",
            "locationName": "studioId"
          }
        },
        "required": [
          "studioId",
          "sessionId"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "session": {
            "shape": "S1c",
            "locationName": "session"
          }
        }
      }
    },
    "DeleteStudio": {
      "http": {
        "method": "DELETE",
        "requestUri": "/2020-08-01/studios/{studioId}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "clientToken": {
            "location": "header",
            "locationName": "X-Amz-Client-Token",
            "idempotencyToken": true
          },
          "studioId": {
            "location": "uri",
            "locationName": "studioId"
          }
        },
        "required": [
          "studioId"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "studio": {
            "shape": "S1t",
            "locationName": "studio"
          }
        }
      }
    },
    "DeleteStudioComponent": {
      "http": {
        "method": "DELETE",
        "requestUri": "/2020-08-01/studios/{studioId}/studio-components/{studioComponentId}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "clientToken": {
            "location": "header",
            "locationName": "X-Amz-Client-Token",
            "idempotencyToken": true
          },
          "studioComponentId": {
            "location": "uri",
            "locationName": "studioComponentId"
          },
          "studioId": {
            "location": "uri",
            "locationName": "studioId"
          }
        },
        "required": [
          "studioId",
          "studioComponentId"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "studioComponent": {
            "shape": "S2r",
            "locationName": "studioComponent"
          }
        }
      }
    },
    "DeleteStudioMember": {
      "http": {
        "method": "DELETE",
        "requestUri": "/2020-08-01/studios/{studioId}/membership/{principalId}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "clientToken": {
            "location": "header",
            "locationName": "X-Amz-Client-Token",
            "idempotencyToken": true
          },
          "principalId": {
            "location": "uri",
            "locationName": "principalId"
          },
          "studioId": {
            "location": "uri",
            "locationName": "studioId"
          }
        },
        "required": [
          "studioId",
          "principalId"
        ]
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "GetEula": {
      "http": {
        "method": "GET",
        "requestUri": "/2020-08-01/eulas/{eulaId}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "eulaId": {
            "location": "uri",
            "locationName": "eulaId"
          }
        },
        "required": [
          "eulaId"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "eula": {
            "shape": "S3b",
            "locationName": "eula"
          }
        }
      }
    },
    "GetLaunchProfile": {
      "http": {
        "method": "GET",
        "requestUri": "/2020-08-01/studios/{studioId}/launch-profiles/{launchProfileId}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "launchProfileId": {
            "location": "uri",
            "locationName": "launchProfileId"
          },
          "studioId": {
            "location": "uri",
            "locationName": "studioId"
          }
        },
        "required": [
          "studioId",
          "launchProfileId"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "launchProfile": {
            "shape": "Ss",
            "locationName": "launchProfile"
          }
        }
      }
    },
    "GetLaunchProfileDetails": {
      "http": {
        "method": "GET",
        "requestUri": "/2020-08-01/studios/{studioId}/launch-profiles/{launchProfileId}/details",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "launchProfileId": {
            "location": "uri",
            "locationName": "launchProfileId"
          },
          "studioId": {
            "location": "uri",
            "locationName": "studioId"
          }
        },
        "required": [
          "studioId",
          "launchProfileId"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "launchProfile": {
            "shape": "Ss",
            "locationName": "launchProfile"
          },
          "streamingImages": {
            "shape": "S3h",
            "locationName": "streamingImages"
          },
          "studioComponentSummaries": {
            "locationName": "studioComponentSummaries",
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "createdAt": {
                  "shape": "S8",
                  "locationName": "createdAt"
                },
                "createdBy": {
                  "locationName": "createdBy"
                },
                "description": {
                  "locationName": "description"
                },
                "name": {
                  "locationName": "name"
                },
                "studioComponentId": {
                  "locationName": "studioComponentId"
                },
                "subtype": {
                  "locationName": "subtype"
                },
                "type": {
                  "locationName": "type"
                },
                "updatedAt": {
                  "shape": "S8",
                  "locationName": "updatedAt"
                },
                "updatedBy": {
                  "locationName": "updatedBy"
                }
              }
            }
          }
        }
      }
    },
    "GetLaunchProfileInitialization": {
      "http": {
        "method": "GET",
        "requestUri": "/2020-08-01/studios/{studioId}/launch-profiles/{launchProfileId}/init",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "launchProfileId": {
            "location": "uri",
            "locationName": "launchProfileId"
          },
          "launchProfileProtocolVersions": {
            "shape": "S3l",
            "location": "querystring",
            "locationName": "launchProfileProtocolVersions"
          },
          "launchPurpose": {
            "location": "querystring",
            "locationName": "launchPurpose"
          },
          "platform": {
            "location": "querystring",
            "locationName": "platform"
          },
          "studioId": {
            "location": "uri",
            "locationName": "studioId"
          }
        },
        "required": [
          "studioId",
          "launchProfileProtocolVersions",
          "launchPurpose",
          "launchProfileId",
          "platform"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "launchProfileInitialization": {
            "locationName": "launchProfileInitialization",
            "type": "structure",
            "members": {
              "activeDirectory": {
                "locationName": "activeDirectory",
                "type": "structure",
                "members": {
                  "computerAttributes": {
                    "shape": "S20",
                    "locationName": "computerAttributes"
                  },
                  "directoryId": {
                    "locationName": "directoryId"
                  },
                  "directoryName": {
                    "locationName": "directoryName"
                  },
                  "dnsIpAddresses": {
                    "locationName": "dnsIpAddresses",
                    "type": "list",
                    "member": {}
                  },
                  "organizationalUnitDistinguishedName": {
                    "locationName": "organizationalUnitDistinguishedName"
                  },
                  "studioComponentId": {
                    "locationName": "studioComponentId"
                  },
                  "studioComponentName": {
                    "locationName": "studioComponentName"
                  }
                }
              },
              "ec2SecurityGroupIds": {
                "locationName": "ec2SecurityGroupIds",
                "type": "list",
                "member": {}
              },
              "launchProfileId": {
                "locationName": "launchProfileId"
              },
              "launchProfileProtocolVersion": {
                "locationName": "launchProfileProtocolVersion"
              },
              "launchPurpose": {
                "locationName": "launchPurpose"
              },
              "name": {
                "locationName": "name"
              },
              "platform": {
                "locationName": "platform"
              },
              "systemInitializationScripts": {
                "shape": "S3t",
                "locationName": "systemInitializationScripts"
              },
              "userInitializationScripts": {
                "shape": "S3t",
                "locationName": "userInitializationScripts"
              }
            }
          }
        }
      }
    },
    "GetLaunchProfileMember": {
      "http": {
        "method": "GET",
        "requestUri": "/2020-08-01/studios/{studioId}/launch-profiles/{launchProfileId}/membership/{principalId}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "launchProfileId": {
            "location": "uri",
            "locationName": "launchProfileId"
          },
          "principalId": {
            "location": "uri",
            "locationName": "principalId"
          },
          "studioId": {
            "location": "uri",
            "locationName": "studioId"
          }
        },
        "required": [
          "studioId",
          "principalId",
          "launchProfileId"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "member": {
            "shape": "S3x",
            "locationName": "member"
          }
        }
      }
    },
    "GetStreamingImage": {
      "http": {
        "method": "GET",
        "requestUri": "/2020-08-01/studios/{studioId}/streaming-images/{streamingImageId}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "streamingImageId": {
            "location": "uri",
            "locationName": "streamingImageId"
          },
          "studioId": {
            "location": "uri",
            "locationName": "studioId"
          }
        },
        "required": [
          "studioId",
          "streamingImageId"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "streamingImage": {
            "shape": "S12",
            "locationName": "streamingImage"
          }
        }
      }
    },
    "GetStreamingSession": {
      "http": {
        "method": "GET",
        "requestUri": "/2020-08-01/studios/{studioId}/streaming-sessions/{sessionId}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "sessionId": {
            "location": "uri",
            "locationName": "sessionId"
          },
          "studioId": {
            "location": "uri",
            "locationName": "studioId"
          }
        },
        "required": [
          "studioId",
          "sessionId"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "session": {
            "shape": "S1c",
            "locationName": "session"
          }
        }
      }
    },
    "GetStreamingSessionStream": {
      "http": {
        "method": "GET",
        "requestUri": "/2020-08-01/studios/{studioId}/streaming-sessions/{sessionId}/streams/{streamId}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "sessionId": {
            "location": "uri",
            "locationName": "sessionId"
          },
          "streamId": {
            "location": "uri",
            "locationName": "streamId"
          },
          "studioId": {
            "location": "uri",
            "locationName": "studioId"
          }
        },
        "required": [
          "studioId",
          "streamId",
          "sessionId"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "stream": {
            "shape": "S1j",
            "locationName": "stream"
          }
        }
      }
    },
    "GetStudio": {
      "http": {
        "method": "GET",
        "requestUri": "/2020-08-01/studios/{studioId}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "studioId": {
            "location": "uri",
            "locationName": "studioId"
          }
        },
        "required": [
          "studioId"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "studio": {
            "shape": "S1t",
            "locationName": "studio"
          }
        }
      }
    },
    "GetStudioComponent": {
      "http": {
        "method": "GET",
        "requestUri": "/2020-08-01/studios/{studioId}/studio-components/{studioComponentId}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "studioComponentId": {
            "location": "uri",
            "locationName": "studioComponentId"
          },
          "studioId": {
            "location": "uri",
            "locationName": "studioId"
          }
        },
        "required": [
          "studioId",
          "studioComponentId"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "studioComponent": {
            "shape": "S2r",
            "locationName": "studioComponent"
          }
        }
      }
    },
    "GetStudioMember": {
      "http": {
        "method": "GET",
        "requestUri": "/2020-08-01/studios/{studioId}/membership/{principalId}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "principalId": {
            "location": "uri",
            "locationName": "principalId"
          },
          "studioId": {
            "location": "uri",
            "locationName": "studioId"
          }
        },
        "required": [
          "studioId",
          "principalId"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "member": {
            "shape": "S4b",
            "locationName": "member"
          }
        }
      }
    },
    "ListEulaAcceptances": {
      "http": {
        "method": "GET",
        "requestUri": "/2020-08-01/studios/{studioId}/eula-acceptances",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "eulaIds": {
            "shape": "S3l",
            "location": "querystring",
            "locationName": "eulaIds"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "studioId": {
            "location": "uri",
            "locationName": "studioId"
          }
        },
        "required": [
          "studioId"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "eulaAcceptances": {
            "shape": "S6",
            "locationName": "eulaAcceptances"
          },
          "nextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "ListEulas": {
      "http": {
        "method": "GET",
        "requestUri": "/2020-08-01/eulas",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "eulaIds": {
            "shape": "S3l",
            "location": "querystring",
            "locationName": "eulaIds"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "eulas": {
            "locationName": "eulas",
            "type": "list",
            "member": {
              "shape": "S3b"
            }
          },
          "nextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "ListLaunchProfileMembers": {
      "http": {
        "method": "GET",
        "requestUri": "/2020-08-01/studios/{studioId}/launch-profiles/{launchProfileId}/membership",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "launchProfileId": {
            "location": "uri",
            "locationName": "launchProfileId"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "studioId": {
            "location": "uri",
            "locationName": "studioId"
          }
        },
        "required": [
          "studioId",
          "launchProfileId"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "members": {
            "locationName": "members",
            "type": "list",
            "member": {
              "shape": "S3x"
            }
          },
          "nextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "ListLaunchProfiles": {
      "http": {
        "method": "GET",
        "requestUri": "/2020-08-01/studios/{studioId}/launch-profiles",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "principalId": {
            "location": "querystring",
            "locationName": "principalId"
          },
          "states": {
            "shape": "S3l",
            "location": "querystring",
            "locationName": "states"
          },
          "studioId": {
            "location": "uri",
            "locationName": "studioId"
          }
        },
        "required": [
          "studioId"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "launchProfiles": {
            "locationName": "launchProfiles",
            "type": "list",
            "member": {
              "shape": "Ss"
            }
          },
          "nextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "ListStreamingImages": {
      "http": {
        "method": "GET",
        "requestUri": "/2020-08-01/studios/{studioId}/streaming-images",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "owner": {
            "location": "querystring",
            "locationName": "owner"
          },
          "studioId": {
            "location": "uri",
            "locationName": "studioId"
          }
        },
        "required": [
          "studioId"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "nextToken": {
            "locationName": "nextToken"
          },
          "streamingImages": {
            "shape": "S3h",
            "locationName": "streamingImages"
          }
        }
      }
    },
    "ListStreamingSessions": {
      "http": {
        "method": "GET",
        "requestUri": "/2020-08-01/studios/{studioId}/streaming-sessions",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "createdBy": {
            "location": "querystring",
            "locationName": "createdBy"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "ownedBy": {
            "location": "querystring",
            "locationName": "ownedBy"
          },
          "sessionIds": {
            "location": "querystring",
            "locationName": "sessionIds"
          },
          "studioId": {
            "location": "uri",
            "locationName": "studioId"
          }
        },
        "required": [
          "studioId"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "nextToken": {
            "locationName": "nextToken"
          },
          "sessions": {
            "locationName": "sessions",
            "type": "list",
            "member": {
              "shape": "S1c"
            }
          }
        }
      }
    },
    "ListStudioComponents": {
      "http": {
        "method": "GET",
        "requestUri": "/2020-08-01/studios/{studioId}/studio-components",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "states": {
            "shape": "S3l",
            "location": "querystring",
            "locationName": "states"
          },
          "studioId": {
            "location": "uri",
            "locationName": "studioId"
          },
          "types": {
            "shape": "S3l",
            "location": "querystring",
            "locationName": "types"
          }
        },
        "required": [
          "studioId"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "nextToken": {
            "locationName": "nextToken"
          },
          "studioComponents": {
            "locationName": "studioComponents",
            "type": "list",
            "member": {
              "shape": "S2r"
            }
          }
        }
      }
    },
    "ListStudioMembers": {
      "http": {
        "method": "GET",
        "requestUri": "/2020-08-01/studios/{studioId}/membership",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "studioId": {
            "location": "uri",
            "locationName": "studioId"
          }
        },
        "required": [
          "studioId"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "members": {
            "locationName": "members",
            "type": "list",
            "member": {
              "shape": "S4b"
            }
          },
          "nextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "ListStudios": {
      "http": {
        "method": "GET",
        "requestUri": "/2020-08-01/studios",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "nextToken": {
            "locationName": "nextToken"
          },
          "studios": {
            "locationName": "studios",
            "type": "list",
            "member": {
              "shape": "S1t"
            }
          }
        }
      }
    },
    "ListTagsForResource": {
      "http": {
        "method": "GET",
        "requestUri": "/2020-08-01/tags/{resourceArn}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "resourceArn": {
            "location": "uri",
            "locationName": "resourceArn"
          }
        },
        "required": [
          "resourceArn"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "tags": {
            "shape": "Sq",
            "locationName": "tags"
          }
        }
      }
    },
    "PutLaunchProfileMembers": {
      "http": {
        "requestUri": "/2020-08-01/studios/{studioId}/launch-profiles/{launchProfileId}/membership",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "clientToken": {
            "location": "header",
            "locationName": "X-Amz-Client-Token",
            "idempotencyToken": true
          },
          "identityStoreId": {
            "locationName": "identityStoreId"
          },
          "launchProfileId": {
            "location": "uri",
            "locationName": "launchProfileId"
          },
          "members": {
            "locationName": "members",
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "persona": {
                  "locationName": "persona"
                },
                "principalId": {
                  "locationName": "principalId"
                }
              },
              "required": [
                "persona",
                "principalId"
              ]
            }
          },
          "studioId": {
            "location": "uri",
            "locationName": "studioId"
          }
        },
        "required": [
          "studioId",
          "members",
          "launchProfileId",
          "identityStoreId"
        ]
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "PutStudioMembers": {
      "http": {
        "requestUri": "/2020-08-01/studios/{studioId}/membership",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "clientToken": {
            "location": "header",
            "locationName": "X-Amz-Client-Token",
            "idempotencyToken": true
          },
          "identityStoreId": {
            "locationName": "identityStoreId"
          },
          "members": {
            "locationName": "members",
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "persona": {
                  "locationName": "persona"
                },
                "principalId": {
                  "locationName": "principalId"
                }
              },
              "required": [
                "persona",
                "principalId"
              ]
            }
          },
          "studioId": {
            "location": "uri",
            "locationName": "studioId"
          }
        },
        "required": [
          "studioId",
          "members",
          "identityStoreId"
        ]
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "StartStudioSSOConfigurationRepair": {
      "http": {
        "method": "PUT",
        "requestUri": "/2020-08-01/studios/{studioId}/sso-configuration",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "clientToken": {
            "location": "header",
            "locationName": "X-Amz-Client-Token",
            "idempotencyToken": true
          },
          "studioId": {
            "location": "uri",
            "locationName": "studioId"
          }
        },
        "required": [
          "studioId"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "studio": {
            "shape": "S1t",
            "locationName": "studio"
          }
        }
      }
    },
    "TagResource": {
      "http": {
        "requestUri": "/2020-08-01/tags/{resourceArn}",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "members": {
          "resourceArn": {
            "location": "uri",
            "locationName": "resourceArn"
          },
          "tags": {
            "shape": "Sq",
            "locationName": "tags"
          }
        },
        "required": [
          "resourceArn"
        ]
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UntagResource": {
      "http": {
        "method": "DELETE",
        "requestUri": "/2020-08-01/tags/{resourceArn}",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "members": {
          "resourceArn": {
            "location": "uri",
            "locationName": "resourceArn"
          },
          "tagKeys": {
            "shape": "S3l",
            "location": "querystring",
            "locationName": "tagKeys"
          }
        },
        "required": [
          "tagKeys",
          "resourceArn"
        ]
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UpdateLaunchProfile": {
      "http": {
        "method": "PATCH",
        "requestUri": "/2020-08-01/studios/{studioId}/launch-profiles/{launchProfileId}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "clientToken": {
            "location": "header",
            "locationName": "X-Amz-Client-Token",
            "idempotencyToken": true
          },
          "description": {
            "locationName": "description"
          },
          "launchProfileId": {
            "location": "uri",
            "locationName": "launchProfileId"
          },
          "launchProfileProtocolVersions": {
            "shape": "Sf",
            "locationName": "launchProfileProtocolVersions"
          },
          "name": {
            "locationName": "name"
          },
          "streamConfiguration": {
            "shape": "Si",
            "locationName": "streamConfiguration"
          },
          "studioComponentIds": {
            "shape": "Sp",
            "locationName": "studioComponentIds"
          },
          "studioId": {
            "location": "uri",
            "locationName": "studioId"
          }
        },
        "required": [
          "studioId",
          "launchProfileId"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "launchProfile": {
            "shape": "Ss",
            "locationName": "launchProfile"
          }
        }
      }
    },
    "UpdateLaunchProfileMember": {
      "http": {
        "method": "PATCH",
        "requestUri": "/2020-08-01/studios/{studioId}/launch-profiles/{launchProfileId}/membership/{principalId}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "clientToken": {
            "location": "header",
            "locationName": "X-Amz-Client-Token",
            "idempotencyToken": true
          },
          "launchProfileId": {
            "location": "uri",
            "locationName": "launchProfileId"
          },
          "persona": {
            "locationName": "persona"
          },
          "principalId": {
            "location": "uri",
            "locationName": "principalId"
          },
          "studioId": {
            "location": "uri",
            "locationName": "studioId"
          }
        },
        "required": [
          "studioId",
          "persona",
          "principalId",
          "launchProfileId"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "member": {
            "shape": "S3x",
            "locationName": "member"
          }
        }
      }
    },
    "UpdateStreamingImage": {
      "http": {
        "method": "PATCH",
        "requestUri": "/2020-08-01/studios/{studioId}/streaming-images/{streamingImageId}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "clientToken": {
            "location": "header",
            "locationName": "X-Amz-Client-Token",
            "idempotencyToken": true
          },
          "description": {
            "locationName": "description"
          },
          "name": {
            "locationName": "name"
          },
          "streamingImageId": {
            "location": "uri",
            "locationName": "streamingImageId"
          },
          "studioId": {
            "location": "uri",
            "locationName": "studioId"
          }
        },
        "required": [
          "studioId",
          "streamingImageId"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "streamingImage": {
            "shape": "S12",
            "locationName": "streamingImage"
          }
        }
      }
    },
    "UpdateStudio": {
      "http": {
        "method": "PATCH",
        "requestUri": "/2020-08-01/studios/{studioId}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "adminRoleArn": {
            "locationName": "adminRoleArn"
          },
          "clientToken": {
            "location": "header",
            "locationName": "X-Amz-Client-Token",
            "idempotencyToken": true
          },
          "displayName": {
            "locationName": "displayName"
          },
          "studioId": {
            "location": "uri",
            "locationName": "studioId"
          },
          "userRoleArn": {
            "locationName": "userRoleArn"
          }
        },
        "required": [
          "studioId"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "studio": {
            "shape": "S1t",
            "locationName": "studio"
          }
        }
      }
    },
    "UpdateStudioComponent": {
      "http": {
        "method": "PATCH",
        "requestUri": "/2020-08-01/studios/{studioId}/studio-components/{studioComponentId}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "clientToken": {
            "location": "header",
            "locationName": "X-Amz-Client-Token",
            "idempotencyToken": true
          },
          "configuration": {
            "shape": "S1y",
            "locationName": "configuration"
          },
          "description": {
            "locationName": "description"
          },
          "ec2SecurityGroupIds": {
            "shape": "S2c",
            "locationName": "ec2SecurityGroupIds"
          },
          "initializationScripts": {
            "shape": "S2e",
            "locationName": "initializationScripts"
          },
          "name": {
            "locationName": "name"
          },
          "scriptParameters": {
            "shape": "S2k",
            "locationName": "scriptParameters"
          },
          "studioComponentId": {
            "location": "uri",
            "locationName": "studioComponentId"
          },
          "studioId": {
            "location": "uri",
            "locationName": "studioId"
          },
          "subtype": {
            "locationName": "subtype"
          },
          "type": {
            "locationName": "type"
          }
        },
        "required": [
          "studioId",
          "studioComponentId"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "studioComponent": {
            "shape": "S2r",
            "locationName": "studioComponent"
          }
        }
      }
    }
  },
  "shapes": {
    "S3": {
      "type": "list",
      "member": {}
    },
    "S6": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "acceptedAt": {
            "shape": "S8",
            "locationName": "acceptedAt"
          },
          "acceptedBy": {
            "locationName": "acceptedBy"
          },
          "accepteeId": {
            "locationName": "accepteeId"
          },
          "eulaAcceptanceId": {
            "locationName": "eulaAcceptanceId"
          },
          "eulaId": {
            "locationName": "eulaId"
          }
        }
      }
    },
    "S8": {
      "type": "timestamp",
      "timestampFormat": "iso8601"
    },
    "Sd": {
      "type": "list",
      "member": {}
    },
    "Sf": {
      "type": "list",
      "member": {}
    },
    "Si": {
      "type": "structure",
      "members": {
        "clipboardMode": {
          "locationName": "clipboardMode"
        },
        "ec2InstanceTypes": {
          "shape": "Sk",
          "locationName": "ec2InstanceTypes"
        },
        "maxSessionLengthInMinutes": {
          "locationName": "maxSessionLengthInMinutes",
          "type": "integer"
        },
        "streamingImageIds": {
          "shape": "Sn",
          "locationName": "streamingImageIds"
        }
      },
      "required": [
        "clipboardMode",
        "streamingImageIds",
        "ec2InstanceTypes"
      ]
    },
    "Sk": {
      "type": "list",
      "member": {}
    },
    "Sn": {
      "type": "list",
      "member": {}
    },
    "Sp": {
      "type": "list",
      "member": {}
    },
    "Sq": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "Ss": {
      "type": "structure",
      "members": {
        "arn": {
          "locationName": "arn"
        },
        "createdAt": {
          "shape": "S8",
          "locationName": "createdAt"
        },
        "createdBy": {
          "locationName": "createdBy"
        },
        "description": {
          "locationName": "description"
        },
        "ec2SubnetIds": {
          "shape": "Sd",
          "locationName": "ec2SubnetIds"
        },
        "launchProfileId": {
          "locationName": "launchProfileId"
        },
        "launchProfileProtocolVersions": {
          "shape": "Sf",
          "locationName": "launchProfileProtocolVersions"
        },
        "name": {
          "locationName": "name"
        },
        "state": {
          "locationName": "state"
        },
        "statusCode": {
          "locationName": "statusCode"
        },
        "statusMessage": {
          "locationName": "statusMessage"
        },
        "streamConfiguration": {
          "locationName": "streamConfiguration",
          "type": "structure",
          "members": {
            "clipboardMode": {
              "locationName": "clipboardMode"
            },
            "ec2InstanceTypes": {
              "shape": "Sk",
              "locationName": "ec2InstanceTypes"
            },
            "maxSessionLengthInMinutes": {
              "locationName": "maxSessionLengthInMinutes",
              "type": "integer"
            },
            "streamingImageIds": {
              "shape": "Sn",
              "locationName": "streamingImageIds"
            }
          }
        },
        "studioComponentIds": {
          "shape": "Sp",
          "locationName": "studioComponentIds"
        },
        "tags": {
          "shape": "Sq",
          "locationName": "tags"
        },
        "updatedAt": {
          "shape": "S8",
          "locationName": "updatedAt"
        },
        "updatedBy": {
          "locationName": "updatedBy"
        }
      }
    },
    "S12": {
      "type": "structure",
      "members": {
        "arn": {
          "locationName": "arn"
        },
        "description": {
          "locationName": "description"
        },
        "ec2ImageId": {
          "locationName": "ec2ImageId"
        },
        "encryptionConfiguration": {
          "locationName": "encryptionConfiguration",
          "type": "structure",
          "members": {
            "keyArn": {
              "locationName": "keyArn"
            },
            "keyType": {
              "locationName": "keyType"
            }
          },
          "required": [
            "keyType"
          ]
        },
        "eulaIds": {
          "shape": "S3",
          "locationName": "eulaIds"
        },
        "name": {
          "locationName": "name"
        },
        "owner": {
          "locationName": "owner"
        },
        "platform": {
          "locationName": "platform"
        },
        "state": {
          "locationName": "state"
        },
        "statusCode": {
          "locationName": "statusCode"
        },
        "statusMessage": {
          "locationName": "statusMessage"
        },
        "streamingImageId": {
          "locationName": "streamingImageId"
        },
        "tags": {
          "shape": "Sq",
          "locationName": "tags"
        }
      }
    },
    "S1c": {
      "type": "structure",
      "members": {
        "arn": {
          "locationName": "arn"
        },
        "createdAt": {
          "shape": "S8",
          "locationName": "createdAt"
        },
        "createdBy": {
          "locationName": "createdBy"
        },
        "ec2InstanceType": {
          "locationName": "ec2InstanceType"
        },
        "launchProfileId": {
          "locationName": "launchProfileId"
        },
        "ownedBy": {
          "locationName": "ownedBy"
        },
        "sessionId": {
          "locationName": "sessionId"
        },
        "state": {
          "locationName": "state"
        },
        "statusCode": {
          "locationName": "statusCode"
        },
        "statusMessage": {
          "locationName": "statusMessage"
        },
        "streamingImageId": {
          "locationName": "streamingImageId"
        },
        "tags": {
          "shape": "Sq",
          "locationName": "tags"
        },
        "terminateAt": {
          "shape": "S8",
          "locationName": "terminateAt"
        },
        "updatedAt": {
          "shape": "S8",
          "locationName": "updatedAt"
        },
        "updatedBy": {
          "locationName": "updatedBy"
        }
      }
    },
    "S1j": {
      "type": "structure",
      "members": {
        "createdAt": {
          "shape": "S8",
          "locationName": "createdAt"
        },
        "createdBy": {
          "locationName": "createdBy"
        },
        "expiresAt": {
          "shape": "S8",
          "locationName": "expiresAt"
        },
        "ownedBy": {
          "locationName": "ownedBy"
        },
        "state": {
          "locationName": "state"
        },
        "statusCode": {
          "locationName": "statusCode"
        },
        "streamId": {
          "locationName": "streamId"
        },
        "url": {
          "locationName": "url"
        }
      }
    },
    "S1o": {
      "type": "structure",
      "members": {
        "keyArn": {
          "locationName": "keyArn"
        },
        "keyType": {
          "locationName": "keyType"
        }
      },
      "required": [
        "keyType"
      ]
    },
    "S1t": {
      "type": "structure",
      "members": {
        "adminRoleArn": {
          "locationName": "adminRoleArn"
        },
        "arn": {
          "locationName": "arn"
        },
        "createdAt": {
          "shape": "S8",
          "locationName": "createdAt"
        },
        "displayName": {
          "locationName": "displayName"
        },
        "homeRegion": {
          "locationName": "homeRegion"
        },
        "ssoClientId": {
          "locationName": "ssoClientId"
        },
        "state": {
          "locationName": "state"
        },
        "statusCode": {
          "locationName": "statusCode"
        },
        "statusMessage": {
          "locationName": "statusMessage"
        },
        "studioEncryptionConfiguration": {
          "shape": "S1o",
          "locationName": "studioEncryptionConfiguration"
        },
        "studioId": {
          "locationName": "studioId"
        },
        "studioName": {
          "locationName": "studioName"
        },
        "studioUrl": {
          "locationName": "studioUrl"
        },
        "tags": {
          "shape": "Sq",
          "locationName": "tags"
        },
        "updatedAt": {
          "shape": "S8",
          "locationName": "updatedAt"
        },
        "userRoleArn": {
          "locationName": "userRoleArn"
        }
      }
    },
    "S1y": {
      "type": "structure",
      "members": {
        "activeDirectoryConfiguration": {
          "locationName": "activeDirectoryConfiguration",
          "type": "structure",
          "members": {
            "computerAttributes": {
              "shape": "S20",
              "locationName": "computerAttributes"
            },
            "directoryId": {
              "locationName": "directoryId"
            },
            "organizationalUnitDistinguishedName": {
              "locationName": "organizationalUnitDistinguishedName"
            }
          }
        },
        "computeFarmConfiguration": {
          "locationName": "computeFarmConfiguration",
          "type": "structure",
          "members": {
            "activeDirectoryUser": {
              "locationName": "activeDirectoryUser"
            },
            "endpoint": {
              "locationName": "endpoint"
            }
          }
        },
        "licenseServiceConfiguration": {
          "locationName": "licenseServiceConfiguration",
          "type": "structure",
          "members": {
            "endpoint": {
              "locationName": "endpoint"
            }
          }
        },
        "sharedFileSystemConfiguration": {
          "locationName": "sharedFileSystemConfiguration",
          "type": "structure",
          "members": {
            "endpoint": {
              "locationName": "endpoint"
            },
            "fileSystemId": {
              "locationName": "fileSystemId"
            },
            "linuxMountPoint": {
              "locationName": "linuxMountPoint"
            },
            "shareName": {
              "locationName": "shareName"
            },
            "windowsMountDrive": {
              "locationName": "windowsMountDrive"
            }
          }
        }
      },
      "union": true
    },
    "S20": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "name": {
            "locationName": "name"
          },
          "value": {
            "locationName": "value"
          }
        }
      }
    },
    "S2c": {
      "type": "list",
      "member": {}
    },
    "S2e": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "launchProfileProtocolVersion": {
            "locationName": "launchProfileProtocolVersion"
          },
          "platform": {
            "locationName": "platform"
          },
          "runContext": {
            "locationName": "runContext"
          },
          "script": {
            "locationName": "script"
          }
        }
      }
    },
    "S2k": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "key": {
            "locationName": "key"
          },
          "value": {
            "locationName": "value"
          }
        }
      }
    },
    "S2r": {
      "type": "structure",
      "members": {
        "arn": {
          "locationName": "arn"
        },
        "configuration": {
          "shape": "S1y",
          "locationName": "configuration"
        },
        "createdAt": {
          "shape": "S8",
          "locationName": "createdAt"
        },
        "createdBy": {
          "locationName": "createdBy"
        },
        "description": {
          "locationName": "description"
        },
        "ec2SecurityGroupIds": {
          "shape": "S2c",
          "locationName": "ec2SecurityGroupIds"
        },
        "initializationScripts": {
          "shape": "S2e",
          "locationName": "initializationScripts"
        },
        "name": {
          "locationName": "name"
        },
        "scriptParameters": {
          "shape": "S2k",
          "locationName": "scriptParameters"
        },
        "state": {
          "locationName": "state"
        },
        "statusCode": {
          "locationName": "statusCode"
        },
        "statusMessage": {
          "locationName": "statusMessage"
        },
        "studioComponentId": {
          "locationName": "studioComponentId"
        },
        "subtype": {
          "locationName": "subtype"
        },
        "tags": {
          "shape": "Sq",
          "locationName": "tags"
        },
        "type": {
          "locationName": "type"
        },
        "updatedAt": {
          "shape": "S8",
          "locationName": "updatedAt"
        },
        "updatedBy": {
          "locationName": "updatedBy"
        }
      }
    },
    "S3b": {
      "type": "structure",
      "members": {
        "content": {
          "locationName": "content"
        },
        "createdAt": {
          "shape": "S8",
          "locationName": "createdAt"
        },
        "eulaId": {
          "locationName": "eulaId"
        },
        "name": {
          "locationName": "name"
        },
        "updatedAt": {
          "shape": "S8",
          "locationName": "updatedAt"
        }
      }
    },
    "S3h": {
      "type": "list",
      "member": {
        "shape": "S12"
      }
    },
    "S3l": {
      "type": "list",
      "member": {}
    },
    "S3t": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "script": {
            "locationName": "script"
          },
          "studioComponentId": {
            "locationName": "studioComponentId"
          },
          "studioComponentName": {
            "locationName": "studioComponentName"
          }
        }
      }
    },
    "S3x": {
      "type": "structure",
      "members": {
        "identityStoreId": {
          "locationName": "identityStoreId"
        },
        "persona": {
          "locationName": "persona"
        },
        "principalId": {
          "locationName": "principalId"
        }
      }
    },
    "S4b": {
      "type": "structure",
      "members": {
        "identityStoreId": {
          "locationName": "identityStoreId"
        },
        "persona": {
          "locationName": "persona"
        },
        "principalId": {
          "locationName": "principalId"
        }
      }
    }
  }
}