{
  "version": "2.0",
  "metadata": {
    "apiVersion": "2018-03-01",
    "endpointPrefix": "fsx",
    "jsonVersion": "1.1",
    "protocol": "json",
    "serviceFullName": "Amazon FSx",
    "serviceId": "FSx",
    "signatureVersion": "v4",
    "signingName": "fsx",
    "targetPrefix": "AWSSimbaAPIService_v20180301",
    "uid": "fsx-2018-03-01"
  },
  "operations": {
    "AssociateFileSystemAliases": {
      "input": {
        "type": "structure",
        "required": [
          "FileSystemId",
          "Aliases"
        ],
        "members": {
          "ClientRequestToken": {
            "idempotencyToken": true
          },
          "FileSystemId": {},
          "Aliases": {
            "shape": "S4"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Aliases": {
            "shape": "S7"
          }
        }
      }
    },
    "CancelDataRepositoryTask": {
      "input": {
        "type": "structure",
        "required": [
          "TaskId"
        ],
        "members": {
          "TaskId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Lifecycle": {},
          "TaskId": {}
        }
      },
      "idempotent": true
    },
    "CopyBackup": {
      "input": {
        "type": "structure",
        "required": [
          "SourceBackupId"
        ],
        "members": {
          "ClientRequestToken": {
            "idempotencyToken": true
          },
          "SourceBackupId": {},
          "SourceRegion": {},
          "KmsKeyId": {},
          "CopyTags": {
            "type": "boolean"
          },
          "Tags": {
            "shape": "Sj"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Backup": {
            "shape": "So"
          }
        }
      },
      "idempotent": true
    },
    "CreateBackup": {
      "input": {
        "type": "structure",
        "members": {
          "FileSystemId": {},
          "ClientRequestToken": {
            "idempotencyToken": true
          },
          "Tags": {
            "shape": "Sj"
          },
          "VolumeId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Backup": {
            "shape": "So"
          }
        }
      },
      "idempotent": true
    },
    "CreateDataRepositoryTask": {
      "input": {
        "type": "structure",
        "required": [
          "Type",
          "FileSystemId",
          "Report"
        ],
        "members": {
          "Type": {},
          "Paths": {
            "shape": "S3a"
          },
          "FileSystemId": {},
          "Report": {
            "shape": "S3c"
          },
          "ClientRequestToken": {
            "idempotencyToken": true
          },
          "Tags": {
            "shape": "Sj"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DataRepositoryTask": {
            "shape": "S3g"
          }
        }
      },
      "idempotent": true
    },
    "CreateFileSystem": {
      "input": {
        "type": "structure",
        "required": [
          "FileSystemType",
          "StorageCapacity",
          "SubnetIds"
        ],
        "members": {
          "ClientRequestToken": {
            "idempotencyToken": true
          },
          "FileSystemType": {},
          "StorageCapacity": {
            "type": "integer"
          },
          "StorageType": {},
          "SubnetIds": {
            "shape": "S15"
          },
          "SecurityGroupIds": {
            "shape": "S3q"
          },
          "Tags": {
            "shape": "Sj"
          },
          "KmsKeyId": {},
          "WindowsConfiguration": {
            "shape": "S3s"
          },
          "LustreConfiguration": {
            "shape": "S3w"
          },
          "OntapConfiguration": {
            "type": "structure",
            "required": [
              "DeploymentType",
              "ThroughputCapacity"
            ],
            "members": {
              "AutomaticBackupRetentionDays": {
                "type": "integer"
              },
              "DailyAutomaticBackupStartTime": {},
              "DeploymentType": {},
              "EndpointIpAddressRange": {},
              "FsxAdminPassword": {
                "shape": "S3y"
              },
              "DiskIopsConfiguration": {
                "shape": "S2y"
              },
              "PreferredSubnetId": {},
              "RouteTableIds": {
                "shape": "S31"
              },
              "ThroughputCapacity": {
                "type": "integer"
              },
              "WeeklyMaintenanceStartTime": {}
            }
          },
          "FileSystemTypeVersion": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "FileSystem": {
            "shape": "Sx"
          }
        }
      }
    },
    "CreateFileSystemFromBackup": {
      "input": {
        "type": "structure",
        "required": [
          "BackupId",
          "SubnetIds"
        ],
        "members": {
          "BackupId": {},
          "ClientRequestToken": {
            "idempotencyToken": true
          },
          "SubnetIds": {
            "shape": "S15"
          },
          "SecurityGroupIds": {
            "shape": "S3q"
          },
          "Tags": {
            "shape": "Sj"
          },
          "WindowsConfiguration": {
            "shape": "S3s"
          },
          "LustreConfiguration": {
            "shape": "S3w"
          },
          "StorageType": {},
          "KmsKeyId": {},
          "FileSystemTypeVersion": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "FileSystem": {
            "shape": "Sx"
          }
        }
      }
    },
    "CreateStorageVirtualMachine": {
      "input": {
        "type": "structure",
        "required": [
          "FileSystemId",
          "Name"
        ],
        "members": {
          "ActiveDirectoryConfiguration": {
            "type": "structure",
            "required": [
              "NetBiosName"
            ],
            "members": {
              "NetBiosName": {},
              "SelfManagedActiveDirectoryConfiguration": {
                "shape": "S3t"
              }
            }
          },
          "ClientRequestToken": {
            "idempotencyToken": true
          },
          "FileSystemId": {},
          "Name": {},
          "SvmAdminPassword": {
            "shape": "S3y"
          },
          "Tags": {
            "shape": "Sj"
          },
          "RootVolumeSecurityStyle": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "StorageVirtualMachine": {
            "shape": "S48"
          }
        }
      }
    },
    "CreateVolume": {
      "input": {
        "type": "structure",
        "required": [
          "VolumeType",
          "Name"
        ],
        "members": {
          "ClientRequestToken": {
            "idempotencyToken": true
          },
          "VolumeType": {},
          "Name": {},
          "OntapConfiguration": {
            "shape": "S4f"
          },
          "Tags": {
            "shape": "Sj"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Volume": {
            "shape": "S2b"
          }
        }
      }
    },
    "CreateVolumeFromBackup": {
      "input": {
        "type": "structure",
        "required": [
          "BackupId",
          "Name"
        ],
        "members": {
          "BackupId": {},
          "ClientRequestToken": {
            "idempotencyToken": true
          },
          "Name": {},
          "OntapConfiguration": {
            "shape": "S4f"
          },
          "Tags": {
            "shape": "Sj"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Volume": {
            "shape": "S2b"
          }
        }
      }
    },
    "DeleteBackup": {
      "input": {
        "type": "structure",
        "required": [
          "BackupId"
        ],
        "members": {
          "BackupId": {},
          "ClientRequestToken": {
            "idempotencyToken": true
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "BackupId": {},
          "Lifecycle": {}
        }
      },
      "idempotent": true
    },
    "DeleteFileSystem": {
      "input": {
        "type": "structure",
        "required": [
          "FileSystemId"
        ],
        "members": {
          "FileSystemId": {},
          "ClientRequestToken": {
            "idempotencyToken": true
          },
          "WindowsConfiguration": {
            "type": "structure",
            "members": {
              "SkipFinalBackup": {
                "type": "boolean"
              },
              "FinalBackupTags": {
                "shape": "Sj"
              }
            }
          },
          "LustreConfiguration": {
            "type": "structure",
            "members": {
              "SkipFinalBackup": {
                "type": "boolean"
              },
              "FinalBackupTags": {
                "shape": "Sj"
              }
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "FileSystemId": {},
          "Lifecycle": {},
          "WindowsResponse": {
            "type": "structure",
            "members": {
              "FinalBackupId": {},
              "FinalBackupTags": {
                "shape": "Sj"
              }
            }
          },
          "LustreResponse": {
            "type": "structure",
            "members": {
              "FinalBackupId": {},
              "FinalBackupTags": {
                "shape": "Sj"
              }
            }
          }
        }
      },
      "idempotent": true
    },
    "DeleteStorageVirtualMachine": {
      "input": {
        "type": "structure",
        "required": [
          "StorageVirtualMachineId"
        ],
        "members": {
          "ClientRequestToken": {
            "idempotencyToken": true
          },
          "StorageVirtualMachineId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "StorageVirtualMachineId": {},
          "Lifecycle": {}
        }
      }
    },
    "DeleteVolume": {
      "input": {
        "type": "structure",
        "required": [
          "VolumeId"
        ],
        "members": {
          "ClientRequestToken": {
            "idempotencyToken": true
          },
          "VolumeId": {},
          "OntapConfiguration": {
            "type": "structure",
            "members": {
              "SkipFinalBackup": {
                "type": "boolean"
              },
              "FinalBackupTags": {
                "shape": "Sj"
              }
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "VolumeId": {},
          "Lifecycle": {},
          "OntapResponse": {
            "type": "structure",
            "members": {
              "FinalBackupId": {},
              "FinalBackupTags": {
                "shape": "Sj"
              }
            }
          }
        }
      }
    },
    "DescribeBackups": {
      "input": {
        "type": "structure",
        "members": {
          "BackupIds": {
            "type": "list",
            "member": {}
          },
          "Filters": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Name": {},
                "Values": {
                  "type": "list",
                  "member": {}
                }
              }
            }
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Backups": {
            "type": "list",
            "member": {
              "shape": "So"
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribeDataRepositoryTasks": {
      "input": {
        "type": "structure",
        "members": {
          "TaskIds": {
            "type": "list",
            "member": {}
          },
          "Filters": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Name": {},
                "Values": {
                  "type": "list",
                  "member": {}
                }
              }
            }
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DataRepositoryTasks": {
            "type": "list",
            "member": {
              "shape": "S3g"
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribeFileSystemAliases": {
      "input": {
        "type": "structure",
        "required": [
          "FileSystemId"
        ],
        "members": {
          "ClientRequestToken": {
            "idempotencyToken": true
          },
          "FileSystemId": {},
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Aliases": {
            "shape": "S7"
          },
          "NextToken": {}
        }
      }
    },
    "DescribeFileSystems": {
      "input": {
        "type": "structure",
        "members": {
          "FileSystemIds": {
            "type": "list",
            "member": {}
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "FileSystems": {
            "type": "list",
            "member": {
              "shape": "Sx"
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribeStorageVirtualMachines": {
      "input": {
        "type": "structure",
        "members": {
          "StorageVirtualMachineIds": {
            "type": "list",
            "member": {}
          },
          "Filters": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Name": {},
                "Values": {
                  "type": "list",
                  "member": {}
                }
              }
            }
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "StorageVirtualMachines": {
            "type": "list",
            "member": {
              "shape": "S48"
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribeVolumes": {
      "input": {
        "type": "structure",
        "members": {
          "VolumeIds": {
            "type": "list",
            "member": {}
          },
          "Filters": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Name": {},
                "Values": {
                  "type": "list",
                  "member": {}
                }
              }
            }
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Volumes": {
            "type": "list",
            "member": {
              "shape": "S2b"
            }
          },
          "NextToken": {}
        }
      }
    },
    "DisassociateFileSystemAliases": {
      "input": {
        "type": "structure",
        "required": [
          "FileSystemId",
          "Aliases"
        ],
        "members": {
          "ClientRequestToken": {
            "idempotencyToken": true
          },
          "FileSystemId": {},
          "Aliases": {
            "shape": "S4"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Aliases": {
            "shape": "S7"
          }
        }
      }
    },
    "ListTagsForResource": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceARN"
        ],
        "members": {
          "ResourceARN": {},
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Tags": {
            "shape": "Sj"
          },
          "NextToken": {}
        }
      }
    },
    "TagResource": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceARN",
          "Tags"
        ],
        "members": {
          "ResourceARN": {},
          "Tags": {
            "shape": "Sj"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    },
    "UntagResource": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceARN",
          "TagKeys"
        ],
        "members": {
          "ResourceARN": {},
          "TagKeys": {
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    },
    "UpdateFileSystem": {
      "input": {
        "type": "structure",
        "required": [
          "FileSystemId"
        ],
        "members": {
          "FileSystemId": {},
          "ClientRequestToken": {
            "idempotencyToken": true
          },
          "StorageCapacity": {
            "type": "integer"
          },
          "WindowsConfiguration": {
            "type": "structure",
            "members": {
              "WeeklyMaintenanceStartTime": {},
              "DailyAutomaticBackupStartTime": {},
              "AutomaticBackupRetentionDays": {
                "type": "integer"
              },
              "ThroughputCapacity": {
                "type": "integer"
              },
              "SelfManagedActiveDirectoryConfiguration": {
                "shape": "S6g"
              },
              "AuditLogConfiguration": {
                "shape": "S3v"
              }
            }
          },
          "LustreConfiguration": {
            "type": "structure",
            "members": {
              "WeeklyMaintenanceStartTime": {},
              "DailyAutomaticBackupStartTime": {},
              "AutomaticBackupRetentionDays": {
                "type": "integer"
              },
              "AutoImportPolicy": {},
              "DataCompressionType": {}
            }
          },
          "OntapConfiguration": {
            "type": "structure",
            "members": {
              "AutomaticBackupRetentionDays": {
                "type": "integer"
              },
              "DailyAutomaticBackupStartTime": {},
              "FsxAdminPassword": {
                "shape": "S3y"
              },
              "WeeklyMaintenanceStartTime": {}
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "FileSystem": {
            "shape": "Sx"
          }
        }
      }
    },
    "UpdateStorageVirtualMachine": {
      "input": {
        "type": "structure",
        "required": [
          "StorageVirtualMachineId"
        ],
        "members": {
          "ActiveDirectoryConfiguration": {
            "type": "structure",
            "members": {
              "SelfManagedActiveDirectoryConfiguration": {
                "shape": "S6g"
              }
            }
          },
          "ClientRequestToken": {
            "idempotencyToken": true
          },
          "StorageVirtualMachineId": {},
          "SvmAdminPassword": {
            "shape": "S3y"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "StorageVirtualMachine": {
            "shape": "S48"
          }
        }
      }
    },
    "UpdateVolume": {
      "input": {
        "type": "structure",
        "required": [
          "VolumeId"
        ],
        "members": {
          "ClientRequestToken": {
            "idempotencyToken": true
          },
          "VolumeId": {},
          "OntapConfiguration": {
            "type": "structure",
            "members": {
              "JunctionPath": {},
              "SecurityStyle": {},
              "SizeInMegabytes": {
                "type": "integer"
              },
              "StorageEfficiencyEnabled": {
                "type": "boolean"
              },
              "TieringPolicy": {
                "shape": "S2k"
              }
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Volume": {
            "shape": "S2b"
          }
        }
      }
    }
  },
  "shapes": {
    "S4": {
      "type": "list",
      "member": {}
    },
    "S7": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "Name": {},
          "Lifecycle": {}
        }
      }
    },
    "Sj": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "Key",
          "Value"
        ],
        "members": {
          "Key": {},
          "Value": {}
        }
      }
    },
    "So": {
      "type": "structure",
      "required": [
        "BackupId",
        "Lifecycle",
        "Type",
        "CreationTime",
        "FileSystem"
      ],
      "members": {
        "BackupId": {},
        "Lifecycle": {},
        "FailureDetails": {
          "type": "structure",
          "members": {
            "Message": {}
          }
        },
        "Type": {},
        "ProgressPercent": {
          "type": "integer"
        },
        "CreationTime": {
          "type": "timestamp"
        },
        "KmsKeyId": {},
        "ResourceARN": {},
        "Tags": {
          "shape": "Sj"
        },
        "FileSystem": {
          "shape": "Sx"
        },
        "DirectoryInformation": {
          "type": "structure",
          "members": {
            "DomainName": {},
            "ActiveDirectoryId": {},
            "ResourceARN": {}
          }
        },
        "OwnerId": {},
        "SourceBackupId": {},
        "SourceBackupRegion": {},
        "ResourceType": {},
        "Volume": {
          "shape": "S2b"
        }
      }
    },
    "Sx": {
      "type": "structure",
      "members": {
        "OwnerId": {},
        "CreationTime": {
          "type": "timestamp"
        },
        "FileSystemId": {},
        "FileSystemType": {},
        "Lifecycle": {},
        "FailureDetails": {
          "type": "structure",
          "members": {
            "Message": {}
          }
        },
        "StorageCapacity": {
          "type": "integer"
        },
        "StorageType": {},
        "VpcId": {},
        "SubnetIds": {
          "shape": "S15"
        },
        "NetworkInterfaceIds": {
          "type": "list",
          "member": {}
        },
        "DNSName": {},
        "KmsKeyId": {},
        "ResourceARN": {},
        "Tags": {
          "shape": "Sj"
        },
        "WindowsConfiguration": {
          "type": "structure",
          "members": {
            "ActiveDirectoryId": {},
            "SelfManagedActiveDirectoryConfiguration": {
              "shape": "S1c"
            },
            "DeploymentType": {},
            "RemoteAdministrationEndpoint": {},
            "PreferredSubnetId": {},
            "PreferredFileServerIp": {},
            "ThroughputCapacity": {
              "type": "integer"
            },
            "MaintenanceOperationsInProgress": {
              "type": "list",
              "member": {}
            },
            "WeeklyMaintenanceStartTime": {},
            "DailyAutomaticBackupStartTime": {},
            "AutomaticBackupRetentionDays": {
              "type": "integer"
            },
            "CopyTagsToBackups": {
              "type": "boolean"
            },
            "Aliases": {
              "shape": "S7"
            },
            "AuditLogConfiguration": {
              "type": "structure",
              "required": [
                "FileAccessAuditLogLevel",
                "FileShareAccessAuditLogLevel"
              ],
              "members": {
                "FileAccessAuditLogLevel": {},
                "FileShareAccessAuditLogLevel": {},
                "AuditLogDestination": {}
              }
            }
          }
        },
        "LustreConfiguration": {
          "type": "structure",
          "members": {
            "WeeklyMaintenanceStartTime": {},
            "DataRepositoryConfiguration": {
              "type": "structure",
              "members": {
                "Lifecycle": {},
                "ImportPath": {},
                "ExportPath": {},
                "ImportedFileChunkSize": {
                  "type": "integer"
                },
                "AutoImportPolicy": {},
                "FailureDetails": {
                  "type": "structure",
                  "members": {
                    "Message": {}
                  }
                }
              }
            },
            "DeploymentType": {},
            "PerUnitStorageThroughput": {
              "type": "integer"
            },
            "MountName": {},
            "DailyAutomaticBackupStartTime": {},
            "AutomaticBackupRetentionDays": {
              "type": "integer"
            },
            "CopyTagsToBackups": {
              "type": "boolean"
            },
            "DriveCacheType": {},
            "DataCompressionType": {}
          }
        },
        "AdministrativeActions": {
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "AdministrativeActionType": {},
              "ProgressPercent": {
                "type": "integer"
              },
              "RequestTime": {
                "type": "timestamp"
              },
              "Status": {},
              "TargetFileSystemValues": {
                "shape": "Sx"
              },
              "FailureDetails": {
                "type": "structure",
                "members": {
                  "Message": {}
                }
              },
              "TargetVolumeValues": {
                "shape": "S2b"
              }
            }
          }
        },
        "OntapConfiguration": {
          "type": "structure",
          "members": {
            "AutomaticBackupRetentionDays": {
              "type": "integer"
            },
            "DailyAutomaticBackupStartTime": {},
            "DeploymentType": {},
            "EndpointIpAddressRange": {},
            "Endpoints": {
              "type": "structure",
              "members": {
                "Intercluster": {
                  "shape": "S2w"
                },
                "Management": {
                  "shape": "S2w"
                }
              }
            },
            "DiskIopsConfiguration": {
              "shape": "S2y"
            },
            "PreferredSubnetId": {},
            "RouteTableIds": {
              "shape": "S31"
            },
            "ThroughputCapacity": {
              "type": "integer"
            },
            "WeeklyMaintenanceStartTime": {}
          }
        },
        "FileSystemTypeVersion": {}
      }
    },
    "S15": {
      "type": "list",
      "member": {}
    },
    "S1c": {
      "type": "structure",
      "members": {
        "DomainName": {},
        "OrganizationalUnitDistinguishedName": {},
        "FileSystemAdministratorsGroup": {},
        "UserName": {},
        "DnsIps": {
          "shape": "S1h"
        }
      }
    },
    "S1h": {
      "type": "list",
      "member": {}
    },
    "S2b": {
      "type": "structure",
      "members": {
        "CreationTime": {
          "type": "timestamp"
        },
        "FileSystemId": {},
        "Lifecycle": {},
        "Name": {},
        "OntapConfiguration": {
          "type": "structure",
          "members": {
            "FlexCacheEndpointType": {},
            "JunctionPath": {},
            "SecurityStyle": {},
            "SizeInMegabytes": {
              "type": "integer"
            },
            "StorageEfficiencyEnabled": {
              "type": "boolean"
            },
            "StorageVirtualMachineId": {},
            "StorageVirtualMachineRoot": {
              "type": "boolean"
            },
            "TieringPolicy": {
              "shape": "S2k"
            },
            "UUID": {},
            "OntapVolumeType": {}
          }
        },
        "ResourceARN": {},
        "Tags": {
          "shape": "Sj"
        },
        "VolumeId": {},
        "VolumeType": {},
        "LifecycleTransitionReason": {
          "shape": "S2r"
        }
      }
    },
    "S2k": {
      "type": "structure",
      "members": {
        "CoolingPeriod": {
          "type": "integer"
        },
        "Name": {}
      }
    },
    "S2r": {
      "type": "structure",
      "members": {
        "Message": {}
      }
    },
    "S2w": {
      "type": "structure",
      "members": {
        "DNSName": {},
        "IpAddresses": {
          "shape": "S2x"
        }
      }
    },
    "S2x": {
      "type": "list",
      "member": {}
    },
    "S2y": {
      "type": "structure",
      "members": {
        "Mode": {},
        "Iops": {
          "type": "long"
        }
      }
    },
    "S31": {
      "type": "list",
      "member": {}
    },
    "S3a": {
      "type": "list",
      "member": {}
    },
    "S3c": {
      "type": "structure",
      "required": [
        "Enabled"
      ],
      "members": {
        "Enabled": {
          "type": "boolean"
        },
        "Path": {},
        "Format": {},
        "Scope": {}
      }
    },
    "S3g": {
      "type": "structure",
      "required": [
        "TaskId",
        "Lifecycle",
        "Type",
        "CreationTime",
        "FileSystemId"
      ],
      "members": {
        "TaskId": {},
        "Lifecycle": {},
        "Type": {},
        "CreationTime": {
          "type": "timestamp"
        },
        "StartTime": {
          "type": "timestamp"
        },
        "EndTime": {
          "type": "timestamp"
        },
        "ResourceARN": {},
        "Tags": {
          "shape": "Sj"
        },
        "FileSystemId": {},
        "Paths": {
          "shape": "S3a"
        },
        "FailureDetails": {
          "type": "structure",
          "members": {
            "Message": {}
          }
        },
        "Status": {
          "type": "structure",
          "members": {
            "TotalCount": {
              "type": "long"
            },
            "SucceededCount": {
              "type": "long"
            },
            "FailedCount": {
              "type": "long"
            },
            "LastUpdatedTime": {
              "type": "timestamp"
            }
          }
        },
        "Report": {
          "shape": "S3c"
        }
      }
    },
    "S3q": {
      "type": "list",
      "member": {}
    },
    "S3s": {
      "type": "structure",
      "required": [
        "ThroughputCapacity"
      ],
      "members": {
        "ActiveDirectoryId": {},
        "SelfManagedActiveDirectoryConfiguration": {
          "shape": "S3t"
        },
        "DeploymentType": {},
        "PreferredSubnetId": {},
        "ThroughputCapacity": {
          "type": "integer"
        },
        "WeeklyMaintenanceStartTime": {},
        "DailyAutomaticBackupStartTime": {},
        "AutomaticBackupRetentionDays": {
          "type": "integer"
        },
        "CopyTagsToBackups": {
          "type": "boolean"
        },
        "Aliases": {
          "shape": "S4"
        },
        "AuditLogConfiguration": {
          "shape": "S3v"
        }
      }
    },
    "S3t": {
      "type": "structure",
      "required": [
        "DomainName",
        "UserName",
        "Password",
        "DnsIps"
      ],
      "members": {
        "DomainName": {},
        "OrganizationalUnitDistinguishedName": {},
        "FileSystemAdministratorsGroup": {},
        "UserName": {},
        "Password": {
          "shape": "S3u"
        },
        "DnsIps": {
          "shape": "S1h"
        }
      }
    },
    "S3u": {
      "type": "string",
      "sensitive": true
    },
    "S3v": {
      "type": "structure",
      "required": [
        "FileAccessAuditLogLevel",
        "FileShareAccessAuditLogLevel"
      ],
      "members": {
        "FileAccessAuditLogLevel": {},
        "FileShareAccessAuditLogLevel": {},
        "AuditLogDestination": {}
      }
    },
    "S3w": {
      "type": "structure",
      "members": {
        "WeeklyMaintenanceStartTime": {},
        "ImportPath": {},
        "ExportPath": {},
        "ImportedFileChunkSize": {
          "type": "integer"
        },
        "DeploymentType": {},
        "AutoImportPolicy": {},
        "PerUnitStorageThroughput": {
          "type": "integer"
        },
        "DailyAutomaticBackupStartTime": {},
        "AutomaticBackupRetentionDays": {
          "type": "integer"
        },
        "CopyTagsToBackups": {
          "type": "boolean"
        },
        "DriveCacheType": {},
        "DataCompressionType": {}
      }
    },
    "S3y": {
      "type": "string",
      "sensitive": true
    },
    "S48": {
      "type": "structure",
      "members": {
        "ActiveDirectoryConfiguration": {
          "type": "structure",
          "members": {
            "NetBiosName": {},
            "SelfManagedActiveDirectoryConfiguration": {
              "shape": "S1c"
            }
          }
        },
        "CreationTime": {
          "type": "timestamp"
        },
        "Endpoints": {
          "type": "structure",
          "members": {
            "Iscsi": {
              "shape": "S4b"
            },
            "Management": {
              "shape": "S4b"
            },
            "Nfs": {
              "shape": "S4b"
            },
            "Smb": {
              "shape": "S4b"
            }
          }
        },
        "FileSystemId": {},
        "Lifecycle": {},
        "Name": {},
        "ResourceARN": {},
        "StorageVirtualMachineId": {},
        "Subtype": {},
        "UUID": {},
        "Tags": {
          "shape": "Sj"
        },
        "LifecycleTransitionReason": {
          "shape": "S2r"
        },
        "RootVolumeSecurityStyle": {}
      }
    },
    "S4b": {
      "type": "structure",
      "members": {
        "DNSName": {},
        "IpAddresses": {
          "shape": "S2x"
        }
      }
    },
    "S4f": {
      "type": "structure",
      "required": [
        "JunctionPath",
        "SizeInMegabytes",
        "StorageEfficiencyEnabled",
        "StorageVirtualMachineId"
      ],
      "members": {
        "JunctionPath": {},
        "SecurityStyle": {},
        "SizeInMegabytes": {
          "type": "integer"
        },
        "StorageEfficiencyEnabled": {
          "type": "boolean"
        },
        "StorageVirtualMachineId": {},
        "TieringPolicy": {
          "shape": "S2k"
        }
      }
    },
    "S6g": {
      "type": "structure",
      "members": {
        "UserName": {},
        "Password": {
          "shape": "S3u"
        },
        "DnsIps": {
          "shape": "S1h"
        }
      }
    }
  }
}