{
  "version": "2.0",
  "metadata": {
    "apiVersion": "2019-11-01",
    "endpointPrefix": "compute-optimizer",
    "jsonVersion": "1.0",
    "protocol": "json",
    "serviceFullName": "AWS Compute Optimizer",
    "serviceId": "Compute Optimizer",
    "signatureVersion": "v4",
    "signingName": "compute-optimizer",
    "targetPrefix": "ComputeOptimizerService",
    "uid": "compute-optimizer-2019-11-01"
  },
  "operations": {
    "DescribeRecommendationExportJobs": {
      "input": {
        "type": "structure",
        "members": {
          "jobIds": {
            "type": "list",
            "member": {}
          },
          "filters": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "name": {},
                "values": {
                  "shape": "S7"
                }
              }
            }
          },
          "nextToken": {},
          "maxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "recommendationExportJobs": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "jobId": {},
                "destination": {
                  "type": "structure",
                  "members": {
                    "s3": {
                      "shape": "Sf"
                    }
                  }
                },
                "resourceType": {},
                "status": {},
                "creationTimestamp": {
                  "type": "timestamp"
                },
                "lastUpdatedTimestamp": {
                  "type": "timestamp"
                },
                "failureReason": {}
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "ExportAutoScalingGroupRecommendations": {
      "input": {
        "type": "structure",
        "required": [
          "s3DestinationConfig"
        ],
        "members": {
          "accountIds": {
            "shape": "Sp"
          },
          "filters": {
            "shape": "Sr"
          },
          "fieldsToExport": {
            "type": "list",
            "member": {}
          },
          "s3DestinationConfig": {
            "shape": "Sw"
          },
          "fileFormat": {},
          "includeMemberAccounts": {
            "type": "boolean"
          },
          "recommendationPreferences": {
            "shape": "S10"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "jobId": {},
          "s3Destination": {
            "shape": "Sf"
          }
        }
      }
    },
    "ExportEBSVolumeRecommendations": {
      "input": {
        "type": "structure",
        "required": [
          "s3DestinationConfig"
        ],
        "members": {
          "accountIds": {
            "shape": "Sp"
          },
          "filters": {
            "shape": "S15"
          },
          "fieldsToExport": {
            "type": "list",
            "member": {}
          },
          "s3DestinationConfig": {
            "shape": "Sw"
          },
          "fileFormat": {},
          "includeMemberAccounts": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "jobId": {},
          "s3Destination": {
            "shape": "Sf"
          }
        }
      }
    },
    "ExportEC2InstanceRecommendations": {
      "input": {
        "type": "structure",
        "required": [
          "s3DestinationConfig"
        ],
        "members": {
          "accountIds": {
            "shape": "Sp"
          },
          "filters": {
            "shape": "Sr"
          },
          "fieldsToExport": {
            "type": "list",
            "member": {}
          },
          "s3DestinationConfig": {
            "shape": "Sw"
          },
          "fileFormat": {},
          "includeMemberAccounts": {
            "type": "boolean"
          },
          "recommendationPreferences": {
            "shape": "S10"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "jobId": {},
          "s3Destination": {
            "shape": "Sf"
          }
        }
      }
    },
    "ExportLambdaFunctionRecommendations": {
      "input": {
        "type": "structure",
        "required": [
          "s3DestinationConfig"
        ],
        "members": {
          "accountIds": {
            "shape": "Sp"
          },
          "filters": {
            "shape": "S1g"
          },
          "fieldsToExport": {
            "type": "list",
            "member": {}
          },
          "s3DestinationConfig": {
            "shape": "Sw"
          },
          "fileFormat": {},
          "includeMemberAccounts": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "jobId": {},
          "s3Destination": {
            "shape": "Sf"
          }
        }
      }
    },
    "GetAutoScalingGroupRecommendations": {
      "input": {
        "type": "structure",
        "members": {
          "accountIds": {
            "shape": "Sp"
          },
          "autoScalingGroupArns": {
            "type": "list",
            "member": {}
          },
          "nextToken": {},
          "maxResults": {
            "type": "integer"
          },
          "filters": {
            "shape": "Sr"
          },
          "recommendationPreferences": {
            "shape": "S10"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "nextToken": {},
          "autoScalingGroupRecommendations": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "accountId": {},
                "autoScalingGroupArn": {},
                "autoScalingGroupName": {},
                "finding": {},
                "utilizationMetrics": {
                  "shape": "S1u"
                },
                "lookBackPeriodInDays": {
                  "type": "double"
                },
                "currentConfiguration": {
                  "shape": "S20"
                },
                "recommendationOptions": {
                  "type": "list",
                  "member": {
                    "type": "structure",
                    "members": {
                      "configuration": {
                        "shape": "S20"
                      },
                      "projectedUtilizationMetrics": {
                        "shape": "S27"
                      },
                      "performanceRisk": {
                        "type": "double"
                      },
                      "rank": {
                        "type": "integer"
                      }
                    }
                  }
                },
                "lastRefreshTimestamp": {
                  "type": "timestamp"
                }
              }
            }
          },
          "errors": {
            "shape": "S2b"
          }
        }
      }
    },
    "GetEBSVolumeRecommendations": {
      "input": {
        "type": "structure",
        "members": {
          "volumeArns": {
            "type": "list",
            "member": {}
          },
          "nextToken": {},
          "maxResults": {
            "type": "integer"
          },
          "filters": {
            "shape": "S15"
          },
          "accountIds": {
            "shape": "Sp"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "nextToken": {},
          "volumeRecommendations": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "volumeArn": {},
                "accountId": {},
                "currentConfiguration": {
                  "shape": "S2m"
                },
                "finding": {},
                "utilizationMetrics": {
                  "type": "list",
                  "member": {
                    "type": "structure",
                    "members": {
                      "name": {},
                      "statistic": {},
                      "value": {
                        "type": "double"
                      }
                    }
                  }
                },
                "lookBackPeriodInDays": {
                  "type": "double"
                },
                "volumeRecommendationOptions": {
                  "type": "list",
                  "member": {
                    "type": "structure",
                    "members": {
                      "configuration": {
                        "shape": "S2m"
                      },
                      "performanceRisk": {
                        "type": "double"
                      },
                      "rank": {
                        "type": "integer"
                      }
                    }
                  }
                },
                "lastRefreshTimestamp": {
                  "type": "timestamp"
                }
              }
            }
          },
          "errors": {
            "shape": "S2b"
          }
        }
      }
    },
    "GetEC2InstanceRecommendations": {
      "input": {
        "type": "structure",
        "members": {
          "instanceArns": {
            "type": "list",
            "member": {}
          },
          "nextToken": {},
          "maxResults": {
            "type": "integer"
          },
          "filters": {
            "shape": "Sr"
          },
          "accountIds": {
            "shape": "Sp"
          },
          "recommendationPreferences": {
            "shape": "S10"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "nextToken": {},
          "instanceRecommendations": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "instanceArn": {},
                "accountId": {},
                "instanceName": {},
                "currentInstanceType": {},
                "finding": {},
                "findingReasonCodes": {
                  "type": "list",
                  "member": {}
                },
                "utilizationMetrics": {
                  "shape": "S1u"
                },
                "lookBackPeriodInDays": {
                  "type": "double"
                },
                "recommendationOptions": {
                  "type": "list",
                  "member": {
                    "type": "structure",
                    "members": {
                      "instanceType": {},
                      "projectedUtilizationMetrics": {
                        "shape": "S27"
                      },
                      "platformDifferences": {
                        "type": "list",
                        "member": {}
                      },
                      "performanceRisk": {
                        "type": "double"
                      },
                      "rank": {
                        "type": "integer"
                      }
                    }
                  }
                },
                "recommendationSources": {
                  "type": "list",
                  "member": {
                    "type": "structure",
                    "members": {
                      "recommendationSourceArn": {},
                      "recommendationSourceType": {}
                    }
                  }
                },
                "lastRefreshTimestamp": {
                  "type": "timestamp"
                }
              }
            }
          },
          "errors": {
            "shape": "S2b"
          }
        }
      }
    },
    "GetEC2RecommendationProjectedMetrics": {
      "input": {
        "type": "structure",
        "required": [
          "instanceArn",
          "stat",
          "period",
          "startTime",
          "endTime"
        ],
        "members": {
          "instanceArn": {},
          "stat": {},
          "period": {
            "type": "integer"
          },
          "startTime": {
            "type": "timestamp"
          },
          "endTime": {
            "type": "timestamp"
          },
          "recommendationPreferences": {
            "shape": "S10"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "recommendedOptionProjectedMetrics": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "recommendedInstanceType": {},
                "rank": {
                  "type": "integer"
                },
                "projectedMetrics": {
                  "type": "list",
                  "member": {
                    "type": "structure",
                    "members": {
                      "name": {},
                      "timestamps": {
                        "type": "list",
                        "member": {
                          "type": "timestamp"
                        }
                      },
                      "values": {
                        "type": "list",
                        "member": {
                          "type": "double"
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    },
    "GetEnrollmentStatus": {
      "input": {
        "type": "structure",
        "members": {}
      },
      "output": {
        "type": "structure",
        "members": {
          "status": {},
          "statusReason": {},
          "memberAccountsEnrolled": {
            "type": "boolean"
          },
          "lastUpdatedTimestamp": {
            "type": "timestamp"
          },
          "numberOfMemberAccountsOptedIn": {
            "type": "integer"
          }
        }
      }
    },
    "GetEnrollmentStatusesForOrganization": {
      "input": {
        "type": "structure",
        "members": {
          "filters": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "name": {},
                "values": {
                  "shape": "S7"
                }
              }
            }
          },
          "nextToken": {},
          "maxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "accountEnrollmentStatuses": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "accountId": {},
                "status": {},
                "statusReason": {},
                "lastUpdatedTimestamp": {
                  "type": "timestamp"
                }
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "GetLambdaFunctionRecommendations": {
      "input": {
        "type": "structure",
        "members": {
          "functionArns": {
            "type": "list",
            "member": {}
          },
          "accountIds": {
            "shape": "Sp"
          },
          "filters": {
            "shape": "S1g"
          },
          "nextToken": {},
          "maxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "nextToken": {},
          "lambdaFunctionRecommendations": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "functionArn": {},
                "functionVersion": {},
                "accountId": {},
                "currentMemorySize": {
                  "type": "integer"
                },
                "numberOfInvocations": {
                  "type": "long"
                },
                "utilizationMetrics": {
                  "type": "list",
                  "member": {
                    "type": "structure",
                    "members": {
                      "name": {},
                      "statistic": {},
                      "value": {
                        "type": "double"
                      }
                    }
                  }
                },
                "lookbackPeriodInDays": {
                  "type": "double"
                },
                "lastRefreshTimestamp": {
                  "type": "timestamp"
                },
                "finding": {},
                "findingReasonCodes": {
                  "type": "list",
                  "member": {}
                },
                "memorySizeRecommendationOptions": {
                  "type": "list",
                  "member": {
                    "type": "structure",
                    "members": {
                      "rank": {
                        "type": "integer"
                      },
                      "memorySize": {
                        "type": "integer"
                      },
                      "projectedUtilizationMetrics": {
                        "type": "list",
                        "member": {
                          "type": "structure",
                          "members": {
                            "name": {},
                            "statistic": {},
                            "value": {
                              "type": "double"
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    },
    "GetRecommendationSummaries": {
      "input": {
        "type": "structure",
        "members": {
          "accountIds": {
            "shape": "Sp"
          },
          "nextToken": {},
          "maxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "nextToken": {},
          "recommendationSummaries": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "summaries": {
                  "type": "list",
                  "member": {
                    "type": "structure",
                    "members": {
                      "name": {},
                      "value": {
                        "type": "double"
                      },
                      "reasonCodeSummaries": {
                        "type": "list",
                        "member": {
                          "type": "structure",
                          "members": {
                            "name": {},
                            "value": {
                              "type": "double"
                            }
                          }
                        }
                      }
                    }
                  }
                },
                "recommendationResourceType": {},
                "accountId": {}
              }
            }
          }
        }
      }
    },
    "UpdateEnrollmentStatus": {
      "input": {
        "type": "structure",
        "required": [
          "status"
        ],
        "members": {
          "status": {},
          "includeMemberAccounts": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "status": {},
          "statusReason": {}
        }
      }
    }
  },
  "shapes": {
    "S7": {
      "type": "list",
      "member": {}
    },
    "Sf": {
      "type": "structure",
      "members": {
        "bucket": {},
        "key": {},
        "metadataKey": {}
      }
    },
    "Sp": {
      "type": "list",
      "member": {}
    },
    "Sr": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "name": {},
          "values": {
            "shape": "S7"
          }
        }
      }
    },
    "Sw": {
      "type": "structure",
      "members": {
        "bucket": {},
        "keyPrefix": {}
      }
    },
    "S10": {
      "type": "structure",
      "members": {
        "cpuVendorArchitectures": {
          "type": "list",
          "member": {}
        }
      }
    },
    "S15": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "name": {},
          "values": {
            "shape": "S7"
          }
        }
      }
    },
    "S1g": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "name": {},
          "values": {
            "shape": "S7"
          }
        }
      }
    },
    "S1u": {
      "type": "list",
      "member": {
        "shape": "S1v"
      }
    },
    "S1v": {
      "type": "structure",
      "members": {
        "name": {},
        "statistic": {},
        "value": {
          "type": "double"
        }
      }
    },
    "S20": {
      "type": "structure",
      "members": {
        "desiredCapacity": {
          "type": "integer"
        },
        "minSize": {
          "type": "integer"
        },
        "maxSize": {
          "type": "integer"
        },
        "instanceType": {}
      }
    },
    "S27": {
      "type": "list",
      "member": {
        "shape": "S1v"
      }
    },
    "S2b": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "identifier": {},
          "code": {},
          "message": {}
        }
      }
    },
    "S2m": {
      "type": "structure",
      "members": {
        "volumeType": {},
        "volumeSize": {
          "type": "integer"
        },
        "volumeBaselineIOPS": {
          "type": "integer"
        },
        "volumeBurstIOPS": {
          "type": "integer"
        },
        "volumeBaselineThroughput": {
          "type": "integer"
        },
        "volumeBurstThroughput": {
          "type": "integer"
        }
      }
    }
  }
}