{
  "pagination": {
    "DescribeGlobalNetworks": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults",
      "result_key": "GlobalNetworks"
    },
    "GetConnections": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults",
      "result_key": "Connections"
    },
    "GetCustomerGatewayAssociations": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults",
      "result_key": "CustomerGatewayAssociations"
    },
    "GetDevices": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults",
      "result_key": "Devices"
    },
    "GetLinkAssociations": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults",
      "result_key": "LinkAssociations"
    },
    "GetLinks": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults",
      "result_key": "Links"
    },
    "GetSites": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults",
      "result_key": "Sites"
    },
    "GetTransitGatewayConnectPeerAssociations": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults",
      "result_key": "TransitGatewayConnectPeerAssociations"
    },
    "GetTransitGatewayRegistrations": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults",
      "result_key": "TransitGatewayRegistrations"
    }
  }
}
