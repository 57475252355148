{
  "metadata": {
    "apiVersion": "2018-04-23",
    "endpointPrefix": "api.mediatailor",
    "jsonVersion": "1.1",
    "protocol": "rest-json",
    "serviceAbbreviation": "MediaTailor",
    "serviceFullName": "AWS MediaTailor",
    "serviceId": "MediaTailor",
    "signatureVersion": "v4",
    "signingName": "mediatailor",
    "uid": "mediatailor-2018-04-23"
  },
  "operations": {
    "ConfigureLogsForPlaybackConfiguration": {
      "http": {
        "method": "PUT",
        "requestUri": "/configureLogs/playbackConfiguration",
        "responseCode": 200
      },
      "input": {
        "members": {
          "PercentEnabled": {
            "type": "integer"
          },
          "PlaybackConfigurationName": {}
        },
        "required": [
          "PercentEnabled",
          "PlaybackConfigurationName"
        ],
        "type": "structure"
      },
      "output": {
        "members": {
          "PercentEnabled": {
            "type": "integer"
          },
          "PlaybackConfigurationName": {}
        },
        "type": "structure"
      }
    },
    "CreateChannel": {
      "http": {
        "requestUri": "/channel/{channelName}",
        "responseCode": 200
      },
      "input": {
        "members": {
          "ChannelName": {
            "location": "uri",
            "locationName": "channelName"
          },
          "FillerSlate": {
            "shape": "S6"
          },
          "Outputs": {
            "shape": "S7"
          },
          "PlaybackMode": {},
          "Tags": {
            "locationName": "tags",
            "shape": "Sc"
          }
        },
        "required": [
          "ChannelName",
          "Outputs",
          "PlaybackMode"
        ],
        "type": "structure"
      },
      "output": {
        "members": {
          "Arn": {},
          "ChannelName": {},
          "ChannelState": {},
          "CreationTime": {
            "shape": "Sf"
          },
          "FillerSlate": {
            "shape": "S6"
          },
          "LastModifiedTime": {
            "shape": "Sf"
          },
          "Outputs": {
            "shape": "Sg"
          },
          "PlaybackMode": {},
          "Tags": {
            "locationName": "tags",
            "shape": "Sc"
          }
        },
        "type": "structure"
      }
    },
    "CreatePrefetchSchedule": {
      "http": {
        "requestUri": "/prefetchSchedule/{PlaybackConfigurationName}/{Name}",
        "responseCode": 200
      },
      "input": {
        "members": {
          "Consumption": {
            "shape": "Sj"
          },
          "Name": {
            "location": "uri",
            "locationName": "Name"
          },
          "PlaybackConfigurationName": {
            "location": "uri",
            "locationName": "PlaybackConfigurationName"
          },
          "Retrieval": {
            "shape": "Sn"
          },
          "StreamId": {}
        },
        "required": [
          "Name",
          "PlaybackConfigurationName",
          "Consumption",
          "Retrieval"
        ],
        "type": "structure"
      },
      "output": {
        "members": {
          "Arn": {},
          "Consumption": {
            "shape": "Sj"
          },
          "Name": {},
          "PlaybackConfigurationName": {},
          "Retrieval": {
            "shape": "Sn"
          },
          "StreamId": {}
        },
        "type": "structure"
      }
    },
    "CreateProgram": {
      "http": {
        "requestUri": "/channel/{channelName}/program/{programName}",
        "responseCode": 200
      },
      "input": {
        "members": {
          "AdBreaks": {
            "shape": "Sq"
          },
          "ChannelName": {
            "location": "uri",
            "locationName": "channelName"
          },
          "ProgramName": {
            "location": "uri",
            "locationName": "programName"
          },
          "ScheduleConfiguration": {
            "members": {
              "Transition": {
                "members": {
                  "RelativePosition": {},
                  "RelativeProgram": {},
                  "ScheduledStartTimeMillis": {
                    "type": "long"
                  },
                  "Type": {}
                },
                "required": [
                  "Type",
                  "RelativePosition"
                ],
                "type": "structure"
              }
            },
            "required": [
              "Transition"
            ],
            "type": "structure"
          },
          "SourceLocationName": {},
          "VodSourceName": {}
        },
        "required": [
          "ChannelName",
          "ProgramName",
          "VodSourceName",
          "ScheduleConfiguration",
          "SourceLocationName"
        ],
        "type": "structure"
      },
      "output": {
        "members": {
          "AdBreaks": {
            "shape": "Sq"
          },
          "Arn": {},
          "ChannelName": {},
          "CreationTime": {
            "shape": "Sf"
          },
          "ProgramName": {},
          "ScheduledStartTime": {
            "shape": "Sf"
          },
          "SourceLocationName": {},
          "VodSourceName": {}
        },
        "type": "structure"
      }
    },
    "CreateSourceLocation": {
      "http": {
        "requestUri": "/sourceLocation/{sourceLocationName}",
        "responseCode": 200
      },
      "input": {
        "members": {
          "AccessConfiguration": {
            "shape": "S10"
          },
          "DefaultSegmentDeliveryConfiguration": {
            "shape": "S13"
          },
          "HttpConfiguration": {
            "shape": "S14"
          },
          "SourceLocationName": {
            "location": "uri",
            "locationName": "sourceLocationName"
          },
          "Tags": {
            "locationName": "tags",
            "shape": "Sc"
          }
        },
        "required": [
          "SourceLocationName",
          "HttpConfiguration"
        ],
        "type": "structure"
      },
      "output": {
        "members": {
          "AccessConfiguration": {
            "shape": "S10"
          },
          "Arn": {},
          "CreationTime": {
            "shape": "Sf"
          },
          "DefaultSegmentDeliveryConfiguration": {
            "shape": "S13"
          },
          "HttpConfiguration": {
            "shape": "S14"
          },
          "LastModifiedTime": {
            "shape": "Sf"
          },
          "SourceLocationName": {},
          "Tags": {
            "locationName": "tags",
            "shape": "Sc"
          }
        },
        "type": "structure"
      }
    },
    "CreateVodSource": {
      "http": {
        "requestUri": "/sourceLocation/{sourceLocationName}/vodSource/{vodSourceName}",
        "responseCode": 200
      },
      "input": {
        "members": {
          "HttpPackageConfigurations": {
            "shape": "S17"
          },
          "SourceLocationName": {
            "location": "uri",
            "locationName": "sourceLocationName"
          },
          "Tags": {
            "locationName": "tags",
            "shape": "Sc"
          },
          "VodSourceName": {
            "location": "uri",
            "locationName": "vodSourceName"
          }
        },
        "required": [
          "SourceLocationName",
          "VodSourceName",
          "HttpPackageConfigurations"
        ],
        "type": "structure"
      },
      "output": {
        "members": {
          "Arn": {},
          "CreationTime": {
            "shape": "Sf"
          },
          "HttpPackageConfigurations": {
            "shape": "S17"
          },
          "LastModifiedTime": {
            "shape": "Sf"
          },
          "SourceLocationName": {},
          "Tags": {
            "locationName": "tags",
            "shape": "Sc"
          },
          "VodSourceName": {}
        },
        "type": "structure"
      }
    },
    "DeleteChannel": {
      "http": {
        "method": "DELETE",
        "requestUri": "/channel/{channelName}",
        "responseCode": 200
      },
      "input": {
        "members": {
          "ChannelName": {
            "location": "uri",
            "locationName": "channelName"
          }
        },
        "required": [
          "ChannelName"
        ],
        "type": "structure"
      },
      "output": {
        "members": {},
        "type": "structure"
      }
    },
    "DeleteChannelPolicy": {
      "http": {
        "method": "DELETE",
        "requestUri": "/channel/{channelName}/policy",
        "responseCode": 200
      },
      "input": {
        "members": {
          "ChannelName": {
            "location": "uri",
            "locationName": "channelName"
          }
        },
        "required": [
          "ChannelName"
        ],
        "type": "structure"
      },
      "output": {
        "members": {},
        "type": "structure"
      }
    },
    "DeletePlaybackConfiguration": {
      "http": {
        "method": "DELETE",
        "requestUri": "/playbackConfiguration/{Name}",
        "responseCode": 204
      },
      "input": {
        "members": {
          "Name": {
            "location": "uri",
            "locationName": "Name"
          }
        },
        "required": [
          "Name"
        ],
        "type": "structure"
      },
      "output": {
        "members": {},
        "type": "structure"
      }
    },
    "DeletePrefetchSchedule": {
      "http": {
        "method": "DELETE",
        "requestUri": "/prefetchSchedule/{PlaybackConfigurationName}/{Name}",
        "responseCode": 204
      },
      "input": {
        "members": {
          "Name": {
            "location": "uri",
            "locationName": "Name"
          },
          "PlaybackConfigurationName": {
            "location": "uri",
            "locationName": "PlaybackConfigurationName"
          }
        },
        "required": [
          "Name",
          "PlaybackConfigurationName"
        ],
        "type": "structure"
      },
      "output": {
        "members": {},
        "type": "structure"
      }
    },
    "DeleteProgram": {
      "http": {
        "method": "DELETE",
        "requestUri": "/channel/{channelName}/program/{programName}",
        "responseCode": 200
      },
      "input": {
        "members": {
          "ChannelName": {
            "location": "uri",
            "locationName": "channelName"
          },
          "ProgramName": {
            "location": "uri",
            "locationName": "programName"
          }
        },
        "required": [
          "ChannelName",
          "ProgramName"
        ],
        "type": "structure"
      },
      "output": {
        "members": {},
        "type": "structure"
      }
    },
    "DeleteSourceLocation": {
      "http": {
        "method": "DELETE",
        "requestUri": "/sourceLocation/{sourceLocationName}",
        "responseCode": 200
      },
      "input": {
        "members": {
          "SourceLocationName": {
            "location": "uri",
            "locationName": "sourceLocationName"
          }
        },
        "required": [
          "SourceLocationName"
        ],
        "type": "structure"
      },
      "output": {
        "members": {},
        "type": "structure"
      }
    },
    "DeleteVodSource": {
      "http": {
        "method": "DELETE",
        "requestUri": "/sourceLocation/{sourceLocationName}/vodSource/{vodSourceName}",
        "responseCode": 200
      },
      "input": {
        "members": {
          "SourceLocationName": {
            "location": "uri",
            "locationName": "sourceLocationName"
          },
          "VodSourceName": {
            "location": "uri",
            "locationName": "vodSourceName"
          }
        },
        "required": [
          "SourceLocationName",
          "VodSourceName"
        ],
        "type": "structure"
      },
      "output": {
        "members": {},
        "type": "structure"
      }
    },
    "DescribeChannel": {
      "http": {
        "method": "GET",
        "requestUri": "/channel/{channelName}",
        "responseCode": 200
      },
      "input": {
        "members": {
          "ChannelName": {
            "location": "uri",
            "locationName": "channelName"
          }
        },
        "required": [
          "ChannelName"
        ],
        "type": "structure"
      },
      "output": {
        "members": {
          "Arn": {},
          "ChannelName": {},
          "ChannelState": {},
          "CreationTime": {
            "shape": "Sf"
          },
          "FillerSlate": {
            "shape": "S6"
          },
          "LastModifiedTime": {
            "shape": "Sf"
          },
          "Outputs": {
            "shape": "Sg"
          },
          "PlaybackMode": {},
          "Tags": {
            "locationName": "tags",
            "shape": "Sc"
          }
        },
        "type": "structure"
      }
    },
    "DescribeProgram": {
      "http": {
        "method": "GET",
        "requestUri": "/channel/{channelName}/program/{programName}",
        "responseCode": 200
      },
      "input": {
        "members": {
          "ChannelName": {
            "location": "uri",
            "locationName": "channelName"
          },
          "ProgramName": {
            "location": "uri",
            "locationName": "programName"
          }
        },
        "required": [
          "ChannelName",
          "ProgramName"
        ],
        "type": "structure"
      },
      "output": {
        "members": {
          "AdBreaks": {
            "shape": "Sq"
          },
          "Arn": {},
          "ChannelName": {},
          "CreationTime": {
            "shape": "Sf"
          },
          "ProgramName": {},
          "ScheduledStartTime": {
            "shape": "Sf"
          },
          "SourceLocationName": {},
          "VodSourceName": {}
        },
        "type": "structure"
      }
    },
    "DescribeSourceLocation": {
      "http": {
        "method": "GET",
        "requestUri": "/sourceLocation/{sourceLocationName}",
        "responseCode": 200
      },
      "input": {
        "members": {
          "SourceLocationName": {
            "location": "uri",
            "locationName": "sourceLocationName"
          }
        },
        "required": [
          "SourceLocationName"
        ],
        "type": "structure"
      },
      "output": {
        "members": {
          "AccessConfiguration": {
            "shape": "S10"
          },
          "Arn": {},
          "CreationTime": {
            "shape": "Sf"
          },
          "DefaultSegmentDeliveryConfiguration": {
            "shape": "S13"
          },
          "HttpConfiguration": {
            "shape": "S14"
          },
          "LastModifiedTime": {
            "shape": "Sf"
          },
          "SourceLocationName": {},
          "Tags": {
            "locationName": "tags",
            "shape": "Sc"
          }
        },
        "type": "structure"
      }
    },
    "DescribeVodSource": {
      "http": {
        "method": "GET",
        "requestUri": "/sourceLocation/{sourceLocationName}/vodSource/{vodSourceName}",
        "responseCode": 200
      },
      "input": {
        "members": {
          "SourceLocationName": {
            "location": "uri",
            "locationName": "sourceLocationName"
          },
          "VodSourceName": {
            "location": "uri",
            "locationName": "vodSourceName"
          }
        },
        "required": [
          "SourceLocationName",
          "VodSourceName"
        ],
        "type": "structure"
      },
      "output": {
        "members": {
          "Arn": {},
          "CreationTime": {
            "shape": "Sf"
          },
          "HttpPackageConfigurations": {
            "shape": "S17"
          },
          "LastModifiedTime": {
            "shape": "Sf"
          },
          "SourceLocationName": {},
          "Tags": {
            "locationName": "tags",
            "shape": "Sc"
          },
          "VodSourceName": {}
        },
        "type": "structure"
      }
    },
    "GetChannelPolicy": {
      "http": {
        "method": "GET",
        "requestUri": "/channel/{channelName}/policy",
        "responseCode": 200
      },
      "input": {
        "members": {
          "ChannelName": {
            "location": "uri",
            "locationName": "channelName"
          }
        },
        "required": [
          "ChannelName"
        ],
        "type": "structure"
      },
      "output": {
        "members": {
          "Policy": {}
        },
        "type": "structure"
      }
    },
    "GetChannelSchedule": {
      "http": {
        "method": "GET",
        "requestUri": "/channel/{channelName}/schedule",
        "responseCode": 200
      },
      "input": {
        "members": {
          "ChannelName": {
            "location": "uri",
            "locationName": "channelName"
          },
          "DurationMinutes": {
            "location": "querystring",
            "locationName": "durationMinutes"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          }
        },
        "required": [
          "ChannelName"
        ],
        "type": "structure"
      },
      "output": {
        "members": {
          "Items": {
            "member": {
              "members": {
                "ApproximateDurationSeconds": {
                  "type": "long"
                },
                "ApproximateStartTime": {
                  "shape": "Sf"
                },
                "Arn": {},
                "ChannelName": {},
                "ProgramName": {},
                "ScheduleAdBreaks": {
                  "member": {
                    "members": {
                      "ApproximateDurationSeconds": {
                        "type": "long"
                      },
                      "ApproximateStartTime": {
                        "shape": "Sf"
                      },
                      "SourceLocationName": {},
                      "VodSourceName": {}
                    },
                    "type": "structure"
                  },
                  "type": "list"
                },
                "ScheduleEntryType": {},
                "SourceLocationName": {},
                "VodSourceName": {}
              },
              "required": [
                "VodSourceName",
                "ChannelName",
                "SourceLocationName",
                "Arn",
                "ProgramName"
              ],
              "type": "structure"
            },
            "type": "list"
          },
          "NextToken": {}
        },
        "type": "structure"
      }
    },
    "GetPlaybackConfiguration": {
      "http": {
        "method": "GET",
        "requestUri": "/playbackConfiguration/{Name}",
        "responseCode": 200
      },
      "input": {
        "members": {
          "Name": {
            "location": "uri",
            "locationName": "Name"
          }
        },
        "required": [
          "Name"
        ],
        "type": "structure"
      },
      "output": {
        "members": {
          "AdDecisionServerUrl": {},
          "AvailSuppression": {
            "shape": "S29"
          },
          "Bumper": {
            "shape": "S2b"
          },
          "CdnConfiguration": {
            "shape": "S2c"
          },
          "ConfigurationAliases": {
            "shape": "S2d"
          },
          "DashConfiguration": {
            "shape": "S2e"
          },
          "HlsConfiguration": {
            "shape": "S2g"
          },
          "LivePreRollConfiguration": {
            "shape": "S2h"
          },
          "LogConfiguration": {
            "shape": "S2i"
          },
          "ManifestProcessingRules": {
            "shape": "S2j"
          },
          "Name": {},
          "PersonalizationThresholdSeconds": {
            "type": "integer"
          },
          "PlaybackConfigurationArn": {},
          "PlaybackEndpointPrefix": {},
          "SessionInitializationEndpointPrefix": {},
          "SlateAdUrl": {},
          "Tags": {
            "locationName": "tags",
            "shape": "Sc"
          },
          "TranscodeProfileName": {},
          "VideoContentSourceUrl": {}
        },
        "type": "structure"
      }
    },
    "GetPrefetchSchedule": {
      "http": {
        "method": "GET",
        "requestUri": "/prefetchSchedule/{PlaybackConfigurationName}/{Name}",
        "responseCode": 200
      },
      "input": {
        "members": {
          "Name": {
            "location": "uri",
            "locationName": "Name"
          },
          "PlaybackConfigurationName": {
            "location": "uri",
            "locationName": "PlaybackConfigurationName"
          }
        },
        "required": [
          "Name",
          "PlaybackConfigurationName"
        ],
        "type": "structure"
      },
      "output": {
        "members": {
          "Arn": {},
          "Consumption": {
            "shape": "Sj"
          },
          "Name": {},
          "PlaybackConfigurationName": {},
          "Retrieval": {
            "shape": "Sn"
          },
          "StreamId": {}
        },
        "type": "structure"
      }
    },
    "ListAlerts": {
      "http": {
        "method": "GET",
        "requestUri": "/alerts",
        "responseCode": 200
      },
      "input": {
        "members": {
          "MaxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "ResourceArn": {
            "location": "querystring",
            "locationName": "resourceArn"
          }
        },
        "required": [
          "ResourceArn"
        ],
        "type": "structure"
      },
      "output": {
        "members": {
          "Items": {
            "member": {
              "members": {
                "AlertCode": {},
                "AlertMessage": {},
                "LastModifiedTime": {
                  "shape": "Sf"
                },
                "RelatedResourceArns": {
                  "shape": "S2t"
                },
                "ResourceArn": {}
              },
              "required": [
                "ResourceArn",
                "AlertCode",
                "LastModifiedTime",
                "RelatedResourceArns",
                "AlertMessage"
              ],
              "type": "structure"
            },
            "type": "list"
          },
          "NextToken": {}
        },
        "type": "structure"
      }
    },
    "ListChannels": {
      "http": {
        "method": "GET",
        "requestUri": "/channels",
        "responseCode": 200
      },
      "input": {
        "members": {
          "MaxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          }
        },
        "type": "structure"
      },
      "output": {
        "members": {
          "Items": {
            "member": {
              "members": {
                "Arn": {},
                "ChannelName": {},
                "ChannelState": {},
                "CreationTime": {
                  "shape": "Sf"
                },
                "FillerSlate": {
                  "shape": "S6"
                },
                "LastModifiedTime": {
                  "shape": "Sf"
                },
                "Outputs": {
                  "shape": "Sg"
                },
                "PlaybackMode": {},
                "Tags": {
                  "locationName": "tags",
                  "shape": "Sc"
                }
              },
              "required": [
                "ChannelState",
                "ChannelName",
                "Outputs",
                "Arn",
                "PlaybackMode"
              ],
              "type": "structure"
            },
            "type": "list"
          },
          "NextToken": {}
        },
        "type": "structure"
      }
    },
    "ListPlaybackConfigurations": {
      "http": {
        "method": "GET",
        "requestUri": "/playbackConfigurations",
        "responseCode": 200
      },
      "input": {
        "members": {
          "MaxResults": {
            "location": "querystring",
            "locationName": "MaxResults",
            "type": "integer"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "NextToken"
          }
        },
        "type": "structure"
      },
      "output": {
        "members": {
          "Items": {
            "member": {
              "members": {
                "AdDecisionServerUrl": {},
                "AvailSuppression": {
                  "shape": "S29"
                },
                "Bumper": {
                  "shape": "S2b"
                },
                "CdnConfiguration": {
                  "shape": "S2c"
                },
                "ConfigurationAliases": {
                  "shape": "S2d"
                },
                "DashConfiguration": {
                  "shape": "S2e"
                },
                "HlsConfiguration": {
                  "shape": "S2g"
                },
                "LivePreRollConfiguration": {
                  "shape": "S2h"
                },
                "LogConfiguration": {
                  "shape": "S2i"
                },
                "ManifestProcessingRules": {
                  "shape": "S2j"
                },
                "Name": {},
                "PersonalizationThresholdSeconds": {
                  "type": "integer"
                },
                "PlaybackConfigurationArn": {},
                "PlaybackEndpointPrefix": {},
                "SessionInitializationEndpointPrefix": {},
                "SlateAdUrl": {},
                "Tags": {
                  "locationName": "tags",
                  "shape": "Sc"
                },
                "TranscodeProfileName": {},
                "VideoContentSourceUrl": {}
              },
              "type": "structure"
            },
            "type": "list"
          },
          "NextToken": {}
        },
        "type": "structure"
      }
    },
    "ListPrefetchSchedules": {
      "http": {
        "requestUri": "/prefetchSchedule/{PlaybackConfigurationName}",
        "responseCode": 200
      },
      "input": {
        "members": {
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {},
          "PlaybackConfigurationName": {
            "location": "uri",
            "locationName": "PlaybackConfigurationName"
          },
          "StreamId": {}
        },
        "required": [
          "PlaybackConfigurationName"
        ],
        "type": "structure"
      },
      "output": {
        "members": {
          "Items": {
            "member": {
              "members": {
                "Arn": {},
                "Consumption": {
                  "shape": "Sj"
                },
                "Name": {},
                "PlaybackConfigurationName": {},
                "Retrieval": {
                  "shape": "Sn"
                },
                "StreamId": {}
              },
              "required": [
                "Retrieval",
                "Consumption",
                "Arn",
                "PlaybackConfigurationName",
                "Name"
              ],
              "type": "structure"
            },
            "type": "list"
          },
          "NextToken": {}
        },
        "type": "structure"
      }
    },
    "ListSourceLocations": {
      "http": {
        "method": "GET",
        "requestUri": "/sourceLocations",
        "responseCode": 200
      },
      "input": {
        "members": {
          "MaxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          }
        },
        "type": "structure"
      },
      "output": {
        "members": {
          "Items": {
            "member": {
              "members": {
                "AccessConfiguration": {
                  "shape": "S10"
                },
                "Arn": {},
                "CreationTime": {
                  "shape": "Sf"
                },
                "DefaultSegmentDeliveryConfiguration": {
                  "shape": "S13"
                },
                "HttpConfiguration": {
                  "shape": "S14"
                },
                "LastModifiedTime": {
                  "shape": "Sf"
                },
                "SourceLocationName": {},
                "Tags": {
                  "locationName": "tags",
                  "shape": "Sc"
                }
              },
              "required": [
                "SourceLocationName",
                "HttpConfiguration",
                "Arn"
              ],
              "type": "structure"
            },
            "type": "list"
          },
          "NextToken": {}
        },
        "type": "structure"
      }
    },
    "ListTagsForResource": {
      "http": {
        "method": "GET",
        "requestUri": "/tags/{ResourceArn}",
        "responseCode": 200
      },
      "input": {
        "members": {
          "ResourceArn": {
            "location": "uri",
            "locationName": "ResourceArn"
          }
        },
        "required": [
          "ResourceArn"
        ],
        "type": "structure"
      },
      "output": {
        "members": {
          "Tags": {
            "locationName": "tags",
            "shape": "Sc"
          }
        },
        "type": "structure"
      }
    },
    "ListVodSources": {
      "http": {
        "method": "GET",
        "requestUri": "/sourceLocation/{sourceLocationName}/vodSources",
        "responseCode": 200
      },
      "input": {
        "members": {
          "MaxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "SourceLocationName": {
            "location": "uri",
            "locationName": "sourceLocationName"
          }
        },
        "required": [
          "SourceLocationName"
        ],
        "type": "structure"
      },
      "output": {
        "members": {
          "Items": {
            "member": {
              "members": {
                "Arn": {},
                "CreationTime": {
                  "shape": "Sf"
                },
                "HttpPackageConfigurations": {
                  "shape": "S17"
                },
                "LastModifiedTime": {
                  "shape": "Sf"
                },
                "SourceLocationName": {},
                "Tags": {
                  "locationName": "tags",
                  "shape": "Sc"
                },
                "VodSourceName": {}
              },
              "required": [
                "VodSourceName",
                "SourceLocationName",
                "HttpPackageConfigurations",
                "Arn"
              ],
              "type": "structure"
            },
            "type": "list"
          },
          "NextToken": {}
        },
        "type": "structure"
      }
    },
    "PutChannelPolicy": {
      "http": {
        "method": "PUT",
        "requestUri": "/channel/{channelName}/policy",
        "responseCode": 200
      },
      "input": {
        "members": {
          "ChannelName": {
            "location": "uri",
            "locationName": "channelName"
          },
          "Policy": {}
        },
        "required": [
          "ChannelName",
          "Policy"
        ],
        "type": "structure"
      },
      "output": {
        "members": {},
        "type": "structure"
      }
    },
    "PutPlaybackConfiguration": {
      "http": {
        "method": "PUT",
        "requestUri": "/playbackConfiguration",
        "responseCode": 200
      },
      "input": {
        "members": {
          "AdDecisionServerUrl": {},
          "AvailSuppression": {
            "shape": "S29"
          },
          "Bumper": {
            "shape": "S2b"
          },
          "CdnConfiguration": {
            "shape": "S2c"
          },
          "ConfigurationAliases": {
            "key": {},
            "type": "map",
            "value": {
              "shape": "Sc"
            }
          },
          "DashConfiguration": {
            "members": {
              "MpdLocation": {},
              "OriginManifestType": {}
            },
            "type": "structure"
          },
          "LivePreRollConfiguration": {
            "shape": "S2h"
          },
          "ManifestProcessingRules": {
            "shape": "S2j"
          },
          "Name": {},
          "PersonalizationThresholdSeconds": {
            "type": "integer"
          },
          "SlateAdUrl": {},
          "Tags": {
            "locationName": "tags",
            "shape": "Sc"
          },
          "TranscodeProfileName": {},
          "VideoContentSourceUrl": {}
        },
        "type": "structure"
      },
      "output": {
        "members": {
          "AdDecisionServerUrl": {},
          "AvailSuppression": {
            "shape": "S29"
          },
          "Bumper": {
            "shape": "S2b"
          },
          "CdnConfiguration": {
            "shape": "S2c"
          },
          "ConfigurationAliases": {
            "shape": "S2d"
          },
          "DashConfiguration": {
            "shape": "S2e"
          },
          "HlsConfiguration": {
            "shape": "S2g"
          },
          "LivePreRollConfiguration": {
            "shape": "S2h"
          },
          "LogConfiguration": {
            "shape": "S2i"
          },
          "ManifestProcessingRules": {
            "shape": "S2j"
          },
          "Name": {},
          "PersonalizationThresholdSeconds": {
            "type": "integer"
          },
          "PlaybackConfigurationArn": {},
          "PlaybackEndpointPrefix": {},
          "SessionInitializationEndpointPrefix": {},
          "SlateAdUrl": {},
          "Tags": {
            "locationName": "tags",
            "shape": "Sc"
          },
          "TranscodeProfileName": {},
          "VideoContentSourceUrl": {}
        },
        "type": "structure"
      }
    },
    "StartChannel": {
      "http": {
        "method": "PUT",
        "requestUri": "/channel/{channelName}/start",
        "responseCode": 200
      },
      "input": {
        "members": {
          "ChannelName": {
            "location": "uri",
            "locationName": "channelName"
          }
        },
        "required": [
          "ChannelName"
        ],
        "type": "structure"
      },
      "output": {
        "members": {},
        "type": "structure"
      }
    },
    "StopChannel": {
      "http": {
        "method": "PUT",
        "requestUri": "/channel/{channelName}/stop",
        "responseCode": 200
      },
      "input": {
        "members": {
          "ChannelName": {
            "location": "uri",
            "locationName": "channelName"
          }
        },
        "required": [
          "ChannelName"
        ],
        "type": "structure"
      },
      "output": {
        "members": {},
        "type": "structure"
      }
    },
    "TagResource": {
      "http": {
        "requestUri": "/tags/{ResourceArn}",
        "responseCode": 204
      },
      "input": {
        "members": {
          "ResourceArn": {
            "location": "uri",
            "locationName": "ResourceArn"
          },
          "Tags": {
            "locationName": "tags",
            "shape": "Sc"
          }
        },
        "required": [
          "ResourceArn",
          "Tags"
        ],
        "type": "structure"
      }
    },
    "UntagResource": {
      "http": {
        "method": "DELETE",
        "requestUri": "/tags/{ResourceArn}",
        "responseCode": 204
      },
      "input": {
        "members": {
          "ResourceArn": {
            "location": "uri",
            "locationName": "ResourceArn"
          },
          "TagKeys": {
            "location": "querystring",
            "locationName": "tagKeys",
            "shape": "S2t"
          }
        },
        "required": [
          "ResourceArn",
          "TagKeys"
        ],
        "type": "structure"
      }
    },
    "UpdateChannel": {
      "http": {
        "method": "PUT",
        "requestUri": "/channel/{channelName}",
        "responseCode": 200
      },
      "input": {
        "members": {
          "ChannelName": {
            "location": "uri",
            "locationName": "channelName"
          },
          "Outputs": {
            "shape": "S7"
          }
        },
        "required": [
          "ChannelName",
          "Outputs"
        ],
        "type": "structure"
      },
      "output": {
        "members": {
          "Arn": {},
          "ChannelName": {},
          "ChannelState": {},
          "CreationTime": {
            "shape": "Sf"
          },
          "FillerSlate": {
            "shape": "S6"
          },
          "LastModifiedTime": {
            "shape": "Sf"
          },
          "Outputs": {
            "shape": "Sg"
          },
          "PlaybackMode": {},
          "Tags": {
            "locationName": "tags",
            "shape": "Sc"
          }
        },
        "type": "structure"
      }
    },
    "UpdateSourceLocation": {
      "http": {
        "method": "PUT",
        "requestUri": "/sourceLocation/{sourceLocationName}",
        "responseCode": 200
      },
      "input": {
        "members": {
          "AccessConfiguration": {
            "shape": "S10"
          },
          "DefaultSegmentDeliveryConfiguration": {
            "shape": "S13"
          },
          "HttpConfiguration": {
            "shape": "S14"
          },
          "SourceLocationName": {
            "location": "uri",
            "locationName": "sourceLocationName"
          }
        },
        "required": [
          "SourceLocationName",
          "HttpConfiguration"
        ],
        "type": "structure"
      },
      "output": {
        "members": {
          "AccessConfiguration": {
            "shape": "S10"
          },
          "Arn": {},
          "CreationTime": {
            "shape": "Sf"
          },
          "DefaultSegmentDeliveryConfiguration": {
            "shape": "S13"
          },
          "HttpConfiguration": {
            "shape": "S14"
          },
          "LastModifiedTime": {
            "shape": "Sf"
          },
          "SourceLocationName": {},
          "Tags": {
            "locationName": "tags",
            "shape": "Sc"
          }
        },
        "type": "structure"
      }
    },
    "UpdateVodSource": {
      "http": {
        "method": "PUT",
        "requestUri": "/sourceLocation/{sourceLocationName}/vodSource/{vodSourceName}",
        "responseCode": 200
      },
      "input": {
        "members": {
          "HttpPackageConfigurations": {
            "shape": "S17"
          },
          "SourceLocationName": {
            "location": "uri",
            "locationName": "sourceLocationName"
          },
          "VodSourceName": {
            "location": "uri",
            "locationName": "vodSourceName"
          }
        },
        "required": [
          "SourceLocationName",
          "VodSourceName",
          "HttpPackageConfigurations"
        ],
        "type": "structure"
      },
      "output": {
        "members": {
          "Arn": {},
          "CreationTime": {
            "shape": "Sf"
          },
          "HttpPackageConfigurations": {
            "shape": "S17"
          },
          "LastModifiedTime": {
            "shape": "Sf"
          },
          "SourceLocationName": {},
          "Tags": {
            "locationName": "tags",
            "shape": "Sc"
          },
          "VodSourceName": {}
        },
        "type": "structure"
      }
    }
  },
  "shapes": {
    "S6": {
      "members": {
        "SourceLocationName": {},
        "VodSourceName": {}
      },
      "type": "structure"
    },
    "S7": {
      "member": {
        "members": {
          "DashPlaylistSettings": {
            "shape": "S9"
          },
          "HlsPlaylistSettings": {
            "shape": "Sa"
          },
          "ManifestName": {},
          "SourceGroup": {}
        },
        "required": [
          "ManifestName",
          "SourceGroup"
        ],
        "type": "structure"
      },
      "type": "list"
    },
    "S9": {
      "members": {
        "ManifestWindowSeconds": {
          "type": "integer"
        },
        "MinBufferTimeSeconds": {
          "type": "integer"
        },
        "MinUpdatePeriodSeconds": {
          "type": "integer"
        },
        "SuggestedPresentationDelaySeconds": {
          "type": "integer"
        }
      },
      "type": "structure"
    },
    "Sa": {
      "members": {
        "ManifestWindowSeconds": {
          "type": "integer"
        }
      },
      "type": "structure"
    },
    "Sc": {
      "key": {},
      "type": "map",
      "value": {}
    },
    "Sf": {
      "timestampFormat": "unixTimestamp",
      "type": "timestamp"
    },
    "Sg": {
      "member": {
        "members": {
          "DashPlaylistSettings": {
            "shape": "S9"
          },
          "HlsPlaylistSettings": {
            "shape": "Sa"
          },
          "ManifestName": {},
          "PlaybackUrl": {},
          "SourceGroup": {}
        },
        "required": [
          "ManifestName",
          "PlaybackUrl",
          "SourceGroup"
        ],
        "type": "structure"
      },
      "type": "list"
    },
    "Sj": {
      "members": {
        "AvailMatchingCriteria": {
          "member": {
            "members": {
              "DynamicVariable": {},
              "Operator": {}
            },
            "required": [
              "Operator",
              "DynamicVariable"
            ],
            "type": "structure"
          },
          "type": "list"
        },
        "EndTime": {
          "shape": "Sf"
        },
        "StartTime": {
          "shape": "Sf"
        }
      },
      "required": [
        "EndTime"
      ],
      "type": "structure"
    },
    "Sn": {
      "members": {
        "DynamicVariables": {
          "shape": "Sc"
        },
        "EndTime": {
          "shape": "Sf"
        },
        "StartTime": {
          "shape": "Sf"
        }
      },
      "required": [
        "EndTime"
      ],
      "type": "structure"
    },
    "Sq": {
      "member": {
        "members": {
          "MessageType": {},
          "OffsetMillis": {
            "type": "long"
          },
          "Slate": {
            "shape": "S6"
          },
          "SpliceInsertMessage": {
            "members": {
              "AvailNum": {
                "type": "integer"
              },
              "AvailsExpected": {
                "type": "integer"
              },
              "SpliceEventId": {
                "type": "integer"
              },
              "UniqueProgramId": {
                "type": "integer"
              }
            },
            "type": "structure"
          }
        },
        "type": "structure"
      },
      "type": "list"
    },
    "S10": {
      "members": {
        "AccessType": {},
        "SecretsManagerAccessTokenConfiguration": {
          "members": {
            "HeaderName": {},
            "SecretArn": {},
            "SecretStringKey": {}
          },
          "type": "structure"
        }
      },
      "type": "structure"
    },
    "S13": {
      "members": {
        "BaseUrl": {}
      },
      "type": "structure"
    },
    "S14": {
      "members": {
        "BaseUrl": {}
      },
      "required": [
        "BaseUrl"
      ],
      "type": "structure"
    },
    "S17": {
      "member": {
        "members": {
          "Path": {},
          "SourceGroup": {},
          "Type": {}
        },
        "required": [
          "Path",
          "Type",
          "SourceGroup"
        ],
        "type": "structure"
      },
      "type": "list"
    },
    "S29": {
      "members": {
        "Mode": {},
        "Value": {}
      },
      "type": "structure"
    },
    "S2b": {
      "members": {
        "EndUrl": {},
        "StartUrl": {}
      },
      "type": "structure"
    },
    "S2c": {
      "members": {
        "AdSegmentUrlPrefix": {},
        "ContentSegmentUrlPrefix": {}
      },
      "type": "structure"
    },
    "S2d": {
      "key": {},
      "type": "map",
      "value": {
        "shape": "Sc"
      }
    },
    "S2e": {
      "members": {
        "ManifestEndpointPrefix": {},
        "MpdLocation": {},
        "OriginManifestType": {}
      },
      "type": "structure"
    },
    "S2g": {
      "members": {
        "ManifestEndpointPrefix": {}
      },
      "type": "structure"
    },
    "S2h": {
      "members": {
        "AdDecisionServerUrl": {},
        "MaxDurationSeconds": {
          "type": "integer"
        }
      },
      "type": "structure"
    },
    "S2i": {
      "members": {
        "PercentEnabled": {
          "type": "integer"
        }
      },
      "required": [
        "PercentEnabled"
      ],
      "type": "structure"
    },
    "S2j": {
      "members": {
        "AdMarkerPassthrough": {
          "members": {
            "Enabled": {
              "type": "boolean"
            }
          },
          "type": "structure"
        }
      },
      "type": "structure"
    },
    "S2t": {
      "member": {},
      "type": "list"
    }
  }
}