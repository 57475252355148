{
  "version": "2.0",
  "metadata": {
    "apiVersion": "2015-01-01",
    "endpointPrefix": "es",
    "protocol": "rest-json",
    "serviceFullName": "Amazon Elasticsearch Service",
    "serviceId": "Elasticsearch Service",
    "signatureVersion": "v4",
    "uid": "es-2015-01-01"
  },
  "operations": {
    "AcceptInboundCrossClusterSearchConnection": {
      "http": {
        "method": "PUT",
        "requestUri": "/2015-01-01/es/ccs/inboundConnection/{ConnectionId}/accept"
      },
      "input": {
        "type": "structure",
        "required": [
          "CrossClusterSearchConnectionId"
        ],
        "members": {
          "CrossClusterSearchConnectionId": {
            "location": "uri",
            "locationName": "ConnectionId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "CrossClusterSearchConnection": {
            "shape": "S4"
          }
        }
      }
    },
    "AddTags": {
      "http": {
        "requestUri": "/2015-01-01/tags"
      },
      "input": {
        "type": "structure",
        "required": [
          "ARN",
          "TagList"
        ],
        "members": {
          "ARN": {},
          "TagList": {
            "shape": "Se"
          }
        }
      }
    },
    "AssociatePackage": {
      "http": {
        "requestUri": "/2015-01-01/packages/associate/{PackageID}/{DomainName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "PackageID",
          "DomainName"
        ],
        "members": {
          "PackageID": {
            "location": "uri",
            "locationName": "PackageID"
          },
          "DomainName": {
            "location": "uri",
            "locationName": "DomainName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DomainPackageDetails": {
            "shape": "Sl"
          }
        }
      }
    },
    "CancelElasticsearchServiceSoftwareUpdate": {
      "http": {
        "requestUri": "/2015-01-01/es/serviceSoftwareUpdate/cancel"
      },
      "input": {
        "type": "structure",
        "required": [
          "DomainName"
        ],
        "members": {
          "DomainName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ServiceSoftwareOptions": {
            "shape": "Sx"
          }
        }
      }
    },
    "CreateElasticsearchDomain": {
      "http": {
        "requestUri": "/2015-01-01/es/domain"
      },
      "input": {
        "type": "structure",
        "required": [
          "DomainName"
        ],
        "members": {
          "DomainName": {},
          "ElasticsearchVersion": {},
          "ElasticsearchClusterConfig": {
            "shape": "S14"
          },
          "EBSOptions": {
            "shape": "S1a"
          },
          "AccessPolicies": {},
          "SnapshotOptions": {
            "shape": "S1d"
          },
          "VPCOptions": {
            "shape": "S1e"
          },
          "CognitoOptions": {
            "shape": "S1g"
          },
          "EncryptionAtRestOptions": {
            "shape": "S1k"
          },
          "NodeToNodeEncryptionOptions": {
            "shape": "S1m"
          },
          "AdvancedOptions": {
            "shape": "S1n"
          },
          "LogPublishingOptions": {
            "shape": "S1o"
          },
          "DomainEndpointOptions": {
            "shape": "S1s"
          },
          "AdvancedSecurityOptions": {
            "shape": "S1v"
          },
          "AutoTuneOptions": {
            "type": "structure",
            "members": {
              "DesiredState": {},
              "MaintenanceSchedules": {
                "shape": "S26"
              }
            }
          },
          "TagList": {
            "shape": "Se"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DomainStatus": {
            "shape": "S2d"
          }
        }
      }
    },
    "CreateOutboundCrossClusterSearchConnection": {
      "http": {
        "requestUri": "/2015-01-01/es/ccs/outboundConnection"
      },
      "input": {
        "type": "structure",
        "required": [
          "SourceDomainInfo",
          "DestinationDomainInfo",
          "ConnectionAlias"
        ],
        "members": {
          "SourceDomainInfo": {
            "shape": "S5"
          },
          "DestinationDomainInfo": {
            "shape": "S5"
          },
          "ConnectionAlias": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "SourceDomainInfo": {
            "shape": "S5"
          },
          "DestinationDomainInfo": {
            "shape": "S5"
          },
          "ConnectionAlias": {},
          "ConnectionStatus": {
            "shape": "S2p"
          },
          "CrossClusterSearchConnectionId": {}
        }
      }
    },
    "CreatePackage": {
      "http": {
        "requestUri": "/2015-01-01/packages"
      },
      "input": {
        "type": "structure",
        "required": [
          "PackageName",
          "PackageType",
          "PackageSource"
        ],
        "members": {
          "PackageName": {},
          "PackageType": {},
          "PackageDescription": {},
          "PackageSource": {
            "shape": "S2t"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "PackageDetails": {
            "shape": "S2x"
          }
        }
      }
    },
    "DeleteElasticsearchDomain": {
      "http": {
        "method": "DELETE",
        "requestUri": "/2015-01-01/es/domain/{DomainName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "DomainName"
        ],
        "members": {
          "DomainName": {
            "location": "uri",
            "locationName": "DomainName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DomainStatus": {
            "shape": "S2d"
          }
        }
      }
    },
    "DeleteElasticsearchServiceRole": {
      "http": {
        "method": "DELETE",
        "requestUri": "/2015-01-01/es/role"
      }
    },
    "DeleteInboundCrossClusterSearchConnection": {
      "http": {
        "method": "DELETE",
        "requestUri": "/2015-01-01/es/ccs/inboundConnection/{ConnectionId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "CrossClusterSearchConnectionId"
        ],
        "members": {
          "CrossClusterSearchConnectionId": {
            "location": "uri",
            "locationName": "ConnectionId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "CrossClusterSearchConnection": {
            "shape": "S4"
          }
        }
      }
    },
    "DeleteOutboundCrossClusterSearchConnection": {
      "http": {
        "method": "DELETE",
        "requestUri": "/2015-01-01/es/ccs/outboundConnection/{ConnectionId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "CrossClusterSearchConnectionId"
        ],
        "members": {
          "CrossClusterSearchConnectionId": {
            "location": "uri",
            "locationName": "ConnectionId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "CrossClusterSearchConnection": {
            "shape": "S36"
          }
        }
      }
    },
    "DeletePackage": {
      "http": {
        "method": "DELETE",
        "requestUri": "/2015-01-01/packages/{PackageID}"
      },
      "input": {
        "type": "structure",
        "required": [
          "PackageID"
        ],
        "members": {
          "PackageID": {
            "location": "uri",
            "locationName": "PackageID"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "PackageDetails": {
            "shape": "S2x"
          }
        }
      }
    },
    "DescribeDomainAutoTunes": {
      "http": {
        "method": "GET",
        "requestUri": "/2015-01-01/es/domain/{DomainName}/autoTunes"
      },
      "input": {
        "type": "structure",
        "required": [
          "DomainName"
        ],
        "members": {
          "DomainName": {
            "location": "uri",
            "locationName": "DomainName"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AutoTunes": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "AutoTuneType": {},
                "AutoTuneDetails": {
                  "type": "structure",
                  "members": {
                    "ScheduledAutoTuneDetails": {
                      "type": "structure",
                      "members": {
                        "Date": {
                          "type": "timestamp"
                        },
                        "ActionType": {},
                        "Action": {},
                        "Severity": {}
                      }
                    }
                  }
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribeElasticsearchDomain": {
      "http": {
        "method": "GET",
        "requestUri": "/2015-01-01/es/domain/{DomainName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "DomainName"
        ],
        "members": {
          "DomainName": {
            "location": "uri",
            "locationName": "DomainName"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "DomainStatus"
        ],
        "members": {
          "DomainStatus": {
            "shape": "S2d"
          }
        }
      }
    },
    "DescribeElasticsearchDomainConfig": {
      "http": {
        "method": "GET",
        "requestUri": "/2015-01-01/es/domain/{DomainName}/config"
      },
      "input": {
        "type": "structure",
        "required": [
          "DomainName"
        ],
        "members": {
          "DomainName": {
            "location": "uri",
            "locationName": "DomainName"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "DomainConfig"
        ],
        "members": {
          "DomainConfig": {
            "shape": "S3q"
          }
        }
      }
    },
    "DescribeElasticsearchDomains": {
      "http": {
        "requestUri": "/2015-01-01/es/domain-info"
      },
      "input": {
        "type": "structure",
        "required": [
          "DomainNames"
        ],
        "members": {
          "DomainNames": {
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "DomainStatusList"
        ],
        "members": {
          "DomainStatusList": {
            "type": "list",
            "member": {
              "shape": "S2d"
            }
          }
        }
      }
    },
    "DescribeElasticsearchInstanceTypeLimits": {
      "http": {
        "method": "GET",
        "requestUri": "/2015-01-01/es/instanceTypeLimits/{ElasticsearchVersion}/{InstanceType}"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceType",
          "ElasticsearchVersion"
        ],
        "members": {
          "DomainName": {
            "location": "querystring",
            "locationName": "domainName"
          },
          "InstanceType": {
            "location": "uri",
            "locationName": "InstanceType"
          },
          "ElasticsearchVersion": {
            "location": "uri",
            "locationName": "ElasticsearchVersion"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "LimitsByRole": {
            "type": "map",
            "key": {},
            "value": {
              "type": "structure",
              "members": {
                "StorageTypes": {
                  "type": "list",
                  "member": {
                    "type": "structure",
                    "members": {
                      "StorageTypeName": {},
                      "StorageSubTypeName": {},
                      "StorageTypeLimits": {
                        "type": "list",
                        "member": {
                          "type": "structure",
                          "members": {
                            "LimitName": {},
                            "LimitValues": {
                              "shape": "S4s"
                            }
                          }
                        }
                      }
                    }
                  }
                },
                "InstanceLimits": {
                  "type": "structure",
                  "members": {
                    "InstanceCountLimits": {
                      "type": "structure",
                      "members": {
                        "MinimumInstanceCount": {
                          "type": "integer"
                        },
                        "MaximumInstanceCount": {
                          "type": "integer"
                        }
                      }
                    }
                  }
                },
                "AdditionalLimits": {
                  "type": "list",
                  "member": {
                    "type": "structure",
                    "members": {
                      "LimitName": {},
                      "LimitValues": {
                        "shape": "S4s"
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    },
    "DescribeInboundCrossClusterSearchConnections": {
      "http": {
        "requestUri": "/2015-01-01/es/ccs/inboundConnection/search"
      },
      "input": {
        "type": "structure",
        "members": {
          "Filters": {
            "shape": "S51"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "CrossClusterSearchConnections": {
            "type": "list",
            "member": {
              "shape": "S4"
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribeOutboundCrossClusterSearchConnections": {
      "http": {
        "requestUri": "/2015-01-01/es/ccs/outboundConnection/search"
      },
      "input": {
        "type": "structure",
        "members": {
          "Filters": {
            "shape": "S51"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "CrossClusterSearchConnections": {
            "type": "list",
            "member": {
              "shape": "S36"
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribePackages": {
      "http": {
        "requestUri": "/2015-01-01/packages/describe"
      },
      "input": {
        "type": "structure",
        "members": {
          "Filters": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Name": {},
                "Value": {
                  "type": "list",
                  "member": {}
                }
              }
            }
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "PackageDetailsList": {
            "type": "list",
            "member": {
              "shape": "S2x"
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribeReservedElasticsearchInstanceOfferings": {
      "http": {
        "method": "GET",
        "requestUri": "/2015-01-01/es/reservedInstanceOfferings"
      },
      "input": {
        "type": "structure",
        "members": {
          "ReservedElasticsearchInstanceOfferingId": {
            "location": "querystring",
            "locationName": "offeringId"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "ReservedElasticsearchInstanceOfferings": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "ReservedElasticsearchInstanceOfferingId": {},
                "ElasticsearchInstanceType": {},
                "Duration": {
                  "type": "integer"
                },
                "FixedPrice": {
                  "type": "double"
                },
                "UsagePrice": {
                  "type": "double"
                },
                "CurrencyCode": {},
                "PaymentOption": {},
                "RecurringCharges": {
                  "shape": "S5q"
                }
              }
            }
          }
        }
      }
    },
    "DescribeReservedElasticsearchInstances": {
      "http": {
        "method": "GET",
        "requestUri": "/2015-01-01/es/reservedInstances"
      },
      "input": {
        "type": "structure",
        "members": {
          "ReservedElasticsearchInstanceId": {
            "location": "querystring",
            "locationName": "reservationId"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "ReservedElasticsearchInstances": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "ReservationName": {},
                "ReservedElasticsearchInstanceId": {},
                "ReservedElasticsearchInstanceOfferingId": {},
                "ElasticsearchInstanceType": {},
                "StartTime": {
                  "type": "timestamp"
                },
                "Duration": {
                  "type": "integer"
                },
                "FixedPrice": {
                  "type": "double"
                },
                "UsagePrice": {
                  "type": "double"
                },
                "CurrencyCode": {},
                "ElasticsearchInstanceCount": {
                  "type": "integer"
                },
                "State": {},
                "PaymentOption": {},
                "RecurringCharges": {
                  "shape": "S5q"
                }
              }
            }
          }
        }
      }
    },
    "DissociatePackage": {
      "http": {
        "requestUri": "/2015-01-01/packages/dissociate/{PackageID}/{DomainName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "PackageID",
          "DomainName"
        ],
        "members": {
          "PackageID": {
            "location": "uri",
            "locationName": "PackageID"
          },
          "DomainName": {
            "location": "uri",
            "locationName": "DomainName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DomainPackageDetails": {
            "shape": "Sl"
          }
        }
      }
    },
    "GetCompatibleElasticsearchVersions": {
      "http": {
        "method": "GET",
        "requestUri": "/2015-01-01/es/compatibleVersions"
      },
      "input": {
        "type": "structure",
        "members": {
          "DomainName": {
            "location": "querystring",
            "locationName": "domainName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "CompatibleElasticsearchVersions": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "SourceVersion": {},
                "TargetVersions": {
                  "shape": "S63"
                }
              }
            }
          }
        }
      }
    },
    "GetPackageVersionHistory": {
      "http": {
        "method": "GET",
        "requestUri": "/2015-01-01/packages/{PackageID}/history"
      },
      "input": {
        "type": "structure",
        "required": [
          "PackageID"
        ],
        "members": {
          "PackageID": {
            "location": "uri",
            "locationName": "PackageID"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "PackageID": {},
          "PackageVersionHistoryList": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "PackageVersion": {},
                "CommitMessage": {},
                "CreatedAt": {
                  "type": "timestamp"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "GetUpgradeHistory": {
      "http": {
        "method": "GET",
        "requestUri": "/2015-01-01/es/upgradeDomain/{DomainName}/history"
      },
      "input": {
        "type": "structure",
        "required": [
          "DomainName"
        ],
        "members": {
          "DomainName": {
            "location": "uri",
            "locationName": "DomainName"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "UpgradeHistories": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "UpgradeName": {},
                "StartTimestamp": {
                  "type": "timestamp"
                },
                "UpgradeStatus": {},
                "StepsList": {
                  "type": "list",
                  "member": {
                    "type": "structure",
                    "members": {
                      "UpgradeStep": {},
                      "UpgradeStepStatus": {},
                      "Issues": {
                        "type": "list",
                        "member": {}
                      },
                      "ProgressPercent": {
                        "type": "double"
                      }
                    }
                  }
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "GetUpgradeStatus": {
      "http": {
        "method": "GET",
        "requestUri": "/2015-01-01/es/upgradeDomain/{DomainName}/status"
      },
      "input": {
        "type": "structure",
        "required": [
          "DomainName"
        ],
        "members": {
          "DomainName": {
            "location": "uri",
            "locationName": "DomainName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "UpgradeStep": {},
          "StepStatus": {},
          "UpgradeName": {}
        }
      }
    },
    "ListDomainNames": {
      "http": {
        "method": "GET",
        "requestUri": "/2015-01-01/domain"
      },
      "input": {
        "type": "structure",
        "members": {
          "EngineType": {
            "location": "querystring",
            "locationName": "engineType"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DomainNames": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "DomainName": {},
                "EngineType": {}
              }
            }
          }
        }
      }
    },
    "ListDomainsForPackage": {
      "http": {
        "method": "GET",
        "requestUri": "/2015-01-01/packages/{PackageID}/domains"
      },
      "input": {
        "type": "structure",
        "required": [
          "PackageID"
        ],
        "members": {
          "PackageID": {
            "location": "uri",
            "locationName": "PackageID"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DomainPackageDetailsList": {
            "shape": "S6u"
          },
          "NextToken": {}
        }
      }
    },
    "ListElasticsearchInstanceTypes": {
      "http": {
        "method": "GET",
        "requestUri": "/2015-01-01/es/instanceTypes/{ElasticsearchVersion}"
      },
      "input": {
        "type": "structure",
        "required": [
          "ElasticsearchVersion"
        ],
        "members": {
          "ElasticsearchVersion": {
            "location": "uri",
            "locationName": "ElasticsearchVersion"
          },
          "DomainName": {
            "location": "querystring",
            "locationName": "domainName"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ElasticsearchInstanceTypes": {
            "type": "list",
            "member": {}
          },
          "NextToken": {}
        }
      }
    },
    "ListElasticsearchVersions": {
      "http": {
        "method": "GET",
        "requestUri": "/2015-01-01/es/versions"
      },
      "input": {
        "type": "structure",
        "members": {
          "MaxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ElasticsearchVersions": {
            "shape": "S63"
          },
          "NextToken": {}
        }
      }
    },
    "ListPackagesForDomain": {
      "http": {
        "method": "GET",
        "requestUri": "/2015-01-01/domain/{DomainName}/packages"
      },
      "input": {
        "type": "structure",
        "required": [
          "DomainName"
        ],
        "members": {
          "DomainName": {
            "location": "uri",
            "locationName": "DomainName"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DomainPackageDetailsList": {
            "shape": "S6u"
          },
          "NextToken": {}
        }
      }
    },
    "ListTags": {
      "http": {
        "method": "GET",
        "requestUri": "/2015-01-01/tags/"
      },
      "input": {
        "type": "structure",
        "required": [
          "ARN"
        ],
        "members": {
          "ARN": {
            "location": "querystring",
            "locationName": "arn"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TagList": {
            "shape": "Se"
          }
        }
      }
    },
    "PurchaseReservedElasticsearchInstanceOffering": {
      "http": {
        "requestUri": "/2015-01-01/es/purchaseReservedInstanceOffering"
      },
      "input": {
        "type": "structure",
        "required": [
          "ReservedElasticsearchInstanceOfferingId",
          "ReservationName"
        ],
        "members": {
          "ReservedElasticsearchInstanceOfferingId": {},
          "ReservationName": {},
          "InstanceCount": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ReservedElasticsearchInstanceId": {},
          "ReservationName": {}
        }
      }
    },
    "RejectInboundCrossClusterSearchConnection": {
      "http": {
        "method": "PUT",
        "requestUri": "/2015-01-01/es/ccs/inboundConnection/{ConnectionId}/reject"
      },
      "input": {
        "type": "structure",
        "required": [
          "CrossClusterSearchConnectionId"
        ],
        "members": {
          "CrossClusterSearchConnectionId": {
            "location": "uri",
            "locationName": "ConnectionId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "CrossClusterSearchConnection": {
            "shape": "S4"
          }
        }
      }
    },
    "RemoveTags": {
      "http": {
        "requestUri": "/2015-01-01/tags-removal"
      },
      "input": {
        "type": "structure",
        "required": [
          "ARN",
          "TagKeys"
        ],
        "members": {
          "ARN": {},
          "TagKeys": {
            "shape": "S1f"
          }
        }
      }
    },
    "StartElasticsearchServiceSoftwareUpdate": {
      "http": {
        "requestUri": "/2015-01-01/es/serviceSoftwareUpdate/start"
      },
      "input": {
        "type": "structure",
        "required": [
          "DomainName"
        ],
        "members": {
          "DomainName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ServiceSoftwareOptions": {
            "shape": "Sx"
          }
        }
      }
    },
    "UpdateElasticsearchDomainConfig": {
      "http": {
        "requestUri": "/2015-01-01/es/domain/{DomainName}/config"
      },
      "input": {
        "type": "structure",
        "required": [
          "DomainName"
        ],
        "members": {
          "DomainName": {
            "location": "uri",
            "locationName": "DomainName"
          },
          "ElasticsearchClusterConfig": {
            "shape": "S14"
          },
          "EBSOptions": {
            "shape": "S1a"
          },
          "SnapshotOptions": {
            "shape": "S1d"
          },
          "VPCOptions": {
            "shape": "S1e"
          },
          "CognitoOptions": {
            "shape": "S1g"
          },
          "AdvancedOptions": {
            "shape": "S1n"
          },
          "AccessPolicies": {},
          "LogPublishingOptions": {
            "shape": "S1o"
          },
          "DomainEndpointOptions": {
            "shape": "S1s"
          },
          "AdvancedSecurityOptions": {
            "shape": "S1v"
          },
          "NodeToNodeEncryptionOptions": {
            "shape": "S1m"
          },
          "EncryptionAtRestOptions": {
            "shape": "S1k"
          },
          "AutoTuneOptions": {
            "shape": "S49"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "DomainConfig"
        ],
        "members": {
          "DomainConfig": {
            "shape": "S3q"
          }
        }
      }
    },
    "UpdatePackage": {
      "http": {
        "requestUri": "/2015-01-01/packages/update"
      },
      "input": {
        "type": "structure",
        "required": [
          "PackageID",
          "PackageSource"
        ],
        "members": {
          "PackageID": {},
          "PackageSource": {
            "shape": "S2t"
          },
          "PackageDescription": {},
          "CommitMessage": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "PackageDetails": {
            "shape": "S2x"
          }
        }
      }
    },
    "UpgradeElasticsearchDomain": {
      "http": {
        "requestUri": "/2015-01-01/es/upgradeDomain"
      },
      "input": {
        "type": "structure",
        "required": [
          "DomainName",
          "TargetVersion"
        ],
        "members": {
          "DomainName": {},
          "TargetVersion": {},
          "PerformCheckOnly": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DomainName": {},
          "TargetVersion": {},
          "PerformCheckOnly": {
            "type": "boolean"
          }
        }
      }
    }
  },
  "shapes": {
    "S4": {
      "type": "structure",
      "members": {
        "SourceDomainInfo": {
          "shape": "S5"
        },
        "DestinationDomainInfo": {
          "shape": "S5"
        },
        "CrossClusterSearchConnectionId": {},
        "ConnectionStatus": {
          "type": "structure",
          "members": {
            "StatusCode": {},
            "Message": {}
          }
        }
      }
    },
    "S5": {
      "type": "structure",
      "required": [
        "DomainName"
      ],
      "members": {
        "OwnerId": {},
        "DomainName": {},
        "Region": {}
      }
    },
    "Se": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "Key",
          "Value"
        ],
        "members": {
          "Key": {},
          "Value": {}
        }
      }
    },
    "Sl": {
      "type": "structure",
      "members": {
        "PackageID": {},
        "PackageName": {},
        "PackageType": {},
        "LastUpdated": {
          "type": "timestamp"
        },
        "DomainName": {},
        "DomainPackageStatus": {},
        "PackageVersion": {},
        "ReferencePath": {},
        "ErrorDetails": {
          "shape": "Ss"
        }
      }
    },
    "Ss": {
      "type": "structure",
      "members": {
        "ErrorType": {},
        "ErrorMessage": {}
      }
    },
    "Sx": {
      "type": "structure",
      "members": {
        "CurrentVersion": {},
        "NewVersion": {},
        "UpdateAvailable": {
          "type": "boolean"
        },
        "Cancellable": {
          "type": "boolean"
        },
        "UpdateStatus": {},
        "Description": {},
        "AutomatedUpdateDate": {
          "type": "timestamp"
        },
        "OptionalDeployment": {
          "type": "boolean"
        }
      }
    },
    "S14": {
      "type": "structure",
      "members": {
        "InstanceType": {},
        "InstanceCount": {
          "type": "integer"
        },
        "DedicatedMasterEnabled": {
          "type": "boolean"
        },
        "ZoneAwarenessEnabled": {
          "type": "boolean"
        },
        "ZoneAwarenessConfig": {
          "type": "structure",
          "members": {
            "AvailabilityZoneCount": {
              "type": "integer"
            }
          }
        },
        "DedicatedMasterType": {},
        "DedicatedMasterCount": {
          "type": "integer"
        },
        "WarmEnabled": {
          "type": "boolean"
        },
        "WarmType": {},
        "WarmCount": {
          "type": "integer"
        },
        "ColdStorageOptions": {
          "type": "structure",
          "required": [
            "Enabled"
          ],
          "members": {
            "Enabled": {
              "type": "boolean"
            }
          }
        }
      }
    },
    "S1a": {
      "type": "structure",
      "members": {
        "EBSEnabled": {
          "type": "boolean"
        },
        "VolumeType": {},
        "VolumeSize": {
          "type": "integer"
        },
        "Iops": {
          "type": "integer"
        }
      }
    },
    "S1d": {
      "type": "structure",
      "members": {
        "AutomatedSnapshotStartHour": {
          "type": "integer"
        }
      }
    },
    "S1e": {
      "type": "structure",
      "members": {
        "SubnetIds": {
          "shape": "S1f"
        },
        "SecurityGroupIds": {
          "shape": "S1f"
        }
      }
    },
    "S1f": {
      "type": "list",
      "member": {}
    },
    "S1g": {
      "type": "structure",
      "members": {
        "Enabled": {
          "type": "boolean"
        },
        "UserPoolId": {},
        "IdentityPoolId": {},
        "RoleArn": {}
      }
    },
    "S1k": {
      "type": "structure",
      "members": {
        "Enabled": {
          "type": "boolean"
        },
        "KmsKeyId": {}
      }
    },
    "S1m": {
      "type": "structure",
      "members": {
        "Enabled": {
          "type": "boolean"
        }
      }
    },
    "S1n": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "S1o": {
      "type": "map",
      "key": {},
      "value": {
        "type": "structure",
        "members": {
          "CloudWatchLogsLogGroupArn": {},
          "Enabled": {
            "type": "boolean"
          }
        }
      }
    },
    "S1s": {
      "type": "structure",
      "members": {
        "EnforceHTTPS": {
          "type": "boolean"
        },
        "TLSSecurityPolicy": {},
        "CustomEndpointEnabled": {
          "type": "boolean"
        },
        "CustomEndpoint": {},
        "CustomEndpointCertificateArn": {}
      }
    },
    "S1v": {
      "type": "structure",
      "members": {
        "Enabled": {
          "type": "boolean"
        },
        "InternalUserDatabaseEnabled": {
          "type": "boolean"
        },
        "MasterUserOptions": {
          "type": "structure",
          "members": {
            "MasterUserARN": {},
            "MasterUserName": {
              "shape": "S1x"
            },
            "MasterUserPassword": {
              "type": "string",
              "sensitive": true
            }
          }
        },
        "SAMLOptions": {
          "type": "structure",
          "members": {
            "Enabled": {
              "type": "boolean"
            },
            "Idp": {
              "shape": "S20"
            },
            "MasterUserName": {
              "shape": "S1x"
            },
            "MasterBackendRole": {},
            "SubjectKey": {},
            "RolesKey": {},
            "SessionTimeoutMinutes": {
              "type": "integer"
            }
          }
        }
      }
    },
    "S1x": {
      "type": "string",
      "sensitive": true
    },
    "S20": {
      "type": "structure",
      "required": [
        "MetadataContent",
        "EntityId"
      ],
      "members": {
        "MetadataContent": {},
        "EntityId": {}
      }
    },
    "S26": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "StartAt": {
            "type": "timestamp"
          },
          "Duration": {
            "type": "structure",
            "members": {
              "Value": {
                "type": "long"
              },
              "Unit": {}
            }
          },
          "CronExpressionForRecurrence": {}
        }
      }
    },
    "S2d": {
      "type": "structure",
      "required": [
        "DomainId",
        "DomainName",
        "ARN",
        "ElasticsearchClusterConfig"
      ],
      "members": {
        "DomainId": {},
        "DomainName": {},
        "ARN": {},
        "Created": {
          "type": "boolean"
        },
        "Deleted": {
          "type": "boolean"
        },
        "Endpoint": {},
        "Endpoints": {
          "type": "map",
          "key": {},
          "value": {}
        },
        "Processing": {
          "type": "boolean"
        },
        "UpgradeProcessing": {
          "type": "boolean"
        },
        "ElasticsearchVersion": {},
        "ElasticsearchClusterConfig": {
          "shape": "S14"
        },
        "EBSOptions": {
          "shape": "S1a"
        },
        "AccessPolicies": {},
        "SnapshotOptions": {
          "shape": "S1d"
        },
        "VPCOptions": {
          "shape": "S2h"
        },
        "CognitoOptions": {
          "shape": "S1g"
        },
        "EncryptionAtRestOptions": {
          "shape": "S1k"
        },
        "NodeToNodeEncryptionOptions": {
          "shape": "S1m"
        },
        "AdvancedOptions": {
          "shape": "S1n"
        },
        "LogPublishingOptions": {
          "shape": "S1o"
        },
        "ServiceSoftwareOptions": {
          "shape": "Sx"
        },
        "DomainEndpointOptions": {
          "shape": "S1s"
        },
        "AdvancedSecurityOptions": {
          "shape": "S2i"
        },
        "AutoTuneOptions": {
          "type": "structure",
          "members": {
            "State": {},
            "ErrorMessage": {}
          }
        }
      }
    },
    "S2h": {
      "type": "structure",
      "members": {
        "VPCId": {},
        "SubnetIds": {
          "shape": "S1f"
        },
        "AvailabilityZones": {
          "shape": "S1f"
        },
        "SecurityGroupIds": {
          "shape": "S1f"
        }
      }
    },
    "S2i": {
      "type": "structure",
      "members": {
        "Enabled": {
          "type": "boolean"
        },
        "InternalUserDatabaseEnabled": {
          "type": "boolean"
        },
        "SAMLOptions": {
          "type": "structure",
          "members": {
            "Enabled": {
              "type": "boolean"
            },
            "Idp": {
              "shape": "S20"
            },
            "SubjectKey": {},
            "RolesKey": {},
            "SessionTimeoutMinutes": {
              "type": "integer"
            }
          }
        }
      }
    },
    "S2p": {
      "type": "structure",
      "members": {
        "StatusCode": {},
        "Message": {}
      }
    },
    "S2t": {
      "type": "structure",
      "members": {
        "S3BucketName": {},
        "S3Key": {}
      }
    },
    "S2x": {
      "type": "structure",
      "members": {
        "PackageID": {},
        "PackageName": {},
        "PackageType": {},
        "PackageDescription": {},
        "PackageStatus": {},
        "CreatedAt": {
          "type": "timestamp"
        },
        "LastUpdatedAt": {
          "type": "timestamp"
        },
        "AvailablePackageVersion": {},
        "ErrorDetails": {
          "shape": "Ss"
        }
      }
    },
    "S36": {
      "type": "structure",
      "members": {
        "SourceDomainInfo": {
          "shape": "S5"
        },
        "DestinationDomainInfo": {
          "shape": "S5"
        },
        "CrossClusterSearchConnectionId": {},
        "ConnectionAlias": {},
        "ConnectionStatus": {
          "shape": "S2p"
        }
      }
    },
    "S3q": {
      "type": "structure",
      "members": {
        "ElasticsearchVersion": {
          "type": "structure",
          "required": [
            "Options",
            "Status"
          ],
          "members": {
            "Options": {},
            "Status": {
              "shape": "S3s"
            }
          }
        },
        "ElasticsearchClusterConfig": {
          "type": "structure",
          "required": [
            "Options",
            "Status"
          ],
          "members": {
            "Options": {
              "shape": "S14"
            },
            "Status": {
              "shape": "S3s"
            }
          }
        },
        "EBSOptions": {
          "type": "structure",
          "required": [
            "Options",
            "Status"
          ],
          "members": {
            "Options": {
              "shape": "S1a"
            },
            "Status": {
              "shape": "S3s"
            }
          }
        },
        "AccessPolicies": {
          "type": "structure",
          "required": [
            "Options",
            "Status"
          ],
          "members": {
            "Options": {},
            "Status": {
              "shape": "S3s"
            }
          }
        },
        "SnapshotOptions": {
          "type": "structure",
          "required": [
            "Options",
            "Status"
          ],
          "members": {
            "Options": {
              "shape": "S1d"
            },
            "Status": {
              "shape": "S3s"
            }
          }
        },
        "VPCOptions": {
          "type": "structure",
          "required": [
            "Options",
            "Status"
          ],
          "members": {
            "Options": {
              "shape": "S2h"
            },
            "Status": {
              "shape": "S3s"
            }
          }
        },
        "CognitoOptions": {
          "type": "structure",
          "required": [
            "Options",
            "Status"
          ],
          "members": {
            "Options": {
              "shape": "S1g"
            },
            "Status": {
              "shape": "S3s"
            }
          }
        },
        "EncryptionAtRestOptions": {
          "type": "structure",
          "required": [
            "Options",
            "Status"
          ],
          "members": {
            "Options": {
              "shape": "S1k"
            },
            "Status": {
              "shape": "S3s"
            }
          }
        },
        "NodeToNodeEncryptionOptions": {
          "type": "structure",
          "required": [
            "Options",
            "Status"
          ],
          "members": {
            "Options": {
              "shape": "S1m"
            },
            "Status": {
              "shape": "S3s"
            }
          }
        },
        "AdvancedOptions": {
          "type": "structure",
          "required": [
            "Options",
            "Status"
          ],
          "members": {
            "Options": {
              "shape": "S1n"
            },
            "Status": {
              "shape": "S3s"
            }
          }
        },
        "LogPublishingOptions": {
          "type": "structure",
          "members": {
            "Options": {
              "shape": "S1o"
            },
            "Status": {
              "shape": "S3s"
            }
          }
        },
        "DomainEndpointOptions": {
          "type": "structure",
          "required": [
            "Options",
            "Status"
          ],
          "members": {
            "Options": {
              "shape": "S1s"
            },
            "Status": {
              "shape": "S3s"
            }
          }
        },
        "AdvancedSecurityOptions": {
          "type": "structure",
          "required": [
            "Options",
            "Status"
          ],
          "members": {
            "Options": {
              "shape": "S2i"
            },
            "Status": {
              "shape": "S3s"
            }
          }
        },
        "AutoTuneOptions": {
          "type": "structure",
          "members": {
            "Options": {
              "shape": "S49"
            },
            "Status": {
              "type": "structure",
              "required": [
                "CreationDate",
                "UpdateDate",
                "State"
              ],
              "members": {
                "CreationDate": {
                  "type": "timestamp"
                },
                "UpdateDate": {
                  "type": "timestamp"
                },
                "UpdateVersion": {
                  "type": "integer"
                },
                "State": {},
                "ErrorMessage": {},
                "PendingDeletion": {
                  "type": "boolean"
                }
              }
            }
          }
        }
      }
    },
    "S3s": {
      "type": "structure",
      "required": [
        "CreationDate",
        "UpdateDate",
        "State"
      ],
      "members": {
        "CreationDate": {
          "type": "timestamp"
        },
        "UpdateDate": {
          "type": "timestamp"
        },
        "UpdateVersion": {
          "type": "integer"
        },
        "State": {},
        "PendingDeletion": {
          "type": "boolean"
        }
      }
    },
    "S49": {
      "type": "structure",
      "members": {
        "DesiredState": {},
        "RollbackOnDisable": {},
        "MaintenanceSchedules": {
          "shape": "S26"
        }
      }
    },
    "S4s": {
      "type": "list",
      "member": {}
    },
    "S51": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "Name": {},
          "Values": {
            "type": "list",
            "member": {}
          }
        }
      }
    },
    "S5q": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "RecurringChargeAmount": {
            "type": "double"
          },
          "RecurringChargeFrequency": {}
        }
      }
    },
    "S63": {
      "type": "list",
      "member": {}
    },
    "S6u": {
      "type": "list",
      "member": {
        "shape": "Sl"
      }
    }
  }
}