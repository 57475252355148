{
  "version": "2.0",
  "metadata": {
    "apiVersion": "2020-12-15",
    "endpointPrefix": "lookoutequipment",
    "jsonVersion": "1.0",
    "protocol": "json",
    "serviceAbbreviation": "LookoutEquipment",
    "serviceFullName": "Amazon Lookout for Equipment",
    "serviceId": "LookoutEquipment",
    "signatureVersion": "v4",
    "targetPrefix": "AWSLookoutEquipmentFrontendService",
    "uid": "lookoutequipment-2020-12-15"
  },
  "operations": {
    "CreateDataset": {
      "input": {
        "type": "structure",
        "required": [
          "DatasetName",
          "DatasetSchema",
          "ClientToken"
        ],
        "members": {
          "DatasetName": {},
          "DatasetSchema": {
            "shape": "S3"
          },
          "ServerSideKmsKeyId": {},
          "ClientToken": {
            "idempotencyToken": true
          },
          "Tags": {
            "shape": "S7"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DatasetName": {},
          "DatasetArn": {},
          "Status": {}
        }
      }
    },
    "CreateInferenceScheduler": {
      "input": {
        "type": "structure",
        "required": [
          "ModelName",
          "InferenceSchedulerName",
          "DataUploadFrequency",
          "DataInputConfiguration",
          "DataOutputConfiguration",
          "RoleArn",
          "ClientToken"
        ],
        "members": {
          "ModelName": {},
          "InferenceSchedulerName": {},
          "DataDelayOffsetInMinutes": {
            "type": "long"
          },
          "DataUploadFrequency": {},
          "DataInputConfiguration": {
            "shape": "Sj"
          },
          "DataOutputConfiguration": {
            "shape": "Sr"
          },
          "RoleArn": {},
          "ServerSideKmsKeyId": {},
          "ClientToken": {
            "idempotencyToken": true
          },
          "Tags": {
            "shape": "S7"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "InferenceSchedulerArn": {},
          "InferenceSchedulerName": {},
          "Status": {}
        }
      }
    },
    "CreateModel": {
      "input": {
        "type": "structure",
        "required": [
          "ModelName",
          "DatasetName",
          "ClientToken"
        ],
        "members": {
          "ModelName": {},
          "DatasetName": {},
          "DatasetSchema": {
            "shape": "S3"
          },
          "LabelsInputConfiguration": {
            "shape": "Sz"
          },
          "ClientToken": {
            "idempotencyToken": true
          },
          "TrainingDataStartTime": {
            "type": "timestamp"
          },
          "TrainingDataEndTime": {
            "type": "timestamp"
          },
          "EvaluationDataStartTime": {
            "type": "timestamp"
          },
          "EvaluationDataEndTime": {
            "type": "timestamp"
          },
          "RoleArn": {},
          "DataPreProcessingConfiguration": {
            "shape": "S12"
          },
          "ServerSideKmsKeyId": {},
          "Tags": {
            "shape": "S7"
          },
          "OffCondition": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ModelArn": {},
          "Status": {}
        }
      }
    },
    "DeleteDataset": {
      "input": {
        "type": "structure",
        "required": [
          "DatasetName"
        ],
        "members": {
          "DatasetName": {}
        }
      }
    },
    "DeleteInferenceScheduler": {
      "input": {
        "type": "structure",
        "required": [
          "InferenceSchedulerName"
        ],
        "members": {
          "InferenceSchedulerName": {}
        }
      }
    },
    "DeleteModel": {
      "input": {
        "type": "structure",
        "required": [
          "ModelName"
        ],
        "members": {
          "ModelName": {}
        }
      }
    },
    "DescribeDataIngestionJob": {
      "input": {
        "type": "structure",
        "required": [
          "JobId"
        ],
        "members": {
          "JobId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "JobId": {},
          "DatasetArn": {},
          "IngestionInputConfiguration": {
            "shape": "S1f"
          },
          "RoleArn": {},
          "CreatedAt": {
            "type": "timestamp"
          },
          "Status": {},
          "FailedReason": {}
        }
      }
    },
    "DescribeDataset": {
      "input": {
        "type": "structure",
        "required": [
          "DatasetName"
        ],
        "members": {
          "DatasetName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DatasetName": {},
          "DatasetArn": {},
          "CreatedAt": {
            "type": "timestamp"
          },
          "LastUpdatedAt": {
            "type": "timestamp"
          },
          "Status": {},
          "Schema": {
            "jsonvalue": true
          },
          "ServerSideKmsKeyId": {},
          "IngestionInputConfiguration": {
            "shape": "S1f"
          }
        }
      }
    },
    "DescribeInferenceScheduler": {
      "input": {
        "type": "structure",
        "required": [
          "InferenceSchedulerName"
        ],
        "members": {
          "InferenceSchedulerName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ModelArn": {},
          "ModelName": {},
          "InferenceSchedulerName": {},
          "InferenceSchedulerArn": {},
          "Status": {},
          "DataDelayOffsetInMinutes": {
            "type": "long"
          },
          "DataUploadFrequency": {},
          "CreatedAt": {
            "type": "timestamp"
          },
          "UpdatedAt": {
            "type": "timestamp"
          },
          "DataInputConfiguration": {
            "shape": "Sj"
          },
          "DataOutputConfiguration": {
            "shape": "Sr"
          },
          "RoleArn": {},
          "ServerSideKmsKeyId": {}
        }
      }
    },
    "DescribeModel": {
      "input": {
        "type": "structure",
        "required": [
          "ModelName"
        ],
        "members": {
          "ModelName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ModelName": {},
          "ModelArn": {},
          "DatasetName": {},
          "DatasetArn": {},
          "Schema": {
            "jsonvalue": true
          },
          "LabelsInputConfiguration": {
            "shape": "Sz"
          },
          "TrainingDataStartTime": {
            "type": "timestamp"
          },
          "TrainingDataEndTime": {
            "type": "timestamp"
          },
          "EvaluationDataStartTime": {
            "type": "timestamp"
          },
          "EvaluationDataEndTime": {
            "type": "timestamp"
          },
          "RoleArn": {},
          "DataPreProcessingConfiguration": {
            "shape": "S12"
          },
          "Status": {},
          "TrainingExecutionStartTime": {
            "type": "timestamp"
          },
          "TrainingExecutionEndTime": {
            "type": "timestamp"
          },
          "FailedReason": {},
          "ModelMetrics": {
            "jsonvalue": true
          },
          "LastUpdatedTime": {
            "type": "timestamp"
          },
          "CreatedAt": {
            "type": "timestamp"
          },
          "ServerSideKmsKeyId": {},
          "OffCondition": {}
        }
      }
    },
    "ListDataIngestionJobs": {
      "input": {
        "type": "structure",
        "members": {
          "DatasetName": {},
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          },
          "Status": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "DataIngestionJobSummaries": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "JobId": {},
                "DatasetName": {},
                "DatasetArn": {},
                "IngestionInputConfiguration": {
                  "shape": "S1f"
                },
                "Status": {}
              }
            }
          }
        }
      }
    },
    "ListDatasets": {
      "input": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          },
          "DatasetNameBeginsWith": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "DatasetSummaries": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "DatasetName": {},
                "DatasetArn": {},
                "Status": {},
                "CreatedAt": {
                  "type": "timestamp"
                }
              }
            }
          }
        }
      }
    },
    "ListInferenceExecutions": {
      "input": {
        "type": "structure",
        "required": [
          "InferenceSchedulerName"
        ],
        "members": {
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          },
          "InferenceSchedulerName": {},
          "DataStartTimeAfter": {
            "type": "timestamp"
          },
          "DataEndTimeBefore": {
            "type": "timestamp"
          },
          "Status": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "InferenceExecutionSummaries": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "ModelName": {},
                "ModelArn": {},
                "InferenceSchedulerName": {},
                "InferenceSchedulerArn": {},
                "ScheduledStartTime": {
                  "type": "timestamp"
                },
                "DataStartTime": {
                  "type": "timestamp"
                },
                "DataEndTime": {
                  "type": "timestamp"
                },
                "DataInputConfiguration": {
                  "shape": "Sj"
                },
                "DataOutputConfiguration": {
                  "shape": "Sr"
                },
                "CustomerResultObject": {
                  "type": "structure",
                  "required": [
                    "Bucket",
                    "Key"
                  ],
                  "members": {
                    "Bucket": {},
                    "Key": {}
                  }
                },
                "Status": {},
                "FailedReason": {}
              }
            }
          }
        }
      }
    },
    "ListInferenceSchedulers": {
      "input": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          },
          "InferenceSchedulerNameBeginsWith": {},
          "ModelName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "InferenceSchedulerSummaries": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "ModelName": {},
                "ModelArn": {},
                "InferenceSchedulerName": {},
                "InferenceSchedulerArn": {},
                "Status": {},
                "DataDelayOffsetInMinutes": {
                  "type": "long"
                },
                "DataUploadFrequency": {}
              }
            }
          }
        }
      }
    },
    "ListModels": {
      "input": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          },
          "Status": {},
          "ModelNameBeginsWith": {},
          "DatasetNameBeginsWith": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "ModelSummaries": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "ModelName": {},
                "ModelArn": {},
                "DatasetName": {},
                "DatasetArn": {},
                "Status": {},
                "CreatedAt": {
                  "type": "timestamp"
                }
              }
            }
          }
        }
      }
    },
    "ListTagsForResource": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceArn"
        ],
        "members": {
          "ResourceArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Tags": {
            "shape": "S7"
          }
        }
      }
    },
    "StartDataIngestionJob": {
      "input": {
        "type": "structure",
        "required": [
          "DatasetName",
          "IngestionInputConfiguration",
          "RoleArn",
          "ClientToken"
        ],
        "members": {
          "DatasetName": {},
          "IngestionInputConfiguration": {
            "shape": "S1f"
          },
          "RoleArn": {},
          "ClientToken": {
            "idempotencyToken": true
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "JobId": {},
          "Status": {}
        }
      }
    },
    "StartInferenceScheduler": {
      "input": {
        "type": "structure",
        "required": [
          "InferenceSchedulerName"
        ],
        "members": {
          "InferenceSchedulerName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ModelArn": {},
          "ModelName": {},
          "InferenceSchedulerName": {},
          "InferenceSchedulerArn": {},
          "Status": {}
        }
      }
    },
    "StopInferenceScheduler": {
      "input": {
        "type": "structure",
        "required": [
          "InferenceSchedulerName"
        ],
        "members": {
          "InferenceSchedulerName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ModelArn": {},
          "ModelName": {},
          "InferenceSchedulerName": {},
          "InferenceSchedulerArn": {},
          "Status": {}
        }
      }
    },
    "TagResource": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceArn",
          "Tags"
        ],
        "members": {
          "ResourceArn": {},
          "Tags": {
            "shape": "S7"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UntagResource": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceArn",
          "TagKeys"
        ],
        "members": {
          "ResourceArn": {},
          "TagKeys": {
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UpdateInferenceScheduler": {
      "input": {
        "type": "structure",
        "required": [
          "InferenceSchedulerName"
        ],
        "members": {
          "InferenceSchedulerName": {},
          "DataDelayOffsetInMinutes": {
            "type": "long"
          },
          "DataUploadFrequency": {},
          "DataInputConfiguration": {
            "shape": "Sj"
          },
          "DataOutputConfiguration": {
            "shape": "Sr"
          },
          "RoleArn": {}
        }
      }
    }
  },
  "shapes": {
    "S3": {
      "type": "structure",
      "members": {
        "InlineDataSchema": {
          "jsonvalue": true
        }
      }
    },
    "S7": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "Key",
          "Value"
        ],
        "members": {
          "Key": {},
          "Value": {}
        }
      }
    },
    "Sj": {
      "type": "structure",
      "members": {
        "S3InputConfiguration": {
          "type": "structure",
          "required": [
            "Bucket"
          ],
          "members": {
            "Bucket": {},
            "Prefix": {}
          }
        },
        "InputTimeZoneOffset": {},
        "InferenceInputNameConfiguration": {
          "type": "structure",
          "members": {
            "TimestampFormat": {},
            "ComponentTimestampDelimiter": {}
          }
        }
      }
    },
    "Sr": {
      "type": "structure",
      "required": [
        "S3OutputConfiguration"
      ],
      "members": {
        "S3OutputConfiguration": {
          "type": "structure",
          "required": [
            "Bucket"
          ],
          "members": {
            "Bucket": {},
            "Prefix": {}
          }
        },
        "KmsKeyId": {}
      }
    },
    "Sz": {
      "type": "structure",
      "required": [
        "S3InputConfiguration"
      ],
      "members": {
        "S3InputConfiguration": {
          "type": "structure",
          "required": [
            "Bucket"
          ],
          "members": {
            "Bucket": {},
            "Prefix": {}
          }
        }
      }
    },
    "S12": {
      "type": "structure",
      "members": {
        "TargetSamplingRate": {}
      }
    },
    "S1f": {
      "type": "structure",
      "required": [
        "S3InputConfiguration"
      ],
      "members": {
        "S3InputConfiguration": {
          "type": "structure",
          "required": [
            "Bucket"
          ],
          "members": {
            "Bucket": {},
            "Prefix": {}
          }
        }
      }
    }
  }
}