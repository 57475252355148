// import { HemisphericLight } from '@babylonjs/core/Lights/hemisphericLight'
// import { PointLight } from '@babylonjs/core/Lights/pointLight'
// import { DirectionalLight } from '@babylonjs/core/Lights/directionalLight'
// import { Mesh } from "@babylonjs/core/Meshes/mesh"
// import { GridMaterial } from '@babylonjs/materials/grid/gridMaterial'
// import { ShadowGenerator } from '@babylonjs/core/Lights/Shadows/shadowGenerator'

import { Color3, Vector3 } from '@babylonjs/core/Maths/math'
import '@babylonjs/core/Materials/standardMaterial'
import '@babylonjs/core/Meshes/meshBuilder'

import floorTexture from '~/assets/textures/floor.png'

// import { StandardMaterial } from '@babylonjs/core/Materials/standardMaterial'
// import { MeshBuilder } from "@babylonjs/core/Meshes/meshBuilder"

import '@babylonjs/core/Layers/effectLayerSceneComponent'
import { HighlightLayer } from '@babylonjs/core/Layers/highlightLayer'

const initializeScene = sceneManager => {
  const { camera, scene, canvas } = sceneManager
  // scene.clearColor = new Color3(0.95, 0.95, 0.95)
  scene.clearColor = new Color3(1, 1, 1)
  scene.collisionsEnabled = false
  camera.setPosition(new Vector3(5, 3, -5))
  camera.setTarget(Vector3.Zero())
  camera.wheelPrecision = 50
  camera.allowUpsideDown = false
  camera.minZ = 0.1
  // TODO: make this dynamic
  camera.lowerRadiusLimit = 0.1
  camera.upperRadiusLimit = 15
  // camera.wheelDeltaPercentage = true
  // camera.panningAxis = new Vector3(1, 0, 1)
  camera.checkCollisions = false
  camera.attachControl(canvas, true)
  // light
  // const light = new HemisphericLight("light1", new Vector3(0, 1, 0), scene)
  // light.intensity = 2.8
  scene.createDefaultLight()

  // TODO!

  const helper = scene.createDefaultEnvironment({
    enableGroundShadow: false,
    groundTexture: floorTexture,
    // groundTexture: "http://localhost:8080/bg.png",
    // groundTexture: false,
    groundColor: Color3.White(),
    groundYBias: 0.01,
    groundSize: 15,
    createSkybox: false,
    // skyboxColor: new Color3(1, 1, 1),
    // cameraExposure: 2,
    // cameraContrast: 2
  })
  helper.ground.isPickable = false
  sceneManager.ground = helper.ground

  // ground floor
  // const ground = MeshBuilder.CreateGround("ground", { width: 25, height: 25 })
  // const groundMaterial = new GridMaterial("groundMaterial")
  // groundMaterial.majorUnitFrequency = 0
  // groundMaterial.minorUnitVisibility = 0.9
  // groundMaterial.gridRatio = 0.4
  // groundMaterial.backFaceCulling = true
  // groundMaterial.mainColor = new Color3(1, 1, 1)
  // groundMaterial.lineColor = new Color3(0.95, 0.95, 0.95)
  // // groundMaterial.lineColor = new Color3(1, 1, 1)
  // // groundMaterial.opacity = 0.95
  // ground.material = groundMaterial
  // ground.isPickable = false
  // sceneManager.ground = ground

  // skybox
  // TODO: pending

  // shadows
  // TODO: pending
  // TODO: the problem is: GridMaterial do *NOT* receive shadows :(
  // const pointLight = new PointLight("light2", new Vector3(0, 1, 0), scene)
  // const dirLight = new DirectionalLight("dir01", new Vector3(0, 2, 0), scene)
  // dirLight.position = new Vector3(2, 2, 20)
  // sceneManager.shadowGenerator = new ShadowGenerator(1024, dirLight)

  // debug
  // const box = MeshBuilder.CreateBox("box1", { size: 2 }, scene)
  // const sphere = Mesh.CreateSphere("sphere1", 16, 2.2, scene)
  window.scene = scene
}

export default initializeScene
