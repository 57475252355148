import { Tooltip2 } from '@blueprintjs/popover2'

const Tooltip = ({ content, children, placement = 'right' }) => {
  return (
    <Tooltip2
      content={content}
      placement={placement}
      usePortal={false}
      hoverOpenDelay={1200}
    >
      {children}
    </Tooltip2>
  )
}

export default Tooltip
