import { makeAutoObservable } from 'mobx'
import { PointerEventTypes } from '@babylonjs/core/Events/pointerEvents'
import {
  getToolState,
  getAllToolsStates,
  setToolState,
} from '~/src/utils/tools'
import {
  getNodeAncestors,
  getObjectIdFromNode,
  getNodePath,
} from '~/src/utils/nodes'
import PositionTool from '~/src/features/tools/position/positionTool'
import toaster from '~/src/features/toaster/toaster'
import {
  assign,
  get,
  set,
  unset,
  has,
  forEach,
  indexOf,
  slice,
  pick,
  map,
  without,
  isEmpty,
} from 'lodash'

import { toJS } from 'mobx'

export const TOOL_MODE = 0
export const ATTRIBUTE_PROPAGATION_MODE = 1

class Toolbar {
  // state
  selectedNode = null
  selectedTool = null
  selectedToolState = null

  // extra info
  pickPoint = null
  pickNormal = null
  eventCoordinates = { x: 0, y: 0 }

  // the toolbar position hook will save an udpate fn here
  updateFloatingPosition = null

  // UI state
  nodeAncestors = null
  toolbarMode = TOOL_MODE
  toolbarButtonClicked = null
  selectedAttributes = []

  constructor(rootStore) {
    makeAutoObservable(this, {})
    this.rootStore = rootStore
    // some shortcuts
    const { sceneManager, appState, training, camera, transitions } = rootStore
    assign(this, { sceneManager, appState, training, camera, transitions })
  }

  attachToScene() {
    const { scene } = this.sceneManager
    scene.onPointerObservable.add(this.handleClick)
  }

  dispose() {
    scene.onPointerObservable.removeCallback(this.handleClick)
  }

  handleClick = ({ event, type, pickInfo }) => {
    if (this.appState.mode !== 'editor') return
    if (this.sceneManager.isCtrlPressed) return
    if (
      type === PointerEventTypes.POINTERPICK &&
      pickInfo.hit &&
      pickInfo.pickedMesh
    ) {
      const mesh = pickInfo.pickedMesh
      // extra info
      this.pickPoint = pickInfo.pickedPoint
      this.pickNormal = pickInfo.getNormal()
      this.eventCoordinates = { x: event.clientX, y: event.clientY }
      // dispatch action
      if (mesh === this.sceneManager.ground) {
        this.unselectNode()
      } else if (event.button === 2) {
        this.unselectNode()
        this.nodeAncestors = getNodeAncestors(mesh)
      } else {
        this.selectNode(mesh)
      }
    } else if (
      type === PointerEventTypes.POINTERTAP &&
      (!pickInfo.hit || !pickInfo.pickedMesh)
    ) {
      this.unselectNode()
    }
  }

  selectNode(mesh) {
    // console.log(">> selected:", mesh, this.selectedNode)
    const { sceneManager } = this
    if (this.selectedNode !== null) {
      sceneManager.removeAllOutlines()
    }
    this.selectedNode = mesh
    sceneManager.applyOutline(mesh)
    mesh.overlayAlpha = 0.5
    // NOTE: is this the right place to do this?
    this.resetToolbarState()
  }

  unselectNode() {
    // console.log(">> unselected:", this.selectedNode)
    const { sceneManager } = this
    this.deactivateCurrentTool()
    sceneManager.removeOutline(this.selectedNode)
    this.selectedNode = null
  }

  resetToolbarState() {
    this.deactivateCurrentTool()
    this.activateTool(PositionTool)
  }

  unselectNodeIfHidden() {
    const { scene } = this.sceneManager
    const visibleNodes = scene.getNodes()
    if (!visibleNodes.includes(this.selectedNode)) {
      this.unselectNode()
    }
  }

  getSelectedNodePosition() {
    if (!this.selectedNode) return { x: 0, y: 0 }
    return this.sceneManager.getNodeScreenPosition(this.selectedNode)
  }

  deactivateCurrentTool() {
    if (this.selectedTool) {
      this.selectedTool.dettachFromScene(
        this.selectedNode,
        this.selectedToolState,
      )
      this.selectedTool = null
    }
    // reset ui state
    this.toolbarMode = TOOL_MODE
    this.selectedAttributes = []
    this.toolbarButtonClicked = null
  }

  activateTool(tool, ...params) {
    const { appState, training } = this.rootStore
    const selectedStep = training.steps[appState.selectedStepId]
    this.deactivateCurrentTool()
    const state = getToolState(this.selectedNode, selectedStep, tool)
    tool.attachToScene(this.selectedNode, state, ...params)
    // save state for future cleanup
    this.selectedTool = tool
    this.selectedToolState = state
  }

  // attribute management

  // NOTE: all this shit probably doesn't belong here...
  // NOTE: this toolbar does too much stuff

  getSelectedStep() {
    return this.training.steps[this.appState.selectedStepId]
  }

  copyAttributes = () => {
    const selectedStep = this.getSelectedStep()
    this.copiedAttributes = getAllToolsStates(this.selectedNode, selectedStep)
    this.resetToolbarState()
    toaster.show({
      icon: 'export',
      intent: 'success',
      message: 'Attributes copied',
      timeout: 1500,
    })
  }

  pasteAttributes = () => {
    const { copiedAttributes, selectedNode, selectedAttributes } = this
    this.rootStore.undo.saveSnapshot('Attributes restored')
    const selectedStep = this.getSelectedStep()
    forEach(selectedAttributes, tool => {
      const originalAttributes = getAllToolsStates(selectedNode, selectedStep)
      if (has(copiedAttributes, tool.key)) {
        const copiedValue = toJS(get(copiedAttributes, tool.key, {}))
        const originalValue = get(originalAttributes, tool.key, {})
        // restore the scene
        const refState = tool.getRefState(
          selectedNode,
          originalValue,
          copiedValue,
        )
        if (tool.onBeforeTransition) {
          tool.onBeforeTransition(
            selectedNode,
            originalValue,
            copiedValue,
            refState,
            1,
          )
        }
        tool.applyToNode(selectedNode, originalValue, copiedValue, refState, 1)
        if (tool.onAfterTransition) {
          tool.onAfterTransition(
            selectedNode,
            originalValue,
            copiedValue,
            refState,
            1,
          )
        }
        // save the state
        setToolState(selectedNode, selectedStep, tool, copiedValue)
      } else if (has(originalAttributes, tool.key)) {
        // copy empty = delete tool application
        tool.resetToOriginal(selectedNode, originalAttributes[tool.key])
        delete originalAttributes[tool.key]
      }
    })
    this.rootStore.transitions.onSelectedStepChange(selectedStep.id)
    this.resetToolbarState()
    toaster.show({
      icon: 'bring-data',
      intent: 'success',
      message: 'Attributes pasted',
      timeout: 1500,
    })
    this.appState.updateStepThumbnail()
  }

  applyAttributesToObject(stepId, objectId, nodePath, attributes) {
    this.rootStore.undo.saveSnapshot('Attributes restored')
    const step = this.training.steps[stepId]
    const tools = this.selectedAttributes
    const path = [...nodePath, 'tools']
    if (step.hasObject(objectId)) {
      const object = step.getObject(objectId)
      forEach(tools, tool => {
        const toolPath = [...path, tool.key]
        // const originalAttributes = get(object, toolPath, {})
        if (has(attributes, tool.key)) {
          const targetValue = toJS(get(attributes, tool.key, {}))
          set(object, toolPath, targetValue)
        } else if (isEmpty(attributes)) {
          unset(object, toolPath)
        }
      })
    }
  }

  propagateAttributesToSteps(stepIds) {
    const selectedStep = this.getSelectedStep()
    const objectId = getObjectIdFromNode(this.selectedNode)
    const nodeAttributes = getAllToolsStates(this.selectedNode, selectedStep)
    const attributes = pick(nodeAttributes, map(this.selectedAttributes, 'key'))
    forEach(stepIds, stepId => {
      this.applyAttributesToObject(
        stepId,
        objectId,
        getNodePath(this.selectedNode),
        attributes,
      )
    })
    this.resetToolbarState()
    toaster.show({
      icon: 'bring-data',
      intent: 'success',
      message: 'Attributes propagated successfully',
      timeout: 2000,
    })
  }

  propagateAttributesEverywhere = () => {
    const selectedStep = this.getSelectedStep()
    const ids = without(this.training.stepSequence, [selectedStep.id])
    this.propagateAttributesToSteps(ids)
  }

  propagateAttributesNext = () => {
    const selectedStep = this.getSelectedStep()
    const sequence = this.training.stepSequence
    const idx = indexOf(sequence, selectedStep.id)
    const next = sequence[idx + 1]
    if (next) this.propagateAttributesToSteps([next])
  }

  propagateAttributesPrev = () => {
    const selectedStep = this.getSelectedStep()
    const sequence = this.training.stepSequence
    const idx = indexOf(sequence, selectedStep.id)
    const prev = sequence[idx - 1]
    if (prev) this.propagateAttributesToSteps([prev])
  }

  propagateAttributesForward = () => {
    const selectedStep = this.getSelectedStep()
    const sequence = this.training.stepSequence
    const idx = indexOf(sequence, selectedStep.id)
    const remaining = slice(sequence, idx + 1)
    this.propagateAttributesToSteps(remaining)
  }

  propagateAttributesBackwards = () => {
    const selectedStep = this.getSelectedStep()
    const sequence = this.training.stepSequence
    const idx = indexOf(sequence, selectedStep.id)
    const before = slice(sequence, 0, idx)
    this.propagateAttributesToSteps(before)
  }
}

export default Toolbar
