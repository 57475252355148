{
  "version": "2.0",
  "metadata": {
    "apiVersion": "2020-12-01",
    "endpointPrefix": "fis",
    "jsonVersion": "1.1",
    "protocol": "rest-json",
    "serviceAbbreviation": "FIS",
    "serviceFullName": "AWS Fault Injection Simulator",
    "serviceId": "fis",
    "signatureVersion": "v4",
    "signingName": "fis",
    "uid": "fis-2020-12-01"
  },
  "operations": {
    "CreateExperimentTemplate": {
      "http": {
        "requestUri": "/experimentTemplates",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "clientToken",
          "description",
          "stopConditions",
          "actions",
          "roleArn"
        ],
        "members": {
          "clientToken": {
            "idempotencyToken": true
          },
          "description": {},
          "stopConditions": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "source"
              ],
              "members": {
                "source": {},
                "value": {}
              }
            }
          },
          "targets": {
            "type": "map",
            "key": {},
            "value": {
              "type": "structure",
              "required": [
                "resourceType",
                "selectionMode"
              ],
              "members": {
                "resourceType": {},
                "resourceArns": {
                  "shape": "Sc"
                },
                "resourceTags": {
                  "shape": "Se"
                },
                "filters": {
                  "shape": "Sh"
                },
                "selectionMode": {}
              }
            }
          },
          "actions": {
            "type": "map",
            "key": {},
            "value": {
              "type": "structure",
              "required": [
                "actionId"
              ],
              "members": {
                "actionId": {},
                "description": {},
                "parameters": {
                  "shape": "Ss"
                },
                "targets": {
                  "shape": "Sv"
                },
                "startAfter": {
                  "shape": "Sx"
                }
              }
            }
          },
          "roleArn": {},
          "tags": {
            "shape": "Se"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "experimentTemplate": {
            "shape": "S11"
          }
        }
      }
    },
    "DeleteExperimentTemplate": {
      "http": {
        "method": "DELETE",
        "requestUri": "/experimentTemplates/{id}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "id"
        ],
        "members": {
          "id": {
            "location": "uri",
            "locationName": "id"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "experimentTemplate": {
            "shape": "S11"
          }
        }
      }
    },
    "GetAction": {
      "http": {
        "method": "GET",
        "requestUri": "/actions/{id}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "id"
        ],
        "members": {
          "id": {
            "location": "uri",
            "locationName": "id"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "action": {
            "type": "structure",
            "members": {
              "id": {},
              "description": {},
              "parameters": {
                "type": "map",
                "key": {},
                "value": {
                  "type": "structure",
                  "members": {
                    "description": {},
                    "required": {
                      "type": "boolean"
                    }
                  }
                }
              },
              "targets": {
                "shape": "S1o"
              },
              "tags": {
                "shape": "Se"
              }
            }
          }
        }
      }
    },
    "GetExperiment": {
      "http": {
        "method": "GET",
        "requestUri": "/experiments/{id}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "id"
        ],
        "members": {
          "id": {
            "location": "uri",
            "locationName": "id"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "experiment": {
            "shape": "S1v"
          }
        }
      }
    },
    "GetExperimentTemplate": {
      "http": {
        "method": "GET",
        "requestUri": "/experimentTemplates/{id}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "id"
        ],
        "members": {
          "id": {
            "location": "uri",
            "locationName": "id"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "experimentTemplate": {
            "shape": "S11"
          }
        }
      }
    },
    "ListActions": {
      "http": {
        "method": "GET",
        "requestUri": "/actions",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "actions": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "id": {},
                "description": {},
                "targets": {
                  "shape": "S1o"
                },
                "tags": {
                  "shape": "Se"
                }
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListExperimentTemplates": {
      "http": {
        "method": "GET",
        "requestUri": "/experimentTemplates",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "experimentTemplates": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "id": {},
                "description": {},
                "creationTime": {
                  "type": "timestamp"
                },
                "lastUpdateTime": {
                  "type": "timestamp"
                },
                "tags": {
                  "shape": "Se"
                }
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListExperiments": {
      "http": {
        "method": "GET",
        "requestUri": "/experiments",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "experiments": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "id": {},
                "experimentTemplateId": {},
                "state": {
                  "shape": "S1w"
                },
                "creationTime": {
                  "type": "timestamp"
                },
                "tags": {
                  "shape": "Se"
                }
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListTagsForResource": {
      "http": {
        "method": "GET",
        "requestUri": "/tags/{resourceArn}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "resourceArn"
        ],
        "members": {
          "resourceArn": {
            "location": "uri",
            "locationName": "resourceArn"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "tags": {
            "shape": "Se"
          }
        }
      }
    },
    "StartExperiment": {
      "http": {
        "requestUri": "/experiments",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "clientToken",
          "experimentTemplateId"
        ],
        "members": {
          "clientToken": {
            "idempotencyToken": true
          },
          "experimentTemplateId": {},
          "tags": {
            "shape": "Se"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "experiment": {
            "shape": "S1v"
          }
        }
      }
    },
    "StopExperiment": {
      "http": {
        "method": "DELETE",
        "requestUri": "/experiments/{id}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "id"
        ],
        "members": {
          "id": {
            "location": "uri",
            "locationName": "id"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "experiment": {
            "shape": "S1v"
          }
        }
      }
    },
    "TagResource": {
      "http": {
        "requestUri": "/tags/{resourceArn}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "resourceArn",
          "tags"
        ],
        "members": {
          "resourceArn": {
            "location": "uri",
            "locationName": "resourceArn"
          },
          "tags": {
            "shape": "Se"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UntagResource": {
      "http": {
        "method": "DELETE",
        "requestUri": "/tags/{resourceArn}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "resourceArn"
        ],
        "members": {
          "resourceArn": {
            "location": "uri",
            "locationName": "resourceArn"
          },
          "tagKeys": {
            "location": "querystring",
            "locationName": "tagKeys",
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UpdateExperimentTemplate": {
      "http": {
        "method": "PATCH",
        "requestUri": "/experimentTemplates/{id}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "id"
        ],
        "members": {
          "id": {
            "location": "uri",
            "locationName": "id"
          },
          "description": {},
          "stopConditions": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "source"
              ],
              "members": {
                "source": {},
                "value": {}
              }
            }
          },
          "targets": {
            "type": "map",
            "key": {},
            "value": {
              "type": "structure",
              "required": [
                "resourceType",
                "selectionMode"
              ],
              "members": {
                "resourceType": {},
                "resourceArns": {
                  "shape": "Sc"
                },
                "resourceTags": {
                  "shape": "Se"
                },
                "filters": {
                  "shape": "Sh"
                },
                "selectionMode": {}
              }
            }
          },
          "actions": {
            "type": "map",
            "key": {},
            "value": {
              "type": "structure",
              "members": {
                "actionId": {},
                "description": {},
                "parameters": {
                  "shape": "Ss"
                },
                "targets": {
                  "shape": "Sv"
                },
                "startAfter": {
                  "shape": "Sx"
                }
              }
            }
          },
          "roleArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "experimentTemplate": {
            "shape": "S11"
          }
        }
      }
    }
  },
  "shapes": {
    "Sc": {
      "type": "list",
      "member": {}
    },
    "Se": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "Sh": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "path",
          "values"
        ],
        "members": {
          "path": {},
          "values": {
            "shape": "Sk"
          }
        }
      }
    },
    "Sk": {
      "type": "list",
      "member": {}
    },
    "Ss": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "Sv": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "Sx": {
      "type": "list",
      "member": {}
    },
    "S11": {
      "type": "structure",
      "members": {
        "id": {},
        "description": {},
        "targets": {
          "type": "map",
          "key": {},
          "value": {
            "type": "structure",
            "members": {
              "resourceType": {},
              "resourceArns": {
                "shape": "Sc"
              },
              "resourceTags": {
                "shape": "Se"
              },
              "filters": {
                "type": "list",
                "member": {
                  "type": "structure",
                  "members": {
                    "path": {},
                    "values": {
                      "shape": "Sk"
                    }
                  }
                }
              },
              "selectionMode": {}
            }
          }
        },
        "actions": {
          "type": "map",
          "key": {},
          "value": {
            "type": "structure",
            "members": {
              "actionId": {},
              "description": {},
              "parameters": {
                "shape": "Ss"
              },
              "targets": {
                "shape": "Sv"
              },
              "startAfter": {
                "shape": "Sx"
              }
            }
          }
        },
        "stopConditions": {
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "source": {},
              "value": {}
            }
          }
        },
        "creationTime": {
          "type": "timestamp"
        },
        "lastUpdateTime": {
          "type": "timestamp"
        },
        "roleArn": {},
        "tags": {
          "shape": "Se"
        }
      }
    },
    "S1o": {
      "type": "map",
      "key": {},
      "value": {
        "type": "structure",
        "members": {
          "resourceType": {}
        }
      }
    },
    "S1v": {
      "type": "structure",
      "members": {
        "id": {},
        "experimentTemplateId": {},
        "roleArn": {},
        "state": {
          "shape": "S1w"
        },
        "targets": {
          "type": "map",
          "key": {},
          "value": {
            "type": "structure",
            "members": {
              "resourceType": {},
              "resourceArns": {
                "shape": "Sc"
              },
              "resourceTags": {
                "shape": "Se"
              },
              "filters": {
                "type": "list",
                "member": {
                  "type": "structure",
                  "members": {
                    "path": {},
                    "values": {
                      "type": "list",
                      "member": {}
                    }
                  }
                }
              },
              "selectionMode": {}
            }
          }
        },
        "actions": {
          "type": "map",
          "key": {},
          "value": {
            "type": "structure",
            "members": {
              "actionId": {},
              "description": {},
              "parameters": {
                "type": "map",
                "key": {},
                "value": {}
              },
              "targets": {
                "type": "map",
                "key": {},
                "value": {}
              },
              "startAfter": {
                "type": "list",
                "member": {}
              },
              "state": {
                "type": "structure",
                "members": {
                  "status": {},
                  "reason": {}
                }
              }
            }
          }
        },
        "stopConditions": {
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "source": {},
              "value": {}
            }
          }
        },
        "creationTime": {
          "type": "timestamp"
        },
        "startTime": {
          "type": "timestamp"
        },
        "endTime": {
          "type": "timestamp"
        },
        "tags": {
          "shape": "Se"
        }
      }
    },
    "S1w": {
      "type": "structure",
      "members": {
        "status": {},
        "reason": {}
      }
    }
  }
}