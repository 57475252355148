import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import LoginPage from './pages/auth/LoginPage'
import PlayerPage from './pages/player/PlayerPage'
import EditorPage from './pages/editor/EditorPage'
import NotFoundPageView from './pages/error/NotFoundPageView'
import { StoreProvider } from './store'
import ProtectedContent from '~/src/app/components/ProtectedContent'
import { env } from '../features/backend/env'

const App = () => (
  <StoreProvider>
    <Router>
      <Switch>
        {/* auth */}

        {/* disabled for now */}
        <Route path="/auth/login" component={LoginPage} />
        <Route path="/play/:id+" component={PlayerPage} />

        <Route path="/not-found" component={NotFoundPageView} />
        <Route
          exact
          path="/"
          component={() => {
            window.location.replace(env.managerURL)
            return null
          }}
        />

        <ProtectedContent>
          {() => (
            <>
              {/* editor */}
              <Route path="/:id" component={EditorPage} />
            </>
          )}
        </ProtectedContent>

        {/* 404 */}
        <Route component={NotFoundPageView} />
      </Switch>
    </Router>
  </StoreProvider>
)

export default App
