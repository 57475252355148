import rootStore from '~/src/app/store'
import { Quaternion, Vector3 } from '@babylonjs/core/Maths/math'
import { GizmoManager } from '@babylonjs/core/Gizmos/gizmoManager'
// import { TransformNode } from '@babylonjs/core/Meshes/transformNode'
import { MeshBuilder } from '@babylonjs/core/Meshes/meshBuilder'
import {
  disposeGizmoManager,
  styleRotationGizmo,
  stylePositionGizmo,
} from '../mixins'
import { nuke } from '~/src/utils/nodes'
import { isEmpty, assign, forEach } from 'lodash'

// debug
import { toJS } from 'mobx'

class RotationTool {
  key = 'rotation'

  // scene management state
  node = null
  state = null
  lastRotation = null
  totalRotation = Vector3.Zero()
  pivotMarker = null

  attachToScene(node, state) {
    assign(this, { node, state })
    this.setupPivotMarker()
    this.setupGizmos(node, state)
    this.activateRotationMode()
    this.attachObservables()
    rootStore.appState.hideLabels()
    this.totalRotation = Vector3.Zero()
    // update the floating toolbar position when moving the part
    this.node.onAfterWorldMatrixUpdateObservable.add(this.updateToolbarPosition)
  }

  dettachFromScene(node, _state) {
    this.gizmoManager.attachToMesh(null)
    disposeGizmoManager(this.gizmoManager)
    this.disposeObservables()
    this.gizmoManager = null
    this.node.onAfterWorldMatrixUpdateObservable.removeCallback(
      this.updateToolbarPosition,
    )
    // pivot marker
    nuke(this.pivotMarker)
    // state
    assign(this, { node: null, state: null, pivotMarker: null })
    rootStore.appState.showLabels()
  }

  setupPivotMarker() {
    // this.pivotMarker = new TransformNode('transformNode')
    this.pivotMarker = MeshBuilder.CreateSphere('marker', { diameter: 0.03 })
    this.pivotMarker.parent = this.node.parent
    this.pivotMarker.rotation.copyFrom(this.node.rotation)
    this.pivotMarker.position = this.node.position.clone()
    if (this.state.pivot) {
      const pivot = Vector3.FromArray(this.state.pivot)
      this.pivotMarker.position = this.pivotMarker.position.add(pivot)
    }
  }

  setupGizmos(node, state) {
    const {
      sceneManager: { scene },
    } = rootStore
    this.gizmoManager = new GizmoManager(scene, 2)
    this.gizmoManager.usePointerToAttachGizmos = false
    this.gizmoManager.attachToMesh(this.pivotMarker)
  }

  // rotation mode

  activateRotationMode() {
    const {
      undo,
      sceneManager: { scene },
    } = rootStore
    this.deactivatePivotMode()
    this.gizmoManager.rotationGizmoEnabled = true
    styleRotationGizmo(this.gizmoManager)
    // store original node position (if not already saved)
    const { rotationGizmo } = this.gizmoManager.gizmos
    rotationGizmo.snapDistance = 0.001

    rotationGizmo.onDragStartObservable.add(() => {
      undo.saveSnapshot()
      const startRotation = this.node.rotation.clone()
      // reference
      this.maybeStoreReferenceRotation(this.state, startRotation)
      // accumulators
      this.totalRotation = Vector3.Zero()
      // snap observables
      const { xGizmo, yGizmo, zGizmo } = rotationGizmo
      xGizmo.onSnapObservable.add(e => this.updateTotalRotation('x', e))
      yGizmo.onSnapObservable.add(e => this.updateTotalRotation('y', e))
      zGizmo.onSnapObservable.add(e => this.updateTotalRotation('z', e))
    })

    // calculate the total rotation delta (against the stored original reference)
    rotationGizmo.onDragEndObservable.add((...args) => {
      // clear snap observables
      rotationGizmo.xGizmo.onSnapObservable.clear()
      rotationGizmo.yGizmo.onSnapObservable.clear()
      rotationGizmo.zGizmo.onSnapObservable.clear()
      this.storeTotalDelta(this.state, this.totalRotation)
      this.totalRotation = Vector3.Zero()
      rootStore.appState.updateStepThumbnail()
    })
  }

  deactivateRotationMode() {
    const { rotationGizmo } = this.gizmoManager.gizmos
    if (!rotationGizmo) return
    rotationGizmo.onDragStartObservable.clear()
    rotationGizmo.onDragEndObservable.clear()
    this.gizmoManager.rotationGizmoEnabled = false
  }

  updateTotalRotation = (axis, { snapDistance }) => {
    // console.log(axis, ':', snapDistance)
    // NOTE: rightHandedMode is very inconsistent
    if (axis === 'z') this.totalRotation[axis] += snapDistance
    else this.totalRotation[axis] -= snapDistance
  }

  // set pivot mode

  activatePivotMode() {
    const {
      undo,
      sceneManager: { scene },
    } = rootStore
    this.deactivateRotationMode()
    this.gizmoManager.positionGizmoEnabled = true
    window.gm = this.gizmoManager
    stylePositionGizmo(this.gizmoManager)
    // store original node position (if not already saved)
    const { positionGizmo } = this.gizmoManager.gizmos
    positionGizmo.onDragStartObservable.add(() => {
      undo.saveSnapshot()
      // reference
      const startPivot = this.node.getPivotPoint()
      this.maybeStoreReferencePivot(this.state, startPivot)
    })
    // calculate the total positiondelta (against the stored original reference)
    positionGizmo.onDragEndObservable.add(() => {
      const pivot = this.pivotMarker.position.subtract(this.node.position)
      this.storePivot(this.state, pivot)
    })
  }

  deactivatePivotMode() {
    const { positionGizmo } = this.gizmoManager.gizmos
    if (!positionGizmo) return
    positionGizmo.onDragStartObservable.clear()
    positionGizmo.onDragEndObservable.clear()
    this.gizmoManager.positionGizmoEnabled = false
  }

  // aux

  updateToolbarPosition = () => {
    const { toolbar } = rootStore
    toolbar.updateFloatingPosition()
  }

  getRotation(node) {
    return node.rotationQuaternion
      ? node.rotationQuaternion.toEulerAngles()
      : node.rotation.clone()
  }

  // observables

  attachObservables() {
    const {
      sceneManager: { scene },
    } = rootStore
    document.addEventListener('keydown', this.onKeyEvent)
    document.addEventListener('keyup', this.onKeyEvent)
    scene.onBeforeRenderObservable.add(this.updateNodeRotation)
  }

  disposeObservables() {
    const {
      sceneManager: { scene },
    } = rootStore
    document.removeEventListener('keydown', this.onKeyEvent)
    document.removeEventListener('keyup', this.onKeyEvent)
    scene.onBeforeRenderObservable.removeCallback(this.updateNodeRotation)
  }

  // TODO: find a way to abstract/generalize this a bit (shortuctManager or something)

  onKeyEvent = e => {
    if (event.key === 'Shift') {
      if (event.type === 'keydown') this.activatePivotMode()
      else if (event.type === 'keyup') this.activateRotationMode()
    }
  }

  updateNodeRotation = () => {
    const pivot = this.pivotMarker.position.subtract(this.node.position)
    this.node.setPivotPoint(pivot)
    // this.node.rotation = this.pivotMarker.rotation.clone()
    const originalRotation = Vector3.FromArray(
      this.state.originalRotation || this.getRotation(this.node).asArray(),
    )
    const accumulatedDelta = this.totalRotation.add(
      this.state.delta ? Vector3.FromArray(this.state.delta) : Vector3.Zero(),
    )
    const finalRotation = accumulatedDelta.add(originalRotation)
    this.node.rotation = finalRotation
  }

  // data

  maybeStoreReferenceRotation(state, startRotation) {
    // never overwrite originalPosition if present!
    if (!state.originalRotation) {
      state.originalRotation = startRotation.asArray()
    }
  }

  maybeStoreReferencePivot(state, startPivot) {
    if (!state.originalPivot) {
      state.originalPivot = startPivot.asArray()
    }
  }

  storeTotalDelta(state, rotationDelta) {
    const originalRotation = Vector3.FromArray(state.originalRotation)
    const accumulatedDelta = rotationDelta.add(
      state.delta ? Vector3.FromArray(state.delta) : Vector3.Zero(),
    )
    const finalRotation = accumulatedDelta.add(originalRotation)
    state.rotation = finalRotation.asArray()
    state.delta = accumulatedDelta.asArray()
  }

  storePivot(state, pivotPosition) {
    state.pivot = pivotPosition.asArray()
  }

  // application

  onBeforeTransition(node, originState = {}, targetState = {}, refState) {
    if (targetState.rotation && !targetState.pivot) {
      node.setPivotPoint(Vector3.Zero())
    } else {
      const targetPivot = Vector3.FromArray(
        targetState.pivot ||
          // this is is a bit weird, but we prefer originState.pivot over originalPivot
          originState.pivot ||
          originState.originalPivot || [0, 0, 0],
      )
      node.setPivotPoint(targetPivot)
    }
  }

  onAfterTransition(node, originState, targetState, refState) {}

  applyToNode(node, originState = {}, targetState = {}, refState, t = 1) {
    if (!targetState.rotation && !originState.originalRotation) return
    // if no target position, then restore the original position
    const { startRotation, startPivot } = refState
    const targetRotation = Vector3.FromArray(
      targetState.rotation || originState.originalRotation,
    )
    const rotation = Vector3.Lerp(startRotation, targetRotation, t)
    node.rotation = rotation
  }

  resetToOriginal(node, originState = {}, targetState = {}) {
    if (isEmpty(originState) && isEmpty(targetState)) return
    const originalRotation = Vector3.FromArray(
      targetState.originalRotation || originState.originalRotation,
    )
    const originalPivot = Vector3.FromArray(
      targetState.originalPivot || originState.originalPivot || [0, 0, 0],
    )
    // TODO: is this order correct?
    node.setPivotPoint(originalPivot)
    node.rotation.copyFrom(originalRotation)
  }

  getRefState(node, _originState, _targetState) {
    return {
      startRotation: this.getRotation(node),
      startPivot: node.getPivotPoint(),
    }
  }
}

export default new RotationTool()
