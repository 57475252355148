{
  "pagination": {
    "Query": {
      "input_token": "NextToken",
      "limit_key": "MaxRows",
      "non_aggregate_keys": [
        "ColumnInfo",
        "QueryId",
        "QueryStatus"
      ],
      "output_token": "NextToken",
      "result_key": "Rows"
    }
  }
}