{
  "version": "2.0",
  "metadata": {
    "apiVersion": "2017-03-31",
    "endpointPrefix": "lakeformation",
    "jsonVersion": "1.1",
    "protocol": "json",
    "serviceFullName": "AWS Lake Formation",
    "serviceId": "LakeFormation",
    "signatureVersion": "v4",
    "signingName": "lakeformation",
    "targetPrefix": "AWSLakeFormation",
    "uid": "lakeformation-2017-03-31"
  },
  "operations": {
    "AddLFTagsToResource": {
      "input": {
        "type": "structure",
        "required": [
          "Resource",
          "LFTags"
        ],
        "members": {
          "CatalogId": {},
          "Resource": {
            "shape": "S3"
          },
          "LFTags": {
            "shape": "Sm"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Failures": {
            "shape": "Sp"
          }
        }
      }
    },
    "BatchGrantPermissions": {
      "input": {
        "type": "structure",
        "required": [
          "Entries"
        ],
        "members": {
          "CatalogId": {},
          "Entries": {
            "shape": "Su"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Failures": {
            "shape": "S12"
          }
        }
      }
    },
    "BatchRevokePermissions": {
      "input": {
        "type": "structure",
        "required": [
          "Entries"
        ],
        "members": {
          "CatalogId": {},
          "Entries": {
            "shape": "Su"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Failures": {
            "shape": "S12"
          }
        }
      }
    },
    "CreateLFTag": {
      "input": {
        "type": "structure",
        "required": [
          "TagKey",
          "TagValues"
        ],
        "members": {
          "CatalogId": {},
          "TagKey": {},
          "TagValues": {
            "shape": "Sf"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteLFTag": {
      "input": {
        "type": "structure",
        "required": [
          "TagKey"
        ],
        "members": {
          "CatalogId": {},
          "TagKey": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeregisterResource": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceArn"
        ],
        "members": {
          "ResourceArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DescribeResource": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceArn"
        ],
        "members": {
          "ResourceArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ResourceInfo": {
            "shape": "S1e"
          }
        }
      }
    },
    "GetDataLakeSettings": {
      "input": {
        "type": "structure",
        "members": {
          "CatalogId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DataLakeSettings": {
            "shape": "S1j"
          }
        }
      }
    },
    "GetEffectivePermissionsForPath": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceArn"
        ],
        "members": {
          "CatalogId": {},
          "ResourceArn": {},
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Permissions": {
            "shape": "S1s"
          },
          "NextToken": {}
        }
      }
    },
    "GetLFTag": {
      "input": {
        "type": "structure",
        "required": [
          "TagKey"
        ],
        "members": {
          "CatalogId": {},
          "TagKey": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "CatalogId": {},
          "TagKey": {},
          "TagValues": {
            "shape": "Sf"
          }
        }
      }
    },
    "GetResourceLFTags": {
      "input": {
        "type": "structure",
        "required": [
          "Resource"
        ],
        "members": {
          "CatalogId": {},
          "Resource": {
            "shape": "S3"
          },
          "ShowAssignedLFTags": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "LFTagOnDatabase": {
            "shape": "Sm"
          },
          "LFTagsOnTable": {
            "shape": "Sm"
          },
          "LFTagsOnColumns": {
            "shape": "S22"
          }
        }
      }
    },
    "GrantPermissions": {
      "input": {
        "type": "structure",
        "required": [
          "Principal",
          "Resource",
          "Permissions"
        ],
        "members": {
          "CatalogId": {},
          "Principal": {
            "shape": "Sx"
          },
          "Resource": {
            "shape": "S3"
          },
          "Permissions": {
            "shape": "Sz"
          },
          "PermissionsWithGrantOption": {
            "shape": "Sz"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "ListLFTags": {
      "input": {
        "type": "structure",
        "members": {
          "CatalogId": {},
          "ResourceShareType": {},
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "LFTags": {
            "shape": "Sm"
          },
          "NextToken": {}
        }
      }
    },
    "ListPermissions": {
      "input": {
        "type": "structure",
        "members": {
          "CatalogId": {},
          "Principal": {
            "shape": "Sx"
          },
          "ResourceType": {},
          "Resource": {
            "shape": "S3"
          },
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "PrincipalResourcePermissions": {
            "shape": "S1s"
          },
          "NextToken": {}
        }
      }
    },
    "ListResources": {
      "input": {
        "type": "structure",
        "members": {
          "FilterConditionList": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Field": {},
                "ComparisonOperator": {},
                "StringValueList": {
                  "type": "list",
                  "member": {}
                }
              }
            }
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ResourceInfoList": {
            "type": "list",
            "member": {
              "shape": "S1e"
            }
          },
          "NextToken": {}
        }
      }
    },
    "PutDataLakeSettings": {
      "input": {
        "type": "structure",
        "required": [
          "DataLakeSettings"
        ],
        "members": {
          "CatalogId": {},
          "DataLakeSettings": {
            "shape": "S1j"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "RegisterResource": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceArn"
        ],
        "members": {
          "ResourceArn": {},
          "UseServiceLinkedRole": {
            "type": "boolean"
          },
          "RoleArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "RemoveLFTagsFromResource": {
      "input": {
        "type": "structure",
        "required": [
          "Resource",
          "LFTags"
        ],
        "members": {
          "CatalogId": {},
          "Resource": {
            "shape": "S3"
          },
          "LFTags": {
            "shape": "Sm"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Failures": {
            "shape": "Sp"
          }
        }
      }
    },
    "RevokePermissions": {
      "input": {
        "type": "structure",
        "required": [
          "Principal",
          "Resource",
          "Permissions"
        ],
        "members": {
          "CatalogId": {},
          "Principal": {
            "shape": "Sx"
          },
          "Resource": {
            "shape": "S3"
          },
          "Permissions": {
            "shape": "Sz"
          },
          "PermissionsWithGrantOption": {
            "shape": "Sz"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "SearchDatabasesByLFTags": {
      "input": {
        "type": "structure",
        "required": [
          "Expression"
        ],
        "members": {
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          },
          "CatalogId": {},
          "Expression": {
            "shape": "Sj"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "DatabaseList": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Database": {
                  "shape": "S5"
                },
                "LFTags": {
                  "shape": "Sm"
                }
              }
            }
          }
        }
      }
    },
    "SearchTablesByLFTags": {
      "input": {
        "type": "structure",
        "required": [
          "Expression"
        ],
        "members": {
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          },
          "CatalogId": {},
          "Expression": {
            "shape": "Sj"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "TableList": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Table": {
                  "shape": "S7"
                },
                "LFTagOnDatabase": {
                  "shape": "Sm"
                },
                "LFTagsOnTable": {
                  "shape": "Sm"
                },
                "LFTagsOnColumns": {
                  "shape": "S22"
                }
              }
            }
          }
        }
      }
    },
    "UpdateLFTag": {
      "input": {
        "type": "structure",
        "required": [
          "TagKey"
        ],
        "members": {
          "CatalogId": {},
          "TagKey": {},
          "TagValuesToDelete": {
            "shape": "Sf"
          },
          "TagValuesToAdd": {
            "shape": "Sf"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UpdateResource": {
      "input": {
        "type": "structure",
        "required": [
          "RoleArn",
          "ResourceArn"
        ],
        "members": {
          "RoleArn": {},
          "ResourceArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    }
  },
  "shapes": {
    "S3": {
      "type": "structure",
      "members": {
        "Catalog": {
          "type": "structure",
          "members": {}
        },
        "Database": {
          "shape": "S5"
        },
        "Table": {
          "shape": "S7"
        },
        "TableWithColumns": {
          "type": "structure",
          "required": [
            "DatabaseName",
            "Name"
          ],
          "members": {
            "CatalogId": {},
            "DatabaseName": {},
            "Name": {},
            "ColumnNames": {
              "shape": "Sa"
            },
            "ColumnWildcard": {
              "type": "structure",
              "members": {
                "ExcludedColumnNames": {
                  "shape": "Sa"
                }
              }
            }
          }
        },
        "DataLocation": {
          "type": "structure",
          "required": [
            "ResourceArn"
          ],
          "members": {
            "CatalogId": {},
            "ResourceArn": {}
          }
        },
        "LFTag": {
          "type": "structure",
          "required": [
            "TagKey",
            "TagValues"
          ],
          "members": {
            "CatalogId": {},
            "TagKey": {},
            "TagValues": {
              "shape": "Sf"
            }
          }
        },
        "LFTagPolicy": {
          "type": "structure",
          "required": [
            "ResourceType",
            "Expression"
          ],
          "members": {
            "CatalogId": {},
            "ResourceType": {},
            "Expression": {
              "shape": "Sj"
            }
          }
        }
      }
    },
    "S5": {
      "type": "structure",
      "required": [
        "Name"
      ],
      "members": {
        "CatalogId": {},
        "Name": {}
      }
    },
    "S7": {
      "type": "structure",
      "required": [
        "DatabaseName"
      ],
      "members": {
        "CatalogId": {},
        "DatabaseName": {},
        "Name": {},
        "TableWildcard": {
          "type": "structure",
          "members": {}
        }
      }
    },
    "Sa": {
      "type": "list",
      "member": {}
    },
    "Sf": {
      "type": "list",
      "member": {}
    },
    "Sj": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "TagKey",
          "TagValues"
        ],
        "members": {
          "TagKey": {},
          "TagValues": {
            "shape": "Sf"
          }
        }
      }
    },
    "Sm": {
      "type": "list",
      "member": {
        "shape": "Sn"
      }
    },
    "Sn": {
      "type": "structure",
      "required": [
        "TagKey",
        "TagValues"
      ],
      "members": {
        "CatalogId": {},
        "TagKey": {},
        "TagValues": {
          "shape": "Sf"
        }
      }
    },
    "Sp": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "LFTag": {
            "shape": "Sn"
          },
          "Error": {
            "shape": "Sr"
          }
        }
      }
    },
    "Sr": {
      "type": "structure",
      "members": {
        "ErrorCode": {},
        "ErrorMessage": {}
      }
    },
    "Su": {
      "type": "list",
      "member": {
        "shape": "Sv"
      }
    },
    "Sv": {
      "type": "structure",
      "required": [
        "Id"
      ],
      "members": {
        "Id": {},
        "Principal": {
          "shape": "Sx"
        },
        "Resource": {
          "shape": "S3"
        },
        "Permissions": {
          "shape": "Sz"
        },
        "PermissionsWithGrantOption": {
          "shape": "Sz"
        }
      }
    },
    "Sx": {
      "type": "structure",
      "members": {
        "DataLakePrincipalIdentifier": {}
      }
    },
    "Sz": {
      "type": "list",
      "member": {}
    },
    "S12": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "RequestEntry": {
            "shape": "Sv"
          },
          "Error": {
            "shape": "Sr"
          }
        }
      }
    },
    "S1e": {
      "type": "structure",
      "members": {
        "ResourceArn": {},
        "RoleArn": {},
        "LastModified": {
          "type": "timestamp"
        }
      }
    },
    "S1j": {
      "type": "structure",
      "members": {
        "DataLakeAdmins": {
          "type": "list",
          "member": {
            "shape": "Sx"
          }
        },
        "CreateDatabaseDefaultPermissions": {
          "shape": "S1l"
        },
        "CreateTableDefaultPermissions": {
          "shape": "S1l"
        },
        "TrustedResourceOwners": {
          "type": "list",
          "member": {}
        }
      }
    },
    "S1l": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "Principal": {
            "shape": "Sx"
          },
          "Permissions": {
            "shape": "Sz"
          }
        }
      }
    },
    "S1s": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "Principal": {
            "shape": "Sx"
          },
          "Resource": {
            "shape": "S3"
          },
          "Permissions": {
            "shape": "Sz"
          },
          "PermissionsWithGrantOption": {
            "shape": "Sz"
          },
          "AdditionalDetails": {
            "type": "structure",
            "members": {
              "ResourceShare": {
                "type": "list",
                "member": {}
              }
            }
          }
        }
      }
    },
    "S22": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "Name": {},
          "LFTags": {
            "shape": "Sm"
          }
        }
      }
    }
  }
}