{
  "version": "2.0",
  "metadata": {
    "apiVersion": "2019-01-25",
    "endpointPrefix": "appmesh",
    "jsonVersion": "1.1",
    "protocol": "rest-json",
    "serviceFullName": "AWS App Mesh",
    "serviceId": "App Mesh",
    "signatureVersion": "v4",
    "signingName": "appmesh",
    "uid": "appmesh-2019-01-25"
  },
  "operations": {
    "CreateGatewayRoute": {
      "http": {
        "method": "PUT",
        "requestUri": "/v20190125/meshes/{meshName}/virtualGateway/{virtualGatewayName}/gatewayRoutes",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "gatewayRouteName",
          "meshName",
          "spec",
          "virtualGatewayName"
        ],
        "members": {
          "clientToken": {
            "idempotencyToken": true
          },
          "gatewayRouteName": {},
          "meshName": {
            "location": "uri",
            "locationName": "meshName"
          },
          "meshOwner": {
            "location": "querystring",
            "locationName": "meshOwner"
          },
          "spec": {
            "shape": "S5"
          },
          "tags": {
            "shape": "S19"
          },
          "virtualGatewayName": {
            "location": "uri",
            "locationName": "virtualGatewayName"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "gatewayRoute"
        ],
        "members": {
          "gatewayRoute": {
            "shape": "S1e"
          }
        },
        "payload": "gatewayRoute"
      },
      "idempotent": true
    },
    "CreateMesh": {
      "http": {
        "method": "PUT",
        "requestUri": "/v20190125/meshes",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "meshName"
        ],
        "members": {
          "clientToken": {
            "idempotencyToken": true
          },
          "meshName": {},
          "spec": {
            "shape": "S1l"
          },
          "tags": {
            "shape": "S19"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "mesh"
        ],
        "members": {
          "mesh": {
            "shape": "S1p"
          }
        },
        "payload": "mesh"
      },
      "idempotent": true
    },
    "CreateRoute": {
      "http": {
        "method": "PUT",
        "requestUri": "/v20190125/meshes/{meshName}/virtualRouter/{virtualRouterName}/routes",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "meshName",
          "routeName",
          "spec",
          "virtualRouterName"
        ],
        "members": {
          "clientToken": {
            "idempotencyToken": true
          },
          "meshName": {
            "location": "uri",
            "locationName": "meshName"
          },
          "meshOwner": {
            "location": "querystring",
            "locationName": "meshOwner"
          },
          "routeName": {},
          "spec": {
            "shape": "S1t"
          },
          "tags": {
            "shape": "S19"
          },
          "virtualRouterName": {
            "location": "uri",
            "locationName": "virtualRouterName"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "route"
        ],
        "members": {
          "route": {
            "shape": "S2t"
          }
        },
        "payload": "route"
      },
      "idempotent": true
    },
    "CreateVirtualGateway": {
      "http": {
        "method": "PUT",
        "requestUri": "/v20190125/meshes/{meshName}/virtualGateways",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "meshName",
          "spec",
          "virtualGatewayName"
        ],
        "members": {
          "clientToken": {
            "idempotencyToken": true
          },
          "meshName": {
            "location": "uri",
            "locationName": "meshName"
          },
          "meshOwner": {
            "location": "querystring",
            "locationName": "meshOwner"
          },
          "spec": {
            "shape": "S2x"
          },
          "tags": {
            "shape": "S19"
          },
          "virtualGatewayName": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "virtualGateway"
        ],
        "members": {
          "virtualGateway": {
            "shape": "S47"
          }
        },
        "payload": "virtualGateway"
      },
      "idempotent": true
    },
    "CreateVirtualNode": {
      "http": {
        "method": "PUT",
        "requestUri": "/v20190125/meshes/{meshName}/virtualNodes",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "meshName",
          "spec",
          "virtualNodeName"
        ],
        "members": {
          "clientToken": {
            "idempotencyToken": true
          },
          "meshName": {
            "location": "uri",
            "locationName": "meshName"
          },
          "meshOwner": {
            "location": "querystring",
            "locationName": "meshOwner"
          },
          "spec": {
            "shape": "S4b"
          },
          "tags": {
            "shape": "S19"
          },
          "virtualNodeName": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "virtualNode"
        ],
        "members": {
          "virtualNode": {
            "shape": "S5t"
          }
        },
        "payload": "virtualNode"
      },
      "idempotent": true
    },
    "CreateVirtualRouter": {
      "http": {
        "method": "PUT",
        "requestUri": "/v20190125/meshes/{meshName}/virtualRouters",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "meshName",
          "spec",
          "virtualRouterName"
        ],
        "members": {
          "clientToken": {
            "idempotencyToken": true
          },
          "meshName": {
            "location": "uri",
            "locationName": "meshName"
          },
          "meshOwner": {
            "location": "querystring",
            "locationName": "meshOwner"
          },
          "spec": {
            "shape": "S5x"
          },
          "tags": {
            "shape": "S19"
          },
          "virtualRouterName": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "virtualRouter"
        ],
        "members": {
          "virtualRouter": {
            "shape": "S61"
          }
        },
        "payload": "virtualRouter"
      },
      "idempotent": true
    },
    "CreateVirtualService": {
      "http": {
        "method": "PUT",
        "requestUri": "/v20190125/meshes/{meshName}/virtualServices",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "meshName",
          "spec",
          "virtualServiceName"
        ],
        "members": {
          "clientToken": {
            "idempotencyToken": true
          },
          "meshName": {
            "location": "uri",
            "locationName": "meshName"
          },
          "meshOwner": {
            "location": "querystring",
            "locationName": "meshOwner"
          },
          "spec": {
            "shape": "S65"
          },
          "tags": {
            "shape": "S19"
          },
          "virtualServiceName": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "virtualService"
        ],
        "members": {
          "virtualService": {
            "shape": "S6a"
          }
        },
        "payload": "virtualService"
      },
      "idempotent": true
    },
    "DeleteGatewayRoute": {
      "http": {
        "method": "DELETE",
        "requestUri": "/v20190125/meshes/{meshName}/virtualGateway/{virtualGatewayName}/gatewayRoutes/{gatewayRouteName}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "gatewayRouteName",
          "meshName",
          "virtualGatewayName"
        ],
        "members": {
          "gatewayRouteName": {
            "location": "uri",
            "locationName": "gatewayRouteName"
          },
          "meshName": {
            "location": "uri",
            "locationName": "meshName"
          },
          "meshOwner": {
            "location": "querystring",
            "locationName": "meshOwner"
          },
          "virtualGatewayName": {
            "location": "uri",
            "locationName": "virtualGatewayName"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "gatewayRoute"
        ],
        "members": {
          "gatewayRoute": {
            "shape": "S1e"
          }
        },
        "payload": "gatewayRoute"
      },
      "idempotent": true
    },
    "DeleteMesh": {
      "http": {
        "method": "DELETE",
        "requestUri": "/v20190125/meshes/{meshName}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "meshName"
        ],
        "members": {
          "meshName": {
            "location": "uri",
            "locationName": "meshName"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "mesh"
        ],
        "members": {
          "mesh": {
            "shape": "S1p"
          }
        },
        "payload": "mesh"
      },
      "idempotent": true
    },
    "DeleteRoute": {
      "http": {
        "method": "DELETE",
        "requestUri": "/v20190125/meshes/{meshName}/virtualRouter/{virtualRouterName}/routes/{routeName}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "meshName",
          "routeName",
          "virtualRouterName"
        ],
        "members": {
          "meshName": {
            "location": "uri",
            "locationName": "meshName"
          },
          "meshOwner": {
            "location": "querystring",
            "locationName": "meshOwner"
          },
          "routeName": {
            "location": "uri",
            "locationName": "routeName"
          },
          "virtualRouterName": {
            "location": "uri",
            "locationName": "virtualRouterName"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "route"
        ],
        "members": {
          "route": {
            "shape": "S2t"
          }
        },
        "payload": "route"
      },
      "idempotent": true
    },
    "DeleteVirtualGateway": {
      "http": {
        "method": "DELETE",
        "requestUri": "/v20190125/meshes/{meshName}/virtualGateways/{virtualGatewayName}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "meshName",
          "virtualGatewayName"
        ],
        "members": {
          "meshName": {
            "location": "uri",
            "locationName": "meshName"
          },
          "meshOwner": {
            "location": "querystring",
            "locationName": "meshOwner"
          },
          "virtualGatewayName": {
            "location": "uri",
            "locationName": "virtualGatewayName"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "virtualGateway"
        ],
        "members": {
          "virtualGateway": {
            "shape": "S47"
          }
        },
        "payload": "virtualGateway"
      },
      "idempotent": true
    },
    "DeleteVirtualNode": {
      "http": {
        "method": "DELETE",
        "requestUri": "/v20190125/meshes/{meshName}/virtualNodes/{virtualNodeName}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "meshName",
          "virtualNodeName"
        ],
        "members": {
          "meshName": {
            "location": "uri",
            "locationName": "meshName"
          },
          "meshOwner": {
            "location": "querystring",
            "locationName": "meshOwner"
          },
          "virtualNodeName": {
            "location": "uri",
            "locationName": "virtualNodeName"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "virtualNode"
        ],
        "members": {
          "virtualNode": {
            "shape": "S5t"
          }
        },
        "payload": "virtualNode"
      },
      "idempotent": true
    },
    "DeleteVirtualRouter": {
      "http": {
        "method": "DELETE",
        "requestUri": "/v20190125/meshes/{meshName}/virtualRouters/{virtualRouterName}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "meshName",
          "virtualRouterName"
        ],
        "members": {
          "meshName": {
            "location": "uri",
            "locationName": "meshName"
          },
          "meshOwner": {
            "location": "querystring",
            "locationName": "meshOwner"
          },
          "virtualRouterName": {
            "location": "uri",
            "locationName": "virtualRouterName"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "virtualRouter"
        ],
        "members": {
          "virtualRouter": {
            "shape": "S61"
          }
        },
        "payload": "virtualRouter"
      },
      "idempotent": true
    },
    "DeleteVirtualService": {
      "http": {
        "method": "DELETE",
        "requestUri": "/v20190125/meshes/{meshName}/virtualServices/{virtualServiceName}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "meshName",
          "virtualServiceName"
        ],
        "members": {
          "meshName": {
            "location": "uri",
            "locationName": "meshName"
          },
          "meshOwner": {
            "location": "querystring",
            "locationName": "meshOwner"
          },
          "virtualServiceName": {
            "location": "uri",
            "locationName": "virtualServiceName"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "virtualService"
        ],
        "members": {
          "virtualService": {
            "shape": "S6a"
          }
        },
        "payload": "virtualService"
      },
      "idempotent": true
    },
    "DescribeGatewayRoute": {
      "http": {
        "method": "GET",
        "requestUri": "/v20190125/meshes/{meshName}/virtualGateway/{virtualGatewayName}/gatewayRoutes/{gatewayRouteName}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "gatewayRouteName",
          "meshName",
          "virtualGatewayName"
        ],
        "members": {
          "gatewayRouteName": {
            "location": "uri",
            "locationName": "gatewayRouteName"
          },
          "meshName": {
            "location": "uri",
            "locationName": "meshName"
          },
          "meshOwner": {
            "location": "querystring",
            "locationName": "meshOwner"
          },
          "virtualGatewayName": {
            "location": "uri",
            "locationName": "virtualGatewayName"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "gatewayRoute"
        ],
        "members": {
          "gatewayRoute": {
            "shape": "S1e"
          }
        },
        "payload": "gatewayRoute"
      }
    },
    "DescribeMesh": {
      "http": {
        "method": "GET",
        "requestUri": "/v20190125/meshes/{meshName}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "meshName"
        ],
        "members": {
          "meshName": {
            "location": "uri",
            "locationName": "meshName"
          },
          "meshOwner": {
            "location": "querystring",
            "locationName": "meshOwner"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "mesh"
        ],
        "members": {
          "mesh": {
            "shape": "S1p"
          }
        },
        "payload": "mesh"
      }
    },
    "DescribeRoute": {
      "http": {
        "method": "GET",
        "requestUri": "/v20190125/meshes/{meshName}/virtualRouter/{virtualRouterName}/routes/{routeName}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "meshName",
          "routeName",
          "virtualRouterName"
        ],
        "members": {
          "meshName": {
            "location": "uri",
            "locationName": "meshName"
          },
          "meshOwner": {
            "location": "querystring",
            "locationName": "meshOwner"
          },
          "routeName": {
            "location": "uri",
            "locationName": "routeName"
          },
          "virtualRouterName": {
            "location": "uri",
            "locationName": "virtualRouterName"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "route"
        ],
        "members": {
          "route": {
            "shape": "S2t"
          }
        },
        "payload": "route"
      }
    },
    "DescribeVirtualGateway": {
      "http": {
        "method": "GET",
        "requestUri": "/v20190125/meshes/{meshName}/virtualGateways/{virtualGatewayName}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "meshName",
          "virtualGatewayName"
        ],
        "members": {
          "meshName": {
            "location": "uri",
            "locationName": "meshName"
          },
          "meshOwner": {
            "location": "querystring",
            "locationName": "meshOwner"
          },
          "virtualGatewayName": {
            "location": "uri",
            "locationName": "virtualGatewayName"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "virtualGateway"
        ],
        "members": {
          "virtualGateway": {
            "shape": "S47"
          }
        },
        "payload": "virtualGateway"
      }
    },
    "DescribeVirtualNode": {
      "http": {
        "method": "GET",
        "requestUri": "/v20190125/meshes/{meshName}/virtualNodes/{virtualNodeName}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "meshName",
          "virtualNodeName"
        ],
        "members": {
          "meshName": {
            "location": "uri",
            "locationName": "meshName"
          },
          "meshOwner": {
            "location": "querystring",
            "locationName": "meshOwner"
          },
          "virtualNodeName": {
            "location": "uri",
            "locationName": "virtualNodeName"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "virtualNode"
        ],
        "members": {
          "virtualNode": {
            "shape": "S5t"
          }
        },
        "payload": "virtualNode"
      }
    },
    "DescribeVirtualRouter": {
      "http": {
        "method": "GET",
        "requestUri": "/v20190125/meshes/{meshName}/virtualRouters/{virtualRouterName}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "meshName",
          "virtualRouterName"
        ],
        "members": {
          "meshName": {
            "location": "uri",
            "locationName": "meshName"
          },
          "meshOwner": {
            "location": "querystring",
            "locationName": "meshOwner"
          },
          "virtualRouterName": {
            "location": "uri",
            "locationName": "virtualRouterName"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "virtualRouter"
        ],
        "members": {
          "virtualRouter": {
            "shape": "S61"
          }
        },
        "payload": "virtualRouter"
      }
    },
    "DescribeVirtualService": {
      "http": {
        "method": "GET",
        "requestUri": "/v20190125/meshes/{meshName}/virtualServices/{virtualServiceName}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "meshName",
          "virtualServiceName"
        ],
        "members": {
          "meshName": {
            "location": "uri",
            "locationName": "meshName"
          },
          "meshOwner": {
            "location": "querystring",
            "locationName": "meshOwner"
          },
          "virtualServiceName": {
            "location": "uri",
            "locationName": "virtualServiceName"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "virtualService"
        ],
        "members": {
          "virtualService": {
            "shape": "S6a"
          }
        },
        "payload": "virtualService"
      }
    },
    "ListGatewayRoutes": {
      "http": {
        "method": "GET",
        "requestUri": "/v20190125/meshes/{meshName}/virtualGateway/{virtualGatewayName}/gatewayRoutes",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "meshName",
          "virtualGatewayName"
        ],
        "members": {
          "limit": {
            "location": "querystring",
            "locationName": "limit",
            "type": "integer"
          },
          "meshName": {
            "location": "uri",
            "locationName": "meshName"
          },
          "meshOwner": {
            "location": "querystring",
            "locationName": "meshOwner"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "virtualGatewayName": {
            "location": "uri",
            "locationName": "virtualGatewayName"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "gatewayRoutes"
        ],
        "members": {
          "gatewayRoutes": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "arn",
                "createdAt",
                "gatewayRouteName",
                "lastUpdatedAt",
                "meshName",
                "meshOwner",
                "resourceOwner",
                "version",
                "virtualGatewayName"
              ],
              "members": {
                "arn": {},
                "createdAt": {
                  "type": "timestamp"
                },
                "gatewayRouteName": {},
                "lastUpdatedAt": {
                  "type": "timestamp"
                },
                "meshName": {},
                "meshOwner": {},
                "resourceOwner": {},
                "version": {
                  "type": "long"
                },
                "virtualGatewayName": {}
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListMeshes": {
      "http": {
        "method": "GET",
        "requestUri": "/v20190125/meshes",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "limit": {
            "location": "querystring",
            "locationName": "limit",
            "type": "integer"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "meshes"
        ],
        "members": {
          "meshes": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "arn",
                "createdAt",
                "lastUpdatedAt",
                "meshName",
                "meshOwner",
                "resourceOwner",
                "version"
              ],
              "members": {
                "arn": {},
                "createdAt": {
                  "type": "timestamp"
                },
                "lastUpdatedAt": {
                  "type": "timestamp"
                },
                "meshName": {},
                "meshOwner": {},
                "resourceOwner": {},
                "version": {
                  "type": "long"
                }
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListRoutes": {
      "http": {
        "method": "GET",
        "requestUri": "/v20190125/meshes/{meshName}/virtualRouter/{virtualRouterName}/routes",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "meshName",
          "virtualRouterName"
        ],
        "members": {
          "limit": {
            "location": "querystring",
            "locationName": "limit",
            "type": "integer"
          },
          "meshName": {
            "location": "uri",
            "locationName": "meshName"
          },
          "meshOwner": {
            "location": "querystring",
            "locationName": "meshOwner"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "virtualRouterName": {
            "location": "uri",
            "locationName": "virtualRouterName"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "routes"
        ],
        "members": {
          "nextToken": {},
          "routes": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "arn",
                "createdAt",
                "lastUpdatedAt",
                "meshName",
                "meshOwner",
                "resourceOwner",
                "routeName",
                "version",
                "virtualRouterName"
              ],
              "members": {
                "arn": {},
                "createdAt": {
                  "type": "timestamp"
                },
                "lastUpdatedAt": {
                  "type": "timestamp"
                },
                "meshName": {},
                "meshOwner": {},
                "resourceOwner": {},
                "routeName": {},
                "version": {
                  "type": "long"
                },
                "virtualRouterName": {}
              }
            }
          }
        }
      }
    },
    "ListTagsForResource": {
      "http": {
        "method": "GET",
        "requestUri": "/v20190125/tags",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "resourceArn"
        ],
        "members": {
          "limit": {
            "location": "querystring",
            "locationName": "limit",
            "type": "integer"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "resourceArn": {
            "location": "querystring",
            "locationName": "resourceArn"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "tags"
        ],
        "members": {
          "nextToken": {},
          "tags": {
            "shape": "S19"
          }
        }
      }
    },
    "ListVirtualGateways": {
      "http": {
        "method": "GET",
        "requestUri": "/v20190125/meshes/{meshName}/virtualGateways",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "meshName"
        ],
        "members": {
          "limit": {
            "location": "querystring",
            "locationName": "limit",
            "type": "integer"
          },
          "meshName": {
            "location": "uri",
            "locationName": "meshName"
          },
          "meshOwner": {
            "location": "querystring",
            "locationName": "meshOwner"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "virtualGateways"
        ],
        "members": {
          "nextToken": {},
          "virtualGateways": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "arn",
                "createdAt",
                "lastUpdatedAt",
                "meshName",
                "meshOwner",
                "resourceOwner",
                "version",
                "virtualGatewayName"
              ],
              "members": {
                "arn": {},
                "createdAt": {
                  "type": "timestamp"
                },
                "lastUpdatedAt": {
                  "type": "timestamp"
                },
                "meshName": {},
                "meshOwner": {},
                "resourceOwner": {},
                "version": {
                  "type": "long"
                },
                "virtualGatewayName": {}
              }
            }
          }
        }
      }
    },
    "ListVirtualNodes": {
      "http": {
        "method": "GET",
        "requestUri": "/v20190125/meshes/{meshName}/virtualNodes",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "meshName"
        ],
        "members": {
          "limit": {
            "location": "querystring",
            "locationName": "limit",
            "type": "integer"
          },
          "meshName": {
            "location": "uri",
            "locationName": "meshName"
          },
          "meshOwner": {
            "location": "querystring",
            "locationName": "meshOwner"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "virtualNodes"
        ],
        "members": {
          "nextToken": {},
          "virtualNodes": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "arn",
                "createdAt",
                "lastUpdatedAt",
                "meshName",
                "meshOwner",
                "resourceOwner",
                "version",
                "virtualNodeName"
              ],
              "members": {
                "arn": {},
                "createdAt": {
                  "type": "timestamp"
                },
                "lastUpdatedAt": {
                  "type": "timestamp"
                },
                "meshName": {},
                "meshOwner": {},
                "resourceOwner": {},
                "version": {
                  "type": "long"
                },
                "virtualNodeName": {}
              }
            }
          }
        }
      }
    },
    "ListVirtualRouters": {
      "http": {
        "method": "GET",
        "requestUri": "/v20190125/meshes/{meshName}/virtualRouters",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "meshName"
        ],
        "members": {
          "limit": {
            "location": "querystring",
            "locationName": "limit",
            "type": "integer"
          },
          "meshName": {
            "location": "uri",
            "locationName": "meshName"
          },
          "meshOwner": {
            "location": "querystring",
            "locationName": "meshOwner"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "virtualRouters"
        ],
        "members": {
          "nextToken": {},
          "virtualRouters": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "arn",
                "createdAt",
                "lastUpdatedAt",
                "meshName",
                "meshOwner",
                "resourceOwner",
                "version",
                "virtualRouterName"
              ],
              "members": {
                "arn": {},
                "createdAt": {
                  "type": "timestamp"
                },
                "lastUpdatedAt": {
                  "type": "timestamp"
                },
                "meshName": {},
                "meshOwner": {},
                "resourceOwner": {},
                "version": {
                  "type": "long"
                },
                "virtualRouterName": {}
              }
            }
          }
        }
      }
    },
    "ListVirtualServices": {
      "http": {
        "method": "GET",
        "requestUri": "/v20190125/meshes/{meshName}/virtualServices",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "meshName"
        ],
        "members": {
          "limit": {
            "location": "querystring",
            "locationName": "limit",
            "type": "integer"
          },
          "meshName": {
            "location": "uri",
            "locationName": "meshName"
          },
          "meshOwner": {
            "location": "querystring",
            "locationName": "meshOwner"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "virtualServices"
        ],
        "members": {
          "nextToken": {},
          "virtualServices": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "arn",
                "createdAt",
                "lastUpdatedAt",
                "meshName",
                "meshOwner",
                "resourceOwner",
                "version",
                "virtualServiceName"
              ],
              "members": {
                "arn": {},
                "createdAt": {
                  "type": "timestamp"
                },
                "lastUpdatedAt": {
                  "type": "timestamp"
                },
                "meshName": {},
                "meshOwner": {},
                "resourceOwner": {},
                "version": {
                  "type": "long"
                },
                "virtualServiceName": {}
              }
            }
          }
        }
      }
    },
    "TagResource": {
      "http": {
        "method": "PUT",
        "requestUri": "/v20190125/tag",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "resourceArn",
          "tags"
        ],
        "members": {
          "resourceArn": {
            "location": "querystring",
            "locationName": "resourceArn"
          },
          "tags": {
            "shape": "S19"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    },
    "UntagResource": {
      "http": {
        "method": "PUT",
        "requestUri": "/v20190125/untag",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "resourceArn",
          "tagKeys"
        ],
        "members": {
          "resourceArn": {
            "location": "querystring",
            "locationName": "resourceArn"
          },
          "tagKeys": {
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    },
    "UpdateGatewayRoute": {
      "http": {
        "method": "PUT",
        "requestUri": "/v20190125/meshes/{meshName}/virtualGateway/{virtualGatewayName}/gatewayRoutes/{gatewayRouteName}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "gatewayRouteName",
          "meshName",
          "spec",
          "virtualGatewayName"
        ],
        "members": {
          "clientToken": {
            "idempotencyToken": true
          },
          "gatewayRouteName": {
            "location": "uri",
            "locationName": "gatewayRouteName"
          },
          "meshName": {
            "location": "uri",
            "locationName": "meshName"
          },
          "meshOwner": {
            "location": "querystring",
            "locationName": "meshOwner"
          },
          "spec": {
            "shape": "S5"
          },
          "virtualGatewayName": {
            "location": "uri",
            "locationName": "virtualGatewayName"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "gatewayRoute"
        ],
        "members": {
          "gatewayRoute": {
            "shape": "S1e"
          }
        },
        "payload": "gatewayRoute"
      },
      "idempotent": true
    },
    "UpdateMesh": {
      "http": {
        "method": "PUT",
        "requestUri": "/v20190125/meshes/{meshName}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "meshName"
        ],
        "members": {
          "clientToken": {
            "idempotencyToken": true
          },
          "meshName": {
            "location": "uri",
            "locationName": "meshName"
          },
          "spec": {
            "shape": "S1l"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "mesh"
        ],
        "members": {
          "mesh": {
            "shape": "S1p"
          }
        },
        "payload": "mesh"
      },
      "idempotent": true
    },
    "UpdateRoute": {
      "http": {
        "method": "PUT",
        "requestUri": "/v20190125/meshes/{meshName}/virtualRouter/{virtualRouterName}/routes/{routeName}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "meshName",
          "routeName",
          "spec",
          "virtualRouterName"
        ],
        "members": {
          "clientToken": {
            "idempotencyToken": true
          },
          "meshName": {
            "location": "uri",
            "locationName": "meshName"
          },
          "meshOwner": {
            "location": "querystring",
            "locationName": "meshOwner"
          },
          "routeName": {
            "location": "uri",
            "locationName": "routeName"
          },
          "spec": {
            "shape": "S1t"
          },
          "virtualRouterName": {
            "location": "uri",
            "locationName": "virtualRouterName"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "route"
        ],
        "members": {
          "route": {
            "shape": "S2t"
          }
        },
        "payload": "route"
      },
      "idempotent": true
    },
    "UpdateVirtualGateway": {
      "http": {
        "method": "PUT",
        "requestUri": "/v20190125/meshes/{meshName}/virtualGateways/{virtualGatewayName}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "meshName",
          "spec",
          "virtualGatewayName"
        ],
        "members": {
          "clientToken": {
            "idempotencyToken": true
          },
          "meshName": {
            "location": "uri",
            "locationName": "meshName"
          },
          "meshOwner": {
            "location": "querystring",
            "locationName": "meshOwner"
          },
          "spec": {
            "shape": "S2x"
          },
          "virtualGatewayName": {
            "location": "uri",
            "locationName": "virtualGatewayName"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "virtualGateway"
        ],
        "members": {
          "virtualGateway": {
            "shape": "S47"
          }
        },
        "payload": "virtualGateway"
      },
      "idempotent": true
    },
    "UpdateVirtualNode": {
      "http": {
        "method": "PUT",
        "requestUri": "/v20190125/meshes/{meshName}/virtualNodes/{virtualNodeName}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "meshName",
          "spec",
          "virtualNodeName"
        ],
        "members": {
          "clientToken": {
            "idempotencyToken": true
          },
          "meshName": {
            "location": "uri",
            "locationName": "meshName"
          },
          "meshOwner": {
            "location": "querystring",
            "locationName": "meshOwner"
          },
          "spec": {
            "shape": "S4b"
          },
          "virtualNodeName": {
            "location": "uri",
            "locationName": "virtualNodeName"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "virtualNode"
        ],
        "members": {
          "virtualNode": {
            "shape": "S5t"
          }
        },
        "payload": "virtualNode"
      },
      "idempotent": true
    },
    "UpdateVirtualRouter": {
      "http": {
        "method": "PUT",
        "requestUri": "/v20190125/meshes/{meshName}/virtualRouters/{virtualRouterName}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "meshName",
          "spec",
          "virtualRouterName"
        ],
        "members": {
          "clientToken": {
            "idempotencyToken": true
          },
          "meshName": {
            "location": "uri",
            "locationName": "meshName"
          },
          "meshOwner": {
            "location": "querystring",
            "locationName": "meshOwner"
          },
          "spec": {
            "shape": "S5x"
          },
          "virtualRouterName": {
            "location": "uri",
            "locationName": "virtualRouterName"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "virtualRouter"
        ],
        "members": {
          "virtualRouter": {
            "shape": "S61"
          }
        },
        "payload": "virtualRouter"
      },
      "idempotent": true
    },
    "UpdateVirtualService": {
      "http": {
        "method": "PUT",
        "requestUri": "/v20190125/meshes/{meshName}/virtualServices/{virtualServiceName}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "meshName",
          "spec",
          "virtualServiceName"
        ],
        "members": {
          "clientToken": {
            "idempotencyToken": true
          },
          "meshName": {
            "location": "uri",
            "locationName": "meshName"
          },
          "meshOwner": {
            "location": "querystring",
            "locationName": "meshOwner"
          },
          "spec": {
            "shape": "S65"
          },
          "virtualServiceName": {
            "location": "uri",
            "locationName": "virtualServiceName"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "virtualService"
        ],
        "members": {
          "virtualService": {
            "shape": "S6a"
          }
        },
        "payload": "virtualService"
      },
      "idempotent": true
    }
  },
  "shapes": {
    "S5": {
      "type": "structure",
      "members": {
        "grpcRoute": {
          "type": "structure",
          "required": [
            "action",
            "match"
          ],
          "members": {
            "action": {
              "type": "structure",
              "required": [
                "target"
              ],
              "members": {
                "rewrite": {
                  "type": "structure",
                  "members": {
                    "hostname": {
                      "shape": "S9"
                    }
                  }
                },
                "target": {
                  "shape": "Sb"
                }
              }
            },
            "match": {
              "type": "structure",
              "members": {
                "hostname": {
                  "shape": "Se"
                },
                "metadata": {
                  "type": "list",
                  "member": {
                    "type": "structure",
                    "required": [
                      "name"
                    ],
                    "members": {
                      "invert": {
                        "type": "boolean"
                      },
                      "match": {
                        "type": "structure",
                        "members": {
                          "exact": {},
                          "prefix": {},
                          "range": {
                            "shape": "Sm"
                          },
                          "regex": {},
                          "suffix": {}
                        },
                        "union": true
                      },
                      "name": {}
                    }
                  }
                },
                "serviceName": {}
              }
            }
          }
        },
        "http2Route": {
          "shape": "Sq"
        },
        "httpRoute": {
          "shape": "Sq"
        },
        "priority": {
          "type": "integer"
        }
      }
    },
    "S9": {
      "type": "structure",
      "members": {
        "defaultTargetHostname": {}
      }
    },
    "Sb": {
      "type": "structure",
      "required": [
        "virtualService"
      ],
      "members": {
        "virtualService": {
          "type": "structure",
          "required": [
            "virtualServiceName"
          ],
          "members": {
            "virtualServiceName": {}
          }
        }
      }
    },
    "Se": {
      "type": "structure",
      "members": {
        "exact": {},
        "suffix": {}
      }
    },
    "Sm": {
      "type": "structure",
      "required": [
        "end",
        "start"
      ],
      "members": {
        "end": {
          "type": "long"
        },
        "start": {
          "type": "long"
        }
      }
    },
    "Sq": {
      "type": "structure",
      "required": [
        "action",
        "match"
      ],
      "members": {
        "action": {
          "type": "structure",
          "required": [
            "target"
          ],
          "members": {
            "rewrite": {
              "type": "structure",
              "members": {
                "hostname": {
                  "shape": "S9"
                },
                "path": {
                  "type": "structure",
                  "members": {
                    "exact": {}
                  }
                },
                "prefix": {
                  "type": "structure",
                  "members": {
                    "defaultPrefix": {},
                    "value": {}
                  }
                }
              }
            },
            "target": {
              "shape": "Sb"
            }
          }
        },
        "match": {
          "type": "structure",
          "members": {
            "headers": {
              "type": "list",
              "member": {
                "type": "structure",
                "required": [
                  "name"
                ],
                "members": {
                  "invert": {
                    "type": "boolean"
                  },
                  "match": {
                    "shape": "S10"
                  },
                  "name": {}
                }
              }
            },
            "hostname": {
              "shape": "Se"
            },
            "method": {},
            "path": {
              "shape": "S12"
            },
            "prefix": {},
            "queryParameters": {
              "shape": "S14"
            }
          }
        }
      }
    },
    "S10": {
      "type": "structure",
      "members": {
        "exact": {},
        "prefix": {},
        "range": {
          "shape": "Sm"
        },
        "regex": {},
        "suffix": {}
      },
      "union": true
    },
    "S12": {
      "type": "structure",
      "members": {
        "exact": {},
        "regex": {}
      }
    },
    "S14": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "name"
        ],
        "members": {
          "match": {
            "type": "structure",
            "members": {
              "exact": {}
            }
          },
          "name": {}
        }
      }
    },
    "S19": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "key",
          "value"
        ],
        "members": {
          "key": {},
          "value": {}
        }
      }
    },
    "S1e": {
      "type": "structure",
      "required": [
        "gatewayRouteName",
        "meshName",
        "metadata",
        "spec",
        "status",
        "virtualGatewayName"
      ],
      "members": {
        "gatewayRouteName": {},
        "meshName": {},
        "metadata": {
          "shape": "S1f"
        },
        "spec": {
          "shape": "S5"
        },
        "status": {
          "type": "structure",
          "required": [
            "status"
          ],
          "members": {
            "status": {}
          }
        },
        "virtualGatewayName": {}
      }
    },
    "S1f": {
      "type": "structure",
      "required": [
        "arn",
        "createdAt",
        "lastUpdatedAt",
        "meshOwner",
        "resourceOwner",
        "uid",
        "version"
      ],
      "members": {
        "arn": {},
        "createdAt": {
          "type": "timestamp"
        },
        "lastUpdatedAt": {
          "type": "timestamp"
        },
        "meshOwner": {},
        "resourceOwner": {},
        "uid": {},
        "version": {
          "type": "long"
        }
      }
    },
    "S1l": {
      "type": "structure",
      "members": {
        "egressFilter": {
          "type": "structure",
          "required": [
            "type"
          ],
          "members": {
            "type": {}
          }
        }
      }
    },
    "S1p": {
      "type": "structure",
      "required": [
        "meshName",
        "metadata",
        "spec",
        "status"
      ],
      "members": {
        "meshName": {},
        "metadata": {
          "shape": "S1f"
        },
        "spec": {
          "shape": "S1l"
        },
        "status": {
          "type": "structure",
          "members": {
            "status": {}
          }
        }
      }
    },
    "S1t": {
      "type": "structure",
      "members": {
        "grpcRoute": {
          "type": "structure",
          "required": [
            "action",
            "match"
          ],
          "members": {
            "action": {
              "type": "structure",
              "required": [
                "weightedTargets"
              ],
              "members": {
                "weightedTargets": {
                  "shape": "S1w"
                }
              }
            },
            "match": {
              "type": "structure",
              "members": {
                "metadata": {
                  "type": "list",
                  "member": {
                    "type": "structure",
                    "required": [
                      "name"
                    ],
                    "members": {
                      "invert": {
                        "type": "boolean"
                      },
                      "match": {
                        "type": "structure",
                        "members": {
                          "exact": {},
                          "prefix": {},
                          "range": {
                            "shape": "Sm"
                          },
                          "regex": {},
                          "suffix": {}
                        },
                        "union": true
                      },
                      "name": {}
                    }
                  }
                },
                "methodName": {},
                "serviceName": {}
              }
            },
            "retryPolicy": {
              "type": "structure",
              "required": [
                "maxRetries",
                "perRetryTimeout"
              ],
              "members": {
                "grpcRetryEvents": {
                  "type": "list",
                  "member": {}
                },
                "httpRetryEvents": {
                  "shape": "S27"
                },
                "maxRetries": {
                  "type": "long"
                },
                "perRetryTimeout": {
                  "shape": "S2a"
                },
                "tcpRetryEvents": {
                  "shape": "S2d"
                }
              }
            },
            "timeout": {
              "shape": "S2f"
            }
          }
        },
        "http2Route": {
          "shape": "S2g"
        },
        "httpRoute": {
          "shape": "S2g"
        },
        "priority": {
          "type": "integer"
        },
        "tcpRoute": {
          "type": "structure",
          "required": [
            "action"
          ],
          "members": {
            "action": {
              "type": "structure",
              "required": [
                "weightedTargets"
              ],
              "members": {
                "weightedTargets": {
                  "shape": "S1w"
                }
              }
            },
            "timeout": {
              "shape": "S2r"
            }
          }
        }
      }
    },
    "S1w": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "virtualNode",
          "weight"
        ],
        "members": {
          "virtualNode": {},
          "weight": {
            "type": "integer"
          }
        }
      }
    },
    "S27": {
      "type": "list",
      "member": {}
    },
    "S2a": {
      "type": "structure",
      "members": {
        "unit": {},
        "value": {
          "type": "long"
        }
      }
    },
    "S2d": {
      "type": "list",
      "member": {}
    },
    "S2f": {
      "type": "structure",
      "members": {
        "idle": {
          "shape": "S2a"
        },
        "perRequest": {
          "shape": "S2a"
        }
      }
    },
    "S2g": {
      "type": "structure",
      "required": [
        "action",
        "match"
      ],
      "members": {
        "action": {
          "type": "structure",
          "required": [
            "weightedTargets"
          ],
          "members": {
            "weightedTargets": {
              "shape": "S1w"
            }
          }
        },
        "match": {
          "type": "structure",
          "members": {
            "headers": {
              "type": "list",
              "member": {
                "type": "structure",
                "required": [
                  "name"
                ],
                "members": {
                  "invert": {
                    "type": "boolean"
                  },
                  "match": {
                    "shape": "S10"
                  },
                  "name": {}
                }
              }
            },
            "method": {},
            "path": {
              "shape": "S12"
            },
            "prefix": {},
            "queryParameters": {
              "shape": "S14"
            },
            "scheme": {}
          }
        },
        "retryPolicy": {
          "type": "structure",
          "required": [
            "maxRetries",
            "perRetryTimeout"
          ],
          "members": {
            "httpRetryEvents": {
              "shape": "S27"
            },
            "maxRetries": {
              "type": "long"
            },
            "perRetryTimeout": {
              "shape": "S2a"
            },
            "tcpRetryEvents": {
              "shape": "S2d"
            }
          }
        },
        "timeout": {
          "shape": "S2n"
        }
      }
    },
    "S2n": {
      "type": "structure",
      "members": {
        "idle": {
          "shape": "S2a"
        },
        "perRequest": {
          "shape": "S2a"
        }
      }
    },
    "S2r": {
      "type": "structure",
      "members": {
        "idle": {
          "shape": "S2a"
        }
      }
    },
    "S2t": {
      "type": "structure",
      "required": [
        "meshName",
        "metadata",
        "routeName",
        "spec",
        "status",
        "virtualRouterName"
      ],
      "members": {
        "meshName": {},
        "metadata": {
          "shape": "S1f"
        },
        "routeName": {},
        "spec": {
          "shape": "S1t"
        },
        "status": {
          "type": "structure",
          "required": [
            "status"
          ],
          "members": {
            "status": {}
          }
        },
        "virtualRouterName": {}
      }
    },
    "S2x": {
      "type": "structure",
      "required": [
        "listeners"
      ],
      "members": {
        "backendDefaults": {
          "type": "structure",
          "members": {
            "clientPolicy": {
              "type": "structure",
              "members": {
                "tls": {
                  "type": "structure",
                  "required": [
                    "validation"
                  ],
                  "members": {
                    "certificate": {
                      "type": "structure",
                      "members": {
                        "file": {
                          "shape": "S32"
                        },
                        "sds": {
                          "shape": "S34"
                        }
                      },
                      "union": true
                    },
                    "enforce": {
                      "type": "boolean"
                    },
                    "ports": {
                      "shape": "S36"
                    },
                    "validation": {
                      "type": "structure",
                      "required": [
                        "trust"
                      ],
                      "members": {
                        "subjectAlternativeNames": {
                          "shape": "S39"
                        },
                        "trust": {
                          "type": "structure",
                          "members": {
                            "acm": {
                              "type": "structure",
                              "required": [
                                "certificateAuthorityArns"
                              ],
                              "members": {
                                "certificateAuthorityArns": {
                                  "type": "list",
                                  "member": {}
                                }
                              }
                            },
                            "file": {
                              "shape": "S3g"
                            },
                            "sds": {
                              "shape": "S3h"
                            }
                          },
                          "union": true
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        },
        "listeners": {
          "type": "list",
          "member": {
            "type": "structure",
            "required": [
              "portMapping"
            ],
            "members": {
              "connectionPool": {
                "type": "structure",
                "members": {
                  "grpc": {
                    "type": "structure",
                    "required": [
                      "maxRequests"
                    ],
                    "members": {
                      "maxRequests": {
                        "type": "integer"
                      }
                    }
                  },
                  "http": {
                    "type": "structure",
                    "required": [
                      "maxConnections"
                    ],
                    "members": {
                      "maxConnections": {
                        "type": "integer"
                      },
                      "maxPendingRequests": {
                        "type": "integer"
                      }
                    }
                  },
                  "http2": {
                    "type": "structure",
                    "required": [
                      "maxRequests"
                    ],
                    "members": {
                      "maxRequests": {
                        "type": "integer"
                      }
                    }
                  }
                },
                "union": true
              },
              "healthCheck": {
                "type": "structure",
                "required": [
                  "healthyThreshold",
                  "intervalMillis",
                  "protocol",
                  "timeoutMillis",
                  "unhealthyThreshold"
                ],
                "members": {
                  "healthyThreshold": {
                    "type": "integer"
                  },
                  "intervalMillis": {
                    "type": "long"
                  },
                  "path": {},
                  "port": {
                    "type": "integer"
                  },
                  "protocol": {},
                  "timeoutMillis": {
                    "type": "long"
                  },
                  "unhealthyThreshold": {
                    "type": "integer"
                  }
                }
              },
              "portMapping": {
                "type": "structure",
                "required": [
                  "port",
                  "protocol"
                ],
                "members": {
                  "port": {
                    "type": "integer"
                  },
                  "protocol": {}
                }
              },
              "tls": {
                "type": "structure",
                "required": [
                  "certificate",
                  "mode"
                ],
                "members": {
                  "certificate": {
                    "type": "structure",
                    "members": {
                      "acm": {
                        "type": "structure",
                        "required": [
                          "certificateArn"
                        ],
                        "members": {
                          "certificateArn": {}
                        }
                      },
                      "file": {
                        "shape": "S32"
                      },
                      "sds": {
                        "shape": "S34"
                      }
                    },
                    "union": true
                  },
                  "mode": {},
                  "validation": {
                    "type": "structure",
                    "required": [
                      "trust"
                    ],
                    "members": {
                      "subjectAlternativeNames": {
                        "shape": "S39"
                      },
                      "trust": {
                        "type": "structure",
                        "members": {
                          "file": {
                            "shape": "S3g"
                          },
                          "sds": {
                            "shape": "S3h"
                          }
                        },
                        "union": true
                      }
                    }
                  }
                }
              }
            }
          }
        },
        "logging": {
          "type": "structure",
          "members": {
            "accessLog": {
              "type": "structure",
              "members": {
                "file": {
                  "type": "structure",
                  "required": [
                    "path"
                  ],
                  "members": {
                    "path": {}
                  }
                }
              },
              "union": true
            }
          }
        }
      }
    },
    "S32": {
      "type": "structure",
      "required": [
        "certificateChain",
        "privateKey"
      ],
      "members": {
        "certificateChain": {},
        "privateKey": {}
      }
    },
    "S34": {
      "type": "structure",
      "required": [
        "secretName"
      ],
      "members": {
        "secretName": {}
      }
    },
    "S36": {
      "type": "list",
      "member": {
        "type": "integer"
      }
    },
    "S39": {
      "type": "structure",
      "required": [
        "match"
      ],
      "members": {
        "match": {
          "type": "structure",
          "required": [
            "exact"
          ],
          "members": {
            "exact": {
              "type": "list",
              "member": {}
            }
          }
        }
      }
    },
    "S3g": {
      "type": "structure",
      "required": [
        "certificateChain"
      ],
      "members": {
        "certificateChain": {}
      }
    },
    "S3h": {
      "type": "structure",
      "required": [
        "secretName"
      ],
      "members": {
        "secretName": {}
      }
    },
    "S47": {
      "type": "structure",
      "required": [
        "meshName",
        "metadata",
        "spec",
        "status",
        "virtualGatewayName"
      ],
      "members": {
        "meshName": {},
        "metadata": {
          "shape": "S1f"
        },
        "spec": {
          "shape": "S2x"
        },
        "status": {
          "type": "structure",
          "required": [
            "status"
          ],
          "members": {
            "status": {}
          }
        },
        "virtualGatewayName": {}
      }
    },
    "S4b": {
      "type": "structure",
      "members": {
        "backendDefaults": {
          "type": "structure",
          "members": {
            "clientPolicy": {
              "shape": "S4d"
            }
          }
        },
        "backends": {
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "virtualService": {
                "type": "structure",
                "required": [
                  "virtualServiceName"
                ],
                "members": {
                  "clientPolicy": {
                    "shape": "S4d"
                  },
                  "virtualServiceName": {}
                }
              }
            },
            "union": true
          }
        },
        "listeners": {
          "type": "list",
          "member": {
            "type": "structure",
            "required": [
              "portMapping"
            ],
            "members": {
              "connectionPool": {
                "type": "structure",
                "members": {
                  "grpc": {
                    "type": "structure",
                    "required": [
                      "maxRequests"
                    ],
                    "members": {
                      "maxRequests": {
                        "type": "integer"
                      }
                    }
                  },
                  "http": {
                    "type": "structure",
                    "required": [
                      "maxConnections"
                    ],
                    "members": {
                      "maxConnections": {
                        "type": "integer"
                      },
                      "maxPendingRequests": {
                        "type": "integer"
                      }
                    }
                  },
                  "http2": {
                    "type": "structure",
                    "required": [
                      "maxRequests"
                    ],
                    "members": {
                      "maxRequests": {
                        "type": "integer"
                      }
                    }
                  },
                  "tcp": {
                    "type": "structure",
                    "required": [
                      "maxConnections"
                    ],
                    "members": {
                      "maxConnections": {
                        "type": "integer"
                      }
                    }
                  }
                },
                "union": true
              },
              "healthCheck": {
                "type": "structure",
                "required": [
                  "healthyThreshold",
                  "intervalMillis",
                  "protocol",
                  "timeoutMillis",
                  "unhealthyThreshold"
                ],
                "members": {
                  "healthyThreshold": {
                    "type": "integer"
                  },
                  "intervalMillis": {
                    "type": "long"
                  },
                  "path": {},
                  "port": {
                    "type": "integer"
                  },
                  "protocol": {},
                  "timeoutMillis": {
                    "type": "long"
                  },
                  "unhealthyThreshold": {
                    "type": "integer"
                  }
                }
              },
              "outlierDetection": {
                "type": "structure",
                "required": [
                  "baseEjectionDuration",
                  "interval",
                  "maxEjectionPercent",
                  "maxServerErrors"
                ],
                "members": {
                  "baseEjectionDuration": {
                    "shape": "S2a"
                  },
                  "interval": {
                    "shape": "S2a"
                  },
                  "maxEjectionPercent": {
                    "type": "integer"
                  },
                  "maxServerErrors": {
                    "type": "long"
                  }
                }
              },
              "portMapping": {
                "shape": "S57"
              },
              "timeout": {
                "type": "structure",
                "members": {
                  "grpc": {
                    "shape": "S2f"
                  },
                  "http": {
                    "shape": "S2n"
                  },
                  "http2": {
                    "shape": "S2n"
                  },
                  "tcp": {
                    "shape": "S2r"
                  }
                },
                "union": true
              },
              "tls": {
                "type": "structure",
                "required": [
                  "certificate",
                  "mode"
                ],
                "members": {
                  "certificate": {
                    "type": "structure",
                    "members": {
                      "acm": {
                        "type": "structure",
                        "required": [
                          "certificateArn"
                        ],
                        "members": {
                          "certificateArn": {}
                        }
                      },
                      "file": {
                        "shape": "S4g"
                      },
                      "sds": {
                        "shape": "S4h"
                      }
                    },
                    "union": true
                  },
                  "mode": {},
                  "validation": {
                    "type": "structure",
                    "required": [
                      "trust"
                    ],
                    "members": {
                      "subjectAlternativeNames": {
                        "shape": "S39"
                      },
                      "trust": {
                        "type": "structure",
                        "members": {
                          "file": {
                            "shape": "S4n"
                          },
                          "sds": {
                            "shape": "S4o"
                          }
                        },
                        "union": true
                      }
                    }
                  }
                }
              }
            }
          }
        },
        "logging": {
          "type": "structure",
          "members": {
            "accessLog": {
              "type": "structure",
              "members": {
                "file": {
                  "type": "structure",
                  "required": [
                    "path"
                  ],
                  "members": {
                    "path": {}
                  }
                }
              },
              "union": true
            }
          }
        },
        "serviceDiscovery": {
          "type": "structure",
          "members": {
            "awsCloudMap": {
              "type": "structure",
              "required": [
                "namespaceName",
                "serviceName"
              ],
              "members": {
                "attributes": {
                  "type": "list",
                  "member": {
                    "type": "structure",
                    "required": [
                      "key",
                      "value"
                    ],
                    "members": {
                      "key": {},
                      "value": {}
                    }
                  }
                },
                "namespaceName": {},
                "serviceName": {}
              }
            },
            "dns": {
              "type": "structure",
              "required": [
                "hostname"
              ],
              "members": {
                "hostname": {},
                "responseType": {}
              }
            }
          },
          "union": true
        }
      }
    },
    "S4d": {
      "type": "structure",
      "members": {
        "tls": {
          "type": "structure",
          "required": [
            "validation"
          ],
          "members": {
            "certificate": {
              "type": "structure",
              "members": {
                "file": {
                  "shape": "S4g"
                },
                "sds": {
                  "shape": "S4h"
                }
              },
              "union": true
            },
            "enforce": {
              "type": "boolean"
            },
            "ports": {
              "shape": "S36"
            },
            "validation": {
              "type": "structure",
              "required": [
                "trust"
              ],
              "members": {
                "subjectAlternativeNames": {
                  "shape": "S39"
                },
                "trust": {
                  "type": "structure",
                  "members": {
                    "acm": {
                      "type": "structure",
                      "required": [
                        "certificateAuthorityArns"
                      ],
                      "members": {
                        "certificateAuthorityArns": {
                          "type": "list",
                          "member": {}
                        }
                      }
                    },
                    "file": {
                      "shape": "S4n"
                    },
                    "sds": {
                      "shape": "S4o"
                    }
                  },
                  "union": true
                }
              }
            }
          }
        }
      }
    },
    "S4g": {
      "type": "structure",
      "required": [
        "certificateChain",
        "privateKey"
      ],
      "members": {
        "certificateChain": {},
        "privateKey": {}
      }
    },
    "S4h": {
      "type": "structure",
      "required": [
        "secretName"
      ],
      "members": {
        "secretName": {}
      }
    },
    "S4n": {
      "type": "structure",
      "required": [
        "certificateChain"
      ],
      "members": {
        "certificateChain": {}
      }
    },
    "S4o": {
      "type": "structure",
      "required": [
        "secretName"
      ],
      "members": {
        "secretName": {}
      }
    },
    "S57": {
      "type": "structure",
      "required": [
        "port",
        "protocol"
      ],
      "members": {
        "port": {
          "type": "integer"
        },
        "protocol": {}
      }
    },
    "S5t": {
      "type": "structure",
      "required": [
        "meshName",
        "metadata",
        "spec",
        "status",
        "virtualNodeName"
      ],
      "members": {
        "meshName": {},
        "metadata": {
          "shape": "S1f"
        },
        "spec": {
          "shape": "S4b"
        },
        "status": {
          "type": "structure",
          "required": [
            "status"
          ],
          "members": {
            "status": {}
          }
        },
        "virtualNodeName": {}
      }
    },
    "S5x": {
      "type": "structure",
      "members": {
        "listeners": {
          "type": "list",
          "member": {
            "type": "structure",
            "required": [
              "portMapping"
            ],
            "members": {
              "portMapping": {
                "shape": "S57"
              }
            }
          }
        }
      }
    },
    "S61": {
      "type": "structure",
      "required": [
        "meshName",
        "metadata",
        "spec",
        "status",
        "virtualRouterName"
      ],
      "members": {
        "meshName": {},
        "metadata": {
          "shape": "S1f"
        },
        "spec": {
          "shape": "S5x"
        },
        "status": {
          "type": "structure",
          "required": [
            "status"
          ],
          "members": {
            "status": {}
          }
        },
        "virtualRouterName": {}
      }
    },
    "S65": {
      "type": "structure",
      "members": {
        "provider": {
          "type": "structure",
          "members": {
            "virtualNode": {
              "type": "structure",
              "required": [
                "virtualNodeName"
              ],
              "members": {
                "virtualNodeName": {}
              }
            },
            "virtualRouter": {
              "type": "structure",
              "required": [
                "virtualRouterName"
              ],
              "members": {
                "virtualRouterName": {}
              }
            }
          },
          "union": true
        }
      }
    },
    "S6a": {
      "type": "structure",
      "required": [
        "meshName",
        "metadata",
        "spec",
        "status",
        "virtualServiceName"
      ],
      "members": {
        "meshName": {},
        "metadata": {
          "shape": "S1f"
        },
        "spec": {
          "shape": "S65"
        },
        "status": {
          "type": "structure",
          "required": [
            "status"
          ],
          "members": {
            "status": {}
          }
        },
        "virtualServiceName": {}
      }
    }
  }
}