import { ShaderMaterial } from '@babylonjs/core/Materials/shaderMaterial'
import { Effect } from '@babylonjs/core'

Effect.ShadersStore['selectedCloneVertexShader'] = `
#ifdef GL_ES
precision highp float;
#endif
attribute vec3 position;
uniform mat4 worldViewProjection;
void main(void) {
    gl_Position = worldViewProjection * vec4(position, 1.0);    
}
`
Effect.ShadersStore['selectedCloneFragmentShader'] = `
#ifdef GL_ES
precision highp float;
#endif

void main(void) {
    gl_FragColor = vec4(0.,0.,0.,1.);
}
`
function createSelectedCloneShader(scene) {
  return new ShaderMaterial('selectedCloneMaterial', scene, 'selectedClone', {
    attributes: ['position'],
    uniforms: ['worldViewProjection'],
  })
}

export default createSelectedCloneShader
