{
  "version": "2.0",
  "metadata": {
    "apiVersion": "2020-07-13",
    "endpointPrefix": "finspace-api",
    "jsonVersion": "1.1",
    "protocol": "rest-json",
    "serviceAbbreviation": "FinSpace Data",
    "serviceFullName": "FinSpace Public API",
    "serviceId": "finspace data",
    "signatureVersion": "v4",
    "signingName": "finspace-api",
    "uid": "finspace-2020-07-13"
  },
  "operations": {
    "CreateChangeset": {
      "http": {
        "requestUri": "/datasets/{datasetId}/changesets"
      },
      "input": {
        "type": "structure",
        "required": [
          "datasetId",
          "changeType",
          "sourceType",
          "sourceParams"
        ],
        "members": {
          "datasetId": {
            "location": "uri",
            "locationName": "datasetId"
          },
          "changeType": {},
          "sourceType": {},
          "sourceParams": {
            "shape": "S5"
          },
          "formatType": {},
          "formatParams": {
            "shape": "S5"
          },
          "tags": {
            "shape": "S5"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "changeset": {
            "type": "structure",
            "members": {
              "id": {},
              "changesetArn": {},
              "datasetId": {},
              "changeType": {},
              "sourceType": {},
              "sourceParams": {
                "shape": "S5"
              },
              "formatType": {},
              "formatParams": {
                "shape": "S5"
              },
              "createTimestamp": {
                "type": "timestamp"
              },
              "status": {},
              "errorInfo": {
                "type": "structure",
                "members": {
                  "errorMessage": {},
                  "errorCategory": {}
                }
              },
              "changesetLabels": {
                "shape": "S5"
              },
              "updatesChangesetId": {},
              "updatedByChangesetId": {}
            }
          }
        }
      }
    },
    "GetProgrammaticAccessCredentials": {
      "http": {
        "method": "GET",
        "requestUri": "/credentials/programmatic"
      },
      "input": {
        "type": "structure",
        "required": [
          "environmentId"
        ],
        "members": {
          "durationInMinutes": {
            "location": "querystring",
            "locationName": "durationInMinutes",
            "type": "long"
          },
          "environmentId": {
            "location": "querystring",
            "locationName": "environmentId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "credentials": {
            "type": "structure",
            "members": {
              "accessKeyId": {},
              "secretAccessKey": {},
              "sessionToken": {}
            }
          },
          "durationInMinutes": {
            "type": "long"
          }
        }
      }
    },
    "GetWorkingLocation": {
      "http": {
        "requestUri": "/workingLocationV1"
      },
      "input": {
        "type": "structure",
        "members": {
          "locationType": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "s3Uri": {},
          "s3Path": {},
          "s3Bucket": {}
        }
      }
    }
  },
  "shapes": {
    "S5": {
      "type": "map",
      "key": {},
      "value": {}
    }
  }
}