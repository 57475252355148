import { useState } from 'react'
import { action } from 'mobx'
import { observer } from 'mobx-react-lite'
import { Spinner, Button, Alert, NonIdealState } from '@blueprintjs/core'
import { useInstanceModelIntoSelectedStep } from '~/src/hooks/models'
import { useStore } from '~/src/app/store'
import CustomIcon from '~/src/app/components/Icon'

const ModelGalleryItems = ({
  items,
  isLoading,
  onDelete,
  emptyText,
  emptyTitle,
}) => {
  const { appState } = useStore()
  const instanceModelIntoSelectedStep = useInstanceModelIntoSelectedStep()
  const handleClick = model => () => {
    instanceModelIntoSelectedStep(model)
    appState.sidebarDrawer = null
  }

  // TODO: this is allways the same... we should do a nice hook
  //       to encapsulate all this repetive sh*t

  // delete conformation boilerplate
  const [modelToDelete, setModelToDelete] = useState(false)
  const confirmDeleteModel = action(() => {
    onDelete(modelToDelete)
    setModelToDelete(false)
  })

  return (
    <>
      <Alert
        canOutsideClickCancel
        intent="danger"
        cancelButtonText="Cancel"
        confirmButtonText="Delete"
        icon={<CustomIcon icon="trash" />}
        isOpen={modelToDelete}
        onCancel={() => setModelToDelete(false)}
        onConfirm={confirmDeleteModel}
      >
        <h3>Delete Model</h3>
        <p>Are you sure you want to delete this model?</p>
      </Alert>

      {isLoading && <Spinner className="spinner" />}
      {items.length == 0 && (
        <NonIdealState
          icon={<CustomIcon icon="documentMinus" />}
          title={emptyTitle}
          description={emptyText}
        />
      )}

      <div className="model-gallery-items-container">
        {items.map(model => (
          <div
            key={model.id}
            className="model-gallery-item"
            onClick={handleClick(model)}
          >
            {onDelete && (
              <Button
                className="delete-button"
                minimal
                intent="danger"
                icon={<CustomIcon icon="trash" />}
                onClick={action(e => {
                  e.stopPropagation()
                  setModelToDelete(model)
                })}
              />
            )}
            <div className="thumbnail">
              <img src={model.thumbnail} alt={model.name} />
            </div>
            <div className="name">{model.name}</div>
          </div>
        ))}
      </div>
    </>
  )
}

export default observer(ModelGalleryItems)
