import { config, CognitoIdentityCredentials } from 'aws-sdk'
import {
  region,
  managerIdentityPoolId,
  // managerIdentityPoolUnauthRole,
} from '../config/aws-exports'
import { globalOptions } from '../options'

config.update({
  region: region,
})

export function getDefaultIdentityCredentials() {
  return new CognitoIdentityCredentials({
    IdentityPoolId: managerIdentityPoolId,
    // RoleArn: managerIdentityPoolUnauthRole,
  })
}

export async function getCredentials(options) {
  options = options || globalOptions
  if (!options.credentialsProvider) return getDefaultIdentityCredentials()
  try {
    return await options.credentialsProvider()
  } catch (err) {
    console.warn(
      "Couldn't get authenticated credentials, falling back to unauthed identity.",
    )
    return getDefaultIdentityCredentials()
  }
}
