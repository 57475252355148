import { Effect, FxaaPostProcess } from '@babylonjs/core'
import { PostProcess, Texture } from '@babylonjs/core'

const COLOR_R = (80 / 255).toFixed(2)
const COLOR_G = (145 / 255).toFixed(2)
const COLOR_B = (250 / 255).toFixed(2)

function createPostProcess(camera) {
  Effect.ShadersStore['postFragmentShader'] = `
  #ifdef GL_ES
  precision highp float;
  #endif
  varying vec2 vUV;
  uniform float thickness;
  uniform float ratio;
  uniform sampler2D textureSampler;
  uniform sampler2D selectedMeshesRender;
  uniform vec2 boundingCenter;
  uniform float boundingRadius;

  void main(void) {

      float thicv = thickness;
      float thich = thickness / ratio;
      
      // exit if not in area of interest
      // if (length(vec2(vUV.x, vUV.y / ratio) - vec2(boundingCenter.x, boundingCenter.y / ratio)) > boundingRadius + thich) {
      //   gl_FragColor = texture2D(textureSampler, vUV);
      //   return;
      // }

      float minY = (vUV.y - thicv);
      float maxY = (vUV.y + thicv);
      float maxX = (vUV.x + thich);
      float minX = (vUV.x - thich);


      // early exit
      if (minY < 0. || minX < 0. || maxY > 1. || maxX > 1.) {
       gl_FragColor = texture2D(textureSampler, vUV);
       return;
      }

      vec2 n = vec2(vUV.x, maxY);
      vec2 s = vec2(vUV.x, minY);
      vec2 e = vec2(maxX, vUV.y);
      vec2 w = vec2(minX, vUV.y);
      vec2 ne = vec2(maxX, maxY);
      vec2 nw = vec2(minX, maxY);
      vec2 se = vec2(maxX, minY);
      vec2 sw = vec2(minX, minY);

     // DEBUG: to see what the rendered target looks like
     // gl_FragColor = texture2D(selectedMeshesRender, vUV);
     // return;

     if (
         (texture2D(selectedMeshesRender, vUV).r != 1.)
         && (
          (texture2D(selectedMeshesRender, n).g != 0.)
          || (texture2D(selectedMeshesRender, s).g != 0.)
          || (texture2D(selectedMeshesRender, e).g != 0.)
          || (texture2D(selectedMeshesRender, w).g != 0.)
          || (texture2D(selectedMeshesRender, ne).g != 0.)
          || (texture2D(selectedMeshesRender, nw).g != 0.)
          || (texture2D(selectedMeshesRender, se).g != 0.)
          || (texture2D(selectedMeshesRender, sw).g != 0.)
         )
     ) {
       gl_FragColor = vec4(${COLOR_R}, ${COLOR_G}, ${COLOR_B}, 1.);
     } else {
       gl_FragColor = texture2D(textureSampler, vUV);
     }
  }
`

  const postProcess = new PostProcess(
    'postForOutline',
    'post',
    ['thickness', 'ratio', 'boundingCenter', 'boundingRadius'],
    ['selectedMeshesRender'],
    1.0,
    camera,
    Texture.BILINEAR_SAMPLINGMODE,
  )
  postProcess.samples = 4

  const fxaaPost = new FxaaPostProcess('fxaaPost', 1, camera)
  fxaaPost.samples = 1

  return postProcess
}
export default createPostProcess
