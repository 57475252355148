{
  "version": "2.0",
  "metadata": {
    "apiVersion": "2020-03-31",
    "endpointPrefix": "wellarchitected",
    "jsonVersion": "1.1",
    "protocol": "rest-json",
    "serviceAbbreviation": "Well-Architected",
    "serviceFullName": "AWS Well-Architected Tool",
    "serviceId": "WellArchitected",
    "signatureVersion": "v4",
    "signingName": "wellarchitected",
    "uid": "wellarchitected-2020-03-31"
  },
  "operations": {
    "AssociateLenses": {
      "http": {
        "method": "PATCH",
        "requestUri": "/workloads/{WorkloadId}/associateLenses"
      },
      "input": {
        "type": "structure",
        "required": [
          "WorkloadId",
          "LensAliases"
        ],
        "members": {
          "WorkloadId": {
            "location": "uri",
            "locationName": "WorkloadId"
          },
          "LensAliases": {
            "shape": "S3"
          }
        }
      }
    },
    "CreateMilestone": {
      "http": {
        "requestUri": "/workloads/{WorkloadId}/milestones"
      },
      "input": {
        "type": "structure",
        "required": [
          "WorkloadId",
          "MilestoneName",
          "ClientRequestToken"
        ],
        "members": {
          "WorkloadId": {
            "location": "uri",
            "locationName": "WorkloadId"
          },
          "MilestoneName": {},
          "ClientRequestToken": {
            "idempotencyToken": true
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "WorkloadId": {},
          "MilestoneNumber": {
            "type": "integer"
          }
        }
      }
    },
    "CreateWorkload": {
      "http": {
        "requestUri": "/workloads"
      },
      "input": {
        "type": "structure",
        "required": [
          "WorkloadName",
          "Description",
          "Environment",
          "ReviewOwner",
          "Lenses",
          "ClientRequestToken"
        ],
        "members": {
          "WorkloadName": {},
          "Description": {},
          "Environment": {},
          "AccountIds": {
            "shape": "Se"
          },
          "AwsRegions": {
            "shape": "Sg"
          },
          "NonAwsRegions": {
            "shape": "Si"
          },
          "PillarPriorities": {
            "shape": "Sk"
          },
          "ArchitecturalDesign": {},
          "ReviewOwner": {},
          "IndustryType": {},
          "Industry": {},
          "Lenses": {
            "shape": "Sq"
          },
          "Notes": {},
          "ClientRequestToken": {
            "idempotencyToken": true
          },
          "Tags": {
            "shape": "Ss"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "WorkloadId": {},
          "WorkloadArn": {}
        }
      }
    },
    "CreateWorkloadShare": {
      "http": {
        "requestUri": "/workloads/{WorkloadId}/shares"
      },
      "input": {
        "type": "structure",
        "required": [
          "WorkloadId",
          "SharedWith",
          "PermissionType",
          "ClientRequestToken"
        ],
        "members": {
          "WorkloadId": {
            "location": "uri",
            "locationName": "WorkloadId"
          },
          "SharedWith": {},
          "PermissionType": {},
          "ClientRequestToken": {
            "idempotencyToken": true
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "WorkloadId": {},
          "ShareId": {}
        }
      }
    },
    "DeleteWorkload": {
      "http": {
        "method": "DELETE",
        "requestUri": "/workloads/{WorkloadId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "WorkloadId",
          "ClientRequestToken"
        ],
        "members": {
          "WorkloadId": {
            "location": "uri",
            "locationName": "WorkloadId"
          },
          "ClientRequestToken": {
            "idempotencyToken": true,
            "location": "querystring",
            "locationName": "ClientRequestToken"
          }
        }
      }
    },
    "DeleteWorkloadShare": {
      "http": {
        "method": "DELETE",
        "requestUri": "/workloads/{WorkloadId}/shares/{ShareId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "ShareId",
          "WorkloadId",
          "ClientRequestToken"
        ],
        "members": {
          "ShareId": {
            "location": "uri",
            "locationName": "ShareId"
          },
          "WorkloadId": {
            "location": "uri",
            "locationName": "WorkloadId"
          },
          "ClientRequestToken": {
            "idempotencyToken": true,
            "location": "querystring",
            "locationName": "ClientRequestToken"
          }
        }
      }
    },
    "DisassociateLenses": {
      "http": {
        "method": "PATCH",
        "requestUri": "/workloads/{WorkloadId}/disassociateLenses"
      },
      "input": {
        "type": "structure",
        "required": [
          "WorkloadId",
          "LensAliases"
        ],
        "members": {
          "WorkloadId": {
            "location": "uri",
            "locationName": "WorkloadId"
          },
          "LensAliases": {
            "shape": "S3"
          }
        }
      }
    },
    "GetAnswer": {
      "http": {
        "method": "GET",
        "requestUri": "/workloads/{WorkloadId}/lensReviews/{LensAlias}/answers/{QuestionId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "WorkloadId",
          "LensAlias",
          "QuestionId"
        ],
        "members": {
          "WorkloadId": {
            "location": "uri",
            "locationName": "WorkloadId"
          },
          "LensAlias": {
            "location": "uri",
            "locationName": "LensAlias"
          },
          "QuestionId": {
            "location": "uri",
            "locationName": "QuestionId"
          },
          "MilestoneNumber": {
            "location": "querystring",
            "locationName": "MilestoneNumber",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "WorkloadId": {},
          "MilestoneNumber": {
            "type": "integer"
          },
          "LensAlias": {},
          "Answer": {
            "shape": "S18"
          }
        }
      }
    },
    "GetLensReview": {
      "http": {
        "method": "GET",
        "requestUri": "/workloads/{WorkloadId}/lensReviews/{LensAlias}"
      },
      "input": {
        "type": "structure",
        "required": [
          "WorkloadId",
          "LensAlias"
        ],
        "members": {
          "WorkloadId": {
            "location": "uri",
            "locationName": "WorkloadId"
          },
          "LensAlias": {
            "location": "uri",
            "locationName": "LensAlias"
          },
          "MilestoneNumber": {
            "location": "querystring",
            "locationName": "MilestoneNumber",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "WorkloadId": {},
          "MilestoneNumber": {
            "type": "integer"
          },
          "LensReview": {
            "shape": "S1t"
          }
        }
      }
    },
    "GetLensReviewReport": {
      "http": {
        "method": "GET",
        "requestUri": "/workloads/{WorkloadId}/lensReviews/{LensAlias}/report"
      },
      "input": {
        "type": "structure",
        "required": [
          "WorkloadId",
          "LensAlias"
        ],
        "members": {
          "WorkloadId": {
            "location": "uri",
            "locationName": "WorkloadId"
          },
          "LensAlias": {
            "location": "uri",
            "locationName": "LensAlias"
          },
          "MilestoneNumber": {
            "location": "querystring",
            "locationName": "MilestoneNumber",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "WorkloadId": {},
          "MilestoneNumber": {
            "type": "integer"
          },
          "LensReviewReport": {
            "type": "structure",
            "members": {
              "LensAlias": {},
              "Base64String": {}
            }
          }
        }
      }
    },
    "GetLensVersionDifference": {
      "http": {
        "method": "GET",
        "requestUri": "/lenses/{LensAlias}/versionDifference"
      },
      "input": {
        "type": "structure",
        "required": [
          "LensAlias",
          "BaseLensVersion"
        ],
        "members": {
          "LensAlias": {
            "location": "uri",
            "locationName": "LensAlias"
          },
          "BaseLensVersion": {
            "location": "querystring",
            "locationName": "BaseLensVersion"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "LensAlias": {},
          "BaseLensVersion": {},
          "LatestLensVersion": {},
          "VersionDifferences": {
            "type": "structure",
            "members": {
              "PillarDifferences": {
                "type": "list",
                "member": {
                  "type": "structure",
                  "members": {
                    "PillarId": {},
                    "DifferenceStatus": {},
                    "QuestionDifferences": {
                      "type": "list",
                      "member": {
                        "type": "structure",
                        "members": {
                          "QuestionId": {},
                          "QuestionTitle": {},
                          "DifferenceStatus": {}
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    },
    "GetMilestone": {
      "http": {
        "method": "GET",
        "requestUri": "/workloads/{WorkloadId}/milestones/{MilestoneNumber}"
      },
      "input": {
        "type": "structure",
        "required": [
          "WorkloadId",
          "MilestoneNumber"
        ],
        "members": {
          "WorkloadId": {
            "location": "uri",
            "locationName": "WorkloadId"
          },
          "MilestoneNumber": {
            "location": "uri",
            "locationName": "MilestoneNumber",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "WorkloadId": {},
          "Milestone": {
            "type": "structure",
            "members": {
              "MilestoneNumber": {
                "type": "integer"
              },
              "MilestoneName": {},
              "RecordedAt": {
                "type": "timestamp"
              },
              "Workload": {
                "shape": "S2j"
              }
            }
          }
        }
      }
    },
    "GetWorkload": {
      "http": {
        "method": "GET",
        "requestUri": "/workloads/{WorkloadId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "WorkloadId"
        ],
        "members": {
          "WorkloadId": {
            "location": "uri",
            "locationName": "WorkloadId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Workload": {
            "shape": "S2j"
          }
        }
      }
    },
    "ListAnswers": {
      "http": {
        "method": "GET",
        "requestUri": "/workloads/{WorkloadId}/lensReviews/{LensAlias}/answers"
      },
      "input": {
        "type": "structure",
        "required": [
          "WorkloadId",
          "LensAlias"
        ],
        "members": {
          "WorkloadId": {
            "location": "uri",
            "locationName": "WorkloadId"
          },
          "LensAlias": {
            "location": "uri",
            "locationName": "LensAlias"
          },
          "PillarId": {
            "location": "querystring",
            "locationName": "PillarId"
          },
          "MilestoneNumber": {
            "location": "querystring",
            "locationName": "MilestoneNumber",
            "type": "integer"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "NextToken"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "MaxResults",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "WorkloadId": {},
          "MilestoneNumber": {
            "type": "integer"
          },
          "LensAlias": {},
          "AnswerSummaries": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "QuestionId": {},
                "PillarId": {},
                "QuestionTitle": {},
                "Choices": {
                  "shape": "S1d"
                },
                "SelectedChoices": {
                  "shape": "S1i"
                },
                "ChoiceAnswerSummaries": {
                  "type": "list",
                  "member": {
                    "type": "structure",
                    "members": {
                      "ChoiceId": {},
                      "Status": {},
                      "Reason": {}
                    }
                  }
                },
                "IsApplicable": {
                  "type": "boolean"
                },
                "Risk": {},
                "Reason": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListLensReviewImprovements": {
      "http": {
        "method": "GET",
        "requestUri": "/workloads/{WorkloadId}/lensReviews/{LensAlias}/improvements"
      },
      "input": {
        "type": "structure",
        "required": [
          "WorkloadId",
          "LensAlias"
        ],
        "members": {
          "WorkloadId": {
            "location": "uri",
            "locationName": "WorkloadId"
          },
          "LensAlias": {
            "location": "uri",
            "locationName": "LensAlias"
          },
          "PillarId": {
            "location": "querystring",
            "locationName": "PillarId"
          },
          "MilestoneNumber": {
            "location": "querystring",
            "locationName": "MilestoneNumber",
            "type": "integer"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "NextToken"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "MaxResults",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "WorkloadId": {},
          "MilestoneNumber": {
            "type": "integer"
          },
          "LensAlias": {},
          "ImprovementSummaries": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "QuestionId": {},
                "PillarId": {},
                "QuestionTitle": {},
                "Risk": {},
                "ImprovementPlanUrl": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListLensReviews": {
      "http": {
        "method": "GET",
        "requestUri": "/workloads/{WorkloadId}/lensReviews"
      },
      "input": {
        "type": "structure",
        "required": [
          "WorkloadId"
        ],
        "members": {
          "WorkloadId": {
            "location": "uri",
            "locationName": "WorkloadId"
          },
          "MilestoneNumber": {
            "location": "querystring",
            "locationName": "MilestoneNumber",
            "type": "integer"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "NextToken"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "MaxResults",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "WorkloadId": {},
          "MilestoneNumber": {
            "type": "integer"
          },
          "LensReviewSummaries": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "LensAlias": {},
                "LensVersion": {},
                "LensName": {},
                "LensStatus": {},
                "UpdatedAt": {
                  "type": "timestamp"
                },
                "RiskCounts": {
                  "shape": "S20"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListLenses": {
      "http": {
        "method": "GET",
        "requestUri": "/lenses"
      },
      "input": {
        "type": "structure",
        "members": {
          "NextToken": {
            "location": "querystring",
            "locationName": "NextToken"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "MaxResults",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "LensSummaries": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "LensAlias": {},
                "LensVersion": {},
                "LensName": {},
                "Description": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListMilestones": {
      "http": {
        "requestUri": "/workloads/{WorkloadId}/milestonesSummaries"
      },
      "input": {
        "type": "structure",
        "required": [
          "WorkloadId"
        ],
        "members": {
          "WorkloadId": {
            "location": "uri",
            "locationName": "WorkloadId"
          },
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "WorkloadId": {},
          "MilestoneSummaries": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "MilestoneNumber": {
                  "type": "integer"
                },
                "MilestoneName": {},
                "RecordedAt": {
                  "type": "timestamp"
                },
                "WorkloadSummary": {
                  "shape": "S3f"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListNotifications": {
      "http": {
        "requestUri": "/notifications"
      },
      "input": {
        "type": "structure",
        "members": {
          "WorkloadId": {},
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NotificationSummaries": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Type": {},
                "LensUpgradeSummary": {
                  "type": "structure",
                  "members": {
                    "WorkloadId": {},
                    "WorkloadName": {},
                    "LensAlias": {},
                    "CurrentLensVersion": {},
                    "LatestLensVersion": {}
                  }
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListShareInvitations": {
      "http": {
        "method": "GET",
        "requestUri": "/shareInvitations"
      },
      "input": {
        "type": "structure",
        "members": {
          "WorkloadNamePrefix": {
            "location": "querystring",
            "locationName": "WorkloadNamePrefix"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "NextToken"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "MaxResults",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ShareInvitationSummaries": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "ShareInvitationId": {},
                "SharedBy": {},
                "SharedWith": {},
                "PermissionType": {},
                "WorkloadName": {},
                "WorkloadId": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListTagsForResource": {
      "http": {
        "method": "GET",
        "requestUri": "/tags/{WorkloadArn}"
      },
      "input": {
        "type": "structure",
        "required": [
          "WorkloadArn"
        ],
        "members": {
          "WorkloadArn": {
            "location": "uri",
            "locationName": "WorkloadArn"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Tags": {
            "shape": "Ss"
          }
        }
      }
    },
    "ListWorkloadShares": {
      "http": {
        "method": "GET",
        "requestUri": "/workloads/{WorkloadId}/shares"
      },
      "input": {
        "type": "structure",
        "required": [
          "WorkloadId"
        ],
        "members": {
          "WorkloadId": {
            "location": "uri",
            "locationName": "WorkloadId"
          },
          "SharedWithPrefix": {
            "location": "querystring",
            "locationName": "SharedWithPrefix"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "NextToken"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "MaxResults",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "WorkloadId": {},
          "WorkloadShareSummaries": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "ShareId": {},
                "SharedWith": {},
                "PermissionType": {},
                "Status": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListWorkloads": {
      "http": {
        "requestUri": "/workloadsSummaries"
      },
      "input": {
        "type": "structure",
        "members": {
          "WorkloadNamePrefix": {},
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "WorkloadSummaries": {
            "type": "list",
            "member": {
              "shape": "S3f"
            }
          },
          "NextToken": {}
        }
      }
    },
    "TagResource": {
      "http": {
        "requestUri": "/tags/{WorkloadArn}"
      },
      "input": {
        "type": "structure",
        "required": [
          "WorkloadArn",
          "Tags"
        ],
        "members": {
          "WorkloadArn": {
            "location": "uri",
            "locationName": "WorkloadArn"
          },
          "Tags": {
            "shape": "Ss"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UntagResource": {
      "http": {
        "method": "DELETE",
        "requestUri": "/tags/{WorkloadArn}"
      },
      "input": {
        "type": "structure",
        "required": [
          "WorkloadArn",
          "TagKeys"
        ],
        "members": {
          "WorkloadArn": {
            "location": "uri",
            "locationName": "WorkloadArn"
          },
          "TagKeys": {
            "location": "querystring",
            "locationName": "tagKeys",
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UpdateAnswer": {
      "http": {
        "method": "PATCH",
        "requestUri": "/workloads/{WorkloadId}/lensReviews/{LensAlias}/answers/{QuestionId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "WorkloadId",
          "LensAlias",
          "QuestionId"
        ],
        "members": {
          "WorkloadId": {
            "location": "uri",
            "locationName": "WorkloadId"
          },
          "LensAlias": {
            "location": "uri",
            "locationName": "LensAlias"
          },
          "QuestionId": {
            "location": "uri",
            "locationName": "QuestionId"
          },
          "SelectedChoices": {
            "shape": "S1i"
          },
          "ChoiceUpdates": {
            "type": "map",
            "key": {},
            "value": {
              "type": "structure",
              "required": [
                "Status"
              ],
              "members": {
                "Status": {},
                "Reason": {},
                "Notes": {}
              }
            }
          },
          "Notes": {},
          "IsApplicable": {
            "type": "boolean"
          },
          "Reason": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "WorkloadId": {},
          "LensAlias": {},
          "Answer": {
            "shape": "S18"
          }
        }
      }
    },
    "UpdateLensReview": {
      "http": {
        "method": "PATCH",
        "requestUri": "/workloads/{WorkloadId}/lensReviews/{LensAlias}"
      },
      "input": {
        "type": "structure",
        "required": [
          "WorkloadId",
          "LensAlias"
        ],
        "members": {
          "WorkloadId": {
            "location": "uri",
            "locationName": "WorkloadId"
          },
          "LensAlias": {
            "location": "uri",
            "locationName": "LensAlias"
          },
          "LensNotes": {},
          "PillarNotes": {
            "type": "map",
            "key": {},
            "value": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "WorkloadId": {},
          "LensReview": {
            "shape": "S1t"
          }
        }
      }
    },
    "UpdateShareInvitation": {
      "http": {
        "method": "PATCH",
        "requestUri": "/shareInvitations/{ShareInvitationId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "ShareInvitationId",
          "ShareInvitationAction"
        ],
        "members": {
          "ShareInvitationId": {
            "location": "uri",
            "locationName": "ShareInvitationId"
          },
          "ShareInvitationAction": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ShareInvitation": {
            "type": "structure",
            "members": {
              "ShareInvitationId": {},
              "WorkloadId": {}
            }
          }
        }
      }
    },
    "UpdateWorkload": {
      "http": {
        "method": "PATCH",
        "requestUri": "/workloads/{WorkloadId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "WorkloadId"
        ],
        "members": {
          "WorkloadId": {
            "location": "uri",
            "locationName": "WorkloadId"
          },
          "WorkloadName": {},
          "Description": {},
          "Environment": {},
          "AccountIds": {
            "shape": "Se"
          },
          "AwsRegions": {
            "shape": "Sg"
          },
          "NonAwsRegions": {
            "shape": "Si"
          },
          "PillarPriorities": {
            "shape": "Sk"
          },
          "ArchitecturalDesign": {},
          "ReviewOwner": {},
          "IsReviewOwnerUpdateAcknowledged": {
            "type": "boolean"
          },
          "IndustryType": {},
          "Industry": {},
          "Notes": {},
          "ImprovementStatus": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Workload": {
            "shape": "S2j"
          }
        }
      }
    },
    "UpdateWorkloadShare": {
      "http": {
        "method": "PATCH",
        "requestUri": "/workloads/{WorkloadId}/shares/{ShareId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "ShareId",
          "WorkloadId",
          "PermissionType"
        ],
        "members": {
          "ShareId": {
            "location": "uri",
            "locationName": "ShareId"
          },
          "WorkloadId": {
            "location": "uri",
            "locationName": "WorkloadId"
          },
          "PermissionType": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "WorkloadId": {},
          "WorkloadShare": {
            "type": "structure",
            "members": {
              "ShareId": {},
              "SharedBy": {},
              "SharedWith": {},
              "PermissionType": {},
              "Status": {},
              "WorkloadName": {},
              "WorkloadId": {}
            }
          }
        }
      }
    },
    "UpgradeLensReview": {
      "http": {
        "method": "PUT",
        "requestUri": "/workloads/{WorkloadId}/lensReviews/{LensAlias}/upgrade"
      },
      "input": {
        "type": "structure",
        "required": [
          "WorkloadId",
          "LensAlias",
          "MilestoneName"
        ],
        "members": {
          "WorkloadId": {
            "location": "uri",
            "locationName": "WorkloadId"
          },
          "LensAlias": {
            "location": "uri",
            "locationName": "LensAlias"
          },
          "MilestoneName": {},
          "ClientRequestToken": {}
        }
      }
    }
  },
  "shapes": {
    "S3": {
      "type": "list",
      "member": {}
    },
    "Se": {
      "type": "list",
      "member": {}
    },
    "Sg": {
      "type": "list",
      "member": {}
    },
    "Si": {
      "type": "list",
      "member": {}
    },
    "Sk": {
      "type": "list",
      "member": {}
    },
    "Sq": {
      "type": "list",
      "member": {}
    },
    "Ss": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "S18": {
      "type": "structure",
      "members": {
        "QuestionId": {},
        "PillarId": {},
        "QuestionTitle": {},
        "QuestionDescription": {},
        "ImprovementPlanUrl": {},
        "HelpfulResourceUrl": {},
        "Choices": {
          "shape": "S1d"
        },
        "SelectedChoices": {
          "shape": "S1i"
        },
        "ChoiceAnswers": {
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "ChoiceId": {},
              "Status": {},
              "Reason": {},
              "Notes": {}
            }
          }
        },
        "IsApplicable": {
          "type": "boolean"
        },
        "Risk": {},
        "Notes": {},
        "Reason": {}
      }
    },
    "S1d": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "ChoiceId": {},
          "Title": {},
          "Description": {}
        }
      }
    },
    "S1i": {
      "type": "list",
      "member": {}
    },
    "S1t": {
      "type": "structure",
      "members": {
        "LensAlias": {},
        "LensVersion": {},
        "LensName": {},
        "LensStatus": {},
        "PillarReviewSummaries": {
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "PillarId": {},
              "PillarName": {},
              "Notes": {},
              "RiskCounts": {
                "shape": "S20"
              }
            }
          }
        },
        "UpdatedAt": {
          "type": "timestamp"
        },
        "Notes": {},
        "RiskCounts": {
          "shape": "S20"
        },
        "NextToken": {}
      }
    },
    "S20": {
      "type": "map",
      "key": {},
      "value": {
        "type": "integer"
      }
    },
    "S2j": {
      "type": "structure",
      "members": {
        "WorkloadId": {},
        "WorkloadArn": {},
        "WorkloadName": {},
        "Description": {},
        "Environment": {},
        "UpdatedAt": {
          "type": "timestamp"
        },
        "AccountIds": {
          "shape": "Se"
        },
        "AwsRegions": {
          "shape": "Sg"
        },
        "NonAwsRegions": {
          "shape": "Si"
        },
        "ArchitecturalDesign": {},
        "ReviewOwner": {},
        "ReviewRestrictionDate": {
          "type": "timestamp"
        },
        "IsReviewOwnerUpdateAcknowledged": {
          "type": "boolean"
        },
        "IndustryType": {},
        "Industry": {},
        "Notes": {},
        "ImprovementStatus": {},
        "RiskCounts": {
          "shape": "S20"
        },
        "PillarPriorities": {
          "shape": "Sk"
        },
        "Lenses": {
          "shape": "Sq"
        },
        "Owner": {},
        "ShareInvitationId": {},
        "Tags": {
          "shape": "Ss"
        }
      }
    },
    "S3f": {
      "type": "structure",
      "members": {
        "WorkloadId": {},
        "WorkloadArn": {},
        "WorkloadName": {},
        "Owner": {},
        "UpdatedAt": {
          "type": "timestamp"
        },
        "Lenses": {
          "shape": "Sq"
        },
        "RiskCounts": {
          "shape": "S20"
        },
        "ImprovementStatus": {}
      }
    }
  }
}