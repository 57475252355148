{
  "version": "2.0",
  "metadata": {
    "apiVersion": "2020-12-01",
    "endpointPrefix": "devops-guru",
    "jsonVersion": "1.1",
    "protocol": "rest-json",
    "serviceFullName": "Amazon DevOps Guru",
    "serviceId": "DevOps Guru",
    "signatureVersion": "v4",
    "signingName": "devops-guru",
    "uid": "devops-guru-2020-12-01"
  },
  "operations": {
    "AddNotificationChannel": {
      "http": {
        "method": "PUT",
        "requestUri": "/channels",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "Config"
        ],
        "members": {
          "Config": {
            "shape": "S2"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "Id"
        ],
        "members": {
          "Id": {}
        }
      }
    },
    "DescribeAccountHealth": {
      "http": {
        "method": "GET",
        "requestUri": "/accounts/health",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {}
      },
      "output": {
        "type": "structure",
        "required": [
          "OpenReactiveInsights",
          "OpenProactiveInsights",
          "MetricsAnalyzed",
          "ResourceHours"
        ],
        "members": {
          "OpenReactiveInsights": {
            "type": "integer"
          },
          "OpenProactiveInsights": {
            "type": "integer"
          },
          "MetricsAnalyzed": {
            "type": "integer"
          },
          "ResourceHours": {
            "type": "long"
          }
        }
      }
    },
    "DescribeAccountOverview": {
      "http": {
        "requestUri": "/accounts/overview",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "FromTime"
        ],
        "members": {
          "FromTime": {
            "type": "timestamp"
          },
          "ToTime": {
            "type": "timestamp"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "ReactiveInsights",
          "ProactiveInsights",
          "MeanTimeToRecoverInMilliseconds"
        ],
        "members": {
          "ReactiveInsights": {
            "type": "integer"
          },
          "ProactiveInsights": {
            "type": "integer"
          },
          "MeanTimeToRecoverInMilliseconds": {
            "type": "long"
          }
        }
      }
    },
    "DescribeAnomaly": {
      "http": {
        "method": "GET",
        "requestUri": "/anomalies/{Id}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "Id"
        ],
        "members": {
          "Id": {
            "location": "uri",
            "locationName": "Id"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ProactiveAnomaly": {
            "type": "structure",
            "members": {
              "Id": {},
              "Severity": {},
              "Status": {},
              "UpdateTime": {
                "type": "timestamp"
              },
              "AnomalyTimeRange": {
                "shape": "Sp"
              },
              "AnomalyReportedTimeRange": {
                "shape": "Sq"
              },
              "PredictionTimeRange": {
                "shape": "Sr"
              },
              "SourceDetails": {
                "shape": "Ss"
              },
              "AssociatedInsightId": {},
              "ResourceCollection": {
                "shape": "S15"
              },
              "Limit": {
                "type": "double"
              }
            }
          },
          "ReactiveAnomaly": {
            "type": "structure",
            "members": {
              "Id": {},
              "Severity": {},
              "Status": {},
              "AnomalyTimeRange": {
                "shape": "Sp"
              },
              "AnomalyReportedTimeRange": {
                "shape": "Sq"
              },
              "SourceDetails": {
                "shape": "Ss"
              },
              "AssociatedInsightId": {},
              "ResourceCollection": {
                "shape": "S15"
              }
            }
          }
        }
      }
    },
    "DescribeFeedback": {
      "http": {
        "requestUri": "/feedback",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "InsightId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "InsightFeedback": {
            "shape": "S1d"
          }
        }
      }
    },
    "DescribeInsight": {
      "http": {
        "method": "GET",
        "requestUri": "/insights/{Id}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "Id"
        ],
        "members": {
          "Id": {
            "location": "uri",
            "locationName": "Id"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ProactiveInsight": {
            "type": "structure",
            "members": {
              "Id": {},
              "Name": {},
              "Severity": {},
              "Status": {},
              "InsightTimeRange": {
                "shape": "S1l"
              },
              "PredictionTimeRange": {
                "shape": "Sr"
              },
              "ResourceCollection": {
                "shape": "S15"
              },
              "SsmOpsItemId": {}
            }
          },
          "ReactiveInsight": {
            "type": "structure",
            "members": {
              "Id": {},
              "Name": {},
              "Severity": {},
              "Status": {},
              "InsightTimeRange": {
                "shape": "S1l"
              },
              "ResourceCollection": {
                "shape": "S15"
              },
              "SsmOpsItemId": {}
            }
          }
        }
      }
    },
    "DescribeResourceCollectionHealth": {
      "http": {
        "method": "GET",
        "requestUri": "/accounts/health/resource-collection/{ResourceCollectionType}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "ResourceCollectionType"
        ],
        "members": {
          "ResourceCollectionType": {
            "location": "uri",
            "locationName": "ResourceCollectionType"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "NextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "CloudFormation"
        ],
        "members": {
          "CloudFormation": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "StackName": {},
                "Insight": {
                  "type": "structure",
                  "members": {
                    "OpenProactiveInsights": {
                      "type": "integer"
                    },
                    "OpenReactiveInsights": {
                      "type": "integer"
                    },
                    "MeanTimeToRecoverInMilliseconds": {
                      "type": "long"
                    }
                  }
                }
              }
            }
          },
          "Service": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "ServiceName": {},
                "Insight": {
                  "type": "structure",
                  "members": {
                    "OpenProactiveInsights": {
                      "type": "integer"
                    },
                    "OpenReactiveInsights": {
                      "type": "integer"
                    }
                  }
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribeServiceIntegration": {
      "http": {
        "method": "GET",
        "requestUri": "/service-integrations",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {}
      },
      "output": {
        "type": "structure",
        "members": {
          "ServiceIntegration": {
            "type": "structure",
            "members": {
              "OpsCenter": {
                "type": "structure",
                "members": {
                  "OptInStatus": {}
                }
              }
            }
          }
        }
      }
    },
    "GetCostEstimation": {
      "http": {
        "method": "GET",
        "requestUri": "/cost-estimation",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "NextToken": {
            "location": "querystring",
            "locationName": "NextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ResourceCollection": {
            "shape": "S26"
          },
          "Status": {},
          "Costs": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Type": {},
                "State": {},
                "Count": {
                  "type": "integer"
                },
                "UnitCost": {
                  "type": "double"
                },
                "Cost": {
                  "type": "double"
                }
              }
            }
          },
          "TimeRange": {
            "type": "structure",
            "members": {
              "StartTime": {
                "type": "timestamp"
              },
              "EndTime": {
                "type": "timestamp"
              }
            }
          },
          "TotalCost": {
            "type": "double"
          },
          "NextToken": {}
        }
      }
    },
    "GetResourceCollection": {
      "http": {
        "method": "GET",
        "requestUri": "/resource-collections/{ResourceCollectionType}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "ResourceCollectionType"
        ],
        "members": {
          "ResourceCollectionType": {
            "location": "uri",
            "locationName": "ResourceCollectionType"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "NextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ResourceCollection": {
            "type": "structure",
            "members": {
              "CloudFormation": {
                "type": "structure",
                "members": {
                  "StackNames": {
                    "shape": "S17"
                  }
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListAnomaliesForInsight": {
      "http": {
        "requestUri": "/anomalies/insight/{InsightId}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "InsightId"
        ],
        "members": {
          "InsightId": {
            "location": "uri",
            "locationName": "InsightId"
          },
          "StartTimeRange": {
            "shape": "S2m"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ProactiveAnomalies": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Id": {},
                "Severity": {},
                "Status": {},
                "UpdateTime": {
                  "type": "timestamp"
                },
                "AnomalyTimeRange": {
                  "shape": "Sp"
                },
                "AnomalyReportedTimeRange": {
                  "shape": "Sq"
                },
                "PredictionTimeRange": {
                  "shape": "Sr"
                },
                "SourceDetails": {
                  "shape": "Ss"
                },
                "AssociatedInsightId": {},
                "ResourceCollection": {
                  "shape": "S15"
                },
                "Limit": {
                  "type": "double"
                }
              }
            }
          },
          "ReactiveAnomalies": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Id": {},
                "Severity": {},
                "Status": {},
                "AnomalyTimeRange": {
                  "shape": "Sp"
                },
                "AnomalyReportedTimeRange": {
                  "shape": "Sq"
                },
                "SourceDetails": {
                  "shape": "Ss"
                },
                "AssociatedInsightId": {},
                "ResourceCollection": {
                  "shape": "S15"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListEvents": {
      "http": {
        "requestUri": "/events",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "Filters"
        ],
        "members": {
          "Filters": {
            "type": "structure",
            "members": {
              "InsightId": {},
              "EventTimeRange": {
                "type": "structure",
                "required": [
                  "FromTime",
                  "ToTime"
                ],
                "members": {
                  "FromTime": {
                    "type": "timestamp"
                  },
                  "ToTime": {
                    "type": "timestamp"
                  }
                }
              },
              "EventClass": {},
              "EventSource": {},
              "DataSource": {},
              "ResourceCollection": {
                "shape": "S15"
              }
            }
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "Events"
        ],
        "members": {
          "Events": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "ResourceCollection": {
                  "shape": "S15"
                },
                "Id": {},
                "Time": {
                  "type": "timestamp"
                },
                "EventSource": {},
                "Name": {},
                "DataSource": {},
                "EventClass": {},
                "Resources": {
                  "type": "list",
                  "member": {
                    "type": "structure",
                    "members": {
                      "Type": {},
                      "Name": {},
                      "Arn": {}
                    }
                  }
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListInsights": {
      "http": {
        "requestUri": "/insights",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "StatusFilter"
        ],
        "members": {
          "StatusFilter": {
            "type": "structure",
            "members": {
              "Ongoing": {
                "type": "structure",
                "required": [
                  "Type"
                ],
                "members": {
                  "Type": {}
                }
              },
              "Closed": {
                "type": "structure",
                "required": [
                  "Type",
                  "EndTimeRange"
                ],
                "members": {
                  "Type": {},
                  "EndTimeRange": {
                    "type": "structure",
                    "members": {
                      "FromTime": {
                        "type": "timestamp"
                      },
                      "ToTime": {
                        "type": "timestamp"
                      }
                    }
                  }
                }
              },
              "Any": {
                "type": "structure",
                "required": [
                  "Type",
                  "StartTimeRange"
                ],
                "members": {
                  "Type": {},
                  "StartTimeRange": {
                    "shape": "S2m"
                  }
                }
              }
            }
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ProactiveInsights": {
            "shape": "S3j"
          },
          "ReactiveInsights": {
            "shape": "S3n"
          },
          "NextToken": {}
        }
      }
    },
    "ListNotificationChannels": {
      "http": {
        "requestUri": "/channels",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Channels": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Id": {},
                "Config": {
                  "shape": "S2"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListRecommendations": {
      "http": {
        "requestUri": "/recommendations",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "InsightId"
        ],
        "members": {
          "InsightId": {},
          "NextToken": {},
          "Locale": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Recommendations": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Description": {},
                "Link": {},
                "Name": {},
                "Reason": {},
                "RelatedEvents": {
                  "type": "list",
                  "member": {
                    "type": "structure",
                    "members": {
                      "Name": {},
                      "Resources": {
                        "type": "list",
                        "member": {
                          "type": "structure",
                          "members": {
                            "Name": {},
                            "Type": {}
                          }
                        }
                      }
                    }
                  }
                },
                "RelatedAnomalies": {
                  "type": "list",
                  "member": {
                    "type": "structure",
                    "members": {
                      "Resources": {
                        "type": "list",
                        "member": {
                          "type": "structure",
                          "members": {
                            "Name": {},
                            "Type": {}
                          }
                        }
                      },
                      "SourceDetails": {
                        "type": "list",
                        "member": {
                          "type": "structure",
                          "members": {
                            "CloudWatchMetrics": {
                              "type": "list",
                              "member": {
                                "type": "structure",
                                "members": {
                                  "MetricName": {},
                                  "Namespace": {}
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "PutFeedback": {
      "http": {
        "method": "PUT",
        "requestUri": "/feedback",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "InsightFeedback": {
            "shape": "S1d"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "RemoveNotificationChannel": {
      "http": {
        "method": "DELETE",
        "requestUri": "/channels/{Id}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "Id"
        ],
        "members": {
          "Id": {
            "location": "uri",
            "locationName": "Id"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "SearchInsights": {
      "http": {
        "requestUri": "/insights/search",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "StartTimeRange",
          "Type"
        ],
        "members": {
          "StartTimeRange": {
            "shape": "S2m"
          },
          "Filters": {
            "type": "structure",
            "members": {
              "Severities": {
                "type": "list",
                "member": {}
              },
              "Statuses": {
                "type": "list",
                "member": {}
              },
              "ResourceCollection": {
                "shape": "S15"
              },
              "ServiceCollection": {
                "shape": "S3l"
              }
            }
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {},
          "Type": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ProactiveInsights": {
            "shape": "S3j"
          },
          "ReactiveInsights": {
            "shape": "S3n"
          },
          "NextToken": {}
        }
      }
    },
    "StartCostEstimation": {
      "http": {
        "method": "PUT",
        "requestUri": "/cost-estimation",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "ResourceCollection"
        ],
        "members": {
          "ResourceCollection": {
            "shape": "S26"
          },
          "ClientToken": {
            "idempotencyToken": true
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UpdateResourceCollection": {
      "http": {
        "method": "PUT",
        "requestUri": "/resource-collections",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "Action",
          "ResourceCollection"
        ],
        "members": {
          "Action": {},
          "ResourceCollection": {
            "type": "structure",
            "members": {
              "CloudFormation": {
                "type": "structure",
                "members": {
                  "StackNames": {
                    "type": "list",
                    "member": {}
                  }
                }
              }
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UpdateServiceIntegration": {
      "http": {
        "method": "PUT",
        "requestUri": "/service-integrations",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "ServiceIntegration"
        ],
        "members": {
          "ServiceIntegration": {
            "type": "structure",
            "members": {
              "OpsCenter": {
                "type": "structure",
                "members": {
                  "OptInStatus": {}
                }
              }
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    }
  },
  "shapes": {
    "S2": {
      "type": "structure",
      "required": [
        "Sns"
      ],
      "members": {
        "Sns": {
          "type": "structure",
          "members": {
            "TopicArn": {}
          }
        }
      }
    },
    "Sp": {
      "type": "structure",
      "required": [
        "StartTime"
      ],
      "members": {
        "StartTime": {
          "type": "timestamp"
        },
        "EndTime": {
          "type": "timestamp"
        }
      }
    },
    "Sq": {
      "type": "structure",
      "required": [
        "OpenTime"
      ],
      "members": {
        "OpenTime": {
          "type": "timestamp"
        },
        "CloseTime": {
          "type": "timestamp"
        }
      }
    },
    "Sr": {
      "type": "structure",
      "required": [
        "StartTime"
      ],
      "members": {
        "StartTime": {
          "type": "timestamp"
        },
        "EndTime": {
          "type": "timestamp"
        }
      }
    },
    "Ss": {
      "type": "structure",
      "members": {
        "CloudWatchMetrics": {
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "MetricName": {},
              "Namespace": {},
              "Dimensions": {
                "type": "list",
                "member": {
                  "type": "structure",
                  "members": {
                    "Name": {},
                    "Value": {}
                  }
                }
              },
              "Stat": {},
              "Unit": {},
              "Period": {
                "type": "integer"
              }
            }
          }
        }
      }
    },
    "S15": {
      "type": "structure",
      "members": {
        "CloudFormation": {
          "type": "structure",
          "members": {
            "StackNames": {
              "shape": "S17"
            }
          }
        }
      }
    },
    "S17": {
      "type": "list",
      "member": {}
    },
    "S1d": {
      "type": "structure",
      "members": {
        "Id": {},
        "Feedback": {}
      }
    },
    "S1l": {
      "type": "structure",
      "required": [
        "StartTime"
      ],
      "members": {
        "StartTime": {
          "type": "timestamp"
        },
        "EndTime": {
          "type": "timestamp"
        }
      }
    },
    "S26": {
      "type": "structure",
      "members": {
        "CloudFormation": {
          "type": "structure",
          "members": {
            "StackNames": {
              "type": "list",
              "member": {}
            }
          }
        }
      }
    },
    "S2m": {
      "type": "structure",
      "members": {
        "FromTime": {
          "type": "timestamp"
        },
        "ToTime": {
          "type": "timestamp"
        }
      }
    },
    "S3j": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "Id": {},
          "Name": {},
          "Severity": {},
          "Status": {},
          "InsightTimeRange": {
            "shape": "S1l"
          },
          "PredictionTimeRange": {
            "shape": "Sr"
          },
          "ResourceCollection": {
            "shape": "S15"
          },
          "ServiceCollection": {
            "shape": "S3l"
          }
        }
      }
    },
    "S3l": {
      "type": "structure",
      "members": {
        "ServiceNames": {
          "type": "list",
          "member": {}
        }
      }
    },
    "S3n": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "Id": {},
          "Name": {},
          "Severity": {},
          "Status": {},
          "InsightTimeRange": {
            "shape": "S1l"
          },
          "ResourceCollection": {
            "shape": "S15"
          },
          "ServiceCollection": {
            "shape": "S3l"
          }
        }
      }
    }
  }
}