{
  "version": "2.0",
  "metadata": {
    "apiVersion": "2021-05-15",
    "endpointPrefix": "messaging-chime",
    "protocol": "rest-json",
    "serviceFullName": "Amazon Chime SDK Messaging",
    "serviceId": "Chime SDK Messaging",
    "signatureVersion": "v4",
    "signingName": "chime",
    "uid": "chime-sdk-messaging-2021-05-15"
  },
  "operations": {
    "AssociateChannelFlow": {
      "http": {
        "method": "PUT",
        "requestUri": "/channels/{channelArn}/channel-flow",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "ChannelArn",
          "ChannelFlowArn",
          "ChimeBearer"
        ],
        "members": {
          "ChannelArn": {
            "location": "uri",
            "locationName": "channelArn"
          },
          "ChannelFlowArn": {},
          "ChimeBearer": {
            "location": "header",
            "locationName": "x-amz-chime-bearer"
          }
        }
      }
    },
    "BatchCreateChannelMembership": {
      "http": {
        "requestUri": "/channels/{channelArn}/memberships?operation=batch-create",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "ChannelArn",
          "MemberArns",
          "ChimeBearer"
        ],
        "members": {
          "ChannelArn": {
            "location": "uri",
            "locationName": "channelArn"
          },
          "Type": {},
          "MemberArns": {
            "type": "list",
            "member": {}
          },
          "ChimeBearer": {
            "location": "header",
            "locationName": "x-amz-chime-bearer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "BatchChannelMemberships": {
            "type": "structure",
            "members": {
              "InvitedBy": {
                "shape": "S8"
              },
              "Type": {},
              "Members": {
                "type": "list",
                "member": {
                  "shape": "S8"
                }
              },
              "ChannelArn": {}
            }
          },
          "Errors": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "MemberArn": {},
                "ErrorCode": {},
                "ErrorMessage": {}
              }
            }
          }
        }
      }
    },
    "ChannelFlowCallback": {
      "http": {
        "requestUri": "/channels/{channelArn}?operation=channel-flow-callback",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "CallbackId",
          "ChannelArn",
          "ChannelMessage"
        ],
        "members": {
          "CallbackId": {
            "idempotencyToken": true
          },
          "ChannelArn": {
            "location": "uri",
            "locationName": "channelArn"
          },
          "DeleteResource": {
            "type": "boolean"
          },
          "ChannelMessage": {
            "type": "structure",
            "required": [
              "MessageId"
            ],
            "members": {
              "MessageId": {},
              "Content": {
                "shape": "Sk"
              },
              "Metadata": {
                "shape": "Sl"
              }
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ChannelArn": {},
          "CallbackId": {}
        }
      }
    },
    "CreateChannel": {
      "http": {
        "requestUri": "/channels",
        "responseCode": 201
      },
      "input": {
        "type": "structure",
        "required": [
          "AppInstanceArn",
          "Name",
          "ClientRequestToken",
          "ChimeBearer"
        ],
        "members": {
          "AppInstanceArn": {},
          "Name": {
            "shape": "So"
          },
          "Mode": {},
          "Privacy": {},
          "Metadata": {
            "shape": "Sl"
          },
          "ClientRequestToken": {
            "shape": "Sr",
            "idempotencyToken": true
          },
          "Tags": {
            "shape": "Ss"
          },
          "ChimeBearer": {
            "location": "header",
            "locationName": "x-amz-chime-bearer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ChannelArn": {}
        }
      }
    },
    "CreateChannelBan": {
      "http": {
        "requestUri": "/channels/{channelArn}/bans",
        "responseCode": 201
      },
      "input": {
        "type": "structure",
        "required": [
          "ChannelArn",
          "MemberArn",
          "ChimeBearer"
        ],
        "members": {
          "ChannelArn": {
            "location": "uri",
            "locationName": "channelArn"
          },
          "MemberArn": {},
          "ChimeBearer": {
            "location": "header",
            "locationName": "x-amz-chime-bearer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ChannelArn": {},
          "Member": {
            "shape": "S8"
          }
        }
      }
    },
    "CreateChannelFlow": {
      "http": {
        "requestUri": "/channel-flows",
        "responseCode": 201
      },
      "input": {
        "type": "structure",
        "required": [
          "AppInstanceArn",
          "Processors",
          "Name",
          "ClientRequestToken"
        ],
        "members": {
          "AppInstanceArn": {},
          "Processors": {
            "shape": "S10"
          },
          "Name": {
            "shape": "So"
          },
          "Tags": {
            "shape": "Ss"
          },
          "ClientRequestToken": {
            "shape": "Sr"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ChannelFlowArn": {}
        }
      }
    },
    "CreateChannelMembership": {
      "http": {
        "requestUri": "/channels/{channelArn}/memberships",
        "responseCode": 201
      },
      "input": {
        "type": "structure",
        "required": [
          "ChannelArn",
          "MemberArn",
          "Type",
          "ChimeBearer"
        ],
        "members": {
          "ChannelArn": {
            "location": "uri",
            "locationName": "channelArn"
          },
          "MemberArn": {},
          "Type": {},
          "ChimeBearer": {
            "location": "header",
            "locationName": "x-amz-chime-bearer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ChannelArn": {},
          "Member": {
            "shape": "S8"
          }
        }
      }
    },
    "CreateChannelModerator": {
      "http": {
        "requestUri": "/channels/{channelArn}/moderators",
        "responseCode": 201
      },
      "input": {
        "type": "structure",
        "required": [
          "ChannelArn",
          "ChannelModeratorArn",
          "ChimeBearer"
        ],
        "members": {
          "ChannelArn": {
            "location": "uri",
            "locationName": "channelArn"
          },
          "ChannelModeratorArn": {},
          "ChimeBearer": {
            "location": "header",
            "locationName": "x-amz-chime-bearer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ChannelArn": {},
          "ChannelModerator": {
            "shape": "S8"
          }
        }
      }
    },
    "DeleteChannel": {
      "http": {
        "method": "DELETE",
        "requestUri": "/channels/{channelArn}",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "ChannelArn",
          "ChimeBearer"
        ],
        "members": {
          "ChannelArn": {
            "location": "uri",
            "locationName": "channelArn"
          },
          "ChimeBearer": {
            "location": "header",
            "locationName": "x-amz-chime-bearer"
          }
        }
      }
    },
    "DeleteChannelBan": {
      "http": {
        "method": "DELETE",
        "requestUri": "/channels/{channelArn}/bans/{memberArn}",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "ChannelArn",
          "MemberArn",
          "ChimeBearer"
        ],
        "members": {
          "ChannelArn": {
            "location": "uri",
            "locationName": "channelArn"
          },
          "MemberArn": {
            "location": "uri",
            "locationName": "memberArn"
          },
          "ChimeBearer": {
            "location": "header",
            "locationName": "x-amz-chime-bearer"
          }
        }
      }
    },
    "DeleteChannelFlow": {
      "http": {
        "method": "DELETE",
        "requestUri": "/channel-flows/{channelFlowArn}",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "ChannelFlowArn"
        ],
        "members": {
          "ChannelFlowArn": {
            "location": "uri",
            "locationName": "channelFlowArn"
          }
        }
      }
    },
    "DeleteChannelMembership": {
      "http": {
        "method": "DELETE",
        "requestUri": "/channels/{channelArn}/memberships/{memberArn}",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "ChannelArn",
          "MemberArn",
          "ChimeBearer"
        ],
        "members": {
          "ChannelArn": {
            "location": "uri",
            "locationName": "channelArn"
          },
          "MemberArn": {
            "location": "uri",
            "locationName": "memberArn"
          },
          "ChimeBearer": {
            "location": "header",
            "locationName": "x-amz-chime-bearer"
          }
        }
      }
    },
    "DeleteChannelMessage": {
      "http": {
        "method": "DELETE",
        "requestUri": "/channels/{channelArn}/messages/{messageId}",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "ChannelArn",
          "MessageId",
          "ChimeBearer"
        ],
        "members": {
          "ChannelArn": {
            "location": "uri",
            "locationName": "channelArn"
          },
          "MessageId": {
            "location": "uri",
            "locationName": "messageId"
          },
          "ChimeBearer": {
            "location": "header",
            "locationName": "x-amz-chime-bearer"
          }
        }
      }
    },
    "DeleteChannelModerator": {
      "http": {
        "method": "DELETE",
        "requestUri": "/channels/{channelArn}/moderators/{channelModeratorArn}",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "ChannelArn",
          "ChannelModeratorArn",
          "ChimeBearer"
        ],
        "members": {
          "ChannelArn": {
            "location": "uri",
            "locationName": "channelArn"
          },
          "ChannelModeratorArn": {
            "location": "uri",
            "locationName": "channelModeratorArn"
          },
          "ChimeBearer": {
            "location": "header",
            "locationName": "x-amz-chime-bearer"
          }
        }
      }
    },
    "DescribeChannel": {
      "http": {
        "method": "GET",
        "requestUri": "/channels/{channelArn}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "ChannelArn",
          "ChimeBearer"
        ],
        "members": {
          "ChannelArn": {
            "location": "uri",
            "locationName": "channelArn"
          },
          "ChimeBearer": {
            "location": "header",
            "locationName": "x-amz-chime-bearer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Channel": {
            "type": "structure",
            "members": {
              "Name": {
                "shape": "So"
              },
              "ChannelArn": {},
              "Mode": {},
              "Privacy": {},
              "Metadata": {
                "shape": "Sl"
              },
              "CreatedBy": {
                "shape": "S8"
              },
              "CreatedTimestamp": {
                "type": "timestamp"
              },
              "LastMessageTimestamp": {
                "type": "timestamp"
              },
              "LastUpdatedTimestamp": {
                "type": "timestamp"
              },
              "ChannelFlowArn": {}
            }
          }
        }
      }
    },
    "DescribeChannelBan": {
      "http": {
        "method": "GET",
        "requestUri": "/channels/{channelArn}/bans/{memberArn}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "ChannelArn",
          "MemberArn",
          "ChimeBearer"
        ],
        "members": {
          "ChannelArn": {
            "location": "uri",
            "locationName": "channelArn"
          },
          "MemberArn": {
            "location": "uri",
            "locationName": "memberArn"
          },
          "ChimeBearer": {
            "location": "header",
            "locationName": "x-amz-chime-bearer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ChannelBan": {
            "type": "structure",
            "members": {
              "Member": {
                "shape": "S8"
              },
              "ChannelArn": {},
              "CreatedTimestamp": {
                "type": "timestamp"
              },
              "CreatedBy": {
                "shape": "S8"
              }
            }
          }
        }
      }
    },
    "DescribeChannelFlow": {
      "http": {
        "method": "GET",
        "requestUri": "/channel-flows/{channelFlowArn}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "ChannelFlowArn"
        ],
        "members": {
          "ChannelFlowArn": {
            "location": "uri",
            "locationName": "channelFlowArn"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ChannelFlow": {
            "type": "structure",
            "members": {
              "ChannelFlowArn": {},
              "Processors": {
                "shape": "S10"
              },
              "Name": {
                "shape": "So"
              },
              "CreatedTimestamp": {
                "type": "timestamp"
              },
              "LastUpdatedTimestamp": {
                "type": "timestamp"
              }
            }
          }
        }
      }
    },
    "DescribeChannelMembership": {
      "http": {
        "method": "GET",
        "requestUri": "/channels/{channelArn}/memberships/{memberArn}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "ChannelArn",
          "MemberArn",
          "ChimeBearer"
        ],
        "members": {
          "ChannelArn": {
            "location": "uri",
            "locationName": "channelArn"
          },
          "MemberArn": {
            "location": "uri",
            "locationName": "memberArn"
          },
          "ChimeBearer": {
            "location": "header",
            "locationName": "x-amz-chime-bearer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ChannelMembership": {
            "type": "structure",
            "members": {
              "InvitedBy": {
                "shape": "S8"
              },
              "Type": {},
              "Member": {
                "shape": "S8"
              },
              "ChannelArn": {},
              "CreatedTimestamp": {
                "type": "timestamp"
              },
              "LastUpdatedTimestamp": {
                "type": "timestamp"
              }
            }
          }
        }
      }
    },
    "DescribeChannelMembershipForAppInstanceUser": {
      "http": {
        "method": "GET",
        "requestUri": "/channels/{channelArn}?scope=app-instance-user-membership",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "ChannelArn",
          "AppInstanceUserArn",
          "ChimeBearer"
        ],
        "members": {
          "ChannelArn": {
            "location": "uri",
            "locationName": "channelArn"
          },
          "AppInstanceUserArn": {
            "location": "querystring",
            "locationName": "app-instance-user-arn"
          },
          "ChimeBearer": {
            "location": "header",
            "locationName": "x-amz-chime-bearer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ChannelMembership": {
            "shape": "S1y"
          }
        }
      }
    },
    "DescribeChannelModeratedByAppInstanceUser": {
      "http": {
        "method": "GET",
        "requestUri": "/channels/{channelArn}?scope=app-instance-user-moderated-channel",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "ChannelArn",
          "AppInstanceUserArn",
          "ChimeBearer"
        ],
        "members": {
          "ChannelArn": {
            "location": "uri",
            "locationName": "channelArn"
          },
          "AppInstanceUserArn": {
            "location": "querystring",
            "locationName": "app-instance-user-arn"
          },
          "ChimeBearer": {
            "location": "header",
            "locationName": "x-amz-chime-bearer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Channel": {
            "shape": "S23"
          }
        }
      }
    },
    "DescribeChannelModerator": {
      "http": {
        "method": "GET",
        "requestUri": "/channels/{channelArn}/moderators/{channelModeratorArn}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "ChannelArn",
          "ChannelModeratorArn",
          "ChimeBearer"
        ],
        "members": {
          "ChannelArn": {
            "location": "uri",
            "locationName": "channelArn"
          },
          "ChannelModeratorArn": {
            "location": "uri",
            "locationName": "channelModeratorArn"
          },
          "ChimeBearer": {
            "location": "header",
            "locationName": "x-amz-chime-bearer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ChannelModerator": {
            "type": "structure",
            "members": {
              "Moderator": {
                "shape": "S8"
              },
              "ChannelArn": {},
              "CreatedTimestamp": {
                "type": "timestamp"
              },
              "CreatedBy": {
                "shape": "S8"
              }
            }
          }
        }
      }
    },
    "DisassociateChannelFlow": {
      "http": {
        "method": "DELETE",
        "requestUri": "/channels/{channelArn}/channel-flow/{channelFlowArn}",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "ChannelArn",
          "ChannelFlowArn",
          "ChimeBearer"
        ],
        "members": {
          "ChannelArn": {
            "location": "uri",
            "locationName": "channelArn"
          },
          "ChannelFlowArn": {
            "location": "uri",
            "locationName": "channelFlowArn"
          },
          "ChimeBearer": {
            "location": "header",
            "locationName": "x-amz-chime-bearer"
          }
        }
      }
    },
    "GetChannelMessage": {
      "http": {
        "method": "GET",
        "requestUri": "/channels/{channelArn}/messages/{messageId}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "ChannelArn",
          "MessageId",
          "ChimeBearer"
        ],
        "members": {
          "ChannelArn": {
            "location": "uri",
            "locationName": "channelArn"
          },
          "MessageId": {
            "location": "uri",
            "locationName": "messageId"
          },
          "ChimeBearer": {
            "location": "header",
            "locationName": "x-amz-chime-bearer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ChannelMessage": {
            "type": "structure",
            "members": {
              "ChannelArn": {},
              "MessageId": {},
              "Content": {
                "shape": "S2b"
              },
              "Metadata": {
                "shape": "Sl"
              },
              "Type": {},
              "CreatedTimestamp": {
                "type": "timestamp"
              },
              "LastEditedTimestamp": {
                "type": "timestamp"
              },
              "LastUpdatedTimestamp": {
                "type": "timestamp"
              },
              "Sender": {
                "shape": "S8"
              },
              "Redacted": {
                "type": "boolean"
              },
              "Persistence": {},
              "Status": {
                "shape": "S2e"
              }
            }
          }
        }
      }
    },
    "GetChannelMessageStatus": {
      "http": {
        "method": "GET",
        "requestUri": "/channels/{channelArn}/messages/{messageId}?scope=message-status",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "ChannelArn",
          "MessageId",
          "ChimeBearer"
        ],
        "members": {
          "ChannelArn": {
            "location": "uri",
            "locationName": "channelArn"
          },
          "MessageId": {
            "location": "uri",
            "locationName": "messageId"
          },
          "ChimeBearer": {
            "location": "header",
            "locationName": "x-amz-chime-bearer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Status": {
            "shape": "S2e"
          }
        }
      }
    },
    "GetMessagingSessionEndpoint": {
      "http": {
        "method": "GET",
        "requestUri": "/endpoints/messaging-session",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {}
      },
      "output": {
        "type": "structure",
        "members": {
          "Endpoint": {
            "type": "structure",
            "members": {
              "Url": {}
            }
          }
        }
      }
    },
    "ListChannelBans": {
      "http": {
        "method": "GET",
        "requestUri": "/channels/{channelArn}/bans",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "ChannelArn",
          "ChimeBearer"
        ],
        "members": {
          "ChannelArn": {
            "location": "uri",
            "locationName": "channelArn"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "max-results",
            "type": "integer"
          },
          "NextToken": {
            "shape": "S2p",
            "location": "querystring",
            "locationName": "next-token"
          },
          "ChimeBearer": {
            "location": "header",
            "locationName": "x-amz-chime-bearer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ChannelArn": {},
          "NextToken": {
            "shape": "S2p"
          },
          "ChannelBans": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Member": {
                  "shape": "S8"
                }
              }
            }
          }
        }
      }
    },
    "ListChannelFlows": {
      "http": {
        "method": "GET",
        "requestUri": "/channel-flows",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "AppInstanceArn"
        ],
        "members": {
          "AppInstanceArn": {
            "location": "querystring",
            "locationName": "app-instance-arn"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "max-results",
            "type": "integer"
          },
          "NextToken": {
            "shape": "S2p",
            "location": "querystring",
            "locationName": "next-token"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ChannelFlows": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "ChannelFlowArn": {},
                "Name": {
                  "shape": "So"
                },
                "Processors": {
                  "shape": "S10"
                }
              }
            }
          },
          "NextToken": {
            "shape": "S2p"
          }
        }
      }
    },
    "ListChannelMemberships": {
      "http": {
        "method": "GET",
        "requestUri": "/channels/{channelArn}/memberships",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "ChannelArn",
          "ChimeBearer"
        ],
        "members": {
          "ChannelArn": {
            "location": "uri",
            "locationName": "channelArn"
          },
          "Type": {
            "location": "querystring",
            "locationName": "type"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "max-results",
            "type": "integer"
          },
          "NextToken": {
            "shape": "S2p",
            "location": "querystring",
            "locationName": "next-token"
          },
          "ChimeBearer": {
            "location": "header",
            "locationName": "x-amz-chime-bearer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ChannelArn": {},
          "ChannelMemberships": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Member": {
                  "shape": "S8"
                }
              }
            }
          },
          "NextToken": {
            "shape": "S2p"
          }
        }
      }
    },
    "ListChannelMembershipsForAppInstanceUser": {
      "http": {
        "method": "GET",
        "requestUri": "/channels?scope=app-instance-user-memberships",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "ChimeBearer"
        ],
        "members": {
          "AppInstanceUserArn": {
            "location": "querystring",
            "locationName": "app-instance-user-arn"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "max-results",
            "type": "integer"
          },
          "NextToken": {
            "shape": "S2p",
            "location": "querystring",
            "locationName": "next-token"
          },
          "ChimeBearer": {
            "location": "header",
            "locationName": "x-amz-chime-bearer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ChannelMemberships": {
            "type": "list",
            "member": {
              "shape": "S1y"
            }
          },
          "NextToken": {
            "shape": "S2p"
          }
        }
      }
    },
    "ListChannelMessages": {
      "http": {
        "method": "GET",
        "requestUri": "/channels/{channelArn}/messages",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "ChannelArn",
          "ChimeBearer"
        ],
        "members": {
          "ChannelArn": {
            "location": "uri",
            "locationName": "channelArn"
          },
          "SortOrder": {
            "location": "querystring",
            "locationName": "sort-order"
          },
          "NotBefore": {
            "location": "querystring",
            "locationName": "not-before",
            "type": "timestamp"
          },
          "NotAfter": {
            "location": "querystring",
            "locationName": "not-after",
            "type": "timestamp"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "max-results",
            "type": "integer"
          },
          "NextToken": {
            "shape": "S2p",
            "location": "querystring",
            "locationName": "next-token"
          },
          "ChimeBearer": {
            "location": "header",
            "locationName": "x-amz-chime-bearer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ChannelArn": {},
          "NextToken": {
            "shape": "S2p"
          },
          "ChannelMessages": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "MessageId": {},
                "Content": {
                  "shape": "S2b"
                },
                "Metadata": {
                  "shape": "Sl"
                },
                "Type": {},
                "CreatedTimestamp": {
                  "type": "timestamp"
                },
                "LastUpdatedTimestamp": {
                  "type": "timestamp"
                },
                "LastEditedTimestamp": {
                  "type": "timestamp"
                },
                "Sender": {
                  "shape": "S8"
                },
                "Redacted": {
                  "type": "boolean"
                },
                "Status": {
                  "shape": "S2e"
                }
              }
            }
          }
        }
      }
    },
    "ListChannelModerators": {
      "http": {
        "method": "GET",
        "requestUri": "/channels/{channelArn}/moderators",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "ChannelArn",
          "ChimeBearer"
        ],
        "members": {
          "ChannelArn": {
            "location": "uri",
            "locationName": "channelArn"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "max-results",
            "type": "integer"
          },
          "NextToken": {
            "shape": "S2p",
            "location": "querystring",
            "locationName": "next-token"
          },
          "ChimeBearer": {
            "location": "header",
            "locationName": "x-amz-chime-bearer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ChannelArn": {},
          "NextToken": {
            "shape": "S2p"
          },
          "ChannelModerators": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Moderator": {
                  "shape": "S8"
                }
              }
            }
          }
        }
      }
    },
    "ListChannels": {
      "http": {
        "method": "GET",
        "requestUri": "/channels",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "AppInstanceArn",
          "ChimeBearer"
        ],
        "members": {
          "AppInstanceArn": {
            "location": "querystring",
            "locationName": "app-instance-arn"
          },
          "Privacy": {
            "location": "querystring",
            "locationName": "privacy"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "max-results",
            "type": "integer"
          },
          "NextToken": {
            "shape": "S2p",
            "location": "querystring",
            "locationName": "next-token"
          },
          "ChimeBearer": {
            "location": "header",
            "locationName": "x-amz-chime-bearer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Channels": {
            "type": "list",
            "member": {
              "shape": "S1z"
            }
          },
          "NextToken": {
            "shape": "S2p"
          }
        }
      }
    },
    "ListChannelsAssociatedWithChannelFlow": {
      "http": {
        "method": "GET",
        "requestUri": "/channels?scope=channel-flow-associations",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "ChannelFlowArn"
        ],
        "members": {
          "ChannelFlowArn": {
            "location": "querystring",
            "locationName": "channel-flow-arn"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "max-results",
            "type": "integer"
          },
          "NextToken": {
            "shape": "S2p",
            "location": "querystring",
            "locationName": "next-token"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Channels": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Name": {
                  "shape": "So"
                },
                "ChannelArn": {},
                "Mode": {},
                "Privacy": {},
                "Metadata": {
                  "shape": "Sl"
                }
              }
            }
          },
          "NextToken": {
            "shape": "S2p"
          }
        }
      }
    },
    "ListChannelsModeratedByAppInstanceUser": {
      "http": {
        "method": "GET",
        "requestUri": "/channels?scope=app-instance-user-moderated-channels",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "ChimeBearer"
        ],
        "members": {
          "AppInstanceUserArn": {
            "location": "querystring",
            "locationName": "app-instance-user-arn"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "max-results",
            "type": "integer"
          },
          "NextToken": {
            "shape": "S2p",
            "location": "querystring",
            "locationName": "next-token"
          },
          "ChimeBearer": {
            "location": "header",
            "locationName": "x-amz-chime-bearer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Channels": {
            "type": "list",
            "member": {
              "shape": "S23"
            }
          },
          "NextToken": {
            "shape": "S2p"
          }
        }
      }
    },
    "ListTagsForResource": {
      "http": {
        "method": "GET",
        "requestUri": "/tags",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "ResourceARN"
        ],
        "members": {
          "ResourceARN": {
            "location": "querystring",
            "locationName": "arn"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Tags": {
            "shape": "Ss"
          }
        }
      }
    },
    "RedactChannelMessage": {
      "http": {
        "requestUri": "/channels/{channelArn}/messages/{messageId}?operation=redact",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "ChannelArn",
          "MessageId",
          "ChimeBearer"
        ],
        "members": {
          "ChannelArn": {
            "location": "uri",
            "locationName": "channelArn"
          },
          "MessageId": {
            "location": "uri",
            "locationName": "messageId"
          },
          "ChimeBearer": {
            "location": "header",
            "locationName": "x-amz-chime-bearer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ChannelArn": {},
          "MessageId": {}
        }
      }
    },
    "SendChannelMessage": {
      "http": {
        "requestUri": "/channels/{channelArn}/messages",
        "responseCode": 201
      },
      "input": {
        "type": "structure",
        "required": [
          "ChannelArn",
          "Content",
          "Type",
          "Persistence",
          "ClientRequestToken",
          "ChimeBearer"
        ],
        "members": {
          "ChannelArn": {
            "location": "uri",
            "locationName": "channelArn"
          },
          "Content": {
            "shape": "Sk"
          },
          "Type": {},
          "Persistence": {},
          "Metadata": {
            "shape": "Sl"
          },
          "ClientRequestToken": {
            "shape": "Sr",
            "idempotencyToken": true
          },
          "ChimeBearer": {
            "location": "header",
            "locationName": "x-amz-chime-bearer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ChannelArn": {},
          "MessageId": {},
          "Status": {
            "shape": "S2e"
          }
        }
      }
    },
    "TagResource": {
      "http": {
        "requestUri": "/tags?operation=tag-resource",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "ResourceARN",
          "Tags"
        ],
        "members": {
          "ResourceARN": {},
          "Tags": {
            "shape": "Ss"
          }
        }
      }
    },
    "UntagResource": {
      "http": {
        "requestUri": "/tags?operation=untag-resource",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "ResourceARN",
          "TagKeys"
        ],
        "members": {
          "ResourceARN": {},
          "TagKeys": {
            "type": "list",
            "member": {
              "shape": "Su"
            }
          }
        }
      }
    },
    "UpdateChannel": {
      "http": {
        "method": "PUT",
        "requestUri": "/channels/{channelArn}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "ChannelArn",
          "Name",
          "Mode",
          "ChimeBearer"
        ],
        "members": {
          "ChannelArn": {
            "location": "uri",
            "locationName": "channelArn"
          },
          "Name": {
            "shape": "So"
          },
          "Mode": {},
          "Metadata": {
            "shape": "Sl"
          },
          "ChimeBearer": {
            "location": "header",
            "locationName": "x-amz-chime-bearer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ChannelArn": {}
        }
      }
    },
    "UpdateChannelFlow": {
      "http": {
        "method": "PUT",
        "requestUri": "/channel-flows/{channelFlowArn}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "ChannelFlowArn",
          "Processors",
          "Name"
        ],
        "members": {
          "ChannelFlowArn": {
            "location": "uri",
            "locationName": "channelFlowArn"
          },
          "Processors": {
            "shape": "S10"
          },
          "Name": {
            "shape": "So"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ChannelFlowArn": {}
        }
      }
    },
    "UpdateChannelMessage": {
      "http": {
        "method": "PUT",
        "requestUri": "/channels/{channelArn}/messages/{messageId}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "ChannelArn",
          "MessageId",
          "ChimeBearer"
        ],
        "members": {
          "ChannelArn": {
            "location": "uri",
            "locationName": "channelArn"
          },
          "MessageId": {
            "location": "uri",
            "locationName": "messageId"
          },
          "Content": {
            "shape": "S2b"
          },
          "Metadata": {
            "shape": "Sl"
          },
          "ChimeBearer": {
            "location": "header",
            "locationName": "x-amz-chime-bearer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ChannelArn": {},
          "MessageId": {},
          "Status": {
            "shape": "S2e"
          }
        }
      }
    },
    "UpdateChannelReadMarker": {
      "http": {
        "method": "PUT",
        "requestUri": "/channels/{channelArn}/readMarker",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "ChannelArn",
          "ChimeBearer"
        ],
        "members": {
          "ChannelArn": {
            "location": "uri",
            "locationName": "channelArn"
          },
          "ChimeBearer": {
            "location": "header",
            "locationName": "x-amz-chime-bearer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ChannelArn": {}
        }
      }
    }
  },
  "shapes": {
    "S8": {
      "type": "structure",
      "members": {
        "Arn": {},
        "Name": {
          "type": "string",
          "sensitive": true
        }
      }
    },
    "Sk": {
      "type": "string",
      "sensitive": true
    },
    "Sl": {
      "type": "string",
      "sensitive": true
    },
    "So": {
      "type": "string",
      "sensitive": true
    },
    "Sr": {
      "type": "string",
      "sensitive": true
    },
    "Ss": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "Key",
          "Value"
        ],
        "members": {
          "Key": {
            "shape": "Su"
          },
          "Value": {
            "type": "string",
            "sensitive": true
          }
        }
      }
    },
    "Su": {
      "type": "string",
      "sensitive": true
    },
    "S10": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "Name",
          "Configuration",
          "ExecutionOrder",
          "FallbackAction"
        ],
        "members": {
          "Name": {
            "shape": "So"
          },
          "Configuration": {
            "type": "structure",
            "required": [
              "Lambda"
            ],
            "members": {
              "Lambda": {
                "type": "structure",
                "required": [
                  "ResourceArn",
                  "InvocationType"
                ],
                "members": {
                  "ResourceArn": {},
                  "InvocationType": {}
                }
              }
            }
          },
          "ExecutionOrder": {
            "type": "integer"
          },
          "FallbackAction": {}
        }
      }
    },
    "S1y": {
      "type": "structure",
      "members": {
        "ChannelSummary": {
          "shape": "S1z"
        },
        "AppInstanceUserMembershipSummary": {
          "type": "structure",
          "members": {
            "Type": {},
            "ReadMarkerTimestamp": {
              "type": "timestamp"
            }
          }
        }
      }
    },
    "S1z": {
      "type": "structure",
      "members": {
        "Name": {
          "shape": "So"
        },
        "ChannelArn": {},
        "Mode": {},
        "Privacy": {},
        "Metadata": {
          "shape": "Sl"
        },
        "LastMessageTimestamp": {
          "type": "timestamp"
        }
      }
    },
    "S23": {
      "type": "structure",
      "members": {
        "ChannelSummary": {
          "shape": "S1z"
        }
      }
    },
    "S2b": {
      "type": "string",
      "sensitive": true
    },
    "S2e": {
      "type": "structure",
      "members": {
        "Value": {},
        "Detail": {}
      }
    },
    "S2p": {
      "type": "string",
      "sensitive": true
    }
  }
}