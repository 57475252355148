{
    "pagination": {
        "GetChannelSchedule": {
            "input_token": "NextToken",
            "limit_key": "MaxResults",
            "output_token": "NextToken",
            "result_key": "Items"
        },
        "ListAlerts": {
            "input_token": "NextToken",
            "limit_key": "MaxResults",
            "output_token": "NextToken",
            "result_key": "Items"
        },
        "ListChannels": {
            "input_token": "NextToken",
            "limit_key": "MaxResults",
            "output_token": "NextToken",
            "result_key": "Items"
        },
        "ListPlaybackConfigurations": {
            "input_token": "NextToken",
            "limit_key": "MaxResults",
            "output_token": "NextToken",
            "result_key": "Items"
        },
        "ListPrefetchSchedules": {
            "input_token": "NextToken",
            "limit_key": "MaxResults",
            "output_token": "NextToken",
            "result_key": "Items"
        },
        "ListSourceLocations": {
            "input_token": "NextToken",
            "limit_key": "MaxResults",
            "output_token": "NextToken",
            "result_key": "Items"
        },
        "ListVodSources": {
            "input_token": "NextToken",
            "limit_key": "MaxResults",
            "output_token": "NextToken",
            "result_key": "Items"
        }
    }
}