import { handleKeyDown, loadKeybindings } from './keybindings'

export function addInputListeners() {
  loadKeybindings()
  document.addEventListener('keydown', handleKeyDown)
  window.onbeforeunload = e => {
    if (this.unsavedChanges) {
      e.preventDefault()
      e.returnValue = ''
    } else {
      delete e['returnValue']
    }
  }
}

export function removeInputListeners() {
  document.removeEventListener('keydown', handleKeyDown)
}
