import { makeAutoObservable } from 'mobx'
import Auth from '~/src/features/backend/auth'
import config from './config'

class AuthStore {
  currentUser = null
  protectedUrl = null

  constructor() {
    makeAutoObservable(this, { protectedUrl: false })
    window.logout = this.signOut
  }

  async currentCredentials() {
    return Auth.currentCredentials()
  }

  async currentUserId() {
    return (await Auth.currentAuthenticatedUser()).username
  }

  async fetchCurrentAuthenticatedUser() {
    this.currentUser = await Auth.currentAuthenticatedUser()
  }

  async signIn(email, password) {
    this.currentUser = await Auth.signIn(email, password)
    console.log('[auth] signed in as:', this.currentUser)
  }

  async signOut() {
    await Auth.signOut()
    console.log('signed out')
    this.currentUser = null
  }
}

export default AuthStore
