{
  "metadata": {
    "apiVersion": "2020-11-02",
    "endpointPrefix": "route53-recovery-control-config",
    "signingName": "route53-recovery-control-config",
    "serviceFullName": "AWS Route53 Recovery Control Config",
    "serviceId": "Route53 Recovery Control Config",
    "protocol": "rest-json",
    "jsonVersion": "1.1",
    "uid": "route53-recovery-control-config-2020-11-02",
    "signatureVersion": "v4"
  },
  "operations": {
    "CreateCluster": {
      "http": {
        "requestUri": "/cluster",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "ClientToken": {
            "idempotencyToken": true
          },
          "ClusterName": {}
        },
        "required": [
          "ClusterName"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "Cluster": {
            "shape": "S5"
          }
        }
      }
    },
    "CreateControlPanel": {
      "http": {
        "requestUri": "/controlpanel",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "ClientToken": {
            "idempotencyToken": true
          },
          "ClusterArn": {},
          "ControlPanelName": {}
        },
        "required": [
          "ClusterArn",
          "ControlPanelName"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "ControlPanel": {
            "shape": "Se"
          }
        }
      }
    },
    "CreateRoutingControl": {
      "http": {
        "requestUri": "/routingcontrol",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "ClientToken": {
            "idempotencyToken": true
          },
          "ClusterArn": {},
          "ControlPanelArn": {},
          "RoutingControlName": {}
        },
        "required": [
          "ClusterArn",
          "RoutingControlName"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "RoutingControl": {
            "shape": "Sj"
          }
        }
      }
    },
    "CreateSafetyRule": {
      "http": {
        "requestUri": "/safetyrule",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "AssertionRule": {
            "type": "structure",
            "members": {
              "AssertedControls": {
                "shape": "Sm"
              },
              "ControlPanelArn": {},
              "Name": {},
              "RuleConfig": {
                "shape": "Sn"
              },
              "WaitPeriodMs": {
                "type": "integer"
              }
            },
            "required": [
              "ControlPanelArn",
              "AssertedControls",
              "RuleConfig",
              "WaitPeriodMs",
              "Name"
            ]
          },
          "ClientToken": {
            "idempotencyToken": true
          },
          "GatingRule": {
            "type": "structure",
            "members": {
              "ControlPanelArn": {},
              "GatingControls": {
                "shape": "Sm"
              },
              "Name": {},
              "RuleConfig": {
                "shape": "Sn"
              },
              "TargetControls": {
                "shape": "Sm"
              },
              "WaitPeriodMs": {
                "type": "integer"
              }
            },
            "required": [
              "TargetControls",
              "ControlPanelArn",
              "GatingControls",
              "RuleConfig",
              "WaitPeriodMs",
              "Name"
            ]
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AssertionRule": {
            "shape": "Sr"
          },
          "GatingRule": {
            "shape": "Ss"
          }
        }
      }
    },
    "DeleteCluster": {
      "http": {
        "method": "DELETE",
        "requestUri": "/cluster/{ClusterArn}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "ClusterArn": {
            "location": "uri",
            "locationName": "ClusterArn"
          }
        },
        "required": [
          "ClusterArn"
        ]
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteControlPanel": {
      "http": {
        "method": "DELETE",
        "requestUri": "/controlpanel/{ControlPanelArn}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "ControlPanelArn": {
            "location": "uri",
            "locationName": "ControlPanelArn"
          }
        },
        "required": [
          "ControlPanelArn"
        ]
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteRoutingControl": {
      "http": {
        "method": "DELETE",
        "requestUri": "/routingcontrol/{RoutingControlArn}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "RoutingControlArn": {
            "location": "uri",
            "locationName": "RoutingControlArn"
          }
        },
        "required": [
          "RoutingControlArn"
        ]
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteSafetyRule": {
      "http": {
        "method": "DELETE",
        "requestUri": "/safetyrule/{SafetyRuleArn}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "SafetyRuleArn": {
            "location": "uri",
            "locationName": "SafetyRuleArn"
          }
        },
        "required": [
          "SafetyRuleArn"
        ]
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DescribeCluster": {
      "http": {
        "method": "GET",
        "requestUri": "/cluster/{ClusterArn}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "ClusterArn": {
            "location": "uri",
            "locationName": "ClusterArn"
          }
        },
        "required": [
          "ClusterArn"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "Cluster": {
            "shape": "S5"
          }
        }
      }
    },
    "DescribeControlPanel": {
      "http": {
        "method": "GET",
        "requestUri": "/controlpanel/{ControlPanelArn}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "ControlPanelArn": {
            "location": "uri",
            "locationName": "ControlPanelArn"
          }
        },
        "required": [
          "ControlPanelArn"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "ControlPanel": {
            "shape": "Se"
          }
        }
      }
    },
    "DescribeRoutingControl": {
      "http": {
        "method": "GET",
        "requestUri": "/routingcontrol/{RoutingControlArn}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "RoutingControlArn": {
            "location": "uri",
            "locationName": "RoutingControlArn"
          }
        },
        "required": [
          "RoutingControlArn"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "RoutingControl": {
            "shape": "Sj"
          }
        }
      }
    },
    "DescribeSafetyRule": {
      "http": {
        "method": "GET",
        "requestUri": "/safetyrule/{SafetyRuleArn}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "SafetyRuleArn": {
            "location": "uri",
            "locationName": "SafetyRuleArn"
          }
        },
        "required": [
          "SafetyRuleArn"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "AssertionRule": {
            "shape": "Sr"
          },
          "GatingRule": {
            "shape": "Ss"
          }
        }
      }
    },
    "ListAssociatedRoute53HealthChecks": {
      "http": {
        "method": "GET",
        "requestUri": "/routingcontrol/{RoutingControlArn}/associatedRoute53HealthChecks",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "MaxResults": {
            "location": "querystring",
            "locationName": "MaxResults",
            "type": "integer"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "NextToken"
          },
          "RoutingControlArn": {
            "location": "uri",
            "locationName": "RoutingControlArn"
          }
        },
        "required": [
          "RoutingControlArn"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "HealthCheckIds": {
            "shape": "Sm"
          },
          "NextToken": {}
        }
      }
    },
    "ListClusters": {
      "http": {
        "method": "GET",
        "requestUri": "/cluster",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "MaxResults": {
            "location": "querystring",
            "locationName": "MaxResults",
            "type": "integer"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "NextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Clusters": {
            "type": "list",
            "member": {
              "shape": "S5"
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListControlPanels": {
      "http": {
        "method": "GET",
        "requestUri": "/controlpanels",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "ClusterArn": {
            "location": "querystring",
            "locationName": "ClusterArn"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "MaxResults",
            "type": "integer"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "NextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ControlPanels": {
            "type": "list",
            "member": {
              "shape": "Se"
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListRoutingControls": {
      "http": {
        "method": "GET",
        "requestUri": "/controlpanel/{ControlPanelArn}/routingcontrols",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "ControlPanelArn": {
            "location": "uri",
            "locationName": "ControlPanelArn"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "MaxResults",
            "type": "integer"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "NextToken"
          }
        },
        "required": [
          "ControlPanelArn"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "RoutingControls": {
            "type": "list",
            "member": {
              "shape": "Sj"
            }
          }
        }
      }
    },
    "ListSafetyRules": {
      "http": {
        "method": "GET",
        "requestUri": "/controlpanel/{ControlPanelArn}/safetyrules",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "ControlPanelArn": {
            "location": "uri",
            "locationName": "ControlPanelArn"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "MaxResults",
            "type": "integer"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "NextToken"
          }
        },
        "required": [
          "ControlPanelArn"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "SafetyRules": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "ASSERTION": {
                  "shape": "Sr"
                },
                "GATING": {
                  "shape": "Ss"
                }
              }
            }
          }
        }
      }
    },
    "UpdateControlPanel": {
      "http": {
        "method": "PUT",
        "requestUri": "/controlpanel",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "ControlPanelArn": {},
          "ControlPanelName": {}
        },
        "required": [
          "ControlPanelArn",
          "ControlPanelName"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "ControlPanel": {
            "shape": "Se"
          }
        }
      }
    },
    "UpdateRoutingControl": {
      "http": {
        "method": "PUT",
        "requestUri": "/routingcontrol",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "RoutingControlArn": {},
          "RoutingControlName": {}
        },
        "required": [
          "RoutingControlName",
          "RoutingControlArn"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "RoutingControl": {
            "shape": "Sj"
          }
        }
      }
    },
    "UpdateSafetyRule": {
      "http": {
        "method": "PUT",
        "requestUri": "/safetyrule",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "AssertionRuleUpdate": {
            "type": "structure",
            "members": {
              "Name": {},
              "SafetyRuleArn": {},
              "WaitPeriodMs": {
                "type": "integer"
              }
            },
            "required": [
              "SafetyRuleArn",
              "WaitPeriodMs",
              "Name"
            ]
          },
          "GatingRuleUpdate": {
            "type": "structure",
            "members": {
              "Name": {},
              "SafetyRuleArn": {},
              "WaitPeriodMs": {
                "type": "integer"
              }
            },
            "required": [
              "SafetyRuleArn",
              "WaitPeriodMs",
              "Name"
            ]
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AssertionRule": {
            "shape": "Sr"
          },
          "GatingRule": {
            "shape": "Ss"
          }
        }
      }
    }
  },
  "shapes": {
    "S5": {
      "type": "structure",
      "members": {
        "ClusterArn": {},
        "ClusterEndpoints": {
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "Endpoint": {},
              "Region": {}
            }
          }
        },
        "Name": {},
        "Status": {}
      }
    },
    "Se": {
      "type": "structure",
      "members": {
        "ClusterArn": {},
        "ControlPanelArn": {},
        "DefaultControlPanel": {
          "type": "boolean"
        },
        "Name": {},
        "RoutingControlCount": {
          "type": "integer"
        },
        "Status": {}
      }
    },
    "Sj": {
      "type": "structure",
      "members": {
        "ControlPanelArn": {},
        "Name": {},
        "RoutingControlArn": {},
        "Status": {}
      }
    },
    "Sm": {
      "type": "list",
      "member": {}
    },
    "Sn": {
      "type": "structure",
      "members": {
        "Inverted": {
          "type": "boolean"
        },
        "Threshold": {
          "type": "integer"
        },
        "Type": {}
      },
      "required": [
        "Type",
        "Inverted",
        "Threshold"
      ]
    },
    "Sr": {
      "type": "structure",
      "members": {
        "AssertedControls": {
          "shape": "Sm"
        },
        "ControlPanelArn": {},
        "Name": {},
        "RuleConfig": {
          "shape": "Sn"
        },
        "SafetyRuleArn": {},
        "Status": {},
        "WaitPeriodMs": {
          "type": "integer"
        }
      },
      "required": [
        "Status",
        "ControlPanelArn",
        "SafetyRuleArn",
        "AssertedControls",
        "RuleConfig",
        "WaitPeriodMs",
        "Name"
      ]
    },
    "Ss": {
      "type": "structure",
      "members": {
        "ControlPanelArn": {},
        "GatingControls": {
          "shape": "Sm"
        },
        "Name": {},
        "RuleConfig": {
          "shape": "Sn"
        },
        "SafetyRuleArn": {},
        "Status": {},
        "TargetControls": {
          "shape": "Sm"
        },
        "WaitPeriodMs": {
          "type": "integer"
        }
      },
      "required": [
        "Status",
        "TargetControls",
        "ControlPanelArn",
        "SafetyRuleArn",
        "GatingControls",
        "RuleConfig",
        "WaitPeriodMs",
        "Name"
      ]
    }
  }
}