import { retrieveJson, initializeFileSystem } from '../file-system'
import { configBucket } from '../config/aws-exports'

const envName =
  process.env['REACT_APP_ENV'] ||
  process.env['NODE_ENV'] ||
  process.env['REACT_APP_ENVIRONMENT'] ||
  'development'

export let env = {
  environment: '',
  managerAPI: '',
  playerAnalyticsAPI: '',
  contentFolder: '',
  userContentBucket: '',
  contentAPI: '',
  userContentDistribution: '',
  multiuserServer: '',
  analyticsLoggerAPI: '',
  logAPI: '',
  managerURL: '',
}

export async function updateEnvData(props = {}) {
  env = Object.assign(env, await getEnv(props.environment || envName), props)
  console.log(
    '!> Environment',
    env.environment === 'development' ? env : envName,
  )
}

export async function getEnv(envName) {
  await initializeFileSystem()
  return await retrieveJson(`env/${envName}.json`, configBucket)
}
