{
  "version": "2.0",
  "metadata": {
    "apiVersion": "2017-10-01",
    "endpointPrefix": "workmail",
    "jsonVersion": "1.1",
    "protocol": "json",
    "serviceFullName": "Amazon WorkMail",
    "serviceId": "WorkMail",
    "signatureVersion": "v4",
    "targetPrefix": "WorkMailService",
    "uid": "workmail-2017-10-01"
  },
  "operations": {
    "AssociateDelegateToResource": {
      "input": {
        "type": "structure",
        "required": [
          "OrganizationId",
          "ResourceId",
          "EntityId"
        ],
        "members": {
          "OrganizationId": {},
          "ResourceId": {},
          "EntityId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    },
    "AssociateMemberToGroup": {
      "input": {
        "type": "structure",
        "required": [
          "OrganizationId",
          "GroupId",
          "MemberId"
        ],
        "members": {
          "OrganizationId": {},
          "GroupId": {},
          "MemberId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    },
    "CancelMailboxExportJob": {
      "input": {
        "type": "structure",
        "required": [
          "ClientToken",
          "JobId",
          "OrganizationId"
        ],
        "members": {
          "ClientToken": {
            "idempotencyToken": true
          },
          "JobId": {},
          "OrganizationId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    },
    "CreateAlias": {
      "input": {
        "type": "structure",
        "required": [
          "OrganizationId",
          "EntityId",
          "Alias"
        ],
        "members": {
          "OrganizationId": {},
          "EntityId": {},
          "Alias": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    },
    "CreateGroup": {
      "input": {
        "type": "structure",
        "required": [
          "OrganizationId",
          "Name"
        ],
        "members": {
          "OrganizationId": {},
          "Name": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "GroupId": {}
        }
      },
      "idempotent": true
    },
    "CreateMobileDeviceAccessRule": {
      "input": {
        "type": "structure",
        "required": [
          "OrganizationId",
          "Name",
          "Effect"
        ],
        "members": {
          "OrganizationId": {},
          "ClientToken": {
            "idempotencyToken": true
          },
          "Name": {},
          "Description": {},
          "Effect": {},
          "DeviceTypes": {
            "shape": "Sm"
          },
          "NotDeviceTypes": {
            "shape": "Sm"
          },
          "DeviceModels": {
            "shape": "So"
          },
          "NotDeviceModels": {
            "shape": "So"
          },
          "DeviceOperatingSystems": {
            "shape": "Sq"
          },
          "NotDeviceOperatingSystems": {
            "shape": "Sq"
          },
          "DeviceUserAgents": {
            "shape": "Ss"
          },
          "NotDeviceUserAgents": {
            "shape": "Ss"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "MobileDeviceAccessRuleId": {}
        }
      }
    },
    "CreateOrganization": {
      "input": {
        "type": "structure",
        "required": [
          "Alias"
        ],
        "members": {
          "DirectoryId": {},
          "Alias": {},
          "ClientToken": {
            "idempotencyToken": true
          },
          "Domains": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "DomainName": {},
                "HostedZoneId": {}
              }
            }
          },
          "KmsKeyArn": {},
          "EnableInteroperability": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "OrganizationId": {}
        }
      },
      "idempotent": true
    },
    "CreateResource": {
      "input": {
        "type": "structure",
        "required": [
          "OrganizationId",
          "Name",
          "Type"
        ],
        "members": {
          "OrganizationId": {},
          "Name": {},
          "Type": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ResourceId": {}
        }
      },
      "idempotent": true
    },
    "CreateUser": {
      "input": {
        "type": "structure",
        "required": [
          "OrganizationId",
          "Name",
          "DisplayName",
          "Password"
        ],
        "members": {
          "OrganizationId": {},
          "Name": {},
          "DisplayName": {},
          "Password": {
            "shape": "S1d"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "UserId": {}
        }
      },
      "idempotent": true
    },
    "DeleteAccessControlRule": {
      "input": {
        "type": "structure",
        "required": [
          "OrganizationId",
          "Name"
        ],
        "members": {
          "OrganizationId": {},
          "Name": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteAlias": {
      "input": {
        "type": "structure",
        "required": [
          "OrganizationId",
          "EntityId",
          "Alias"
        ],
        "members": {
          "OrganizationId": {},
          "EntityId": {},
          "Alias": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    },
    "DeleteGroup": {
      "input": {
        "type": "structure",
        "required": [
          "OrganizationId",
          "GroupId"
        ],
        "members": {
          "OrganizationId": {},
          "GroupId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    },
    "DeleteMailboxPermissions": {
      "input": {
        "type": "structure",
        "required": [
          "OrganizationId",
          "EntityId",
          "GranteeId"
        ],
        "members": {
          "OrganizationId": {},
          "EntityId": {},
          "GranteeId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    },
    "DeleteMobileDeviceAccessOverride": {
      "input": {
        "type": "structure",
        "required": [
          "OrganizationId",
          "UserId",
          "DeviceId"
        ],
        "members": {
          "OrganizationId": {},
          "UserId": {},
          "DeviceId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteMobileDeviceAccessRule": {
      "input": {
        "type": "structure",
        "required": [
          "OrganizationId",
          "MobileDeviceAccessRuleId"
        ],
        "members": {
          "OrganizationId": {},
          "MobileDeviceAccessRuleId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteOrganization": {
      "input": {
        "type": "structure",
        "required": [
          "OrganizationId",
          "DeleteDirectory"
        ],
        "members": {
          "ClientToken": {
            "idempotencyToken": true
          },
          "OrganizationId": {},
          "DeleteDirectory": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "OrganizationId": {},
          "State": {}
        }
      },
      "idempotent": true
    },
    "DeleteResource": {
      "input": {
        "type": "structure",
        "required": [
          "OrganizationId",
          "ResourceId"
        ],
        "members": {
          "OrganizationId": {},
          "ResourceId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    },
    "DeleteRetentionPolicy": {
      "input": {
        "type": "structure",
        "required": [
          "OrganizationId",
          "Id"
        ],
        "members": {
          "OrganizationId": {},
          "Id": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    },
    "DeleteUser": {
      "input": {
        "type": "structure",
        "required": [
          "OrganizationId",
          "UserId"
        ],
        "members": {
          "OrganizationId": {},
          "UserId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    },
    "DeregisterFromWorkMail": {
      "input": {
        "type": "structure",
        "required": [
          "OrganizationId",
          "EntityId"
        ],
        "members": {
          "OrganizationId": {},
          "EntityId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    },
    "DeregisterMailDomain": {
      "input": {
        "type": "structure",
        "required": [
          "OrganizationId",
          "DomainName"
        ],
        "members": {
          "OrganizationId": {},
          "DomainName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    },
    "DescribeGroup": {
      "input": {
        "type": "structure",
        "required": [
          "OrganizationId",
          "GroupId"
        ],
        "members": {
          "OrganizationId": {},
          "GroupId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "GroupId": {},
          "Name": {},
          "Email": {},
          "State": {},
          "EnabledDate": {
            "type": "timestamp"
          },
          "DisabledDate": {
            "type": "timestamp"
          }
        }
      },
      "idempotent": true
    },
    "DescribeInboundDmarcSettings": {
      "input": {
        "type": "structure",
        "required": [
          "OrganizationId"
        ],
        "members": {
          "OrganizationId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Enforced": {
            "type": "boolean"
          }
        }
      },
      "idempotent": true
    },
    "DescribeMailboxExportJob": {
      "input": {
        "type": "structure",
        "required": [
          "JobId",
          "OrganizationId"
        ],
        "members": {
          "JobId": {},
          "OrganizationId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "EntityId": {},
          "Description": {},
          "RoleArn": {},
          "KmsKeyArn": {},
          "S3BucketName": {},
          "S3Prefix": {},
          "S3Path": {},
          "EstimatedProgress": {
            "type": "integer"
          },
          "State": {},
          "ErrorInfo": {},
          "StartTime": {
            "type": "timestamp"
          },
          "EndTime": {
            "type": "timestamp"
          }
        }
      },
      "idempotent": true
    },
    "DescribeOrganization": {
      "input": {
        "type": "structure",
        "required": [
          "OrganizationId"
        ],
        "members": {
          "OrganizationId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "OrganizationId": {},
          "Alias": {},
          "State": {},
          "DirectoryId": {},
          "DirectoryType": {},
          "DefaultMailDomain": {},
          "CompletedDate": {
            "type": "timestamp"
          },
          "ErrorMessage": {},
          "ARN": {}
        }
      },
      "idempotent": true
    },
    "DescribeResource": {
      "input": {
        "type": "structure",
        "required": [
          "OrganizationId",
          "ResourceId"
        ],
        "members": {
          "OrganizationId": {},
          "ResourceId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ResourceId": {},
          "Email": {},
          "Name": {},
          "Type": {},
          "BookingOptions": {
            "shape": "S2s"
          },
          "State": {},
          "EnabledDate": {
            "type": "timestamp"
          },
          "DisabledDate": {
            "type": "timestamp"
          }
        }
      },
      "idempotent": true
    },
    "DescribeUser": {
      "input": {
        "type": "structure",
        "required": [
          "OrganizationId",
          "UserId"
        ],
        "members": {
          "OrganizationId": {},
          "UserId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "UserId": {},
          "Name": {},
          "Email": {},
          "DisplayName": {},
          "State": {},
          "UserRole": {},
          "EnabledDate": {
            "type": "timestamp"
          },
          "DisabledDate": {
            "type": "timestamp"
          }
        }
      },
      "idempotent": true
    },
    "DisassociateDelegateFromResource": {
      "input": {
        "type": "structure",
        "required": [
          "OrganizationId",
          "ResourceId",
          "EntityId"
        ],
        "members": {
          "OrganizationId": {},
          "ResourceId": {},
          "EntityId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    },
    "DisassociateMemberFromGroup": {
      "input": {
        "type": "structure",
        "required": [
          "OrganizationId",
          "GroupId",
          "MemberId"
        ],
        "members": {
          "OrganizationId": {},
          "GroupId": {},
          "MemberId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    },
    "GetAccessControlEffect": {
      "input": {
        "type": "structure",
        "required": [
          "OrganizationId",
          "IpAddress",
          "Action",
          "UserId"
        ],
        "members": {
          "OrganizationId": {},
          "IpAddress": {},
          "Action": {},
          "UserId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Effect": {},
          "MatchedRules": {
            "type": "list",
            "member": {}
          }
        }
      }
    },
    "GetDefaultRetentionPolicy": {
      "input": {
        "type": "structure",
        "required": [
          "OrganizationId"
        ],
        "members": {
          "OrganizationId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Id": {},
          "Name": {},
          "Description": {},
          "FolderConfigurations": {
            "shape": "S38"
          }
        }
      },
      "idempotent": true
    },
    "GetMailDomain": {
      "input": {
        "type": "structure",
        "required": [
          "OrganizationId",
          "DomainName"
        ],
        "members": {
          "OrganizationId": {},
          "DomainName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Records": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Type": {},
                "Hostname": {},
                "Value": {}
              }
            }
          },
          "IsTestDomain": {
            "type": "boolean"
          },
          "IsDefault": {
            "type": "boolean"
          },
          "OwnershipVerificationStatus": {},
          "DkimVerificationStatus": {}
        }
      },
      "idempotent": true
    },
    "GetMailboxDetails": {
      "input": {
        "type": "structure",
        "required": [
          "OrganizationId",
          "UserId"
        ],
        "members": {
          "OrganizationId": {},
          "UserId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "MailboxQuota": {
            "type": "integer"
          },
          "MailboxSize": {
            "type": "double"
          }
        }
      },
      "idempotent": true
    },
    "GetMobileDeviceAccessEffect": {
      "input": {
        "type": "structure",
        "required": [
          "OrganizationId"
        ],
        "members": {
          "OrganizationId": {},
          "DeviceType": {},
          "DeviceModel": {},
          "DeviceOperatingSystem": {},
          "DeviceUserAgent": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Effect": {},
          "MatchedRules": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "MobileDeviceAccessRuleId": {},
                "Name": {}
              }
            }
          }
        }
      }
    },
    "GetMobileDeviceAccessOverride": {
      "input": {
        "type": "structure",
        "required": [
          "OrganizationId",
          "UserId",
          "DeviceId"
        ],
        "members": {
          "OrganizationId": {},
          "UserId": {},
          "DeviceId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "UserId": {},
          "DeviceId": {},
          "Effect": {},
          "Description": {},
          "DateCreated": {
            "type": "timestamp"
          },
          "DateModified": {
            "type": "timestamp"
          }
        }
      }
    },
    "ListAccessControlRules": {
      "input": {
        "type": "structure",
        "required": [
          "OrganizationId"
        ],
        "members": {
          "OrganizationId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Rules": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Name": {},
                "Effect": {},
                "Description": {},
                "IpRanges": {
                  "shape": "S3x"
                },
                "NotIpRanges": {
                  "shape": "S3x"
                },
                "Actions": {
                  "shape": "S3z"
                },
                "NotActions": {
                  "shape": "S3z"
                },
                "UserIds": {
                  "shape": "S40"
                },
                "NotUserIds": {
                  "shape": "S40"
                },
                "DateCreated": {
                  "type": "timestamp"
                },
                "DateModified": {
                  "type": "timestamp"
                }
              }
            }
          }
        }
      }
    },
    "ListAliases": {
      "input": {
        "type": "structure",
        "required": [
          "OrganizationId",
          "EntityId"
        ],
        "members": {
          "OrganizationId": {},
          "EntityId": {},
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Aliases": {
            "type": "list",
            "member": {}
          },
          "NextToken": {}
        }
      },
      "idempotent": true
    },
    "ListGroupMembers": {
      "input": {
        "type": "structure",
        "required": [
          "OrganizationId",
          "GroupId"
        ],
        "members": {
          "OrganizationId": {},
          "GroupId": {},
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Members": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Id": {},
                "Name": {},
                "Type": {},
                "State": {},
                "EnabledDate": {
                  "type": "timestamp"
                },
                "DisabledDate": {
                  "type": "timestamp"
                }
              }
            }
          },
          "NextToken": {}
        }
      },
      "idempotent": true
    },
    "ListGroups": {
      "input": {
        "type": "structure",
        "required": [
          "OrganizationId"
        ],
        "members": {
          "OrganizationId": {},
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Groups": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Id": {},
                "Email": {},
                "Name": {},
                "State": {},
                "EnabledDate": {
                  "type": "timestamp"
                },
                "DisabledDate": {
                  "type": "timestamp"
                }
              }
            }
          },
          "NextToken": {}
        }
      },
      "idempotent": true
    },
    "ListMailDomains": {
      "input": {
        "type": "structure",
        "required": [
          "OrganizationId"
        ],
        "members": {
          "OrganizationId": {},
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "MailDomains": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "DomainName": {},
                "DefaultDomain": {
                  "type": "boolean"
                }
              }
            }
          },
          "NextToken": {}
        }
      },
      "idempotent": true
    },
    "ListMailboxExportJobs": {
      "input": {
        "type": "structure",
        "required": [
          "OrganizationId"
        ],
        "members": {
          "OrganizationId": {},
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Jobs": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "JobId": {},
                "EntityId": {},
                "Description": {},
                "S3BucketName": {},
                "S3Path": {},
                "EstimatedProgress": {
                  "type": "integer"
                },
                "State": {},
                "StartTime": {
                  "type": "timestamp"
                },
                "EndTime": {
                  "type": "timestamp"
                }
              }
            }
          },
          "NextToken": {}
        }
      },
      "idempotent": true
    },
    "ListMailboxPermissions": {
      "input": {
        "type": "structure",
        "required": [
          "OrganizationId",
          "EntityId"
        ],
        "members": {
          "OrganizationId": {},
          "EntityId": {},
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Permissions": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "GranteeId",
                "GranteeType",
                "PermissionValues"
              ],
              "members": {
                "GranteeId": {},
                "GranteeType": {},
                "PermissionValues": {
                  "shape": "S4r"
                }
              }
            }
          },
          "NextToken": {}
        }
      },
      "idempotent": true
    },
    "ListMobileDeviceAccessOverrides": {
      "input": {
        "type": "structure",
        "required": [
          "OrganizationId"
        ],
        "members": {
          "OrganizationId": {},
          "UserId": {},
          "DeviceId": {},
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Overrides": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "UserId": {},
                "DeviceId": {},
                "Effect": {},
                "Description": {},
                "DateCreated": {
                  "type": "timestamp"
                },
                "DateModified": {
                  "type": "timestamp"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListMobileDeviceAccessRules": {
      "input": {
        "type": "structure",
        "required": [
          "OrganizationId"
        ],
        "members": {
          "OrganizationId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Rules": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "MobileDeviceAccessRuleId": {},
                "Name": {},
                "Description": {},
                "Effect": {},
                "DeviceTypes": {
                  "shape": "Sm"
                },
                "NotDeviceTypes": {
                  "shape": "Sm"
                },
                "DeviceModels": {
                  "shape": "So"
                },
                "NotDeviceModels": {
                  "shape": "So"
                },
                "DeviceOperatingSystems": {
                  "shape": "Sq"
                },
                "NotDeviceOperatingSystems": {
                  "shape": "Sq"
                },
                "DeviceUserAgents": {
                  "shape": "Ss"
                },
                "NotDeviceUserAgents": {
                  "shape": "Ss"
                },
                "DateCreated": {
                  "type": "timestamp"
                },
                "DateModified": {
                  "type": "timestamp"
                }
              }
            }
          }
        }
      }
    },
    "ListOrganizations": {
      "input": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "OrganizationSummaries": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "OrganizationId": {},
                "Alias": {},
                "DefaultMailDomain": {},
                "ErrorMessage": {},
                "State": {}
              }
            }
          },
          "NextToken": {}
        }
      },
      "idempotent": true
    },
    "ListResourceDelegates": {
      "input": {
        "type": "structure",
        "required": [
          "OrganizationId",
          "ResourceId"
        ],
        "members": {
          "OrganizationId": {},
          "ResourceId": {},
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Delegates": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "Id",
                "Type"
              ],
              "members": {
                "Id": {},
                "Type": {}
              }
            }
          },
          "NextToken": {}
        }
      },
      "idempotent": true
    },
    "ListResources": {
      "input": {
        "type": "structure",
        "required": [
          "OrganizationId"
        ],
        "members": {
          "OrganizationId": {},
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Resources": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Id": {},
                "Email": {},
                "Name": {},
                "Type": {},
                "State": {},
                "EnabledDate": {
                  "type": "timestamp"
                },
                "DisabledDate": {
                  "type": "timestamp"
                }
              }
            }
          },
          "NextToken": {}
        }
      },
      "idempotent": true
    },
    "ListTagsForResource": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceARN"
        ],
        "members": {
          "ResourceARN": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Tags": {
            "shape": "S5f"
          }
        }
      }
    },
    "ListUsers": {
      "input": {
        "type": "structure",
        "required": [
          "OrganizationId"
        ],
        "members": {
          "OrganizationId": {},
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Users": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Id": {},
                "Email": {},
                "Name": {},
                "DisplayName": {},
                "State": {},
                "UserRole": {},
                "EnabledDate": {
                  "type": "timestamp"
                },
                "DisabledDate": {
                  "type": "timestamp"
                }
              }
            }
          },
          "NextToken": {}
        }
      },
      "idempotent": true
    },
    "PutAccessControlRule": {
      "input": {
        "type": "structure",
        "required": [
          "Name",
          "Effect",
          "Description",
          "OrganizationId"
        ],
        "members": {
          "Name": {},
          "Effect": {},
          "Description": {},
          "IpRanges": {
            "shape": "S3x"
          },
          "NotIpRanges": {
            "shape": "S3x"
          },
          "Actions": {
            "shape": "S3z"
          },
          "NotActions": {
            "shape": "S3z"
          },
          "UserIds": {
            "shape": "S40"
          },
          "NotUserIds": {
            "shape": "S40"
          },
          "OrganizationId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "PutInboundDmarcSettings": {
      "input": {
        "type": "structure",
        "required": [
          "OrganizationId",
          "Enforced"
        ],
        "members": {
          "OrganizationId": {},
          "Enforced": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    },
    "PutMailboxPermissions": {
      "input": {
        "type": "structure",
        "required": [
          "OrganizationId",
          "EntityId",
          "GranteeId",
          "PermissionValues"
        ],
        "members": {
          "OrganizationId": {},
          "EntityId": {},
          "GranteeId": {},
          "PermissionValues": {
            "shape": "S4r"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    },
    "PutMobileDeviceAccessOverride": {
      "input": {
        "type": "structure",
        "required": [
          "OrganizationId",
          "UserId",
          "DeviceId",
          "Effect"
        ],
        "members": {
          "OrganizationId": {},
          "UserId": {},
          "DeviceId": {},
          "Effect": {},
          "Description": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "PutRetentionPolicy": {
      "input": {
        "type": "structure",
        "required": [
          "OrganizationId",
          "Name",
          "FolderConfigurations"
        ],
        "members": {
          "OrganizationId": {},
          "Id": {},
          "Name": {},
          "Description": {
            "type": "string",
            "sensitive": true
          },
          "FolderConfigurations": {
            "shape": "S38"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    },
    "RegisterMailDomain": {
      "input": {
        "type": "structure",
        "required": [
          "OrganizationId",
          "DomainName"
        ],
        "members": {
          "ClientToken": {
            "idempotencyToken": true
          },
          "OrganizationId": {},
          "DomainName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    },
    "RegisterToWorkMail": {
      "input": {
        "type": "structure",
        "required": [
          "OrganizationId",
          "EntityId",
          "Email"
        ],
        "members": {
          "OrganizationId": {},
          "EntityId": {},
          "Email": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    },
    "ResetPassword": {
      "input": {
        "type": "structure",
        "required": [
          "OrganizationId",
          "UserId",
          "Password"
        ],
        "members": {
          "OrganizationId": {},
          "UserId": {},
          "Password": {
            "shape": "S1d"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    },
    "StartMailboxExportJob": {
      "input": {
        "type": "structure",
        "required": [
          "ClientToken",
          "OrganizationId",
          "EntityId",
          "RoleArn",
          "KmsKeyArn",
          "S3BucketName",
          "S3Prefix"
        ],
        "members": {
          "ClientToken": {
            "idempotencyToken": true
          },
          "OrganizationId": {},
          "EntityId": {},
          "Description": {},
          "RoleArn": {},
          "KmsKeyArn": {},
          "S3BucketName": {},
          "S3Prefix": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "JobId": {}
        }
      },
      "idempotent": true
    },
    "TagResource": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceARN",
          "Tags"
        ],
        "members": {
          "ResourceARN": {},
          "Tags": {
            "shape": "S5f"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UntagResource": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceARN",
          "TagKeys"
        ],
        "members": {
          "ResourceARN": {},
          "TagKeys": {
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UpdateDefaultMailDomain": {
      "input": {
        "type": "structure",
        "required": [
          "OrganizationId",
          "DomainName"
        ],
        "members": {
          "OrganizationId": {},
          "DomainName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    },
    "UpdateMailboxQuota": {
      "input": {
        "type": "structure",
        "required": [
          "OrganizationId",
          "UserId",
          "MailboxQuota"
        ],
        "members": {
          "OrganizationId": {},
          "UserId": {},
          "MailboxQuota": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    },
    "UpdateMobileDeviceAccessRule": {
      "input": {
        "type": "structure",
        "required": [
          "OrganizationId",
          "MobileDeviceAccessRuleId",
          "Name",
          "Effect"
        ],
        "members": {
          "OrganizationId": {},
          "MobileDeviceAccessRuleId": {},
          "Name": {},
          "Description": {},
          "Effect": {},
          "DeviceTypes": {
            "shape": "Sm"
          },
          "NotDeviceTypes": {
            "shape": "Sm"
          },
          "DeviceModels": {
            "shape": "So"
          },
          "NotDeviceModels": {
            "shape": "So"
          },
          "DeviceOperatingSystems": {
            "shape": "Sq"
          },
          "NotDeviceOperatingSystems": {
            "shape": "Sq"
          },
          "DeviceUserAgents": {
            "shape": "Ss"
          },
          "NotDeviceUserAgents": {
            "shape": "Ss"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UpdatePrimaryEmailAddress": {
      "input": {
        "type": "structure",
        "required": [
          "OrganizationId",
          "EntityId",
          "Email"
        ],
        "members": {
          "OrganizationId": {},
          "EntityId": {},
          "Email": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    },
    "UpdateResource": {
      "input": {
        "type": "structure",
        "required": [
          "OrganizationId",
          "ResourceId"
        ],
        "members": {
          "OrganizationId": {},
          "ResourceId": {},
          "Name": {},
          "BookingOptions": {
            "shape": "S2s"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    }
  },
  "shapes": {
    "Sm": {
      "type": "list",
      "member": {}
    },
    "So": {
      "type": "list",
      "member": {}
    },
    "Sq": {
      "type": "list",
      "member": {}
    },
    "Ss": {
      "type": "list",
      "member": {}
    },
    "S1d": {
      "type": "string",
      "sensitive": true
    },
    "S2s": {
      "type": "structure",
      "members": {
        "AutoAcceptRequests": {
          "type": "boolean"
        },
        "AutoDeclineRecurringRequests": {
          "type": "boolean"
        },
        "AutoDeclineConflictingRequests": {
          "type": "boolean"
        }
      }
    },
    "S38": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "Name",
          "Action"
        ],
        "members": {
          "Name": {},
          "Action": {},
          "Period": {
            "type": "integer"
          }
        }
      }
    },
    "S3x": {
      "type": "list",
      "member": {}
    },
    "S3z": {
      "type": "list",
      "member": {}
    },
    "S40": {
      "type": "list",
      "member": {}
    },
    "S4r": {
      "type": "list",
      "member": {}
    },
    "S5f": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "Key",
          "Value"
        ],
        "members": {
          "Key": {},
          "Value": {}
        }
      }
    }
  }
}