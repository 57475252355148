{
  "pagination": {
    "DescribeTrails": {
      "result_key": "trailList"
    },
    "ListPublicKeys": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "result_key": "PublicKeyList"
    },
    "ListTags": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "result_key": "ResourceTagList"
    },
    "ListTrails": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "result_key": "Trails"
    },
    "LookupEvents": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "Events"
    }
  }
}