{
  "version": "2.0",
  "metadata": {
    "apiVersion": "2020-07-01",
    "endpointPrefix": "airflow",
    "jsonVersion": "1.1",
    "protocol": "rest-json",
    "serviceFullName": "AmazonMWAA",
    "serviceId": "MWAA",
    "signatureVersion": "v4",
    "signingName": "airflow",
    "uid": "mwaa-2020-07-01"
  },
  "operations": {
    "CreateCliToken": {
      "http": {
        "requestUri": "/clitoken/{Name}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {
            "location": "uri",
            "locationName": "Name"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "CliToken": {
            "type": "string",
            "sensitive": true
          },
          "WebServerHostname": {}
        }
      },
      "endpoint": {
        "hostPrefix": "env."
      }
    },
    "CreateEnvironment": {
      "http": {
        "method": "PUT",
        "requestUri": "/environments/{Name}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "DagS3Path",
          "ExecutionRoleArn",
          "Name",
          "NetworkConfiguration",
          "SourceBucketArn"
        ],
        "members": {
          "AirflowConfigurationOptions": {
            "type": "map",
            "key": {},
            "value": {},
            "sensitive": true
          },
          "AirflowVersion": {},
          "DagS3Path": {},
          "EnvironmentClass": {},
          "ExecutionRoleArn": {},
          "KmsKey": {},
          "LoggingConfiguration": {
            "shape": "Sf"
          },
          "MaxWorkers": {
            "type": "integer"
          },
          "MinWorkers": {
            "type": "integer"
          },
          "Name": {
            "location": "uri",
            "locationName": "Name"
          },
          "NetworkConfiguration": {
            "shape": "Sl"
          },
          "PluginsS3ObjectVersion": {},
          "PluginsS3Path": {},
          "RequirementsS3ObjectVersion": {},
          "RequirementsS3Path": {},
          "Schedulers": {
            "type": "integer"
          },
          "SourceBucketArn": {},
          "Tags": {
            "shape": "St"
          },
          "WebserverAccessMode": {},
          "WeeklyMaintenanceWindowStart": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Arn": {}
        }
      },
      "endpoint": {
        "hostPrefix": "api."
      },
      "idempotent": true
    },
    "CreateWebLoginToken": {
      "http": {
        "requestUri": "/webtoken/{Name}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {
            "location": "uri",
            "locationName": "Name"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "WebServerHostname": {},
          "WebToken": {
            "type": "string",
            "sensitive": true
          }
        }
      },
      "endpoint": {
        "hostPrefix": "env."
      },
      "idempotent": true
    },
    "DeleteEnvironment": {
      "http": {
        "method": "DELETE",
        "requestUri": "/environments/{Name}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {
            "location": "uri",
            "locationName": "Name"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "endpoint": {
        "hostPrefix": "api."
      },
      "idempotent": true
    },
    "GetEnvironment": {
      "http": {
        "method": "GET",
        "requestUri": "/environments/{Name}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {
            "location": "uri",
            "locationName": "Name"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Environment": {
            "type": "structure",
            "members": {
              "AirflowConfigurationOptions": {
                "type": "map",
                "key": {},
                "value": {}
              },
              "AirflowVersion": {},
              "Arn": {},
              "CreatedAt": {
                "type": "timestamp"
              },
              "DagS3Path": {},
              "EnvironmentClass": {},
              "ExecutionRoleArn": {},
              "KmsKey": {},
              "LastUpdate": {
                "type": "structure",
                "members": {
                  "CreatedAt": {
                    "type": "timestamp"
                  },
                  "Error": {
                    "type": "structure",
                    "members": {
                      "ErrorCode": {},
                      "ErrorMessage": {}
                    }
                  },
                  "Status": {}
                }
              },
              "LoggingConfiguration": {
                "type": "structure",
                "members": {
                  "DagProcessingLogs": {
                    "shape": "S1h"
                  },
                  "SchedulerLogs": {
                    "shape": "S1h"
                  },
                  "TaskLogs": {
                    "shape": "S1h"
                  },
                  "WebserverLogs": {
                    "shape": "S1h"
                  },
                  "WorkerLogs": {
                    "shape": "S1h"
                  }
                }
              },
              "MaxWorkers": {
                "type": "integer"
              },
              "MinWorkers": {
                "type": "integer"
              },
              "Name": {},
              "NetworkConfiguration": {
                "shape": "Sl"
              },
              "PluginsS3ObjectVersion": {},
              "PluginsS3Path": {},
              "RequirementsS3ObjectVersion": {},
              "RequirementsS3Path": {},
              "Schedulers": {
                "type": "integer"
              },
              "ServiceRoleArn": {},
              "SourceBucketArn": {},
              "Status": {},
              "Tags": {
                "shape": "St"
              },
              "WebserverAccessMode": {},
              "WebserverUrl": {},
              "WeeklyMaintenanceWindowStart": {}
            }
          }
        }
      },
      "endpoint": {
        "hostPrefix": "api."
      }
    },
    "ListEnvironments": {
      "http": {
        "method": "GET",
        "requestUri": "/environments",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "MaxResults": {
            "location": "querystring",
            "locationName": "MaxResults",
            "type": "integer"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "NextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "Environments"
        ],
        "members": {
          "Environments": {
            "type": "list",
            "member": {}
          },
          "NextToken": {}
        }
      },
      "endpoint": {
        "hostPrefix": "api."
      }
    },
    "ListTagsForResource": {
      "http": {
        "method": "GET",
        "requestUri": "/tags/{ResourceArn}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "ResourceArn"
        ],
        "members": {
          "ResourceArn": {
            "location": "uri",
            "locationName": "ResourceArn"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Tags": {
            "shape": "St"
          }
        }
      },
      "endpoint": {
        "hostPrefix": "api."
      }
    },
    "PublishMetrics": {
      "http": {
        "requestUri": "/metrics/environments/{EnvironmentName}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "EnvironmentName",
          "MetricData"
        ],
        "members": {
          "EnvironmentName": {
            "location": "uri",
            "locationName": "EnvironmentName"
          },
          "MetricData": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "MetricName",
                "Timestamp"
              ],
              "members": {
                "Dimensions": {
                  "type": "list",
                  "member": {
                    "type": "structure",
                    "required": [
                      "Name",
                      "Value"
                    ],
                    "members": {
                      "Name": {},
                      "Value": {}
                    }
                  }
                },
                "MetricName": {},
                "StatisticValues": {
                  "type": "structure",
                  "members": {
                    "Maximum": {
                      "type": "double"
                    },
                    "Minimum": {
                      "type": "double"
                    },
                    "SampleCount": {
                      "type": "integer"
                    },
                    "Sum": {
                      "type": "double"
                    }
                  }
                },
                "Timestamp": {
                  "type": "timestamp"
                },
                "Unit": {},
                "Value": {
                  "type": "double"
                }
              }
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "endpoint": {
        "hostPrefix": "ops."
      }
    },
    "TagResource": {
      "http": {
        "requestUri": "/tags/{ResourceArn}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "ResourceArn",
          "Tags"
        ],
        "members": {
          "ResourceArn": {
            "location": "uri",
            "locationName": "ResourceArn"
          },
          "Tags": {
            "shape": "St"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "endpoint": {
        "hostPrefix": "api."
      }
    },
    "UntagResource": {
      "http": {
        "method": "DELETE",
        "requestUri": "/tags/{ResourceArn}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "ResourceArn",
          "tagKeys"
        ],
        "members": {
          "ResourceArn": {
            "location": "uri",
            "locationName": "ResourceArn"
          },
          "tagKeys": {
            "location": "querystring",
            "locationName": "tagKeys",
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "endpoint": {
        "hostPrefix": "api."
      },
      "idempotent": true
    },
    "UpdateEnvironment": {
      "http": {
        "method": "PATCH",
        "requestUri": "/environments/{Name}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "AirflowConfigurationOptions": {
            "type": "map",
            "key": {},
            "value": {},
            "sensitive": true
          },
          "AirflowVersion": {},
          "DagS3Path": {},
          "EnvironmentClass": {},
          "ExecutionRoleArn": {},
          "LoggingConfiguration": {
            "shape": "Sf"
          },
          "MaxWorkers": {
            "type": "integer"
          },
          "MinWorkers": {
            "type": "integer"
          },
          "Name": {
            "location": "uri",
            "locationName": "Name"
          },
          "NetworkConfiguration": {
            "type": "structure",
            "required": [
              "SecurityGroupIds"
            ],
            "members": {
              "SecurityGroupIds": {
                "shape": "Sm"
              }
            }
          },
          "PluginsS3ObjectVersion": {},
          "PluginsS3Path": {},
          "RequirementsS3ObjectVersion": {},
          "RequirementsS3Path": {},
          "Schedulers": {
            "type": "integer"
          },
          "SourceBucketArn": {},
          "WebserverAccessMode": {},
          "WeeklyMaintenanceWindowStart": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Arn": {}
        }
      },
      "endpoint": {
        "hostPrefix": "api."
      }
    }
  },
  "shapes": {
    "Sf": {
      "type": "structure",
      "members": {
        "DagProcessingLogs": {
          "shape": "Sg"
        },
        "SchedulerLogs": {
          "shape": "Sg"
        },
        "TaskLogs": {
          "shape": "Sg"
        },
        "WebserverLogs": {
          "shape": "Sg"
        },
        "WorkerLogs": {
          "shape": "Sg"
        }
      }
    },
    "Sg": {
      "type": "structure",
      "required": [
        "Enabled",
        "LogLevel"
      ],
      "members": {
        "Enabled": {
          "type": "boolean"
        },
        "LogLevel": {}
      }
    },
    "Sl": {
      "type": "structure",
      "members": {
        "SecurityGroupIds": {
          "shape": "Sm"
        },
        "SubnetIds": {
          "type": "list",
          "member": {}
        }
      }
    },
    "Sm": {
      "type": "list",
      "member": {}
    },
    "St": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "S1h": {
      "type": "structure",
      "members": {
        "CloudWatchLogGroupArn": {},
        "Enabled": {
          "type": "boolean"
        },
        "LogLevel": {}
      }
    }
  }
}