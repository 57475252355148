import React, { useEffect } from 'react'
import { reaction, flow } from 'mobx'
import { observer } from 'mobx-react-lite'
import { useParams } from 'react-router-dom'
import LoadingScreen from '~/src/app/components/LoadingScreen'
import PlayerUI from './PlayerUI'
import PlayerViewport from '~/src/features/player/PlayerViewport'
import LabelOverlay from '~/src/features/tools/label/LabelOverlay'
import { useStore } from '~/src/app/store'
import { useLoadTrainingForPlayer } from '~/src/hooks/training'

const PlayerPage = () => {
  const { id } = useParams()
  const { appState, transitions, player } = useStore()
  const loadTraining = useLoadTrainingForPlayer()

  useEffect(() => {
    // set the app in player mode
    appState.playerMode()

    // setup mobx reactions
    const disposers = [
      // adjust selection when navigating between steps
      reaction(
        () => appState.selectedStepId,
        selectedStepId => {
          transitions.onSelectedStepChange(selectedStepId)
        },
      ),
    ]

    // fatch the data
    const doFetchTraining = flow(function* () {
      appState.setLoading('scene', 0)
      yield loadTraining(id)
      player.start()
      appState.clearLoading('scene')
    })
    doFetchTraining()

    // cleaup
    return () => {
      console.log('PlayerPage: cleanup')
      // cleanup mobx reactions
      for (const disposer of disposers) disposer()
    }
  }, [id])

  return (
    <div className="player-container">
      <div className="viewport-container" />
      <PlayerViewport />
      <PlayerUI hideBackButton />
      <LabelOverlay />
      <LoadingScreen />
    </div>
  )
}

export default observer(PlayerPage)
