import rootStore from '~/src/app/store'
import { RED, GREEN, BLUE } from '~/src/utils/nodes'

export const disposeGizmoManager = gizmoManager => {
  const { sceneManager } = rootStore
  const { scene } = sceneManager
  gizmoManager._pointerObservers.forEach(observer => {
    scene.onPointerObservable.remove(observer)
  })
  for (var key in gizmoManager.gizmos) {
    var gizmo = gizmoManager.gizmos[key]
    if (gizmo) gizmo.dispose()
  }
  gizmoManager.boundingBoxDragBehavior.detach()
  gizmoManager.onAttachedToMeshObservable.clear()
}

export const stylePositionGizmo = gizmoManager => {
  const { positionGizmo } = gizmoManager.gizmos
  positionGizmo.scaleRatio = 1.2
  const { xGizmo, yGizmo, zGizmo } = positionGizmo
  xGizmo._coloredMaterial.diffuseColor = BLUE
  // xGizmo._hoverMaterial.diffuseColor = new Color3()
  yGizmo._coloredMaterial.diffuseColor = GREEN
  // yGizmo._hoverMaterial.diffuseColor = new Color3()
  zGizmo._coloredMaterial.diffuseColor = RED
  // zGizmo._hoverMaterial.diffuseColor = new Color3()
  // planar
  positionGizmo.planarGizmoEnabled = true
  const { xPlaneGizmo, yPlaneGizmo, zPlaneGizmo } = positionGizmo
  xPlaneGizmo._coloredMaterial.diffuseColor = BLUE
  xPlaneGizmo._coloredMaterial.alpha = 0.5
  xPlaneGizmo._hoverMaterial.diffuseColor = BLUE
  yPlaneGizmo._coloredMaterial.diffuseColor = GREEN
  yPlaneGizmo._coloredMaterial.alpha = 0.5
  yPlaneGizmo._hoverMaterial.diffuseColor = GREEN
  zPlaneGizmo._coloredMaterial.diffuseColor = RED
  zPlaneGizmo._coloredMaterial.alpha = 0.5
  zPlaneGizmo._hoverMaterial.diffuseColor = RED
}

export const styleRotationGizmo = gizmoManager => {
  const { rotationGizmo } = gizmoManager.gizmos
  rotationGizmo.scaleRatio = 1.2
  const { xGizmo, yGizmo, zGizmo } = rotationGizmo
  xGizmo._coloredMaterial.diffuseColor = BLUE
  // xGizmo._hoverMaterial.diffuseColor = new Color3()
  yGizmo._coloredMaterial.diffuseColor = GREEN
  // yGizmo._hoverMaterial.diffuseColor = new Color3()
  zGizmo._coloredMaterial.diffuseColor = RED
}

export const styleScaleGizmo = gizmoManager => {
  const { scaleGizmo } = gizmoManager.gizmos
  scaleGizmo.scaleRatio = 1.2
  const { xGizmo, yGizmo, zGizmo } = scaleGizmo
  xGizmo._coloredMaterial.diffuseColor = BLUE
  // xGizmo._hoverMaterial.diffuseColor = new Color3()
  yGizmo._coloredMaterial.diffuseColor = GREEN
  // yGizmo._hoverMaterial.diffuseColor = new Color3()
  zGizmo._coloredMaterial.diffuseColor = RED
}
